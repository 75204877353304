import React from "react";

const SupportPageCustomerHelpIcon = () => {
    return (
        <svg
            width="140"
            height="130"
            viewBox="0 0 140 130"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="flex-grow-0 flex-shrink-0 w-[139.66px] h-[129.03px] relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <g clip-path="url(#clip0_15405_23072)">
                <path
                    d="M112.55 86.2594C112.046 85.7009 113.235 82.4382 118.874 82.2072C124.513 81.9763 126.772 84.0297 126.781 86.2594C126.785 87.2462 112.55 86.2594 112.55 86.2594Z"
                    fill="#455A64"
                ></path>
                <path
                    d="M109.757 0.898135L103.492 39.6433H160.709L167.604 0.482422L109.757 0.898135Z"
                    fill="#F5F5F5"
                ></path>
                <path
                    d="M109.195 4.42118C109.221 4.28681 166.958 4.20283 166.958 4.20283L167.613 0.482422L109.766 0.902332L109.195 4.42958"
                    fill="#E0E0E0"
                ></path>
                <path
                    d="M112.184 2.64238C112.185 2.74803 112.154 2.85155 112.096 2.9398C112.038 3.02805 111.955 3.09705 111.857 3.13806C111.76 3.17907 111.653 3.19023 111.549 3.17013C111.445 3.15003 111.35 3.09957 111.275 3.02516C111.2 2.95075 111.149 2.85574 111.128 2.75218C111.107 2.64862 111.117 2.54118 111.157 2.44349C111.198 2.3458 111.266 2.26226 111.354 2.20347C111.442 2.14469 111.545 2.1133 111.65 2.1133C111.72 2.11274 111.79 2.12602 111.854 2.15236C111.919 2.1787 111.978 2.21759 112.027 2.26677C112.077 2.31596 112.116 2.37447 112.143 2.43894C112.17 2.5034 112.184 2.57255 112.184 2.64238Z"
                    fill="#263238"
                ></path>
                <path
                    d="M114.785 2.64237C114.785 2.74701 114.754 2.84931 114.696 2.93631C114.637 3.02332 114.555 3.09114 114.458 3.13118C114.361 3.17123 114.255 3.1817 114.152 3.16129C114.05 3.14087 113.956 3.09048 113.882 3.01649C113.808 2.9425 113.757 2.84822 113.737 2.74559C113.716 2.64296 113.727 2.53657 113.767 2.43989C113.807 2.34322 113.875 2.26058 113.962 2.20245C114.049 2.14431 114.151 2.11328 114.256 2.11328C114.396 2.11328 114.531 2.16903 114.63 2.26825C114.729 2.36747 114.785 2.50204 114.785 2.64237Z"
                    fill="#263238"
                ></path>
                <path
                    d="M117.402 2.64238C117.403 2.74803 117.373 2.85155 117.315 2.9398C117.257 3.02805 117.174 3.09705 117.076 3.13806C116.979 3.17907 116.871 3.19023 116.768 3.17013C116.664 3.15003 116.569 3.09957 116.494 3.02516C116.419 2.95075 116.367 2.85574 116.346 2.75218C116.326 2.64862 116.336 2.54118 116.376 2.44349C116.416 2.3458 116.485 2.26226 116.572 2.20347C116.66 2.14469 116.764 2.1133 116.869 2.1133C116.939 2.11274 117.008 2.12602 117.073 2.15236C117.138 2.1787 117.196 2.21759 117.246 2.26677C117.296 2.31596 117.335 2.37447 117.362 2.43894C117.389 2.5034 117.402 2.57255 117.402 2.64238Z"
                    fill="#263238"
                ></path>
                <path
                    d="M123.857 21.8264L116.697 30.107C115.346 29.1898 114.235 27.9601 113.459 26.5223C112.684 25.0845 112.266 23.4808 112.242 21.8474C112.242 16.0904 117.487 11.3496 123.857 11.3496C130.227 11.3496 135.472 16.0862 135.472 21.8474L123.857 21.8264Z"
                    fill="#D80027"
                ></path>
                <path
                    d="M124.773 23.1406C124.738 23.1992 124.697 23.2541 124.651 23.3044L124.277 23.7495L122.858 25.4291C121.624 26.8736 119.868 28.9144 117.765 31.3709V31.2365C119.351 32.3284 121.183 33.0094 123.097 33.2185C125.259 33.4677 127.447 33.1372 129.438 32.2611C131.338 31.4356 132.98 30.1108 134.187 28.4273C134.731 27.6423 135.168 26.7878 135.485 25.8868C135.761 25.0452 135.929 24.1717 135.985 23.2876L136.077 23.3842L127.889 23.2456L125.588 23.1952L124.979 23.1742C124.907 23.1809 124.836 23.1809 124.765 23.1742C124.832 23.1677 124.899 23.1677 124.966 23.1742H125.567H127.855L136.077 23.2288H136.178V23.3296C136.13 24.2335 135.963 25.1272 135.682 25.9876C135.363 26.908 134.921 27.7809 134.368 28.5827C133.138 30.3111 131.458 31.669 129.51 32.5088C127.482 33.3961 125.254 33.7294 123.056 33.4746C121.109 33.2536 119.247 32.5524 117.639 31.4339L117.555 31.3751L117.622 31.2953L122.804 25.3871L124.252 23.7495L124.639 23.3296C124.678 23.2628 124.723 23.1996 124.773 23.1406Z"
                    fill="#263238"
                ></path>
                <path
                    d="M128.791 16.2412C129.151 16.2412 129.442 15.9498 129.442 15.5903C129.442 15.2308 129.151 14.9395 128.791 14.9395C128.432 14.9395 128.141 15.2308 128.141 15.5903C128.141 15.9498 128.432 16.2412 128.791 16.2412Z"
                    fill="#263238"
                ></path>
                <path
                    d="M142.369 10.8282C142.166 10.8597 141.961 10.8737 141.756 10.8702L140.076 10.9038C138.67 10.9038 136.717 10.9374 134.58 10.9374H133.555L133.631 10.908C132.283 12.2517 131.082 13.4275 130.213 14.2673L129.184 15.2499C129.066 15.3775 128.934 15.4903 128.789 15.5858C128.891 15.4461 129.005 15.3154 129.129 15.1953C129.385 14.9223 129.717 14.578 130.112 14.1665C130.952 13.2973 132.136 12.0963 133.471 10.7442L133.5 10.7148H134.605C136.751 10.7148 138.695 10.7148 140.101 10.7442L141.781 10.782C141.978 10.7788 142.175 10.7943 142.369 10.8282Z"
                    fill="#263238"
                ></path>
                <path
                    d="M129.815 29.333C130.174 29.333 130.466 29.0416 130.466 28.6821C130.466 28.3227 130.174 28.0312 129.815 28.0312C129.455 28.0312 129.164 28.3227 129.164 28.6821C129.164 29.0416 129.455 29.333 129.815 29.333Z"
                    fill="#263238"
                ></path>
                <path
                    d="M141.629 28.6834C141.629 28.7464 138.983 28.7926 135.721 28.7926C132.458 28.7926 129.812 28.7464 129.812 28.6834C129.812 28.6204 132.458 28.5742 135.721 28.5742C138.983 28.5742 141.629 28.6246 141.629 28.6834Z"
                    fill="#263238"
                ></path>
                <path
                    d="M87.3066 72.6855C87.105 73.8655 81.5034 86.2613 85.1189 86.2613H103.293C103.293 86.2613 101.416 84.4976 107.097 72.6855H87.3066Z"
                    fill="#455A64"
                ></path>
                <path
                    d="M75.5454 31.3133L68.1172 74.7237H130.772L138.456 29.7891L75.5454 31.3133Z"
                    fill="#455A64"
                ></path>
                <path
                    d="M77.6749 33.4291L71.4141 72.1784H128.627L135.522 33.0176L77.6749 33.4291Z"
                    fill="#FAFAFA"
                ></path>
                <path
                    d="M138.456 29.7891L139.641 30.5869L131.662 75.9036H69.7716L68.1172 74.7237H130.772L138.456 29.7891Z"
                    fill="#263238"
                ></path>
                <path
                    d="M77.1094 36.9563C77.1304 36.8178 134.868 36.738 134.868 36.738L135.523 33.0176L77.6763 33.4375L77.1094 36.9647"
                    fill="#EBEBEB"
                ></path>
                <path
                    d="M119.609 68.7389V68.6297L119.66 68.3064C119.713 67.9985 119.784 67.5786 119.874 67.0467C120.071 65.9171 120.348 64.2963 120.693 62.2849C121.407 58.2118 122.402 52.543 123.531 46.11V46.0176H128.26L128.239 46.1478C126.487 55.6335 124.955 63.9687 124.069 68.7599V68.8103H124.023L120.731 68.7767H119.891H119.685H119.912H120.781L124.035 68.7431L123.976 68.7893L128.041 46.1604L128.15 46.2863H123.649L123.754 46.1981C122.591 52.5934 121.57 58.2244 120.815 62.2765C120.445 64.2921 120.143 65.9171 119.933 67.0509C119.828 67.601 119.748 68.0335 119.689 68.3442C119.66 68.4828 119.639 68.5961 119.622 68.6801C119.619 68.6999 119.614 68.7195 119.609 68.7389Z"
                    fill="#263238"
                ></path>
                <path
                    d="M112.359 68.7396C112.365 68.6376 112.38 68.5363 112.406 68.4373C112.443 68.2147 112.498 67.9166 112.565 67.5387C112.716 66.7282 112.926 65.5819 113.191 64.1794C113.741 61.2694 114.509 57.2214 115.387 52.594V52.5059H120.115L120.09 52.636C118.763 59.216 117.57 65.0654 116.831 68.7732V68.8278H116.777L113.489 68.79H112.649C112.555 68.7963 112.461 68.7963 112.368 68.79H112.679H113.552L116.794 68.7522L116.726 68.8068C117.457 65.0948 118.608 59.2412 119.901 52.6528L120.006 52.783H115.479L115.584 52.699C114.673 57.2844 113.875 61.2988 113.3 64.1836C113.01 65.6155 112.779 66.766 112.611 67.5764L112.422 68.496C112.422 68.5926 112.38 68.6724 112.368 68.7354L112.359 68.7396Z"
                    fill="#263238"
                ></path>
                <path
                    d="M105.071 68.7394C105.068 68.6946 105.068 68.6497 105.071 68.605L105.139 68.1851C105.202 67.8156 105.29 67.2865 105.407 66.6062C105.651 65.2163 105.999 63.2133 106.436 60.7275C107.326 55.6886 108.565 48.7138 109.968 40.8069V40.7188H114.696L114.675 40.8447C112.483 52.85 110.547 63.3099 109.523 68.7394V68.7855H109.476L106.172 68.752H105.332H105.055H105.353H106.214L109.481 68.7226L109.43 68.7646C110.388 63.3057 112.277 52.8542 114.469 40.8531L114.578 40.9833H110.089L110.194 40.8909C108.763 48.7558 107.494 55.7095 106.579 60.7107C106.117 63.2049 105.739 65.2163 105.487 66.6104L105.185 68.2103C105.151 68.3866 105.126 68.5252 105.105 68.6302L105.071 68.7394Z"
                    fill="#263238"
                ></path>
                <path
                    d="M97.7975 68.7378C97.8007 68.6673 97.8091 68.5971 97.8227 68.5278C97.8521 68.364 97.8856 68.1583 97.9276 67.8979C98.0242 67.3185 98.1628 66.5122 98.3476 65.5087C98.7129 63.4301 99.221 60.5453 99.8046 57.2112V57.123H104.529L104.503 57.2532C103.634 61.7882 102.853 65.8698 102.299 68.7546V68.8176H102.24L98.9648 68.7756L98.0788 68.7378C97.9823 68.7438 97.8855 68.7438 97.7891 68.7378H98.1082H98.9942L102.219 68.6958L102.144 68.7546C102.681 65.8698 103.441 61.7799 104.281 57.2406L104.39 57.3708H99.8928L99.9936 57.2868C99.3721 60.5831 98.8388 63.4343 98.4483 65.4877C98.2552 66.4955 98.0956 67.3101 97.9822 67.8937C97.9276 68.1583 97.8856 68.3766 97.852 68.5404C97.8377 68.6072 97.8195 68.6731 97.7975 68.7378Z"
                    fill="#263238"
                ></path>
                <path
                    d="M90.5168 68.7383C90.5168 68.7383 90.5168 68.709 90.5168 68.6502C90.5168 68.5914 90.5168 68.4864 90.5546 68.3772C90.5966 68.1211 90.6512 67.7683 90.7226 67.3148C90.8779 66.37 91.0963 65.0179 91.3692 63.3383C91.9403 59.9202 92.7297 55.1668 93.6325 49.7542V49.666H98.3607L98.3355 49.7962C96.9498 57.6191 95.7279 64.535 94.9762 68.7509V68.8055H94.9259L91.6422 68.7677H90.7813C90.6877 68.7738 90.5937 68.7738 90.5 68.7677H90.5798H90.8065H91.6799L94.9259 68.7341L94.8629 68.7845L98.1298 49.813L98.239 49.9432H93.7585L93.8635 49.855C92.9271 55.2298 92.1041 59.9328 91.512 63.3383C91.2096 65.0179 90.9703 66.3826 90.7981 67.3358C90.7142 67.7935 90.6512 68.1757 90.6008 68.415L90.5462 68.7006L90.5168 68.7383Z"
                    fill="#263238"
                ></path>
                <path
                    d="M83.2541 68.7396C83.2486 68.6992 83.2486 68.6583 83.2541 68.6178C83.2541 68.5255 83.2877 68.4037 83.3129 68.2483C83.3717 67.9082 83.4515 67.4085 83.5564 66.8038C83.7706 65.5441 84.0855 63.7007 84.4718 61.4248C85.2697 56.8268 86.3824 50.4273 87.6422 43.1839V43.0957H92.3704L92.3494 43.2259C90.3926 54.11 88.6625 63.6251 87.7094 68.7522V68.7984H87.6632L84.3669 68.7648H83.5271H83.3255H83.548H84.4089L87.6716 68.7312L87.617 68.7774C88.5072 63.6377 90.1952 54.1141 92.152 43.2343L92.257 43.3602H87.7597L87.8647 43.2721C86.5756 50.4777 85.4334 56.8394 84.6146 61.4122C84.1947 63.6965 83.863 65.5315 83.6278 66.808C83.5145 67.4379 83.4221 67.925 83.3591 68.2735C83.3255 68.4331 83.3045 68.5591 83.2835 68.6556C83.2625 68.7522 83.2541 68.7396 83.2541 68.7396Z"
                    fill="#263238"
                ></path>
                <path
                    d="M80.09 35.1756C80.0908 35.2812 80.0603 35.3847 80.0022 35.473C79.9441 35.5612 79.8611 35.6302 79.7637 35.6712C79.6663 35.7123 79.559 35.7234 79.4553 35.7033C79.3515 35.6832 79.2561 35.6328 79.1811 35.5583C79.1061 35.4839 79.0549 35.3889 79.034 35.2854C79.0131 35.1818 79.0234 35.0744 79.0636 34.9767C79.1039 34.879 79.1722 34.7954 79.26 34.7367C79.3478 34.6779 79.4511 34.6465 79.5567 34.6465C79.6974 34.6465 79.8324 34.7021 79.9323 34.8012C80.0322 34.9003 80.0889 35.0349 80.09 35.1756Z"
                    fill="#263238"
                ></path>
                <path
                    d="M82.1697 35.7047C82.4619 35.7047 82.6988 35.4678 82.6988 35.1756C82.6988 34.8834 82.4619 34.6465 82.1697 34.6465C81.8775 34.6465 81.6406 34.8834 81.6406 35.1756C81.6406 35.4678 81.8775 35.7047 82.1697 35.7047Z"
                    fill="#263238"
                ></path>
                <path
                    d="M85.3087 35.1756C85.3096 35.2814 85.2789 35.3851 85.2206 35.4734C85.1623 35.5618 85.0791 35.6308 84.9815 35.6717C84.8839 35.7125 84.7763 35.7235 84.6725 35.703C84.5686 35.6826 84.4732 35.6317 84.3984 35.5569C84.3236 35.482 84.2727 35.3866 84.2522 35.2828C84.2318 35.1789 84.2427 35.0714 84.2836 34.9738C84.3245 34.8761 84.3935 34.7929 84.4818 34.7346C84.5701 34.6763 84.6738 34.6457 84.7797 34.6465C84.9196 34.6476 85.0536 34.7037 85.1526 34.8027C85.2515 34.9017 85.3076 35.0356 85.3087 35.1756Z"
                    fill="#263238"
                ></path>
                <path
                    d="M75.9844 68.7403C75.9844 68.7403 75.9844 68.6311 76.0306 68.4169C76.0768 68.2028 76.1187 67.8668 76.1859 67.4637C76.3329 66.6239 76.5387 65.3936 76.7948 63.8861C77.3323 60.8039 78.0755 56.5209 78.9279 51.6331V51.5449H83.6561L83.6309 51.6751C82.3334 58.6708 81.1787 64.8771 80.4606 68.7571V68.8116H80.4102L77.1223 68.7738H76.2825C76.1874 68.7798 76.0921 68.7798 75.997 68.7738H76.081H76.3077H77.1811L80.427 68.736L80.3598 68.7906L83.4462 51.6961L83.5512 51.8221H79.0329L79.1379 51.7339C78.2519 56.5838 77.4583 60.8333 76.9208 63.8861C76.6352 65.402 76.4085 66.6239 76.2447 67.4805C76.1691 67.9004 76.1061 68.2196 76.06 68.4547L76.0096 68.7109C76.0016 68.721 75.9932 68.7308 75.9844 68.7403Z"
                    fill="#263238"
                ></path>
                <path
                    d="M125.062 51.6529H101.094L102.463 43.6074H126.599L125.062 51.6529Z"
                    fill="#D80027"
                ></path>
                <path
                    d="M101.099 51.6541L100.633 54.4633C100.633 54.4633 103.782 51.5449 103.732 51.5449H101.12"
                    fill="#D80027"
                ></path>
                <path
                    d="M122.99 46.4021C122.99 46.4609 118.996 46.5113 114.075 46.5113C109.154 46.5113 105.156 46.4609 105.156 46.4021C105.156 46.3434 109.15 46.293 114.075 46.293C119.001 46.293 122.99 46.3434 122.99 46.4021Z"
                    fill="#FAFAFA"
                ></path>
                <path
                    d="M122.755 48.3494C122.755 48.4082 118.762 48.4586 113.841 48.4586C108.919 48.4586 104.922 48.4082 104.922 48.3494C104.922 48.2906 108.915 48.2402 113.841 48.2402C118.766 48.2402 122.755 48.2864 122.755 48.3494Z"
                    fill="#FAFAFA"
                ></path>
                <path
                    d="M161.958 89.0449H93.6719V128.89H161.958V89.0449Z"
                    fill="#263238"
                ></path>
                <path
                    d="M124.779 89.0449H93.6719V128.89H124.779V89.0449Z"
                    fill="#455A64"
                ></path>
                <path
                    d="M121.589 102.576C121.589 102.576 121.589 102.505 121.589 102.375C121.589 102.245 121.589 102.043 121.589 101.795C121.589 101.279 121.589 100.536 121.568 99.5825C121.568 97.6677 121.547 94.9089 121.526 91.5076L121.605 91.5874L96.5829 91.6168C96.4863 91.7176 96.7131 91.4866 96.6921 91.5118V94.3924C96.6921 95.3456 96.6921 96.2862 96.6921 97.21C96.6921 99.0618 96.6921 100.859 96.6921 102.576L96.5913 102.476L114.526 102.522L119.728 102.551H121.122H121.488H121.614C121.576 102.555 121.538 102.555 121.5 102.551H121.148H119.775L114.597 102.581L96.5913 102.631H96.4863V102.526C96.4863 100.809 96.4863 99.0114 96.4863 97.1596V91.5118C96.4611 91.5118 96.6921 91.3019 96.5955 91.3984L121.589 91.4152H121.668V91.4908C121.668 94.9131 121.635 97.6845 121.626 99.6161C121.626 100.561 121.626 101.296 121.605 101.808C121.605 102.047 121.605 102.228 121.605 102.375C121.605 102.442 121.599 102.51 121.589 102.576Z"
                    fill="#263238"
                ></path>
                <path
                    d="M110.152 97.0442C110.152 97.0442 110.127 96.9518 110.076 96.7965C110.006 96.5817 109.869 96.3949 109.686 96.2632C109.554 96.1673 109.399 96.1059 109.237 96.0847C109.075 96.0634 108.91 96.0829 108.758 96.1414C108.573 96.2102 108.414 96.3337 108.302 96.4955C108.19 96.6572 108.13 96.8494 108.13 97.0463C108.13 97.2432 108.19 97.4354 108.302 97.5971C108.414 97.7589 108.573 97.8824 108.758 97.9512C108.911 98.0075 109.075 98.0258 109.237 98.0046C109.398 97.9834 109.553 97.9233 109.686 97.8294C109.869 97.6977 110.006 97.5109 110.076 97.2961C110.127 97.1408 110.131 97.0442 110.152 97.0442C110.173 97.0442 110.186 97.1408 110.152 97.3171C110.103 97.5669 109.968 97.7915 109.77 97.9512C109.62 98.0731 109.441 98.1545 109.251 98.1876C109.06 98.2207 108.865 98.2043 108.682 98.1402C108.455 98.0608 108.259 97.9129 108.12 97.7169C107.981 97.5209 107.906 97.2866 107.906 97.0463C107.906 96.806 107.981 96.5717 108.12 96.3757C108.259 96.1797 108.455 96.0318 108.682 95.9524C108.864 95.8889 109.06 95.8725 109.25 95.9048C109.44 95.9371 109.619 96.0171 109.77 96.1372C109.969 96.2982 110.104 96.5243 110.152 96.7755C110.186 96.9518 110.165 97.0484 110.152 97.0442Z"
                    fill="#263238"
                ></path>
                <path
                    d="M121.587 114.763C121.587 114.763 121.587 114.692 121.587 114.562C121.587 114.432 121.587 114.226 121.587 113.978C121.587 113.462 121.587 112.718 121.566 111.769C121.566 109.85 121.545 107.096 121.524 103.695L121.603 103.774L96.581 103.804L96.6901 103.695V106.571C96.6901 107.524 96.6901 108.461 96.6901 109.389C96.6901 111.24 96.6901 113.033 96.6901 114.755L96.5894 114.65L114.524 114.7L119.726 114.73H121.121H121.486C121.528 114.726 121.57 114.726 121.612 114.73H121.498H121.146H119.773L114.595 114.759L96.5894 114.81H96.4844V114.705C96.4844 112.983 96.4844 111.19 96.4844 109.338V103.703L96.5936 103.594L121.616 103.623H121.696V103.703C121.696 107.125 121.662 109.897 121.654 111.824C121.654 112.769 121.654 113.504 121.633 114.02C121.633 114.259 121.633 114.44 121.633 114.583C121.621 114.644 121.606 114.704 121.587 114.763Z"
                    fill="#263238"
                ></path>
                <path
                    d="M110.152 109.23C110.152 109.23 110.127 109.134 110.076 108.978C110.006 108.764 109.869 108.577 109.686 108.445C109.553 108.351 109.398 108.291 109.237 108.27C109.075 108.249 108.911 108.267 108.758 108.323C108.573 108.392 108.414 108.516 108.302 108.677C108.19 108.839 108.13 109.031 108.13 109.228C108.13 109.425 108.19 109.617 108.302 109.779C108.414 109.941 108.573 110.064 108.758 110.133C108.91 110.191 109.075 110.211 109.237 110.19C109.398 110.17 109.553 110.11 109.686 110.016C109.871 109.883 110.008 109.695 110.076 109.478C110.127 109.323 110.131 109.23 110.152 109.23C110.173 109.23 110.186 109.327 110.152 109.499C110.105 109.751 109.969 109.977 109.77 110.137C109.619 110.259 109.441 110.339 109.25 110.372C109.06 110.404 108.864 110.387 108.682 110.322C108.455 110.243 108.259 110.095 108.12 109.899C107.981 109.703 107.906 109.469 107.906 109.228C107.906 108.988 107.981 108.754 108.12 108.558C108.259 108.362 108.455 108.214 108.682 108.134C108.865 108.07 109.06 108.054 109.251 108.087C109.441 108.12 109.62 108.201 109.77 108.323C109.969 108.484 110.105 108.71 110.152 108.962C110.186 109.134 110.165 109.23 110.152 109.23Z"
                    fill="#263238"
                ></path>
                <path
                    d="M121.589 126.946C121.589 126.946 121.589 126.874 121.589 126.744C121.589 126.614 121.589 126.413 121.589 126.165C121.589 125.648 121.589 124.905 121.568 123.952C121.568 122.037 121.547 119.278 121.526 115.877L121.605 115.957L96.5829 115.986C96.4863 116.087 96.7131 115.856 96.6921 115.881V118.762C96.6921 119.715 96.6921 120.656 96.6921 121.579C96.6921 123.431 96.6921 125.228 96.6921 126.946L96.5913 126.845L114.526 126.891L119.728 126.921H121.122H121.488H121.614C121.576 126.925 121.538 126.925 121.5 126.921H121.148H119.775L114.597 126.946L96.5913 126.996H96.4863V126.891C96.4863 125.174 96.4863 123.377 96.4863 121.525V115.881C96.4611 115.902 96.6921 115.671 96.5955 115.768L121.618 115.801H121.698V115.877C121.698 119.299 121.664 122.075 121.656 124.002C121.656 124.947 121.656 125.682 121.635 126.194C121.635 126.438 121.635 126.614 121.635 126.761C121.624 126.824 121.608 126.885 121.589 126.946Z"
                    fill="#263238"
                ></path>
                <path
                    d="M110.152 121.411C110.152 121.411 110.127 121.319 110.076 121.164C110.006 120.949 109.869 120.762 109.686 120.63C109.553 120.536 109.399 120.475 109.237 120.454C109.075 120.432 108.911 120.451 108.758 120.509C108.573 120.577 108.414 120.701 108.302 120.863C108.19 121.024 108.13 121.217 108.13 121.413C108.13 121.61 108.19 121.803 108.302 121.964C108.414 122.126 108.573 122.25 108.758 122.318C108.911 122.376 109.075 122.395 109.237 122.373C109.399 122.352 109.553 122.291 109.686 122.197C109.869 122.065 110.006 121.878 110.076 121.663C110.127 121.508 110.131 121.411 110.152 121.411C110.173 121.411 110.186 121.508 110.152 121.684C110.103 121.935 109.968 122.161 109.77 122.323C109.619 122.443 109.44 122.523 109.25 122.555C109.06 122.587 108.864 122.571 108.682 122.507C108.455 122.428 108.259 122.28 108.12 122.084C107.981 121.888 107.906 121.654 107.906 121.413C107.906 121.173 107.981 120.939 108.12 120.743C108.259 120.547 108.455 120.399 108.682 120.32C108.864 120.256 109.06 120.24 109.25 120.272C109.44 120.304 109.619 120.384 109.77 120.504C109.969 120.665 110.104 120.891 110.152 121.143C110.186 121.319 110.165 121.416 110.152 121.411Z"
                    fill="#263238"
                ></path>
                <path
                    d="M59.9864 89.0449H2.54688V128.89H59.9864V89.0449Z"
                    fill="#263238"
                ></path>
                <path
                    d="M164.424 86.2637H0.34375V90.0513H164.424V86.2637Z"
                    fill="#263238"
                ></path>
                <path
                    d="M7.63619 90.0527H2.54688V128.89H7.63619V90.0527Z"
                    fill="#455A64"
                ></path>
                <path
                    d="M66.3352 26.3286C66.3014 26.1693 66.2775 26.008 66.2638 25.8457C66.2027 25.4141 66.0602 24.998 65.8439 24.6196C65.7037 24.3661 65.4899 24.1612 65.2308 24.0317C65.0848 23.969 64.9234 23.951 64.7671 23.9802C64.6108 24.0094 64.4668 24.0843 64.3532 24.1955C63.8668 24.7993 63.6016 25.5512 63.6016 26.3265C63.6016 27.1018 63.8668 27.8538 64.3532 28.4576C64.4663 28.5695 64.6102 28.6452 64.7664 28.6751C64.9227 28.705 65.0844 28.6878 65.2308 28.6255C65.4893 28.4933 65.7027 28.2872 65.8439 28.0335C66.0602 27.655 66.2027 27.2389 66.2638 26.8073C66.2772 26.6464 66.3011 26.4865 66.3352 26.3286C66.3624 26.4913 66.3624 26.6573 66.3352 26.8199C66.3071 27.2722 66.1825 27.7132 65.9698 28.1132C65.818 28.4018 65.5823 28.6375 65.2938 28.7893C65.1228 28.8712 64.9309 28.8991 64.7437 28.8691C64.5482 28.8308 64.3659 28.7425 64.2146 28.6129C63.6726 27.9752 63.375 27.1656 63.375 26.3286C63.375 25.4917 63.6726 24.682 64.2146 24.0443C64.3646 23.9119 64.5473 23.822 64.7437 23.784C64.9309 23.7561 65.1222 23.7838 65.2938 23.8638C65.5823 24.0156 65.818 24.2513 65.9698 24.5398C66.1831 24.9412 66.3077 25.3837 66.3352 25.8373C66.3621 26 66.3621 26.166 66.3352 26.3286Z"
                    fill="#263238"
                ></path>
                <path
                    d="M48.2129 18.1165L46.9531 43.2776L57.7616 44.2937L58.3579 38.1841C58.3579 38.1841 62.1371 39.1247 63.3128 35.61C64.0477 33.3635 64.7027 15.7734 64.7027 15.7734L48.2129 18.1165Z"
                    fill="#FFBE9D"
                ></path>
                <path
                    d="M67.0027 18.3702C66.0831 20.5579 62.6147 22.4349 62.6147 22.4349L62.0478 28.0071C61.5319 27.9715 61.0142 27.9715 60.4983 28.0071C60.7755 25.3911 57.412 25.9076 57.0677 27.6838C56.7401 29.397 58.6927 30.5434 58.6927 30.5434C57.3448 32.6093 56.7989 33.7011 54.8841 35.6369C52.8182 37.7364 51.7054 37.9044 49.5681 37.8414L47.2124 37.7322C47.2124 37.7322 46.3726 35.7209 45.7637 30.9717C45.4655 28.6874 43.6641 25.6934 44.189 21.9772C45.6335 11.4249 57.3112 13.2977 57.3112 13.2977C61.6951 9.88798 69.1695 13.2263 67.0027 18.3702Z"
                    fill="#263238"
                ></path>
                <path
                    d="M66.3379 26.3574C65.5408 26.511 64.7311 26.5897 63.9193 26.5925C63.1118 26.6642 62.2996 26.6642 61.4922 26.5925C62.2882 26.4414 63.0965 26.3641 63.9067 26.3616C64.7154 26.2898 65.5289 26.2883 66.3379 26.3574Z"
                    fill="#263238"
                ></path>
                <path
                    d="M100.768 55.9145L96.8423 58.3457L95.402 61.705V58.7656C95.402 58.7656 96.4686 57.5059 97.342 56.5863C98.2154 55.6667 97.5897 55.0284 97.342 54.7135C97.0942 54.3986 93.3361 58.7656 93.3361 58.7656V61.2851C91.585 59.131 90.7746 59.87 90.5058 60.2605C90.2371 60.651 93.3361 63.5736 93.3361 63.5736V64.4638L77.9379 72.7445L73.8858 62.6666L65.6094 67.0127L70.5517 78.9759C70.8925 79.8035 71.4073 80.5482 72.0613 81.1593C72.7152 81.7704 73.493 82.2336 74.3417 82.5176C75.1905 82.8016 76.0905 82.8997 76.9805 82.8052C77.8705 82.7107 78.7298 82.4258 79.5 81.9699L97.6191 71.258L98.8243 69.2466C99.2426 68.5534 99.4889 67.7701 99.5423 66.9623L100.168 57.9132C101.268 57.2246 100.768 55.9145 100.768 55.9145Z"
                    fill="#FFBE9D"
                ></path>
                <path
                    d="M78.0201 72.7578C77.6542 74.1748 77.1449 75.5508 76.5 76.8645C76.8709 75.4491 77.38 74.0736 78.0201 72.7578Z"
                    fill="#EB996E"
                ></path>
                <path
                    d="M100.169 57.9141C99.9369 58.0334 99.6985 58.14 99.4549 58.2332C99.177 58.3649 98.929 58.552 98.7261 58.7831C98.5231 59.0142 98.3696 59.2843 98.275 59.5769C97.9611 60.4225 97.5791 61.2412 97.1328 62.025C97.3789 61.1615 97.6935 60.319 98.0734 59.5055C98.1962 59.1619 98.3909 58.8484 98.6445 58.5859C98.8621 58.3838 99.1137 58.2217 99.3877 58.1072C99.6299 57.9841 99.8971 57.918 100.169 57.9141Z"
                    fill="#EB996E"
                ></path>
                <path
                    d="M100.111 59.3476C100.042 59.3567 99.9773 59.3811 99.9198 59.4189C99.8624 59.4567 99.8142 59.5069 99.7789 59.5659C99.6571 59.8011 99.5017 60.1706 99.359 60.5653C99.2012 61.0649 99.0161 61.5556 98.8047 62.035C98.8053 61.4991 98.9243 60.97 99.1532 60.4855C99.2889 60.1321 99.462 59.7942 99.6697 59.4777C99.7077 59.4251 99.7567 59.3813 99.8132 59.3494C99.8698 59.3175 99.9325 59.2983 99.9972 59.293C100.077 59.293 100.115 59.335 100.111 59.3476Z"
                    fill="#EB996E"
                ></path>
                <path
                    d="M63.1426 65.4818L64.902 71.4403L76.0674 66.4014C75.6475 65.1417 73.8167 59.5946 70.2852 50.7765C69.3182 48.3587 67.7453 46.2304 65.7178 44.5963C63.6903 42.9623 61.2764 41.8774 58.7083 41.4461C55.0551 40.8373 51.0785 40.3628 48.0174 40.4005C44.9783 40.4288 42.0409 41.4992 39.6961 43.4328C37.3513 45.3664 35.7411 48.0462 35.1346 51.0243C34.1898 55.7063 35.8442 58.801 34.7776 69.0468L34.4375 94.8167L64.2511 95.9127L63.1426 65.4818Z"
                    fill="#D80027"
                ></path>
                <path
                    d="M36.1644 68.7695C36.1644 68.7695 32.0199 69.0131 30.3906 65.8763C30.3906 65.8763 34.8543 49.3739 36.4121 47.4297L36.1644 68.7695Z"
                    fill="#D80027"
                ></path>
                <path
                    d="M70.8459 52.4367C70.7391 52.5191 70.6201 52.5843 70.4932 52.6298C70.128 52.7624 69.7436 52.8347 69.3552 52.844C68.7448 52.8409 68.137 52.7634 67.5454 52.613C66.8567 52.4577 66.0883 52.2267 65.2275 52.084C64.7789 52.0298 64.3272 52.0059 63.8754 52.0126C63.3866 52.0513 62.902 52.1314 62.4267 52.2519C61.3723 52.5384 60.3601 52.9619 59.4159 53.5116C58.3956 54.0659 57.3584 54.721 56.254 55.3677C55.1208 56.0709 53.9069 56.6348 52.6386 57.0473C51.3354 57.4511 49.9437 57.4729 48.6284 57.1103C47.4356 56.7243 46.324 56.1222 45.3489 55.3341C44.4125 54.6076 43.5979 53.8434 42.8295 53.1463C42.1287 52.493 41.3852 51.887 40.604 51.3323C39.4918 50.5011 38.1953 49.9508 36.8248 49.7283C36.3185 49.6998 35.811 49.6998 35.3047 49.7283C35.4285 49.678 35.5584 49.6441 35.691 49.6275C36.0713 49.5669 36.4572 49.5486 36.8416 49.5729C38.257 49.7372 39.607 50.2605 40.7635 51.093C41.5702 51.6374 42.3364 52.2394 43.0562 52.8944C43.8373 53.583 44.6603 54.3305 45.5757 55.0275C46.5169 55.7731 47.5853 56.3422 48.7292 56.7072C49.9703 57.0416 51.2808 57.0168 52.5084 56.6358C53.7418 56.2326 54.9241 55.6872 56.0315 55.0107C57.1274 54.3767 58.173 53.7216 59.2144 53.1673C60.1939 52.6109 61.2431 52.1873 62.3343 51.9076C62.8335 51.7882 63.3421 51.7123 63.8544 51.6808C64.328 51.6859 64.8007 51.7223 65.2695 51.79C66.1597 51.958 66.9197 52.2099 67.5958 52.3863C68.1663 52.5508 68.7538 52.6494 69.3468 52.6802C69.8561 52.6774 70.3619 52.5952 70.8459 52.4367Z"
                    fill="#263238"
                ></path>
                <path
                    d="M63.3372 73.0014C62.768 72.6624 62.1396 72.4344 61.4854 72.3296C60.7729 72.2454 60.0512 72.2852 59.3523 72.4472C58.5544 72.6067 57.6936 72.8965 56.7488 73.1316C55.1275 73.5484 53.4301 73.5715 51.7981 73.1988C50.5383 72.9049 49.8623 72.506 49.8833 72.464C49.9043 72.422 50.6349 72.6865 51.8611 72.8839C53.4511 73.1545 55.0801 73.0901 56.6438 72.6949C57.5634 72.464 58.4411 72.1826 59.2767 72.0357C60.0214 71.8851 60.7879 71.8766 61.5358 72.0105C62.0421 72.1005 62.5233 72.2981 62.9467 72.5899C63.2406 72.8209 63.3582 72.9847 63.3372 73.0014Z"
                    fill="#263238"
                ></path>
                <path
                    d="M63.6347 88.3852C63.6347 88.3852 63.5213 88.5741 63.24 88.8387C62.8336 89.2104 62.3787 89.5254 61.8879 89.7751C61.1514 90.1574 60.3366 90.3644 59.507 90.3798C58.4942 90.3519 57.4963 90.1295 56.5676 89.7247C55.5892 89.3426 54.6948 88.9143 53.8508 88.6749C53.1048 88.4471 52.3238 88.356 51.5455 88.4062C51.0255 88.4374 50.5185 88.5807 50.059 88.8261C49.7315 89.0067 49.5803 89.1536 49.5509 89.1242C49.5215 89.0948 49.6517 88.9185 49.9708 88.7043C50.426 88.3744 50.9579 88.1663 51.5161 88.0996C52.3353 88.004 53.1652 88.0682 53.96 88.2886C54.9022 88.5814 55.8274 88.9263 56.7314 89.3216C57.607 89.7048 58.5442 89.9281 59.4986 89.9808C60.2735 89.9841 61.04 89.8194 61.7451 89.4979C62.9838 88.9395 63.5927 88.3222 63.6347 88.3852Z"
                    fill="#263238"
                ></path>
                <g opacity="0.3">
                    <path
                        d="M31.3047 69.0621C35.2099 69.8599 39.22 69.0621 43.1965 68.8186C45.611 68.6758 48.2859 68.3986 50.1922 69.8977C51.7039 71.0735 52.2918 73.0512 52.7495 74.9156C54.4291 82.0289 54.7189 88.3822 55.0926 95.676L35.7767 95.2561L31.3047 69.0621Z"
                        fill="black"
                    ></path>
                </g>
                <path
                    opacity="0.3"
                    d="M36.4122 55.6699L37.2478 67.7129H34.7031L36.4122 55.6699Z"
                    fill="black"
                ></path>
                <path
                    d="M41.8281 94.7988L80.1659 96.1551C83.1577 96.2603 85.9888 97.5351 88.0507 99.7053C90.1126 101.876 91.2408 104.768 91.1928 107.761L90.8568 129.517H76.7982L75.8912 110.76L41.8281 109.861V94.7988Z"
                    fill="#263238"
                ></path>
                <path
                    d="M54.6927 114.689H50.9219V128.492H54.6927V114.689Z"
                    fill="#455A64"
                ></path>
                <path
                    d="M19.2812 70.8372V104.094C19.2824 106.188 20.115 108.196 21.5962 109.676C23.0773 111.157 25.0857 111.988 27.1798 111.988H49.3258V70.8372C49.3247 69.9036 48.953 69.0086 48.2925 68.3488C47.6319 67.689 46.7364 67.3184 45.8028 67.3184H22.8001C21.8668 67.3184 20.9718 67.6891 20.3119 68.349C19.652 69.0089 19.2812 69.904 19.2812 70.8372Z"
                    fill="#455A64"
                ></path>
                <path
                    d="M79.1364 114.689H25.2031C25.2098 113.135 25.8321 111.646 26.9339 110.549C28.0356 109.452 29.5271 108.836 31.0819 108.836H73.3038C74.8563 108.836 76.3452 109.453 77.4429 110.55C78.5407 111.648 79.1574 113.137 79.1574 114.689H79.1364Z"
                    fill="#455A64"
                ></path>
                <path
                    d="M49.335 108.059C49.3595 108.387 49.3311 108.717 49.251 109.037C49.0702 109.786 48.676 110.466 48.1163 110.995C47.5565 111.524 46.8552 111.88 46.0975 112.018C45.7547 112.074 45.4075 112.098 45.0603 112.09H43.9728H39.127H30.3089L27.6425 112.056H26.916C26.8313 112.059 26.7466 112.05 26.6641 112.031C26.7474 112.018 26.8316 112.011 26.916 112.01L27.6425 111.989L30.3089 111.951L39.127 111.909H45.0603C45.3974 111.922 45.7349 111.902 46.0681 111.85C46.6577 111.749 47.2146 111.508 47.6932 111.149C48.4033 110.624 48.9165 109.876 49.1503 109.024C49.3266 108.411 49.3056 108.054 49.335 108.059Z"
                    fill="#263238"
                ></path>
                <path
                    d="M56.1058 114.802C55.0079 114.913 53.9038 114.949 52.8011 114.911C51.6969 114.949 50.5914 114.913 49.4922 114.802C50.5916 114.694 51.6969 114.657 52.8011 114.693C53.9038 114.657 55.0078 114.694 56.1058 114.802Z"
                    fill="#263238"
                ></path>
                <path
                    d="M72.5204 112.284C72.4683 112.149 72.4344 112.008 72.4197 111.864C72.3651 111.591 72.2937 111.196 72.2097 110.684C72.0375 109.655 71.7898 108.165 71.521 106.334C71.3699 105.418 71.2481 104.398 71.0465 103.336C70.992 103.071 70.9416 102.802 70.887 102.525C70.8324 102.248 70.7274 101.988 70.6476 101.711C70.6056 101.576 70.5678 101.433 70.5259 101.291C70.4839 101.148 70.4209 101.018 70.3663 100.871C70.2529 100.594 70.1521 100.312 70.0304 100.031C69.4972 98.8811 68.823 97.802 68.0232 96.8187C67.1498 95.7742 66.1334 94.8581 65.004 94.0977C63.8796 93.3282 62.6451 92.7335 61.3424 92.334C60.1334 91.9503 58.8772 91.7355 57.6094 91.6958C55.1655 91.6412 52.94 91.6538 51.084 91.6286C49.228 91.6034 47.7247 91.5824 46.6834 91.5572C46.1627 91.5572 45.7596 91.532 45.4824 91.5152C45.3412 91.5195 45.2 91.5054 45.0625 91.4732C45.1996 91.4379 45.341 91.4224 45.4824 91.427L46.6834 91.385C47.7205 91.3599 49.228 91.3389 51.084 91.3179C52.94 91.2969 55.1529 91.2591 57.622 91.2927C58.9291 91.3206 60.2256 91.5341 61.4726 91.9267C62.8188 92.3296 64.0939 92.9403 65.2518 93.7365C66.4183 94.5173 67.4651 95.4636 68.3591 96.5457C69.1776 97.5626 69.8647 98.6786 70.4041 99.8672C70.5259 100.153 70.6266 100.447 70.74 100.728C70.7904 100.871 70.845 101.009 70.8996 101.148C70.9542 101.287 70.9794 101.433 71.0214 101.568C71.1011 101.853 71.1767 102.131 71.2523 102.408C71.3279 102.685 71.3573 102.966 71.4077 103.248C71.5966 104.335 71.6974 105.347 71.8276 106.262C72.0669 108.106 72.2475 109.601 72.3777 110.634C72.4364 111.15 72.4826 111.553 72.5078 111.826C72.535 111.977 72.5392 112.132 72.5204 112.284Z"
                    fill="#455A64"
                ></path>
                <path
                    d="M4.44328 72.0391C4.44328 72.0391 3.76303 73.9413 5.06475 75.3186H7.77317C7.77317 75.3186 7.53382 72.2448 4.44328 72.0391Z"
                    fill="#D80027"
                ></path>
                <path
                    opacity="0.4"
                    d="M4.44328 72.0391C4.44328 72.0391 3.76303 73.9413 5.06475 75.3186H7.77317C7.77317 75.3186 7.53382 72.2448 4.44328 72.0391Z"
                    fill="black"
                ></path>
                <path
                    d="M9.06423 68.1228C8.95085 67.9884 6.63295 71.6164 7.93467 75.32H10.4877C10.4877 75.32 11.4871 71.0117 9.06423 68.1228Z"
                    fill="#D80027"
                ></path>
                <path
                    d="M13.5911 72.0391C12.7565 72.0951 11.9738 72.4639 11.3993 73.0719C10.8248 73.6798 10.5009 74.4822 10.4922 75.3186H13.041C13.041 75.3186 14.158 73.7481 13.5911 72.0391Z"
                    fill="#D80027"
                ></path>
                <path
                    d="M4.87012 75.1758C3.98961 75.8079 3.30668 76.677 2.9008 77.682C2.49492 78.6871 2.38277 79.7867 2.57741 80.853C3.23247 84.6322 7.22162 86.2614 7.22162 86.2614H11.6433C11.6433 86.2614 16.1447 84.2752 16.0355 80.1895C15.9264 76.1038 13.2473 75.2346 13.2473 75.2346L4.87012 75.1758Z"
                    fill="#455A64"
                ></path>
                <path
                    d="M16.0109 79.732C15.9107 79.5633 15.7878 79.4093 15.6455 79.2743C15.4399 79.1102 15.2037 78.9885 14.9507 78.9165C14.6977 78.8444 14.4329 78.8233 14.1716 78.8544C13.3896 79.0026 12.6507 79.324 12.0091 79.795C11.6016 80.0556 11.1699 80.2764 10.72 80.4542C10.2236 80.6431 9.686 80.6967 9.16212 80.6096C8.08295 80.4248 7.22213 79.8454 6.41591 79.4591C5.7299 79.0588 4.94139 78.869 4.14839 78.9132C3.61824 78.9681 3.12739 79.218 2.77109 79.6144C2.65202 79.7707 2.55063 79.9397 2.46875 80.1183C2.47156 80.0655 2.48144 80.0132 2.49814 79.963C2.54166 79.812 2.6113 79.6699 2.7039 79.543C3.056 79.1014 3.56692 78.8151 4.1274 78.7452C4.96112 78.6721 5.79708 78.8583 6.52088 79.2785C7.3607 79.6648 8.20053 80.2233 9.21251 80.3913C9.70051 80.4751 10.202 80.4273 10.6654 80.2527C11.1044 80.0817 11.5263 79.8694 11.9251 79.6186C12.5948 79.1433 13.3649 78.8286 14.1758 78.699C14.4556 78.6717 14.7379 78.7031 15.0047 78.7913C15.2716 78.8796 15.517 79.0226 15.7253 79.2113C15.8337 79.3178 15.9219 79.443 15.9857 79.5808C16.0067 79.6774 16.0193 79.7278 16.0109 79.732Z"
                    fill="#263238"
                ></path>
                <path
                    d="M16.07 80.7203C15.9693 80.5545 15.848 80.4021 15.7089 80.2668C15.5064 80.1016 15.2725 79.9792 15.0213 79.907C14.7702 79.8348 14.5069 79.8144 14.2476 79.8469C13.4742 79.9956 12.744 80.3156 12.1103 80.7833C11.7133 81.0455 11.2913 81.2677 10.8506 81.4467C10.3587 81.6361 9.82499 81.6898 9.30528 81.6021C8.23451 81.4173 7.3821 80.8379 6.58427 80.4516C5.90881 80.0518 5.1298 79.8618 4.34615 79.9057C3.81942 79.9632 3.33294 80.2146 2.98144 80.6111C2.86418 80.7655 2.76552 80.9333 2.6875 81.1108C2.6875 81.1108 2.6875 81.0562 2.71269 80.9554C2.75812 80.8125 2.82617 80.6779 2.91425 80.5565C3.26071 80.1161 3.76545 79.8283 4.32095 79.7545C5.14063 79.68 5.96331 79.8604 6.67665 80.271C7.49127 80.6573 8.35629 81.2158 9.33888 81.3837C9.82132 81.4676 10.3175 81.4197 10.775 81.2452C11.2075 81.0747 11.6224 80.8623 12.0137 80.6111C12.6755 80.1356 13.4389 79.8208 14.2434 79.6915C14.5172 79.6669 14.7931 79.6998 15.0534 79.788C15.3137 79.8762 15.5527 80.0178 15.7551 80.2038C15.8615 80.3094 15.9483 80.4331 16.0113 80.5691C16.0391 80.6159 16.059 80.667 16.07 80.7203Z"
                    fill="#263238"
                ></path>
                <path
                    d="M4.59081 80.6953C4.59081 80.6701 4.70839 80.5946 4.86375 80.7205C4.91071 80.761 4.94427 80.8148 4.96001 80.8748C4.97576 80.9348 4.97294 80.9981 4.95194 81.0565C4.9261 81.1283 4.87764 81.1898 4.81385 81.2318C4.75005 81.2738 4.67436 81.2939 4.59815 81.2892C4.52194 81.2844 4.44932 81.2551 4.39121 81.2056C4.3331 81.156 4.29263 81.089 4.27588 81.0145C4.26112 80.9569 4.26231 80.8964 4.27933 80.8394C4.29634 80.7825 4.32854 80.7312 4.37246 80.6911C4.52363 80.5652 4.6538 80.6239 4.6454 80.6491C4.637 80.6743 4.55302 80.6953 4.49003 80.7961C4.47644 80.8213 4.46932 80.8494 4.46932 80.878C4.46932 80.9066 4.47644 80.9347 4.49003 80.9599C4.50234 80.9893 4.52188 81.0151 4.54684 81.0349C4.5718 81.0548 4.60138 81.0679 4.6328 81.0733C4.66382 81.0721 4.69402 81.0631 4.7205 81.0469C4.74698 81.0307 4.76884 81.008 4.78397 80.9809C4.79803 80.9574 4.80546 80.9306 4.80546 80.9032C4.80546 80.8758 4.79803 80.849 4.78397 80.8255C4.69159 80.7289 4.59501 80.7247 4.59081 80.6953Z"
                    fill="#263238"
                ></path>
                <path
                    d="M9.59887 79.0977C9.59887 79.0725 9.71644 78.9927 9.87601 79.1229C9.9213 79.1645 9.95338 79.2186 9.96831 79.2783C9.98323 79.338 9.98034 79.4007 9.95999 79.4588C9.93349 79.5302 9.88511 79.5913 9.82176 79.6336C9.75842 79.6758 9.68334 79.6969 9.60727 79.6939C9.53137 79.6868 9.45955 79.6563 9.40166 79.6066C9.34378 79.557 9.30265 79.4907 9.28393 79.4168C9.2687 79.3589 9.27007 79.2978 9.28789 79.2407C9.30572 79.1835 9.33927 79.1325 9.38471 79.0935C9.53588 78.9675 9.66605 79.0263 9.65346 79.0515C9.64086 79.0767 9.56108 79.0977 9.49809 79.1985C9.4855 79.2239 9.47895 79.2519 9.47895 79.2803C9.47895 79.3087 9.4855 79.3368 9.49809 79.3622C9.51039 79.3916 9.52993 79.4174 9.5549 79.4373C9.57986 79.4571 9.60943 79.4703 9.64086 79.4756C9.67196 79.4749 9.70233 79.466 9.72888 79.4498C9.75543 79.4335 9.77722 79.4106 9.79202 79.3832C9.80654 79.3599 9.81424 79.333 9.81424 79.3055C9.81424 79.2781 9.80654 79.2512 9.79202 79.2279C9.69964 79.1313 9.60307 79.1271 9.59887 79.0977Z"
                    fill="#263238"
                ></path>
                <path
                    d="M14.4659 80.7676C14.4659 80.7424 14.5835 80.6668 14.743 80.7928C14.7883 80.8345 14.8204 80.8885 14.8353 80.9482C14.8503 81.0079 14.8474 81.0706 14.827 81.1287C14.8005 81.2001 14.7521 81.2613 14.6888 81.3035C14.6255 81.3457 14.5504 81.3669 14.4743 81.3639C14.3989 81.357 14.3275 81.3271 14.2697 81.2783C14.2119 81.2294 14.1704 81.1641 14.151 81.0909C14.1359 81.0324 14.1373 80.9707 14.1551 80.9129C14.1729 80.8551 14.2063 80.8034 14.2518 80.7634C14.4029 80.6374 14.5331 80.6962 14.5205 80.7214C14.5079 80.7466 14.4281 80.7718 14.3651 80.8684C14.3475 80.8932 14.3365 80.9223 14.3334 80.9526C14.3303 80.9829 14.3352 81.0136 14.3474 81.0415C14.3597 81.0694 14.379 81.0937 14.4034 81.1119C14.4279 81.1301 14.4566 81.1417 14.4869 81.1455C14.518 81.1448 14.5484 81.1359 14.5749 81.1197C14.6015 81.1035 14.6233 81.0805 14.6381 81.0531C14.6526 81.0298 14.6603 81.0029 14.6603 80.9755C14.6603 80.948 14.6526 80.9211 14.6381 80.8978C14.5667 80.8054 14.4743 80.797 14.4659 80.7676Z"
                    fill="#263238"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_15405_23072">
                    <rect
                        width="139.658"
                        height="129.035"
                        fill="white"
                        transform="translate(0.34375 0.482422)"
                    ></rect>
                </clipPath>
            </defs>
        </svg>
    );
};

export default SupportPageCustomerHelpIcon;
