import React from "react";
import { Button, COLOR, SIZE } from "../../../components/ui/buttons/Button";
import { useNavigate } from "react-router-dom";
import { noop } from "../../../utils/noop";

const TicketDetails = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col   gap-10 w-full max-w-[1173px]  mx-auto px-4 xl:px-0  ">
            {/* breadcrumbs */}
            <div className="flex overflow-hidden gap-2 items-center">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative w-6 h-6"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                        stroke="#292929"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M20.5019 12H3.67188"
                        stroke="#292929"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <p className=" text-base text-left text-[#7b7b7b]">Soporte</p>
                <p className=" text-base text-left text-[#7b7b7b]">/</p>
                <p className=" text-base text-left text-[#7b7b7b]">
                    Centro de ayuda para clientes
                </p>
                <p className=" text-base text-left text-[#7b7b7b]">/</p>
                <p className=" text-base text-left text-[#7b7b7b]">
                    Historial de tickets
                </p>
                <p className=" text-base text-left text-[#d80027]">/</p>
                <p className=" text-base text-left text-[#d80027]">
                    Detalle del ticket
                </p>
            </div>

            <div className="flex flex-col gap-6 items-center sm:flex-row sm:gap-10">
                <p className=" text-3xl sm:text-[40px] flex-grow font-bold text-left text-[#373737]">
                    Historial de tickets
                </p>
                <div className="flex flex-row-reverse gap-6 justify-start items-center">
                    <Button
                        color={COLOR.RED}
                        size={SIZE.MEDIUM}
                        type="submit"
                        addClass={"h-11 text-sm"}
                        onClick={() => navigate("../contact-us")}
                    >
                        Crear un nuevo ticket
                    </Button>
                    <p className=" text-base font-bold text-left text-[#d80027]">
                        Tickets (2)
                    </p>
                </div>
            </div>
            <p className="self-stretch  text-left text-[#373737]">
                <span className="self-stretch  text-[21px] font-bold text-left text-[#373737]">
                    Asunto:
                </span>
                <span className="self-stretch  text-[21px] text-left text-[#373737]">
                    El profesional no responde
                </span>
            </p>
            <div className="flex flex-col-reverse gap-6 items-start md:flex-row">
                <div className="flex flex-col items-start basis-[816px] gap-[30px] ">
                    <div className="flex flex-col  items-start w-full max-w-[816px]">
                        <div className="flex flex-col sm:flex-row  items-start self-stretch gap-y-2 relative justify-between p-4 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                            <div className="flex relative gap-3 items-start">
                                <div className=" w-[46px] h-[46px]">
                                    <div className="w-[46px] h-[46px] absolute left-0 top-0 overflow-hidden rounded-[112.2px] border-[2.24px] border-[#d9d9d9]">
                                        <img
                                            src="rectangle-1413-2.png"
                                            alt="rectangle-1413-2.png"
                                            className="w-[46px] h-[46px] absolute left-[-1.53px] top-[-1.53px] object-cover"
                                        />
                                    </div>
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-[34.21px] top-[2.81px]"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <circle
                                            cx="5.70027"
                                            cy="5.29206"
                                            r="4.48781"
                                            fill="#00BC4F"
                                            stroke="#F2F2F2"
                                            stroke-width="1.12195"
                                        ></circle>
                                    </svg>
                                </div>
                                <div className="flex relative flex-col gap-1 items-start">
                                    <p className="self-stretch  text-base font-bold text-left text-[#373737]">
                                        Cristian Lopez
                                    </p>
                                    <p className="self-stretch  text-sm text-left text-[#7b7b7b]">
                                        Cristianlopez@gmail.com
                                    </p>
                                </div>
                            </div>
                            <p className="text-sm text-left">
                                <span className=" text-sm text-left text-[#7b7b7b]">
                                    abrió este ticket
                                </span>
                                <br />
                                <span className=" text-sm font-bold text-left text-[#373737]">
                                    14 de agosto de 2023
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-col justify-center items-start self-stretch  gap-4 p-4 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                            <div className="flex relative flex-col gap-y-2 justify-between items-start self-stretch sm:flex-row">
                                <div className="flex relative gap-3 items-start">
                                    <div className=" w-[46px] h-[46px]">
                                        <div className="w-[46px] h-[46px] absolute left-0 top-0 overflow-hidden rounded-[112.2px] border-[2.24px] border-[#d9d9d9]">
                                            <img
                                                src="rectangle-1413-2.png"
                                                alt="rectangle-1413-2.png"
                                                className="w-[46px] h-[46px] absolute left-[-1.53px] top-[-1.53px] object-cover"
                                            />
                                        </div>
                                        <svg
                                            width="11"
                                            height="11"
                                            viewBox="0 0 11 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="absolute left-[34.21px] top-[2.81px]"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <circle
                                                cx="5.70027"
                                                cy="5.29206"
                                                r="4.48781"
                                                fill="#00BC4F"
                                                stroke="#F2F2F2"
                                                stroke-width="1.12195"
                                            ></circle>
                                        </svg>
                                    </div>
                                    <div className="flex relative flex-col gap-1 items-start">
                                        <p className="self-stretch  text-base font-bold text-left text-[#373737]">
                                            Cristian Lopez
                                        </p>
                                        <p className="self-stretch  text-sm text-left text-[#7b7b7b]">
                                            Cristianlopez@gmail.com
                                        </p>
                                    </div>
                                </div>
                                <p className=" text-sm text-left text-[#7b7b7b]">
                                    Hace 4 meses
                                </p>
                            </div>
                            <div className="flex justify-center items-start self-stretch  relative gap-2.5 sm:pl-[58px]">
                                <p className="flex-grow  text-sm text-left text-[#7b7b7b]">
                                    <span className="flex-grow  text-sm text-left text-[#7b7b7b]">
                                        Lorem ipsum dolor sit amet consectetur.
                                        Lacus blandit massa volutpat sed urna
                                        sed. Justo arcu orci tortor molestie
                                        faucibus lorem tortor eget. Nec
                                        imperdiet ac a et amet sapien dolor sem.
                                        Condimentum neque at arcu arcu at tortor
                                        aliquam quis facilisis. Mi ac cras
                                        cursus donec.
                                    </span>
                                    <br />
                                    <br />
                                    <span className="flex-grow  text-sm text-left text-[#7b7b7b]">
                                        Morbi eu rhoncus commodo in habitasse
                                        scelerisque. Ut maecenas facilisis nunc
                                        quis nulla. Nibh viverra id porta risus
                                        imperdiet fusce augue egestas enim.
                                        Tempus sed quis lorem ultrices faucibus
                                        lorem ut sagittis id. Sollicitudin dolor
                                        ut justo nulla sit viverra. Mauris purus
                                        faucibus in facilisi sodales donec
                                        iaculis eu. Ornare mauris orci sagittis
                                        pretium quis feugiat sit. Sit tristique
                                        nec nulla enim viverra. Euismod et
                                        suspendisse adipiscing urna lorem amet
                                        arcu scelerisque dictum. Nunc
                                        scelerisque vel sit id nec nibh. In
                                        donec hac at malesuada libero auctor
                                        nibh.
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-start self-stretch  gap-4 p-4 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                            <div className="flex relative flex-col gap-y-2 justify-between items-start self-stretch sm:flex-row">
                                <div className="flex relative gap-3 items-start">
                                    <div className=" w-[46px] h-[46px]">
                                        <div className="w-[46px] h-[46px] absolute left-0 top-0 overflow-hidden rounded-[112.2px] border-[2.24px] border-[#d9d9d9]">
                                            <img
                                                src="rectangle-1413.png"
                                                alt="rectangle-1413.png"
                                                className="w-[46px] h-[46px] absolute left-[-1.53px] top-[-1.53px] object-cover"
                                            />
                                        </div>
                                        <svg
                                            width="11"
                                            height="11"
                                            viewBox="0 0 11 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="absolute left-[34.21px] top-[2.81px]"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <circle
                                                cx="5.70027"
                                                cy="5.29206"
                                                r="4.48781"
                                                fill="#00BC4F"
                                                stroke="#F2F2F2"
                                                stroke-width="1.12195"
                                            ></circle>
                                        </svg>
                                    </div>
                                    <div className="flex relative flex-col gap-1 items-start">
                                        <p className="self-stretch   text-base font-bold text-left text-[#373737]">
                                            Pedro Sanchez
                                        </p>
                                        <p className="self-stretch   text-sm text-left text-[#7b7b7b]">
                                            Apoyo de soporte
                                        </p>
                                    </div>
                                </div>
                                <p className=" text-sm text-left text-[#7b7b7b]">
                                    Hace 4 meses
                                </p>
                            </div>
                            <div className="flex justify-center items-start self-stretch  relative gap-2.5 sm:pl-[58px]">
                                <p className="flex-grow  text-sm text-left text-[#7b7b7b]">
                                    <span className="flex-grow  text-sm text-left text-[#7b7b7b]">
                                        Lorem ipsum dolor sit amet consectetur.
                                        Lacus blandit massa volutpat sed urna
                                        sed. Justo arcu orci tortor molestie
                                        faucibus lorem tortor eget. Nec
                                        imperdiet ac a et amet sapien dolor sem.
                                        Condimentum neque at arcu arcu at tortor
                                        aliquam quis facilisis. Mi ac cras
                                        cursus donec.
                                    </span>
                                    <br />
                                    <br />
                                    <span className="flex-grow  text-sm text-left text-[#7b7b7b]">
                                        Morbi eu rhoncus commodo in habitasse
                                        scelerisque. Ut maecenas facilisis nunc
                                        quis nulla. Nibh viverra id porta risus
                                        imperdiet fusce augue egestas enim.
                                        Tempus sed quis lorem ultrices faucibus
                                        lorem ut sagittis id. Sollicitudin dolor
                                        ut justo nulla sit viverra. Mauris purus
                                        faucibus in facilisi sodales donec
                                        iaculis eu. Ornare mauris orci sagittis
                                        pretium quis feugiat sit. Sit tristique
                                        nec nulla enim viverra. Euismod et
                                        suspendisse adipiscing urna lorem amet
                                        arcu scelerisque dictum. Nunc
                                        scelerisque vel sit id nec nibh. In
                                        donec hac at malesuada libero auctor
                                        nibh.
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="flex  items-center self-stretch  relative gap-4 p-4 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                            <svg
                                width="46"
                                height="46"
                                viewBox="0 0 46 46"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=" w-[46px] h-[46px] relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M15.3385 32.1055H19.1719V29.2305H15.3385V32.1055ZM38.3385 19.168H36.4219C36.4219 16.0534 33.7865 13.418 30.6719 13.418H24.4427V9.10547C24.4427 8.32682 23.7839 7.66797 23.0052 7.66797C22.1667 7.66797 21.5677 8.32682 21.5677 9.10547V13.418H15.3385C12.1641 13.418 9.58854 16.0534 9.58854 19.168H7.67188C6.05469 19.168 4.79688 20.4857 4.79688 22.043V29.7096C4.79688 31.3268 6.05469 32.5846 7.67188 32.5846H9.58854C9.58854 35.7591 12.1641 38.3346 15.3385 38.3346H30.6719C33.7865 38.3346 36.4219 35.7591 36.4219 32.5846H38.3385C39.8958 32.5846 41.2135 31.3268 41.2135 29.7096V22.043C41.2135 20.4857 39.8958 19.168 38.3385 19.168ZM9.58854 29.7096H7.67188V22.043H9.58854V29.7096ZM33.5469 32.5846C33.487 34.2018 32.2292 35.4596 30.6719 35.4596H15.3385C13.7214 35.4596 12.4635 34.2018 12.4635 32.5846V19.168C12.4635 17.6107 13.7214 16.293 15.3385 16.293H30.6719C32.2292 16.293 33.5469 17.6107 33.5469 19.168V32.5846ZM38.3385 29.7096H36.4219V22.043H38.3385V29.7096ZM18.2135 20.1263C16.5964 20.1263 15.3385 21.444 15.3385 23.0013C15.3385 24.6185 16.5964 25.8763 18.2135 25.8763C19.7708 25.8763 21.0885 24.6185 21.0885 23.0013C21.0885 21.444 19.7708 20.1263 18.2135 20.1263ZM27.7969 20.1263C26.1797 20.1263 24.9219 21.444 24.9219 23.0013C24.9219 24.6185 26.1797 25.8763 27.7969 25.8763C29.3542 25.8763 30.6719 24.6185 30.6719 23.0013C30.6719 21.444 29.3542 20.1263 27.7969 20.1263ZM26.8385 32.1055H30.6719V29.2305H26.8385V32.1055ZM21.0885 32.1055H24.9219V29.2305H21.0885V32.1055Z"
                                    fill="#D80027"
                                ></path>
                            </svg>
                            <p className=" text-base text-left text-[#373737]">
                                Sistema de asistencia técnica marcó este
                                billete:
                            </p>
                            <p className="flex-grow  text-base font-bold text-left text-[#00bc4f]">
                                Resuelto
                            </p>
                            <p className=" text-sm text-left text-[#7b7b7b]">
                                Hace 4 meses
                            </p>
                        </div>
                        <div className="flex flex-col justify-center items-center w-full  relative gap-4 p-4 bg-[#f2f2f2]">
                            <p className="self-stretch   text-[21px] font-bold text-center text-[#373737]">
                                Este ticket ya está cerrado.
                            </p>
                            <p className="self-stretch   text-base text-center text-[#373737]">
                                Este ticket se cerró hace 3 meses (27 de agosto
                                de 2023).
                            </p>
                            <div className="flex justify-center items-center  w-full max-w-[141px] h-9 relative gap-2.5 px-4 py-2.5 rounded border-2 border-[#292929]">
                                <p className=" text-sm text-left text-[#373737]">
                                    Reabrir ticket
                                </p>
                            </div>
                        </div>
                        <div className="flex  items-center self-stretch  relative gap-[5px] p-2 rounded bg-[#f5f8ff]">
                            <p className="flex-grow  text-sm text-left text-[#373737]">
                                No puedes responder a este ticket porque se ha
                                resuelto.
                            </p>
                        </div>
                    </div>
                    <Button
                        color={COLOR.RED}
                        size={SIZE.MEDIUM}
                        type="submit"
                        addClass={"h-11 text-sm"}
                        onClick={noop}
                    >
                        Crear un nuevo ticket
                    </Button>
                </div>
                <div
                    className="flex flex-col   w-full basis-[333px] relative gap-4 p-5 bg-white"
                    style={{ boxShadow: "0px 0px 10px 0 rgba(0,0,0,0.1)" }}
                >
                    <div className="flex  items-center self-stretch  relative gap-2.5 pb-2 border-t-0 border-r-0 border-b border-l-0 border-[#e6e6e6]">
                        <p className="flex-grow text-base font-bold text-left text-[#373737]">
                            Información de entradas:
                        </p>
                    </div>
                    <p className="self-stretch   text-sm text-left text-[#373737]">
                        Agente asignado:
                    </p>
                    <div className="flex relative gap-3 items-start self-stretch">
                        <div className=" w-[46px] h-[46px]">
                            <div className="w-[46px] h-[46px] absolute left-0 top-0 overflow-hidden rounded-[112.2px] border-[2.24px] border-[#d9d9d9]">
                                <img
                                    src="rectangle-1413.png"
                                    alt="rectangle-1413.png"
                                    className="w-[46px] h-[46px] absolute left-[-1.53px] top-[-1.53px] object-cover"
                                />
                            </div>
                            <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute left-[34.21px] top-[2.81px]"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <circle
                                    cx="5.70027"
                                    cy="5.29206"
                                    r="4.48781"
                                    fill="#00BC4F"
                                    stroke="#F2F2F2"
                                    stroke-width="1.12195"
                                ></circle>
                            </svg>
                        </div>
                        <div className="flex relative flex-col gap-1 items-start">
                            <p className="self-stretch   text-base font-bold text-left text-[#373737]">
                                Pedro Sanchez
                            </p>
                            <p className="self-stretch   text-sm text-left text-[#7b7b7b]">
                                Apoyo de soporte
                            </p>
                        </div>
                    </div>
                    <p className="self-stretch   text-sm text-left text-[#373737]">
                        Entrada abierta por:
                    </p>
                    <div className="flex  items-start self-stretch  h-[46px] relative gap-3">
                        <div className=" w-[46px] h-[46px]">
                            <div className="w-[46px] h-[46px] absolute left-0 top-0 overflow-hidden rounded-[112.2px] border-[2.24px] border-[#d9d9d9]">
                                <img
                                    src="rectangle-1413-2.png"
                                    alt="rectangle-1413-2.png"
                                    className="w-[46px] h-[46px] absolute left-[-1.53px] top-[-1.53px] object-cover"
                                />
                            </div>
                            <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute left-[34.21px] top-[2.81px]"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <circle
                                    cx="5.70027"
                                    cy="5.29206"
                                    r="4.48781"
                                    fill="#00BC4F"
                                    stroke="#F2F2F2"
                                    stroke-width="1.12195"
                                ></circle>
                            </svg>
                        </div>
                        <div className="flex relative flex-col gap-1 items-start">
                            <p className="self-stretch  text-base font-bold text-left text-[#373737]">
                                Cristian Lopez
                            </p>
                            <p className="self-stretch  text-sm text-left text-[#7b7b7b]">
                                Cristianlopez@gmail.com
                            </p>
                        </div>
                    </div>
                    <p className="self-stretch   text-sm text-left text-[#373737]">
                        Referencia del billete:
                    </p>
                    <p className="self-stretch   text-sm font-bold text-left text-[#373737]">
                        1967710
                    </p>
                    <p className="self-stretch   text-sm text-left text-[#373737]">
                        Departamento:
                    </p>
                    <p className="self-stretch   text-sm font-bold text-left text-[#373737]">
                        General
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TicketDetails;
