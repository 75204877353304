import React from "react";
import { Button, SIZE } from "../../components/ui/buttons/Button";
/*import Dialog from "../../components/Dialog";*/
/* import CancelMembershipModal from "./CancelMembershipModal";*/
import error4044 from "../../assets/images/404/4044.png";
import { Link } from 'react-router-dom';

function Membership() {
     /* const [showDialog, setShowDialog] = useState(false);*/

    return (
        <div className="flex w-full flex-col items-center mt-[150px]" style={{ position:'relative', top: "-140px"}}  >
        <div style={{ fontSize:"40px", fontWeight:"700"}}>Página en construcción</div>
        <div  style={{ fontSize:"18px", fontWeight:"400", top:"10px", position:'relative'}}>Hola actualmente estamos trabajando en esta página, lo invitamos a regresar pronto.</div>
        <div className="  w-[63vw] h-[30vw] xl:w-[45vw]">
                           <img
                               src={error4044}
                               alt="error4044"
                               // xl:h-[75vh]
                               className={`bottom-[0%] right-[0%] object-contain xl:w-[95%]  `}
                           />
                       </div>
                       <div style={{ top:"35px" , position:'relative'}}>
                       <Link to="/platform/professional-dashboard">
                       <Button size={SIZE.LARGE} stretch  >
                          Regresar al tablero
                       </Button>
                       </Link>
                       </div>
       </div>
     /*<div>
            <Dialog
                main={
                    <CancelMembershipModal
                        closeDialog={() => setShowDialog(false)}
                    />
                }
                setDialog={setShowDialog}
                showDialog={showDialog}
            />
            <div className="flex flex-col justify-start items-start">
                <div className="flex flex-col justify-start items-start  w-full  lg:w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
                    <p className="flex-grow w-full  lg:w-[765px] text-[21px] font-bold text-left text-[#373737]">
                        Membresía
                    </p>
                </div>
                <div className="flex flex-col justify-start items-start  w-full  lg:w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
                    <p className=" w-full  lg:w-[765px] h-[25px] text-lg font-bold text-left text-[#373737]">
                        Plan actual
                    </p>
                    <div className="flex flex-col lg:flex-row  justify-start items-start self-stretch  gap-[22px]">
                        <div className="flex relative flex-col flex-grow gap-1 justify-start items-start">
                            <p className="self-stretch  w-full  lg:w-[371.5px] text-sm font-bold text-left text-[#373737]">
                                Plus
                            </p>
                            <p className="self-stretch  w-full  lg:w-[371.5px] text-sm text-left text-[#373737]">
                                $9.99 por mes
                            </p>
                            <div className="flex flex-col lg:flex-row  justify-start items-start py-5 lg:py-0  gap-[22px]">
                                <Button
                                    size={SIZE.MEDIUM}
                                    addClass={"h-11 w-full lg:w-auto"}
                                    color={COLOR.WHITE}
                                    text="Gestionar"
                                />
                                <Button
                                    size={SIZE.MEDIUM}
                                    addClass={"h-11 w-full lg:w-auto"}
                                    color={COLOR.WHITE}
                                    text="Cancelar"
                                    onClick={() => setShowDialog(true)}
                                />
                            </div>
                        </div>
                        <div className="flex relative flex-col flex-grow gap-1 justify-start items-start">
                            <p className="self-stretch  w-full  lg:w-[371.5px] text-sm font-bold text-left text-[#373737]">
                                Información de facturación
                            </p>
                            <p className="self-stretch  w-full  lg:w-[371.5px] text-sm text-left text-[#373737]">
                                Tu pago de $9.99 USDserá facturado el Dec 20,
                                2023.
                            </p>
                            <p className="self-stretch  w-full  lg:w-[371.5px] text-sm text-left text-[#d80027]">
                                Ver el historial de transacciones | Gestionar
                                formas de pago
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </div>*/

    );
}

export default Membership;
