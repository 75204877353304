import React from "react";
import map1 from "../../assets/images/landing/map1.png";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";

function LandingHowItWorks() {
    return (
        <div className="flex flex-col justify-start items-center relative gap-10 w-full px-[7%] my-[100px]">
            <div className="relative flex flex-col items-end justify-start w-auto gap-5">
                <p className="self-stretch  font-[Inter]  text-lg font-bold text-left text-[#7b7b7b] w-auto">
                    Así funciona
                </p>
                <p className="self-stretch font-[Satoshi]  text-[41px] sm:text-[61px] font-bold text-left text-[#373737] w-auto">
                    Siempre hay un profesional{" "}
                    <span className="relative">
                        cerca<>&nbsp;</>de<>&nbsp;</>ti
                        <svg
                            width={277}
                            height={24}
                            viewBox="0 0 277 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            // flex-grow-0 flex-shrink-0
                            className="absolute right-0 top-[88%] w-[200px] sm:w-auto"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M275.5 17.8566C257.5 4.85669 28 -12.1433 2 22.3566"
                                stroke="#D80027"
                                strokeWidth={3}
                                stroke-linecap="round"
                            />
                        </svg>
                    </span>
                </p>
                <p className="self-stretch  font-[Satoshi]   text-lg text-left text-[#7b7b7b] w-auto">
                    Si necesitas acompañamiento de un profesional calificado del
                    derecho estas en el lugar indicado, a través de Legal EPROF
                    contactaras con abogados cercanos a tu ubicación que ofrecen
                    sus servicios profesionales de manera presencial, filtra tu
                    búsqueda y encuentra al profesional indicado para tu
                    requerimiento. También puedes asesorarte inmediatamente con
                    los profesionales que se encuentren en línea o programar una
                    asesoría.
                </p>
                <div className="flex items-start justify-end gap-5">
                    <Button
                        size={SIZE.LARGE}
                        //  className="cursor-pointer hover:opacity-80 transition-all duration-500 flex justify-center items-center  w-[156px] h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]"
                    >
                        Contratar
                    </Button>

                    <Button
                        onClick={() =>
                            document
                                .getElementById("contactForm")
                                .scrollIntoView({
                                    behavior: "smooth"
                                })
                        }
                        size={SIZE.LARGE}
                        color={COLOR.WHITE}
                    >
                        ¡Asesórate ya!
                    </Button>
                </div>
            </div>
            <img
                src={map1}
                alt="mapa-mundo-1.png"
                className="md:h-[50vh]  object-contain"
            />
        </div>
    );
}

export default LandingHowItWorks;
