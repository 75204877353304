import React from "react";

import feature from "../../assets/images/landing/feature.png";

function Features() {
    return (
        <div className="flex items-center justify-start pr-[7%]  lg:my-[400px] flex-col lg:flex-row ">
            <div className="absolute  w-[35vw] h-full  -z-10 hidden lg:block" />
            <img
                src={feature}
                alt="joven-mujer-feliz-estrechandole-mano-agente-bienes-raices-mientras-su-esposo-usa-panel-tactil-oficina-1.jpeg"
                className="w-full lg:w-[45vw] object-contain"
            />
            <div className="flex flex-col justify-start items-start w-[90%] lg:w-[35vw] font-[Satoshi] cursor-default   gap-[37px] ml-0 lg:ml-[100px] mt-5 lg:mt-0">
                <div className="relative flex items-start self-stretch justify-start gap-6">
                    <div
                        // flex-grow-0 flex-shrink-0
                        className="flex justify-start items-start  relative gap-2.5 bg-[#d80027]"
                        style={{ boxShadow: "0px 8px 15px 0 rgba(0,0,0,0.15)" }}
                    >
                        <svg
                            width={100}
                            height={101}
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            // flex-grow-0 flex-shrink-0
                            className=" w-[50px] h-[50px] lg:w-[100px] lg:h-[100px] relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M50.0002 18.2083C32.1616 18.2083 17.7085 32.6614 17.7085 50.4999C17.7085 68.3385 32.1616 82.7916 50.0002 82.7916C67.8387 82.7916 82.2918 68.3385 82.2918 50.4999C82.2918 32.6614 67.8387 18.2083 50.0002 18.2083ZM50.0002 76.5416C35.547 76.5416 23.9585 64.953 23.9585 50.4999C23.9585 36.177 35.547 24.4583 50.0002 24.4583C64.3231 24.4583 76.0418 36.177 76.0418 50.4999C76.0418 64.953 64.3231 76.5416 50.0002 76.5416ZM57.9429 62.9999C58.7241 63.5208 59.6356 63.3905 60.1564 62.6093L62.6304 59.3541C63.1512 58.5728 63.021 57.6614 62.2397 57.1405L53.646 50.7603V32.2708C53.646 31.4895 52.8647 30.7083 52.0835 30.7083H47.9168C47.0054 30.7083 46.3543 31.4895 46.3543 32.2708V53.7551C46.3543 54.1458 46.4845 54.6666 46.8752 54.927L57.9429 62.9999Z"
                                fill="#F2F2F2"
                            />
                        </svg>
                    </div>
                    <p className="flex-grow w-[420px] text-[24px] lg:text-[34px] text-left text-[#373737]">
                        Servicio 24 horas del día y 365 días del año
                    </p>
                </div>
                <div className="relative flex items-start self-stretch justify-start gap-6">
                    <div
                        // flex-grow-0 flex-shrink-0
                        className="flex justify-start items-start  relative gap-2.5 bg-[#d80027]"
                        style={{ boxShadow: "0px 8px 15px 0 rgba(0,0,0,0.15)" }}
                    >
                        <svg
                            width={100}
                            height={101}
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            // flex-grow-0 flex-shrink-0
                            className=" w-[50px] h-[50px] lg:w-[100px] lg:h-[100px] relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M50.0002 54.6668C60.2866 54.6668 68.7502 46.3334 68.7502 35.9167C68.7502 25.6303 60.2866 17.1667 50.0002 17.1667C39.5835 17.1667 31.2502 25.6303 31.2502 35.9167C31.2502 46.3334 39.5835 54.6668 50.0002 54.6668ZM50.0002 23.4167C56.771 23.4167 62.5002 29.1459 62.5002 35.9167C62.5002 42.8178 56.771 48.4167 50.0002 48.4167C43.0991 48.4167 37.5002 42.8178 37.5002 35.9167C37.5002 29.1459 43.0991 23.4167 50.0002 23.4167ZM61.8491 56.8803L54.5575 76.9324L52.0835 66.1251L56.2502 58.8334H43.7502L47.9168 66.1251L45.3127 76.9324L38.021 56.8803C28.3856 57.0105 20.8335 64.6928 20.8335 74.3282V77.5834C20.8335 81.099 23.5679 83.8334 27.0835 83.8334H72.9168C76.3022 83.8334 79.1668 81.099 79.1668 77.5834V74.3282C79.1668 64.6928 71.4845 57.0105 61.8491 56.8803ZM33.3335 77.5834H27.0835V74.3282C27.0835 69.6407 29.8179 65.7345 33.9845 63.9115L38.9325 77.5834H33.3335ZM72.9168 77.5834H60.9377L66.0158 63.9115C70.0522 65.7345 72.9168 69.6407 72.9168 74.3282V77.5834Z"
                                fill="#F2F2F2"
                            />
                        </svg>
                    </div>
                    <p className="flex-grow w-[420px] text-[24px] lg:text-[34px] text-left text-[#373737]">
                        Más de 100 profesionales registrados
                    </p>
                </div>
                <div className="relative flex items-start self-stretch justify-start gap-6">
                    <div
                        // flex-grow-0 flex-shrink-0
                        className="flex justify-start items-start  relative gap-2.5 bg-[#d80027]"
                        style={{ boxShadow: "0px 8px 15px 0 rgba(0,0,0,0.15)" }}
                    >
                        <svg
                            width={100}
                            height={101}
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            // flex-grow-0 flex-shrink-0
                            className=" w-[50px] h-[50px] lg:w-[100px] lg:h-[100px] relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M77.0832 33.8333H66.6665V27.5833C66.6665 24.1978 63.8019 21.3333 60.4165 21.3333H39.5832C36.0675 21.3333 33.3332 24.1978 33.3332 27.5833V33.8333H22.9165C19.4009 33.8333 16.6665 36.6978 16.6665 40.0833V73.4166C16.6665 76.9322 19.4009 79.6666 22.9165 79.6666H77.0832C80.4686 79.6666 83.3332 76.9322 83.3332 73.4166V40.0833C83.3332 36.6978 80.4686 33.8333 77.0832 33.8333ZM39.5832 27.5833H60.4165V33.8333H39.5832V27.5833ZM22.9165 73.4166V40.0833H33.3332V73.4166H22.9165ZM39.5832 73.4166V40.0833H60.4165V73.4166H39.5832ZM77.0832 73.4166H66.6665V40.0833H77.0832V73.4166Z"
                                fill="#F2F2F2"
                            />
                        </svg>
                    </div>
                    <p className="flex-grow w-[420px] text-[24px] lg:text-[34px] text-left text-[#373737]">
                        Mas de 100 clientes atendidos
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Features;
