import { Button, COLOR, SIZE } from "./ui/buttons/Button";
import { useState } from "react";
import { FaLock } from 'react-icons/fa';

function Discount({
    closeDialog,
    title = "Elige tu tipo de Pago",
    header1 = "Pago al momento de recibir la Asesoria",
    header2 = "Pago Inmediato de Asesoría",
    set1 = [
        `Un profesional se comunicará contigo, brindarán la
        asesoría.`,
        `Consulta sin necesidad de registro`
    ],
    set2 = [
        `Regístrate y adquiere todos beneficios por estar
        registrado.`,
        <p>
            Asegura tu asesoría cancelando anticipadamente.
            <a href="/login" className=" text-[#d80027] font-bold">
                {` Recibes +15% `}
            </a>
            de descuento.
        </p>
    ],
    cost1 = null,
    cost2 = null,

    dHeader = "",
    dSubHeader = "",
    dText = "",
    discountPercent = ""
}) {
    const [enviarAnonimamente, setEnviarAnonimamente] = useState(false);

    const handleCheckboxChange = () => {
        setEnviarAnonimamente(!enviarAnonimamente);
    };

    return (
        <div
            className="flex flex-col mt-10 min-w-fit max-w-[600px] overflow-y-auto max-h-[100vh]  xl:w-[803px] h-full relative gap-5 pt-[30px] sm:p-[30px] bg-white"
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
        >

            {/* closeIcon */}
            <svg
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer w-10 h-10 absolute right-[10%] xl:right-[5px] top-[5px] z-10 "
                preserveAspectRatio="xMidYMid meet"
                onClick={closeDialog}
            >
                <path
                    d="M20.0002 7.0835C12.8647 7.0835 7.0835 12.8647 7.0835 20.0002C7.0835 27.1356 12.8647 32.9168 20.0002 32.9168C27.1356 32.9168 32.9168 27.1356 32.9168 20.0002C32.9168 12.8647 27.1356 7.0835 20.0002 7.0835ZM20.0002 30.4168C14.2189 30.4168 9.5835 25.7814 9.5835 20.0002C9.5835 14.271 14.2189 9.5835 20.0002 9.5835C25.7293 9.5835 30.4168 14.271 30.4168 20.0002C30.4168 25.7814 25.7293 30.4168 20.0002 30.4168ZM25.2606 16.771C25.521 16.5627 25.521 16.146 25.2606 15.8856L24.1147 14.7397C23.8543 14.4793 23.4377 14.4793 23.2293 14.7397L20.0002 17.9689L16.7189 14.7397C16.5106 14.4793 16.0939 14.4793 15.8335 14.7397L14.6877 15.8856C14.4272 16.146 14.4272 16.5627 14.6877 16.771L17.9168 20.0002L14.6877 23.2814C14.4272 23.4897 14.4272 23.9064 14.6877 24.1668L15.8335 25.3127C16.0939 25.5731 16.5106 25.5731 16.7189 25.3127L20.0002 22.0835L23.2293 25.3127C23.4377 25.5731 23.8543 25.5731 24.1147 25.3127L25.2606 24.1668C25.521 23.9064 25.521 23.4897 25.2606 23.2814L22.0314 20.0002L25.2606 16.771Z"
                    fill="#333333"
                />
            </svg>

            {/* modaltitle */}

            <div className="flex  justify-start items-center self-stretch  relative gap-2.5 mt-5 ">
                <svg
                    width={50}
                    height={50}
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" xl:w-[50px] h-[50px] relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M43.2292 22.3958H43.75V15.625C43.75 13.9323 42.3177 12.5 40.625 12.5H9.375C7.61719 12.5 6.25 13.9323 6.25 15.625V22.3958H6.77083C8.20312 22.3958 9.375 23.5677 9.375 25C9.375 26.4974 8.20312 27.6042 6.77083 27.6042H6.25V34.375C6.25 36.1328 7.61719 37.5 9.375 37.5H40.625C42.3177 37.5 43.75 36.1328 43.75 34.375V27.6042H43.2292C41.7318 27.6042 40.625 26.4974 40.625 25C40.625 23.5677 41.7318 22.3958 43.2292 22.3958ZM40.625 19.9219C38.737 20.8984 37.5 22.7865 37.5 25C37.5 27.2786 38.737 29.1667 40.625 30.1432V34.375H9.375V30.1432C11.1979 29.1667 12.5 27.2786 12.5 25C12.5 22.7865 11.1979 20.8984 9.375 19.9219V15.625H40.625V19.9219Z"
                        fill="#333333"
                    />
                </svg>
                <p className="font-[Satoshi] flex-grow xl:w-[683px] text-3xl xl:text-4xl text-left text-[#373737]">
                    {title}
                </p>
            </div>
            <div className="flex flex-col items-start self-stretch justify-start gap-5 xl:flex-row">
                <div className="flex flex-col justify-start items-start self-stretch flex-grow relative gap-2.5 p-5 border border-[#cdcdcd]">
                    <Button
                        color={COLOR.BLACK}
                        stretch
                        addClass=" text-sm shrink-0 w-full text-center xl:text-base     h-11    px-8 py-2.5 "
                    >
                        {header1}
                    </Button>
                    <ul className="self-stretch ml-5  xl:w-[321.5px]  text-sm xl:text-base text-left text-[#373737]">
                        {set1.map((point, id) => (
                            <li
                                key={id}
                                className="self-stretch list-disc  xl:w-[321.5px]  text-sm xl:text-base text-left text-[#373737]"
                            >
                                {point}
                            </li>
                        ))}
                    </ul>
                    {cost1 ? (
                        <p className="self-stretch  xl:w-[321.5px]  text-md xl:text-lg font-bold text-right text-[#373737]">
                            Costo Q.{cost1}
                        </p>
                    ) : (
                        <div className="flex justify-end w-full">
                            <Button
                                link="/client/urgent-hiring"
                                size={SIZE.SMALL}
                                text="Contratar"
                            />
                        </div>
                    )}
                    <label className="flex items-center text-sm xl:text-base text-left text-[#373737]">
                        <input
                            type="checkbox"
                            checked={enviarAnonimamente}
                            onChange={handleCheckboxChange}
                            className="mr-2"
                        />
                        <span>Enviar anónimamente</span>
                    </label>
                </div>
                <div className="flex flex-col justify-start items-start self-stretch  flex-grow relative gap-2.5 p-5 border border-[#cdcdcd]">
                    <Button
                        color={COLOR.BLACK}
                        stretch
                        addClass=" text-sm shrink-0 w-full text-center xl:text-base     h-11    px-8 py-2.5 "
                    >
                        {header2}
                    </Button>
                    <ul className=" list-disc ml-5 self-stretch  xl:w-[321.5px] text-left">
                        {set2.map((point, id) => (
                            <li
                                key={id}
                                className=" self-stretch  xl:w-[321.5px]  text-sm xl:text-base text-left text-[#373737]"
                            >
                                {point}
                            </li>
                        ))}
                    </ul>
                    {cost2 ? (
                        <p className="self-stretch  xl:w-[321.5px]  text-md xl:text-lg font-bold text-right text-[#373737]">
                            Costo Q.{cost2}
                        </p>
                    ) : (
                        <div className="flex justify-end w-full">
                                     <FaLock 
                                       size={20} 
                                       color="#d80027" 
                                       style={{ position: 'relative', right: '10px', top: '5px' }} 
                                        />
                            <Button
                                color={COLOR.GRAY}
                                size={SIZE.SMALL}
                                text="Contratar"
                                //link="/platform/client-dashboard"
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* Commment out old discount card logic */}
            {/* <DiscountCard
                percent={discountPercent}
                header={dHeader}
                subHeader={dSubHeader}
                text={dText}
                link={"/signup/client"}
                buttonColor={COLOR.WHITE}
            /> */}

            {/* Commment in new discount card logic */}
            {dHeader && dSubHeader && dText && discountPercent ? (
                <DiscountCard
                    percent={discountPercent}
                    header={dHeader}
                    subHeader={dSubHeader}
                    text={dText}
                    link={"/signup/client"}
                    buttonColor={COLOR.WHITE}
                />
            ) : null}
        </div>
    );
}

export const DiscountCard = (props) => {
    const { percent, header, subHeader, text, link, buttonColor } = props;

    return (
        <div
            className="flex w-full border-[3px] border-[#292929]"
            style={{ filter: "drop-shadow(0px 0px 20px rgba(0,0,0,0.1))" }}
        >
            <div className="flex flex-col justify-center items-center self-stretch  relative space-y-[-1px] p-5 bg-[#d80027]">
                <p className="font-bold text-left text-white text-md xl:text-lg">
                    DESCUENTO
                </p>
                <p className="font-[Satoshi]  text-5xl font-bold text-left text-white mt-1">
                    {percent}%
                </p>
            </div>
            <div className="flex flex-col justify-center items-start flex-grow relative gap-[7px] p-5 bg-white">
                <p className="self-stretch  xl:w-[554px] text-xl xl:text-2xl font-bold text-left text-[#d80027]">
                    {header}
                </p>
                <p className="self-stretch  xl:w-[554px]  text-md xl:text-lg font-bold text-left text-[#373737]">
                    {subHeader}
                </p>
                <div className="flex flex-col xl:flex-row justify-between w-full xl:items-center self-stretch  gap-[7px]">
                    <div className="flex  text-sm xl:text-base text-left text-[#373737] justify-center items-center  relative gap-2.5 p-2.5 bg-[#ffecef]">
                        {text}
                    </div>
                    <Button
                        color={buttonColor}
                        link={link}
                        addClass="   text-sm xl:text-base   h-11  gap-2.5 px-8 py-2.5 border-2 border-[#292929]"
                    >
                        Registrarme
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Discount;



/////////////////////Codigo original/////////////////////////
// function Discount({
//     closeDialog,
//     title = "Elige tu tipo de Pago",
//     header1 = "Pago al momento de recibir la Asesoria",
//     header2 = "Pago Inmediato de Asesoría",
//     set1 = [
//         `Un profesional se comunicará contigo, brindarán la
//         asesoría.`,
//         `Consulta sin necesidad de registro`
//     ],
//     set2 = [
//         `Regístrate y adquiere todos beneficios por estar
//         registrado.`,
//         <p>
//             Asegura tu asesoría cancelando anticipadamente.
//             <a href="/login" className=" text-[#d80027] font-bold">
//                 {` Recibes +15% `}
//             </a>
//             de descuento.
//         </p>
//     ],
//     cost1 = null,
//     cost2 = null,

//     dHeader = "¡Obtén tu cupón de bienvenida!",
//     dSubHeader = `Regístrese correctamente e inicie sesión y reclame su
//     cupón`,
//     dText = "Aplica solo para su primera consulta",
//     discountPercent = "30"
// }) {
//     const [enviarAnonimamente, setEnviarAnonimamente] = useState(false);

//   const handleCheckboxChange = () => {
//     setEnviarAnonimamente(!enviarAnonimamente);
//   };

//     return (
//         <div
//             className="flex flex-col mt-10 min-w-fit max-w-[600px] overflow-y-auto max-h-[100vh]  xl:w-[803px] h-full relative gap-5 pt-[30px] sm:p-[30px] bg-white"
//             style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
//         >

//             {/* closeIcon */}
//             <svg
//                 // width={40}
//                 // height={40}
//                 viewBox="0 0 40 40"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="cursor-pointer w-10 h-10 absolute right-[10%] xl:right-[5px] top-[5px] z-10 "
//                 preserveAspectRatio="xMidYMid meet"
//                 onClick={closeDialog}
//             >
//                 <path
//                     d="M20.0002 7.0835C12.8647 7.0835 7.0835 12.8647 7.0835 20.0002C7.0835 27.1356 12.8647 32.9168 20.0002 32.9168C27.1356 32.9168 32.9168 27.1356 32.9168 20.0002C32.9168 12.8647 27.1356 7.0835 20.0002 7.0835ZM20.0002 30.4168C14.2189 30.4168 9.5835 25.7814 9.5835 20.0002C9.5835 14.271 14.2189 9.5835 20.0002 9.5835C25.7293 9.5835 30.4168 14.271 30.4168 20.0002C30.4168 25.7814 25.7293 30.4168 20.0002 30.4168ZM25.2606 16.771C25.521 16.5627 25.521 16.146 25.2606 15.8856L24.1147 14.7397C23.8543 14.4793 23.4377 14.4793 23.2293 14.7397L20.0002 17.9689L16.7189 14.7397C16.5106 14.4793 16.0939 14.4793 15.8335 14.7397L14.6877 15.8856C14.4272 16.146 14.4272 16.5627 14.6877 16.771L17.9168 20.0002L14.6877 23.2814C14.4272 23.4897 14.4272 23.9064 14.6877 24.1668L15.8335 25.3127C16.0939 25.5731 16.5106 25.5731 16.7189 25.3127L20.0002 22.0835L23.2293 25.3127C23.4377 25.5731 23.8543 25.5731 24.1147 25.3127L25.2606 24.1668C25.521 23.9064 25.521 23.4897 25.2606 23.2814L22.0314 20.0002L25.2606 16.771Z"
//                     fill="#333333"
//                 />
//             </svg>

//             {/* modaltitle */}

//             <div className="flex  justify-start items-center self-stretch  relative gap-2.5 mt-5 ">
//                 <svg
//                     width={50}
//                     height={50}
//                     viewBox="0 0 50 50"
//                     fill="none"
//                     xmlns="http://www.w3.org/2000/svg"
//                     className=" xl:w-[50px] h-[50px] relative"
//                     preserveAspectRatio="xMidYMid meet"
//                 >
//                     <path
//                         d="M43.2292 22.3958H43.75V15.625C43.75 13.9323 42.3177 12.5 40.625 12.5H9.375C7.61719 12.5 6.25 13.9323 6.25 15.625V22.3958H6.77083C8.20312 22.3958 9.375 23.5677 9.375 25C9.375 26.4974 8.20312 27.6042 6.77083 27.6042H6.25V34.375C6.25 36.1328 7.61719 37.5 9.375 37.5H40.625C42.3177 37.5 43.75 36.1328 43.75 34.375V27.6042H43.2292C41.7318 27.6042 40.625 26.4974 40.625 25C40.625 23.5677 41.7318 22.3958 43.2292 22.3958ZM40.625 19.9219C38.737 20.8984 37.5 22.7865 37.5 25C37.5 27.2786 38.737 29.1667 40.625 30.1432V34.375H9.375V30.1432C11.1979 29.1667 12.5 27.2786 12.5 25C12.5 22.7865 11.1979 20.8984 9.375 19.9219V15.625H40.625V19.9219Z"
//                         fill="#333333"
//                     />
//                 </svg>
//                 <p className="font-[Satoshi] flex-grow xl:w-[683px] text-3xl xl:text-4xl text-left text-[#373737]">
//                     {title}
//                 </p>
//             </div>
//             <div className="flex flex-col items-start self-stretch justify-start gap-5 xl:flex-row">
//                 <div className="flex flex-col justify-start items-start self-stretch flex-grow relative gap-2.5 p-5 border border-[#cdcdcd]">
//                     <Button
//                         color={COLOR.BLACK}
//                         stretch
//                         addClass=" text-sm shrink-0 w-full text-center xl:text-base     h-11    px-8 py-2.5 "
//                     >
//                         {header1}
//                     </Button>
//                     <ul className="self-stretch ml-5  xl:w-[321.5px]  text-sm xl:text-base text-left text-[#373737]">
//                         {set1.map((point, id) => (
//                             <li
//                                 key={id}
//                                 className="self-stretch list-disc  xl:w-[321.5px]  text-sm xl:text-base text-left text-[#373737]"
//                             >
//                                 {point}
//                             </li>
//                         ))}
//                     </ul>
//                     {cost1 ? (
//                         <p className="self-stretch  xl:w-[321.5px]  text-md xl:text-lg font-bold text-right text-[#373737]">
//                             Costo Q.{cost1}
//                         </p>
//                     ) : (
//                         <div className="flex justify-end w-full">
//                             <Button
//                                 link="/signup/client"
//                                 size={SIZE.SMALL}
//                                 // color={COLOR.WHITE}
//                                 // addClass={"text-red-500"}
//                                 text="Contratar"
//                             />
//                         </div>
//                     )}
//                     <label className="flex items-center text-sm xl:text-base text-left text-[#373737]">
//                         <input
//                             type="checkbox"
//                             checked={enviarAnonimamente}
//                             onChange={handleCheckboxChange}
//                             className="mr-2"
//                         />
//                         <span>Enviar anónimamente</span>
//                     </label>
//                 </div>
//                 <div className="flex flex-col justify-start items-start self-stretch  flex-grow relative gap-2.5 p-5 border border-[#cdcdcd]">
//                     <Button
//                         color={COLOR.BLACK}
//                         stretch
//                         addClass=" text-sm shrink-0 w-full text-center xl:text-base     h-11    px-8 py-2.5 "
//                     >
//                         {header2}
//                     </Button>
//                     <ul className=" list-disc ml-5 self-stretch  xl:w-[321.5px] text-left">
//                         {set2.map((point, id) => (
//                             <li
//                                 key={id}
//                                 className=" self-stretch  xl:w-[321.5px]  text-sm xl:text-base text-left text-[#373737]"
//                             >
//                                 {point}
//                             </li>
//                         ))}
//                     </ul>
//                     {cost2 ? (
//                         <p className="self-stretch  xl:w-[321.5px]  text-md xl:text-lg font-bold text-right text-[#373737]">
//                             Costo Q.{cost2}
//                         </p>
//                     ) : (
//                         <div className="flex justify-end w-full">
//                             <Button
//                                 size={SIZE.SMALL}
//                                 // color={COLOR.WHITE}
//                                 // addClass={"text-red-500"}
//                                 text="Contratar"
//                                 link="/platform/client-dashboard"
//                             />
//                         </div>
//                     )}
//                 </div>
//             </div>

//             {/* commmon 5% discount card */}

//             <DiscountCard
//                 percent={discountPercent}
//                 header={dHeader}
//                 subHeader={dSubHeader}
//                 text={dText}
//                 link={"/signup/client"}
//                 buttonColor={COLOR.WHITE}
//             />
//         </div>
//     );
// }

// export const DiscountCard = (props) => {
//     const { percent, header, subHeader, text, link, buttonColor } = props;

//     return (
//         <div
//             className="flex w-full border-[3px] border-[#292929]"
//             style={{ filter: "drop-shadow(0px 0px 20px rgba(0,0,0,0.1))" }}
//         >
//             <div className="flex flex-col justify-center items-center self-stretch  relative space-y-[-1px] p-5 bg-[#d80027]">
//                 <p className="font-bold text-left text-white text-md xl:text-lg">
//                     DESCUENTO
//                 </p>
//                 <p className="font-[Satoshi]  text-5xl font-bold text-left text-white mt-1">
//                     {percent}%
//                 </p>
//             </div>
//             <div className="flex flex-col justify-center items-start flex-grow relative gap-[7px] p-5 bg-white">
//                 <p className="self-stretch  xl:w-[554px] text-xl xl:text-2xl font-bold text-left text-[#d80027]">
//                     {header}
//                 </p>
//                 <p className="self-stretch  xl:w-[554px]  text-md xl:text-lg font-bold text-left text-[#373737]">
//                     {subHeader}
//                 </p>
//                 <div className="flex flex-col xl:flex-row justify-between w-full xl:items-center self-stretch  gap-[7px]">
//                     <div className="flex  text-sm xl:text-base text-left text-[#373737] justify-center items-center  relative gap-2.5 p-2.5 bg-[#ffecef]">
//                         {text}
//                     </div>
//                     <Button
//                         color={buttonColor}
//                         link={link}
//                         addClass="   text-sm xl:text-base   h-11  gap-2.5 px-8 py-2.5 border-2 border-[#292929]"
//                     >
//                         Registrarme
//                     </Button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Discount;
