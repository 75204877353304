import React from "react";

function PhoneWrapper({
    illustration = (
        <svg
            width={454}
            height={471}
            viewBox="0 0 454 471"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[258px] h-[278.98px]  flex-grow-0 flex-shrink-0  lg:w-[454px] lg:h-[470.29px]"
            preserveAspectRatio="none"
        >
            <path
                d="M454 352.692V407.989C454 412.796 452.091 417.406 448.692 420.804C445.293 424.203 440.683 426.113 435.876 426.113H237.597C232.793 426.109 228.186 424.198 224.79 420.8C221.394 417.401 219.486 412.793 219.486 407.989V361.258L194.791 335.324L435.824 334.581C438.208 334.573 440.57 335.035 442.775 335.941C444.98 336.847 446.984 338.18 448.673 339.863C450.361 341.545 451.701 343.545 452.615 345.746C453.529 347.948 454 350.308 454 352.692Z"
                fill="#F5F5F5"
            />
            <path
                d="M248.705 368.077L428.639 367.243L428.613 361.715L248.692 362.549L248.705 368.077Z"
                fill="#E6E6E6"
            />
            <path
                d="M248.705 386.267L428.639 385.445L428.613 379.917L248.692 380.738L248.705 386.267Z"
                fill="#E6E6E6"
            />
            <path
                d="M319.861 398.14L248.671 398.488L248.698 404.016L319.888 403.668L319.861 398.14Z"
                fill="#E6E6E6"
            />
            <path
                d="M124.751 0.391357H241.695C243.065 0.391357 244.421 0.661161 245.687 1.18536C246.952 1.70957 248.102 2.47791 249.071 3.44651C250.039 4.41511 250.808 5.56501 251.332 6.83055C251.856 8.09609 252.126 9.45249 252.126 10.8223V68.0752C252.126 70.8416 251.027 73.4948 249.071 75.4509C247.115 77.4071 244.461 78.5061 241.695 78.5061H142.458C139.691 78.5061 137.038 77.4071 135.082 75.4509C133.126 73.4948 132.027 70.8416 132.027 68.0752V7.61478L124.751 0.391357Z"
                fill="#F5F5F5"
            />
            <path
                d="M271.23 147.687V168.236C271.23 169.62 271.78 170.946 272.758 171.924C273.736 172.902 275.063 173.452 276.446 173.452H391.265C392.648 173.452 393.974 172.902 394.952 171.924C395.931 170.946 396.48 169.62 396.48 168.236V157.01L409.662 142.902L276.459 142.472C275.773 142.47 275.093 142.604 274.459 142.865C273.825 143.127 273.248 143.51 272.763 143.995C272.277 144.479 271.892 145.055 271.629 145.689C271.366 146.322 271.23 147.001 271.23 147.687Z"
                fill="#F0F0F0"
            />
            <path
                d="M192.606 68.4318V105.11C192.608 106.688 192.299 108.251 191.696 109.709C191.093 111.168 190.209 112.493 189.094 113.609C187.979 114.726 186.654 115.612 185.196 116.216C183.739 116.82 182.176 117.131 180.598 117.131H49.0375C47.4594 117.131 45.8968 116.82 44.439 116.216C42.9812 115.612 41.6568 114.726 40.5415 113.609C39.4262 112.493 38.542 111.168 37.9392 109.709C37.3365 108.251 37.0272 106.688 37.0289 105.11V74.1297L20.6914 56.9186L180.559 56.4232C182.139 56.418 183.705 56.7248 185.166 57.326C186.628 57.9272 187.956 58.8109 189.075 59.9265C190.195 61.0422 191.083 62.3677 191.689 63.8272C192.295 65.2867 192.606 66.8515 192.606 68.4318Z"
                fill="#E0E0E0"
            />
            <path
                d="M56.4554 78.6409L175.785 78.0933L175.772 74.4294L56.4424 74.9771L56.4554 78.6409Z"
                fill="#F0F0F0"
            />
            <path
                d="M56.4554 90.715L175.785 90.1543L175.772 86.4905L56.4424 87.0381L56.4554 90.715Z"
                fill="#F0F0F0"
            />
            <path
                d="M103.638 98.5847L56.4258 98.8154L56.4437 102.479L103.656 102.249L103.638 98.5847Z"
                fill="#F0F0F0"
            />
            <path
                d="M1.88704e-05 210.65V265.961C1.82537e-05 268.34 0.468903 270.696 1.37987 272.894C2.29083 275.092 3.62602 277.089 5.30914 278.771C6.99226 280.453 8.99031 281.787 11.1891 282.696C13.3879 283.606 15.7444 284.073 18.1238 284.071H216.403C218.783 284.073 221.139 283.606 223.338 282.696C225.537 281.787 227.535 280.453 229.218 278.771C230.901 277.089 232.236 275.092 233.147 272.894C234.058 270.696 234.527 268.34 234.527 265.961V219.23L259.209 193.296L18.1759 192.501C15.7893 192.494 13.4248 192.958 11.2182 193.868C9.01162 194.777 7.00636 196.113 5.31754 197.8C3.62872 199.486 2.28961 201.489 1.37708 203.695C0.464555 205.9 -0.00342503 208.264 1.88704e-05 210.65Z"
                fill="#F0F0F0"
            />
            <path
                d="M205.294 226.036L25.3604 225.215L25.3864 219.686L205.307 220.508L205.294 226.036Z"
                fill="#E6E6E6"
            />
            <path
                d="M205.294 244.238L25.3604 243.404L25.3864 237.875L205.307 238.71L205.294 244.238Z"
                fill="#E6E6E6"
            />
            <path
                d="M134.107 232.106L134.08 237.635L205.27 237.982L205.297 232.454L134.107 232.106Z"
                fill="#E6E6E6"
            />
            <path
                d="M300.895 470.677L165.188 470.038C161.307 470.022 157.467 469.243 153.888 467.743C150.308 466.243 147.059 464.052 144.326 461.297C141.593 458.541 139.43 455.273 137.96 451.681C136.491 448.089 135.743 444.243 135.76 440.362L137.207 100.143C137.252 92.3096 140.404 84.8136 145.97 79.3011C151.536 73.7886 159.062 70.71 166.896 70.7412L302.603 71.3671C310.441 71.405 317.944 74.5535 323.462 80.1205C328.98 85.6876 332.063 93.2177 332.031 101.056L330.597 441.235C330.559 449.077 327.409 456.583 321.84 462.104C316.27 467.624 308.737 470.708 300.895 470.677Z"
                fill="#263238"
            />
            <path
                d="M302.406 83.0758L276.094 82.9585C275.236 82.972 274.389 83.155 273.601 83.4969C272.814 83.8388 272.101 84.3328 271.505 84.9507C270.909 85.5685 270.441 86.298 270.128 87.0973C269.814 87.8966 269.662 88.7499 269.679 89.6082V94.3803C269.697 95.2387 269.544 96.092 269.231 96.8912C268.917 97.6905 268.449 98.42 267.853 99.0379C267.257 99.6557 266.545 100.15 265.757 100.492C264.97 100.834 264.123 101.017 263.264 101.03L211.944 100.795C211.084 100.775 210.237 100.585 209.452 100.236C208.666 99.8871 207.957 99.3861 207.365 98.762C206.774 98.1379 206.312 97.4029 206.006 96.5995C205.699 95.796 205.555 94.9399 205.581 94.0805V89.3083C205.607 88.4489 205.463 87.5928 205.157 86.7893C204.851 85.9859 204.388 85.2509 203.797 84.6268C203.206 84.0026 202.497 83.5017 201.711 83.1527C200.925 82.8037 200.078 82.6136 199.218 82.5934H189.961L166.974 82.476C164.254 82.464 161.56 82.9877 159.043 84.0172C156.526 85.0466 154.237 86.5617 152.306 88.4759C150.375 90.3901 148.839 92.666 147.788 95.1735C146.736 97.681 146.189 100.371 146.177 103.09L144.769 435.159C144.748 440.65 146.907 445.924 150.772 449.823C154.638 453.723 159.892 455.928 165.383 455.956L300.816 456.582C303.535 456.594 306.23 456.07 308.746 455.041C311.263 454.011 313.552 452.496 315.483 450.582C317.415 448.668 318.95 446.392 320.001 443.884C321.053 441.377 321.6 438.687 321.612 435.968L323.021 103.899C323.034 101.178 322.512 98.4809 321.483 95.962C320.455 93.4431 318.94 91.1514 317.026 89.2178C315.112 87.2842 312.836 85.7466 310.327 84.6928C307.819 83.6389 305.127 83.0895 302.406 83.0758Z"
                fill="white"
            />
            <path
                d="M227.97 414.65C227.97 415.212 227.803 415.761 227.491 416.229C227.179 416.696 226.735 417.06 226.215 417.276C225.696 417.491 225.124 417.547 224.573 417.437C224.022 417.328 223.515 417.057 223.118 416.659C222.72 416.262 222.449 415.755 222.34 415.204C222.23 414.653 222.286 414.081 222.502 413.562C222.717 413.042 223.081 412.599 223.548 412.286C224.016 411.974 224.565 411.807 225.128 411.807C225.88 411.811 226.601 412.111 227.134 412.643C227.666 413.176 227.967 413.897 227.97 414.65Z"
                fill="#455A64"
            />
            <path
                d="M238.766 414.65C238.766 415.212 238.599 415.761 238.287 416.229C237.975 416.696 237.531 417.06 237.011 417.276C236.492 417.491 235.92 417.547 235.369 417.437C234.818 417.328 234.311 417.057 233.914 416.659C233.516 416.262 233.245 415.755 233.136 415.204C233.026 414.653 233.082 414.081 233.297 413.562C233.513 413.042 233.877 412.599 234.344 412.286C234.812 411.974 235.361 411.807 235.923 411.807C236.676 411.811 237.397 412.111 237.93 412.643C238.462 413.176 238.762 413.897 238.766 414.65Z"
                fill="#455A64"
            />
            <path
                d="M249.561 414.65C249.561 415.212 249.394 415.761 249.082 416.229C248.769 416.696 248.326 417.06 247.806 417.276C247.287 417.491 246.715 417.547 246.164 417.437C245.613 417.328 245.106 417.057 244.709 416.659C244.311 416.262 244.04 415.755 243.931 415.204C243.821 414.653 243.877 414.081 244.092 413.562C244.307 413.042 244.672 412.599 245.139 412.286C245.607 411.974 246.156 411.807 246.718 411.807C247.471 411.811 248.192 412.111 248.725 412.643C249.257 413.176 249.557 413.897 249.561 414.65Z"
                fill="#455A64"
            />
            <path
                d="M171.407 175.716C171.407 175.716 171.407 175.481 171.407 174.999C171.507 173.107 172.175 171.289 173.324 169.783C174.276 168.487 175.535 167.447 176.988 166.758C178.841 165.934 180.875 165.598 182.895 165.78H207.564L311.873 165.989H312.643L312.108 166.55L298.743 180.605L298.835 180.384V203.658C298.928 205.209 298.814 206.765 298.496 208.286C298.095 209.81 297.34 211.217 296.292 212.394C295.264 213.567 293.979 214.486 292.537 215.08C291.102 215.644 289.566 215.906 288.025 215.849H245.467L188.475 215.731H182.477C180.558 215.879 178.632 215.533 176.884 214.727C175.293 213.959 173.935 212.783 172.946 211.32C171.956 209.857 171.372 208.158 171.251 206.396C171.251 199.876 171.251 194.387 171.251 189.837C171.251 185.286 171.251 181.792 171.316 179.406C171.316 178.219 171.316 177.319 171.316 176.681C171.316 176.042 171.316 175.755 171.316 175.755C171.316 175.755 171.316 176.068 171.316 176.681C171.316 177.293 171.316 178.219 171.316 179.406C171.316 181.818 171.316 185.338 171.316 189.837C171.316 194.335 171.316 199.942 171.407 206.37C171.532 208.06 172.102 209.688 173.06 211.087C174.017 212.486 175.327 213.607 176.858 214.336C178.546 215.108 180.406 215.432 182.256 215.275H188.475L245.532 215.21H279.224H288.065C289.516 215.269 290.964 215.025 292.315 214.493C293.664 213.937 294.868 213.081 295.836 211.989C296.808 210.891 297.505 209.577 297.87 208.156C298.177 206.691 298.287 205.191 298.196 203.697V180.266L298.287 180.175L311.665 166.119L311.912 166.68L207.603 166.237L190.079 166.145H182.921C180.946 165.951 178.954 166.26 177.131 167.045C175.701 167.705 174.456 168.709 173.507 169.966C172.804 170.883 172.265 171.915 171.916 173.017C171.663 173.899 171.493 174.802 171.407 175.716Z"
                fill="#455A64"
            />
            <path
                d="M282.642 184.06L185.151 183.604L185.164 180.605L282.642 181.062V184.06Z"
                fill="#455A64"
            />
            <path
                d="M282.642 193.918L185.151 193.461L185.164 190.462L282.642 190.919V193.918Z"
                fill="#455A64"
            />
            <path
                d="M244.061 200.328L244.046 203.327L282.614 203.515L282.629 200.517L244.061 200.328Z"
                fill="#455A64"
            />
            <path
                d="M303.946 241.743V271.732C303.946 274.334 302.914 276.829 301.075 278.67C299.237 280.511 296.743 281.547 294.141 281.551H186.702C184.098 281.551 181.601 280.516 179.76 278.675C177.919 276.834 176.884 274.336 176.884 271.732V246.398L163.52 232.343L294.089 231.938C295.381 231.931 296.662 232.18 297.857 232.67C299.053 233.159 300.14 233.881 301.056 234.792C301.972 235.703 302.699 236.786 303.195 237.979C303.691 239.172 303.946 240.451 303.946 241.743Z"
                fill="#D80027"
            />
            <path
                d="M192.727 250.088L290.217 249.632L290.204 246.633L192.714 247.089L192.727 250.088Z"
                fill="white"
            />
            <path
                d="M192.727 259.945L290.217 259.489L290.204 256.503L192.714 256.946L192.727 259.945Z"
                fill="white"
            />
            <path
                d="M231.261 266.374L192.693 266.563L192.708 269.562L231.276 269.373L231.261 266.374Z"
                fill="white"
            />
            <path
                d="M245.469 126.964C246.414 126.552 247.216 125.868 247.773 125.001C248.33 124.133 248.617 123.119 248.598 122.088C248.633 121.418 248.531 120.749 248.298 120.12C248.066 119.491 247.708 118.917 247.246 118.431C246.784 117.945 246.229 117.558 245.613 117.293C244.997 117.029 244.333 116.892 243.663 116.892C242.993 116.892 242.329 117.029 241.714 117.293C241.098 117.558 240.542 117.945 240.08 118.431C239.619 118.917 239.261 119.491 239.028 120.12C238.796 120.749 238.694 121.418 238.728 122.088C238.702 123.127 238.988 124.15 239.548 125.026C240.107 125.902 240.916 126.59 241.87 127.003C234.269 128.151 234.608 136.417 234.608 136.417L252.588 136.495C252.588 136.495 253.005 128.112 245.469 126.964Z"
                fill="#D80027"
            />
            <path
                opacity="0.5"
                d="M245.469 126.964C246.414 126.552 247.216 125.868 247.773 125.001C248.33 124.133 248.617 123.119 248.598 122.088C248.633 121.418 248.531 120.749 248.298 120.12C248.066 119.491 247.708 118.917 247.246 118.431C246.784 117.945 246.229 117.558 245.613 117.293C244.997 117.029 244.333 116.892 243.663 116.892C242.993 116.892 242.329 117.029 241.714 117.293C241.098 117.558 240.542 117.945 240.08 118.431C239.619 118.917 239.261 119.491 239.028 120.12C238.796 120.749 238.694 121.418 238.728 122.088C238.702 123.127 238.988 124.15 239.548 125.026C240.107 125.902 240.916 126.59 241.87 127.003C234.269 128.151 234.608 136.417 234.608 136.417L252.588 136.495C252.588 136.495 253.005 128.112 245.469 126.964Z"
                fill="white"
            />
            <path
                d="M235.598 126.964C236.539 126.545 237.338 125.861 237.896 124.995C238.455 124.128 238.748 123.118 238.74 122.088C238.79 121.408 238.698 120.726 238.472 120.084C238.246 119.442 237.891 118.853 237.427 118.354C236.963 117.856 236.402 117.458 235.777 117.186C235.153 116.914 234.479 116.774 233.799 116.774C233.118 116.774 232.444 116.914 231.82 117.186C231.195 117.458 230.634 117.856 230.17 118.354C229.707 118.853 229.351 119.442 229.125 120.084C228.899 120.726 228.808 121.408 228.857 122.088C228.831 123.127 229.117 124.15 229.676 125.026C230.236 125.902 231.045 126.59 231.999 127.003C224.398 128.151 224.737 136.417 224.737 136.417L242.717 136.495C242.717 136.495 243.134 128.111 235.598 126.964Z"
                fill="#D80027"
            />
            <path
                d="M263.092 140.147L214.758 139.928L214.731 145.926L263.065 146.145L263.092 140.147Z"
                fill="#D80027"
            />
            <path
                opacity="0.3"
                d="M263.092 140.147L214.758 139.928L214.731 145.926L263.065 146.145L263.092 140.147Z"
                fill="white"
            />
            <path
                d="M173.664 310.027C173.641 309.793 173.641 309.557 173.664 309.323C173.742 308.619 173.969 307.939 174.329 307.328C174.926 306.316 175.844 305.532 176.936 305.098C178.447 304.686 180.019 304.549 181.578 304.694H211.476L312.148 304.903H312.917L312.343 305.437L299.174 319.571L299.265 319.337V328.79C299.265 329.494 299.265 330.094 299.265 330.876C299.212 331.975 298.83 333.033 298.17 333.914C297.51 334.794 296.602 335.457 295.562 335.818C294.879 336.05 294.159 336.156 293.437 336.131H279.394H249.001L197.603 336.026H180.014C179.259 336.067 178.503 336.023 177.758 335.896C176.708 335.668 175.75 335.131 175.007 334.355C174.263 333.58 173.769 332.6 173.585 331.541C173.508 330.896 173.482 330.247 173.507 329.598C173.507 327.108 173.507 324.839 173.507 322.792C173.507 318.711 173.507 315.542 173.572 313.352C173.572 312.296 173.572 311.487 173.572 310.901C173.572 310.314 173.572 310.066 173.572 310.066C173.572 310.066 173.572 310.353 173.572 310.901C173.572 311.448 173.572 312.296 173.572 313.352C173.572 315.542 173.572 318.711 173.638 322.792C173.638 324.839 173.638 327.108 173.638 329.598C173.616 330.225 173.646 330.854 173.729 331.476C173.9 332.465 174.365 333.381 175.062 334.104C175.759 334.827 176.658 335.324 177.64 335.531C178.361 335.639 179.09 335.674 179.818 335.635H187.172H197.603L249.053 335.557H293.476C294.118 335.58 294.758 335.488 295.367 335.283C296.589 334.854 297.593 333.959 298.157 332.793C298.436 332.213 298.596 331.584 298.626 330.941C298.626 330.289 298.626 329.572 298.626 328.894V319.311L298.718 319.219L311.913 305.112L312.148 305.672L211.489 305.242L181.591 305.046C180.062 304.878 178.515 304.993 177.028 305.385C176.184 305.719 175.441 306.268 174.874 306.977C174.307 307.686 173.935 308.53 173.794 309.427C173.761 309.629 173.718 309.83 173.664 310.027Z"
                fill="#455A64"
            />
            <path
                d="M287.01 317.929L189.52 317.485L189.533 314.486L287.023 314.943L287.01 317.929Z"
                fill="#455A64"
            />
            <path
                d="M247.467 324.383L247.452 327.381L287.011 327.575L287.026 324.576L247.467 324.383Z"
                fill="#455A64"
            />
            <path
                d="M300.518 363.512V384.048C300.518 385.431 299.968 386.758 298.99 387.736C298.012 388.714 296.685 389.263 295.302 389.263H180.484C179.1 389.263 177.774 388.714 176.796 387.736C175.818 386.758 175.268 385.431 175.268 384.048V372.821L162.086 358.714L295.302 358.296C296.685 358.296 298.012 358.846 298.99 359.824C299.968 360.802 300.518 362.129 300.518 363.512Z"
                fill="#D80027"
            />
            <path
                d="M187.185 371.413L284.675 370.97L284.662 367.971L187.172 368.428L187.185 371.413Z"
                fill="white"
            />
            <path
                d="M187.185 381.062L284.675 380.605L284.662 377.62L187.172 378.063L187.185 381.062Z"
                fill="white"
            />
        </svg>
    ),
    title = "Mantén siempre el contacto",
    desc = `Utiliza nuestras herramientas de colaboración para
    trabajar eficientemente con tu profesional Comparte
    archivos, chatea en tiempo real, monitorea el avance
    y mucho más.`,
    features = [
        {
            svg: (
                <svg
                    width={61}
                    height={62}
                    viewBox="0 0 61 62"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    //  w-[60.
                    className="1px] h-[60.1px] relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M47.5789 23.5217H40.0665V16.0093C40.0665 13.2704 37.7971 11.001 35.0582 11.001H12.521C9.70382 11.001 7.5127 13.2704 7.5127 16.0093V28.53C7.5127 31.3471 9.70382 33.5382 12.521 33.5382H15.0251V37.6075C15.0251 38.2335 15.4164 38.5465 15.9642 38.5465C16.1207 38.5465 16.2772 38.5465 16.5119 38.39L22.5375 35.0251V41.0506C22.5375 43.8678 24.7287 46.0589 27.5458 46.0589H35.0582L43.5097 50.9107C43.7445 51.0672 43.901 51.0672 44.1357 51.0672C44.6053 51.0672 45.0748 50.7542 45.0748 50.1282V46.0589H47.5789C50.3178 46.0589 52.5872 43.8678 52.5872 41.0506V28.53C52.5872 25.791 50.3178 23.5217 47.5789 23.5217ZM15.0251 29.782H12.521C11.8167 29.782 11.2689 29.2342 11.2689 28.53V16.0093C11.2689 15.3832 11.8167 14.7572 12.521 14.7572H35.0582C35.6843 14.7572 36.3103 15.3832 36.3103 16.0093V28.53C36.3103 29.2342 35.6843 29.782 35.0582 29.782H24.0244L23.1636 30.3298L18.7813 32.8339V29.782H15.0251ZM48.831 41.0506C48.831 41.7549 48.205 42.3027 47.5789 42.3027H41.3186V45.3546L36.8581 42.8505L35.9973 42.3027H27.5458C26.8415 42.3027 26.2937 41.7549 26.2937 41.0506V33.5382H35.0582C37.7971 33.5382 40.0665 31.3471 40.0665 28.53V27.2779H47.5789C48.205 27.2779 48.831 27.9039 48.831 28.53V41.0506Z"
                        fill="#D80027"
                    />
                </svg>
            ),
            title: "Chat en vivo",
            desc: `Utiliza nuestras herramientas de colaboración para
            trabajar eficientemente con tu profesional. Comparte
            archivos, chatea en tiempo real, monitorea el avance
            y mucho más.`
        },
        {
            svg: (
                <svg
                    width={61}
                    height={62}
                    viewBox="0 0 61 62"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    //  w-[60.
                    className="1px] h-[60.1px] relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M32.5541 43.5548C32.5541 42.2245 31.3803 41.0506 30.05 41.0506C28.6414 41.0506 27.5459 42.2245 27.5459 43.5548C27.5459 44.9634 28.6414 46.0589 30.05 46.0589C31.3803 46.0589 32.5541 44.9634 32.5541 43.5548ZM42.5707 14.7572C42.5707 12.7226 40.8491 11.001 38.8145 11.001H21.2855C19.1726 11.001 17.5293 12.7226 17.5293 14.7572V47.311C17.5293 49.4239 19.1726 51.0672 21.2855 51.0672H38.8145C40.8491 51.0672 42.5707 49.4239 42.5707 47.311V14.7572ZM38.8145 46.8415C38.8145 47.1545 38.5797 47.311 38.345 47.311H21.755C21.442 47.311 21.2855 47.1545 21.2855 46.8415V15.2267C21.2855 14.9919 21.442 14.7572 21.755 14.7572H38.345C38.5797 14.7572 38.8145 14.9919 38.8145 15.2267V46.8415Z"
                        fill="#D80027"
                    />
                </svg>
            ),
            title: "App móvil",
            desc: ` Gestiona tu proyecto con unos cuantos toques. La
            aplicación móvil hace que la colaboración sobre la
            marcha sea muy sencilla.`
        }
    ]
}) {
    return (
        <div className="relative flex flex-col items-center justify-start gap-6 lg:flex-row ">
            <div className="flex flex-col justify-start items-start  gap-[30px]">
                <div className="flex flex-col justify-start items-start  relative gap-2.5">
                    <p className="self-stretch  font-[Satoshi] text-lg lg:text-5xl font-bold text-left text-[#373737]">
                        {title}
                    </p>
                    <div className="flex flex-col justify-start items-start self-stretch  relative gap-2.5">
                        <p className="self-stretch text-md  lg:text-[21px] text-left text-[#7b7b7b]">
                            {desc}
                        </p>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row  justify-start items-start  gap-[27px]">
                    {features.map((feature, id) => (
                        <div
                            key={`ft${id}`}
                            className="flex flex-col justify-center items-start flex-grow relative gap-2.5"
                        >
                            <div className="flex justify-start items-start  relative gap-[6.009933948516846px] bg-white">
                                {feature.svg}
                            </div>
                            <p className="self-stretch text-md  lg:text-[21px] font-bold text-left text-[#373737]">
                                {feature.title}
                            </p>
                            <p className="self-stretch  text-base text-left text-[#7b7b7b]">
                                {feature.desc}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            {illustration}
        </div>
    );
}

export default PhoneWrapper;
