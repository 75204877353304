import React, { useState, useEffect } from 'react';
import apiurl from "../ApiConfig";
function ProfileBoard() {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    console.log('Token from localStorage:', token);

    if (token) {
      fetchUser(token);
    }
  }, []);

  const fetchUser = async (token) => {
    try {
        const response = await fetch(`${apiurl}/User/Profile`,  {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (!response.ok) {
        throw new Error(`Error fetching user data: ${response.status}`);
      }

      const data = await response.json();
      setUserData(data);
    } catch (error) {
      setError(error);
    }
  };

  if (error) {
    return <div>Error loading profile: {error.message}</div>;
  }

  if (!userData) {
    return <div>Loading profile...</div>;
  }

  return (
    <div
      className="relative flex flex-col items-start justify-start w-full gap-4 p-5 bg-white"
      style={{ boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)", width: "375px", justifyContent: "center", display: "flex", alignItems: "center", border: "1px solid #c5c5c5" }}
    >
      <span style={{ width: "200px", height: "200px", marginRight: "10px" }}>
        
        <img src={userData.profile_picture || "https://cdn-icons-png.flaticon.com/512/3135/3135768.png"} alt="profile" />
      </span>
      <span style={{ fontWeight: "bold", fontSize: "20px" }}>{userData.first_name} {userData.last_name}</span>
      <span>@{userData.username}</span>
      <span>{userData.nationality}</span>
    </div>
  );
}

export default ProfileBoard;
