import React, { useCallback, useState } from "react";
import { noop } from "../../utils/noop";
import { Button, COLOR } from "../ui/buttons/Button";
import Input from "../ui/Input";

function AddTelephoneDialog({ closeDialog = noop }) {
    const [formData, setFormData] = useState({});

    const handleChange = useCallback(
        (target = {}) => {
            let { name, value } = target;
            console.log(target);
            setFormData((data) => ({ ...data, ...{ [name]: value } }));
            console.log(formData);
        },
        [formData]
    );

    const handleFormSubmission = useCallback((e) => {
        // submit formData
        e.preventDefault();
    }, []);

    return (
        <form
            onSubmit={handleFormSubmission}
            className="flex flex-col justify-start items-start lg:w-[516px] relative gap-5 p-5 bg-white"
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
        >
            <svg
                onClick={closeDialog}
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer w-10 h-10 absolute left-[476px] top-0"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M20.0007 7.08398C12.8652 7.08398 7.08398 12.8652 7.08398 20.0007C7.08398 27.1361 12.8652 32.9173 20.0007 32.9173C27.1361 32.9173 32.9173 27.1361 32.9173 20.0007C32.9173 12.8652 27.1361 7.08398 20.0007 7.08398ZM20.0007 30.4173C14.2194 30.4173 9.58398 25.7819 9.58398 20.0007C9.58398 14.2715 14.2194 9.58398 20.0007 9.58398C25.7298 9.58398 30.4173 14.2715 30.4173 20.0007C30.4173 25.7819 25.7298 30.4173 20.0007 30.4173ZM25.2611 16.7715C25.5215 16.5632 25.5215 16.1465 25.2611 15.8861L24.1152 14.7402C23.8548 14.4798 23.4382 14.4798 23.2298 14.7402L20.0007 17.9694L16.7194 14.7402C16.5111 14.4798 16.0944 14.4798 15.834 14.7402L14.6882 15.8861C14.4277 16.1465 14.4277 16.5632 14.6882 16.7715L17.9173 20.0007L14.6882 23.2819C14.4277 23.4902 14.4277 23.9069 14.6882 24.1673L15.834 25.3132C16.0944 25.5736 16.5111 25.5736 16.7194 25.3132L20.0007 22.084L23.2298 25.3132C23.4382 25.5736 23.8548 25.5736 24.1152 25.3132L25.2611 24.1673C25.5215 23.9069 25.5215 23.4902 25.2611 23.2819L22.0319 20.0007L25.2611 16.7715Z"
                    fill="#333333"
                />
            </svg>
            <div className="flex justify-start items-center   relative gap-2.5">
                <svg
                    width={40}
                    height={40}
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative w-10 h-10"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M31.8743 23.8535L26.5618 21.6139C26.3014 21.5098 25.9889 21.4056 25.6243 21.4056C24.8952 21.4056 24.2181 21.7702 23.8014 22.291L22.0827 24.3743C19.3223 22.8639 17.1348 20.6764 15.6243 17.916L17.7077 16.1973C18.2285 15.7806 18.5931 15.1035 18.5931 14.3743C18.5931 14.0098 18.4889 13.6973 18.3848 13.4368L16.1452 8.12435C15.7806 7.29102 14.8952 6.66602 13.9056 6.66602C13.7493 6.66602 13.5931 6.7181 13.3848 6.77018L8.48893 7.86393C7.44727 8.12435 6.66602 9.06185 6.66602 10.2077C6.66602 23.0202 17.0306 33.3848 29.791 33.3327C30.9368 33.3327 31.8743 32.5514 32.1348 31.5098L33.2285 26.6139C33.2806 26.4056 33.3327 26.2493 33.3327 26.0931C33.3327 25.1035 32.7077 24.2181 31.8743 23.8535ZM29.6868 30.8848C18.3848 30.8327 9.2181 21.6139 9.16602 10.3118L13.8535 9.2181L16.041 14.3223L12.4993 17.2389C14.9473 22.3952 17.6035 25.0514 22.7598 27.4993L25.6764 23.9577L30.7806 26.1452L29.6868 30.8848Z"
                        fill="#333333"
                    />
                </svg>
                <p className="  text-2xl font-bold text-left text-[#373737]">
                    Agregar nuevo número
                </p>
            </div>

            <Input
                label="Nombre"
                placeholder="Nombre"
                required
                onChange={(e) => handleChange(e.target)}
                value={formData.name}
                name="name"
            />
            <Input
                label="Teléfono 1"
                placeholder="Teléfono"
                required
                onChange={(e) => handleChange(e.target)}
                value={formData.phone1}
                name="phone1"
            />
            <Input
                label="Teléfono 2"
                placeholder="Teléfono"
                required
                onChange={(e) => handleChange(e.target)}
                value={formData.phone2}
                name="phone2"
            />
            <Input
                label="Teléfono 3"
                placeholder="Teléfono"
                required
                onChange={(e) => handleChange(e.target)}
                value={formData.phone3}
                name="phone3"
            />
            <Input
                label="Teléfono 4"
                placeholder="Teléfono"
                required
                onChange={(e) => handleChange(e.target)}
                value={formData.phone4}
                name="phone4"
            />
            <div className="flex items-start self-stretch justify-start gap-5">
                <Button
                    addClass={"h-11"}
                    stretch
                    color={COLOR.WHITE}
                    onClick={closeDialog}
                    // className="flex justify-center items-center flex-grow h-11 relative gap-2.5 px-8 py-2.5 rounded border-2 border-[#292929]"
                >
                    Cancelar
                </Button>
                <Button
                    addClass={"h-11"}
                    type="submit"
                    stretch
                    // className="flex justify-center items-center flex-grow h-11 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]"
                >
                    Guardar
                </Button>
            </div>
        </form>
    );
}

export default AddTelephoneDialog;
