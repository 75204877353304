import React, { useState } from "react";

function Pagination({
    length = 4,
    selectedProp = 1,
    show = 4,
    start = 1,
    changePage = () => {}
}) {
    const list = [];
    const [selected, setSelected] = useState(selectedProp);

    for (let index = start; index <= length; index++) {
        list.push(index);
    }
   const goToNext = () => {
       if (selected < length) {
           setSelected(selected + 1);
           changePage(selected + 1);
       }
   };
   const goToPrev = () => {
       if (selected > 1) {
           setSelected(selected - 1);
           changePage(selected - 1);
       }
   };
    return (
        <div className="flex justify-center items-center   gap-2.5 select-none">
            <div
                onClick={goToPrev}
                className="flex flex-col justify-center items-center   h-6 w-6 relative gap-2.5 p-[5px] rounded-[50px] bg-white"
            >
                <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative w-5 h-5 cursor-pointer hover:opacity-80"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M12.5004 17.225C12.3421 17.225 12.1837 17.1667 12.0587 17.0417L6.62539 11.6084C5.74206 10.725 5.74206 9.27502 6.62539 8.39168L12.0587 2.95835C12.3004 2.71668 12.7004 2.71668 12.9421 2.95835C13.1837 3.20002 13.1837 3.60002 12.9421 3.84168L7.50872 9.27502C7.10872 9.67502 7.10872 10.325 7.50872 10.725L12.9421 16.1583C13.1837 16.4 13.1837 16.8 12.9421 17.0417C12.8171 17.1584 12.6587 17.225 12.5004 17.225Z"
                        fill="#A4A4A4"
                    />
                </svg>
            </div>
            {list.map((num) =>
                num === selected ? (
                    <div className="cursor-default flex flex-col justify-center items-center   h-8 w-8 relative gap-2.5 p-[5px] rounded-[50px] bg-[#d80027]">
                        <p className="text-base font-bold text-left text-white">
                            {num}
                        </p>
                    </div>
                ) : (
                    <div
                        onClick={() => {
                            setSelected(num);
                            changePage(num);
                        }}
                        className=" cursor-pointer hover:bg-gray-200 flex flex-col justify-center items-center   h-8 w-8 relative gap-2.5 p-[5px] rounded-[50px] bg-white"
                    >
                        <p className="  text-base font-bold text-left text-[#7b7b7b]">
                            {num}
                        </p>
                    </div>
                )
            )}
            <div
                onClick={goToNext}
                className="flex flex-col justify-center items-center   h-6 w-6 relative gap-2.5 p-[5px] rounded-[50px] bg-white"
            >
                <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative w-5 h-5 cursor-pointer hover:opacity-80"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M7.42565 17.225C7.26732 17.225 7.10898 17.1667 6.98398 17.0417C6.74232 16.8 6.74232 16.4 6.98398 16.1583L12.4173 10.725C12.8173 10.325 12.8173 9.67502 12.4173 9.27502L6.98398 3.84168C6.74232 3.60002 6.74232 3.20002 6.98398 2.95835C7.22565 2.71668 7.62565 2.71668 7.86732 2.95835L13.3007 8.39168C13.7256 8.81668 13.9673 9.39168 13.9673 10C13.9673 10.6084 13.734 11.1834 13.3007 11.6084L7.86732 17.0417C7.74232 17.1584 7.58398 17.225 7.42565 17.225Z"
                        fill="#A4A4A4"
                    />
                </svg>
            </div>
        </div>
    );
}

export default Pagination;
