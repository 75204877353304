import React from "react";
import { twMerge } from "tailwind-merge";

function ModelClose({ onClick, className }) {
    return (
        <svg
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={twMerge("cursor-pointer w-10 h-10", className)}
            preserveAspectRatio="xMidYMid meet"
            onClick={onClick}
            
        >
            <path
                d="M20.0002 7.0835C12.8647 7.0835 7.0835 12.8647 7.0835 20.0002C7.0835 27.1356 12.8647 32.9168 20.0002 32.9168C27.1356 32.9168 32.9168 27.1356 32.9168 20.0002C32.9168 12.8647 27.1356 7.0835 20.0002 7.0835ZM20.0002 30.4168C14.2189 30.4168 9.5835 25.7814 9.5835 20.0002C9.5835 14.271 14.2189 9.5835 20.0002 9.5835C25.7293 9.5835 30.4168 14.271 30.4168 20.0002C30.4168 25.7814 25.7293 30.4168 20.0002 30.4168ZM25.2606 16.771C25.521 16.5627 25.521 16.146 25.2606 15.8856L24.1147 14.7397C23.8543 14.4793 23.4377 14.4793 23.2293 14.7397L20.0002 17.9689L16.7189 14.7397C16.5106 14.4793 16.0939 14.4793 15.8335 14.7397L14.6877 15.8856C14.4272 16.146 14.4272 16.5627 14.6877 16.771L17.9168 20.0002L14.6877 23.2814C14.4272 23.4897 14.4272 23.9064 14.6877 24.1668L15.8335 25.3127C16.0939 25.5731 16.5106 25.5731 16.7189 25.3127L20.0002 22.0835L23.2293 25.3127C23.4377 25.5731 23.8543 25.5731 24.1147 25.3127L25.2606 24.1668C25.521 23.9064 25.521 23.4897 25.2606 23.2814L22.0314 20.0002L25.2606 16.771Z"
                fill="#333333"
            />
        </svg>
    );
}

export default ModelClose;
