import banner from "../../assets/images/blogs/banner.png";

function Hero() {
    return (
        <div className="flex flex-col-reverse sm:flex-row justify-start w-auto mx-[7%] mt-[20vh] gap-20">
            <img src={banner} alt="banner" className="" />
            {/* w-[456px] */}
            <div className="flex flex-col justify-start items-start w-[90%]  relative gap-5">
                {/* w-[456px] */}
                <p className=" relative self-stretch   text-[61px] w-[90%] font-bold text-left text-[#373737]">
                    Blog
                    <svg
                        width={135}
                        height={18}
                        viewBox="0 0 135 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute bottom-0"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M133 8.96874C102.562 1.39734 12.2993 -4.3726 2 16"
                            stroke="#D80027"
                            strokeWidth={3}
                            stroke-linecap="round"
                        />
                    </svg>
                </p>
                {/* w-[456px] */}
                <p className="self-stretch  text-[21px] text-left text-[#7b7b7b]">
                    Encuentre artículos interesantes y noticias actualizadas
                    todos los días, redactadas por profesionales del derecho y
                    comunicadores calificados
                </p>
            </div>
        </div>
    );
}

export default Hero;
