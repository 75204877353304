import React, { useState, useEffect } from "react";
import apiurl from "../../../components/ApiConfig";
import ProfileBoard from "../../../components/platform/ProfileBoardt";
import VerificationsBoard from "../../../components/platform/VerificationsProfile";
import { useNavigate } from "react-router-dom";
import { Button, SIZE } from "../../../components/ui/buttons/Button";
function ProfessionalProfile() {
  const [userData, setUserData] = useState(null);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  // eslint-disable-next-line
  const [error, setError] = useState(null);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.location.href = "/login";
    } else {
      fetchUser(authToken);
    }
  }, []);

  const saveDescription = async (editedDescription) => {
    try {
      const authToken = localStorage.getItem("authToken");

      const response = await fetch(`${apiurl}/User/Profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ description: editedDescription }),
      });

      if (!response.ok) {
        throw new Error("Error al actualizar la descripción");
      }

      console.log("Descripción guardada:", editedDescription);
      setIsEditingDescription(false);
      window.location.reload(); // Recargar la página después de guardar
    } catch (error) {
      console.error("Error al guardar la descripción:", error);
    }
  };

  const fetchUser = async (token) => {
    try {
      const response = await fetch(`${apiurl}/User/Profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error(`Error fetching user data: ${response.status}`);
      }

      const data = await response.json();
      setUserData(data);
      setEditedDescription(data.description); // Inicializar editedDescription con el valor actual
    } catch (error) {
      setError(error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const pageRefreshed = localStorage.getItem("pageRefreshed");

    if (!authToken && !pageRefreshed) {
      navigate("/login");
    }
    if (authToken && !pageRefreshed) {
      localStorage.setItem("pageRefreshed", "true");
      window.location.reload();
    }
  }, [navigate]);

  const handleDescriptionChange = (event) => {
    setEditedDescription(event.target.value);
  };

  return (
    <div className="flex w-full flex-col items-center mt-[150px]">
      <div className="flex flex-col items-start justify-start w-full gap-6 xl:w-auto xl:flex-row">
        <div className="flex flex-col justify-start items-start gap-[27px]">
          <div className="xl:w-[375px] flex flex-col gap-5">
            <ProfileBoard />
            <VerificationsBoard />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full  xl:w-auto">
          <div
            className="relative flex flex-col w-full xl:w-[1150px] p-2.5 bg-white"
            style={{
              boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)",
              padding: "50px",
              border: "1px solid #c5c5c5",
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              Abogado Experto
            </span>
            <br />
            {isEditingDescription ? (
              <textarea
                style={{
                  wordWrap: "break-word",
                  width: "100%",
                  minHeight: "100px",
                  marginTop: "10px",
                  border: "1px solid #C5C5C5",
                  borderRadius: "5px",
                  padding: "6px"
                }}
                value={editedDescription}
                onChange={handleDescriptionChange}
              />
            ) : (
              <span style={{ wordWrap: "break-word" }}>
                {userData && userData.description}
              </span>
            )}
            {isEditingDescription ? (
              <div
                style={{
                  top: "20px",
                  position: "relative",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  size={SIZE.SMALL}
                  onClick={() => saveDescription(editedDescription)}
                  style={{ marginTop: "10px" }}
                >
                  Guardar Descripción
                </Button>
              </div>
            ) : (
              <div
                style={{
                  top: "20px",
                  position: "relative",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  size={SIZE.SMALL}
                  onClick={() => setIsEditingDescription(true)}
                  style={{ marginTop: "10px" }}
                >
                  Editar Descripción
                </Button>
              </div>
            )}
          </div>
          <div
            className="relative flex flex-col w-full xl:w-[1150px] p-2.5 bg-white"
            style={{
              boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)",
              padding: "50px",
              border: "1px solid #c5c5c5",
              top: "-1px",
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              Áreas del derecho
            </span>
            <br />
            <span>{userData && userData.areas_of_law}</span>
          </div>
          <div
            className="relative flex flex-col w-full xl:w-[1150px] p-2.5 bg-white"
            style={{
              boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)",
              padding: "50px",
              border: "1px solid #c5c5c5",
              top: "-2px",
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>
              Comentarios
            </span>
            <br />
            <img
              alt="img"
              src={require(`./img/comms.png`)}
              style={{ width: "1050px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfessionalProfile;
