import { useCallback, useState, useEffect } from "react";
import JobDetailSection from "../../../components/platform/JobDetailSection";
import JobProposals from "../../../components/platform/JobProposals";
import { useFormik } from "formik";
import * as Yup from "yup";
import jobDetailsDummyData from "../../data/platform/jobDetails.json";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
const schema = Yup.object().shape({
    offer: Yup.number().required('La oferta es obligatoria'),
    timeframe: Yup.number().required('El plazo es obligatorio'),
    proposal: Yup.string().required('La propuesta es obligatoria'),
  });
  
  function JobDetails({
    showContactButton = true,
    proposalsFirst = true,
    data = jobDetailsDummyData,
    CustomProfessionalButton = null,
    className = "flex flex-col justify-start items-center relative w-full mt-[150px] px-5"
  }) {
    const { suggestedOffer } = data;
    const [formData, setFormData] = useState({});
    const [detailsView, setDetailsView] = useState(!proposalsFirst);
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [applicants, setApplicants] = useState([]);
    const [averageOfferAmount, setAverageOfferAmount] = useState(0);
    const apiUrl = `https://eprof-production-6g7zxvibga-uc.a.run.app/api/v1/JobList/${id}/`;
  
    // Fetch project details
    useEffect(() => {
      const fetchProject = async () => {
        try {
          const response = await fetch(apiUrl);
          if (!response.ok) {
            throw new Error("Error al obtener los datos del proyecto");
          }
          const projectData = await response.json();
          setProject(projectData);
        } catch (error) {
          console.error("Error al obtener los datos del proyecto:", error);
        }
      };
  
      fetchProject();
    }, [apiUrl]);
  
    // Fetch applicants data
    useEffect(() => {
      const fetchApplicants = async () => {
        try {
          const response = await fetch(`https://eprof-production-6g7zxvibga-uc.a.run.app/job_applicants/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          const data = await response.json();
  
          if (response.ok) {
            setApplicants(data.applicants);
            // Calculate average offer amount
            const totalOfferAmount = data.applicants.reduce((acc, applicant) => acc + parseFloat(applicant.offer_amount), 0);
            const average = data.applicants.length > 0 ? (totalOfferAmount / data.applicants.length) : 0;
            setAverageOfferAmount(average.toFixed(2)); 
          } else {
           
          }
  
        } catch (error) {
          console.error('Error:', error);
          Swal.fire({
            title: 'Error',
            text: 'Ocurrió un error al obtener los aplicantes. Por favor, intenta nuevamente.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      };
  
      fetchApplicants();
    }, [id]);
    const location = useLocation()
  
    const handleChange = useCallback((target = {}) => {
      let { name, value } = target;
      setFormData((data) => ({ ...data, ...{ [name]: value } }));
    }, []);
  
    const formik = useFormik({
      initialValues: {
        offer: "",
        timeframe: "",
        proposal: ""
      },
      validationSchema: schema,
      onSubmit: (values) => {
        console.log(values);
      }
    });
  
    if (!project) {
      return <p>Cargando...</p>;
    }
  
    return (
      <div className={className}>
        <div className="flex flex-col justify-start items-start gap-[27px]">
          <div
            className={`flex flex-col lg:flex-row justify-start items-start ${
              detailsView ? "w-full max-w-[816px]" : "w-full"
            } relative gap-4`}
          >
            <p className="flex-grow w-full max-w-[586px] lg:text-lg text-xl font-bold text-left text-[#373737]">
              {project.project_name}
            </p>
            <div className="flex flex-col justify-start items-start shrink-0 relative gap-2.5 pl-5 border-t-0 border-r-0 border-b-0 border-l border-[#cdcdcd]">
              <p className="lg:text-lg text-xl text-left text-[#373737]">
                {applicants.length} ofertas
              </p>
              <div className="flex justify-start items-start gap-2.5">
                <div className="flex relative flex-col justify-start items-start">
                  <p className="text-lg font-bold text-left text-[#373737]">
                    GTQ {averageOfferAmount}
                  </p>
                  <p className="text-xs text-center text-[#7b7b7b]">
                    Oferta promedio
                  </p>
                </div>
                <div className="flex relative flex-col justify-start items-start">
                  <p className="text-lg font-bold text-left text-[#373737]">
                    GTQ {suggestedOffer}
                  </p>
                  <p className="text-xs text-center text-[#7b7b7b]">
                    Oferta sugerida
                  </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* w-full was added later - need to check across pages  */}
                <div className="flex flex-col items-start w-full">
                    <div className="flex justify-start items-start">
                    <div
                            onClick={() => setDetailsView(0)}
                            className="cursor-pointer hover:opacity-80 flex flex-col justify-center items-start   h-[42px] lg:w-[126px] rounded-tl-[5px] rounded-tr-[5px]"
                        >
                            <div
                                className={`flex  justify-center  text-base text-center  ${
                                    !detailsView
                                        ? "text-[#373737]  font-bold"
                                        : "text-[#7b7b7b]"
                                } items-center self-stretch flex-grow relative gap-[5px] px-4 py-[5px]`}
                            >
                                 Detalles
                               
                            </div>

                            {!detailsView ? (
                                <div className="flex flex-col justify-start items-start self-stretch   relative gap-2.5">
                                    <div className="self-stretch   h-0.5 bg-[#d80027]" />
                                </div>
                            ) : (
                                <div className="flex flex-col justify-start items-start   lg:w-[121px] relative gap-2.5">
                                    <div className="  w-0.5 h-0.5 opacity-0 bg-[#a4a4a4]" />
                                </div>
                            )}
                        </div>
                        <div
                            onClick={() => setDetailsView(1)}
                            className="cursor-pointer hover:opacity-80 flex flex-col justify-center items-start   h-[42px] lg:w-[129px] rounded-tl-[5px] rounded-tr-[5px]"
                        >
                            <div
                                className={`flex  justify-center text-base  text-center ${
                                    detailsView
                                        ? "text-[#373737]  font-bold"
                                        : "text-[#7b7b7b]"
                                } items-center self-stretch flex-grow relative gap-[5px] px-4 py-[5px]`}
                            >
                                 Propuestas
                            </div>
                            {detailsView ? (
                                <div className="flex flex-col justify-start items-start self-stretch   relative gap-2.5">
                                    <div className="self-stretch   h-0.5 bg-[#d80027]" />
                                </div>
                            ) : (
                                <div className="flex flex-col justify-start items-start   lg:w-[121px] relative gap-2.5">
                                    <div className="  w-0.5 h-0.5 opacity-0 bg-[#a4a4a4]" />
                                </div>
                            )}
                        </div>
                       
                    </div>

                    {detailsView ? (
                     <JobProposals
                     handleChange={handleChange}
                     formData={formData}
                     CustomProfessionalButton={CustomProfessionalButton}
                     data={data}
                     showContactButton={showContactButton}
                     showContractButton={location.pathname === "/platform/client/urgent-advice/processing"}
                 />
                    ) : (
                        <JobDetailSection
                        formik={formik}
                        handleChange={handleChange}
                        formData={formData}
                        data={data}
                    />
                        
                    )}
                </div>
            </div>
        </div>
    );
}

export default JobDetails;