import React, { useCallback, useState } from "react";
import { noop } from "../../../utils/noop";
import Input from "../../../components/ui/Input";
import InfoHover from "../../../components/ui/InfoHover";
import { UploadDocuments } from "../../../components/client-only/UrgentHiringForm";
import { Button, COLOR, SIZE } from "../../../components/ui/buttons/Button";

const ContactFormModal = ({ setShowTickDialog = noop }) => {
    const [formData, setFormData] = useState({});

    const fileHandler = useCallback((event) => {
        const documents = event.target.files;
        setFormData({
            documents
        });
    }, []);

    const handleChange = useCallback(
        (e) => {
            // console.log("e : ");
            // console.log(e);
            const { name, value } = e.target;
            if (name === "documents") {
                fileHandler(e);
                return;
            }
            if (name === "areas") {
                if (
                    Array.isArray(formData.areas) &&
                    formData.areas.includes(value)
                ) {
                    return;
                }
                setFormData((prev) => ({
                    ...prev,
                    areas: [...prev.areas, value]
                }));
                return;
            }
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        },
        [fileHandler, formData.areas]
    );

    const removeFileByIndex = (index) => {
        setFormData((prevFormData) => {
            // Create a copy of the documents array
            const newDocuments = [...prevFormData.documents];

            // Check if the index is valid
            if (index >= 0 && index < newDocuments.length) {
                // Remove the file at the specified index
                newDocuments.splice(index, 1);
                console.log(`File at index ${index} removed successfully.`);
            } else {
                console.log(`Invalid index: ${index}`);
            }

            // Return the updated formData object
            return { ...prevFormData, documents: newDocuments };
        });
    };

    return (
        <div
            className="flex flex-col  w-[100vw] max-w-[674px] gap-[30px] p-[30px] rounded-[20px] bg-white mt-40"
            style={{ boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)" }}
        >
            <div className="flex flex-col   gap-[30px]">
                <div className="flex flex-col  relative gap-2.5">
                    <p className="  text-[21px] text-[#373737]">Contáctanos</p>
                    <p className=" text-base text-left text-[#7b7b7b]">
                        Rellene el siguiente formulario para continuar con su
                        solicitud
                    </p>
                </div>
                <div className="flex flex-col   gap-3">
                    <Input
                        label="Nombre"
                        placeholder="Nombre"
                        required
                        onChange={handleChange}
                        value={formData.name}
                        name="name"
                    />

                    <Input
                        label="Correo electrónico"
                        placeholder="CristianLopez@gmail.com"
                        disabled
                        onChange={handleChange}
                        value={formData.email}
                        name="email"
                    />

                    <Input
                        label="Asunto"
                        placeholder="Cristian Lopez"
                        required
                        onChange={handleChange}
                        value={formData.asunto}
                        name="asunto"
                    />

                    <Input
                        type="long"
                        value={formData.description}
                        label="Descripción"
                        name="description"
                        onChange={handleChange}
                        // required
                        placeholder="Describe aquí tu inquietud"
                        textAreaLimit={1200}
                        addInputClass="h-[129px] "
                    />

                    <div className="flex flex-col gap-[5px] ">
                        <div className="flex justify-start items-center  gap-[5px]">
                            <InfoHover red={false} text={"Adjuntar archivos"} />

                            <p className=" text-sm text-left text-[#373737]">
                                Adjuntar archivos
                            </p>
                        </div>

                        <UploadDocuments
                            files={formData.documents}
                            handleChange={handleChange}
                            text="+Añadir imagen"
                            removeFile={removeFileByIndex}
                            supportPage={true}
                        />
                    </div>
                </div>
                 <div className="flex justify-between items-center ">
                        
                        <Button
                            color={COLOR.WHITE}
                            size={SIZE.MEDIUM}
                            addClass={"h-11"}
                            onClick={""}
                        >
                            Cancelar
                        </Button>
                        <Button
                            color={COLOR.RED}
                            size={SIZE.MEDIUM}
                            type="submit"
                            addClass={"h-11"}
                            onClick={() => setShowTickDialog(true)}
                        >
                            Enviar
                        </Button>

                        
                    </div>
            </div>
        </div>
    );
};

export default ContactFormModal;
