import Header from "../components/Header";
import React from "react";

function NotFound() {
    return (
        <>
            <Header />
            <div className="w-full h-[150vh] flex flex-row items-center justify-center   uppercase font-bold text-2xl ">
                NotFound Page - You've been served a bad link
            </div>
        </>
    );
}

export default NotFound;
