import React from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const defaultLinks = [
    { to: ".", text: "Ofertas" },
    { to: "currentWork", text: "Trabajo actual" },
    { to: "previousWork", text: "Trabajo anterior" },
    { to: "quotations", text: "Cotizaciones" }
];

const MyProjectsNav = ({links = defaultLinks}) => {
    console.log("hhelo")
    return (
        <div className="flex justify-start items-center overflow-x-auto whitespace-nowrap">
            {links.map((link, index) => (
                <NavLink
                    key={index}
                    to={link.to}
                    end={index === 0}
                    className={({ isActive }) =>
                        twMerge(
                            "px-2 sm:px-4 py-[5px] rounded-tr-[5px] text-[#7b7b7b] text-xs sm:text-sm md:text-base ",
                            isActive && "border-b border-[#d80027] font-bold text-black"
                        )
                    }
                >
                    {link.text}
                </NavLink>
            ))}
        </div>
    );
};

export default MyProjectsNav;
