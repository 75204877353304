import { noop } from "../../utils/noop";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";
import Facebook from "../ui/socials/Facebook";
import Linkedin from "../ui/socials/Linkedin";
import Mail from "../ui/socials/Mail";
import Twitter from "../ui/socials/Twitter";
import WhatsappInvite from "../ui/socials/Whatsapp";

function Invite({ link, closeDialog = noop }) {
    return (
        <div
            className="flex flex-col justify-start items-center lg:w-[823px] relative gap-[30px] p-[30px] bg-white"
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
        >
            <div className="flex justify-center items-center self-stretch  relative gap-[15px]">
                <p className=" text-2xl lg:text-4xl font-bold text-left text-[#373737]">
                    Compartir esta publicacion
                </p>
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-start  gap-[30px]">
                <Mail />
                <Linkedin />
                <WhatsappInvite />
                <Twitter />
                <Facebook />
            </div>
            <div className="flex flex-col justify-start items-start  relative gap-2.5">
                <p className=" text-lg font-bold text-left text-[#373737]">
                    O copiar enlace
                </p>
                <div className="flex flex-col lg:flex-row justify-start items-start  lg:w-[594px]">
                    <div className="flex flex-col justify-start items-start flex-grow gap-[5px]">
                        <div className="flex justify-end items-center self-stretch  h-9 gap-2.5 px-4 py-2.5 xl:rounded-tl xl:rounded-bl bg-white border border-[#cdcdcd]">
                            <div className="flex justify-start items-center flex-grow relative gap-2.5">
                                {/* <input
                                    className="text-base text-center text-[#7b7b7b] border-none h-full w-full"
                                    type="text"
                                    placeholder="https://www.eprof.com/post/5d6b9ea"
                                    disabled
                                /> */}
                                <p className=" text-base text-center text-[#7b7b7b]">
                                    {link ??
                                        "https://www.eprof.com/post/5d6b9ea"}
                                </p>
                            </div>
                        </div>
                    </div>

                    <Button
                        size={SIZE.SMALL}
                        color={COLOR.BLACK}
                        // onClick={handleCopy}
                        // addClass={"min-w-fit w-[180px]"}
                        text="Copiar"
                        addClass={
                            "flex justify-center items-center w-full  lg:w-[118px] h-9 relative gap-2.5 px-4 py-2.5 rounded-none xl:rounded-tr xl:rounded-br  bg-[#292929]"
                        }
                        iconName={"copy"}
                    />
                </div>
            </div>
            <svg
                onClick={closeDialog}
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute right-0 w-10 h-10 cursor-pointer top-3"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M20.0002 7.0835C12.8647 7.0835 7.0835 12.8647 7.0835 20.0002C7.0835 27.1356 12.8647 32.9168 20.0002 32.9168C27.1356 32.9168 32.9168 27.1356 32.9168 20.0002C32.9168 12.8647 27.1356 7.0835 20.0002 7.0835ZM20.0002 30.4168C14.2189 30.4168 9.5835 25.7814 9.5835 20.0002C9.5835 14.271 14.2189 9.5835 20.0002 9.5835C25.7293 9.5835 30.4168 14.271 30.4168 20.0002C30.4168 25.7814 25.7293 30.4168 20.0002 30.4168ZM25.2606 16.771C25.521 16.5627 25.521 16.146 25.2606 15.8856L24.1147 14.7397C23.8543 14.4793 23.4377 14.4793 23.2293 14.7397L20.0002 17.9689L16.7189 14.7397C16.5106 14.4793 16.0939 14.4793 15.8335 14.7397L14.6877 15.8856C14.4272 16.146 14.4272 16.5627 14.6877 16.771L17.9168 20.0002L14.6877 23.2814C14.4272 23.4897 14.4272 23.9064 14.6877 24.1668L15.8335 25.3127C16.0939 25.5731 16.5106 25.5731 16.7189 25.3127L20.0002 22.0835L23.2293 25.3127C23.4377 25.5731 23.8543 25.5731 24.1147 25.3127L25.2606 24.1668C25.521 23.9064 25.521 23.4897 25.2606 23.2814L22.0314 20.0002L25.2606 16.771Z"
                    fill="#333333"
                />
            </svg>
        </div>
    );
}

export default Invite;
