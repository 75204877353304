import { twMerge } from "tailwind-merge";
import { checkedSvg, uncheckedSvg } from "../../utils/icons";
import InfoHover from "./InfoHover";
import { v4 as uuidv4 } from 'uuid';

import "./input.module.css";

const noop = () => {};

function Input({
    onChange = noop,
    onClick = noop,
    onBlur = noop,
    value = "",
    label = "", // title for checkbox
    desc = "",
    icon = null,
    placeholder = "",
    name = "",
    required = false,
    checked = false,
    className = null,
    inputClassName = null,
    borderAt = [],
    arrowBg = false,
    type = "text",
    selectList = [],
    addClass = "",
    addInputClass = "",
    showTextAreaLimit= true,
    textAreaLimit = 1500,
    textAreaHeight = 500,
    onIconClick,
    min = null,
    max = null,

    info = null,
    defaultValue = undefined,
    labelClass = null,
    disabled = false,
    iconClass = "",
    addOptionClass = ""
}) {
    if (type === "checkbox") {
        let borderClass = "";
        borderAt.forEach((direction) => {
            borderClass += `border-${direction[0]} `;
        });
        return (
            <div
                onClick={(e) => {
                    onClick({
                        name,
                        value: !checked
                    });
                }}
                // w-full lastly
                className={`min-h-[80px] w-full sm:w-[50%] cursor-pointer hover:opacity-80 flex justify-start items-center flex-grow relative gap-2.5 px-4 py-2.5   ${borderClass} border-[#cdcdcd]`}
            >
                <div className="flex justify-start items-center flex-grow relative gap-2.5">
                    <img src={checked ? checkedSvg : uncheckedSvg} alt="" />

                    <div className="flex flex-col justify-center items-start flex-grow relative gap-[3px]">
                        <p className="self-stretch   sm:w-[266px] text-sm font-bold text-left text-[#373737]">
                            {label}
                        </p>
                        <p className="self-stretch   sm:w-[266px] text-xs text-left text-[#373737]">
                            {desc}
                        </p>
                    </div>
                </div>
                {icon ? icon : null}
            </div>
        );
    } else if (type === "dropdown") {
        return (
            <div
                className={
                    className
                        ? className
                        : "flex relative flex-col flex-grow justify-start items-start w-full gap-[5px]"
                }
            >
                {label && (
                    <div className={twMerge("flex justify-start items-center self-stretch   relative gap-[5px]", labelClass && labelClass)}>
                        <p className="text-sm text-left">
                            <span className="  text-sm text-left text-[#373737]">
                                {label}
                            </span>
                            {required && (
                                <span className="  text-sm text-left text-[#d80027]">
                                    *
                                </span>
                            )}
                        </p>
                    </div>
                )}

                {/* {Boolean(arrowBg) ? (
          <div className="flex bg-[#A4A4A4] w-14 absolute right-0 top-0 h-full  "></div>
        ) : null} */}
                <select
                    defaultValue={defaultValue}
                    value={value}
                    onChange={onChange}
                    onClick={onClick}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    className={`formSelectList  ${
                        inputClassName
                            ? inputClassName
                            : // pr-5
                              twMerge(
                                  `flex   overflow-ellipsis justify-end items-center self-stretch w-full  h-12 relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929]`,
                                  addInputClass
                              )
                    } `}
                >
                    <div className={twMerge(arrowBg && "selectList__greyBg", iconClass)} />
                    {selectList.map((option) => (
                        <option
                            key={uuidv4()}
                            className={twMerge("capitalize selectOption", 
                                option === value && "selectedOption", addOptionClass)}
                            value={option}
                            selected={option === value}
                        >
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        );
    } else if (type === "long") {
        return (
            <div
                className={
                    className
                        ? className
                        : twMerge(
                              `flex flex-col flex-grow justify-start items-start w-full gap-[5px] h-[178px]`,
                              addClass
                          )
                }
            >
                {label && (
                    <div className="flex justify-start items-center self-stretch   relative gap-[5px]">
                        <p>
                            <span
                                className={
                                    labelClass
                                        ? labelClass
                                        : "text-sm text-left text-[#373737]"
                                }
                            >
                                {label}
                            </span>
                            {required && (
                                <span className="  text-sm text-left text-[#d80027]">
                                    *
                                </span>
                            )}
                        </p>
                    </div>
                )}
                <textarea
                    height={textAreaHeight}
                    size={textAreaLimit}
                    value={value}
                    onChange={onChange}
                    onClick={onClick}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    className={
                        inputClassName
                            ? inputClassName
                            : twMerge(
                                  `flex w-full justify-end items-center self-stretch flex-grow  gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929]`,
                                  addInputClass
                              )
                    }
                />
                {showTextAreaLimit && <div className="flex justify-end items-start self-stretch  relative gap-2.5">
                    <p
                        className={` text-sm font-semibold text-left ${
                            value.length > textAreaLimit
                                ? "text-red-600"
                                : "text-[#7b7b7b]"
                        }`}
                    >
                        {value.length}/{textAreaLimit}
                    </p>
                </div>}
            </div>
        );
    } else if (type === "underline") {
        return (
            <input
                // disabled={disabled}
                autoComplete={type === "password" ? "new-password" : ""}
                value={value}
                onChange={onChange}
                onClick={onClick}
                type={type}
                name={name}
                className="flex text-base font-bold text-left outline-none    justify-center items-center w-full  relative gap-2.5 pb-[5px] border-t-0 border-r-0 border-b border-l-0 border-[#d80027]"
                placeholder={placeholder}
            />
        );
    }

    return (
        <div
            className={
                className
                    ? className
                    : twMerge("flex flex-col flex-grow justify-start items-start w-full gap-[5px]", addClass)
            }
        >
            {label && (
                <div className="flex justify-start items-center self-stretch   relative gap-[5px]">
                    <p>
                        <span
                            className={
                                labelClass
                                    ? labelClass
                                    : "text-sm text-left text-[#373737]"
                            }
                        >
                            {label}
                        </span>
                        {required && (
                            <span className="  text-sm text-left text-[#d80027]">
                                *
                            </span>
                        )}
                    </p>
                    {Boolean(info) && <InfoHover text={info} red />}
                </div>
            )}
            <div className="w-full relative">
                {disabled ? (
                    <input
                        disabled={disabled}
                        autoComplete={type === "password" ? "new-password" : ""}
                        value={value}
                        onChange={onChange}
                        // onClick={onClick}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        className={
                            inputClassName
                                ? inputClassName
                                : twMerge(
                                      `flex w-full  text-base text-left text-[#7b7b7b] justify-start items-center self-stretch  h-12  gap-2.5 px-4 py-2.5 rounded bg-[#f2f2f2] border border-[#cdcdcd]`,
                                      addInputClass
                                  )
                        }
                    />
                ) : (
                    <input
                        autoComplete={type === "password" ? "new-password" : ""}
                        value={value}
                        onChange={onChange}
                        onClick={onClick}
                        onBlur={onBlur}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        {...(min && { min })}
                        {...(max && { max })}
                        className={
                            inputClassName
                                ? inputClassName
                                : twMerge(
                                      "formInput w-full overflow-ellipsis flex justify-end items-center h-12 gap-2.5 px-4 py-2.5 rounded border border-[#292929] ",
                                      addInputClass
                                    )
                        }
                    />
                )}
                {icon ? (
                    <span onClick={onIconClick} className={twMerge("cursor-pointer absolute top-1/2 -translate-y-1/2 right-0 mr-2", disabled && "bg-[#f2f2f2] ", iconClass)}>
                        {icon}
                    </span>
                ) : null}
            </div>
        </div>
    );
}

export default Input;
