import { twMerge } from "tailwind-merge";

function AngleUp({ active, size, onClick }) {
    const handleClick = (e) => {
        if (!active) return;
        onClick(e);
    };
    return (
        <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
            className={twMerge(active && "cursor-pointer")}
        >
            <g id="Angle Up">
                <path
                    id="angle-up"
                    d="M12.25 9.65625C12.0938 9.5 11.875 9.5 11.7188 9.65625L7.09375 14.2188C6.9375 14.375 6.9375 14.625 7.09375 14.75L7.71875 15.375C7.875 15.5312 8.09375 15.5312 8.25 15.375L12 11.6875L15.7188 15.375C15.875 15.5312 16.125 15.5312 16.25 15.375L16.875 14.75C17.0312 14.625 17.0312 14.375 16.875 14.2188L12.25 9.65625Z"
                    fill={active ? "black" : "#A4A4A4"}
                    // fill={"black"}
                />
            </g>
        </svg>
    );
}

export default AngleUp;
