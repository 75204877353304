
import React from 'react';
import error404 from "../../../assets/images/404/404.png";
//import { Button, SIZE } from "../../../components/ui/buttons/Button";

function Error4044() {
  return (
    <div className="flex w-full flex-col items-center mt-[150px]" style={{ position:'relative', top: "-50px"}}  >
     <div style={{ fontSize:"40px", fontWeight:"700"}}>Página en construcción</div>
     <div  style={{ fontSize:"18px", fontWeight:"400", top:"10px", position:'relative'}}>Hola actualmente estamos trabajando en esta página, lo invitamos a regresar pronto.</div>
     <div className="  w-[63vw] h-[30vw] xl:w-[45vw]">
                        <img
                            src={error404}
                            alt="error404"
                            // xl:h-[75vh]
                            className={`bottom-[0%] right-[0%] object-contain xl:w-[95%]  `}
                        />
                    </div>
                    <div style={{ top:"35px" , position:'relative'}}>
                    {/*<Button size={SIZE.LARGE}stretch  >
                       Regresar al tablero
                    </Button>*/}
                    </div>
    </div>
  );
}

export default Error4044;