import React, { useState } from "react";
import Input from "../../../components/ui/Input";
import Pagination from "../../../components/Pagination";
import CustomDropdown from "../../../components/ui/CustomDropdown";
import { Button, COLOR, SIZE } from "../../../components/ui/buttons/Button";

const Section = ({ data, button = false }) => (
    <div className="flex flex-col md:flex-row items-center   relative gap-6 p-4 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
        <p className=" flex-1 text-base text-left text-[#373737]">
            {data.title}
        </p>
        <p className=" flex-1 text-base text-left text-[#373737]">
            {data.recipient}
        </p>
        <p className=" flex-1 text-sm text-left text-[#373737]">
            {data.total}
        </p>
        <p className=" flex-1 text-sm text-left text-[#373737]">
            {data.startDate}
        </p>
        <p className=" flex-1 text-sm text-left text-[#373737]">
            {data.endDate}
        </p>
        <p className=" flex-1 text-sm text-left text-[#373737]">
            {data.status}
        </p>
        <div>
            {button ? (
                <Button
                    stretch
                    color={COLOR.WHITE}
                    size={SIZE.SMALL}
                    text="Iniciar sesión"
                />
            ) : (
                <CustomDropdown options={["Cancelar"]} className={""}/>
            )}
        </div>
    </div>
);

const Quotations = () => {
    const [order, setOrder] = useState(10);
    const [search, setSearch] = useState("");

    const quotationData = [
        {
            heading: [
                "Título",
                "Para",
                "Total",
                "Inicio",
                "Final",
                "Estado",
                "Acciones",
            ],
            content: [
                {
                    title: "Lorem ipsum",
                    recipient: "@Luisvazquez",
                    total: "GTQ 25,00",
                    startDate: "03/06/2023",
                    endDate: "03/07/2023",
                    status: "Abierto",
                    button: true
                },
                {
                    title: "Lorem ipsum",
                    recipient: "@Luisvazquez",
                    total: "GTQ 25,00",
                    startDate: "03/06/2023",
                    endDate: "03/07/2023",
                    status: "Cerrado",
                    button: true
                },
                {
                    title: "Lorem ipsum",
                    recipient: "@Luisvazquez",
                    total: "GTQ 25,00",
                    startDate: "03/06/2023",
                    endDate: "03/07/2023",
                    status: "Expira en 1 día",
                    button: false
                },
            ],
        },
    ];

    return (
        <div className="flex flex-col justify-center items-center  gap-[30px] p-[30px] bg-white border border-[#cdcdcd]">
            {/* searchBar */}
            <div className="flex  items-center self-start w-full max-w-[400px]  relative gap-2.5">
                <Input
                    // label="Apellido"
                    placeholder="Buscar proyectos"
                    // required
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    name="search"
                    suggestions
                    suggestionList={[]}
                    addClass={""}
                    addInputClass="w-full max-w-[400px]"
                    icon={
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6 "
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                fill="#333333"
                            />
                        </svg>
                    }
                />
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M18.4697 4H5.50091C4.15716 4 3.46966 5.625 4.43841 6.5625L9.50091 11.625V17C9.50091 17.5 9.71966 17.9375 10.0947 18.25L12.0947 19.75C13.0634 20.4062 14.5009 19.7812 14.5009 18.5312V11.625L19.5322 6.5625C20.5009 5.625 19.8134 4 18.4697 4ZM13.0009 11V18.5L11.0009 17V11L5.50091 5.5H18.5009L13.0009 11Z"
                        fill="#333333"
                    ></path>
                </svg>
            </div>

            {/* table */}
            <div className="flex flex-col justify-center items-center w-full ">
                {quotationData.map((section, index) => (
                    <div key={index} className="w-full">
                        {section.heading && section.heading.length > 0 && (
                            <div className="md:flex hidden   items-center   gap-4 px-4 py-3 bg-[#f2f2f2]">
                                {section.heading.map((heading, index) => (
                                    <p
                                        key={index}
                                        className="w-full text-sm font-bold text-left text-[#373737]"
                                    >
                                        {heading}
                                    </p>
                                ))}
                            </div>
                        )}
                        {section.content &&
                            section.content.length > 0 &&
                            section.content.map((data, index) => (
                                <Section
                                    key={index}
                                    data={data}
                                    button={data.button}
                                />
                            ))}
                    </div>
                ))}
            </div>

            {/* bottomnav */}
            <div className="flex flex-col sm:flex-row gap-10 items-center w-full justify-between">
                <div className="flex items-center relative gap-2.5">
                    <p className="  text-base text-left text-[#373737]">
                        Postulaciones por página:
                    </p>

                    <div className="flex flex-col justify-center items-start  gap-[5px]">
                        <Input
                            name="order"
                            value={order}
                            onChange={(e) => setOrder(e.target.value)}
                            type="dropdown"
                            selectList={["10", "50", "100"]}
                            inputClassName={
                                " text-base w-[100px] text-left text-[#373737] flex  items-center    gap-2.5 px-3 py-1.5 rounded h-9 bg-white border-t border-r border-b border-l border-[#292929]"
                            }
                        />
                    </div>
                </div>
                <Pagination />
            </div>
        </div>
    );
};

export default Quotations;
