import React from "react";

function CustomerSupport() {
    return (
        <div className="flex flex-col  gap-[5px] w-full">
            <div className="flex flex-col justify-center  pl-4 pb-px border-b border-[#cdcdcd]">
                 {" "}
                <div className="flex relative justify-start items-center self-stretch">
                    <p className="flex-grow  text-[21px] font-bold text-left text-[#373737]">
                        Comenzando en EPROF
                    </p>
                    <div className="flex justify-start items-center  relative gap-2.5 px-4 py-2.5">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M18.7812 9.25L18.1875 8.625C18.0312 8.46875 17.7812 8.46875 17.6562 8.625L12 14.2812L6.3125 8.625C6.1875 8.46875 5.9375 8.46875 5.78125 8.625L5.1875 9.25C5.03125 9.375 5.03125 9.625 5.1875 9.78125L11.7188 16.3125C11.875 16.4688 12.0938 16.4688 12.25 16.3125L18.7812 9.78125C18.9375 9.625 18.9375 9.375 18.7812 9.25Z"
                                fill="#333333"
                            ></path>
                        </svg>
                    </div>
                </div>
                <div className="flex relative flex-col gap-5 justify-center items-start self-stretch p-5">
                    <p className="self-stretch  text-base text-left text-[#373737]">
                        <span className="self-stretch  text-base text-left text-[#373737]">
                            Lorem ipsum dolor sit amet consectetur. Lectus
                            volutpat tincidunt aliquam leo ac quis magna ornare.
                            Metus at libero in commodo. Amet tristique
                            pellentesque egestas ac. Quisque ultrices non neque
                            ut vitae tristique. Vulputate pellentesque donec
                            aliquet dis ullamcorper est. Fermentum id interdum
                            neque id blandit.
                        </span>
                        <br />
                        <span className="self-stretch  text-base text-left text-[#373737]">
                            Platea molestie eu amet at rhoncus velit. Facilisis
                            mi tincidunt sed ornare. Felis ornare semper et nisl
                            semper. Integer tempus viverra vitae cras sapien
                            fames eget imperdiet nibh. Quis curabitur volutpat
                            consectetur turpis. Quam aliquet in sapien
                            pellentesque cursus tellus. Et egestas eu tristique
                            eget. Neque egestas eu nascetur viverra diam turpis
                            in volutpat. Iaculis tortor consequat lacus quis
                            molestie. Diam tortor velit arcu mi in hendrerit non
                            aliquet.
                        </span>
                        <br />
                        <span className="self-stretch  text-base text-left text-[#373737]">
                            Integer quam lacus commodo est duis duis nulla. Amet
                            fermentum condimentum scelerisque nec libero
                            pharetra amet. Sit varius pellentesque lobortis amet
                            sed nulla sed quam. Neque vitae dui amet viverra.
                            Enim aliquet volutpat mauris nulla nulla cursus in.
                            Massa egestas ornare orci ut sollicitudin.
                        </span>
                        <br />
                        <span className="self-stretch  text-base text-left text-[#373737]">
                            Sed faucibus aliquam maecenas cursus mauris arcu
                            maecenas sapien. Nisi turpis volutpat vel enim
                            viverra nunc malesuada. Scelerisque morbi diam eu
                            sollicitudin. Non facilisis id turpis justo nunc sit
                            nunc. Mi sed fringilla fusce dictumst tristique.
                            Volutpat urna feugiat duis tortor. Ac sed lobortis
                            faucibus quis vitae nulla non rutrum. Lectus amet
                            facilisis faucibus pellentesque. Bibendum sem
                            pellentesque fermentum orci adipiscing ultricies eu
                            nec. Auctor dignissim eget auctor quam. Amet congue
                            bibendum duis tortor non mattis proin. Scelerisque
                            neque velit sit felis a sem enim sit. Cras tempor in
                            sit vitae ullamcorper non lobortis.
                        </span>
                        <br />
                        <br />
                        <span className="self-stretch  text-base text-left text-[#373737]">
                            Blandit tempus neque elementum in risus tincidunt.{" "}
                        </span>
                        <br />
                        <span className="self-stretch  text-base text-left text-[#373737]">
                            Blandit tempus neque elementum in risus tincidunt.{" "}
                        </span>
                        <br />
                        <span className="self-stretch  text-base text-left text-[#373737]">
                            Blandit tempus neque elementum in risus tincidunt.{" "}
                        </span>
                        <br />
                        <span className="self-stretch  text-base text-left text-[#373737]">
                            Blandit tempus neque elementum in risus tincidunt.
                        </span>
                    </p>
                    <div className="flex flex-col justify-center items-center self-stretch  relative gap-2.5">
                        <img
                            src="registro-1.jpeg"
                            alt="registro-1.jpeg"
                            className="w-full  max-w-[536px] min-h-[335px] object-cover"
                        />
                    </div>
                    <div className="flex flex-col justify-center items-center self-stretch  relative gap-4 p-4 bg-[#f2f2f2]">
                        <p className="self-stretch  text-[21px] font-bold text-center text-[#373737]">
                            ¿Fue útil este artículo?
                        </p>
                        <div className="flex justify-start items-center  relative gap-[30px]">
                            <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-10 h-10"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M30.9349 21.6139C31.4036 20.8327 31.6641 19.9993 31.6641 19.0098C31.6641 16.7181 29.6849 14.5827 27.1849 14.5827H25.2578C25.5182 13.9056 25.7266 13.1243 25.7266 12.1348C25.7266 8.33268 23.7474 6.66602 20.7786 6.66602C17.5495 6.66602 17.7578 11.6139 17.0286 12.3431C15.8307 13.541 14.4245 15.8327 13.4349 16.666H8.33073C7.39323 16.666 6.66406 17.4473 6.66406 18.3327V30.8327C6.66406 31.7702 7.39323 32.4993 8.33073 32.4993H11.6641C12.3932 32.4993 13.0703 31.9785 13.2266 31.3014C15.5703 31.3535 17.1849 33.3327 22.4974 33.3327C22.9141 33.3327 23.3307 33.3327 23.6953 33.3327C27.7057 33.3327 29.4766 31.3014 29.5286 28.3848C30.2578 27.4473 30.6224 26.1452 30.4661 24.8952C30.987 23.9577 31.1432 22.8118 30.9349 21.6139ZM27.7057 24.4264C28.3828 25.5202 27.7578 26.9785 26.9766 27.4473C27.3932 29.9473 26.0391 30.8327 24.2161 30.8327H22.237C18.5391 30.8327 16.0911 28.9056 13.3307 28.9056V19.166H13.8516C15.362 19.166 17.3932 15.5202 18.7995 14.1139C20.2578 12.6556 19.7891 10.1556 20.7786 9.16602C23.2266 9.16602 23.2266 10.8848 23.2266 12.1348C23.2266 14.166 21.7682 15.1035 21.7682 17.0827H27.1849C28.2786 17.0827 29.112 18.0723 29.1641 19.0618C29.1641 19.9993 28.487 20.9889 27.9661 20.9889C28.6953 21.7702 28.8516 23.3848 27.7057 24.4264ZM11.2474 29.166C11.2474 29.8952 10.6745 30.416 9.9974 30.416C9.26823 30.416 8.7474 29.8952 8.7474 29.166C8.7474 28.4889 9.26823 27.916 9.9974 27.916C10.6745 27.916 11.2474 28.4889 11.2474 29.166Z"
                                    fill="#333333"
                                ></path>
                            </svg>
                            <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-10 h-10"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M30.9349 18.4368C31.1432 17.2389 30.987 16.0931 30.4661 15.1556C30.6224 13.9056 30.2578 12.6035 29.5286 11.666C29.4766 8.74935 27.7057 6.66602 23.6953 6.66602C23.3307 6.66602 22.9141 6.7181 22.4974 6.7181C17.1328 6.7181 15.4141 8.74935 13.3307 8.74935H12.7578C12.4453 8.54102 12.0807 8.33268 11.6641 8.33268H8.33073C7.39323 8.33268 6.66406 9.11393 6.66406 9.99935V22.4993C6.66406 23.4368 7.39323 24.166 8.33073 24.166H11.6641C12.237 24.166 12.8099 23.8535 13.0703 23.3327H13.4349C14.4245 24.2181 15.8307 26.5098 17.0286 27.7077C17.7578 28.4368 17.5495 33.3327 20.7786 33.3327C23.7474 33.3327 25.7266 31.7181 25.7266 27.916C25.7266 26.9264 25.5182 26.1452 25.2578 25.4681H27.1849C29.6849 25.4681 31.6641 23.3327 31.6641 21.041C31.6641 19.9993 31.4036 19.2181 30.9349 18.4368ZM9.9974 22.0827C9.26823 22.0827 8.7474 21.5618 8.7474 20.8327C8.7474 20.1556 9.26823 19.5827 9.9974 19.5827C10.6745 19.5827 11.2474 20.1556 11.2474 20.8327C11.2474 21.5618 10.6745 22.0827 9.9974 22.0827ZM27.1849 22.9681H21.7682C21.7682 24.9473 23.2266 25.8848 23.2266 27.916C23.2266 29.166 23.2266 30.8327 20.7786 30.8327C19.7891 29.8952 20.2578 27.3952 18.7995 25.9368C17.3932 24.5306 15.362 20.8327 13.8516 20.8327H13.3307V11.1452C16.0911 11.1452 18.5391 9.2181 22.237 9.2181H24.2161C26.0391 9.2181 27.3932 10.1035 26.9766 12.6035C27.7578 13.0723 28.3828 14.5306 27.7057 15.6243C28.8516 16.666 28.6953 18.2806 27.9661 19.0618C28.487 19.0618 29.1641 20.0514 29.1641 20.9889C29.112 21.9785 28.2786 22.9681 27.1849 22.9681Z"
                                    fill="#333333"
                                ></path>
                            </svg>
                        </div>
                    </div>
                    <div className="flex flex-col w-full max-w-80 relative gap-[21px] px-4">
                        <div className="flex self-stretch  relative gap-2.5 pb-3  border-b  border-[#e6e6e6]">
                            <p className="flex-grow  text-lg font-bold text-left text-[#373737]">
                                Artículos relacionados
                            </p>
                        </div>
                        <p className="self-stretch   text-base text-left text-[#d80027]">
                            Pagos por hitos
                        </p>
                        <p className="self-stretch   text-base text-left text-[#d80027]">
                            Membresías de profesionales
                        </p>
                        <p className="self-stretch   text-base text-left text-[#d80027]">
                            Lorem ipsum
                        </p>
                        <p className="self-stretch   text-base text-left text-[#d80027]">
                            Lorem ipsum
                        </p>
                        <p className="self-stretch   text-base text-left text-[#d80027]">
                            Lorem ipsum
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center  pl-4 pb-px border-b border-[#cdcdcd]">
                <div className="flex relative justify-start items-center self-stretch">
                    <p className="flex-grow  text-[21px] font-bold text-left text-[#373737]">
                        Lorem ipsum
                    </p>
                    <div className="flex justify-start items-center  relative gap-2.5 px-4 py-2.5">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M18.7812 9.25L18.1875 8.625C18.0312 8.46875 17.7812 8.46875 17.6562 8.625L12 14.2812L6.3125 8.625C6.1875 8.46875 5.9375 8.46875 5.78125 8.625L5.1875 9.25C5.03125 9.375 5.03125 9.625 5.1875 9.78125L11.7188 16.3125C11.875 16.4688 12.0938 16.4688 12.25 16.3125L18.7812 9.78125C18.9375 9.625 18.9375 9.375 18.7812 9.25Z"
                                fill="#333333"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center  pl-4 pb-px border-b border-[#cdcdcd]">
                <div className="flex relative justify-start items-center self-stretch">
                    <p className="flex-grow  text-[21px] font-bold text-left text-[#373737]">
                        Lorem ipsum
                    </p>
                    <div className="flex justify-start items-center  relative gap-2.5 px-4 py-2.5">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M18.7812 9.25L18.1875 8.625C18.0312 8.46875 17.7812 8.46875 17.6562 8.625L12 14.2812L6.3125 8.625C6.1875 8.46875 5.9375 8.46875 5.78125 8.625L5.1875 9.25C5.03125 9.375 5.03125 9.625 5.1875 9.78125L11.7188 16.3125C11.875 16.4688 12.0938 16.4688 12.25 16.3125L18.7812 9.78125C18.9375 9.625 18.9375 9.375 18.7812 9.25Z"
                                fill="#333333"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center  pl-4 pb-px border-b border-[#cdcdcd]">
                <div className="flex relative justify-start items-center self-stretch">
                    <p className="flex-grow  text-[21px] font-bold text-left text-[#373737]">
                        Lorem ipsum
                    </p>
                    <div className="flex justify-start items-center  relative gap-2.5 px-4 py-2.5">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M18.7812 9.25L18.1875 8.625C18.0312 8.46875 17.7812 8.46875 17.6562 8.625L12 14.2812L6.3125 8.625C6.1875 8.46875 5.9375 8.46875 5.78125 8.625L5.1875 9.25C5.03125 9.375 5.03125 9.625 5.1875 9.78125L11.7188 16.3125C11.875 16.4688 12.0938 16.4688 12.25 16.3125L18.7812 9.78125C18.9375 9.625 18.9375 9.375 18.7812 9.25Z"
                                fill="#333333"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center  pl-4 pb-px border-b border-[#cdcdcd]">
                <div className="flex relative justify-start items-center self-stretch">
                    <p className="flex-grow  text-[21px] font-bold text-left text-[#373737]">
                        Lorem ipsum
                    </p>
                    <div className="flex justify-start items-center  relative gap-2.5 px-4 py-2.5">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M18.7812 9.25L18.1875 8.625C18.0312 8.46875 17.7812 8.46875 17.6562 8.625L12 14.2812L6.3125 8.625C6.1875 8.46875 5.9375 8.46875 5.78125 8.625L5.1875 9.25C5.03125 9.375 5.03125 9.625 5.1875 9.78125L11.7188 16.3125C11.875 16.4688 12.0938 16.4688 12.25 16.3125L18.7812 9.78125C18.9375 9.625 18.9375 9.375 18.7812 9.25Z"
                                fill="#333333"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center  pl-4 pb-px border-b border-[#cdcdcd]">
                <div className="flex relative justify-start items-center self-stretch">
                    <p className="flex-grow  text-[21px] font-bold text-left text-[#373737]">
                        Lorem ipsum
                    </p>
                    <div className="flex justify-start items-center  relative gap-2.5 px-4 py-2.5">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M18.7812 9.25L18.1875 8.625C18.0312 8.46875 17.7812 8.46875 17.6562 8.625L12 14.2812L6.3125 8.625C6.1875 8.46875 5.9375 8.46875 5.78125 8.625L5.1875 9.25C5.03125 9.375 5.03125 9.625 5.1875 9.78125L11.7188 16.3125C11.875 16.4688 12.0938 16.4688 12.25 16.3125L18.7812 9.78125C18.9375 9.625 18.9375 9.375 18.7812 9.25Z"
                                fill="#333333"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomerSupport;
