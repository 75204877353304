import avatar from "../../assets/images/blogs/avatar.png";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";

function BlogAuthor({ blogAuthor }) {
    return (
        <div className="flex flex-col justify-start items-start  gap-4 pb-10 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
            <div className="relative flex items-center justify-start gap-4">
                <div
                    className=" w-20 h-20 relative overflow-hidden rounded-[85.56px] border-[3.42px] border-[#f2f2f2]"
                    style={{
                        filter: "drop-shadow(0px 8px 15px rgba(0,0,0,0.15))"
                    }}
                >
                    <img
                        src={avatar}
                        alt="rectangle-1413-2.png"
                        className="w-20 h-20 absolute left-[-2.08px] top-[-2.08px] object-cover"
                    />
                </div>
                <div className="flex flex-col justify-start items-start  relative gap-[5px]">
                    <div className="flex justify-start items-center  relative gap-[5px]">
                        <p className=" text-base font-bold text-left text-[#373737]">
                            Cristian Fernández
                        </p>
                        <svg
                            width={30}
                            height={31}
                            viewBox="0 0 30 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=" w-[30px] h-[30px] relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M27.5 6.8667H2.5V23.5335H27.5V6.8667Z"
                                fill="white"
                            />
                            <path
                                d="M10.8332 6.8667H2.5V23.5335H10.8332V6.8667Z"
                                fill="#007D36"
                            />
                            <path
                                d="M27.5001 6.8667H19.167V23.5335H27.5001V6.8667Z"
                                fill="#D80027"
                            />
                            <path
                                d="M14.9905 18.0227C13.4343 18.0227 12.168 16.7565 12.168 15.2001C12.168 14.9774 12.3483 14.7969 12.5712 14.7969C12.794 14.7969 12.9744 14.9774 12.9744 15.2001C12.9744 16.3117 13.8789 17.2162 14.9905 17.2162C16.1021 17.2162 17.0066 16.3117 17.0066 15.2001C17.0066 14.9774 17.187 14.7969 17.4099 14.7969C17.6328 14.7969 17.8131 14.9774 17.8131 15.2001C17.8131 16.7565 16.5467 18.0227 14.9905 18.0227Z"
                                fill="#4BA543"
                            />
                            <path
                                d="M14.1887 13.184H14.5877C15.2558 13.184 15.7974 13.7256 15.7974 14.3936V15.6033L15.2336 16.0065C14.6316 16.0065 14.1212 15.5638 14.0361 14.9679L14.1887 13.184Z"
                                fill="#966A5B"
                            />
                            <path
                                d="M15.7976 15.6033H15.2338C14.8325 15.6033 14.4922 15.3082 14.4354 14.9109L14.1888 13.184H13.8418C13.5968 13.184 13.4083 13.4007 13.4425 13.6434L13.6369 15.0249C13.7495 15.8144 14.4362 16.4098 15.2336 16.4098H15.3943C15.617 16.4098 15.7975 16.2293 15.7975 16.0066V15.6033H15.7976Z"
                                fill="#785353"
                            />
                        </svg>
                    </div>
                    <p className=" w-[221px] text-sm text-left text-[#7b7b7b]">
                        12 March 2022
                    </p>
                </div>
            </div>
            <div className="relative flex flex-col items-start self-stretch justify-start gap-5">
                <p className="self-stretch   text-base text-left text-[#7b7b7b]">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Massa varius ipsum lacus, turpis a nullam facilisi. Nullam
                    diam viverra ipsum, eget ultrices ridiculus proin mi.
                    Consequat lorem amet, feugiat ut eu nunc dictumst ut. Sem
                    nam urna interdum malesuada mus.
                </p>
            </div>
            <div className="flex flex-col justify-center items-end self-stretch  gap-2.5">
                <Button
                    size={SIZE.SMALL}
                    color={COLOR.WHITE}
                    // onClick={handleSharePost}
                    text="Ver perfil"
                    iconName={"arrow"}
                    addClass={"w-[141px]"}
                />
                {/* <div className="flex justify-center items-center  w-[141px] h-9 relative gap-2.5 px-4 py-2.5 rounded border-2 border-[#292929]">
                    <p className=" text-sm text-left text-[#373737]">
                        Ver perfil
                    </p>
                    <svg
                        width={21}
                        height={21}
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-5 h-5 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12.3493 6.97083L11.8285 7.49166C11.7243 7.62187 11.7243 7.80416 11.8545 7.93437L13.9378 9.93958H4.97949C4.7972 9.93958 4.66699 10.0958 4.66699 10.2521V10.9812C4.66699 11.1635 4.7972 11.2937 4.97949 11.2937H13.9378L11.8545 13.325C11.7243 13.4552 11.7243 13.6375 11.8285 13.7677L12.3493 14.2885C12.4795 14.3927 12.6618 14.3927 12.792 14.2885L16.2295 10.851C16.3337 10.7208 16.3337 10.5385 16.2295 10.4083L12.792 6.97083C12.6618 6.86666 12.4795 6.86666 12.3493 6.97083Z"
                            fill="#292929"
                        />
                    </svg>
                </div> */}
            </div>
        </div>
    );
}

export default BlogAuthor;
