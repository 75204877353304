import { useFormik } from "formik";
import { useCallback, useState } from "react";
import Swal from "sweetalert2";
import Dialog from "../components/Dialog";
import SignUpWrapper from "../components/SignInWrapper";
import FormFooter from "../components/form/FormFooter.js";
//import FormFooterSocial from "../components/form/FormFooterSocial";
import FormSubmit from "../components/form/FormSubmit.js";
import PaymentMethods from "../components/form/PaymentMethods";
import UploadImage from "../components/form/UploadImage";
import Checkbox from "../components/ui/Checkbox";
import Input from "../components/ui/Input.js";
import * as Yup from "yup";
import {
  ALL_DEPARTMENTS,
  ALL_MUNICIPALITIES,
  CANTONS,
  DISTRICTS,
  EXCLUSIVE_LATIN_COUNTRIES,
  PROVINCES,
} from "../constants/constants.js";
import { dropdownSvg } from "../utils/icons.js";
// import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import apiurl from "../components/ApiConfig";
import FormValidation from "../components/form/FormValidation";
import { Tag } from "../components/ui/Tag";
import "./css/alerts.css";

//const professionalSchema = Yup.object().shape({
//  name: Yup.string()
//       .required("El nombre es requerido")
//       .matches(/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/, "Solo se permiten letras en el nombre"),
//    surname: Yup.string()
//       .required("El apellido es requerido")
//        .matches(/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/, "Solo se permiten letras en el apellido"),
//   dpi: Yup.number().required("El DPI es requerido"),
//   date: Yup.string().required("La fecha de nacimiento es requerida"),
//   membershipNumber: Yup.number().required(
//       "El número de colegiado es requerido"
//  ),
//  phone: Yup.number()
//    .typeError("Por favor ingresa un número válido")
//      .max(999999999999, "Máximo 12 dígitos")
//      .min(10000000, "Mínimo 8 dígitos")
//      .required("Requerido"),
//   exp: Yup.string().required("Los años de experiencia son requeridos"),
//  meses: Yup.string().required("Los meses de experiencia son requeridos"),
//  correo: Yup.string()
//      .email("Formato de correo electrónico inválido")
//       .required("Requerido"),
//   password: Yup.string().required("La contraseña es requerida"),
//   confirmPassword: Yup.string().required(
//       "La confirmación de contraseña es requerida"
//   ),
//  nationality: Yup.string().required("La nacionalidad es requerida"),
// address: Yup.string().required("La dirección es requerida"),
//  dept: Yup.string().required("El departamento es requerido"),
//  tariff: Yup.string().required("La tarifa por hora es requerida"),
//  Descripción: Yup.string().required("La descripción es requerida"),
//  inicio: Yup.string().required("La hora de inicio es requerida"),
// final: Yup.string().required("La hora final es requerida"),
// checked24Hrs: Yup.boolean().required("La hora final es requerida"),
//  tariff1: Yup.string().required("La tarifa por hora es requerida"),
//  dept1: Yup.string().required("El departamento es requerido")
// });

const areasOfLawWithTitles = [
  {
    title: "-- Áreas del Derecho Público --",
    areas: [
      "Derecho Penal - Derecho Procesal Penal",
      "Derecho Administrativo",
      "Derecho Constitucional",
      "Derecho Laboral",
    ],
  },
  {
    title: "-- Áreas del Derecho Privado --",
    areas: [
      "Derecho Notarial",
      "Derecho Civil - Derecho Procesal Civil",
      "Derecho Civil Sustantivo ",
      "Derecho Mercantil ",
      
    ],
  },
];
const isFirstLetterUpperCase = (value) => {
    return /^[A-Z]/.test(value);
};
function SignUpProfessional() {
    const [formData, setFormData] = useState({
        areas: [],
        country: EXCLUSIVE_LATIN_COUNTRIES.PAIS,
      });
  const [acceptAgreement, setAgreement] = useState(false);
  const [checked24Hrs, setChecked24Hrs] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [showRegisteredDialog, setRegisteredDialog] = useState(0);

  const fileHandler = useCallback((event) => {
    const documents = event.target.files;
    setFormData({
      documents,
    });
  }, []);
  
  const handleChangeMain = useCallback(
    (target = {}) => {
        let { name, value } = target;
        console.log(target);
        setFormData((data) => ({ ...data, [name]: value }));
        console.log(formData); 
    },
    [formData, setFormData]
);



  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      documents: [],
      areas: [],
      username: "",
      name: "",
      last_name: "",
      date: "",
      registration_id: "",
      phone: "",
      experience_years: "",
      experience_months: "",
      email: "",
      password: "",
      nationality: EXCLUSIVE_LATIN_COUNTRIES.PAIS,
      province:"" ,
      canton: "",
      district: "",
      legal_address: "",
      department: "",
      municipality:"",
      description: "",
      availability_starttime: "",
      avialability_endtime: "",
      final: "",
      areas_of_law: "",
      checked24Hrs: "",
      tarifa: "",
      dept1: "",
      confirmemail: "",
    },
    validate: (values) => {
        const errors = {};
        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Las contraseñas no coinciden";
        }
        const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
        if (!passwordRegex.test(values.password)) {
            errors.password = "La contraseña debe tener al menos 8 caracteres, una mayúscula y un número";
        }
        if (values.email !== values.confirmemail) {
            errors.confirmemail = "Los correos electrónicos no coinciden";
          }
    

        return errors;
    },

    onSubmit: async (values) => {
      console.log("Antes de la solicitud HTTP");
      try {
            if (!isFirstLetterUpperCase(values.name)) {
                return;
            }
            if (!isFirstLetterUpperCase(values.last_name)) {
                return;
            }
            
            Swal.fire({
              title: 'Procesando...',
              html: 'Espere un momento mientras se registra...',
              allowOutsideClick: false,
              showConfirmButton: false, 
              didOpen: () => {
                Swal.showLoading(); 
              }
            });
        const data = {
          
          username: values.username,
          first_name: values.name,
          last_name: values.last_name,
          birth_date: values.date,
          email: values.email,
          registration_id: values.registration_id,
          password: values.password,
          phone: values.phone,
          experience_years: values.experience_years,
          experience_months: values.experience_months,
          phone_two: values.phoneFamily,
          titular_phonename: values.phonePropio,
          nationality: values.nationality,
          legal_address: values.legal_address,
          department: values.department,
          municipality: values.municipality,
          province: values.province,
          areas_of_law: formData.areas.join(', '),
          district: values.district,
          canton: values.canton,
          description: values.description,
          availability_starttime: checked24Hrs
            ? "00:00"
            : values.availability_starttime,
          avialability_endtime: checked24Hrs
            ? "23:59"
            : values.avialability_endtime,
        };

        const response = await fetch(`${apiurl}/api/v1/ProfesionalRegister/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
            console.log("Registro exitoso");
            setFormData({ ...formData, creditDebit: 2 });
            await Swal.fire({
                title: "Registro exitoso!",
                text: "Has sido registrado correctamente.",
                icon: "success",
                timer: 2000,
                showConfirmButton: false
            }).then(() => {
                navigate("/login");
            });
        } else {
            console.error("Error al registrar el cliente:", response.statusText);
            const responseBody = await response.json();
            const errorMessage = responseBody.username[0]; 
            await Swal.fire({
                title: "Error al registrar!",
                text: `Ha ocurrido un error: ${errorMessage}`,
                timer: 5000,
                showConfirmButton: false
            });
        }
      } catch (error) {
        console.error("Error al realizar la solicitud:", error);
      }
    },
  });
  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (name === "documents") {
        fileHandler(e);
        return;
      }
      if (name === "areas") {
        const isTitle = areasOfLawWithTitles.some(category => category.title === value);
        if (isTitle) {
          return;
        }
        // Check if the area is already selected
        if (!formData.areas.includes(value)) {
          setFormData((prev) => ({
            ...prev,
            areas: [...prev.areas, value],
          }));
        }
        return;
      }
      if (name === "name" || name === "last_name") {
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        formik.setFieldValue(name, capitalizedValue);
        return;
      }
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [fileHandler, formData.areas, formik]
  );
const removeTag = (tag) => {
    setFormData((prevData) => ({
      ...prevData,
      areas: prevData.areas.filter((area) => area !== tag),
    }));
  };
  console.log("formData.country : ");
  console.log(formData.country);

  // const handleFormSubmission = useCallback((e) => {
  //     e.preventDefault();
  //     setRegisteredDialog(1);
  // submit formData
  // e.preventDefault();
  // const url = "http://localhost:3000/uploadFile";
  // const config = {
  //     headers: {
  //         "content-type": "multipart/form-data"
  //     }
  // };
  // axios
  //     .post(url, formData, config)
  //     .then((response) => {
  //         console.log(response.data);
  //         setUploadedFile(response.data.file);
  //     })
  //     .catch((error) => {
  //         console.error("Error uploading file: ", error);
  //         setError(error);
  //     });
  // }, []);
  // console.log(formData);

  return (
      <SignUpWrapper>
          <Dialog
              showDialog={showRegisteredDialog}
              setDialog={setRegisteredDialog}
              main={
                  <div
                      className="flex flex-col justify-start items-center w-[456px] gap-5 p-[30px] bg-white"
                      style={{
                          boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)"
                      }}
                  >
                      <div className="flex justify-center items-start self-stretch  gap-2.5">
                          <div className="flex justify-center items-center  relative gap-2.5 p-2 rounded-[100px] bg-[#ccf2dc]">
                              <svg
                                  width={40}
                                  height={40}
                                  viewBox="0 0 40 40"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="relative w-10 h-10"
                                  preserveAspectRatio="xMidYMid meet"
                              >
                                  <path
                                      d="M19.9997 7.08337C12.8643 7.08337 7.08301 12.9167 7.08301 20C7.08301 27.1355 12.8643 32.9167 19.9997 32.9167C27.083 32.9167 32.9163 27.1355 32.9163 20C32.9163 12.9167 27.083 7.08337 19.9997 7.08337ZM19.9997 9.58337C25.7288 9.58337 30.4163 14.2709 30.4163 20C30.4163 25.7813 25.7288 30.4167 19.9997 30.4167C14.2184 30.4167 9.58301 25.7813 9.58301 20C9.58301 14.2709 14.2184 9.58337 19.9997 9.58337ZM27.2913 16.4063L26.0934 15.2084C25.8851 14.948 25.4684 14.948 25.208 15.2084L17.8643 22.5L14.7393 19.375C14.4788 19.1146 14.1143 19.1146 13.8538 19.375L12.6559 20.5209C12.4476 20.7813 12.4476 21.198 12.6559 21.4063L17.3955 26.198C17.6559 26.4584 18.0205 26.4584 18.2809 26.198L27.2913 17.2917C27.4997 17.0313 27.4997 16.6146 27.2913 16.4063Z"
                                      fill="#00BC4F"
                                  />
                              </svg>
                          </div>
                          <div className="flex flex-col justify-start items-start flex-grow relative gap-2.5">
                              <p className="self-stretch  w-[330px] text-[34px] text-left text-[#373737]">
                                  Falta poco!
                              </p>
                              <p className="self-stretch  w-[330px] text-base text-left text-[#7b7b7b]">
                                  Su cuenta esta siendo revisada para ser
                                  aprobada, le enviaremos un correo de
                                  confirmación.
                              </p>
                          </div>
                      </div>
                      <div className="flex justify-start items-start self-stretch  relative gap-2.5 p-2.5 rounded-[10px] bg-[#f5f8ff]">
                          <p className="flex-grow w-[376px] text-base text-left text-[#373737]">
                              El mensaje llegará a su bandeja de entrada en las
                              próximas 24 horas. Si no puede encontrarlo,
                              verifique también la carpeta de correo no deseado.
                          </p>
                      </div>
                  </div>
              }
          />

          <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-5 justify-start items-end self-stretch"
          >
              <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
                  <div className="flex flex-col flex-grow">
                      <Input
                          label="Nacionalidad"
                          placeholder="Seleccionar"
                          onChange={formik.handleChange}
                          value={formik.values.nationality}
                          name="nationality"
                          type="dropdown"
                          icon={
                              <img
                                  alt="dropdown"
                                  src={dropdownSvg}
                                  className="object-contain w-24 h-24"
                              />
                          }
                          selectList={
                              // get values from EXCLUSIVE_LATIN_COUNTRIES
                              Object.keys(EXCLUSIVE_LATIN_COUNTRIES).map(
                                  (key) => EXCLUSIVE_LATIN_COUNTRIES[key]
                              )
                          }
                      />
                      {<FormValidation formik={formik} name="nationality" />}
                  </div>
              </div>

              <div className="flex flex-col gap-5 justify-between items-start self-stretch sm:flex-row">
                  {["Guatemala", "El Salvador"].includes(
                      formik.values.nationality
                  ) ? (
                      <>
                          <div className="flex flex-col flex-grow w-auto sm:w-1/2">
                              <Input
                                  label="Departamento"
                                  placeholder="Seleccionar"
                                  onChange={formik.handleChange}
                                  value={formik.values.department}
                                  name="department"
                                  type="dropdown"
                                  icon={
                                      <img
                                          alt="dropdown"
                                          src={dropdownSvg}
                                          className="object-contain w-24 h-24"
                                      />
                                  }
                                  selectList={
                                      ALL_DEPARTMENTS[formik.values.nationality]
                                  }
                              />
                              {
                                  <FormValidation
                                      formik={formik}
                                      name="department"
                                  />
                              }
                          </div>
                          <div className="flex flex-col flex-grow w-auto sm:w-1/2">
                              <Input
                                  label="Municipio"
                                  placeholder="Seleccionar"
                                  onChange={formik.handleChange}
                                  value={formik.values.municipality}
                                  name="municipality"
                                  type="dropdown"
                                  icon={
                                      <img
                                          alt="dropdown"
                                          src={dropdownSvg}
                                          className="object-contain w-24 h-24"
                                      />
                                  }
                                  selectList={
                                      ALL_MUNICIPALITIES[
                                          formik.values.nationality
                                      ]?.[formik.values.department] || [
                                          "Municipio"
                                      ]
                                  }
                              />
                              {
                                  <FormValidation
                                      formik={formik}
                                      name="municipality"
                                  />
                              }
                          </div>
                      </>
                  ) : (
                      <>
                          <div className="flex flex-col flex-grow">
                              <Input
                                  label="Provincia"
                                  placeholder="Provincia"
                                  required
                                  onChange={formik.handleChange}
                                  value={formik.values.province}
                                  name="province"
                                  selectList={PROVINCES}
                                  type="dropdown"
                              />
                              {
                                  <FormValidation
                                      formik={formik}
                                      name="province"
                                  />
                              }
                          </div>
                          <div className="flex flex-col flex-grow">
                              <Input
                                  label="Cantón"
                                  placeholder="Cantón"
                                  required
                                  onChange={formik.handleChange}
                                  value={formik.values.canton}
                                  name="canton"
                                  selectList={CANTONS}
                                  type="dropdown"
                              />
                              {<FormValidation formik={formik} name="canton" />}
                          </div>
                          <div className="flex flex-col flex-grow">
                              <Input
                                  label="Distrito"
                                  placeholder="Distrito"
                                  required
                                  onChange={formik.handleChange}
                                  value={formik.values.district}
                                  name="district"
                                  selectList={DISTRICTS}
                                  type="dropdown"
                              />
                              {
                                  <FormValidation
                                      formik={formik}
                                      name="district"
                                  />
                              }
                          </div>
                      </>
                  )}
              </div>
              <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
                  <div className="flex flex-col flex-grow">
                      <Input
                          label="Nombre"
                          placeholder="Nombre"
                          required
                          onChange={handleChange}
                          value={formik.values.name}
                          onBlur={formik.handleBlur}
                          name="name"
                      />
                      {!isFirstLetterUpperCase(formik.values.name) && formik.touched.name && (
    <p className="text-red-500">La primera letra del nombre debe estar en mayúscula</p>
)}
                  </div>
                  <div className="flex flex-col flex-grow">
                      <Input
                          label="Apellidos"
                          placeholder="Nombre"
                          required
                          onChange={handleChange}
                          value={formik.values.last_name}
                          onBlur={formik.handleBlur}
                          name="last_name"
                      />
                      {!isFirstLetterUpperCase(formik.values.last_name) && formik.touched.last_name && (
    <p className="text-red-500">La primera letra del apellido debe estar en mayúscula</p>
)}
                  </div>
              </div>
              <div className="flex flex-col flex-grow w-full">
                  <Input
                      label="Dirección de oficina Jurídica o Residencia"
                      placeholder="Dirección"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.legal_address}
                      name="legal_address"
                  />
                  {<FormValidation formik={formik} name="legal_address" />}
              </div>
              <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
                  <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
                      {formik.values.nationality === "Guatemala" ? (
                          <div className="flex flex-col flex-grow">
                              <Input
                                  label="DPI"
                                  placeholder="DPI"
                                  required
                                  onChange={formik.handleChange}
                                  value={formik.values.username}
                                  name="username"
                                  type="number"
                                  className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto  gap-[5px]"
                              />
                              {<FormValidation formik={formik} name="dni" />}
                          </div>
                      ) : formik.values.nationality === "El Salvador" ? (
                          <div className="flex flex-col flex-grow">
                              <Input
                                  label="DNI"
                                  placeholder="DNI"
                                  required
                                  onChange={formik.handleChange}
                                  value={formik.values.username}
                                  name="username"
                                  className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto  gap-[5px]"
                              />
                              {<FormValidation formik={formik} name="dpi" />}
                          </div>
                      ) : (
                          <div className="flex flex-col flex-grow">
                              <Input
                                  label="Cédula"
                                  placeholder="Cédula"
                                  required
                                  onChange={formik.handleChange}
                                  value={formik.values.username}
                                  name="username"
                                  className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto  gap-[5px]"
                              />
                              {<FormValidation formik={formik} name="Cédula" />}
                          </div>
                      )}
                  </div>
                  <div className="flex flex-col flex-grow">
                      <Input
                          label="Fecha de nacimiento"
                          placeholder="07/19/1985"
                          required
                          onChange={formik.handleChange}
                          value={formik.values.date}
                          name="date"
                          type="date"
                          className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto  gap-[5px]"
                          max={new Date().toISOString().split("T")[0]}
                      />
                      {<FormValidation formik={formik} name="date" />}
                  </div>

                  <div className="flex flex-col flex-grow">
                      <Input
                          label="Número de colegiado"
                          placeholder="Número de colegiado"
                          required
                          onChange={formik.handleChange}
                          value={formik.values.registration_id}
                          name="registration_id"
                          type="number"
                          className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto  gap-[5px]"
                      />
                      {
                          <FormValidation
                              formik={formik}
                              name="registration_id"
                          />
                      }
                  </div>
                  {/* Número de colegiado* */}
              </div>
              <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
                  <div className="flex flex-col flex-grow">
                      <Input
                          label="Teléfono"
                          placeholder="Teléfono"
                          required
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                          name="phone"
                          type="number"
                      />
                      {<FormValidation formik={formik} name="phone" />}
                  </div>
                  <Input
                      label="Año de Graduacion"
                      placeholder="07/19/1985"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.experience_years}
                      name="experience_years"
                      type="date"
                      className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto  gap-[5px]"
                      max={new Date().toISOString().split("T")[0]}
                  />

                  {/*<Input
                      label="Meses de experiencia"
                      placeholder="3 meses"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.experience_months}
                      name="experience_months"
                      type="text"
                      className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto sm:max-w-[23%] gap-[5px]"
                  />*/}
              </div>
              <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
                  <div className="flex flex-col flex-grow">
                  <div style={{ marginBottom: "1em" }}>
            <Input
              label="Correo electrónico"
              placeholder="Correo electrónico"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              name="email"
              type="email"
              error={formik.touched.email && formik.errors.email ? true : false}
            />
            {formik.touched.email && formik.errors.email ? (
              <div style={{ color: "red", marginTop: "0.5em" }}>
                {formik.errors.email}
              </div>
            ) : null}
          </div>

          <div style={{ marginBottom: "1em" }}>
            <Input
              label="Confirmar Correo"
              placeholder="Correo electrónico"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmemail}
              name="confirmemail"
              type="email"
              error={
                formik.touched.confirmemail && formik.errors.confirmemail
                  ? true
                  : false
              }
            />
            {formik.touched.confirmemail && formik.errors.confirmemail ? (
              <div style={{ color: "red", marginTop: "0.5em" }}>
                {formik.errors.confirmemail}
              </div>
            ) : null}
          </div>
                      {formik.errors.password && formik.touched.password && (
                <p className="text-red-500">{formik.errors.password}</p>
            )}
            {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                <p className="text-red-500">{formik.errors.confirmPassword}</p>
            )}
                  
                  </div>

                  <Input
                      label="Contraseña"
                      placeholder="Contraseña"
                      required
                      onChange={formik.handleChange}
                      className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto sm:max-w-[23%] gap-[5px]"
                      value={formik.values.password}
                      name="password"
                      onBlur={formik.handleBlur}
                      type={inputType}
                      onIconClick={() =>
                   setInputType((type) =>
                       type === "password" ? "text" : "password"
                   )
               }
               icon={
                   <svg
                       width={24}
                       height={24}
                       viewBox="0 0 24 24"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg"
                       className="relative w-6 h-6 "
                       preserveAspectRatio="xMidYMid meet"
                   >
                       <path
                           d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                           fill="#A4A4A4"
                       />
                   </svg>
               }
                  />
                    
                  <Input
                      label="Confirmar contraseña"
                      placeholder="Contraseña"
                      required
                      onChange={formik.handleChange}
                      className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto sm:max-w-[23%] gap-[5px]"
                      value={formik.values.confirmPassword}
                      name="confirmPassword"
                      type={inputType}
                      onBlur={formik.handleBlur}
                      onIconClick={() =>
                   setInputType((type) =>
                       type === "password" ? "text" : "password"
                   )
               }
               icon={
                   <svg
                       width={24}
                       height={24}
                       viewBox="0 0 24 24"
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg"
                       className="relative w-6 h-6 "
                       preserveAspectRatio="xMidYMid meet"
                   >
                       <path
                           d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                           fill="#A4A4A4"
                       />
                   </svg>
               }
                  />
                  
              </div>
              <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
                  <UploadImage
                      setFormData={setFormData}
                      label="Antecedentes penales"
                  />
                  <UploadImage
                      setFormData={setFormData}
                      label="Antecedentes policiales"
                  />
              </div>
              <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
                  <UploadImage
                      setFormData={setFormData}
                      label="Curriculum vitae"
                  />
                  <UploadImage
                      cameraFeature
                      setFormData={setFormData}
                      label="Foto de perfil"
                  />
              </div>
              <div className="flex justify-end items-start self-stretch   gap-5 pb-10 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                  <div className="flex flex-col flex-grow">
                      <Input
                          label="Descripción"
                          placeholder="Háblanos sobre tus cualidades"
                          required
                          onChange={formik.handleChange}
                          value={formik.values.description}
                          name="description"
                          type="long"
                          limit={2100}
                      />
                      {<FormValidation formik={formik} name="description" />}
                  </div>
              </div>
              <div className="flex flex-col justify-start items-center self-stretch  relative gap-5 pb-10 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                  <p className="self-stretch  sm:w-[744px] text-base font-bold text-left text-[#373737]">
                      Horario laboral
                  </p>
                  <div className="flex flex-col gap-5 justify-start items-end w-full sm:flex-row">
                      <Input
                          label="Hora de inicio"
                          placeholder="Hora de inicio"
                          required
                          onChange={formik.handleChange}
                          value={formik.values.availability_starttime}
                          name="availability_starttime"
                          type="time"
                          disabled={checked24Hrs}
                      />
                      <Input
                          label="Hora de finalización"
                          placeholder="Hora de finalización"
                          required
                          onChange={formik.handleChange}
                          value={formik.values.avialability_endtime}
                          name="avialability_endtime"
                          type="time"
                          disabled={checked24Hrs}
                      />
                  </div>
                  <div className="flex justify-start items-center self-stretch  gap-2.5">
                      <div
                          className="flex justify-start items-center  relative gap-[5px]"
                          onClick={() => setChecked24Hrs((v) => !v)}
                      >
                          <Checkbox
                              checked={checked24Hrs}
                              toggleCheck={() =>
                                  setChecked24Hrs((prev) => !prev)
                              }
                              text="Automatico / 24 hrs"
                          />
                      </div>
                      <div className="flex justify-start items-center flex-grow relative gap-2.5 p-2.5 rounded bg-[#f5f8ff]">
                          <p className="flex-grow sm:w-[537px] text-xs text-left text-[#373737]">
                              Recibirás de forma automática todas las
                              notificaciones de todos los posibles clientes.
                          </p>
                      </div>
                  </div>
                  {formik.values.nationality === "Guatemala" ? (
                  <div className="flex justify-start items-start  gap-5 w-full sm:w-[50%] ">
                  
                      <Input
                          label="Tarifa por hora"
                          placeholder="Q. 30.00"
                          onChange={formik.handleChange}
                          value={formik.values.tarifa}
                          name="tarifa"
                          icon={
                              <p className=" text-base text-right text-[#373737] -translate-x-5  ">
                                  GTQ
                              </p>
                          }
                      />
                  </div>
                ): formik.values.nationality === "El Salvador" ? (
                    <div className="flex justify-start items-start  gap-5 w-full sm:w-[50%] ">
                  
                      <Input
                          label="Tarifa por hora"
                          placeholder="$10.00"
                          onChange={formik.handleChange}
                          value={formik.values.tarifa}
                          name="tarifa"
                          icon={
                              <p className=" text-base text-right text-[#373737] -translate-x-5  ">
                                  USD
                              </p>
                          }
                      />
                  </div>
                  ):(
                    <div className="flex justify-start items-start  gap-5 w-full sm:w-[50%] ">
                  
                      <Input
                          label="Tarifa por hora"
                          placeholder="₡10,000.00"
                          onChange={formik.handleChange}
                          value={formik.values.tarifa}
                          name="tarifa"
                          icon={
                              <p className=" text-base text-right text-[#373737] -translate-x-5  ">
                                  CRC
                              </p>
                          }
                      />
                  </div>
                  )}
              </div>
              <div className="flex relative flex-col gap-3 justify-start items-start self-stretch">
                  <p className="self-stretch  lg:w-full max-w-[876px] text-base font-bold text-left text-[#373737]">
                      Áreas del derecho
                  </p>
                  <div className="flex justify-start items-center self-stretch  relative gap-[5px] p-2 rounded bg-[#f5f8ff]">
                      <svg
                          width={24}
                          height={25}
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="relative w-6 h-6"
                          preserveAspectRatio="xMidYMid meet"
                      >
                          <path
                              d="M12 5.06641C7.71875 5.06641 4.25 8.56641 4.25 12.8164C4.25 17.0977 7.71875 20.5664 12 20.5664C16.25 20.5664 19.75 17.0977 19.75 12.8164C19.75 8.56641 16.25 5.06641 12 5.06641ZM12 19.0664C8.53125 19.0664 5.75 16.2852 5.75 12.8164C5.75 9.37891 8.53125 6.56641 12 6.56641C15.4375 6.56641 18.25 9.37891 18.25 12.8164C18.25 16.2852 15.4375 19.0664 12 19.0664ZM12 8.50391C11.25 8.50391 10.6875 9.09766 10.6875 9.81641C10.6875 10.5664 11.25 11.1289 12 11.1289C12.7188 11.1289 13.3125 10.5664 13.3125 9.81641C13.3125 9.09766 12.7188 8.50391 12 8.50391ZM13.75 16.4414V15.6914C13.75 15.5039 13.5625 15.3164 13.375 15.3164H13V12.1914C13 12.0039 12.8125 11.8164 12.625 11.8164H10.625C10.4062 11.8164 10.25 12.0039 10.25 12.1914V12.9414C10.25 13.1602 10.4062 13.3164 10.625 13.3164H11V15.3164H10.625C10.4062 15.3164 10.25 15.5039 10.25 15.6914V16.4414C10.25 16.6602 10.4062 16.8164 10.625 16.8164H13.375C13.5625 16.8164 13.75 16.6602 13.75 16.4414Z"
                              fill="#333333"
                          />
                      </svg>
                      <p className="flex-grow w-full max-w-[831px] text-xs text-left text-[#373737]">
                          Puedes seleccionar máximo de 5 áreas de derecho{" "}
                      </p>
                  </div>

                  <Input
                      label="Seleccionar áreas del derecho"
                      placeholder="Seleccione áreas del derecho"
                      onChange={(e) => handleChange(e)}
                      value={formik.values.areas}
                      name="areas"
                      type="dropdown"
                      selectList={areasOfLawWithTitles.reduce(
                          (acc, curr) => [...acc, curr.title, ...curr.areas],
                          []
                      )}
                      addInputClass="lg:w-[362px]"
                  />

                  {formData.areas?.length ? (
                      <div className="flex justify-start items-start gap-5 overflow-x-auto w-full min-h-[50px]">
                          {formData.areas.map((text, index) => (
                              <Tag
                                  key={index}
                                  text={text}
                                  hasClose
                                  removeTag={(text) => removeTag(text)}
                              />
                          ))}
                      </div>
                  ) : null}
              </div>
              <p className="self-stretch  sm:w-[744px] text-base font-bold text-left text-[#373737]">
                  Formas de pago
              </p>
              <PaymentMethods
                    formData={formData}
                    handleChange={handleChangeMain}
                    setFormData={setFormData}
                    useFormik={useFormik}
                    Yup={Yup}
                />
               {/* <FormFooterSocial />*/}
              <FormSubmit
                  acceptAgreement={acceptAgreement}
                  setAgreement={setAgreement}
              />
              <FormFooter />
          </form>
      </SignUpWrapper>
  );
}

export default SignUpProfessional;