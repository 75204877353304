import { Link, useLocation } from "react-router-dom";
import profile from "../../assets/images/landing/profile.png";
import Input from "../ui/Input";
import DiscountTemplateClient from "../DiscountTemplateClient";
import React, { useCallback, useState } from "react";
import Dialog from "../Dialog";
import Quote from "../dialogs/Quote";
import ProfileMenuClient from "./ProfileMenuClient";
import { noop } from "../../utils/noop";
import { Button, SIZE } from "../ui/buttons/Button";

export const SubHeaderItem = ({
  active = false,
  text = "Tablero",
  onClick = noop,
}) =>
  active ? (
    <div
      onClick={onClick}
      className="flex relative flex-col gap-2 justify-center items-start pt-2 cursor-pointer hover:opacity-95"
    >
      <p className="flex-grow-0 text-sm font-bold text-left text-white sm:text-base">
        {text}
      </p>
      <div className="self-stretch  h-[3px] bg-[#d80027]" />
    </div>
  ) : (
    <div
      onClick={onClick}
      className="flex relative flex-col gap-2 justify-start items-center pt-2 cursor-pointer hover:opacity-95"
    >
      <p className="flex-grow-0 text-sm text-left text-white sm:text-base">
        {text}
      </p>
      <div className="flex-grow-0  w-px h-px opacity-0 bg-[#1f6bff]" />
    </div>
  );

function PlatformHeader({ subHeaderProp = false }) {
  const [formData, setFormData] = useState({});
  const [showDialog, setDialog] = useState(0);
  const [showDialog1, setDialog1] = useState(0);
  const [showProfileMenu, setProfileMenu] = useState(false);
  const locationPath = useLocation()?.pathname;
  const boardPage = locationPath.includes("platform/board");
  const communityPage = locationPath.includes("platform/community");
  const myProjectsPage = locationPath.includes("platform/projects");
  const subHeader = boardPage || communityPage || myProjectsPage;

  const handleChange = useCallback(
    (target = {}) => {
      let { name, value } = target;
      console.log(target);
      setFormData((data) => ({ ...data, ...{ [name]: value } }));
      console.log(formData);
    },
    [formData]
  );

  const handleFormSubmission = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <div
      className={`z-10 fixed top-0 w-full ${
        subHeader ? "flex flex-col w-full" : ""
      }`}
    >
      <div className="flex justify-between sm:justify-start items-center   sm:w-full h-[70px] gap-5 px-[13px] py-2.5 bg-white border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
        <a
          href="/platform/board"
          className=" cursor-pointer   flex justify-start items-center   w-[195px] relative gap-2.5"
        >
          <svg
            width={34}
            height={34}
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="  w-[33.1px] h-[33.87px] flex-grow-0 flex-shrink-0"
            preserveAspectRatio="none"
          >
            <path
              d="M32.5862 33.8067L0.510304 33.9339C0.375998 33.9339 0.247193 33.8805 0.152225 33.7856C0.0572558 33.6906 0.00390625 33.5618 0.00390625 33.4275C0.00390625 33.2932 0.0572558 33.1644 0.152225 33.0694C0.247193 32.9744 0.375998 32.9211 0.510304 32.9211L32.5862 32.7939C32.6529 32.7936 32.719 32.8065 32.7807 32.8318C32.8424 32.8571 32.8985 32.8944 32.9458 32.9414C32.993 32.9885 33.0305 33.0444 33.0561 33.106C33.0817 33.1676 33.0949 33.2336 33.0949 33.3003C33.0949 33.367 33.0817 33.4331 33.0561 33.4946C33.0305 33.5562 32.993 33.6122 32.9458 33.6592C32.8985 33.7063 32.8424 33.7435 32.7807 33.7688C32.719 33.7942 32.6529 33.807 32.5862 33.8067Z"
              fill="#D80027"
            />
            <path
              d="M15.1957 11.934C15.0614 11.934 14.9326 11.8806 14.8376 11.7856C14.7427 11.6907 14.6893 11.5619 14.6893 11.4276C14.6893 8.81607 13.9808 7.05388 12.4639 5.87984C12.411 5.83932 12.3667 5.78879 12.3334 5.73112C12.3001 5.67345 12.2786 5.60978 12.2699 5.54376C12.2613 5.47774 12.2657 5.41066 12.283 5.34637C12.3003 5.28207 12.3301 5.22182 12.3708 5.16906C12.4115 5.11607 12.4623 5.07166 12.5202 5.03839C12.5781 5.00511 12.6421 4.98363 12.7084 4.97519C12.7747 4.96675 12.842 4.9715 12.9064 4.98919C12.9708 5.00687 13.0311 5.03713 13.0838 5.07823C14.846 6.44074 15.7021 8.51631 15.7021 11.4366C15.6997 11.5694 15.6453 11.6958 15.5506 11.7889C15.4559 11.8819 15.3285 11.934 15.1957 11.934Z"
              fill="#D80027"
            />
            <path
              d="M18.1415 11.9338C18.0748 11.9341 18.0087 11.9212 17.947 11.8959C17.8853 11.8706 17.8292 11.8333 17.7819 11.7862C17.7347 11.7392 17.6972 11.6833 17.6716 11.6217C17.646 11.5601 17.6328 11.4941 17.6328 11.4274C17.6328 8.51612 18.4912 6.43147 20.2534 5.06895C20.3059 5.02792 20.366 4.9977 20.4303 4.98005C20.4945 4.9624 20.5616 4.95766 20.6277 4.9661C20.6938 4.97455 20.7576 4.99602 20.8153 5.02926C20.8731 5.06251 20.9237 5.10687 20.9642 5.15979C21.0052 5.21229 21.0354 5.2724 21.0531 5.33666C21.0707 5.40091 21.0755 5.46803 21.067 5.53412C21.0586 5.60022 21.0371 5.66399 21.0038 5.72174C20.9706 5.77948 20.9262 5.83007 20.8733 5.87057C19.3541 7.0446 18.6479 8.80679 18.6479 11.4183C18.6491 11.4855 18.6369 11.5524 18.612 11.6149C18.5871 11.6774 18.55 11.7343 18.5028 11.7822C18.4557 11.8302 18.3994 11.8684 18.3374 11.8944C18.2754 11.9204 18.2088 11.9338 18.1415 11.9338Z"
              fill="#D80027"
            />
            <path
              d="M27.3171 4.97141C25.6058 4.97381 23.9653 5.65484 22.7554 6.86513C21.5455 8.07542 20.8651 9.71616 20.8633 11.4275C20.8633 11.5618 20.9166 11.6906 21.0116 11.7855C21.1066 11.8805 21.2354 11.9339 21.3697 11.9339C21.4362 11.9339 21.502 11.9208 21.5635 11.8953C21.6249 11.8699 21.6807 11.8326 21.7278 11.7855C21.7748 11.7385 21.8121 11.6827 21.8375 11.6213C21.863 11.5598 21.8761 11.494 21.8761 11.4275C21.8761 9.98202 22.4503 8.59577 23.4724 7.57369C24.4945 6.5516 25.8807 5.9774 27.3262 5.9774C27.885 5.97889 28.4381 6.09043 28.9538 6.30567C29.4696 6.52092 29.9379 6.83563 30.332 7.23186C30.7261 7.62808 31.0383 8.09805 31.2508 8.61493C31.4633 9.13182 31.5719 9.68549 31.5704 10.2443C31.5674 11.1175 31.2189 11.954 30.6011 12.571C29.9832 13.188 29.1463 13.5353 28.2731 13.5371C27.6 13.5365 26.9545 13.2688 26.4785 12.7928C26.0026 12.3168 25.7349 11.6714 25.7343 10.9983C25.7349 10.4871 25.9382 9.99709 26.2996 9.63566C26.6611 9.27423 27.1511 9.07091 27.6622 9.07031C28.0179 9.07053 28.3609 9.20239 28.6251 9.44046C28.3912 9.51322 28.1867 9.65896 28.0417 9.85633C27.8966 10.0537 27.8185 10.2923 27.8189 10.5373C27.8189 10.842 27.94 11.1343 28.1555 11.3498C28.371 11.5653 28.6632 11.6863 28.968 11.6863C29.5993 11.6863 30.1148 11.2322 30.1148 10.3874C30.1148 8.98402 29.0179 8.05524 27.6622 8.05524C26.8793 8.05524 26.1284 8.36626 25.5748 8.91989C25.0211 9.47352 24.7101 10.2244 24.7101 11.0074C24.7119 11.9507 25.0883 12.8548 25.7564 13.5208C26.4245 14.1867 27.3297 14.5602 28.2731 14.559C29.4174 14.559 30.5149 14.1044 31.324 13.2953C32.1332 12.4861 32.5877 11.3887 32.5877 10.2443C32.588 9.552 32.4519 8.86638 32.1872 8.22665C31.9225 7.58692 31.5343 7.00562 31.0448 6.51595C30.5553 6.02628 29.9742 5.63785 29.3346 5.37283C28.695 5.10781 28.0094 4.9714 27.3171 4.97141Z"
              fill="#D80027"
            />
            <path
              d="M5.51808 4.97141C7.22978 4.97321 8.87086 5.65398 10.0812 6.86433C11.2916 8.07469 11.9723 9.71577 11.9741 11.4275C11.9741 11.5618 11.9208 11.6906 11.8258 11.7855C11.7309 11.8805 11.602 11.9339 11.4677 11.9339C11.3334 11.9339 11.2046 11.8805 11.1097 11.7855C11.0147 11.6906 10.9613 11.5618 10.9613 11.4275C10.9613 9.98202 10.3871 8.59577 9.36505 7.57369C8.34296 6.5516 6.95672 5.9774 5.51128 5.9774C4.95242 5.97889 4.39933 6.09043 3.88359 6.30567C3.36784 6.52092 2.89955 6.83563 2.50543 7.23186C2.11132 7.62808 1.79911 8.09805 1.58662 8.61493C1.37414 9.13182 1.26554 9.68549 1.26704 10.2443C1.26824 11.1191 1.61624 11.9576 2.23477 12.5762C2.8533 13.1947 3.69186 13.5427 4.56659 13.5439C5.23974 13.5433 5.88515 13.2756 6.36114 12.7996C6.83714 12.3236 7.10481 11.6782 7.10541 11.0051C7.10481 10.4935 6.90119 10.0032 6.53926 9.64167C6.17734 9.28017 5.68672 9.07712 5.17518 9.07712C4.82019 9.07726 4.4779 9.20917 4.21461 9.44727C4.4485 9.52003 4.65296 9.66577 4.79803 9.86314C4.9431 10.0605 5.02115 10.2992 5.02076 10.5441C5.02016 10.8487 4.89892 11.1406 4.68356 11.3559C4.4682 11.5713 4.17627 11.6926 3.87171 11.6932C3.24041 11.6932 2.72265 11.239 2.72265 10.3942C2.72265 8.99083 3.82175 8.06205 5.17518 8.06205C5.95813 8.06205 6.70901 8.37308 7.26264 8.9267C7.81627 9.48033 8.1273 10.2312 8.1273 11.0142C8.12369 11.956 7.74689 12.8579 7.07945 13.5224C6.41201 14.1868 5.50838 14.5596 4.56659 14.559C3.42228 14.559 2.32484 14.1044 1.51569 13.2953C0.706537 12.4861 0.251953 11.3887 0.251953 10.2443C0.251952 8.84705 0.806565 7.50689 1.79396 6.51822C2.78136 5.52955 4.12079 4.97321 5.51808 4.97141Z"
              fill="#D80027"
            />
            <path
              d="M23.1898 3.53637H9.65093C9.51662 3.53637 9.38782 3.48301 9.29285 3.38804C9.19788 3.29307 9.14453 3.16427 9.14453 3.02996C9.14453 2.96346 9.15763 2.89761 9.18308 2.83617C9.20853 2.77473 9.24583 2.71891 9.29285 2.67188C9.33987 2.62486 9.3957 2.58756 9.45714 2.56211C9.51857 2.53666 9.58443 2.52356 9.65093 2.52356H23.1943C23.3287 2.52356 23.4575 2.57691 23.5524 2.67188C23.6474 2.76685 23.7007 2.89565 23.7007 3.02996C23.7008 3.09685 23.6875 3.16307 23.6618 3.22481C23.636 3.28655 23.5983 3.34257 23.5508 3.38966C23.5033 3.43674 23.447 3.47395 23.385 3.49913C23.323 3.52431 23.2567 3.53697 23.1898 3.53637Z"
              fill="#D80027"
            />
            <path
              d="M7.34236 3.53622C6.88241 3.53562 6.44147 3.35264 6.11623 3.02741C5.791 2.70217 5.60802 2.26124 5.60742 1.80129C5.60802 1.34134 5.791 0.900395 6.11623 0.575162C6.44147 0.249928 6.88241 0.0669461 7.34236 0.0663452H25.4956C25.9556 0.0669461 26.3965 0.249928 26.7218 0.575162C27.047 0.900395 27.23 1.34134 27.2306 1.80129C27.23 2.26124 27.047 2.70217 26.7218 3.02741C26.3965 3.35264 25.9556 3.53562 25.4956 3.53622C25.4289 3.53652 25.3628 3.52365 25.3011 3.49833C25.2394 3.47302 25.1833 3.43576 25.1361 3.38871C25.0888 3.34165 25.0513 3.28573 25.0257 3.22414C25.0001 3.16255 24.987 3.09651 24.987 3.02982C24.987 2.96313 25.0001 2.89709 25.0257 2.8355C25.0513 2.77392 25.0888 2.71799 25.1361 2.67094C25.1833 2.62388 25.2394 2.58663 25.3011 2.56131C25.3628 2.536 25.4289 2.52312 25.4956 2.52342C25.6868 2.52282 25.8699 2.44647 26.0048 2.31111C26.1397 2.17575 26.2155 1.99242 26.2155 1.80129C26.2155 1.61016 26.1397 1.42682 26.0048 1.29146C25.8699 1.1561 25.6868 1.07975 25.4956 1.07915H7.34236C7.15124 1.07975 6.96814 1.1561 6.83321 1.29146C6.69827 1.42682 6.62249 1.61016 6.62249 1.80129C6.62249 1.99242 6.69827 2.17575 6.83321 2.31111C6.96814 2.44647 7.15124 2.52282 7.34236 2.52342C7.40906 2.52312 7.47515 2.536 7.53685 2.56131C7.59855 2.58663 7.65465 2.62388 7.70191 2.67094C7.74918 2.71799 7.78668 2.77392 7.81227 2.8355C7.83786 2.89709 7.85103 2.96313 7.85103 3.02982C7.85103 3.09651 7.83786 3.16255 7.81227 3.22414C7.78668 3.28573 7.74918 3.34165 7.70191 3.38871C7.65465 3.43576 7.59855 3.47302 7.53685 3.49833C7.47515 3.52365 7.40906 3.53652 7.34236 3.53622Z"
              fill="#D80027"
            />
            <path
              d="M24.3725 14.9224C24.3303 14.9227 24.2883 14.9174 24.2476 14.9065C19.1076 13.5901 13.7187 13.5901 8.57865 14.9065C8.51423 14.9233 8.44714 14.9271 8.38122 14.9179C8.31531 14.9087 8.25185 14.8866 8.19448 14.8529C8.13712 14.8191 8.08697 14.7744 8.0469 14.7213C8.00683 14.6681 7.97763 14.6076 7.96097 14.5432C7.92785 14.4128 7.94774 14.2746 8.01625 14.1589C8.08477 14.0431 8.19634 13.9592 8.32657 13.9255C13.629 12.5675 19.1881 12.5675 24.4906 13.9255C24.555 13.9422 24.6155 13.9714 24.6687 14.0114C24.7218 14.0515 24.7665 14.1017 24.8003 14.159C24.834 14.2164 24.8561 14.2798 24.8653 14.3458C24.8745 14.4117 24.8707 14.4788 24.8539 14.5432C24.8265 14.6505 24.7645 14.7458 24.6774 14.8144C24.5904 14.8829 24.4832 14.9209 24.3725 14.9224Z"
              fill="#D80027"
            />
            <path
              d="M12.4556 29.6351C12.3889 29.6351 12.3229 29.622 12.2613 29.5964C12.1997 29.5708 12.1438 29.5333 12.0967 29.486C12.0497 29.4387 12.0124 29.3826 11.9871 29.3209C11.9618 29.2592 11.9489 29.1931 11.9492 29.1265V18.2263C11.9492 18.1598 11.9623 18.094 11.9878 18.0325C12.0132 17.9711 12.0505 17.9153 12.0975 17.8682C12.1446 17.8212 12.2004 17.7839 12.2618 17.7585C12.3233 17.733 12.3891 17.7199 12.4556 17.7199C12.5223 17.7196 12.5884 17.7325 12.6501 17.7578C12.7118 17.7831 12.7679 17.8204 12.8152 17.8674C12.8624 17.9145 12.8999 17.9704 12.9255 18.032C12.9511 18.0936 12.9643 18.1596 12.9643 18.2263V29.1265C12.9643 29.2614 12.9107 29.3907 12.8153 29.4861C12.7199 29.5815 12.5905 29.6351 12.4556 29.6351Z"
              fill="#D80027"
            />
            <path
              d="M16.4185 29.6352C16.3518 29.6352 16.2858 29.622 16.2242 29.5964C16.1626 29.5708 16.1067 29.5333 16.0596 29.4861C16.0126 29.4388 15.9753 29.3827 15.95 29.321C15.9247 29.2593 15.9118 29.1932 15.9121 29.1265V17.695C15.9121 17.5607 15.9655 17.4319 16.0604 17.3369C16.1554 17.242 16.2842 17.1886 16.4185 17.1886C16.5528 17.1886 16.6816 17.242 16.7766 17.3369C16.8716 17.4319 16.9249 17.5607 16.9249 17.695V29.1265C16.9252 29.1932 16.9123 29.2593 16.887 29.321C16.8617 29.3827 16.8244 29.4388 16.7774 29.4861C16.7303 29.5333 16.6744 29.5708 16.6128 29.5964C16.5512 29.622 16.4852 29.6352 16.4185 29.6352Z"
              fill="#D80027"
            />
            <path
              d="M20.3817 29.6351C20.2468 29.6351 20.1174 29.5815 20.022 29.4861C19.9266 29.3907 19.873 29.2614 19.873 29.1265V18.2263C19.873 18.1596 19.8862 18.0936 19.9118 18.032C19.9374 17.9704 19.9749 17.9145 20.0222 17.8674C20.0694 17.8204 20.1255 17.7831 20.1872 17.7578C20.2489 17.7325 20.315 17.7196 20.3817 17.7199C20.516 17.7199 20.6448 17.7733 20.7398 17.8682C20.8348 17.9632 20.8881 18.092 20.8881 18.2263V29.1265C20.8884 29.1931 20.8755 29.2592 20.8502 29.3209C20.8249 29.3826 20.7877 29.4387 20.7406 29.486C20.6935 29.5333 20.6376 29.5708 20.576 29.5964C20.5145 29.622 20.4484 29.6351 20.3817 29.6351Z"
              fill="#D80027"
            />
            <path
              d="M32.3303 29.6354H23.8668V16.9185C18.9722 15.7309 13.8645 15.7309 8.96991 16.9185V29.6354H0.506403C0.43971 29.6354 0.373684 29.6222 0.312096 29.5966C0.250508 29.571 0.194573 29.5335 0.14752 29.4862C0.100466 29.439 0.0632141 29.3829 0.037899 29.3212C0.0125838 29.2595 -0.00029398 29.1934 5.09207e-06 29.1267C5.09207e-06 28.9924 0.0533547 28.8636 0.148324 28.7686C0.243292 28.6736 0.372097 28.6203 0.506403 28.6203H7.95484V16.1305L8.33634 16.0329C13.6388 14.6749 19.1979 14.6749 24.5003 16.0329L24.8818 16.1305V28.6203H32.3303C32.4646 28.6203 32.5934 28.6736 32.6883 28.7686C32.7833 28.8636 32.8367 28.9924 32.8367 29.1267C32.837 29.1934 32.8241 29.2595 32.7988 29.3212C32.7734 29.3829 32.7362 29.439 32.6891 29.4862C32.6421 29.5335 32.5862 29.571 32.5246 29.5966C32.463 29.6222 32.3969 29.6354 32.3303 29.6354Z"
              fill="#D80027"
            />
          </svg>
          <p className="hidden sm:block font-[Enriqueta] flex-grow-0 flex-shrink-0  text-[25.104915618896484px] text-left text-[#373737]">
            Legal EPROF
          </p>
        </a>
        <div className="hidden gap-5 justify-start items-center xl:flex">
          <a
            href="/platform/404-client"
            className="flex justify-start items-center   text-base text-left text-[#373737]  relative gap-[5px]"
          >
            <svg
              width={30}
              height={30}
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="  w-[30px] h-[30px] relative"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M15 16.2499C18.0859 16.2499 20.625 13.7499 20.625 10.6249C20.625 7.53894 18.0859 4.99988 15 4.99988C11.875 4.99988 9.375 7.53894 9.375 10.6249C9.375 13.7499 11.875 16.2499 15 16.2499ZM15 6.87488C17.0313 6.87488 18.75 8.59363 18.75 10.6249C18.75 12.6952 17.0313 14.3749 15 14.3749C12.9297 14.3749 11.25 12.6952 11.25 10.6249C11.25 8.59363 12.9297 6.87488 15 6.87488ZM18.5547 16.9139L16.3672 22.9296L15.625 19.6874L16.875 17.4999H13.125L14.375 19.6874L13.5938 22.9296L11.4063 16.9139C8.51563 16.953 6.25 19.2577 6.25 22.1483V23.1249C6.25 24.1796 7.07031 24.9999 8.125 24.9999H21.875C22.8906 24.9999 23.75 24.1796 23.75 23.1249V22.1483C23.75 19.2577 21.4453 16.953 18.5547 16.9139ZM10 23.1249H8.125V22.1483C8.125 20.7421 8.94531 19.5702 10.1953 19.0233L11.6797 23.1249H10ZM21.875 23.1249H18.2813L19.8047 19.0233C21.0156 19.5702 21.875 20.7421 21.875 22.1483V23.1249Z"
                fill="#D80027"
              />
            </svg>
            Trabajos
          </a>
          <a
            href="/platform/professionals-directory"
            className="text-base text-left text-[#373737] cursor-pointer flex justify-start items-center   relative gap-[5px]"
          >
            <svg
              width={30}
              height={30}
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="  w-[30px] h-[30px] relative"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M24.4144 8.82813L21.1331 5.58594C20.7816 5.23438 20.3128 5 19.8441 5H11.8363C10.8206 5.03906 10.0003 5.85938 10.0003 6.91406V10H11.8363V6.91406H18.0863V10.9766C18.0863 11.4844 18.5159 11.9141 19.0238 11.9141H23.1253V23.1641H13.7503V25H23.0863C24.1409 25 25.0003 24.1797 25.0003 23.1641V10.1563C25.0003 9.6875 24.7659 9.17969 24.4144 8.82813ZM19.9613 10.0391V7.03125L22.9691 10.0391H19.9613ZM14.6488 18.2422C14.9222 17.9297 15.0394 17.5 14.9613 17.1094C14.7269 16.2891 14.7269 16.4063 14.9613 15.625C15.0394 15.1953 14.9222 14.7656 14.6488 14.4922C14.0628 13.9063 14.1019 13.9844 13.9066 13.2031C13.7894 12.7734 13.4769 12.4609 13.0863 12.3438C12.305 12.1484 12.4222 12.1875 11.8363 11.6016C11.5238 11.3281 11.0941 11.2109 10.7034 11.3281C9.9222 11.5234 10.0394 11.5234 9.25813 11.3281C8.86751 11.2109 8.43782 11.3281 8.12532 11.6016C7.53938 12.1875 7.65657 12.1484 6.87532 12.3438C6.4847 12.4609 6.1722 12.7734 6.05501 13.2031C5.82063 13.9844 5.89876 13.9063 5.31282 14.4922C5.03938 14.7656 4.9222 15.1953 5.00032 15.625C5.2347 16.4063 5.2347 16.2891 5.00032 17.1094C4.9222 17.5 5.03938 17.9297 5.31282 18.2422C5.89876 18.8281 5.82063 18.7109 6.05501 19.5313C6.1722 19.9219 6.4847 20.2344 6.87532 20.3516C7.4222 20.5078 7.30501 20.4688 7.46126 20.5469V25L9.96126 23.75L12.4613 25V20.5469C12.6566 20.4688 12.5394 20.5078 13.0863 20.3516C13.4769 20.2344 13.7894 19.9219 13.9066 19.5313C14.1409 18.7109 14.0628 18.8281 14.6488 18.2422ZM10.0003 18.75C8.59407 18.75 7.50032 17.6563 7.50032 16.25C7.50032 14.8828 8.59407 13.75 10.0003 13.75C11.3675 13.75 12.5003 14.8828 12.5003 16.25C12.5003 17.6563 11.3675 18.75 10.0003 18.75Z"
                fill="#D80027"
              />
            </svg>
            Profesionales
          </a>
        </div>
        <div className="hidden sm:flex flex-col justify-center items-center flex-grow gap-2.5">
          <div className="flex flex-col justify-start items-start self-stretch   gap-[5px]">
            <Input
              onSubmit={handleFormSubmission}
              placeholder="Escribe la palabra clave"
              // required
              onChange={(e) => handleChange(e.target)}
              value={formData.message}
              name="message"
              inputClassName={
                "flex justify-end items-center self-stretch mt-[5px]  h-9 relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929] w-full"
              }
              // className={
              //     "flex-grow w-full border-none outline-none"
              // }
              icon={
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 cursor-pointer"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                    fill="#333333"
                  />
                </svg>
              }
            />
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-start   gap-2.5">
          <Dialog
            showDialog={showDialog}
            setDialog={setDialog}
            main={<Quote closeDialog={() => setDialog(0)} />}
          />
          <div style={{ width: "212px" }}>
            <DiscountTemplateClient
              showDialog={showDialog1}
              setDialog={setDialog1}
            />
            <Button onClick={() => setDialog1(1)} size={SIZE.SMALL} stretch>
              Contratar / Asesoría
            </Button>
          </div>
        </div>
        <div className="flex justify-end items-center   relative gap-[53px]">
          <Link to="/platform/404-client">
            {/*<Link to="/platform/chat">*/}
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              className="cursor-pointer hover:opacity-90  w-[30px] h-[30px] relative"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 5H7.5C6.09375 5 5 6.13281 5 7.5V18.75C5 20.1562 6.09375 21.25 7.5 21.25H11.25V24.5312C11.25 24.8438 11.4453 25 11.7188 25C11.7969 25 11.875 25 11.9922 24.9219L16.875 21.25H22.5C23.8672 21.25 25 20.1562 25 18.75V7.5C25 6.13281 23.8672 5 22.5 5ZM23.125 18.75C23.125 19.1016 22.8125 19.375 22.5 19.375H16.25L15.7422 19.7656L13.125 21.7188V19.375H7.5C7.14844 19.375 6.875 19.1016 6.875 18.75V7.5C6.875 7.1875 7.14844 6.875 7.5 6.875H22.5C22.8125 6.875 23.125 7.1875 23.125 7.5V18.75ZM10 11.875C9.29688 11.875 8.75 12.4609 8.75 13.125C8.75 13.8281 9.29688 14.375 10 14.375C10.6641 14.375 11.25 13.8281 11.25 13.125C11.25 12.4609 10.6641 11.875 10 11.875ZM15 11.875C14.2969 11.875 13.75 12.4609 13.75 13.125C13.75 13.8281 14.2969 14.375 15 14.375C15.6641 14.375 16.25 13.8281 16.25 13.125C16.25 12.4609 15.6641 11.875 15 11.875ZM20 11.875C19.2969 11.875 18.75 12.4609 18.75 13.125C18.75 13.8281 19.2969 14.375 20 14.375C20.6641 14.375 21.25 13.8281 21.25 13.125C21.25 12.4609 20.6641 11.875 20 11.875Z"
                fill="#333333"
              />
              <circle
                cx="22.5"
                cy="6.5"
                r="3.8"
                fill="#D80027"
                stroke="white"
                strokeWidth="1.4"
              />
            </svg>
          </Link>
          <svg
            width={30}
            height={30}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer hover:opacity-90  w-[30px] h-[30px] relative"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M23.398 19.1797C22.6558 18.3594 21.2105 17.1484 21.2105 13.125C21.2105 10.1172 19.1012 7.69531 16.2105 7.07031V6.25C16.2105 5.58594 15.6637 5 14.9996 5C14.2965 5 13.7496 5.58594 13.7496 6.25V7.07031C10.859 7.69531 8.74959 10.1172 8.74959 13.125C8.74959 17.1484 7.30428 18.3594 6.56209 19.1797C6.32771 19.4141 6.21053 19.7266 6.24959 20C6.24959 20.6641 6.71834 21.25 7.49959 21.25H22.4605C23.2418 21.25 23.7105 20.6641 23.7496 20C23.7496 19.7266 23.6324 19.4141 23.398 19.1797ZM8.86678 19.375C9.68709 18.3203 10.5855 16.4844 10.6246 13.1641C10.6246 13.1641 10.6246 13.1641 10.6246 13.125C10.6246 10.7422 12.5777 8.75 14.9996 8.75C17.3824 8.75 19.3746 10.7422 19.3746 13.125C19.3746 13.1641 19.3355 13.1641 19.3355 13.1641C19.3746 16.4844 20.273 18.3203 21.0933 19.375H8.86678ZM14.9996 25C16.3668 25 17.4605 23.9062 17.4605 22.5H12.4996C12.4996 23.9062 13.5933 25 14.9996 25Z"
              fill="#333333"
            />
          </svg>
          <div className="cursor-pointer   w-[30px] h-[30px]">
            <div className="w-[30px] h-[30px] absolute left-[165px] top-0 overflow-hidden rounded-[73.17px] border-[1.46px] border-[#d9d9d9]">
              <img
                onClick={() => setProfileMenu((pre) => !pre)}
                src={profile}
                alt="rectangle-1413.png"
                className="hover:opacity-90 w-[30px] h-[30px] absolute left-[-1px] top-[-1px] object-cover"
              />
            </div>
            <svg
              width={8}
              height={8}
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute left-[187.32px] top-[1.83px]"
              preserveAspectRatio="xMidYMid meet"
            >
              <circle
                cx="4.24457"
                cy="3.75607"
                r="2.92683"
                fill="#00BC4F"
                stroke="#F2F2F2"
                strokeWidth="0.731707"
              />
            </svg>
            <span className="relative">
              {showProfileMenu ? (
                <div className="absolute top-[50px] right-[-20px]">
                  <ProfileMenuClient closeMenu={() => setProfileMenu(false)} />
                </div>
              ) : null}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlatformHeader;
