import React from "react";

/*import visa from "../../assets/images/bank/visa.png";*/
/*import mastercard from "../../assets/images/bank/mastercard.png";*/
import { Button,SIZE } from "../../components/ui/buttons/Button";
/*import Input from "../../components/ui/Input";*/
import error4044 from "../../assets/images/404/4044.png";
import { Link } from 'react-router-dom';

/*const NewTaxInput = ({ handleChange, formData }) => {
    return (
        <div className="flex justify-start flex-col lg:flex-row  items-start self-stretch  gap-[22px]">
            <Input
                label="Tipo de impuesto"
                placeholder="Tipo de impuesto"
                name="taxType"
                onChange={handleChange}
                value={formData.taxType}
            />
            <Input
                label="Tarifa"
                placeholder="Sin archivos"
                name="taxRate"
                onChange={handleChange}
                value={formData.taxRate}
                icon={<span className="translate-y-[-10px]">%</span>}
                type="number"
                min="1"
                max="100"
            />
        </div>
    );
};*/

/*const Authenticated = () => {
    return (
        <div className="flex relative flex-col gap-1 justify-start items-center lg:flex-row">
            <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M12 4.75C7.71875 4.75 4.25 8.25 4.25 12.5C4.25 16.7812 7.71875 20.25 12 20.25C16.25 20.25 19.75 16.7812 19.75 12.5C19.75 8.25 16.25 4.75 12 4.75ZM12 6.25C15.4375 6.25 18.25 9.0625 18.25 12.5C18.25 15.9688 15.4375 18.75 12 18.75C8.53125 18.75 5.75 15.9688 5.75 12.5C5.75 9.0625 8.53125 6.25 12 6.25ZM16.375 10.3438L15.6562 9.625C15.5312 9.46875 15.2812 9.46875 15.125 9.625L10.7188 14L8.84375 12.125C8.6875 11.9688 8.46875 11.9688 8.3125 12.125L7.59375 12.8125C7.46875 12.9688 7.46875 13.2188 7.59375 13.3438L10.4375 16.2188C10.5938 16.375 10.8125 16.375 10.9688 16.2188L16.375 10.875C16.5 10.7188 16.5 10.4688 16.375 10.3438Z"
                    fill="#00BC4F"
                />
            </svg>
            <p className=" text-sm font-bold text-left text-[#00bc4f]">
                AUTENTICADA
            </p>
        </div>
    );
};*/

/*const Unauthenticated = () => {
    return (
        <div className="flex relative gap-1 justify-start items-center">
            <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M12 4.75C7.71875 4.75 4.25 8.21875 4.25 12.5C4.25 16.7812 7.71875 20.25 12 20.25C16.2812 20.25 19.75 16.7812 19.75 12.5C19.75 8.21875 16.2812 4.75 12 4.75ZM12 18.75C8.53125 18.75 5.75 15.9688 5.75 12.5C5.75 9.0625 8.53125 6.25 12 6.25C15.4375 6.25 18.25 9.0625 18.25 12.5C18.25 15.9688 15.4375 18.75 12 18.75ZM15.1562 10.5625C15.3125 10.4375 15.3125 10.1875 15.1562 10.0312L14.4688 9.34375C14.3125 9.1875 14.0625 9.1875 13.9375 9.34375L12 11.2812L10.0312 9.34375C9.90625 9.1875 9.65625 9.1875 9.5 9.34375L8.8125 10.0312C8.65625 10.1875 8.65625 10.4375 8.8125 10.5625L10.75 12.5L8.8125 14.4688C8.65625 14.5938 8.65625 14.8438 8.8125 15L9.5 15.6875C9.65625 15.8438 9.90625 15.8438 10.0312 15.6875L12 13.75L13.9375 15.6875C14.0625 15.8438 14.3125 15.8438 14.4688 15.6875L15.1562 15C15.3125 14.8438 15.3125 14.5938 15.1562 14.4688L13.2188 12.5L15.1562 10.5625Z"
                    fill="#D80027"
                />
            </svg>
            <p className=" text-sm font-bold text-left text-[#d80027]">
                VENCIDA
            </p>
        </div>
    );
};*/
// eslint-disable-next-line
function PaymentsFinances({
    /*cards = [
        {
            type: "visa",
            number: "#B-5FL982966B557205P",
            authenticated: true
        },
        {
            type: "mastercard",
            number: "#B-5FL982966B557205P",
            authenticated: false
        }
    ]*/
}) {
   /* const cardsDropList = cards.map((card) => `${card.type} - ${card.number}`);
    const [formData, setFormData] = useState({
        taxType: "",
        taxRate: "",
        listOfTaxes: [],
        preferredPayment: cardsDropList[0],
        currency: "GTQ"
    });*/

    /*const handleChange = (e) => {
        if (e.target.name === "taxRate" && e.target.value > 100) return;
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };*/

   /* const handleStoreTaxInfo = () => {
        // add the new tax info to the list of taxes
        setFormData((prev) => ({
            ...prev,
            listOfTaxes: [
                ...prev.listOfTaxes,
                { taxType: prev.taxType, taxRate: prev.taxRate }
            ],

            taxType: "",
            taxRate: ""
        }));
    };*/

    return (
        <div className="flex w-full flex-col items-center mt-[150px]" style={{ position:'relative', top: "-140px"}}  >
        <div style={{ fontSize:"40px", fontWeight:"700"}}>Página en construcción</div>
        <div  style={{ fontSize:"18px", fontWeight:"400", top:"10px", position:'relative'}}>Hola actualmente estamos trabajando en esta página, lo invitamos a regresar pronto.</div>
        <div className="  w-[63vw] h-[30vw] xl:w-[45vw]">
                           <img
                               src={error4044}
                               alt="error4044"
                               // xl:h-[75vh]
                               className={`bottom-[0%] right-[0%] object-contain xl:w-[95%]  `}
                           />
                       </div>
                       <div style={{ top:"35px" , position:'relative'}}>
                       <Link to="/platform/professional-dashboard">
                       <Button size={SIZE.LARGE} stretch  >
                          Regresar al tablero
                       </Button>
                       </Link>
                       </div>
       </div>
        /* <div className="flex flex-col justify-start items-start">
            <div className="flex flex-col justify-start items-start  w-full  lg:w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
                <p className="flex-grow w-full  lg:w-[765px] text-[21px] font-bold text-left text-[#373737]">
                    Pagos y finanzas
                </p>
            </div>
            <div className="flex flex-col justify-start items-start  w-full  lg:w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
                <p className=" w-full  lg:w-[765px] h-[25px] text-lg font-bold text-left text-[#373737]">
                    Formas de pago
                </p>
                {cards.map((card, index) => (
                    <div className="flex flex-col lg:flex-row  justify-start  items-center self-stretch  gap-[22px] pb-4 border-t-0 border-r-0 border-b border-l-0 border-[#e6e6e6]">
                        <div className="flex relative flex-col flex-grow gap-1 justify-start items-center lg:flex-row">
                            <img
                                src={card.type === "visa" ? visa : mastercard}
                                alt="58482363cef1014c0b5e49c1-1.png"
                                className="w-full max-w-[81px] h-[33px] object-cover"
                            />
                            <p className=" text-sm font-bold text-left text-[#373737]">
                                {card.number}
                            </p>
                        </div>
                        <div className="flex relative flex-col gap-1 justify-start items-center lg:flex-row">
                            {card.authenticated ? (
                                <Authenticated />
                            ) : (
                                <Unauthenticated />
                            )}
                        </div>
                    </div>
                ))}
                <Button size={SIZE.LARGE} color={COLOR.WHITE} iconName={"plus"}>
                    Agregar otra forma de pago
                </Button>
                <Input
                    type="dropdown"
                    selectList={cardsDropList}
                    label="Configura tu forma de pago preferida"
                    value={formData.preferredPayment}
                    onChange={handleChange}
                    name="preferredPayment"
                    addInputClass={" capitalize w-full lg:w-[400px]"}
                />
                <div className="flex justify-start  items-center self-stretch  relative gap-[5px] p-2 rounded bg-[#f5f8ff]">
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                            fill="#333333"
                        />
                    </svg>
                    <p className="flex-grow w-full  lg:w-[720px] text-sm text-left text-[#373737]">
                        La tarifa de suscripción de Membresía activa se restará
                        de su saldo en el sitio de forma predeterminada.
                    </p>
                </div>
            </div>
            <div className="flex flex-col justify-start items-start  w-full  lg:w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
                <p className=" w-full  lg:w-[765px] h-[25px] text-lg font-bold text-left text-[#373737]">
                    Configuración de finanzas
                </p>
                <div className="flex flex-col justify-start items-start  w-full  lg:w-[371.5px] gap-[5px]">
                    <Input
                        label="Mi moneda"
                        // placeholder="USD"
                        name="currency"
                        type="dropdown"
                        onChange={handleChange}
                        value={formData.currency}
                        selectList={[
                            "GTQ",
                            "USD",
                            "EUR",
                            "GBP",
                            "AUD",
                            "CAD",
                            "JPY"
                        ]}
                        // required
                        className="   lg:min-w-[200px]"
                    />
                </div>
                <p className=" w-full  lg:w-[765px] text-sm font-bold text-left text-[#373737]">
                    Impuestos (se utiliza cuando se crea una factura para un
                    empleador)
                </p>
                {formData.listOfTaxes.length > 0 && (
                    <table className="w-full table-auto">
                        <thead>
                            <tr>
                                <th className="px-4 py-2">Impuesto</th>
                                <th className="px-4 py-2">Tarifa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData.listOfTaxes.map((tax, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-2 border">
                                        {tax.taxType}
                                    </td>
                                    <td className="px-4 py-2 border">
                                        {tax.taxRate}%
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <NewTaxInput
                    handleChange={handleChange}
                    formData={formData}
                    setFormData={setFormData}
                />
                <Button
                    size={SIZE.LARGE}
                    color={COLOR.WHITE}
                    iconName={"plus"}
                    text="Otro impuesto"
                    onClick={handleStoreTaxInfo}
                />
            </div>
            <div className="flex flex-col justify-center items-center md:items-end  w-full  lg:w-[813px] gap-[22px] p-6 bg-[#f2f2f2] border border-[#cdcdcd]">
                <Button size={SIZE.LARGE}>Guardar configuración</Button>
            </div>
        </div>*/
    );
}

export default PaymentsFinances;
