function Whatsapp() {

    return (
        <div
            onClick={() => window.open("https://api.whatsapp.com/send?phone=50251119923")}
            className="z-10 cursor-pointer fixed bottom-[3%] right-[3%] flex justify-start items-start gap-[16.494932174682617px] p-[11.70608139038086px] rounded-[164.95px]  bg-[#292929]"
            style={{ boxShadow: "0px 1px 5px 0 rgba(0,0,0,0.25)" }}
        >
            <svg
                width={36}
                height={36}
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="flex-grow-0 flex-shrink-0 w-[35.07px] h-[35.07px] relative"
                preserveAspectRatio="xMidYMid meet"
            >
                    <g clip-path="url(#clip0_11291_3590)">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.9842 2.84311C9.60451 2.84311 2.79297 9.60564 2.79297 17.9192C2.79096 21.0986 3.80481 24.1955 5.68665 26.7582L3.78898 32.3569L9.62656 30.5021C12.1099 32.1317 15.0163 32.9981 17.9866 32.994C26.3626 32.994 33.1778 26.2314 33.1778 17.9253C33.1778 9.61912 26.3614 2.84923 17.9866 2.84923L17.9842 2.84311Z"
                            fill="#292929"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M35.1111 17.7907C35.1111 27.224 27.404 34.8735 17.8961 34.8735C14.9872 34.8783 12.1243 34.1471 9.57398 32.7479L0.0415039 35.7764L3.14835 26.6114C1.52872 23.9546 0.67406 20.9022 0.678554 17.7907C0.678554 8.35746 8.38686 0.706726 17.8961 0.706726C27.4053 0.706726 35.1111 8.35501 35.1111 17.7907ZM17.8961 3.42767C9.91211 3.42767 3.42032 9.86923 3.42032 17.7907C3.41992 20.8205 4.38788 23.7712 6.18291 26.212L4.36977 31.5449L9.93171 29.7783C12.2972 31.3306 15.0655 32.156 17.8948 32.1525C25.8751 32.1525 32.3681 25.7097 32.3681 17.7907C32.3681 9.87168 25.8751 3.42767 17.8948 3.42767H17.8961ZM26.5943 21.7257C26.4877 21.5505 26.2059 21.4452 25.7857 21.2357C25.3655 21.0262 23.2877 20.0106 22.9018 19.8746C22.5159 19.7386 22.2317 19.6651 21.9524 20.0829C21.673 20.5006 20.8608 21.4452 20.6146 21.7257C20.3683 22.0063 20.1245 22.0393 19.7006 21.8299C19.2768 21.6204 17.9181 21.1769 16.3071 19.7472C15.3859 18.9014 14.5945 17.9243 13.9586 16.8474C13.7136 16.4296 13.9341 16.203 14.1424 15.9898C14.3335 15.8011 14.565 15.4998 14.777 15.2548C14.9502 15.0425 15.0921 14.8065 15.1984 14.554C15.2551 14.4385 15.2816 14.3104 15.2754 14.1819C15.2692 14.0533 15.2305 13.9285 15.1629 13.8189C15.0575 13.6107 14.2134 11.5501 13.8618 10.7109C13.5102 9.87168 13.1598 9.90475 12.9124 9.90475C12.6771 9.91556 12.4428 9.9401 12.2104 9.97826C12.2104 9.97826 11.3651 10.0824 10.9853 10.5014C10.6055 10.9204 9.50905 11.9335 9.50905 13.9929C9.50905 16.0523 11.0196 18.0455 11.2303 18.3236C11.441 18.6017 14.1375 22.9827 18.4339 24.6598C22.7303 26.337 22.7217 25.7759 23.4984 25.7061C24.2751 25.6362 25.9952 24.6941 26.3468 23.7153C26.6984 22.7364 26.6996 21.8985 26.5918 21.7257H26.5943Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clippath id="clip0_11291_3590">
                            <rect
                                width="35.0708"
                                height="35.0708"
                                fill="white"
                                transform="translate(0.0410156 0.706085)"
                            />
                        </clippath>
                    </defs>
                </svg>
            </div>
    );
}

export default Whatsapp;
