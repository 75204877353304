function HIWWrapper({ children }) {
    return (
        <div className="flex flex-col items-center w-full ">
            <div
                className="flex flex-col justify-start items-center gap-[100px]  w-full lg:max-w-[2000px]
px-4 lg:px-[150px]"
            >
                {children}
            </div>
        </div>
    );
}

export default HIWWrapper;
