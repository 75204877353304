import { Button, COLOR } from "../ui/buttons/Button";
import BlogComment from "./BlogComment";

function BlogComments({ blogComments = [1, 2], sort }) {
    return (
        <>
            {/* <p>{sort}</p> */}
            {blogComments.map((blogComment, id) => (
                <BlogComment blogComment={blogComment} />
            ))}
            <div className="flex flex-col justify-center items-start sm:items-end self-stretch  gap-2.5 mt-10">
                <Button color={COLOR.WHITE} iconName={"plus"} text="Ver más" />
            </div>
        </>
    );
}

export default BlogComments;
