import { useCallback, useState } from "react";
import Pagination from "../../../components/Pagination";
import PlatformWrapper from "../../../components/platform/PlatformWrapper";
import SearchPageInput from "../../../components/platform/SearchPageInput";
import SearchItemTemplate from "../../../components/platform/SearchItemTemplate";
import { PAGE_TYPES } from "../../../constants/platform";
import NavTabs from "../../../components/ui/NavTabs";

const CONTENT = {
    LAW: {
        type: "LAW",
        title: "Sección de Leyes",
        desc: "Consulte todas las leyes lorem ipsum",
        search: {
            placeholder: "Buscar leyes",
            underIcon: (
                <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="  w-[30px] h-[30px] relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M12.5005 18.125H12.4614C12.4614 17.5 12.5395 17.8125 9.1411 11.0547C8.8286 10.3516 8.16454 10 7.46142 10C6.79735 10 6.13329 10.3516 5.82079 11.0547C2.38329 17.8516 2.50048 17.5391 2.50048 18.125C2.50048 19.8828 4.72704 21.25 7.50048 21.25C10.2349 21.25 12.5005 19.8828 12.5005 18.125ZM5.74267 15.3906C6.17235 14.4922 6.75829 13.3594 7.46142 11.9141V11.875H7.50048C8.24267 13.4375 8.86767 14.6094 9.29735 15.5078C9.72704 16.4063 10.0786 17.0703 10.313 17.5H4.64892C4.92235 17.0313 5.27392 16.3281 5.74267 15.3906ZM23.1255 23.125H15.938V11.0938C16.9927 10.7813 17.813 9.88281 18.0474 8.75H23.1255C23.438 8.75 23.7505 8.47656 23.7505 8.125V7.5C23.7505 7.1875 23.438 6.875 23.1255 6.875H17.852C17.3442 5.78125 16.2505 5 15.0005 5C13.7114 5 12.6177 5.78125 12.1099 6.875H6.87548C6.52392 6.875 6.25048 7.1875 6.25048 7.5V8.125C6.25048 8.47656 6.52392 8.75 6.87548 8.75H11.9145C12.1489 9.88281 12.9692 10.7813 14.063 11.0938V23.125H6.87548C6.52392 23.125 6.25048 23.4375 6.25048 23.75V24.375C6.25048 24.7266 6.52392 25 6.87548 25H23.1255C23.438 25 23.7505 24.7266 23.7505 24.375V23.75C23.7505 23.4375 23.438 23.125 23.1255 23.125ZM15.0005 9.375C14.2974 9.375 13.7505 8.82813 13.7505 8.125C13.7505 7.46094 14.2974 6.875 15.0005 6.875C15.6645 6.875 16.2505 7.46094 16.2505 8.125C16.2505 8.82813 15.6645 9.375 15.0005 9.375ZM27.4614 18.125C27.4614 17.5 27.5395 17.8125 24.1411 11.0547C23.8286 10.3516 23.1645 10 22.4614 10C21.7974 10 21.1333 10.3516 20.8208 11.0547C17.3833 17.8516 17.5005 17.5391 17.5005 18.125C17.5005 19.8828 19.727 21.25 22.5005 21.25C25.2349 21.25 27.5005 19.8828 27.5005 18.125H27.4614ZM19.6489 17.5C19.9224 17.0313 20.2739 16.3281 20.7427 15.3906C21.1724 14.4922 21.7583 13.3594 22.4614 11.9141V11.875H22.5005C23.2427 13.4375 23.8677 14.6094 24.2974 15.5078C24.727 16.4063 25.0786 17.0703 25.313 17.5H19.6489Z"
                        fill="#D80027"
                    />
                </svg>
            ),
            underText: "17K resultados",
            resultsCount: "17K"
        }
    },
    JURIS: {
        type: "JURIS",
        title: "Jurisprudencias",
        desc: "Consulte todas las jurisprudencias lorem ipsum",
        search: {
            placeholder: "Buscar Jurisprudencias",
            underIcon: (
                <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="  w-[30px] h-[30px] relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M5.625 11.25H24.375C24.6875 11.25 25 10.9766 25 10.625V9.21875C25 8.94531 24.8047 8.71094 24.5703 8.63281L15.4297 5.11719C15.2734 5.03906 15.1172 5 15 5C14.8438 5 14.6875 5.03906 14.5313 5.11719L5.39063 8.63281C5.15625 8.71094 5 8.94531 5 9.21875V10.625C5 10.9766 5.27344 11.25 5.625 11.25ZM15 6.95313L21.3672 9.375H8.59375L15 6.95313ZM25.625 23.125H25V20.625C25 19.9609 24.3359 19.375 23.5547 19.375H22.5V12.5H20.625V19.375H18.125V12.5H16.25V19.375H13.75V12.5H11.875V19.375H9.375V12.5H7.5V19.375H6.40625C5.625 19.375 5 19.9609 5 20.625V23.125H4.375C4.02344 23.125 3.75 23.4375 3.75 23.75V24.375C3.75 24.7266 4.02344 25 4.375 25H25.625C25.9375 25 26.25 24.7266 26.25 24.375V23.75C26.25 23.4375 25.9375 23.125 25.625 23.125ZM23.125 23.125H6.875V21.25H23.125V23.125Z"
                        fill="#D80027"
                    />
                </svg>
            ),
            underText: "17K resultados",
            resultsCount: "17K"
        }
    },
    DOCS: {
        type: "DOCS",
        title: "Documentos Jurídicos",
        desc: "Consulte todos los Documentos Jurídicos lorem ipsum",
        search: {
            placeholder: "Buscar Docs Juridicos",
            underIcon: (
                <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="  w-[30px] h-[30px] relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M11.25 10.9375C11.25 11.1328 11.3672 11.25 11.5625 11.25H19.6875C19.8438 11.25 20 11.1328 20 10.9375V9.6875C20 9.53125 19.8438 9.375 19.6875 9.375H11.5625C11.3672 9.375 11.25 9.53125 11.25 9.6875V10.9375ZM11.5625 14.375H19.6875C19.8438 14.375 20 14.2578 20 14.0625V12.8125C20 12.6563 19.8438 12.5 19.6875 12.5H11.5625C11.3672 12.5 11.25 12.6563 11.25 12.8125V14.0625C11.25 14.2578 11.3672 14.375 11.5625 14.375ZM23.2422 20.625C23.5156 20.5859 23.75 20.3125 23.75 20V5.625C23.75 5.3125 23.4375 5 23.125 5H9.375C7.61719 5 6.25 6.40625 6.25 8.125V21.875C6.25 23.6328 7.61719 25 9.375 25H23.125C23.4375 25 23.75 24.7266 23.75 24.375V23.75C23.75 23.4766 23.5156 23.2031 23.2422 23.1641C23.0469 22.6563 23.0469 21.1328 23.2422 20.625ZM21.6406 20.625C21.5234 21.3281 21.5234 22.4609 21.6406 23.125H9.375C8.67188 23.125 8.125 22.5781 8.125 21.875C8.125 21.2109 8.67188 20.625 9.375 20.625H21.6406ZM21.875 6.875V18.75H9.375C8.90625 18.75 8.47656 18.8672 8.125 19.0234V8.125C8.125 7.46094 8.67188 6.875 9.375 6.875H21.875Z"
                        fill="#D80027"
                    />
                </svg>
            ),
            underText: "17K resultados",
            resultsCount: "17K"
        }
    },
    TELE: {
        type: "TELE",
        title: "Directorio telefónico",
        desc: "Consulte todas las jurisprudencias lorem ipsum",
        search: {
            placeholder: "Buscar número telefónico",
            underIcon: (
                <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="  w-[30px] h-[30px] relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M23.9063 17.8906L19.9219 16.2109C19.7266 16.1328 19.4922 16.0547 19.2188 16.0547C18.6719 16.0547 18.1641 16.3281 17.8516 16.7188L16.5625 18.2813C14.4922 17.1484 12.8516 15.5078 11.7188 13.4375L13.2813 12.1484C13.6719 11.8359 13.9453 11.3281 13.9453 10.7813C13.9453 10.5078 13.8672 10.2734 13.7891 10.0781L12.1094 6.09375C11.8359 5.46875 11.1719 5 10.4297 5C10.3125 5 10.1953 5.03906 10.0391 5.07813L6.36719 5.89844C5.58594 6.09375 5 6.79688 5 7.65625C5 17.2656 12.7734 25.0391 22.3438 25C23.2031 25 23.9063 24.4141 24.1016 23.6328L24.9219 19.9609C24.9609 19.8047 25 19.6875 25 19.5703C25 18.8281 24.5313 18.1641 23.9063 17.8906ZM22.2656 23.1641C13.7891 23.125 6.91406 16.2109 6.875 7.73438L10.3906 6.91406L12.0313 10.7422L9.375 12.9297C11.2109 16.7969 13.2031 18.7891 17.0703 20.625L19.2578 17.9688L23.0859 19.6094L22.2656 23.1641Z"
                        fill="#D80027"
                    />
                </svg>
            ),
            underText: "17K resultados",
            resultsCount: "17K"
        }
    }
};

function SearchTemplates({
    type = "LAW",
    title = "Sección de Leyes",
    desc = "Consulte todas las leyes lorem ipsum",
    search = {
        placeholder: "Buscar leyes",
        underIcon: (
            <svg
                width={30}
                height={30}
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="  w-[30px] h-[30px] relative"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M12.5005 18.125H12.4614C12.4614 17.5 12.5395 17.8125 9.1411 11.0547C8.8286 10.3516 8.16454 10 7.46142 10C6.79735 10 6.13329 10.3516 5.82079 11.0547C2.38329 17.8516 2.50048 17.5391 2.50048 18.125C2.50048 19.8828 4.72704 21.25 7.50048 21.25C10.2349 21.25 12.5005 19.8828 12.5005 18.125ZM5.74267 15.3906C6.17235 14.4922 6.75829 13.3594 7.46142 11.9141V11.875H7.50048C8.24267 13.4375 8.86767 14.6094 9.29735 15.5078C9.72704 16.4063 10.0786 17.0703 10.313 17.5H4.64892C4.92235 17.0313 5.27392 16.3281 5.74267 15.3906ZM23.1255 23.125H15.938V11.0938C16.9927 10.7813 17.813 9.88281 18.0474 8.75H23.1255C23.438 8.75 23.7505 8.47656 23.7505 8.125V7.5C23.7505 7.1875 23.438 6.875 23.1255 6.875H17.852C17.3442 5.78125 16.2505 5 15.0005 5C13.7114 5 12.6177 5.78125 12.1099 6.875H6.87548C6.52392 6.875 6.25048 7.1875 6.25048 7.5V8.125C6.25048 8.47656 6.52392 8.75 6.87548 8.75H11.9145C12.1489 9.88281 12.9692 10.7813 14.063 11.0938V23.125H6.87548C6.52392 23.125 6.25048 23.4375 6.25048 23.75V24.375C6.25048 24.7266 6.52392 25 6.87548 25H23.1255C23.438 25 23.7505 24.7266 23.7505 24.375V23.75C23.7505 23.4375 23.438 23.125 23.1255 23.125ZM15.0005 9.375C14.2974 9.375 13.7505 8.82813 13.7505 8.125C13.7505 7.46094 14.2974 6.875 15.0005 6.875C15.6645 6.875 16.2505 7.46094 16.2505 8.125C16.2505 8.82813 15.6645 9.375 15.0005 9.375ZM27.4614 18.125C27.4614 17.5 27.5395 17.8125 24.1411 11.0547C23.8286 10.3516 23.1645 10 22.4614 10C21.7974 10 21.1333 10.3516 20.8208 11.0547C17.3833 17.8516 17.5005 17.5391 17.5005 18.125C17.5005 19.8828 19.727 21.25 22.5005 21.25C25.2349 21.25 27.5005 19.8828 27.5005 18.125H27.4614ZM19.6489 17.5C19.9224 17.0313 20.2739 16.3281 20.7427 15.3906C21.1724 14.4922 21.7583 13.3594 22.4614 11.9141V11.875H22.5005C23.2427 13.4375 23.8677 14.6094 24.2974 15.5078C24.727 16.4063 25.0786 17.0703 25.313 17.5H19.6489Z"
                    fill="#D80027"
                />
            </svg>
        ),
        underText: "17K resultados",
        resultsCount: "17K"
    }
}) {
    const [formData, setFormData] = useState({});

    const handleChange = useCallback((target = {}) => {
        let { name, value } = target;
        // console.log(target);
        setFormData((data) => ({ ...data, ...{ [name]: value } }));
        // console.log(formData);
    }, []);

    const handleFormSubmission = useCallback((e) => {
        // submit formData
        e.preventDefault();
    }, []);

    return (
        <div className="flex flex-col  justify-start items-center relative w-full mt-[100px] ">
            <div className="flex flex-col items-start">
                <div className="flex flex-col items-start justify-start gap-5 px-5 lg:p-0">
                    <div className="flex flex-col justify-start items-start w-full   lg:w-[696px] relative gap-[5px]">
                        <p className="self-stretch   lg:w-[696px] text-md lg:text-lg font-bold text-left text-[#373737]">
                            {CONTENT[type].title}
                        </p>
                        <p className="self-stretch   lg:w-[696px] text-sm lg:text-base text-left text-[#7b7b7b]">
                            {CONTENT[type].desc}
                        </p>
                    </div>
                </div>
                <PlatformWrapper className="flex flex-col lg:flex-row  justify-start items-center h-full  mt-[50px]">
                    <div className="flex flex-col items-center justify-start gap-5 lg:items-start">
                        <SearchPageInput
                            CONTENT={CONTENT}
                            formData={formData}
                            handleChange={handleChange}
                            handleFormSubmission={handleFormSubmission}
                            type={type}
                        />
                        <div className="flex flex-col justify-start w-full gap-6 lg:items-end">
                            {type !== PAGE_TYPES.TELE ? (
                                <div className="flex justify-center items-center   lg:w-[816px]">
                                    <NavTabs
                                        tabsClass={
                                            "flex items-end justify-end w-full"
                                        }
                                        tabs={[
                                            { text: "Derecho público" },
                                            { text: "Derecho privadot" }
                                        ]}
                                        views={[]}
                                    />
                                </div>
                            ) : null}
                            <Pagination />
                            <div className="flex flex-col justify-start lg:items-end ">
                                <SearchItemTemplate type={type} />
                                <SearchItemTemplate type={type} />
                                <SearchItemTemplate type={type} />
                                <SearchItemTemplate type={type} />
                                <SearchItemTemplate type={type} />
                            </div>
                            <Pagination />
                        </div>
                    </div>
                </PlatformWrapper>
            </div>
        </div>
    );
}

export default SearchTemplates;
