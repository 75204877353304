import { useCallback, useState } from "react";

function HoverCard({ children, text, popoverElm = undefined }) {
    const [show, setShow] = useState(0);

    const handleHover = useCallback((hover) => {
        setShow(hover);
    }, []);

    return (
        <div className="relative ">
            <div
                // right-[86px] translate-y-[-50px] top-1
                // bottom-4 right-[21px]
                className={`z-1 pointer-events-none transition-all bottom-4 right-[89px] ease-in-out duration-300 absolute  w-full  card ${
                    show ? "block" : "hidden"
                }`}
            >
                <div
                    // w-[194px]
                    className="flex flex-col items-start justify-start h-fit min-w-fit "
                    style={{
                        filter: "drop-shadow(0px 3px 8px rgba(0,0,0,0.15))"
                    }}
                >
                    {popoverElm ?? (
                        <div className="flex   w-[200px]  text-sm text-left text-[#373737] justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 p-2.5 rounded-[10px] bg-white">
                            {/* w-[174px] */}
                            {text}
                        </div>
                    )}
                    <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                        <svg
                            width={20}
                            height={16}
                            viewBox="0 0 20 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-0 -translate-y-1"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M11.1485 14.3928C10.543 15.2267 9.29641 15.2158 8.70563 14.3715L0.888718 3.19968C0.189655 2.20059 0.9115 0.82915 2.13083 0.839791L17.9584 0.977918C19.1778 0.988559 19.8756 2.37239 19.1592 3.35912L11.1485 14.3928Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div
                onMouseLeave={() => handleHover(0)}
                onMouseOver={() => handleHover(1)}
                className="z-10 cursor-help"
            >
                {children}
            </div>
        </div>
    );
}

export default HoverCard;
