import { useCallback, useState } from "react";
import avatar from "../../assets/images/avatar2.png";
import { mapSvg } from "../../utils/icons";
import Dialog from "../Dialog";
import ReportDialog from "../dialogs/ReportDialog";
import { Button, SIZE } from "../ui/buttons/Button";
import RatingStars from "../ui/buttons/RatingStars";

function ProfSearchCard() {
    const [formData, setFormData] = useState({ desc: "" });

    const handleChange = useCallback((e = {}) => {
        let { name, value } = e.target;
        console.log(name);
        console.log(value);
        // console.log(formData);
        setFormData((data) => ({ ...data, ...{ [name]: value } }));
    }, []);

    // const handleFormSubmission = useCallback((e) => {
    //     e.preventDefault();
    // }, []);
    const [showDialog, setDialog] = useState(0);

    return (
        <>
            <Dialog
                showDialog={showDialog}
                setDialog={setDialog}
                main={
                    <ReportDialog
                        setDialog={setDialog}
                        handleChange={handleChange}
                        formData={formData}
                    />
                }
            />
            <div className="flex flex-col justify-start items-start self-stretch   relative gap-4 p-[30px] bg-white border border-[#cdcdcd]">
                <div className="relative flex flex-col self-stretch justify-start gap-5 lg:items-start lg:flex-row ">
                    <div className="flex items-center justify-start flex-grow flex-shrink gap-4">
                        <img
                            src={avatar}
                            alt="rectangle-1413.png"
                            className="w-[64px] lg:w-[84px] h-[64px] lg:h-[84px]  rounded-[999px] object-contain"
                        />
                        <div className="flex flex-col justify-center items-start flex-grow relative gap-[5px]  ">
                            <div className="flex justify-start items-center   relative gap-[5px]">
                                <p className="  text-sm lg:text-base font-bold text-left text-[#373737]">
                                    Abidemi K.
                                </p>
                                <svg
                                    width={24}
                                    height={25}
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                        d="M21.9997 5.5H2V18.8333H21.9997V5.5Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M8.66643 5.5H2V18.8333H8.66643V5.5Z"
                                        fill="#4997D0"
                                    />
                                    <path
                                        d="M22.0004 5.5H15.334V18.8333H22.0004V5.5Z"
                                        fill="#4997D0"
                                    />
                                </svg>
                            </div>
                            <p className="  text-sm lg:text-base text-left text-[#d80027]">
                                @abidemi
                            </p>

                            <p className="  overflow-hidden text-sm lg:text-base font-bold text-left text-[#373737]">
                                Derecho de la Tecnología de la Información
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start flex-grow gap-[5px] h-full">
                        <div className="flex justify-start items-center   h-[22px] relative gap-4">
                            <RatingStars rating={3.5} />
                            <p className="  text-sm lg:text-base text-left text-[#373737]">
                                3,5
                            </p>
                        </div>
                        <div className="flex items-center justify-start gap-4">
                            <div className="flex justify-start items-center   relative gap-[2.3480663299560547px]">
                                <svg
                                    width={24}
                                    height={25}
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M18 4.5H6C4.875 4.5 4 5.40625 4 6.5V15.5C4 16.625 4.875 17.5 6 17.5H9V20.125C9 20.375 9.15625 20.5 9.375 20.5C9.4375 20.5 9.5 20.5 9.59375 20.4375L13.5 17.5H18C19.0938 17.5 20 16.625 20 15.5V6.5C20 5.40625 19.0938 4.5 18 4.5ZM18.5 15.5C18.5 15.7812 18.25 16 18 16H13L12.5938 16.3125L10.5 17.875V16H6C5.71875 16 5.5 15.7812 5.5 15.5V6.5C5.5 6.25 5.71875 6 6 6H18C18.25 6 18.5 6.25 18.5 6.5V15.5Z"
                                        fill="#333333"
                                    />
                                </svg>
                                <p className="  text-sm lg:text-base text-left text-[#7b7b7b]">
                                    0
                                </p>
                            </div>
                            <div className="flex justify-start items-center   relative gap-[2.3480663299560547px]">
                                <svg
                                    width={25}
                                    height={25}
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M18.754 13.5H11.3477V6.09375C11.3477 5.8125 11.0977 5.59375 10.8477 5.59375C10.8165 5.59375 10.7852 5.59375 10.7852 5.59375C7.06647 6.125 4.22272 9.375 4.34772 13.2812C4.47272 17.1562 7.69147 20.375 11.5665 20.5C11.6602 20.5 11.754 20.5 11.8477 20.5C15.629 20.5 18.7227 17.7188 19.254 14.0625C19.2852 13.7812 19.0352 13.5 18.754 13.5ZM11.8477 19C11.754 19 11.6915 19 11.629 19C8.53522 18.9062 5.94147 16.3125 5.84772 13.2188C5.75397 10.5625 7.41022 8.21875 9.84772 7.375V15H17.4727C16.6602 17.375 14.4102 19 11.8477 19ZM13.3477 4.53125C13.3477 4.53125 13.3477 4.5 13.3165 4.5C13.0665 4.5 12.8477 4.75 12.8477 5.03125V12H19.8165C20.0977 12 20.3477 11.7812 20.3165 11.5C20.0665 7.75 17.0977 4.78125 13.3477 4.53125Z"
                                        fill="#333333"
                                    />
                                </svg>
                                <p className="  text-sm lg:text-base text-left text-[#7b7b7b]">
                                    100%
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="relative flex flex-col items-start justify-start ">
                        <p className="self-stretch   w-28 text-2xl font-bold text-right text-[#373737]">
                            GTQ 1750
                        </p>
                        <p className="self-stretch   w-28 text-sm text-right text-[#7b7b7b]">
                            en 10 días
                        </p>
                    </div>
                </div>
                <p className="self-stretch   lg:w-[716px] text-sm lg:text-base text-left">
                    <span className="self-stretch   lg:w-[716px] text-sm lg:text-base text-left text-[#7b7b7b]">
                        Amet minim mollit non deserunt ullamco est sit aliqua
                        dolor do amet sint. Velit officia consequat duis enim
                        velit mollit. Exercitation veniam consequat sunt nostrud
                        amet. Amet minim mollit non deserunt ullamco est sit
                        aliqua dolor do amet sint. Velit officia consequat duis
                        enim velit mollit. Exercitation veniam consequat sunt
                        nostrud amet.{" "}
                    </span>
                    <span className="self-stretch cursor-pointer hover:underline   lg:w-[716px] text-sm lg:text-base text-left text-[#d80027]">
                        más...
                    </span>
                </p>
                <div className="flex justify-start items-center  relative gap-[5px]">
                    <img src={mapSvg} alt="mapSvg" />
                    <p className=" text-base text-left text-[#373737]">
                        2118 Thornridge Cir. Syracuse, Connecticut 35624
                    </p>
                </div>
                <div className="flex flex-col self-stretch justify-end gap-5 lg:flex-row lg:items-center">
                    <button
                        onClick={() => setDialog(1)}
                        className="flex text-sm lg:text-base text-left text-[#d80027] justify-center items-center   h-11 relative gap-2.5 py-2.5 rounded-[20px]"
                    >
                        Denunciar oferta
                    </button>
                    <div className="flex flex-col justify-end flex-grow gap-5 lg:items-center lg:flex-row">
                        <Button
                            size={SIZE.MEDIUM}
                            addClass={"h-11  text-sm lg:text-base  "}

                            // className="flex  text-sm lg:text-base text-left text-white justify-center items-center   h-11 relative gap-2.5 px-4 py-1 lg:px-8 lg:py-2.5 rounded bg-[#d80027]"
                        >
                            Solicitar cotización
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfSearchCard;
