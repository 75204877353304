import { noop } from "../../utils/noop";
import Checkbox from "../ui/Checkbox";
import Input from "../ui/Input";
import { Button, COLOR } from "../ui/buttons/Button";
import React, { useCallback, useState } from "react";

function ReportTelephone({ closeDialog = noop }) {
    const [formData, setFormData] = useState({});

    const handleChange = useCallback(
        (target = {}) => {
            let { name, value } = target;
            console.log(target);
            setFormData((data) => ({ ...data, ...{ [name]: value } }));
            console.log(formData);
        },
        [formData]
    );

    const handleFormSubmission = useCallback((e) => {
        // submit formData
        e.preventDefault();
    }, []);

    return (
        <form
            onSubmit={handleFormSubmission}
            className="flex flex-col justify-start items-start lg:w-[516px] relative gap-5 p-5 bg-white"
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
        >
            <svg
                onClick={closeDialog}
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer flex-grow-0 flex-shrink-0  w-10 h-10 absolute left-[476px] top-0"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M20.0007 7.08398C12.8652 7.08398 7.08398 12.8652 7.08398 20.0007C7.08398 27.1361 12.8652 32.9173 20.0007 32.9173C27.1361 32.9173 32.9173 27.1361 32.9173 20.0007C32.9173 12.8652 27.1361 7.08398 20.0007 7.08398ZM20.0007 30.4173C14.2194 30.4173 9.58398 25.7819 9.58398 20.0007C9.58398 14.2715 14.2194 9.58398 20.0007 9.58398C25.7298 9.58398 30.4173 14.2715 30.4173 20.0007C30.4173 25.7819 25.7298 30.4173 20.0007 30.4173ZM25.2611 16.7715C25.5215 16.5632 25.5215 16.1465 25.2611 15.8861L24.1152 14.7402C23.8548 14.4798 23.4382 14.4798 23.2298 14.7402L20.0007 17.9694L16.7194 14.7402C16.5111 14.4798 16.0944 14.4798 15.834 14.7402L14.6882 15.8861C14.4277 16.1465 14.4277 16.5632 14.6882 16.7715L17.9173 20.0007L14.6882 23.2819C14.4277 23.4902 14.4277 23.9069 14.6882 24.1673L15.834 25.3132C16.0944 25.5736 16.5111 25.5736 16.7194 25.3132L20.0007 22.084L23.2298 25.3132C23.4382 25.5736 23.8548 25.5736 24.1152 25.3132L25.2611 24.1673C25.5215 23.9069 25.5215 23.4902 25.2611 23.2819L22.0319 20.0007L25.2611 16.7715Z"
                    fill="#333333"
                />
            </svg>
            <div className="flex justify-start items-center self-stretch  relative gap-2.5">
                <svg
                    width={40}
                    height={40}
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative flex-grow-0 flex-shrink-0 w-10 h-10 "
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M24.166 10.8341C21.6139 10.8341 19.2702 9.16739 15.7285 9.16739C14.0618 9.16739 12.6556 9.53197 11.5098 10.0007C11.6139 9.74031 11.6139 9.47989 11.6139 9.16739C11.6139 9.11531 11.6139 9.01114 11.6139 8.90697C11.5098 7.70906 10.5202 6.77156 9.32227 6.71947C7.86393 6.61531 6.66602 7.76114 6.66602 9.16739C6.66602 10.1049 7.13477 10.9382 7.91602 11.3549V32.5007C7.91602 32.9695 8.2806 33.3341 8.74935 33.3341H9.58268C9.99935 33.3341 10.416 32.9695 10.416 32.5007V28.1778C12.3431 27.2924 14.1139 26.6674 17.0306 26.6674C19.5827 26.6674 21.9264 28.3341 25.4681 28.3341C28.541 28.3341 30.7806 27.1882 32.1868 26.3028C32.8639 25.8341 33.3327 25.0528 33.3327 24.1674V11.6674C33.3327 9.89656 31.4577 8.69864 29.8431 9.37572C27.9681 10.2091 25.9889 10.8341 24.166 10.8341ZM30.8327 24.1674C29.6868 25.0007 27.6556 25.8341 25.4681 25.8341C22.3431 25.8341 20.1556 24.1674 17.0306 24.1674C14.791 24.1674 12.0306 24.6882 10.416 25.4174V13.3341C11.5098 12.5528 13.541 11.6674 15.7285 11.6674C18.8535 11.6674 21.041 13.3341 24.166 13.3341C26.4056 13.3341 29.166 12.4486 30.8327 11.6674V24.1674Z"
                        fill="#D80027"
                    />
                </svg>
                <p className="flex-grow lg:w-[426px] text-2xl font-bold text-left text-[#373737]">
                    Reportar un problema
                </p>
            </div>

            <Input
                label="Nombre"
                placeholder="Nombre"
                required
                onChange={(e) => handleChange(e.target)}
                value={formData.name}
                name="name"
            />
            <Checkbox checked text="El número telefónico actual ha cambiado" />
            <Input
                label="Nuevo número telefónico"
                placeholder="Número de teléfono"
                required
                onChange={(e) => handleChange(e.target)}
                value={formData.newNumber}
                name="newNumber"
                type="number"
            />
            <Input
                // labelClass={
                //     "self-stretch   lg:w-[756px] text-base font-bold text-left text-[#373737]"
                // }
                label="Descripción"
                required
                onChange={(e) => handleChange(e.target)}
                value={formData.desc}
                name="desc"
                type="long"
                placeholder="Descripción"
            />
            <div className="flex items-start self-stretch justify-start gap-5">
                <Button
                    addClass={"h-11"}
                    stretch
                    color={COLOR.WHITE}
                    onClick={closeDialog}
                    // className="flex justify-center items-center flex-grow h-11 relative gap-2.5 px-8 py-2.5 rounded border-2 border-[#292929]"
                >
                    Cancelar
                </Button>
                <Button
                    type="submit"
                    addClass={"h-11"}
                    stretch
                    // className="flex justify-center items-center flex-grow h-11 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]"
                >
                    Enviar
                </Button>
            </div>
        </form>
    );
}

export default ReportTelephone;
