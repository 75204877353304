import React from "react";

import avatar from "../../../assets/images/landing/profile.png";

function Message({
    user = {
        avatar,
        name: "Samuel",
        status: "online"
    },
    date = null,
    message = "Hola un placer saludarte",
    handleNavigateNext
}) {
    return (
        <div className="flex relative flex-col gap-5 justify-start items-start">
            {Boolean(date) && (
                <p className="font-[MaisonNeue] w-full max-w-96 text-xs text-center text-[#7b7b7b]">
                    {date}
                </p>
            )}
            <div className="flex justify-start items-center  w-full max-w-96 relative gap-2.5">
                <div className=" w-[41px] h-[41px]">
                    <div
                        onClick={handleNavigateNext}
                        className="w-[41px] h-[41px] absolute left-0 top-[6.5px] overflow-hidden rounded-[100px] border-2 border-[#d9d9d9]"
                    >
                        <img
                            src={user?.avatar}
                            alt="rectangle-1413.png"
                            className="w-[41px] h-[41px] absolute left-[-1.37px] top-[-1.37px] object-cover"
                        />
                    </div>
                    {user?.status === "online" && (
                        <svg
                            width={9}
                            height={10}
                            viewBox="0 0 9 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute left-[30.5px] top-[9px]"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <circle
                                cx="4.5"
                                cy={5}
                                r={4}
                                fill="#00BC4F"
                                stroke="#F2F2F2"
                            />
                        </svg>
                    )}
                </div>
                <div className="flex justify-start items-center">
                    <svg
                        width={16}
                        height={20}
                        viewBox="0 0 16 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=""
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M0.889107 8.46154C-0.295201 9.1453 -0.295201 10.8547 0.889107 11.5385L15.5449 20L15.5449 0L0.889107 8.46154Z"
                            fill="#FFECEF"
                        />
                    </svg>
                    <div className="flex justify-center items-center  relative gap-2.5 p-4 rounded-[10px] bg-[#ffecef]">
                        <p className=" text-base text-left text-[#292929]">
                            {message}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Message;
