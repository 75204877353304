import avatar from "../../assets/images/avatar2.png";

function CommunityPost({
    post = {
        verified: false,
        title: `Amet minim mollit non deserunt ullamco est sit aliqua...`,
        createAgo: "3 horas",
        views: 432,
        replies: 23,
        user: {
            title: "por",
            name: "Abidemi K.",
            image: avatar,

            country: (
                <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative w-6 h-6 "
                    preserveAspectRatio="none"
                >
                    <path d="M21.9997 5H2V18.3333H21.9997V5Z" fill="white" />
                    <path d="M8.66643 5H2V18.3333H8.66643V5Z" fill="#4997D0" />
                    <path
                        d="M22.0004 5H15.334V18.3333H22.0004V5Z"
                        fill="#4997D0"
                    />
                </svg>
            )
        }
    }
}) {
    return (
        <a
            href="/platform/community/blog"
            className="flex flex-col sm:flex-row justify-between items-center    w-full self-stretch gap-2.5 p-5 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]"
        >
            <div className="flex flex-col justify-start items-start flex-grow relative gap-2.5">
                <p className="self-stretch underline    text-md sm:text-lg text-left text-[#373737]">
                    {post.title}
                </p>
                <div className="flex justify-start items-center   relative gap-2.5">
                    <div className="  w-10 h-10 relative overflow-hidden rounded-[30.56px]">
                        <img
                            alt="rectangle-1413.png"
                            src={avatar}
                            className="w-10 h-10 absolute left-[-0.44px] top-[-0.44px] object-cover"
                        />
                    </div>
                    <div className="flex justify-start items-center   relative gap-2.5">
                        <div className="flex justify-start items-center   relative gap-[5px]">
                            <p className="  text-sm text-left text-[#7b7b7b]">
                                por
                            </p>
                            <p className="  text-sm sm:text-base font-bold text-left text-[#373737]">
                                Abidemi K.
                            </p>
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M21.9997 5H2V18.3333H21.9997V5Z"
                                    fill="white"
                                />
                                <path
                                    d="M8.66643 5H2V18.3333H8.66643V5Z"
                                    fill="#4997D0"
                                />
                                <path
                                    d="M22.0004 5H15.334V18.3333H22.0004V5Z"
                                    fill="#4997D0"
                                />
                            </svg>
                        </div>
                        <p className="  text-sm text-left text-[#7b7b7b]">
                            hace 3 horas
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-2 ">
                <div className="flex flex-row sm:flex-col justify-start items-start   relative gap-2.5">
                    <p className="self-stretch     text-sm sm:text-base text-left text-[#d80027]">
                        24 replicas
                    </p>
                    <p className="self-stretch     text-sm sm:text-base font-bold text-left text-[#7b7b7b]">
                        148 Views
                    </p>
                </div>
                <div
                    className={`flex justify-center items-center self-stretch   relative gap-2.5 p-1 ${
                        post.verified ? "bg-[#00bc4f]" : ""
                    }`}
                >
                    <svg
                        width={24}
                        height={25}
                        viewBox="0 0 24 25"
                        fill={"none"}
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        {!post.verified ? null : (
                            <path
                                d="M12 4.75C7.71875 4.75 4.25 8.25 4.25 12.5C4.25 16.7812 7.71875 20.25 12 20.25C16.25 20.25 19.75 16.7812 19.75 12.5C19.75 8.25 16.25 4.75 12 4.75ZM12 6.25C15.4375 6.25 18.25 9.0625 18.25 12.5C18.25 15.9688 15.4375 18.75 12 18.75C8.53125 18.75 5.75 15.9688 5.75 12.5C5.75 9.0625 8.53125 6.25 12 6.25ZM16.375 10.3438L15.6562 9.625C15.5312 9.46875 15.2812 9.46875 15.125 9.625L10.7188 14L8.84375 12.125C8.6875 11.9688 8.46875 11.9688 8.3125 12.125L7.59375 12.8125C7.46875 12.9688 7.46875 13.2188 7.59375 13.3438L10.4375 16.2188C10.5938 16.375 10.8125 16.375 10.9688 16.2188L16.375 10.875C16.5 10.7188 16.5 10.4688 16.375 10.3438Z"
                                fill="#F2F2F2"
                            />
                        )}
                    </svg>
                </div>
                {/* )} */}
            </div>
        </a>
    );
}

export default CommunityPost;
