import React from "react";
import { Button, COLOR } from "../../ui/buttons/Button";
import Dialog from "../../Dialog";
import Input from "../../ui/Input";
import UploadImage from "../../form/UploadImage";
import { useLocation } from "react-router-dom";
import CustomDropdown from "../../ui/CustomDropdown";
import { twMerge } from "tailwind-merge";

const dummyMilestoneReleaseData = {
  paymentSummary: {
    requested: "30.00",
    inProgress: "30.00",
    releasedToProfessional: "00.00"
  },
  milestonePayment: {
    requestedMilestones: [
      {
        date: "July 13, 2023",
        description: "Lorem Ipsum",
        state: "On hold",
        amount: "20.00"
      },
      {
        date: "July 23, 2023",
        description: "Lorem Ipsum",
        state: "On hold",
        amount: "50.00"
      }
    ],
    milestonesCreated: [
      {
        date: "July 25, 2023",
        description: "Lorem Ipsum",
        state: "In progress",
        amount: "30.00"
      },

      {
        date: "July 28, 2023",
        description: "Lorem Ipsum",
        state: "In progress",
        amount: "100.00"
      }
    ]
  },
  processingFee: "0.99",
  totalOwed: "30.99"
};



const headings = [
  "Fecha",
  "Descripción",
  "Estado",
  "Monto",
  ""
]

const MilestonesHeader = () => (
  <div className="hidden md:flex border-b border-[#cdcdcd] w-full">
    {headings.map((heading, index) => (
      <p key={index} className={twMerge("text-base w-full font-bold text-left text-[#373737]", )}>
        {heading}
      </p>
    ))}
  </div>
);

const PaymentDialog = ({
  goToNext,
  title = "Confirma el total de tu pago en Efectivo"
}) => {
  const [formData, setFormData] = React.useState({
    description: "",

    amount: 30,
    currency: "🇨🇷 Quetzales"
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="flex flex-col justify-center items-center w-full h-screen">
      <div
        className="flex flex-col justify-start items-start  w-full max-x-[456px] bg-white"
        style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
      >
        <div className="flex flex-col justify-center items-start self-stretch  relative gap-5 px-4 py-5 border border-[#cdcdcd]">
          <p className="self-stretch   w-full max-x-[424px] text-[21px] font-bold text-left text-[#373737]">
            {title}
          </p>
          <div className="flex cursor-default justify-start items-center self-stretch  gap-[9px] p-2 bg-[#99e4b9]">
            <div className="flex justify-center items-center  relative gap-2.5 px-1.5 py-1 bg-white">
              <p className=" text-[21px] font-bold text-left text-[#373737]">
                100%
              </p>
            </div>
            <div className="flex relative flex-col justify-start items-start">
              <p className=" text-sm font-bold text-left uppercase text-[#373737]">
                Garantía de reembolso
              </p>
              <p className=" text-sm text-left text-[#373737]">
                Términos y condiciones
              </p>
            </div>
          </div>
          <div className="flex relative justify-between items-center self-stretch w-full">
            <p className="flex-grow  w-full max-x-[196px] text-sm font-bold text-left text-[#373737]">
              Moneda
            </p>

            <Input
              name="currency"
              value={formData.currency}
              onChange={(e) => handleChange(e)}
              type="dropdown"
              placeholder="🇨🇷 Quetzales"
              selectList={["🇨🇷 Quetzales", "🇺🇸 Dollars"]}
              icon={
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative w-6 h-6"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M18.7812 9.25L18.1875 8.625C18.0312 8.46875 17.7812 8.46875 17.6562 8.625L12 14.2812L6.3125 8.625C6.1875 8.46875 5.9375 8.46875 5.78125 8.625L5.1875 9.25C5.03125 9.375 5.03125 9.625 5.1875 9.78125L11.7188 16.3125C11.875 16.4688 12.0938 16.4688 12.25 16.3125L18.7812 9.78125C18.9375 9.625 18.9375 9.375 18.7812 9.25Z"
                    fill="#333333"
                  />
                </svg>
              }
            />
          </div>
          <div className="flex relative justify-between items-center self-stretch w-full">
            <p className="flex-grow w-full max-w-[196px] text-sm font-bold text-left text-[#373737]">
              Cantidad
            </p>

            <Input
              name="amount"
              type="number"
              onChange={handleChange}
              value={formData.amount}
              icon={
                <p className="flex-grow-0 flex-shrink-0 text-base text-left translate-y-[-8px] text-[#373737]">
                  GTQ
                </p>

                // <p className=" translate-y-[-10px] text-lg  text-left text-[#373737]">
                //   GTQ
                // </p>
              }
            />
          </div>
          <Input
            type="long"
            value={formData.description}
            label="Descripción"
            name="description"
            onChange={handleChange}
            required
            placeholder="Describe los servicios adicionales que recibiste"
            textAreaLimit={1200}
            addInputClass="h-[129px] "
          />
          <div className="flex flex-col justify-start items-start self-stretch  h-[107px] gap-2.5">
            <div className="flex flex-col justify-start items-start self-stretch  gap-[5px]">
              {/* <div className="flex justify-start items-center  relative gap-[5px]">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative w-6 h-6"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                    fill="#333333"
                  />
                </svg>
                <p className="text-sm text-left">
                  <span className=" text-sm text-left text-[#373737]">
                    Adjunte captura{" "}
                  </span>
                  <span className=" text-sm text-left text-[#d80027]">*</span>
                </p>
              </div> */}
              <UploadImage
                label="Adjunte captura"
                alignLeft
                info="Adjunte una captura de pantalla de la transferencia realizada"
                setFormData={setFormData}
                formData={formData}
              />
            </div>
          </div>
          <div className="flex relative justify-start items-center self-stretch">
            <p className="flex-grow  w-full max-x-[212px] text-sm font-bold text-left text-[#373737]">
              Total adeudado
            </p>
            <p className="flex-grow  w-full max-x-[212px] text-sm text-right text-[#373737]">
              {" "}
              GTQ 30,00
            </p>
          </div>
          <div className="flex justify-start items-center self-stretch  relative pb-5 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
            <div className="flex justify-start items-center flex-grow relative gap-[5px]">
              <p className=" text-sm font-bold text-left text-[#373737]">
                Cuota de procesamiento
              </p>
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <p className="flex-grow  w-full max-x-[212px] text-sm text-right text-[#373737]">
              GTQ 0,99
            </p>
          </div>
          <div className="flex relative justify-start items-center self-stretch">
            <p className="flex-grow  w-full max-x-[212px] text-sm font-bold text-left text-[#373737]">
              Total adeudado
            </p>
            <p className="flex-grow  w-full max-x-[212px] text-sm text-right text-[#373737]">
              GTQ 30,99
            </p>
          </div>
          <Button
            onClick={goToNext}
            addClass={"h-11"}
            stretch
            color={COLOR.BLACK}
          >
            Enviar
          </Button>
        </div>
      </div>
    </div>
  );
};

function ReleasePayment({ goToNext, buttonRed=false }) {
  const [showDialog, setShowDialog] = React.useState(false);
  const pathname = useLocation().pathname;

  const handleChange = (e) => {
      console.log(e.target.value)
      console.log(e.target.name)
  }

  return (
    <>
      <Dialog
        setDialog={setShowDialog}
        showDialog={showDialog}
        main={<PaymentDialog goToNext={goToNext} />}
      />
      <div className="flex flex-col">
        <div className="flex flex-col justify-start items-start w-full max-w-[1056px] gap-[30px]">

          <div className="flex flex-col justify-start items-start self-stretch  relative gap-[22px] pb-[30px] border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
            <p className="w-full max-w-[936px] text-[21px] font-bold text-left text-[#373737]">
              Resumen de pago
            </p>
            <div className="flex flex-col w-full sm:flex-row justify-between max-w-[800px] ">
              <div className="flex flex-col gap-3 items-start">
                <p className="flex-grow  w-full max-x-[180px] text-base font-bold text-left text-[#373737]">
                  Solicitado
                </p>
                <p className="flex-grow  w-full max-x-[181.33px] text-sm text-left text-[#373737]">
                  GTQ {dummyMilestoneReleaseData.paymentSummary.requested}
                </p>
              </div>
              <div className="flex flex-col gap-3 items-start">
                <p className="flex-grow  w-full max-x-[180px] text-base font-bold text-left text-[#373737]">
                  En curso
                </p>
                <p className="flex-grow  w-full max-x-[180px] text-sm text-left text-[#373737]">
                  GTQ {dummyMilestoneReleaseData.paymentSummary.inProgress}
                </p>
              </div>
              <div className="flex flex-col gap-3 items-start">
                <p className=" text-base font-bold text-left text-[#373737]">
                  Liberado al profesional
                </p>
                <p className="flex-grow  w-full max-x-[180px] text-sm text-left text-[#373737]">
                  GTQ{" "}
                  {
                    dummyMilestoneReleaseData.paymentSummary
                      .releasedToProfessional
                  }
                </p>
              </div>
            </div>
          </div>


          <div className="flex flex-col justify-start items-start self-stretch  gap-[30px]">
            <div className="flex relative justify-start items-center self-stretch">
              <p className="flex-grow w-full max-w-[1056px] text-[21px] font-bold text-left text-[#373737]">
                Pago de hitos
              </p>
            </div>

            <div className="flex flex-col  w-full gap-2.5">
              <p className="text-lg font-bold text-left text-[#373737]">
                Hitos solicitados
              </p>
              <div className="flex flex-col gap-[5px]">
                <MilestonesHeader />
                <div className="py-4">
                  <div className="flex flex-col gap-6">
                    {dummyMilestoneReleaseData.milestonePayment.requestedMilestones.map(
                      (milestone, index) => (
                        <div
                          key={index}
                          className="flex flex-col sm:flex-row w-full gap-3 justify-between"
                        >
                          <p className=" w-full text-base text-left text-[#373737]">
                            {milestone.date}
                          </p>
                          <p className=" w-full text-base text-left text-[#373737]">
                            {milestone.description}
                          </p>
                          <p className=" w-full text-base text-left text-[#292929]">
                            {milestone.state}
                          </p>
                          <p className=" w-full text-sm text-left text-[#373737]">
                            {`GTQ ${milestone.amount}`}
                          </p>
                          <p className="w-full hidden md:block"></p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full gap-2.5">
              <p className="w-full max-w-[1056px] text-lg font-bold text-left text-[#373737]">
                Hitos creados
              </p>
              <div className="flex flex-col w-full gap-[5px]">
                <MilestonesHeader />
                <div className="py-4">
                  <div className="flex flex-col gap-6">
                    {dummyMilestoneReleaseData.milestonePayment.milestonesCreated.map(
                      (milestone, index) => (
                        <div
                          key={index}
                          className="flex flex-col sm:flex-row w-full gap-3"
                        >
                          <p className="w-full text-base text-left text-[#373737]">
                            {milestone.date}
                          </p>
                          <p className="w-full text-base text-left text-[#373737]">
                            {milestone.description}
                          </p>
                          <p className="w-full text-base text-left text-[#292929]">
                            {milestone.state}
                          </p>
                          <p className="w-full text-sm text-left text-[#373737]">
                            {" "}
                            {`GTQ ${milestone.amount}`}
                          </p>
                          {/* <DropdownPlaceholder /> */}

                          
                          <CustomDropdown 
                            options={[
                              "Liberar pago",
                              "Anular hito ",
                              "Disputar "
                            ]} 
                            name={""}
                            onChange={handleChange}
                            className={"min-w-[175px] w-full"}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full max-w-[700px] pb-5 ">
              <div className="w-full flex max-x-[394.5px]  justify-start items-center">
                <p className=" text-sm font-bold text-left text-[#373737]">
                  Cuota de procesamiento
                </p>
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative w-6 h-6"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                    fill="#333333"
                  />
                </svg>
              </div>
              <p className=" w-full max-x-[392px] text-sm text-right text-[#373737]">
                {`GTQ ${dummyMilestoneReleaseData.processingFee}`}
              </p>
            </div>

            <div className="flex w-full max-w-[700px] pb-5 ">
              <p className="  w-full max-x-[394.5px] text-sm font-bold text-left text-[#373737]">
                Total adeudado
              </p>
              <p className=" w-full max-x-[394.5px] text-sm text-right text-[#373737]">
                {`GTQ ${dummyMilestoneReleaseData.totalOwed}`}
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center py-10 w-full lg:justify-end lg:p-0">
          {pathname === "/platform/client/urgent-advice/processing" || buttonRed ? 
          <Button
            addClass={"h-11 w-fit"}
            color={COLOR.RED}
            onClick={() => setShowDialog(true)}
          >
            Liberar todos los pagos
          </Button>
        :
        
          <Button
            addClass={"h-11 w-fit"}
            color={COLOR.BLACK}
            onClick={() => setShowDialog(true)}
          >
            Liberar todos los pagos
          </Button>
        }
        </div>
      </div>
    </>
  );
}

export default ReleasePayment;
