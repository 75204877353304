import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import ProfFilters from "../../components/platform/ProfFilters";
import ProfSearch from "../../components/platform/ProfSearch";
import { EXCLUSIVE_LATIN_COUNTRIES } from "../../constants/constants";
import { useScreenDimensions } from "../../hooks/useDeviceCheck";
function ProfessionalDirectory() {
    useEffect(() => {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
            window.location.href = "/login";
        }
    }, []);
    const [formData, setFormData] = useState({
        country: EXCLUSIVE_LATIN_COUNTRIES.COSTA_RICA
    });
    const [showFilter, setShowFilter] = useState(false);

    const toggleFilterExpand = useCallback(() => {
        setShowFilter((f) => !f);
    }, []);

    const handleChange = useCallback((target = {}) => {
        let { name, value } = target;
        console.log(target);
        setFormData((data) => ({ ...data, ...{ [name]: value } }));
        // console.log(formData);
    }, []);

    const handleFormSubmission = useCallback((e) => {
        // submit formData
        e.preventDefault();
    }, []);

    const { width } = useScreenDimensions();

    const filterSideBarView = width < 1173;

    return (
        <div className="flex flex-col  justify-start items-center relative gap-5 w-full mt-[100px]  ">
            <div className="flex gap-6 justify-start items-start">
                <div
                    className={`${
                        !showFilter && filterSideBarView ? "hidden" : ""
                    } ${
                        filterSideBarView
                            ? "absolute left-0 top-0 z-20 bg-white p-10 h-full"
                            : ""
                    }  `}
                >
                    <ProfFilters
                        filterSideBarView={filterSideBarView}
                        toggleFilterExpand={toggleFilterExpand}
                        handleFormSubmission={handleFormSubmission}
                        formData={formData}
                        setFormData={setFormData}
                        handleChange={handleChange}
                        useFormik={useFormik}
                        Yup={Yup}
                    />
                </div>
                <ProfSearch
                    filterSideBarView={filterSideBarView}
                    toggleFilterExpand={toggleFilterExpand}
                    handleFormSubmission={handleFormSubmission}
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    useFormik={useFormik}
                    Yup={Yup}
                />
            </div>
        </div>
    );
}

export default ProfessionalDirectory;
