import { useState } from "react";
import Dialog from "../../Dialog";
import ReportTelephone from "../../dialogs/ReportTelephone";

function ReportTelephoneButton({ telephoneId }) {
    // todo: Abstract this even more with DIALOG HANDLER? (clickable, dialog)
    const [showDialog, setDialog] = useState(0);

    return (
        <>
            <Dialog
                showDialog={showDialog}
                setDialog={setDialog}
                main={
                    <ReportTelephone
                        telephoneId={telephoneId}
                        closeDialog={() => setDialog(0)}
                    />
                }
            />

            <svg
                onClick={() => setDialog(1)}
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0 w-10 h-10 cursor-pointer hover:opacity-90"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M24.166 10.8341C21.6139 10.8341 19.2702 9.16739 15.7285 9.16739C14.0618 9.16739 12.6556 9.53197 11.5098 10.0007C11.6139 9.74031 11.6139 9.47989 11.6139 9.16739C11.6139 9.11531 11.6139 9.01114 11.6139 8.90697C11.5098 7.70906 10.5202 6.77156 9.32227 6.71947C7.86393 6.61531 6.66602 7.76114 6.66602 9.16739C6.66602 10.1049 7.13477 10.9382 7.91602 11.3549V32.5007C7.91602 32.9695 8.2806 33.3341 8.74935 33.3341H9.58268C9.99935 33.3341 10.416 32.9695 10.416 32.5007V28.1778C12.3431 27.2924 14.1139 26.6674 17.0306 26.6674C19.5827 26.6674 21.9264 28.3341 25.4681 28.3341C28.541 28.3341 30.7806 27.1882 32.1868 26.3028C32.8639 25.8341 33.3327 25.0528 33.3327 24.1674V11.6674C33.3327 9.89656 31.4577 8.69864 29.8431 9.37572C27.9681 10.2091 25.9889 10.8341 24.166 10.8341ZM30.8327 24.1674C29.6868 25.0007 27.6556 25.8341 25.4681 25.8341C22.3431 25.8341 20.1556 24.1674 17.0306 24.1674C14.791 24.1674 12.0306 24.6882 10.416 25.4174V13.3341C11.5098 12.5528 13.541 11.6674 15.7285 11.6674C18.8535 11.6674 21.041 13.3341 24.166 13.3341C26.4056 13.3341 29.166 12.4486 30.8327 11.6674V24.1674Z"
                    fill="#D80027"
                />
            </svg>
        </>
    );
}

export default ReportTelephoneButton;
