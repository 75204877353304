import React from "react";
import registerLeft from "../../assets/images/registerLeft.png";
import { Button, SIZE } from "../ui/buttons/Button";

function Network() {
    return (
        <div className="flex flex-col sm:flex-row justify-between items-center   relative gap-[39px] sm:p-0 w-full ">
            <div className="flex flex-col justify-start items-start flex-grow relative gap-5 w-[80vw] sm:w-[40%]  ">
                {/* w-[525.19px] */}
                <p className="font-[Inter] self-stretch   text-lg font-bold text-left text-[#7b7b7b]">
                    Ampliamos tu red
                </p>
                {/* w-[525.19px] */}
                <p className="font-[Inter] self-stretch text-[31px]   sm:text-[61px] font-bold text-left text-[#373737]">
                    <span className="relative">
                        Únete
                        <svg
                            width={250}
                            height={19}
                            viewBox="0 0 166 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute left-[-10%] top-[85%] w-[130px] sm:w-[250px]  "
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M164.5 11.441C148.5 1.27004 28.5 -5.55896 2 17.4411"
                                stroke="#D80027"
                                strokeWidth={3}
                                stroke-linecap="round"
                            />
                        </svg>
                    </span>
                    a nuestro equipo de profesionales
                </p>
                {/* w-[525.19px] */}
                <p className=" self-stretch   text-[21px] text-left text-[#7b7b7b]">
                    {/* w-[525.19px] */}
                    <span className="self-stretch   text-[21px] text-left text-[#7b7b7b]">
                        Si eres profesional del derecho, en Legal EPROF tenemos
                        un lugar para ti, únete a nuestro grupo de profesionales
                        y empieza a ofrecer tus servicios a través de nuestra
                        plataforma, esta es una excelente oportunidad de ampliar
                        tu cartera de clientes y ganar reputación mientras tu
                        carrera crece. Puedes contratar servicios de mensajería
                        y procuración dentro de la aplicación, selecciona tu
                        especialidad y ¡empieza hoy mismo.!
                    </span>
                    <br />
                </p>
                <Button
                    link={"/signup/professional"}
                    size={SIZE.LARGE}
                    // className="transition-all duration-500 cursor-pointer hover:opacity-80 flex justify-center items-center   text-lg text-left text-white  h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]"
                >
                    Regístrese ahora
                </Button>
            </div>
            <img
                src={registerLeft}
                alt="registerLeft"
                className="w-[70vw] sm:w-[30vw] object-contain "
            />
        </div>
    );
}

export default Network;
