import profileImg from "../../assets/images/landing/profile.png";
import phero from "../../assets/images/professionals/hero.png";

function Hero() {
    return (
        <div className="flex  items-center justify-between lg:justify-start px-[4%] lg:px-0 pt-[100px] lg:mb-[100px] flex-col-reverse lg:flex-row-reverse gap-10 lg:gap-2 ">
            {/* h-full */}
            <div className="absolute h-[80vh]  w-[35vw]  bg-[#ffecef] -z-10 hidden lg:block" />
            <img
                src={phero}
                alt="joven-mujer-feliz-estrechandole-mano-agente-bienes-raices-mientras-su-esposo-usa-panel-tactil-oficina-1.jpeg"
                className="w-full lg:w-[45vw] object-contain"
            />
            <div className=" flex flex-col justify-start items-start w-auto  lg:w-[30vw] lg:mr-[10vw]   relative gap-[67px]">
                <p className="font-[Satoshi] self-stretch  w-auto lg:w-[451px] text-5xl lg:text-6xl font-bold text-left text-[#373737]">
                    Directorio de{" "}
                    <span className="relative">
                        profesionales
                        <svg
                            width={380}
                            height={19}
                            viewBox="0 0 166 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute -right-2 top-[85%] w-[320px] lg:w-auto"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M164.5 11.441C148.5 1.27004 28.5 -5.55896 2 17.4411"
                                stroke="#D80027"
                                strokeWidth={3}
                                stroke-linecap="round"
                            />
                        </svg>
                    </span>{" "}
                </p>
                <div className="relative flex flex-col items-start self-stretch justify-start gap-5">
                    <div className="flex justify-center items-center  relative space-x-[-7.922572135925293px]">
                        <div className=" w-[60.94px] h-[60.94px] relative overflow-hidden rounded-[30.47px] border-[2.44px] border-[#a4a4a4]">
                            <img
                                src={profileImg}
                                alt="rectangle-1413.png"
                                className="w-[60.94px] h-[60.94px] absolute left-[-1.35px] top-[-1.35px] object-cover"
                            />
                        </div>
                        <div className=" w-[60.94px] h-[60.94px] relative overflow-hidden rounded-[30.47px] border-[2.44px] border-[#a4a4a4]">
                            <img
                                src={profileImg}
                                alt="rectangle-1413-2.png"
                                className="w-[60.94px] h-[60.94px] absolute left-[-1.35px] top-[-1.35px] object-cover"
                            />
                        </div>
                        <div className=" w-[60.94px] h-[60.94px] relative overflow-hidden rounded-[30.47px] border-[2.44px] border-[#a4a4a4]">
                            <img
                                src={profileImg}
                                alt="rectangle-1413-4.png"
                                className="w-[60.94px] h-[60.94px] absolute left-[-1.35px] top-[-1.35px] object-cover"
                            />
                        </div>
                        <div className=" w-[60.94px] h-[60.94px] relative overflow-hidden rounded-[30.47px] border-[2.44px] border-[#a4a4a4]">
                            <img
                                src={profileImg}
                                alt="rectangle-1413-3.png"
                                className="w-[60.94px] h-[60.94px] absolute left-[-1.35px] top-[-1.35px] object-cover"
                            />
                        </div>
                    </div>
                    <p className="self-stretch  w-auto lg:w-[451px] text-[21px] text-left text-[#7b7b7b]">
                        Ubica al profesional que se ajuste a tus requerimientos
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Hero;
