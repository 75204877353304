import React from "react";
import { twMerge } from "tailwind-merge";
import "./styles.css";

function Twitter({
    className = "",
    hover = true,

    width = 67,
    height = 67
}) {
    return (
        <div
            className={twMerge(
                `flex twShadow cursor-pointer justify-center items-center w-[76.74px] h-[76.74px] relative gap-[27.407394409179688px] p-[5.481479167938232px] rounded-[21.93px] ${
                    hover ? "bg-white" : "bg-[#defcff]"
                } hover:bg-[#defcff]`,
                className
            )}
            // style={{ boxShadow: "0px 16.44443702697754px 27.407394409179688px 0 rgba(21,87,255,0.25)" }}
        >
            <svg
                width={width}
                height={height}
                viewBox="0 0 67 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M51.09 24.4629C52.8029 23.1781 54.3446 21.6365 55.5437 19.8379C54.002 20.5231 52.2034 21.0369 50.4048 21.2082C52.289 20.0948 53.6594 18.3819 54.3446 16.2407C52.6316 17.2684 50.6617 18.0393 48.6918 18.4675C46.9788 16.6689 44.6663 15.6411 42.0969 15.6411C37.1293 15.6411 33.1038 19.6666 33.1038 24.6342C33.1038 25.3194 33.1895 26.0045 33.3608 26.6897C25.9094 26.2615 19.2289 22.6643 14.7751 17.2684C14.0043 18.5531 13.5761 20.0948 13.5761 21.8078C13.5761 24.8911 15.1177 27.6319 17.6015 29.2592C16.1455 29.1735 14.6895 28.8309 13.4904 28.1457V28.2314C13.4904 32.5994 16.5738 36.1967 20.6849 37.0531C19.9997 37.2244 19.1432 37.3957 18.3724 37.3957C17.7728 37.3957 17.2589 37.3101 16.6594 37.2244C17.7728 40.8217 21.1131 43.3911 25.0529 43.4768C21.9696 45.8749 18.1154 47.3309 13.9187 47.3309C13.1478 47.3309 12.4627 47.2453 11.7775 47.1596C15.7173 49.7291 20.4279 51.1851 25.5668 51.1851C42.0969 51.1851 51.09 37.567 51.09 25.6619C51.09 25.2337 51.09 24.8911 51.09 24.4629Z"
                    fill="#09B3FC"
                />
            </svg>
        </div>
    );
}

export default Twitter;
