import { useCallback, useEffect, useState } from "react";
import Input from "../ui/Input";
import { Button } from "../ui/buttons/Button";

const ReferredEmailItem = ({ email }) => (
    <div className="flex flex-col sm:flex-row justify-start items-start self-stretch   pb-px border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
        <div className="flex justify-start items-center self-stretch flex-grow relative gap-2.5 px-4 py-2.5">
            <p className="  text-sm lg:text-base text-left text-[#373737]">
                {email}
            </p>
        </div>
        <div className="flex justify-start items-center   lg:w-[188px] gap-2.5 px-4 py-2.5">
            <div className="flex justify-start items-center   relative gap-2.5 px-4 py-1 rounded-[100px] bg-[#ffecef]">
                <p className="  text-sm lg:text-base text-left text-[#373737]">
                    Bono pendiente
                </p>
            </div>
        </div>
    </div>
);

function ReferEmailList() {
    const [formData, setFormData] = useState({ email: "", creditDebit: 1 });
    const [filteredEmails, setFilteredEmails] = useState([]);
    const [allEmails] = useState([
        "john.doe@example.com",
        "jane.smith@example.com",
        "james.brown@example.com",
        "emily.johnson@example.com",
        "david.wilson@example.com",
        "sarah.anderson@example.com",
        "michael.thomas@example.com",
        "laura.jackson@example.com",
        "robert.miller@example.com",
        "olivia.davis@example.com"
    ]);
    const handleChange = useCallback(
        (target = {}) => {
            let { name, value } = target;
            setFormData((data) => ({ ...data, ...{ [name]: value } }));
            const filteredResults = allEmails.filter((email) =>
                email.includes(value.toLowerCase())
            );
            setFilteredEmails(filteredResults);
        },
        [allEmails]
    );
    useEffect(() => {
        setFilteredEmails(allEmails);
    }, [allEmails]);
    const handleFormSubmission = useCallback((e) => {
        e.preventDefault();
    }, []);
    return (
        <div className="relative flex flex-col items-center justify-start w-full gap-5 px-5 lg:w-auto lg:p-0">
            <p className="  lg:w-[936px] font-[Satoshi] text-2xl lg:text-5xl font-bold text-center text-[#373737]">
                Historial de referidos
            </p>
            <div className="flex flex-col items-start self-stretch justify-start ">
                <form
                    onSubmit={handleFormSubmission}
                    className="flex flex-col lg:flex-row justify-start items-start self-stretch   gap-6 px-4 py-5 border border-[#cdcdcd]"
                >
                    <Input
                        // label="Correo electrónico"
                        placeholder="Busca un referido por correo electrónico"
                        // required
                        onChange={(e) => handleChange(e.target)}
                        value={formData.email}
                        name="email"
                        type="email"
                    />
                    <Button
                        type="submit"
                        text="Agregar a un amigo"
                        iconName={"plus"}
                        // stretch
                    />
                    {/* <button
                        type="submit"
                        className="flex justify-center items-center flex-shrink-0  self-stretch w-full lg:max-w-[300px] text-left text-white  text-md lg:text-lg   h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]"
                    >
                        <span className="flex-grow-0 flex-shrink-0 ">
                            Agregar a un amigo
                        </span>
                        <svg
                            width={31}
                            height={30}
                            viewBox="0 0 31 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-  w-[30px] h-[30px] relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M22.375 13.75H16.75V8.125C16.75 7.8125 16.4375 7.5 16.125 7.5H14.875C14.5234 7.5 14.25 7.8125 14.25 8.125V13.75H8.625C8.27344 13.75 8 14.0625 8 14.375V15.625C8 15.9766 8.27344 16.25 8.625 16.25H14.25V21.875C14.25 22.2266 14.5234 22.5 14.875 22.5H16.125C16.4375 22.5 16.75 22.2266 16.75 21.875V16.25H22.375C22.6875 16.25 23 15.9766 23 15.625V14.375C23 14.0625 22.6875 13.75 22.375 13.75Z"
                                fill="#F2F2F2"
                            />
                        </svg>
                    </button> */}
                </form>
                <div className="flex justify-start items-start self-stretch   border border-[#cdcdcd]">
                    <div className="flex justify-start items-center flex-grow relative gap-2.5 px-4 py-2.5 bg-[#f2f2f2]">
                        <p className="  text-sm lg:text-base font-bold text-left text-[#373737]">
                            Dirección de email
                        </p>
                    </div>
                    <div className="hidden sm:flex  justify-start items-center   lg:w-[206px] relative gap-2.5 px-4 py-2.5 bg-[#f2f2f2]">
                        <p className="  text-sm lg:text-base font-bold text-left text-[#373737]">
                            Estado
                        </p>
                    </div>
                </div>
                <div className="flex justify-start max-h-[500px] overflow-y-auto items-start self-stretch   gap-[5px] pr-[5px] border border-[#cdcdcd]">
                    <div className="flex flex-col items-start justify-start flex-grow">
                        {filteredEmails.map((email) => (
                            <ReferredEmailItem key={email} email={email} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReferEmailList;
