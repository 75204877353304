import { Link } from "react-router-dom";
import imgHero from "../../assets/images/img-landing.png";

function Hero() {
    return (
        <div className="flex flex-col   justify-start items-start  pl-[7%] mt-[15vh]  ">
            {/* <div className="absolute top-0 left-[10%] flex justify-start items-center   overflow-hidden gap-2"> */}
            <Link to={"/blog"}>
                <div className="flex my-5 cursor-pointer opacity-80 sm:my-0 ">
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6 mr-3"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M9.57 5.92999L3.5 12L9.57 18.07"
                            stroke="#292929"
                            strokeWidth="1.5"
                            stroke-miterlimit={10}
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M20.4999 12H3.66992"
                            stroke="#292929"
                            strokeWidth="1.5"
                            stroke-miterlimit={10}
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <p className=" text-base text-left text-[#d80027]">
                        {" "}
                        Volver
                    </p>
                </div>
            </Link>

            <div className="relative flex-col sm:flex-row flex items-center justify-between w-full sm:mb-[100px] gap-10 sm:gap-0   ">
                <div className="flex sm:flex-[0.35] flex-col justify-start     relative    gap-5 ">
                    {/* w-[456px] */}
                    <p className="  text-lg font-bold text-left text-[#7b7b7b]">
                        Lorem ipsum
                    </p>
                    {/* w-[456px] */}
                    <p className="font-[Satoshi]  text-4xl sm:text-6xl font-bold text-left text-[#373737]">
                        Lorem ipsum dolor sit amet, consectetur elit.
                    </p>
                    {/* w-[456px] */}
                    <p className="  text-md sm:text-xl text-left text-[#7b7b7b]">
                        Encuentre artículos interesantes y noticias actualizadas
                        todos los días, redactadas por profesionales del derecho
                        y comunicadores calificados
                    </p>
                </div>
                <div className="flex sm:hidden     flex-col justify-start h-[50vh] w-full    relative  ">
                    <div className="absolute right-0   h-full top-[2%]  w-full  bg-[#ffecef]" />
                    <img
                        src={imgHero}
                        alt="joven-mujer-feliz-estrechandole-mano-agente-bienes-raices-mientras-su-esposo-usa-panel-tactil-oficina-1.jpeg"
                        className="absolute top-[15%]  right-[0%]    w-[90%] object-contain "
                    />
                </div>

                <div className="hidden sm:flex   flex-[0.65] flex-col justify-start h-[80vh]    relative  ">
                    <div className="absolute right-0   h-full top-[2%]  w-[35vw]  bg-[#ffecef]" />
                    <img
                        src={imgHero}
                        alt="joven-mujer-feliz-estrechandole-mano-agente-bienes-raices-mientras-su-esposo-usa-panel-tactil-oficina-1.jpeg"
                        className="absolute top-[15%]  right-[0%] max-h-[60vh] w-full sm:w-[50vw] object-cover mr-[7%]"
                    />
                </div>
            </div>
        </div>
    );
}

export default Hero;
