import React, { useState } from "react";

function FAQItem({
    question = `¿De qué se trata el programa de Invitar
    y ganar?`,
    answer = `  Lorem ipsum dolor sit amet consectetur. Non
    at leo nunc in id aliquam facilisi sed.
    Turpis quis id sodales magnis mattis
    sagittis rutrum. Est eros tortor in integer
    aliquet aliquet. Sit tellus tristique
    vivamus lectus sit turpis nisl venenatis
    urna. Accumsan vitae tortor nulla elit sit
    gravida. Nec tincidunt imperdiet bibendum
    malesuada augue. In at habitant enim
    praesent. Pellentesque egestas eget
    pellentesque nisi nisl platea.

    Lorem ipsum dolor sit amet consectetur. Non
    at leo nunc in id aliquam facilisi sed.
    Turpis quis id sodales magnis mattis
    sagittis rutrum. Est eros tortor in integer
    aliquet aliquet. Sit tellus tristique
    vivamus lectus sit turpis nisl venenatis
    urna. Accumsan vitae tortor nulla elit sit
    gravida. Nec tincidunt imperdiet bibendum
    malesuada augue. In at habitant enim
    praesent. Pellentesque egestas eget
    pellentesque nisi nisl platea.
    `,
    expandedProp = false
}) {
    const [expanded, setExpanded] = useState(expandedProp);
    return (
        <div
            onClick={() => setExpanded((e) => !e)}
            className="flex flex-col items-start self-stretch justify-center cursor-pointer hover:opacity-90 "
        >
            <div
                className={`flex justify-start items-center self-stretch   relative gap-2.5 px-2.5 py-4 ${
                    expanded && "bg-[#f2f2f2]"
                } border-t-0 border-r-0 border-b-[0.98px] border-l-0 border-[#cdcdcd]`}
            >
                <p className="flex-grow lg:w-[882.45px] text-md lg:text-lg font-bold text-left text-[#373737]">
                    {question}
                </p>
                <svg
                    width={25}
                    height={25}
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${
                        !expanded && "rotate-180"
                    }  lg:w-[23.55px] h-[23.55px] relative`}
                    preserveAspectRatio="none"
                >
                    <path
                        d="M20.4961 15.4932L14.0984 9.09541C13.3428 8.33985 12.1064 8.33985 11.3509 9.09541L4.95312 15.4932"
                        stroke="#A4A4A4"
                        strokeWidth="1.47187"
                        stroke-miterlimit={10}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
            {expanded && (
                <div className="flex flex-col justify-start items-start self-stretch   overflow-hidden gap-[68.6875px] px-2.5 py-4 border-t-0 border-r-0 border-b-[0.98px] border-l-0 border-[#cdcdcd]">
                    <span className="self-stretch max-h-[150px] overflow-y-auto  lg:w-[898.34px] text-sm lg:text-base text-left text-[#373737]">
                        {answer}
                    </span>
                </div>
            )}
        </div>
    );
}

export default FAQItem;
