import React from "react";
import { twMerge } from "tailwind-merge";
import "./styles.css";

function Linkedin({
    className = "",
    hover = true,

    width = 66,
    height = 67
}) {
    return (
        <div
            className={twMerge(
                `flex liShadow cursor-pointer justify-center items-center w-[76.74px] h-[76.74px] relative gap-[27.407394409179688px] p-[5.481479167938232px] rounded-[21.93px] ${
                    hover ? "bg-white" : "bg-[#d4e5ff]"
                } hover:bg-[#d4e5ff]`,
                className
            )}
            // style={{ boxShadow: "0px 16.44443702697754px 27.407394409179688px 0 rgba(21,87,255,0.25)" }}
        >
            <svg
                width={width}
                height={height}
                viewBox="0 0 66 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M22.3799 49.8147V24.2059H14.4146V49.8147H22.3799ZM18.3544 20.78C20.9239 20.78 22.9794 18.6388 22.9794 16.0694C22.9794 13.5856 20.9239 11.53 18.3544 11.53C15.8706 11.53 13.8151 13.5856 13.8151 16.0694C13.8151 18.6388 15.8706 20.78 18.3544 20.78ZM52.0998 49.8147H52.1854V35.7684C52.1854 28.9166 50.6438 23.6064 42.5928 23.6064C38.7387 23.6064 36.1692 25.7476 35.0558 27.7175H34.9701V24.2059H27.3475V49.8147H35.3127V37.1388C35.3127 33.7985 35.9123 30.6296 40.0234 30.6296C44.1345 30.6296 44.2201 34.3981 44.2201 37.3958V49.8147H52.0998Z"
                    fill="#2087FF"
                />
            </svg>
        </div>
    );
}

export default Linkedin;
