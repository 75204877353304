import React, { useState } from "react";
import { Button, COLOR } from "../../ui/buttons/Button";
import TickDialog from "../../ui/dialogs/TickDialog";
import Dialog from "../../Dialog";
import Checkbox from "../../ui/Checkbox";

const data = [
    {
      "text": "Calidad del trabajo",
      "rating": 3
    },
    {
      "text": "Comunicación",
      "rating": 3
    },
    {
      "text": "Experticia",
      "rating": 3
    },
    {
      "text": "Profesionalismo",
      "rating": 3
    },
    {
      "text": "¿Qué probabilidades hay de que vuelvas a contratar?",
      "rating": 3
    }
]

export const StarSvg = ({colored, onClick}) => {
    return (
        <svg
            width={20}
            height={31}
            viewBox="0 0 20 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-grow-0 flex-shrink-0 w-5 h-[31px] relative cursor-pointer"
            preserveAspectRatio="none"
            onClick={onClick}
        >
            <path
                d="M19.287 12.697L13.3362 11.8321L10.6761 6.43917C10.6034 6.29151 10.4839 6.17198 10.3362 6.09933C9.9659 5.91651 9.5159 6.06886 9.33074 6.43917L6.67059 11.8321L0.719807 12.697C0.555745 12.7204 0.405745 12.7978 0.290901 12.9149C0.152061 13.0577 0.0755541 13.2496 0.078191 13.4487C0.0808278 13.6478 0.162393 13.8377 0.304963 13.9767L4.61043 18.1743L3.59324 24.1017C3.56939 24.2396 3.58465 24.3814 3.63729 24.511C3.68993 24.6407 3.77784 24.753 3.89106 24.8352C4.00428 24.9174 4.13828 24.9663 4.27785 24.9763C4.41743 24.9862 4.557 24.9568 4.68074 24.8915L10.0034 22.0931L15.3261 24.8915C15.4714 24.9689 15.6401 24.9946 15.8018 24.9665C16.2097 24.8962 16.4839 24.5095 16.4136 24.1017L15.3964 18.1743L19.7018 13.9767C19.819 13.8618 19.8964 13.7118 19.9198 13.5478C19.9831 13.1376 19.6972 12.7579 19.287 12.697Z"
                fill={colored ? "#D80027" : "#F0F0F0"}
            />
        </svg>
    )
}

function ReviewPage() {
    const [showDialog, setDialog] = useState(false);
    const [onTime, setOnTime] = useState(false);
    const [inBudget, setInBudget] = useState(false);
    const [ratings, setRatings] = useState(data.map(() => 0));

    const handleRatingChange = (index, rating) => {
        console.log(index, rating)
        const newRatings = [...ratings];
        newRatings[index] = rating;
        setRatings(newRatings);
    };


    return (
        <>
            <Dialog
                main={<TickDialog />}
                showDialog={showDialog}
                setDialog={setDialog}
            />
            <div className="flex overflow-hidden gap-2 items-center">
                <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M9.57 5.93018L3.5 12.0002L9.57 18.0702"
                        stroke="#292929"
                        strokeWidth="1.5"
                        stroke-miterlimit={10}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M20.5019 12H3.67188"
                        stroke="#292929"
                        strokeWidth="1.5"
                        stroke-miterlimit={10}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <p className="text-base text-left text-[#d80027]">
                    Pagos
                </p>
                <p className="text-base text-left text-[#d80027]">
                    /
                </p>
                <p className="text-base text-left text-[#d80027]">
                    Buscando profesionales
                </p>
            </div>
            <div className="space-y-[30px] ">
                <div className="space-y-[5px]">
                    <p className="text-[21px] font-bold text-left text-[#373737]">
                        Calificar
                    </p>
                    <p className="text-base text-left text-[#373737]">
                        Por favor indicanos que te parecio el servicio, califica
                        al profesional
                    </p>
                </div>
                <div className="space-y-3">
                    <Checkbox checked={onTime} toggleCheck={() => setOnTime(x => !x)} text="¿Entregado a tiempo?"/>
                    <Checkbox checked={inBudget} toggleCheck={() => setInBudget(x => !x)} text="¿Entregado dentro del presupuesto?"/>
                </div>
                <div className="space-y-3">
                    <p className="text-[21px] font-bold text-left text-[#373737]">
                        Tasa de efectividad
                    </p>
                    <div className="space-y-4">

                        {
                            data.map((item, index) => (

                                <div key={index} className="flex items-center pb-4 border-t-0 border-r-0 border-b border-l-0 border-[#e6e6e6]">
                                    <p className="flex-grow text-base text-left text-[#373737] ">{item.text}</p>
                                    <div className="flex  gap-6">
                                        <div className="flex relative flex-grow-0 flex-shrink-0 gap-2 justify-start items-center">
                                            {[...Array(5)].map((_, i) => (
                                                <StarSvg key={i} colored={i < ratings[index]} onClick={() => handleRatingChange(index, i + 1)} />
                                            ))}
                                        </div>
                                        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[73px] relative gap-2.5 px-2.5 py-1 rounded-[100px] bg-[#292929]">
                                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-white">{ratings[index]}.0</p>
                                        </div>
                                    </div>
                                </div>

                            ))
                        }
                        
                    </div>
                </div>
            </div>
            <div className="flex justify-end w-full">
                <Button
                    iconName={"arrow"}
                    color={COLOR.WHITE}
                    onClick={() => setDialog(true)}
                >
                    Enviar y finalizar
                </Button>
            </div>
        </>
    );
}

export default ReviewPage;

