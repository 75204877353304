import React from "react";

function ModelTitleCoupon() {
    return (
        <svg
            width={50}
            height={50}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className=" xl:w-[50px] h-[50px] relative"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M43.2292 22.3958H43.75V15.625C43.75 13.9323 42.3177 12.5 40.625 12.5H9.375C7.61719 12.5 6.25 13.9323 6.25 15.625V22.3958H6.77083C8.20312 22.3958 9.375 23.5677 9.375 25C9.375 26.4974 8.20312 27.6042 6.77083 27.6042H6.25V34.375C6.25 36.1328 7.61719 37.5 9.375 37.5H40.625C42.3177 37.5 43.75 36.1328 43.75 34.375V27.6042H43.2292C41.7318 27.6042 40.625 26.4974 40.625 25C40.625 23.5677 41.7318 22.3958 43.2292 22.3958ZM40.625 19.9219C38.737 20.8984 37.5 22.7865 37.5 25C37.5 27.2786 38.737 29.1667 40.625 30.1432V34.375H9.375V30.1432C11.1979 29.1667 12.5 27.2786 12.5 25C12.5 22.7865 11.1979 20.8984 9.375 19.9219V15.625H40.625V19.9219Z"
                fill="#333333"
            />
        </svg>
    );
}

export default ModelTitleCoupon;
