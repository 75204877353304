import { useState, useEffect } from "react";
import Input from "../ui/Input";
import { Button } from "../ui/buttons/Button";
import { useFormik } from "formik";
import apiurl from "../ApiConfig";
import "../../pages/css/alerts.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/auth";
function LoginStep1({ navigateNext }) {
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [inputType, setInputType] = useState("password");
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const setProfileDescription = useAuthStore(
    (state) => state.setProfileDescription
  );

  useEffect(() => {
    const savedUsername = localStorage.getItem("username");
    const savedPassword = localStorage.getItem("password");

    if (savedUsername && savedPassword) {
      formik.setValues({ username: savedUsername, password: savedPassword });
      setRememberMe(true);
    }
    // eslint-disable-next-line
  }, []);
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      Swal.fire({
        title: "Iniciando sesion...",
        text: "Por favor, espere mientras se loguea.",
        allowOutsideClick: false,
                    showConfirmButton: false,
                    didOpen: () => {
                      Swal.showLoading();
                    }
                  }); 
      try {
        const response = await fetch(`${apiurl}/o/token/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            grant_type: "password",
            username: values.username,
            password: values.password,
            client_id: "P65wI6oAFgp2XGLcyCPr5u3SAMR4Guec4U2aBP23",
            client_secret:
              "uxzebMbeWeO8CIoJ3Uc7HmMGsFG8CMAXM1PamidXPXfF6cgAr9MfZgZgkszZQdW8gq2ZL5LVzSCElLLfrP8Jtl8nx6FTm2Am7rmfDaUGxfFd5slEEqy2QVwFgAcPGb7y",
          }),
        });
        if (!response.ok) {
          throw new Error("identificación o clave invalida");
        }
        const data = await response.json();
        console.log("Token:", data.access_token);
        localStorage.setItem("authToken", data.access_token);
        setError(null);
        if (rememberMe) {
          localStorage.setItem("username", values.username);
          localStorage.setItem("password", values.password);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
        }
        const profileResponse = await fetch(`${apiurl}/User/Profile`, {
          headers: {
            Authorization: `Bearer ${data.access_token}`,
          },
        });
        if (!profileResponse.ok) {
          throw new Error("Error al obtener el perfil del usuario");
        }

        const profileData = await profileResponse.json();
        if (profileData.description === null) {
          setProfileDescription("client");
          navigate("/platform/board");
        } else {
          setProfileDescription("professional");
          navigate("/platform/professional-dashboard");
        }

        await Swal.fire({
          title: "Inicio de sesión exitoso",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      } catch (error) {
        await Swal.fire({
          title: "Error de inicio de sesión",
          icon: "error",
          text: error.message,
          timer: 2000,
          showConfirmButton: false,
        });
      }
    },
  });
  return (
    <div
      className="flex flex-col justify-start items-start mt-20 mx-2 xl:m-0 xl:w-[28vw] gap-8 p-[30px] rounded-[20px] bg-white"
      style={{ boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)" }}
    >
      <div className="flex flex-col justify-start items-start self-stretch  relative gap-2.5">
        <p className="flex-grow  text-[34px] text-left text-[#373737]">
          Bienvenido
        </p>
        <p className="self-stretch   text-base text-left text-[#7B7B7B]">
          Ingrese sus credenciales para acceder a su cuenta
        </p>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-5 justify-start items-end self-stretch"
      >
        <div className="flex justify-start items-center self-stretch  relative gap-[5px]">
          <div className="flex-grow h-px bg-[#CDCDCD]" />
          <p className=" text-base text-left text-[#373737]">O</p>
          <div className="flex-grow h-px bg-[#CDCDCD]" />
        </div>
        <Input
          label="Ingrese su Identificación"
          placeholder="DPI"
          required
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, "");
            formik.handleChange({
              target: {
                name: "username",
                value,
              },
            });
          }}
          value={formik.values.username}
          name="username"
          type="text"
          onBlur={formik.handleBlur}
        />
        {formik.errors.correo && formik.touched.correo && (
          <p className="text-red-500">{formik.errors.correo}</p>
        )}
        <Input
          label="Contraseña"
          placeholder="Contraseña"
          required
          onChange={formik.handleChange}
          value={formik.values.password}
          name="password"
          onBlur={formik.handleBlur}
          type={inputType}
          onIconClick={() =>
            setInputType((type) => (type === "password" ? "text" : "password"))
          }
          icon={
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative w-6 h-6"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                fill="#A4A4A4"
              />
            </svg>
          }
        />
        {formik.errors.password && formik.touched.password && (
          <p className="text-red-500">{formik.errors.password}</p>
        )}
        <div className="flex justify-start items-center self-stretch  relative gap-[5px]">
          <label>
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
          </label>
          <p className=" text-base text-left text-[#292929]">Recordar</p>
        </div>
        <div className="flex relative flex-col gap-5 justify-start items-start self-stretch">
          <Button
            // size={SIZE.LARGE}
            stretch
            type="submit"
            addClass="self-stretch text-base   h-11 gap-2.5 px-8 py-2.5 "
          >
            Iniciar sesión
          </Button>
          <button
            onClick={navigateNext}
            className="self-stretch   text-base text-center text-[#D80027]"
          >
            Olvidé mi contraseña
          </button>
        </div>
        <div className="flex justify-start items-center self-stretch  relative gap-[5px]">
          <div className="flex-grow h-px bg-[#CDCDCD]" />
          <p className=" text-base text-left text-[#373737]">o</p>
          <div className="flex-grow h-px bg-[#CDCDCD]" />
        </div>
        <div className="flex flex-col justify-start items-center self-stretch  relative gap-2.5">
          <p className=" text-base text-left text-[#373737]">
            ¿Aún no tienes una cuenta?
          </p>
          <a href="/signup" className=" text-base text-left text-[#D80027]">
            Crea una aquí
          </a>
        </div>
      </form>
    </div>
  );
}
export default LoginStep1;
