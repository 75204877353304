import React from "react";
import { Button, COLOR, SIZE } from "../../ui/buttons/Button";

const TimerModal = () => {
    return (
        <div>
            <div
                class="flex flex-col justify-start items-center w-[594px] gap-[30px] p-[30px] bg-white"
                style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
            >
                <div class="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2.5 p-2.5 rounded-[100px] bg-[#ffd80c]">
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M19.9987 7.08203C12.8633 7.08203 7.08203 12.9154 7.08203 19.9987C7.08203 27.1341 12.8633 32.9154 19.9987 32.9154C27.082 32.9154 32.9154 27.1341 32.9154 19.9987C32.9154 12.9154 27.082 7.08203 19.9987 7.08203ZM19.9987 30.4154C14.2174 30.4154 9.58203 25.7799 9.58203 19.9987C9.58203 14.2695 14.2174 9.58203 19.9987 9.58203C25.7279 9.58203 30.4154 14.2695 30.4154 19.9987C30.4154 25.7799 25.7279 30.4154 19.9987 30.4154ZM22.1862 24.9987C22.1862 23.8008 21.1966 22.8112 19.9987 22.8112C18.7487 22.8112 17.8112 23.8008 17.8112 24.9987C17.8112 26.2487 18.7487 27.1862 19.9987 27.1862C21.1966 27.1862 22.1862 26.2487 22.1862 24.9987ZM17.9154 14.0091L18.2799 21.0924C18.2799 21.4049 18.5924 21.6654 18.9049 21.6654H21.0404C21.3529 21.6654 21.6654 21.4049 21.6654 21.0924L22.0299 14.0091C22.0299 13.6445 21.7695 13.332 21.4049 13.332H18.5404C18.1758 13.332 17.9154 13.6445 17.9154 14.0091Z"
                            fill="#333333"
                        ></path>
                    </svg>
                </div>
                <div class="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]">
                    <p class="self-stretch flex-grow-0 flex-shrink-0 w-[534px] text-2xl font-bold text-center text-[#373737]">
                        Su examen ha expirado. Ahora serás dirigido a la página
                        de resultados.
                    </p>
                </div>
                <div class="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 gap-5">
                    
                    <Button
                            color={COLOR.RED}
                            size={SIZE.MEDIUM}
                            type="submit"
                            // onClick={""}
                            text="continuar"
                            addClass={"h-11 text-base sm:text-base md:text-base lg:text-base"}
                        />
                </div>
            </div>
        </div>
    );
};

export default TimerModal;
