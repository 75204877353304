import React, { useState } from "react";
import Dialog from "../components/Dialog";
import Socials from "../components/Socials";
import SuccessDialog from "../components/dialogs/SuccessDialog";
import Checkbox from "../components/ui/Checkbox";
import Input from "../components/ui/Input";
import contact from "../assets/images/contact/contact.png";
import { phoneSvg } from "../utils/icons";
import { Button, COLOR, SIZE } from "../components/ui/buttons/Button";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
    firstName: Yup.string()
        .required("El nombre es obligatorio")
        .matches(/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/, "Solo se permiten letras en el nombre"),
    lastName: Yup.string()
        .required("El apellido es obligatorio")
        .matches(/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/, "Solo se permiten letras en el apellido"),
    email: Yup.string()
        .email("Formato de correo electrónico inválido")
        .required("Requerido"),
    phone: Yup.number()
        .typeError("Por favor ingresa un número válido")
        .max(999999999999, "Máximo 12 dígitos")
        .min(10000000, "Mínimo 8 dígitos")
        .required("Requerido"),
    consulta: Yup.string().required("Requerido")
});


function Contact({ email = "lic.lopezalvarado@gmail.com" }) {
    // showDialog
    const [showDialog, setDialog] = useState(0);
    const [formData, setFormData] = useState({});
    const [acceptAgreement, setAgreement] = useState(false);
    console.log(formData);
    // const handleChange = useCallback(
    //     (target = {}) => {
    //         let { name, value } = target;
    //         console.log(target);
    //         setFormData((data) => ({ ...data, ...{ [name]: value } }));
    //         console.log(formData);
    //     },
    //     [formData]
    // );

    // const handleFormSubmission = useCallback((e) => {
    // submit formData
    // e.preventDefault();
    // setDialog(1);
    // const url = "http://localhost:3000/uploadFile";
    // const config = {
    //     headers: {
    //         "content-type": "multipart/form-data"
    //     }
    // };
    // axios
    //     .post(url, formData, config)
    //     .then((response) => {
    //         console.log(response.data);
    //         setUploadedFile(response.data.file);
    //     })
    //     .catch((error) => {
    //         console.error("Error uploading file: ", error);
    //         setError(error);
    //     });
    // }, []);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            consulta: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setFormData((data) => ({ ...data, ...values }));
            setDialog(1);
        }
    });

    return (
        <>
            <Dialog
                showDialog={showDialog}
                setDialog={setDialog}
                main={<SuccessDialog setDialog={setDialog} />}
            />

            <div className="mx-[7%] mt-[15vh] mb-[100px] flex justify-between flex-col sm:flex-row gap-[100px]  sm:gap-2  ">
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-[0.5]  flex-col justify-start items-start  gap-5"
                >
                    <div className="relative flex flex-col items-start justify-start gap-5">
                        <p className="font-[Satoshi]   text-[61px] relative font-bold text-left text-[#373737]">
                            Contacto
                            <svg
                                width={241}
                                height={18}
                                viewBox="0 0 231 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute bottom-0 right-3"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M229 8.96874C176.256 1.39734 19.8469 -4.3726 2 16"
                                    stroke="#D80027"
                                    strokeWidth={3}
                                    stroke-linecap="round"
                                />
                            </svg>
                        </p>
                        <p className="   text-md sm:text-xl text-left text-[#7b7b7b]">
                            Ponte en contacto con nosotros a traves de nuestros
                            diferentes canales de atencion
                        </p>
                    </div>
                    <div className="flex flex-col justify-start items-start self-stretch  gap-2.5">
                        <div className="flex flex-col sm:flex-row justify-start items-start self-stretch  h-fit gap-[21px]">
                            <div className="flex flex-col flex-grow">
                                <Input
                                    label="Nombre"
                                    placeholder="Nombre"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                    name="firstName"
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.firstName &&
                                formik.errors.firstName ? (
                                    <div className="text-red-600">
                                        {formik.errors.firstName}
                                    </div>
                                ) : null}
                            </div>
                            <div className="flex flex-col flex-grow">
                                <Input
                                    label="Apellido"
                                    placeholder="Apellido"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                    name="lastName"
                                />
                                {formik.touched.lastName &&
                                formik.errors.lastName ? (
                                    <div className="text-red-600">
                                        {formik.errors.lastName}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <Input
                            label="Correo electrónico"
                            placeholder="Correo electrónico"
                            required
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            name="email"
                            type="email"
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-red-600">
                                {formik.errors.email}
                            </div>
                        ) : null}
                        <Input
                            label="Teléfono"
                            placeholder="Teléfono"
                            required
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            name="phone"
                            type="number"
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-red-600">
                                {formik.errors.phone}
                            </div>
                        ) : null}

                        <Input
                            label="Consulta"
                            required
                            onChange={formik.handleChange}
                            value={formik.values.consulta}
                            name="consulta"
                            type="long"
                        />
                        {formik.touched.consulta && formik.errors.consulta ? (
                            <div className="text-red-600">
                                {formik.errors.consulta}
                            </div>
                        ) : null}

                        <div className="flex flex-col justify-start items-start self-stretch  relative gap-2.5">
                            {/* w-[577px] */}
                            <p className="self-stretch   text-sm font-bold text-left text-[#373737]">
                                Tus datos estan seguros con nosotros
                            </p>{" "}
                        </div>
                        <div className="flex justify-start items-center   relative gap-[5px]">
                            <Checkbox
                                checked={acceptAgreement}
                                toggleCheck={() =>
                                    setAgreement((prev) => !prev)
                                }
                                // text=""
                                customElm={
                                    <p className="self-stretch flex-grow text-base text-left">
                                        <span className="self-stretch flex-grow text-sm text-left text-[#373737]">
                                            Acepto que mis datos sean
                                            recopilados de acuerdo con la{" "}
                                        </span>
                                        <a
                                            href="/policy"
                                            className=" text-sm text-left text-[#d80027]"
                                        >
                                            política de privacidad*
                                        </a>
                                    </p>
                                }
                            />
                        </div>

                        <Button
                            type="submit"
                            size={SIZE.LARGE}
                            color={COLOR.BLACK}
                            stretch
                            // className="cursor-pointer hover:opacity-80 flex justify-center items-center text-lg text-left text-white self-stretch  h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#292929]"
                        >
                            Enviar consulta
                        </Button>
                    </div>
                </form>
                <div className="flex flex-[0.5]   flex-col justify-start items-center sm:items-end  relative gap-[17px] sm:ml-10">
                    <img
                        src={contact}
                        alt="hombre-revisando-algunos-documentos-importantes-copia.png"
                        className="w-full sm:w-[40vw] object-contain mt-5"
                    />
                    <div className="relative flex flex-col items-start justify-start w-auto gap-4">
                        <p className="self-stretch  w-auto text-md sm:text-xl text-left text-[#7b7b7b]">
                            En eProf nos preocupamos por atender tus
                            solicitudes, para ello tenemos los siguientes
                            canales disponibles
                        </p>
                        <div className="flex flex-col items-start justify-start ">
                            <a
                                href={`mailto:${email}`}
                                className="flex justify-end items-center  relative gap-1.5 pr-2 py-2"
                            >
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6 "
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M18.5 6H5.5C4.65625 6 4 6.6875 4 7.5V16.5C4 17.3438 4.65625 18 5.5 18H18.5C19.3125 18 20 17.3438 20 16.5V7.5C20 6.6875 19.3125 6 18.5 6ZM18.5 7.5V8.78125C17.7812 9.375 16.6562 10.25 14.2812 12.125C13.75 12.5312 12.7188 13.5312 12 13.5C11.25 13.5312 10.2188 12.5312 9.6875 12.125C7.3125 10.25 6.1875 9.375 5.5 8.78125V7.5H18.5ZM5.5 16.5V10.7188C6.1875 11.2812 7.21875 12.0938 8.75 13.3125C9.4375 13.8438 10.6562 15.0312 12 15C13.3125 15.0312 14.5 13.8438 15.2188 13.3125C16.75 12.0938 17.7812 11.2812 18.5 10.7188V16.5H5.5Z"
                                        fill="#D80027"
                                    />
                                </svg>
                                <p className=" text-base font-medium text-left text-[#373737]">
                                    {email}
                                </p>
                            </a>
                            <a
                                href={`tel:${"+50251119923"}`}
                                className="flex justify-start items-center self-stretch  relative gap-1.5 pr-2 py-2"
                            >
                                <div className="relative w-6 h-6 overflow-hidden ">
                                    <img
                                        src={phoneSvg}
                                        alt="phoneSvg"
                                        className=""
                                    />
                                </div>
                                <p className=" text-base font-medium text-left text-[#373737]">
                                    +502 5697-1163
                                </p>
                            </a>
                        </div>
                        <Socials
                            color={"#292929"}
                            className="gap-7 sm:gap-[7px]"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;
