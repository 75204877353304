import React from 'react'

const PreviousWork = () => {
  return (
    <div>
      Previous Work
    </div>
  )
}

export default PreviousWork
