import React, { useState } from "react";
// import { noop } from "../../utils/noop";

function Expand({
    expandedProp = true,
    // toggleExpand = noop,
    content = null,
    label = "",
    labelClass = undefined,
    customClass = undefined
}) {
    const [expanded, setExpanded] = useState(expandedProp);
    return (
        <div
            className={
                customClass ??
                " flex flex-col justify-start items-start  gap-2.5 w-full"
            }
        >
            {/* gap-[57px] lg:w-[336px] */}
            <div
                onClick={() => setExpanded((e) => !e)}
                className="relative flex items-center justify-start w-full cursor-pointer hover:opacity-90"
            >
                <p
                    className={
                        labelClass ??
                        " flex-grow w-full lg:w-[249px] text-lg font-bold text-left text-[#373737]"
                    }
                >
                    {label}
                </p>
                <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={` ${
                        !expanded && "rotate-180"
                    }  w-[30px] h-[30px] relative`}
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M6.48438 19.0625L7.22656 19.8438C7.42188 20 7.73438 20 7.89062 19.8438L15 12.7734L22.0703 19.8438C22.2266 20 22.5391 20 22.7344 19.8438L23.4766 19.0625C23.6719 18.8672 23.6719 18.5938 23.4766 18.3984L15.3125 10.1953C15.1172 10.0391 14.8438 10.0391 14.6484 10.1953L6.48438 18.3984C6.28906 18.5938 6.28906 18.8672 6.48438 19.0625Z"
                        fill="#A4A4A4"
                    />
                </svg>
            </div>
            {expanded ? content : null}
        </div>
    );
}

export default Expand;
