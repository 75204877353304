import React from "react";

function FormFooter() {
    return (
        <>
            <div className="flex justify-start items-center self-stretch flex-grow-0  relative gap-[5px]">
                <div className="flex-grow h-px bg-[#cdcdcd]" />
                <p className="flex-grow-0  text-base text-left text-[#373737]">
                    O
                </p>
                <div className="flex-grow h-px bg-[#cdcdcd]" />
            </div>
            <div className="flex flex-col justify-start items-center self-stretch flex-grow-0  relative gap-2.5">
                <p className="flex-grow-0  text-base text-left text-[#373737]">
                    Ya tienes una cuenta?
                </p>
                <a
                    href="/login"
                    className="flex-grow-0  text-base text-left text-[#d80027]"
                >
                    Iniciar sesión
                </a>
            </div>
        </>
    );
}

export default FormFooter;
