import React from "react";
import error4044 from "../../assets/images/404/4044.png";
import { Link } from 'react-router-dom';
 import { Button, SIZE } from "../../components/ui/buttons/Button";

/*const VerifiedLabel = ({ text = "VERIFICADO" }) => {
    return (
        <div className="flex relative gap-1 justify-start items-center">
            <svg
                width={24}
                height={25}
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative w-6 h-6"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M12 4.75C7.71875 4.75 4.25 8.25 4.25 12.5C4.25 16.7812 7.71875 20.25 12 20.25C16.25 20.25 19.75 16.7812 19.75 12.5C19.75 8.25 16.25 4.75 12 4.75ZM12 6.25C15.4375 6.25 18.25 9.0625 18.25 12.5C18.25 15.9688 15.4375 18.75 12 18.75C8.53125 18.75 5.75 15.9688 5.75 12.5C5.75 9.0625 8.53125 6.25 12 6.25ZM16.375 10.3438L15.6562 9.625C15.5312 9.46875 15.2812 9.46875 15.125 9.625L10.7188 14L8.84375 12.125C8.6875 11.9688 8.46875 11.9688 8.3125 12.125L7.59375 12.8125C7.46875 12.9688 7.46875 13.2188 7.59375 13.3438L10.4375 16.2188C10.5938 16.375 10.8125 16.375 10.9688 16.2188L16.375 10.875C16.5 10.7188 16.5 10.4688 16.375 10.3438Z"
                    fill="#00BC4F"
                />
            </svg>
            <p className=" text-sm font-bold text-left text-[#00bc4f]">
                {text}
            </p>
        </div>
    );
};*/
// eslint-disable-next-line
function TrustVerification({
   /*  totalScore = 90,
    fortaleza = "Excelente",
    verifyList = {
        email: {
            label: "Dirección de email",
            points: 5,
            verified: false,
            buttonText: "Verificar email"
        },
        phone: {
            label: "Número telefónico",
            points: 10,
            verified: false,
            buttonText: "Verificar número"
        },
        id: {
            label: "Verificar ID",
            points: 35,
            verified: true,
            buttonText: "Verificar ID"
        },
        card: {
            label: "Autenticar tarjeta de crédito o débito",
            points: 45,
            verified: false,
            buttonText: "Autenticar tarjeta"
        }
    }*/
}) {
    return (
        <div className="flex w-full flex-col items-center mt-[150px]" style={{ position:'relative', top: "-140px"}}  >
        <div style={{ fontSize:"40px", fontWeight:"700"}}>Página en construcción</div>
        <div  style={{ fontSize:"18px", fontWeight:"400", top:"10px", position:'relative'}}>Hola actualmente estamos trabajando en esta página, lo invitamos a regresar pronto.</div>
        <div className="  w-[63vw] h-[30vw] xl:w-[45vw]">
                           <img
                               src={error4044}
                               alt="error4044"
                               // xl:h-[75vh]
                               className={`bottom-[0%] right-[0%] object-contain xl:w-[95%]  `}
                           />
                       </div>
                       <div style={{ top:"35px" , position:'relative'}}>
                       <Link to="/platform/professional-dashboard">
                       <Button size={SIZE.LARGE} stretch  >
                          Regresar al tablero
                       </Button>
                       </Link>
                       </div>
       </div>
         /*<div className="flex flex-col justify-start items-start">
            <div className="flex flex-col justify-start items-start w-full  lg:w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
                <p className="flex-groww-full  lg:w-[765px] text-[21px] font-bold text-left text-[#373737]">
                    Confianza y verificación
                </p>
            </div>
            <div className="flex flex-col justify-start items-start w-full  lg:w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
                <p className="w-full  lg:w-[765px] lg:h-[25px] text-lg font-bold text-left text-[#373737]">
                    ¿Qué es un puntaje de confianza?
                </p>
                <p className="w-full  lg:w-[765px] text-base text-left text-[#373737]">
                    El Puntaje de Confianza EPROF es la base de cómo manejamos
                    la verificación, la confianza y los pagos. El Puntaje de
                    Confianza es un valor que indica hasta qué punto hemos sido
                    capaces de verificar si un usuario es quién dice ser. Los
                    empleadores y profesionales con los que es más seguro
                    trabajar son aquellos que se esfuerzan en verificarse para
                    convertirse en usuarios altamente confiables.
                </p>
            </div>
            <div className="flex flex-col justify-start items-start w-full  lg:w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
                <p className="w-full  lg:w-[765px] lg:h-[25px] text-lg font-bold text-left text-[#373737]">
                    Configuración de finanzas
                </p>
                <div className="flex flex-col justify-start items-end  relative gap-1.5">
                    <div className="w-full  lg:w-[765px] h-5">
                        <div className="w-[765px] lg:h-[7px] absolute left-[-0.5px] top-[6.5px] bg-[#d9d9d9]" />
                        <div className="flex justify-between items-start w-full  lg:w-[765px] absolute left-0 top-0">
                            <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=""
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <circle cx={10} cy={10} r={10} fill="#D80027" />
                            </svg>
                            <svg
                                width={21}
                                height={20}
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=""
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <circle
                                    cx="10.3359"
                                    cy={10}
                                    r={10}
                                    fill="#D80027"
                                />
                            </svg>
                            <svg
                                width={21}
                                height={20}
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=""
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <circle
                                    cx="10.6641"
                                    cy={10}
                                    r={10}
                                    fill="#D80027"
                                />
                            </svg>
                            <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=""
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <circle cx={10} cy={10} r={10} fill="#D80027" />
                            </svg>
                        </div>
                    </div>
                    <div className="flex justify-start items-start w-full  lg:w-[521px] relative">
                        <p className="w-full  lg:w-[242px] text-sm font-bold text-left text-[#373737]">
                            Bajo
                        </p>
                        <p className="flex-grow w-full  lg:w-[211px] text-sm font-bold text-left text-[#373737]">
                            Bueno
                        </p>
                        <p className=" text-sm font-bold text-left text-[#373737]">
                            Excelente
                        </p>
                    </div>
                </div>
                <p className="w-full  lg:w-[765px] text-base text-left text-[#373737]">
                    <span className="w-full  lg:w-[765px] text-base text-left text-[#373737]">
                        Puntaje de confianza actual:{" "}
                    </span>
                    <span className="w-full  lg:w-[765px] text-base font-bold text-left text-[#373737]">
                        {totalScore}
                    </span>
                </p>
                <p className="w-full  lg:w-[765px] text-base text-left text-[#373737]">
                    <span className="w-full  lg:w-[765px] text-base text-left text-[#373737]">
                        Fortaleza:{" "}
                    </span>
                    <span className="w-full  lg:w-[765px] text-base font-bold text-left text-[#373737]">
                        {fortaleza}
                    </span>
                </p>
            </div>
            {Object.keys(verifyList).map((key) => {
                const { label, points, verified, buttonText } = verifyList[key];
                return (
                    <div
                        key={key}
                        className="flex flex-col justify-start items-start   w-full    lg:w-[813px]  gap-[22px] p-6 border border-[#cdcdcd]"
                    >
                        <div className="flex relative flex-col gap-10 justify-between items-center self-stretch lg:gap-0 lg:flex-row">
                            <p className="  lg:h-[25px] text-lg font-bold text-left text-[#373737]">
                                {label}
                            </p>
                            <div className="flex justify-start items-center flex-col lg:flex-row gap-5      relative lg:gap-[22px]">
                                {verified ? (
                                    <VerifiedLabel />
                                ) : (
                                    <Button
                                        text={buttonText}
                                        size={SIZE.SMALL}
                                        color={COLOR.WHITE}
                                    />
                                )}

                                {!verified ? (
                                    <div className="flex justify-start items-center  relative gap-[22px]">
                                        <p className=" text-sm font-bold text-left text-[#cdcdcd]">
                                            {points} PUNTOS
                                        </p>
                                    </div>
                                ) : (
                                    <p className=" text-sm font-bold text-left text-[#373737]">
                                        {points} PUNTOS
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>*/
    );
}

export default TrustVerification;
