import React, { useCallback, useState } from "react";
import ChatItem from "./ChatItem";
import Input from "../../ui/Input";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

function Sidebar({ handleNavigateNext = () => {}, addClass = "" }) {
    const [formData, setFormData] = useState({});

    const locationPath = useLocation()?.pathname;
    const processingChatScreen = locationPath.includes(
        "urgent-contract/processing"
    );

    const handleChange = useCallback(
        (target = {}) => {
            let { name, value } = target;
            console.log(target);
            setFormData((data) => ({ ...data, ...{ [name]: value } }));
            console.log(formData);
        },
        [formData]
    );
    return (
        <div
            className={twMerge(
                "flex flex-col justify-start  items-start self-stretch   gap-2.5  border-r  border-[#cdcdcd]",
                addClass,
                processingChatScreen && "flex xl:flex basis-[285px]  "
            )}
        >
            {/* searchbar */}
            {!processingChatScreen && (
                <div className="flex flex-col justify-center items-start  h-[60px] w-full xl:w-[356px] gap-2.5 px-[13px] bg-white">
                    <div className="flex flex-col justify-start items-start self-stretch  gap-[5px]">
                        <Input
                            onChange={(e) => handleChange(e.target)}
                            value={formData.search}
                            name="search"
                            addInputClass={`h-9 px-4 py-2.5`}
                            placeholder="Buscar"
                            icon={
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                        fill="#333333"
                                    />
                                </svg>
                            }
                        />
                    </div>
                </div>
            )}

            <div className="flex overflow-y-auto relative justify-start items-start w-full h-full">
                <div className="flex flex-col justify-start items-start   gap-2.5 ">
                    <div className="flex justify-start items-start   relative gap-2.5 px-[13px]">
                        <p className=" text-base font-bold text-left text-[#373737]">
                            Charlas
                        </p>
                    </div>
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        active
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                    <ChatItem
                        handleNavigateNext={handleNavigateNext}
                        processingChatScreen={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
