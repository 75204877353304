import { useState } from "react";
import card from "../../assets/images/professionals/card.png";
import gFlag from "../../assets/flags/Guatemala.svg";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";
import RatingStars from "../ui/buttons/RatingStars";

function ProfessionalsCard() {
    const [hover, setHover] = useState(0);
    return (
        <div
            onMouseLeave={() => setHover(0)}
            onMouseOver={() => setHover(1)}
            className="flex flex-col justify-start items-start cursor-pointer   w-full sm:w-[316px] relative gap-6 bg-white border border-[#cdcdcd] transition-all ease-in-out duration-300   "
            style={
                hover ? { boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" } : {}
            }
        >
            <div className="self-stretch   h-[241px] relative overflow-hidden">
                <img
                    src={card}
                    alt="rectangle-1415.jpeg"
                    className={`w-full ${
                        hover ? "grayscale-0" : "grayscale"
                    }  sm:w-[316px] h-[241px] absolute left-[-1.52px] top-[-1.52px] object-cover transition-all ease-in-out duration-300`}
                />
            </div>
            <div className="flex flex-col justify-start items-start self-stretch   gap-2.5 px-4">
                <div className="flex justify-start items-start self-stretch   relative gap-[5px]">
                    <div className="relative flex flex-col items-start justify-start flex-grow">
                        <p className="  text-base font-bold text-left text-[#373737]">
                            Cristian Fernández
                        </p>
                        <p className="  text-sm text-left text-[#373737]">
                            Derecho penal
                        </p>
                    </div>
                    <img
                        src={gFlag}
                        className="  w-[30px] h-[30px] relative"
                        alt="gFlag"
                    />
                </div>
                <RatingStars rating={5} />
            </div>
            <div className="flex flex-col justify-start items-start self-stretch   gap-2.5 px-4 pb-4">
                <div className="flex justify-end items-center self-stretch   h-[46px] gap-6 pt-2.5 border-t border-r-0 border-b-0 border-l-0 border-[#d9d9d9]">
                    <Button
                        size={SIZE.SMALL}
                        color={COLOR.WHITE}
                        text="Consultar"
                        iconName={"arrow"}
                    />
                </div>
            </div>
        </div>
    );
}

export default ProfessionalsCard;
