// import { useMobileCheck } from "../../hooks/useDeviceCheck";

import { useCallback, useState } from "react";
import Input from "../ui/Input";
import { Tag } from "../ui/Tag";
import { Button, COLOR } from "../ui/buttons/Button";

function Search() {
    // const mobileView = useMobileCheck();
    const [formData, setFormData] = useState({});

    const handleChange = useCallback(
        (target = {}) => {
            let { name, value } = target;
            console.log(target);
            setFormData((data) => ({ ...data, ...{ [name]: value } }));
            console.log(formData);
        },
        [formData]
    );


    return (
        <div className="flex  flex-col items-center mt-0 xl:mt-[-20vh] ">
            <div className="flex flex-col justify-start items-center w-[90%] sm:w-[985px] relative gap-10 my-[50px] sm:my-auto ">
                <div
                    className="flex flex-col sm:flex-row justify-start items-center  w-full sm:w-[721px] gap-7 p-[30px] backdrop-blur-[20px]"
                    style={{background: "linear-gradient(134.72deg, rgba(255,255,255,0.85) -17.57%, rgba(255,255,255,0) 103.21%)", boxShadow: "0px 4px 20px 0 rgba(0,0,0,0.1)"}}
                >
                    <Input
                        // label="Apellido"
                        placeholder="Escribe la palabra clave"
                        // required
                        onChange={(e) => handleChange(e.target)}
                        value={formData.search}
                        name="search"
                        icon={
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                    fill="#333333"
                                />
                            </svg>
                        }
                    />
                    {/* <div className="flex flex-col justify-start items-start flex-grow gap-[5px]">
                        <div className="flex justify-end items-center self-stretch  h-12 relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929]">
                            <p className="flex-grow w-full sm:w-[411px] text-base text-left text-[#373737]">
                                Escribe la palabra clave
                            </p>
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                    fill="#333333"
                                />
                            </svg>
                        </div>
                    </div> */}
                    <Button
                        addClass={" w-full sm:w-[156px] "}
                        color={COLOR.BLACK}
                    >
                        Buscar
                    </Button>

                    {/* <div className="flex justify-center items-center  w-[156px] h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#292929]">
                        <p className="text-lg text-left text-white ">Buscar</p>
                    </div> */}
                </div>
                <p className=" text-lg font-bold text-left text-[#373737]">
                    Etiquetas populares
                </p>
                <div className="flex justify-center items-start  gap-5 overflow-x-auto w-[100vw] sm:overflow-x-hidden sm:w-auto min-h-[50px]">
                    <Tag />
                    <Tag />
                    <Tag />
                    {/* {!mobileView && ( */}
                    {/* <> */}
                    <Tag />
                    <Tag />
                    <Tag />
                    <Tag />
                    {/* </>
                    )} */}
                </div>
            </div>
        </div>
    );
}

export default Search;
