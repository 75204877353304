import React from "react";
import { Button, COLOR } from "../../components/ui/buttons/Button";
import { certificationsData } from "./Certifications";
import { twMerge } from "tailwind-merge";
import ProgressRing from "../../components/platform/ProgressRing";

const CertificatePositiveResult = () => {
    // const size = 120;
    // const circumference = Math.PI * size;
    // const dashOffset = circumference - (circumference * 20) / 100;
    // const circumference = 377;
    // const dashOffset = 2;
    // const strokeWidth = 6;

    return (
        <div className="flex flex-col gap-10 items-center py-20 mt-20">
            <div className="flex w-full flex-col md:flex-row max-w-[1173px] jus pb-[30px] border-b border-[#cdcdcd] px-2 gap-y-4">
                <div className="flex flex-col justify-start items-start flex-grow gap-[30px]">
                    <div className="flex justify-start items-center  relative gap-3.5">
                        <div className="flex justify-start items-start  gap-2.5 p-1 rounded-lg bg-white">
                            <div className="flex justify-center items-center  relative gap-1 p-2 rounded-lg bg-[#ffeaee]">
                                <svg
                                    width="46"
                                    height="46"
                                    viewBox="0 0 46 46"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className=" w-[46px] h-[46px] relative"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M28.1528 24.5586L24.9783 24.1393L23.6007 21.2643C23.4809 21.0247 23.2414 20.9049 22.9419 20.9049C22.7023 20.9049 22.4627 21.0247 22.3429 21.2643L20.9653 24.1393L17.7908 24.5586C17.2518 24.6784 17.0122 25.3372 17.4315 25.7565L19.7075 27.9727L19.1684 31.0872C19.1085 31.5065 19.4679 31.8659 19.8273 31.8659C19.9471 31.8659 20.0669 31.8659 20.1867 31.806L23.0018 30.3086L25.757 31.806C25.8768 31.8659 25.9966 31.8659 26.1164 31.8659C26.4757 31.8659 26.8351 31.5065 26.7752 31.0872L26.2361 27.9727L28.5122 25.7565C28.9315 25.3372 28.6919 24.6784 28.1528 24.5586ZM37.3169 7.66797H27.9132C27.1945 7.66797 26.5955 8.02734 26.2361 8.6263L23.0018 14.0768L19.7075 8.6263C19.3481 8.08724 18.7492 7.66797 18.0304 7.66797H8.62677C7.84812 7.66797 7.36895 8.56641 7.78822 9.22526L14.6163 18.9883C12.6997 21.0247 11.5018 23.7799 11.5018 26.8346C11.5018 33.1836 16.6528 38.3346 23.0018 38.3346C29.2908 38.3346 34.5018 33.1836 34.5018 26.8346C34.5018 23.7799 33.244 21.0247 31.3273 18.9883L38.1554 9.22526C38.5747 8.56641 38.0955 7.66797 37.3169 7.66797ZM12.2804 10.543H17.4914L20.546 15.6341C19.2283 15.9336 17.9705 16.4128 16.8924 17.1315L12.2804 10.543ZM31.6268 26.8346C31.6268 31.6263 27.7335 35.4596 23.0018 35.4596C18.2101 35.4596 14.3768 31.6263 14.3768 26.8346C14.3768 22.1029 18.2101 18.2096 23.0018 18.2096C27.7335 18.2096 31.6268 22.1029 31.6268 26.8346ZM29.0512 17.1315C27.9731 16.4128 26.7153 15.9336 25.3976 15.6341L28.4523 10.543H33.6632L29.0512 17.1315Z"
                                        fill="#E03352"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <p className=" w-full max-w-[381px] text-[34px] text-left text-[#373737]">
                            Titulo de la certificación
                        </p>
                    </div>
                    <div className="flex gap-5 justify-start items-center">
                        <div className="flex flex-col justify-start items-start bg-white/0">
                            <div className="flex justify-center items-center  relative overflow-hidden px-2 rounded-[100px] bg-[#ff4d4f]">
                                <p className="text-sm sm:text-[21px] text-center text-white p-1">
                                    Nivel 1
                                </p>
                            </div>
                        </div>
                        <div className="flex relative gap-1 justify-end items-center">
                            <p className=" text-[21px] text-left text-[#00bc4f]">
                                APROBADO
                            </p>
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-8 h-8"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M16.0013 5.66797C10.293 5.66797 5.66797 10.3346 5.66797 16.0013C5.66797 21.7096 10.293 26.3346 16.0013 26.3346C21.668 26.3346 26.3346 21.7096 26.3346 16.0013C26.3346 10.3346 21.668 5.66797 16.0013 5.66797ZM16.0013 7.66797C20.5846 7.66797 24.3346 11.418 24.3346 16.0013C24.3346 20.6263 20.5846 24.3346 16.0013 24.3346C11.3763 24.3346 7.66797 20.6263 7.66797 16.0013C7.66797 11.418 11.3763 7.66797 16.0013 7.66797ZM21.8346 13.1263L20.8763 12.168C20.7096 11.9596 20.3763 11.9596 20.168 12.168L14.293 18.0013L11.793 15.5013C11.5846 15.293 11.293 15.293 11.0846 15.5013L10.1263 16.418C9.95964 16.6263 9.95964 16.9596 10.1263 17.1263L13.918 20.9596C14.1263 21.168 14.418 21.168 14.6263 20.9596L21.8346 13.8346C22.0013 13.6263 22.0013 13.293 21.8346 13.1263Z"
                                    fill="#00BC4F"
                                ></path>
                            </svg>
                        </div>
                    </div>
                    <div className="flex relative gap-5 justify-start items-center self-stretch">
                        <p className=" text-lg font-bold text-left text-[#373737]">
                            Califica esta certificación
                        </p>
                        <div className="flex relative gap-2 justify-start items-center">
                            <svg
                                width="20"
                                height="31"
                                viewBox="0 0 20 31"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=" w-5 h-[31px] relative"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M19.2831 12.6984L13.3323 11.8336L10.6722 6.44063C10.5995 6.29298 10.48 6.17345 10.3323 6.10079C9.96199 5.91798 9.51199 6.07032 9.32684 6.44063L6.66668 11.8336L0.715901 12.6984C0.551838 12.7219 0.401838 12.7992 0.286995 12.9164C0.148155 13.0591 0.0716479 13.2511 0.0742847 13.4502C0.0769216 13.6493 0.158487 13.8392 0.301057 13.9781L4.60653 18.1758L3.58934 24.1031C3.56549 24.241 3.58074 24.3828 3.63338 24.5125C3.68602 24.6421 3.77394 24.7544 3.88716 24.8367C4.00038 24.9189 4.13437 24.9678 4.27395 24.9777C4.41352 24.9877 4.5531 24.9583 4.67684 24.893L9.99949 22.0945L15.3222 24.893C15.4675 24.9703 15.6362 24.9961 15.7979 24.968C16.2057 24.8977 16.48 24.5109 16.4097 24.1031L15.3925 18.1758L19.6979 13.9781C19.8151 13.8633 19.8925 13.7133 19.9159 13.5492C19.9792 13.1391 19.6932 12.7594 19.2831 12.6984Z"
                                    fill="#FADB14"
                                ></path>
                            </svg>
                            <svg
                                width="20"
                                height="31"
                                viewBox="0 0 20 31"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=" w-5 h-[31px] relative"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M19.2831 12.6984L13.3323 11.8336L10.6722 6.44063C10.5995 6.29298 10.48 6.17345 10.3323 6.10079C9.96199 5.91798 9.51199 6.07032 9.32684 6.44063L6.66668 11.8336L0.715901 12.6984C0.551838 12.7219 0.401838 12.7992 0.286995 12.9164C0.148155 13.0591 0.0716479 13.2511 0.0742847 13.4502C0.0769216 13.6493 0.158487 13.8392 0.301057 13.9781L4.60653 18.1758L3.58934 24.1031C3.56549 24.241 3.58074 24.3828 3.63338 24.5125C3.68602 24.6421 3.77394 24.7544 3.88716 24.8367C4.00038 24.9189 4.13437 24.9678 4.27395 24.9777C4.41352 24.9877 4.5531 24.9583 4.67684 24.893L9.99949 22.0945L15.3222 24.893C15.4675 24.9703 15.6362 24.9961 15.7979 24.968C16.2057 24.8977 16.48 24.5109 16.4097 24.1031L15.3925 18.1758L19.6979 13.9781C19.8151 13.8633 19.8925 13.7133 19.9159 13.5492C19.9792 13.1391 19.6932 12.7594 19.2831 12.6984Z"
                                    fill="#FADB14"
                                ></path>
                            </svg>
                            <svg
                                width="20"
                                height="31"
                                viewBox="0 0 20 31"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=" w-5 h-[31px] relative"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M19.2831 12.6984L13.3323 11.8336L10.6722 6.44063C10.5995 6.29298 10.48 6.17345 10.3323 6.10079C9.96199 5.91798 9.51199 6.07032 9.32684 6.44063L6.66668 11.8336L0.715901 12.6984C0.551838 12.7219 0.401838 12.7992 0.286995 12.9164C0.148155 13.0591 0.0716479 13.2511 0.0742847 13.4502C0.0769216 13.6493 0.158487 13.8392 0.301057 13.9781L4.60653 18.1758L3.58934 24.1031C3.56549 24.241 3.58074 24.3828 3.63338 24.5125C3.68602 24.6421 3.77394 24.7544 3.88716 24.8367C4.00038 24.9189 4.13437 24.9678 4.27395 24.9777C4.41352 24.9877 4.5531 24.9583 4.67684 24.893L9.99949 22.0945L15.3222 24.893C15.4675 24.9703 15.6362 24.9961 15.7979 24.968C16.2057 24.8977 16.48 24.5109 16.4097 24.1031L15.3925 18.1758L19.6979 13.9781C19.8151 13.8633 19.8925 13.7133 19.9159 13.5492C19.9792 13.1391 19.6932 12.7594 19.2831 12.6984Z"
                                    fill="#FADB14"
                                ></path>
                            </svg>
                            <svg
                                width="20"
                                height="31"
                                viewBox="0 0 20 31"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=" w-5 h-[31px] relative"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M19.2831 12.6984L13.3323 11.8336L10.6722 6.44063C10.5995 6.29298 10.48 6.17345 10.3323 6.10079C9.96199 5.91798 9.51199 6.07032 9.32684 6.44063L6.66668 11.8336L0.715901 12.6984C0.551838 12.7219 0.401838 12.7992 0.286995 12.9164C0.148155 13.0591 0.0716479 13.2511 0.0742847 13.4502C0.0769216 13.6493 0.158487 13.8392 0.301057 13.9781L4.60653 18.1758L3.58934 24.1031C3.56549 24.241 3.58074 24.3828 3.63338 24.5125C3.68602 24.6421 3.77394 24.7544 3.88716 24.8367C4.00038 24.9189 4.13437 24.9678 4.27395 24.9777C4.41352 24.9877 4.5531 24.9583 4.67684 24.893L9.99949 22.0945L15.3222 24.893C15.4675 24.9703 15.6362 24.9961 15.7979 24.968C16.2057 24.8977 16.48 24.5109 16.4097 24.1031L15.3925 18.1758L19.6979 13.9781C19.8151 13.8633 19.8925 13.7133 19.9159 13.5492C19.9792 13.1391 19.6932 12.7594 19.2831 12.6984Z"
                                    fill="#FADB14"
                                ></path>
                            </svg>
                            <svg
                                width="20"
                                height="31"
                                viewBox="0 0 20 31"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=" w-5 h-[31px] relative"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M19.2831 12.6984L13.3323 11.8336L10.6722 6.44063C10.5995 6.29298 10.48 6.17345 10.3323 6.10079C9.96199 5.91798 9.51199 6.07032 9.32684 6.44063L6.66668 11.8336L0.715901 12.6984C0.551838 12.7219 0.401838 12.7992 0.286995 12.9164C0.148155 13.0591 0.0716479 13.2511 0.0742847 13.4502C0.0769216 13.6493 0.158487 13.8392 0.301057 13.9781L4.60653 18.1758L3.58934 24.1031C3.56549 24.241 3.58074 24.3828 3.63338 24.5125C3.68602 24.6421 3.77394 24.7544 3.88716 24.8367C4.00038 24.9189 4.13437 24.9678 4.27395 24.9777C4.41352 24.9877 4.5531 24.9583 4.67684 24.893L9.99949 22.0945L15.3222 24.893C15.4675 24.9703 15.6362 24.9961 15.7979 24.968C16.2057 24.8977 16.48 24.5109 16.4097 24.1031L15.3925 18.1758L19.6979 13.9781C19.8151 13.8633 19.8925 13.7133 19.9159 13.5492C19.9792 13.1391 19.6932 12.7594 19.2831 12.6984Z"
                                    fill="#F0F0F0"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div
                    className="flex flex-col items-center md:items-start  relative gap-5 p-5 rounded-[10px] bg-white"
                    style={{ boxShadow: "0px 0px 10px 0 rgba(0,0,0,0.1)" }}
                >
                    <p className=" text-lg font-bold text-left text-[#373737]">
                        Tus resultados
                    </p>
                    <div className="flex justify-start items-start  gap-[31px] ">
                        <ProgressRing percent={75} />
                        <ProgressRing
                            percent={100}
                            time={"15:00"}
                            text={"minutos"}
                            color="#00BC4F"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full max-w-[1440px] justify-center items-center  relative gap-10 px-2.5 py-[60px] bg-[#ffecef]">
                <p className="text-left text-2xl/tight font-bold  text-[#373737]  self-start lg:pl-32">
                    Certificaciones recomendadas
                </p>
                <div className="flex flex-col md:flex-row w-full max-w-[1176px] gap-10">
                    {certificationsData.map((certification, index) => (
                        <div
                            key={index}
                            className="flex relative flex-col flex-grow gap-3 justify-center items-start p-3 bg-white rounded-lg"
                            style={{
                                boxShadow: "0px 0px 10px 0 rgba(0,0,0,0.1)"
                            }}
                        >
                            <div className="flex  items-center self-stretch  gap-3 pb-3  border-b border-[#cdcdcd]">
                                <div className="flex gap-2.5 p-1 rounded-lg bg-white">
                                    <div
                                        className={twMerge(
                                            "flex justify-center items-center  relative gap-1 p-3 rounded-lg",
                                            `bg-[${certification.bgColor}]`
                                        )}
                                    >
                                        {certification.svg}
                                    </div>
                                </div>
                                <div className="flex relative flex-col flex-grow gap-1 justify-center items-start">
                                    <p className="self-stretch  text-sm text-left text-[#373737]">
                                        {certification.title}
                                    </p>
                                    <div className="flex flex-col bg-white/0">
                                        <div
                                            className={twMerge(
                                                "flex justify-center items-center relative overflow-hidden px-2 rounded-[100px]",
                                                `bg-[${certification.color}]`
                                            )}
                                        >
                                            <p className="text-xs text-center text-white">
                                                {certification.level}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p className="self-stretch  w-full max-w-[341.33px] text-sm text-left text-[#373737]">
                                <span className="self-stretch  w-[341.33px] text-sm text-left text-[#373737]">
                                    {certification.subtextA}
                                </span>
                                <br />
                                <span className="self-stretch  w-[341.33px] text-sm text-left text-[#373737]">
                                    {certification.subtextB}
                                </span>
                            </p>
                            <div className="flex flex-col justify-center items-end self-stretch  gap-2.5">
                                <Button
                                    color={COLOR.RED}
                                    link={""}
                                    addClass="text:sm sm:text-sm md:text-sm xl:text-sm h-9  py-2  px-4 sm:px-4 md:px-4 lg:px-4 rounded-md border-0"
                                >
                                    Prueba tus habilidades
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CertificatePositiveResult;
