import { Link } from "react-router-dom";
import registerBg from "./../assets/images/registerBg.png";
import registerLeft from "./../assets/images/pana000.png";

function AuthWrapper({ className, children = null, childClass = "" }) {
    return (
        <div className={`signUp  h-full overflow-hidden  ${className}`}>
            <Link to="/">
                <div className="flex flex-row justify-start  items-center  fixed top-0 w-full px-[10%] h-[15%]">
                    <svg
                        width={46}
                        height={47}
                        viewBox="0 0 46 47"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-grow-0 flex-shrink-0 w-[45.75px] h-[46.82px]"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M45.0466 46.641L0.706368 46.8168C0.520709 46.8168 0.342656 46.7431 0.211376 46.6118C0.0800956 46.4805 0.00634766 46.3025 0.00634766 46.1168C0.00634766 45.9311 0.0800956 45.7531 0.211376 45.6218C0.342656 45.4905 0.520709 45.4168 0.706368 45.4168L45.0466 45.241C45.1388 45.2406 45.2302 45.2584 45.3155 45.2934C45.4007 45.3284 45.4783 45.3799 45.5436 45.4449C45.609 45.5099 45.6608 45.5872 45.6962 45.6724C45.7316 45.7575 45.7498 45.8488 45.7498 45.941C45.7498 46.0332 45.7316 46.1245 45.6962 46.2096C45.6608 46.2948 45.609 46.3721 45.5436 46.4371C45.4783 46.5022 45.4007 46.5537 45.3155 46.5887C45.2302 46.6236 45.1388 46.6414 45.0466 46.641Z"
                            fill="#D80027"
                        />
                        <path
                            d="M21.007 16.4049C20.8213 16.4049 20.6432 16.3311 20.512 16.1998C20.3807 16.0686 20.3069 15.8905 20.3069 15.7048C20.3069 12.0948 19.3275 9.65887 17.2306 8.03594C17.1576 7.97993 17.0963 7.91007 17.0503 7.83035C17.0043 7.75064 16.9744 7.66263 16.9625 7.57136C16.9505 7.4801 16.9567 7.38737 16.9806 7.29849C17.0045 7.20962 17.0457 7.12632 17.1019 7.05339C17.1581 6.98014 17.2284 6.91875 17.3085 6.87276C17.3886 6.82676 17.477 6.79707 17.5686 6.7854C17.6602 6.77372 17.7532 6.7803 17.8423 6.80475C17.9314 6.82919 18.0147 6.87102 18.0876 6.92783C20.5235 8.81131 21.707 11.6805 21.707 15.7174C21.7037 15.9009 21.6285 16.0757 21.4976 16.2043C21.3666 16.3329 21.1905 16.4049 21.007 16.4049Z"
                            fill="#D80027"
                        />
                        <path
                            d="M25.0787 16.4047C24.9865 16.4051 24.8951 16.3873 24.8098 16.3523C24.7245 16.3173 24.647 16.2658 24.5816 16.2008C24.5163 16.1358 24.4644 16.0585 24.4291 15.9733C24.3937 15.8882 24.3755 15.7969 24.3755 15.7047C24.3755 11.6803 25.5621 8.79861 27.998 6.91513C28.0706 6.85841 28.1537 6.81664 28.2425 6.79224C28.3314 6.76783 28.4241 6.76128 28.5155 6.77296C28.6069 6.78463 28.695 6.81431 28.7749 6.86026C28.8547 6.90622 28.9246 6.96754 28.9806 7.04069C29.0373 7.11327 29.0791 7.19637 29.1035 7.28519C29.1279 7.37401 29.1344 7.46679 29.1228 7.55816C29.1111 7.64953 29.0814 7.73768 29.0354 7.81751C28.9895 7.89734 28.9282 7.96726 28.855 8.02324C26.7549 9.64618 25.7787 12.0821 25.7787 15.6921C25.7803 15.7851 25.7635 15.8775 25.729 15.9639C25.6946 16.0503 25.6433 16.1289 25.5781 16.1953C25.513 16.2616 25.4352 16.3143 25.3495 16.3503C25.2637 16.3862 25.1716 16.4047 25.0787 16.4047Z"
                            fill="#D80027"
                        />
                        <path
                            d="M37.7603 6.78052C35.3946 6.78384 33.1269 7.72527 31.4545 9.39832C29.782 11.0714 28.8414 13.3394 28.8389 15.7051C28.8389 15.8907 28.9126 16.0688 29.0439 16.2001C29.1752 16.3313 29.3532 16.4051 29.5389 16.4051C29.6308 16.4051 29.7218 16.387 29.8068 16.3518C29.8917 16.3166 29.9689 16.2651 30.0339 16.2001C30.0989 16.1351 30.1505 16.0579 30.1856 15.973C30.2208 15.888 30.2389 15.797 30.2389 15.7051C30.2389 13.707 31.0327 11.7907 32.4456 10.3778C33.8584 8.9649 35.7747 8.17116 37.7728 8.17116C38.5454 8.17321 39.3099 8.32741 40.0229 8.62495C40.7358 8.92249 41.3832 9.35754 41.928 9.90526C42.4728 10.453 42.9044 11.1026 43.1981 11.8172C43.4918 12.5317 43.6419 13.297 43.6399 14.0696C43.6357 15.2766 43.154 16.4329 42.2999 17.2858C41.4458 18.1387 40.2889 18.6188 39.0819 18.6213C38.1513 18.6205 37.2591 18.2505 36.6011 17.5925C35.9432 16.9345 35.5731 16.0423 35.5723 15.1118C35.5731 14.4052 35.8542 13.7278 36.3538 13.2282C36.8534 12.7285 37.5309 12.4475 38.2374 12.4467C38.7291 12.447 39.2032 12.6292 39.5684 12.9583C39.2451 13.0589 38.9625 13.2604 38.7619 13.5332C38.5614 13.806 38.4535 14.1359 38.454 14.4745C38.454 14.8958 38.6214 15.2998 38.9193 15.5977C39.2172 15.8956 39.6212 16.0629 40.0424 16.0629C40.9151 16.0629 41.6277 15.4351 41.6277 14.2673C41.6277 12.3274 40.1115 11.0435 38.2374 11.0435C37.1551 11.0435 36.1171 11.4734 35.3518 12.2387C34.5865 13.004 34.1566 14.042 34.1566 15.1243C34.1591 16.4284 34.6793 17.6781 35.6029 18.5987C36.5264 19.5194 37.7778 20.0356 39.0819 20.0339C40.6637 20.0339 42.1808 19.4055 43.2993 18.287C44.4178 17.1685 45.0462 15.6514 45.0462 14.0696C45.0466 13.1125 44.8585 12.1647 44.4925 11.2804C44.1265 10.3961 43.5899 9.59252 42.9133 8.91563C42.2367 8.23873 41.4334 7.70178 40.5492 7.33543C39.665 6.96908 38.7173 6.78052 37.7603 6.78052Z"
                            fill="#D80027"
                        />
                        <path
                            d="M7.62828 6.78052C9.99445 6.78301 12.263 7.72407 13.9361 9.39721C15.6093 11.0703 16.5503 13.3389 16.5528 15.7051C16.5528 15.8907 16.4791 16.0688 16.3478 16.2001C16.2165 16.3313 16.0385 16.4051 15.8528 16.4051C15.6672 16.4051 15.4891 16.3313 15.3578 16.2001C15.2265 16.0688 15.1528 15.8907 15.1528 15.7051C15.1528 13.707 14.359 11.7907 12.9461 10.3778C11.5333 8.9649 9.61699 8.17116 7.61887 8.17116C6.84634 8.17321 6.08177 8.32741 5.36883 8.62495C4.65589 8.92249 4.00854 9.35754 3.46374 9.90526C2.91893 10.453 2.48735 11.1026 2.19362 11.8172C1.89989 12.5317 1.74977 13.2971 1.75183 14.0696C1.7535 15.2788 2.23456 16.438 3.08959 17.293C3.94461 18.148 5.1038 18.6291 6.31298 18.6307C7.24352 18.6299 8.1357 18.2599 8.79369 17.6019C9.45168 16.9439 9.8217 16.0517 9.82253 15.1212C9.8217 14.4141 9.54022 13.7362 9.03991 13.2365C8.53961 12.7368 7.86139 12.4561 7.15427 12.4561C6.66355 12.4563 6.19038 12.6386 5.82642 12.9677C6.14975 13.0683 6.43238 13.2698 6.63291 13.5426C6.83345 13.8155 6.94134 14.1453 6.94081 14.484C6.93998 14.905 6.77238 15.3085 6.47467 15.6062C6.17697 15.9039 5.77343 16.0715 5.35241 16.0724C4.47973 16.0724 3.76401 15.4445 3.76401 14.2768C3.76401 12.3368 5.28335 11.0529 7.15427 11.0529C8.23658 11.0529 9.27457 11.4828 10.0399 12.2481C10.8052 13.0135 11.2351 14.0514 11.2351 15.1337C11.2302 16.4356 10.7093 17.6824 9.78665 18.601C8.86401 19.5195 7.61488 20.0348 6.31298 20.0339C4.73114 20.0339 3.21409 19.4055 2.09556 18.287C0.977028 17.1685 0.348633 15.6514 0.348633 14.0696C0.348631 12.138 1.1153 10.2855 2.48023 8.91876C3.84516 7.55207 5.69673 6.78301 7.62828 6.78052Z"
                            fill="#D80027"
                        />
                        <path
                            d="M32.0566 4.79654H13.3411C13.1555 4.79654 12.9774 4.72279 12.8461 4.59151C12.7149 4.46023 12.6411 4.28217 12.6411 4.09651C12.6411 4.00458 12.6592 3.91355 12.6944 3.82862C12.7296 3.74369 12.7811 3.66652 12.8461 3.60152C12.9111 3.53651 12.9883 3.48495 13.0732 3.44977C13.1582 3.41459 13.2492 3.39648 13.3411 3.39648H32.0629C32.2486 3.39648 32.4266 3.47024 32.5579 3.60152C32.6892 3.7328 32.7629 3.91085 32.7629 4.09651C32.7629 4.18897 32.7446 4.28051 32.7091 4.36586C32.6735 4.4512 32.6214 4.52865 32.5557 4.59374C32.49 4.65882 32.4121 4.71025 32.3265 4.74506C32.2408 4.77987 32.1491 4.79737 32.0566 4.79654Z"
                            fill="#D80027"
                        />
                        <path
                            d="M10.1488 4.7966C9.51298 4.79577 8.90343 4.54282 8.45384 4.09323C8.00425 3.64365 7.75132 3.03411 7.75049 2.3983C7.75132 1.76249 8.00425 1.15295 8.45384 0.703364C8.90343 0.253776 9.51298 0.000830586 10.1488 0H35.243C35.8788 0.000830586 36.4884 0.253776 36.9379 0.703364C37.3875 1.15295 37.6405 1.76249 37.6413 2.3983C37.6405 3.03411 37.3875 3.64365 36.9379 4.09323C36.4884 4.54282 35.8788 4.79577 35.243 4.7966C35.1508 4.79701 35.0595 4.77921 34.9742 4.74422C34.8889 4.70922 34.8113 4.65772 34.746 4.59268C34.6807 4.52763 34.6288 4.45032 34.5934 4.36518C34.558 4.28005 34.5398 4.18876 34.5398 4.09657C34.5398 4.00437 34.558 3.91309 34.5934 3.82795C34.6288 3.74282 34.6807 3.66551 34.746 3.60047C34.8113 3.53542 34.8889 3.48392 34.9742 3.44893C35.0595 3.41393 35.1508 3.39613 35.243 3.39654C35.5072 3.39571 35.7603 3.29018 35.9468 3.10306C36.1334 2.91594 36.2381 2.66251 36.2381 2.3983C36.2381 2.13409 36.1334 1.88066 35.9468 1.69354C35.7603 1.50643 35.5072 1.40089 35.243 1.40005H10.1488C9.88458 1.40089 9.63149 1.50643 9.44496 1.69354C9.25843 1.88066 9.15367 2.13409 9.15367 2.3983C9.15367 2.66251 9.25843 2.91594 9.44496 3.10306C9.63149 3.29018 9.88458 3.39571 10.1488 3.39654C10.241 3.39613 10.3323 3.41393 10.4176 3.44893C10.5029 3.48392 10.5805 3.53542 10.6458 3.60047C10.7111 3.66551 10.763 3.74282 10.7984 3.82795C10.8337 3.91309 10.852 4.00437 10.852 4.09657C10.852 4.18876 10.8337 4.28005 10.7984 4.36518C10.763 4.45032 10.7111 4.52763 10.6458 4.59268C10.5805 4.65772 10.5029 4.70922 10.4176 4.74422C10.3323 4.77921 10.241 4.79701 10.1488 4.7966Z"
                            fill="#D80027"
                        />
                        <path
                            d="M33.692 20.5362C33.6338 20.5367 33.5757 20.5293 33.5194 20.5143C26.414 18.6946 18.9647 18.6946 11.8594 20.5143C11.7703 20.5374 11.6776 20.5428 11.5865 20.5301C11.4953 20.5173 11.4076 20.4868 11.3283 20.4401C11.249 20.3935 11.1797 20.3317 11.1243 20.2582C11.0689 20.1847 11.0286 20.1011 11.0055 20.012C10.9598 19.8318 10.9872 19.6408 11.082 19.4807C11.1767 19.3207 11.3309 19.2047 11.5109 19.1582C18.8407 17.2809 26.5254 17.2809 33.8553 19.1582C33.9443 19.1812 34.028 19.2216 34.1015 19.2769C34.1749 19.3323 34.2367 19.4017 34.2834 19.481C34.33 19.5603 34.3606 19.648 34.3733 19.7391C34.386 19.8302 34.3807 19.923 34.3575 20.012C34.3196 20.1604 34.2339 20.2921 34.1136 20.3869C33.9933 20.4816 33.8452 20.5341 33.692 20.5362Z"
                            fill="#D80027"
                        />
                        <path
                            d="M17.2181 40.8746C17.1259 40.8746 17.0346 40.8564 16.9495 40.821C16.8644 40.7856 16.787 40.7338 16.722 40.6685C16.6569 40.6031 16.6055 40.5256 16.5705 40.4403C16.5355 40.355 16.5177 40.2636 16.5181 40.1714V25.1036C16.5181 25.0117 16.5362 24.9206 16.5714 24.8357C16.6065 24.7508 16.6581 24.6736 16.7231 24.6086C16.7881 24.5436 16.8653 24.492 16.9502 24.4569C17.0352 24.4217 17.1262 24.4036 17.2181 24.4036C17.3103 24.4032 17.4017 24.421 17.487 24.456C17.5723 24.4909 17.6498 24.5424 17.7151 24.6075C17.7805 24.6725 17.8323 24.7498 17.8677 24.835C17.9031 24.9201 17.9213 25.0114 17.9213 25.1036V40.1714C17.9213 40.3579 17.8472 40.5368 17.7153 40.6686C17.5834 40.8005 17.4046 40.8746 17.2181 40.8746Z"
                            fill="#D80027"
                        />
                        <path
                            d="M22.6956 40.8743C22.6034 40.8743 22.5121 40.8561 22.427 40.8207C22.3419 40.7853 22.2646 40.7335 22.1995 40.6681C22.1345 40.6028 22.083 40.5253 22.048 40.44C22.013 40.3547 21.9952 40.2633 21.9956 40.1711V24.3687C21.9956 24.1831 22.0694 24.005 22.2006 23.8737C22.3319 23.7425 22.51 23.6687 22.6956 23.6687C22.8813 23.6687 23.0593 23.7425 23.1906 23.8737C23.3219 24.005 23.3957 24.1831 23.3957 24.3687V40.1711C23.3961 40.2633 23.3783 40.3547 23.3433 40.44C23.3083 40.5253 23.2568 40.6028 23.1917 40.6681C23.1267 40.7335 23.0494 40.7853 22.9643 40.8207C22.8791 40.8561 22.7878 40.8743 22.6956 40.8743Z"
                            fill="#D80027"
                        />
                        <path
                            d="M28.1739 40.8746C27.9874 40.8746 27.8085 40.8005 27.6767 40.6686C27.5448 40.5368 27.4707 40.3579 27.4707 40.1714V25.1036C27.4707 25.0114 27.4889 24.9201 27.5243 24.835C27.5597 24.7498 27.6115 24.6725 27.6768 24.6075C27.7422 24.5424 27.8197 24.4909 27.905 24.456C27.9903 24.421 28.0817 24.4032 28.1739 24.4036C28.3595 24.4036 28.5376 24.4773 28.6689 24.6086C28.8001 24.7399 28.8739 24.9179 28.8739 25.1036V40.1714C28.8743 40.2636 28.8565 40.355 28.8215 40.4403C28.7865 40.5256 28.735 40.6031 28.67 40.6685C28.6049 40.7338 28.5276 40.7856 28.4425 40.821C28.3573 40.8564 28.2661 40.8746 28.1739 40.8746Z"
                            fill="#D80027"
                        />
                        <path
                            d="M44.6918 40.8747H32.9923V23.2955C26.2262 21.6538 19.1656 21.6538 12.3996 23.2955V40.8747H0.700027C0.607834 40.8747 0.516563 40.8564 0.431427 40.8211C0.346291 40.7857 0.268969 40.7338 0.203924 40.6685C0.13888 40.6032 0.0873843 40.5256 0.0523898 40.4403C0.0173953 40.355 -0.000406385 40.2637 7.03903e-06 40.1715C7.03903e-06 39.9858 0.073755 39.8078 0.205035 39.6765C0.336316 39.5452 0.514369 39.4715 0.700027 39.4715H10.9964V22.2062L11.5238 22.0712C18.8536 20.194 26.5383 20.194 33.8681 22.0712L34.3955 22.2062V39.4715H44.6918C44.8775 39.4715 45.0555 39.5452 45.1868 39.6765C45.3181 39.8078 45.3918 39.9858 45.3918 40.1715C45.3923 40.2637 45.3745 40.355 45.3395 40.4403C45.3045 40.5256 45.253 40.6032 45.1879 40.6685C45.1229 40.7338 45.0456 40.7857 44.9604 40.8211C44.8753 40.8564 44.784 40.8747 44.6918 40.8747Z"
                            fill="#D80027"
                        />
                    </svg>
                    <p className="font-[Enriqueta] flex-grow-0 flex-shrink-0 text-[34.703853607177734px] text-left text-[#373737] ml-5">
                        Legal EPROF
                    </p>
                </div>
            </Link>

            <div className="flex flex-col justify-start h-full">
                <img
                    src={registerBg}
                    alt="registerBg"
                    className="w-auto h-full sm:w-full sm:h-auto  object-cover lg:object-contain fixed left-0 bottom-0 overflow-hidden -z-[1]   "
                />
            </div>
            <div className=" flex   flex-col-reverse xl:flex-row  justify-evenly items-center w-full mt-[100px] sm:mt-[7vw] gap-5   ">
                <img
                    src={registerLeft}
                    alt={"registerLeft"}
                    className="h-[75vh] object-contain   "
                />
                {/* justify-evenly  */}
                <div
                    className={`flex flex-col items-center w-full xl:max-w-[40vw] min-h-fit h-full ${childClass}  z-[1]`}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

export default AuthWrapper;
