import { useState } from "react";
// import { Link } from "react-router-dom";
import SupportPageCustomerHelpIcon from "../../../assets/images/SupportPageCustomerHelpIcon";
import SupportPageProfessionalHelpIcon from "../../../assets/images/SupportPageProfessionalHelpIcon";
import Input from "../../../components/ui/Input";

const supportData = [
    {
        heading: "Centro de ayuda para clientes",
        text: "Aprende todo lo que debes saber sobre publicar y completar proyectos, configurar un método de pago escoger al mejor ofertante y liberar pagos.",
        icon: <SupportPageCustomerHelpIcon />,
    },
    {
        heading: "Centro de ayuda para profesionales",
        text: "Aprende todo lo que debes saber sobre completar y optimizar tu perfil, ofertar en proyectos y acceder a los mejores planes y herramientas para hacer tu trabajo y recibir tu pago.",
        icon: <SupportPageProfessionalHelpIcon />,
    },
];

const Support = () => {
  const [search, setSearch] = useState("");

    return (
        // <article className="mt-16 py-1.5">
            // {/* <BlackHeader /> */}
            <div className="flex relative flex-col gap-10 justify-start items-start px-2 mx-auto max-w-max">
                <p className=" w-full max-w-xl text-3xl md:text-[40px] font-bold text-left text-[#373737]">
                    ¿Cómo podemos ayudarte?
                </p>
                {/* searchbar */}
                <div className="flex flex-col justify-start items-start  w-full max-w-xl gap-[5px]">

                    <Input
                        placeholder="Busca un referido por correo electrónico"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        name="search"
                        icon={
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                    fill="#333333"
                                />
                            </svg>
                        }
                    />
                </div>

                <div className="flex justify-start items-start flex-col md:flex-row gap-[26px] ">
                    <div className="flex justify-center w-full max-w-[934px] gap-6 flex-wrap xl:flex-nowrap ">
                        {supportData.map((item, index) => (
                            <div
                                key={index}
                                className="flex  flex-col  items-start  w-full max-w-[455px] relative gap-3 p-5 bg-white"
                                style={{boxShadow: "0px 0px 10px 0 rgba(0,0,0,0.1)"}}
                            >
                                <p className="self-stretch text-[21px] font-bold text-left text-[#373737]">
                                    {item.heading}
                                </p>
                                <div className="flex flex-col-reverse sm:flex-row justify-start items-center self-stretch  relative gap-2.5">
                                    <p className="flex-grow w-full max-w-[265.34px] text-base text-left text-[#373737]">
                                        {item.text}
                                    </p>
                                    {item.icon}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col w-[216px] relative gap-[21px] px-4">
                        <div className="flex justify-start items-start self-stretch  relative gap-2.5 pb-3 border-t-0 border-r-0 border-b border-l-0 border-[#e6e6e6]">
                            <p className="flex-grow w-[184px] text-lg font-bold text-left text-[#373737]">
                                Artículos sugeridos
                            </p>
                        </div>
                        <p className="self-stretch  w-[184px] text-base text-left text-[#d80027]">
                            Pagos por hitos
                        </p>
                        <p className="self-stretch  w-[184px] text-base text-left text-[#373737]">
                            Membresías de profesionales
                        </p>
                    </div>
                </div>
            </div>
        // </article>
    );
};

export default Support;

// const BlackHeader = () => {
//     return (
//         <div className="flex justify-start  items-center sm:w-full pl-0  sm:pl-[132px] bg-[#292929]">
//             <div className="flex justify-center items-center sm:items-start w-full sm:w-auto  gap-[29px]">
//                 <Link to="/platform/support">
//                     <SubHeaderItem active={true} text="Support" />
//                 </Link>
//                 <Link to="">
//                     <SubHeaderItem active={""} text="Comunidad" />
//                 </Link>
//                 <Link to="">
//                     <SubHeaderItem active={""} text="Mis proyectos" />
//                 </Link>
//             </div>
//         </div>
//     );
// };
