import React from "react";
import JobProposals from "../../platform/JobProposals";
import { useOutletContext } from "react-router-dom";

const Proposals = () => {
    const data = useOutletContext();
    return (
        <JobProposals
            formData={data}
            // CustomProfessionalButton={CustomProfessionalButton}
            data={data}
            // showContactButton={showContactButton}
        />
    );
};

export default Proposals;
