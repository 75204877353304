import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { noop } from "../../../utils/noop";
import Dialog from "../../Dialog";
import ChatConfirmDialog from "../../dialogs/ChatConfirmDialog";
import Toggle from "../../ui/Toggle";
import { Button, COLOR, SIZE } from "../../ui/buttons/Button";
import RatingStars from "../../ui/buttons/RatingStars";
import ReturnButton from "../../ui/buttons/ReturnButton";

function Profile({ handleNavigatePrevious = noop, addClass = "" }) {
    const navigate = useNavigate();
    const locationPath = useLocation()?.pathname;
    const processingChatScreen = locationPath.includes(
        "urgent-contract/processing"
    );

    const [showDialog, setDialog] = useState(0);

    return (
        <div
            className={twMerge(
                "flex relative flex-col justify-start items-center self-stretch gap-[19px]",
                addClass,
                processingChatScreen &&
                    "flex xl:flex basis-[215px] border-l border-[#cdcdcd] px-3"
            )}
        >
            <div className="block w-full xl:hidden">
                <ReturnButton onClick={handleNavigatePrevious} />
            </div>
            <div className="flex flex-col justify-start items-center self-stretch  relative space-y-[-46px] ">
                <div className="self-stretch  h-[60px] bg-white" />
                <div className=" w-[139px] h-[139px] relative ">
                    <img
                        src={"https://source.unsplash.com/random/?profile"}
                        // src={avatar}
                        alt="rectangle-1413.png"
                        className="w-[139px] h-[139px]   rounded-[339.02px] border-[6.78px] border-[#d9d9d9] object-cover"
                    />
                    {/* <div className="w-[139px] h-[139px] absolute left-[113px] top-3.5 overflow-hidden rounded-[339.02px] border-[6.78px] border-[#d9d9d9]">
                        <img
                            src={avatar}
                            alt="rectangle-1413.png"
                            className="w-[139px] h-[139px]   rounded-[339.02px] border-[6.78px] border-[#d9d9d9] object-cover"
                        />
                    </div> */}
                    <svg
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute top-2 right-2"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <circle
                            cx="15.9635"
                            cy="16.0365"
                            r="13.561"
                            fill="#00BC4F"
                            stroke="#F2F2F2"
                            strokeWidth="3.39024"
                        />
                    </svg>
                </div>
            </div>
            <div className="flex flex-col justify-start items-center  gap-[5px]">
                <div className="flex relative flex-col justify-start items-start">
                    <p className="self-stretch text-sm text-center">
                        <span className="self-stretch   text-sm font-bold text-center text-[#373737]">
                            Cristian Fer...{" "}
                        </span>
                        <span className="self-stretch   text-sm text-center text-[#7b7b7b]">
                            @cristian
                        </span>
                    </p>
                    <p className="self-stretch   text-sm text-center text-[#373737]">
                        Derecho penal
                    </p>
                </div>
                <RatingStars />
            </div>
            <p className="self-stretch   text-sm text-center text-[#00bc4f]">
                Activo ahora
            </p>
            {!processingChatScreen && (
                <div className="flex flex-col justify-start items-center  gap-[19px] px-4">
                    <div className="flex w-full items-center  gap-[19px]">
                        <Button
                            onClick={() => navigate("/platform/quote")}
                            stretch
                            size={SIZE.SMALL}
                        >
                            Crear cotización
                        </Button>
                        <Button
                            stretch
                            size={SIZE.SMALL}
                            iconName={"arrow"}
                            color={COLOR.WHITE}
                            text="Ver perfil"
                        />
                        {/* <div className="flex justify-center items-center  xl:w-[157px] h-9 relative gap-2.5 px-4 py-2.5 rounded bg-[#d80027]">
                        <p className="text-sm text-left text-white">
                            Crear cotización
                        </p>
                    </div> */}
                        {/* <div className="flex justify-center items-center  xl:w-[157px] h-9 relative gap-2.5 px-4 py-2.5 rounded border-2 border-[#292929]">
                        <p className=" text-sm text-left text-[#373737]">
                            Ver perfil
                        </p>
                        <svg
                            width={21}
                            height={20}
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-5 h-5"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M12.3483 6.35413L11.8275 6.87496C11.7233 7.00517 11.7233 7.18746 11.8535 7.31767L13.9368 9.32288H4.97852C4.79622 9.32288 4.66602 9.47913 4.66602 9.63538V10.3645C4.66602 10.5468 4.79622 10.677 4.97852 10.677H13.9368L11.8535 12.7083C11.7233 12.8385 11.7233 13.0208 11.8275 13.151L12.3483 13.6718C12.4785 13.776 12.6608 13.776 12.791 13.6718L16.2285 10.2343C16.3327 10.1041 16.3327 9.92183 16.2285 9.79163L12.791 6.35413C12.6608 6.24996 12.4785 6.24996 12.3483 6.35413Z"
                                fill="#292929"
                            />
                        </svg>
                    </div> */}
                    </div>
                    <div
                        className="flex justify-start items-center self-stretch  relative gap-[19px] p-2.5 rounded bg-white"
                        style={{ boxShadow: "0px 3px 8px 0 rgba(0,0,0,0.15)" }}
                    >
                        <div className="flex flex-col justify-center items-start  relative gap-[5px]">
                            <p className="self-stretch  xl:w-[269px] text-sm font-bold text-left text-[#373737]">
                                Silenciar
                            </p>
                            <p className="self-stretch  xl:w-[269px] text-sm text-left text-[#7b7b7b]">
                                Desactivar las notificaciones de este chat
                            </p>
                        </div>
                        <Toggle activeProp={false} />
                        {/* <svg
                        width={24}
                        height={25}
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M15 6.5H9C5.65625 6.5 3 9.1875 3 12.5C3 15.8438 5.65625 18.5 9 18.5H15C18.3125 18.5 21 15.8438 21 12.5C21 9.1875 18.3125 6.5 15 6.5ZM4.5 12.5C4.5 10.0312 6.5 8 9 8C11.4688 8 13.5 10.0312 13.5 12.5C13.5 15 11.4688 17 9 17C6.5 17 4.5 15 4.5 12.5ZM15 17H12.9375C15.6562 14.625 15.6562 10.4062 12.9375 8H15C17.4688 8 19.5 10.0312 19.5 12.5C19.5 15 17.4688 17 15 17Z"
                            fill="#D80027"
                        />
                    </svg> */}
                    </div>
                    <div
                        className="flex justify-start items-center self-stretch  relative gap-[19px] p-2.5 rounded bg-white"
                        style={{ boxShadow: "0px 3px 8px 0 rgba(0,0,0,0.15)" }}
                    >
                        <div className="flex flex-col justify-center items-start flex-grow relative gap-[5px]">
                            <p className="self-stretch  xl:w-[270px] text-sm font-bold text-left text-[#373737]">
                                Bloquear
                            </p>
                            <p className="self-stretch  xl:w-[270px] text-sm text-left text-[#7b7b7b]">
                                Restringir mensajes para este chat.
                            </p>
                        </div>

                        <Toggle activeProp={false} />
                        {/* <svg
                        width={24}
                        height={25}
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M15 6.5H9C5.65625 6.5 3 9.1875 3 12.5C3 15.8438 5.65625 18.5 9 18.5H15C18.3125 18.5 21 15.8438 21 12.5C21 9.1875 18.3125 6.5 15 6.5ZM4.5 12.5C4.5 10.0312 6.5 8 9 8C11.4688 8 13.5 10.0312 13.5 12.5C13.5 15 11.4688 17 9 17C6.5 17 4.5 15 4.5 12.5ZM15 17H12.9375C15.6562 14.625 15.6562 10.4062 12.9375 8H15C17.4688 8 19.5 10.0312 19.5 12.5C19.5 15 17.4688 17 15 17Z"
                            fill="#D80027"
                        />
                    </svg> */}
                    </div>
                    <div
                        className="flex justify-start items-center self-stretch  gap-[19px] p-2.5 rounded bg-white"
                        style={{ boxShadow: "0px 3px 8px 0 rgba(0,0,0,0.15)" }}
                    >
                        <div className="flex flex-col justify-center items-start flex-grow relative gap-[5px]">
                            <p className="self-stretch  xl:w-[209px] text-sm font-bold text-left text-[#373737]">
                                Eliminar chat
                            </p>
                            <p className="self-stretch  xl:w-[209px] text-sm text-left text-[#7b7b7b]">
                                Borrar mensajes para este chat en acuerdo mutuo
                            </p>
                        </div>

                        {showDialog ? (
                            <Dialog
                                showDialog={showDialog}
                                setDialog={setDialog}
                                main={
                                    <ChatConfirmDialog
                                        closeDialog={() => setDialog(0)}
                                    />
                                }
                            />
                        ) : null}
                        <Button
                            size={SIZE.SMALL}
                            color={COLOR.WHITE}
                            onClick={() => setDialog(1)}
                            // className="flex  text-sm text-left text-[#373737] justify-center items-center  h-9 relative gap-2.5 px-4 py-2.5 rounded border-2 border-[#292929]"
                        >
                            Solicitar
                        </Button>
                    </div>
                </div>
            )}
            {processingChatScreen && (
                <>
                    <Button
                        stretch
                        size={SIZE.SMALL}
                        iconName={"arrow"}
                        color={COLOR.WHITE}
                        text="Ver perfil"
                    />
                    <Button
                        stretch
                        size={SIZE.SMALL}
                        iconName={"whitePhone"}
                        color={COLOR.BLACK}
                        text="Llamar"
                    />
                    <Button
                        stretch
                        size={SIZE.SMALL}
                        iconName={"videoCam"}
                        color={COLOR.BLACK}
                        text="Video llamada"
                        iconColor="whitePhone"
                    />
                </>
            )}
        </div>
    );
}

export default Profile;
