import React from "react";
import { Button, SIZE } from "../../components/ui/buttons/Button";
import error4044 from "../../assets/images/404/4044.png";
import { Link } from 'react-router-dom';

function EmailNotifications() {
    /*  const [formData, setFormData] = useState({
        email: "",
        password: "",
        timeIntervals:"Defecto"
    });

   const [toggleStates, setToggleStates] = useState({
        privateMessageToggle: false,
        projectMessageToggle: false,
        eprofMessageToggle: false,
        newPostCommunityToggle: false,
        newCommentToggle: false,
        mentionToggle: false,
        newTaskListToggle: false,
        marketingEmailToggle: false,
        eprofOffersToggle: false,
        monthlyNewsletterToggle: false,
        weeklyCommunitySummaryToggle: false,
        recentActivityNotificationToggle: false,
        professionalPaymentRequestToggle: false,
        mainBidderNotificationToggle: false,
        taskListInvitationToggle: false,
        professionalContactRequestToggle: false,
        newsAndAnnouncementsToggle: false,
        marketingEmailsToggle: false,
        eprofOffersEmailsToggle: false,
        monthlyNewsletterEmailsToggle: false,
        weeklyCommunitySummaryEmailsToggle: false,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleButtonClick = () => {};

    const handleToggleChange = (toggleName) => {
        setToggleStates((prevState) => ({
            ...prevState,
            [toggleName]: !prevState[toggleName],
        }));
    };*/

    return (
        <div className="flex w-full flex-col items-center mt-[150px]" style={{ position:'relative', top: "-140px"}}  >
        <div style={{ fontSize:"40px", fontWeight:"700"}}>Página en construcción</div>
        <div  style={{ fontSize:"18px", fontWeight:"400", top:"10px", position:'relative'}}>Hola actualmente estamos trabajando en esta página, lo invitamos a regresar pronto.</div>
        <div className="  w-[63vw] h-[30vw] xl:w-[45vw]">
                           <img
                               src={error4044}
                               alt="error4044"
                               // xl:h-[75vh]
                               className={`bottom-[0%] right-[0%] object-contain xl:w-[95%]  `}
                           />
                       </div>
                       <div style={{ top:"35px" , position:'relative'}}>
                       <Link to="/platform/professional-dashboard">
                       <Button size={SIZE.LARGE} stretch  >
                          Regresar al tablero
                       </Button>
                       </Link>
                       </div>
       </div>
        /* <div className="flex flex-col justify-start items-start">
            <div className="flex flex-col justify-start items-start w-full max-w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
                <p className="flex-grow max-w-[765px] text-[21px] font-bold text-left text-[#373737]">
                    Email y notificaciones
                </p>
            </div>
            <div className="flex flex-col justify-start items-start w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
                <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
                    <p className="flex-grow max-max-w-[765px] text-lg font-bold text-left text-[#373737]">
                        Email
                    </p>
                </div>

                <div className="flex justify-start flex-col lg:flex-row items-start self-stretch gap-[22px]">
                    <Input
                        label="Dirección de email"
                        placeholder="Lorem ipsum"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    <Input
                        label="Ingresa la contraseña actual (si cambiaste el email)"
                        placeholder="Lorem ipsum"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                </div>
                <Button
                    size={SIZE.LARGE}
                    color={COLOR.WHITE}
                    iconName={"plus"}
                    onClick={handleButtonClick}
                >
                    Agregar otra forma de pago
                </Button>
            </div>
            <div className="flex flex-col justify-start items-start w-full max-w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
                <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
                    <p className="flex-grow max-max-w-[765px] text-lg font-bold text-left text-[#373737]">
                        Compendio de emails para tus proyectos publicados
                    </p>
                </div>
                <Toggle
                    label="Compendio de emails para tus proyectos publicados"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.weeklyCommunitySummaryEmailsToggle}
                    callback={() =>
                        handleToggleChange(
                            toggleStates.weeklyCommunitySummaryEmailsToggle
                        )
                    }
                />
                <div className="flex flex-col justify-start items-start  w-full max-w-[371.5px] gap-[5px]">
                    <div className="flex justify-start items-center self-stretch  relative gap-[5px]">
                        <p className="flex-grow w-full max-w-[371.5px] text-sm text-left text-[#373737]">
                            Frecuencia de email para la actividad relacionada
                            con el proyecto
                        </p>
                    </div>
                    <div className="flex justify-start items-start self-stretch  h-12">
                        <Input
                            name="timeIntervals"
                            type="dropdown"
                            onChange={handleInputChange}
                            value={formData.timeIntervals}
                            selectList={[
                                "Defecto",
                                "Instantáneamente",
                                "Cada hora",
                                "Cada 3 horas",
                                "Cada 6 horas",
                                "Cada 12 horas",
                                "Diario"
                            ]}
                            // required
                            className="lg:min-w-[279.5px]"
                        />
                    </div>
                </div>

                <p className="self-stretch  max-w-[765px] text-sm text-left text-[#373737]">
                    Recibe las primeras 3 notificaciones de inmediato. Luego,
                    recibe resúmenes de la actividad del proyecto al cabo de 15,
                    30 y 45 minutos, y luego al cabo de 2 y 6 horas, y luego
                    diariamente.
                </p>
            </div>
            <div className="flex flex-col justify-start items-start w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
                <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
                    <p className="flex-grow max-w-[765px] text-lg font-bold text-left text-[#373737]">
                        Compendio de mensajes por email
                    </p>
                </div>

                <Toggle
                    label="Cuando recibas un mensaje privado de un contacto"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.privateMessageToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.privateMessageToggle)
                    }
                />
                <Toggle
                    label="Cuando recibas un mensaje acerca de un proyecto"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.projectMessageToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.projectMessageToggle)
                    }
                />
                <Toggle
                    label="Cuando recibas un mensaje del personal de Eprof.com"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.eprofMessageToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.eprofMessageToggle)
                    }
                />
            </div>
            <div className="flex flex-col justify-start items-start w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
                <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
                    <p className="flex-grow max-w-[765px] text-lg font-bold text-left text-[#373737]">
                        Correos electrónicos de resumen para Comunidad
                    </p>
                </div>
                <Toggle
                    label="Cuando se realiza una nueva publicación en la comunidad"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.newPostCommunityToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.newPostCommunityToggle)
                    }
                />
                <Toggle
                    label="Cuando se publica un nuevo comentario en una publicación que estás siguiendo"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.newCommentToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.newCommentToggle)
                    }
                />
                <Toggle
                    label="Cuando te mencionan en un comentario nuevo"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.mentionToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.mentionToggle)
                    }
                />
            </div>
            <div className="flex flex-col justify-start items-start w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
                <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
                    <p className="flex-grow max-w-[765px] text-lg font-bold text-left text-[#373737]">
                        Notificaciones push para comunidad
                    </p>
                </div>
                <Toggle
                    label="Cuando se realiza una nueva publicación en la comunidad"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.marketingEmailsToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.marketingEmailsToggle)
                    }
                />
                <Toggle
                    label="Cuando se publica un nuevo comentario en una publicación que estás siguiendo"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.eprofOffersEmailsToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.eprofOffersEmailsToggle)
                    }
                />
                <Toggle
                    label="Cuando te mencionan en un comentario nuevo"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.monthlyNewsletterEmailsToggle}
                    callback={() =>
                        handleToggleChange(
                            toggleStates.monthlyNewsletterEmailsToggle
                        )
                    }
                />
            </div>
            <div className="flex flex-col justify-start items-start w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
                <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
                    <p className="flex-grow max-w-[765px] text-lg font-bold text-left text-[#373737]">
                        Notificaciones push de listas de tareas
                    </p>
                </div>
                <Toggle
                    label="Notificaciones push de listas de tareas"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.newTaskListToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.newTaskListToggle)
                    }
                />
            </div>
            <div className="flex flex-col justify-start items-start w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
                <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
                    <p className="flex-grow max-w-[765px] text-lg font-bold text-left text-[#373737]">
                        Emails individuales
                    </p>
                </div>
                <Toggle
                    label="Noticias y anuncios de Eprof.com"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.newsAndAnnouncementsToggle}
                    callback={() =>
                        handleToggleChange(
                            toggleStates.newsAndAnnouncementsToggle
                        )
                    }
                />

                <Toggle
                    label="Un profesional te pide liberar un pago de hito"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.professionalPaymentRequestToggle}
                    callback={() =>
                        handleToggleChange(
                            toggleStates.professionalPaymentRequestToggle
                        )
                    }
                />

                <Toggle
                    label="Te notificamos del principal ofertante para tu proyecto"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.mainBidderNotificationToggle}
                    callback={() =>
                        handleToggleChange(
                            toggleStates.mainBidderNotificationToggle
                        )
                    }
                />

                <Toggle
                    label="Te notificamos de la actividad más reciente con respecto a los Servicios"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.recentActivityNotificationToggle}
                    callback={() =>
                        handleToggleChange(
                            toggleStates.recentActivityNotificationToggle
                        )
                    }
                />

                <Toggle
                    label="Un profesional ha solicitado contactarte"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.professionalContactRequestToggle}
                    callback={() =>
                        handleToggleChange(
                            toggleStates.professionalContactRequestToggle
                        )
                    }
                />

                <Toggle
                    label="Cuando te invitamos a una lista de tareas"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.taskListInvitationToggle}
                    callback={() =>
                        handleToggleChange(
                            toggleStates.taskListInvitationToggle
                        )
                    }
                />
            </div>
            <div className="flex flex-col justify-start items-start w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
                <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
                    <p className="flex-grow max-w-[765px] text-lg font-bold text-left text-[#373737]">
                        Otros emails
                    </p>
                </div>
                <Toggle
                    label="Emails de marketing"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.marketingEmailToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.marketingEmailToggle)
                    }
                />
                <Toggle
                    label="Ofertas de Eprof.com"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.eprofOffersToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.eprofOffersToggle)
                    }
                />
                <Toggle
                    label="Boletín mensual"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.monthlyNewsletterToggle}
                    callback={() =>
                        handleToggleChange(toggleStates.monthlyNewsletterToggle)
                    }
                />
                <Toggle
                    label="Compendio semanal de la comunidad"
                    labelClass={"text-sm font-bold text-left text-[#373737]"}
                    labelSecond={true}
                    activeProp={toggleStates.weeklyCommunitySummaryToggle}
                    callback={() =>
                        handleToggleChange(
                            toggleStates.weeklyCommunitySummaryToggle
                        )
                    }
                />
            </div>
        </div> */
    );
}

export default EmailNotifications;
