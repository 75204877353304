import { useFormik } from "formik";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import apiurl from "../components/ApiConfig.js";
import SignUpWrapper from "../components/SignInWrapper.js";
import FormFooter from "../components/form/FormFooter.js";
//import FormFooterSocial from "../components/form/FormFooterSocial";
import FormSubmit from "../components/form/FormSubmit.js";
import FormValidation from "../components/form/FormValidation.js";
import PaymentMethods from "../components/form/PaymentMethods";
import UploadImage from "../components/form/UploadImage";
import Input from "../components/ui/Input.js";
import { COUNTRIES } from "../constants/constants.js";
import { dropdownSvg } from "../utils/icons.js";
import "./css/alerts.css";

//const validationSchema = Yup.object({
// name: Yup.string()
// .required("El nombre es obligatorio")
// .matches(/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/, "Solo se permiten letras en el nombre"),
// lastName: Yup.string()
// .required("El apellido es obligatorio")
// .matches(/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/, "Solo se permiten letras en el apellido"),
// dpi: Yup.number().required("El DPI es obligatorio"),
// date: Yup.string().required("La fecha es obligatoria"),
// correo: Yup.string()
// .email("Formato de correo electrónico inválido")
// .required("El correo electrónico es obligatorio"),
// password: Yup.string().required("La contraseña es obligatoria"),
// confirmPassword: Yup.string().required(
// "La confirmación de contraseña es obligatoria"
// ),
// phone: Yup.number()
// .typeError("Por favor ingresa un número válido")
// .max(999999999999, "Máximo 12 dígitos")
// .min(10000000, "Mínimo 8 dígitos")
// .required("El teléfono es obligatorio"),
// phoneFamily: Yup.number()
// .typeError("Por favor ingresa un número válido")
// .max(999999999999, "Máximo 12 dígitos")
// .min(10000000, "Mínimo 8 dígitos")
// .required("El teléfono familiar es obligatorio"),
// phonePropio: Yup.number()
// .typeError("Por favor ingresa un número válido")
// .max(999999999999, "Máximo 12 dígitos")
// .min(10000000, "Mínimo 8 dígitos")
// .required("El teléfono propio es obligatorio"),
// nationality: Yup.string().required("La nacionalidad es obligatoria"),
// address: Yup.string().required("La dirección es obligatoria")
//});

function SignUpClient() {
  const [formData, setFormData] = useState({ creditDebit: 1 });
  const [acceptAgreement, setAgreement] = useState(false);
  const navigate = useNavigate();
  const [inputType, setInputType] = useState("password");
  const formik = useFormik({
    initialValues: {
      username: '',
      name: '',
      lastName: '',
      date: '',
      email: '',
      confirmemail: '',
      password: '',
      confirmPassword: '',
      phone: '',
      phoneFamily: '',
      phonePropio: '',
      nationality: '',
      address: '',
    },
    validate: (values) => {
      const errors = {};

      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Las contraseñas no coinciden';
      }

      const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
      if (!passwordRegex.test(values.password)) {
        errors.password =
          'La contraseña debe tener al menos 8 caracteres, una mayúscula y un número';
      }

      if (values.email !== values.confirmemail) {
        errors.confirmemail = 'Los correos electrónicos no coinciden';
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        if (!isFirstLetterUpperCase(values.name)) {
          return;
        }
        if (!isFirstLetterUpperCase(values.lastName)) {
          return;
        }
        if (values.phone && values.phoneFamily && values.phone === values.phoneFamily) {
          return;
        }
        
        Swal.fire({
          title: 'Procesando...',
          html: 'Espere un momento mientras se registra...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await fetch(`${apiurl}/api/v1/RegisterClient/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: values.username,
            first_name: values.name,
            last_name: values.lastName,
            birth_date: values.date,
            email: values.email,
            password: values.password,
            phone: values.phone,
            phone_two: values.phoneFamily,
            titular_phonename: values.phonePropio,
            nationality: values.nationality,
            address: values.address,
          }),
        });

        if (response.ok) {
          console.log('Registro exitoso');
          setFormData({ ...formData, creditDebit: 2 });
          await Swal.fire({
            title: 'Registro exitoso!',
            text: 'Has sido registrado correctamente.',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          }).then(() => {
            navigate('/login');
          });
        } else {
          console.error('Error al registrar el cliente:', response.statusText);
          const responseBody = await response.json();
          const errorMessage = responseBody.username[0];
          await Swal.fire({
            title: 'Error al registrar!',
            text: `Ha ocurrido un error: ${errorMessage}`,
            icon: 'error',
            timer: 5000,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
      }
    },
  });

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      // Convertir la primera letra a mayúscula y el resto a minúscula
      const formattedValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      formik.setFieldValue(name, formattedValue); // Usar formik.setFieldValue en lugar de setFormData directamente
    },
    [formik]
  );

  const isFirstLetterUpperCase = (value) => {
    return /^[A-Z]/.test(value);
  };

  
  return (
    <SignUpWrapper>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-5 justify-start items-end self-stretch"
      >
        <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
          <div className="flex flex-col flex-grow w-auto sm:w-1/2">
            <Input
              label="Nacionalidad"
              placeholder="Seleccionar"
              onChange={formik.handleChange}
              value={formik.values.nationality}
              name="nationality"
              type="dropdown"
              icon={
                <img
                  alt="dropdown"
                  src={dropdownSvg}
                  className="object-contain w-24 h-24"
                />
              }
              selectList={COUNTRIES}
            />
            {<FormValidation formik={formik} name="nationality" />}
          </div>
          <div className="flex flex-col flex-grow w-auto sm:w-1/2">
            <Input
              label="Dirección"
              placeholder="Dirección"
              required
              onChange={formik.handleChange}
              value={formik.values.address}
              name="address"
            />
            {<FormValidation formik={formik} name="address" />}
          </div>
        </div>
        <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
          <div className="flex flex-col flex-grow">
            <Input
              label="Nombre"
              placeholder="Nombre"
              required
              onChange={handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              name="name"
            />
            {!isFirstLetterUpperCase(formik.values.name) &&
              formik.touched.name && (
                <p className="text-red-500">
                  La primera letra del nombre debe estar en mayúscula
                </p>
              )}
          </div>
          <div className="flex flex-col flex-grow">
            <Input
              label="Apellidos"
              placeholder="Apellidos"
              required
              onChange={handleChange}
              value={formik.values.lastName}
              onBlur={formik.handleBlur}
              name="lastName"
            />
            {!isFirstLetterUpperCase(formik.values.lastName) &&
              formik.touched.lastName && (
                <p className="text-red-500">
                  La primera letra del apellido debe estar en mayúscula
                </p>
              )}
          </div>
        </div>
        <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
          <Input
            label="Identificación"
            placeholder="Identificación"
            required
            onChange={formik.handleChange}
            value={formik.values.username}
            name="username"
            type="number"
            className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto sm:max-w-[23%] gap-[5px]"
          />
          <Input
            label="Fecha de nacimiento"
            placeholder="07/19/1985"
            required
            onChange={formik.handleChange}
            value={formik.values.date}
            name="date"
            type="date"
            className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto sm:max-w-[23%] gap-[5px]"
          />

          <div style={{ marginBottom: "1em" }}>
            <Input
              label="Correo electrónico"
              placeholder="Correo electrónico"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              name="email"
              type="email"
              error={formik.touched.email && formik.errors.email ? true : false}
            />
            {formik.touched.email && formik.errors.email ? (
              <div style={{ color: "red", marginTop: "0.5em" }}>
                {formik.errors.email}
              </div>
            ) : null}
          </div>

          <div style={{ marginBottom: "1em" }}>
            <Input
              label="Confirmar Correo"
              placeholder="Correo electrónico"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmemail}
              name="confirmemail"
              type="email"
              error={
                formik.touched.confirmemail && formik.errors.confirmemail
                  ? true
                  : false
              }
            />
            {formik.touched.confirmemail && formik.errors.confirmemail ? (
              <div style={{ color: "red", marginTop: "0.5em" }}>
                {formik.errors.confirmemail}
              </div>
            ) : null}
          </div>
        </div>
        {/* <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
<div className="flex flex-col flex-grow">
<Input
label="DPI (CUI)"
placeholder="DPI (CUI)"
required
onChange={formik.handleChange}
value={formik.values.dpi}
name="dpi"
className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto gap-[5px]"

/>
{<FormValidation formik={formik} name="dpi" />}
</div>

<div className="flex flex-col flex-grow">
<Input
label="Fecha de nacimiento"
placeholder="07/19/1985"
required
onChange={formik.handleChange}
value={formik.values.date}
name="date"
type="date"
className="flex flex-col justify-start items-start flex-grow w-full sm:w-auto gap-[5px]"

/>
{<FormValidation formik={formik} name="date" />}
</div>

<div className="flex flex-col flex-grow">
<Input
label="Correo electrónico"
placeholder="Correo electrónico"
required
onChange={formik.handleChange}
value={formik.values.correo}
name="correo"
type="email"
// w-[362px]
className="flex flex-col justify-start items-start flex-grow w-full gap-[5px]"

/>
{<FormValidation formik={formik} name="correo" />}
</div>
</div> */}
        <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
          <div className="flex flex-col flex-grow">
            <Input
              label="Contraseña"
              placeholder="Contraseña"
              required
              onChange={formik.handleChange}
              value={formik.values.password}
              name="password"
              type={inputType}
              onBlur={formik.handleBlur}
              onIconClick={() =>
                setInputType((type) =>
                  type === "password" ? "text" : "password"
                )
              }
              icon={
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative w-6 h-6 "
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                    fill="#A4A4A4"
                  />
                </svg>
              }
            />
            {formik.errors.password && formik.touched.password && (
              <p className="text-red-500">{formik.errors.password}</p>
            )}
          </div>
          <div className="flex flex-col flex-grow">
            <Input
              label="Confirmar contraseña"
              placeholder="Contraseña"
              required
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              name="confirmPassword"
              onBlur={formik.handleBlur}
              type={inputType}
              onIconClick={() =>
                setInputType((type) =>
                  type === "password" ? "text" : "password"
                )
              }
              icon={
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative w-6 h-6 "
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                    fill="#A4A4A4"
                  />
                </svg>
              }
            />
            {formik.errors.confirmPassword &&
              formik.touched.confirmPassword && (
                <p className="text-red-500">{formik.errors.confirmPassword}</p>
              )}
          </div>
        </div>
        <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
          {/* <div className="flex flex-col flex-grow"> */}
          <Input
            label="Teléfono propio"
            placeholder="Teléfono"
            required
            onChange={formik.handleChange}
            value={formik.values.phone}
            name="phone"
            type="number"
          />
          {/* {<FormValidation formik={formik} name="phone" />} */}
          {/* </div> */}
          <UploadImage cameraFeature setFormData={setFormData} />
        </div>
        <div className="flex flex-col gap-5 justify-end items-start self-stretch sm:flex-row">
          <div className="flex flex-col flex-grow">
            <Input
              label="Teléfono de amigos o familia"
              placeholder="Teléfono"
              // required
              onChange={formik.handleChange}
              value={formik.values.phoneFamily}
              name="phoneFamily"
              type="number"
              info={`En caso de urgencia te contactaremos a través de este número telefónico`}
            />
            {formik.values.phone === formik.values.phoneFamily && (
              <p className="text-red-500">
                El número de teléfono propio no puede ser igual al número de
                teléfono de amigos o familia.
              </p>
            )}
          </div>
          <div className="flex flex-col flex-grow">
            <Input
              label="Nombre del titular del telefono"
              placeholder="Nombre"
              // required
              onChange={formik.handleChange}
              value={formik.values.phonePropio}
              name="phonePropio"
            />
            {<FormValidation formik={formik} name="phonePropio" />}
          </div>
        </div>
        <PaymentMethods
          formData={formData}
          handleChange={handleChange}
          setFormData={setFormData}
          useFormik={useFormik}
          Yup={Yup}
        />
        {/*<FormFooterSocial />*/}
        <FormSubmit
          acceptAgreement={acceptAgreement}
          setAgreement={setAgreement}
        />
        <FormFooter />
      </form>
    </SignUpWrapper>
  );
}

export default SignUpClient;
