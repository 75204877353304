import React from "react";
import { twMerge } from "tailwind-merge";
import "./styles.css";

function Facebook({
    className = "",
    hover = true,

    width = 67,
    height = 67
}) {
    return (
        <div
            className={twMerge(
                `flex fbShadow cursor-pointer justify-center items-center w-[76.74px] h-[76.74px] relative gap-[27.407394409179688px] p-[5.481479167938232px] rounded-[21.93px] ${
                    hover ? "bg-white" : "bg-[#cddbff]"
                } hover:bg-[#cddbff]`,
                className
            )}
            // style={{ boxShadow: "0px 16.44443702697754px 27.407394409179688px 0 rgba(21,87,255,0.25)" }}
        >
            <svg
                width={width}
                height={height}
                viewBox="0 0 67 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M43.5625 36.111L44.7615 28.2314H37.1389V23.0925C37.1389 20.8656 38.1666 18.8101 41.5926 18.8101H45.1041V12.0439C45.1041 12.0439 41.9352 11.4443 38.9375 11.4443C32.6852 11.4443 28.574 15.2985 28.574 22.1503V28.2314H21.5509V36.111H28.574V55.2962H37.1389V36.111H43.5625Z"
                    fill="#1557FF"
                />
            </svg>
        </div>
    );
}

export default Facebook;
