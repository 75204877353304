import React, { useState } from "react";
import AngleUp from "../../assets/icons/AngleUp";
import AngleDown from "../../assets/icons/AngleDown";
import { noop } from "../../utils/noop";
import { twMerge } from "tailwind-merge";

const CustomDropdown = ({ options = [], onChange = noop, name = "", className }) => {
    const [selectedKey, setSelectedKey] = useState(0);
    const [showOptions, setShowOptions] = useState(false);

    const handleClick = () => {
        setShowOptions((x) => !x);
    };

    const handleOptionClick = (key) => {
        console.log(name)
        console.log("handleOptionClick", key)
        setSelectedKey(key);
        setShowOptions(false);
        onChange({target: {
            name: name,
            value: options[key]
        } })
    };

    const handleArrowClick = (delta) => {
        setSelectedKey(x => x + delta)
        setShowOptions(false)
        onChange({target: {
            name: name,
            value: options[selectedKey+delta]
        } })
    }

    return (
        <div className={twMerge("min-w-[216px] relative select-none", className)} tabIndex={0} onBlur={() => setShowOptions(false)}>
            <div className="w-full flex box-border border border-zinc-300 rounded" >
                <p className="p-4 py-2.5 w-full text-neutral-500 text-base leading-snug font-normal" onClick={handleClick}>
                    {options[selectedKey]}
                </p>
                <div className=" bg-zinc-100 flex flex-col items-center min-w-[44px] border-l border-zinc-300 rounded-r ">
                    <AngleUp active={selectedKey !== 0} size={20} onClick={() => handleArrowClick(-1)}/>
                    <AngleDown active={selectedKey !== options.length-1} size={20} onClick={() => handleArrowClick(1)} />
                </div>
            </div>

            {showOptions && (
                <ul className="absolute w-full z-10 min-w-[216px] divide-y divide-zinc-200 border border-t-0">
                    {options.map((option, key) => (
                        <li key={key} onClick={() => handleOptionClick(key)} className="w-full p-4 bg-white cursor-pointer hover:bg-zinc-100">
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropdown;
