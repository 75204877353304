import { useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";
import stepsIcon from "../../assets/icons/steps-item-icon.svg";
import Dialog from "../Dialog";
import { UploadDocuments } from "../client-only/UrgentHiringForm";
import InfoHover from "../ui/InfoHover";
import Input from "../ui/Input";
import Radio from "../ui/Radio";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";
import DetailsModal from "./DetailsModal";

function ReasonForDispute({
    declarationText = "Tras haber contratado el servicio en cuestión, me encuentro en la posición de tener que iniciar una disputa debido a que no se ha cumplido completamente con lo acordado. pues no recibí la totalidad de lo",
    handleChange,
    formData,
    goToNext
}) {
    const [showLess, setShowLess] = useState(1);
    return (
        <div className="flex flex-col justify-start items-start w-[818px] relative gap-10">
            <div className="flex relative flex-col flex-grow-0 flex-shrink-0 gap-4 justify-start items-start self-stretch">
                <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px] p-2 rounded bg-[#f5f8ff]">
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                            fill="#333333"
                        />
                    </svg>
                    <p className="flex-grow w-[773px] text-base text-left">
                        <span className="flex-grow w-[773px] text-base text-left text-[#373737]">
                            Presenta el motivo de la disputa, explicando
                            detalladamente la naturaleza del problema o
                            desacuerdo con la transacción o servicio. Al
                            solicitar la disputa usted esta autorizando a Legal
                            EPROF a poder revisar todas sus conversaciones y
                            demás transacciones, para más información consulte
                            nuestras{" "}
                        </span>
                        {/* todo: Add link to redirect to privacy policy */}
                        <span className="cursor-pointer flex-grow w-[773px] text-base text-left text-[#d80027]">
                            Políticas de privacidad.
                        </span>
                    </p>
                </div>
            </div>
            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[818px] text-base text-left text-[#373737]">
                <span className="self-stretch flex-grow-0 flex-shrink-0 w-[818px] text-base font-bold text-left text-[#373737]">
                    Motivo de la disputa:{" "}
                </span>
                <span className="self-stretch flex-grow-0 flex-shrink-0 w-[818px] text-base text-left text-[#373737]">
                    Incumplimiento total del servicio
                </span>
            </p>
            <p className="flex-grow-0 flex-shrink-0 w-[787px] text-base text-left">
                <span className="flex-grow-0 flex-shrink-0 w-[787px] text-base font-bold text-left text-[#373737]">
                    Declaración del caso:{" "}
                </span>
                <span className="flex-grow-0 flex-shrink-0 w-[787px] text-base text-left text-[#373737]">
                    {declarationText.length > 200
                        ? showLess
                            ? declarationText.trim().substring(0, 200) + "..."
                            : declarationText
                        : declarationText}
                </span>
                {showLess && declarationText.length > 200 ? (
                    <span
                        onClick={() => setShowLess(false)}
                        className="underline cursor-pointer flex-grow-0 flex-shrink-0 w-[787px] text-base text-left text-[#d80027]"
                    >
                        más
                    </span>
                ) : null}
            </p>
            <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]">
                {/* <p className="self-stretch flex-grow-0 flex-shrink-0 w-[818px] text-sm text-left text-[#373737]">
                    Descripción de su apelación
                </p> */}
                <Input
                    label="Descripción de su apelación"
                    onChange={handleChange}
                    value={formData.desc}
                    name="desc"
                    placeholder="Justifique su solicitud de apelación"
                    type="long"
                />

                {/* <div className="flex justify-end items-center self-stretch flex-grow-0 flex-shrink-0 h-[154px] relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929]">
                    <p className="self-stretch flex-grow w-[786px] h-[134px] text-base font-semibold text-left text-[#7b7b7b]">
                        Justifique su solicitud de apelación
                    </p>
                </div> */}
            </div>
            <div className="flex flex-grow-0 flex-shrink-0 gap-5 justify-end items-center self-stretch">
                <Button
                    // stretch
                    color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    addClass={"h-11"}
                    text="Cancelar"
                    // iconName={"arrow"}
                />
                <Button
                    // stretch
                    // color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    text="Siguiente"
                    addClass={"h-11"}
                    onClick={goToNext}
                    iconName={"arrow"}
                />
            </div>
        </div>
    );
}
function AttachEvidence({
    goToNext,
    goToPrev,
    handleChange,
    formData,
    setFormData
}) {
    const RELACIONADAS = [
        "Iniciar una disputa",
        "Responder a una disputa",
        "Solicitar arbitraje",
        "Negociar un acuerdo",
        "Adjuntar evidencias"
    ];

    const removeFileByIndex = (index) => {
        setFormData((prevFormData) => {
            // Create a copy of the documents array
            const newDocuments = [...prevFormData.documents];

            // Check if the index is valid
            if (index >= 0 && index < newDocuments.length) {
                // Remove the file at the specified index
                newDocuments.splice(index, 1);
                console.log(`File at index ${index} removed successfully.`);
            } else {
                console.log(`Invalid index: ${index}`);
            }

            // Return the updated formData object
            return { ...prevFormData, documents: newDocuments };
        });
    };
    return (
        <div className="flex flex-col justify-start items-start w-[818px] gap-10">
            <div className="flex relative flex-col flex-grow-0 flex-shrink-0 gap-4 justify-start items-start self-stretch">
                <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px] p-2 rounded bg-[#f5f8ff]">
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                            fill="#333333"
                        />
                    </svg>
                    <p className="flex-grow w-[773px] text-base text-left text-[#373737]">
                        Adjuntar archivos de evidencia relevantes, como capturas
                        de pantalla, correos electrónicos u otros documentos que
                        respalden sus argumentos.
                    </p>
                </div>
            </div>
            <div className="flex flex-col flex-grow-0 flex-shrink-0 gap-4 justify-start items-start self-stretch">
                <Input
                    label="relacionada"
                    placeholder="relacionada"
                    required
                    onChange={handleChange}
                    value={formData.relacionada}
                    name="relacionada"
                    selectList={RELACIONADAS}
                    type="dropdown"
                />
                <div className="flex flex-col justify-start items-start self-stretch  gap-2.5 ">
                    <div className="flex flex-col justify-start items-start self-stretch  gap-[5px] ">
                        <div className="flex justify-start items-center  gap-[5px]">
                            <InfoHover red={false} text={"Adjuntar archivos"} />

                            <p className=" text-sm text-left text-[#373737]">
                                Adjuntar archivos
                            </p>
                        </div>

                        <UploadDocuments
                            files={formData.documents}
                            handleChange={handleChange}
                            removeFile={removeFileByIndex}
                        />
                    </div>
                </div>

                <Input
                    label="Describe el documento adjuntado"
                    onChange={handleChange}
                    value={formData.desc}
                    name="desc"
                    placeholder="Justifique su solicitud de disputa"
                    type="long"
                    textAreaLimit={1200}
                />
                <div className="flex flex-col justify-center items-end self-stretch flex-grow-0 flex-shrink-0 gap-2.5">
                    <Button
                        // stretch
                        color={COLOR.BLACK}
                        // size={SIZE.MEDIUM}
                        // addClass={"h-11"}
                        text="Cargar"
                        // onClick={goToPrev}
                        // iconName={"arrow"}
                    />
                    <div className="flex relative flex-col flex-grow-0 flex-shrink-0 gap-1 justify-start items-start self-stretch">
                        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                            <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#c10434]">
                                file name.jpg
                            </p>
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                                preserveAspectRatio="none"
                            >
                                <g clip-path="url(#clip0_13035_80190)">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M12.0007 13.4149L17.6575 19.0718C18.048 19.4623 18.6812 19.4623 19.0717 19.0718C19.4622 18.6812 19.4622 18.0481 19.0717 17.6575L13.4149 12.0007L19.0717 6.34384C19.4622 5.95332 19.4622 5.32015 19.0717 4.92963C18.6812 4.5391 18.048 4.5391 17.6575 4.92963L12.0007 10.5865L6.34382 4.92961C5.9533 4.53909 5.32013 4.53909 4.92961 4.92961C4.53909 5.32014 4.53909 5.9533 4.92961 6.34383L10.5864 12.0007L4.92961 17.6576C4.53909 18.0481 4.53909 18.6812 4.92961 19.0718C5.32013 19.4623 5.9533 19.4623 6.34382 19.0718L12.0007 13.4149Z"
                                        fill="#292929"
                                    />
                                </g>
                                <defs>
                                    <clippath id="clip0_13035_80190">
                                        <rect
                                            width={24}
                                            height={24}
                                            fill="white"
                                        />
                                    </clippath>
                                </defs>
                            </svg>
                        </div>
                        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[818px] text-sm text-left text-[#373737]">
                            Aquí en esta captura muestro el chat donde el
                            cliente no especifica la urgencia ni la fecha del
                            documento.
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex flex-grow-0 flex-shrink-0 gap-5 justify-end items-center self-stretch">
                <Button
                    // stretch
                    color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    addClass={"h-11"}
                    text="Regresar"
                    onClick={goToPrev}
                    // iconName={"arrow"}
                />
                <Button
                    // stretch
                    // color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    text="Siguiente"
                    addClass={"h-11"}
                    onClick={goToNext}
                    iconName={"arrow"}
                />
            </div>
        </div>
    );
}
function MilestoneNegotiation({
    goToNext,
    goToPrev,
    formData,
    setFormData,
    handleChange
}) {
    const questions = [
        "¿El servicio fue prestado según los requerimientos de su cliente?",
        "¿Durante la prestación del servicio ha mantenido una comunicación clara y oportuna?",
        "¿El Cliente se mostró en desacuerdo con usted en algún momento?",
        "¿Ha entregado información y documentación necesaria en relación con el servicio?"
    ];

    // "Al cliente le llega una notificación indicando que el abogado a apelado su proceso e disputa y tiene una contra oferta, la cual debe rechazar o aceptar." into English:
    // "The client receives a notification indicating that the lawyer has appealed their dispute process and has a counteroffer, which they must reject or accept."
    return (
        <div className="flex flex-col justify-start items-start w-[818px] gap-10">
            <div className="flex relative flex-col flex-grow-0 flex-shrink-0 gap-4 justify-start items-start self-stretch">
                <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px] p-2 rounded bg-[#f5f8ff]">
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                            fill="#333333"
                        />
                    </svg>
                    <p className="flex-grow w-[773px] text-base text-left text-[#373737]">
                        En esta sección usted tiene la oportunidad de negociar
                        un monto por el servicio. Deberá responder una serie de
                        preguntas que respaldaran su requerimiento
                    </p>
                </div>
            </div>
            <div className="flex flex-col space-y-4">
                {questions.map((question, index) => (
                    <div
                        className="flex justify-between items-center"
                        key={index}
                    >
                        <label>{question}</label>
                        <div className="flex space-x-2">
                            <Radio
                                text="Sí"
                                checked={formData?.[`answer${index}`] ?? false}
                                onClick={() => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        [`answer${index}`]: true
                                    }));
                                }}
                            />
                            <Radio
                                text="No"
                                checked={!formData?.[`answer${index}`] ?? false}
                                onClick={() => {
                                    setFormData((prev) => ({
                                        ...prev,
                                        [`answer${index}`]: false
                                    }));
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex flex-col flex-grow-0 flex-shrink-0 gap-4 justify-start items-start self-stretch">
                <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[22px]">
                    <p className="flex-grow w-[471px] text-base font-bold text-left text-[#373737]">
                        Indique el monto mínimo dispuesto a recibir por el
                        servicio
                    </p>
                    <div className="flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 w-[325px] gap-[5px]">
                        <div className="flex flex-grow-0 flex-shrink-0 justify-start items-start self-stretch h-12">
                            <div className="flex flex-col justify-center items-center self-stretch flex-grow-0 flex-shrink-0 w-12 gap-2.5 px-4 py-2.5 rounded-tl rounded-bl bg-[#f2f2f2] border border-[#cdcdcd]">
                                <div className="flex relative flex-grow-0 flex-shrink-0 justify-center items-center self-stretch">
                                    <p className="flex-grow-0 flex-shrink-0 w-1.5 text-sm font-semibold text-center text-[#112752]">
                                        $
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center self-stretch flex-grow gap-2.5 px-4 py-2.5 rounded-tr rounded-br bg-white border border-[#cdcdcd]">
                                <div className="flex justify-end items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5">
                                    <input
                                        className="flex-grow outline-none w-[245px] text-sm font-semibold text-right text-[#7b7b7b]"
                                        value={formData.amount}
                                        onChange={handleChange}
                                        name="amount"
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-[5px] p-2 rounded bg-[#fff7ce]">
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                            fill="#333333"
                        />
                    </svg>
                    <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#373737]">
                        Este monto no puede exceder el registrado para el
                        cumplimiento de este hito
                    </p>
                </div>
            </div>

            <div className="flex flex-grow-0 flex-shrink-0 gap-5 justify-end items-center self-stretch">
                <Button
                    // stretch
                    color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    addClass={"h-11"}
                    text="Regresar"
                    onClick={goToPrev}
                    // iconName={"arrow"}
                />
                <Button
                    // stretch
                    // color={COLOR.WHITE}
                    size={SIZE.MEDIUM}
                    text="Siguiente"
                    addClass={"h-11"}
                    onClick={goToNext}
                    iconName={"arrow"}
                />
            </div>
        </div>
    );
}
function Arbitration({ goToPrev }) {
    const [showDialog, setShowDialog] = useState(false);

    const data = [
        {
            date: "20/02/24",
            message: "El cliente yy ha iniciado el proceso de disputa"
        },
        {
            date: "20/02/24",
            message: "Su Apelación ha sido registrada"
        }
    ];

    return (
        <>
            <Dialog
                main={
                    <DetailsModal
                        closeDialog={() => setShowDialog(0)}
                        customTimerButtons={
                            <div className="flex flex-grow-0 flex-shrink-0 gap-4 justify-center items-center self-stretch">
                                <Button
                                    // stretch
                                    // color={COLOR.WHITE}
                                    size={SIZE.MEDIUM}
                                    addClass={"h-11"}
                                    text="Descargar"
                                    // onClick={goToPrev}
                                />
                                <Button
                                    // stretch
                                    color={COLOR.WHITE}
                                    size={SIZE.MEDIUM}
                                    addClass={"h-11"}
                                    text="Cerrar"
                                    // onClick={goToPrev}
                                />
                            </div>
                        }
                    />
                }
                setDialog={setShowDialog}
                showDialog={showDialog}
            />
            <div className="flex flex-col justify-start items-start w-[818px] gap-10">
                <div className="flex relative flex-col flex-grow-0 flex-shrink-0 gap-4 justify-start items-start self-stretch">
                    <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px] p-2 rounded bg-[#f5f8ff]">
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                                fill="#333333"
                            />
                        </svg>
                        <p className="flex-grow w-[773px] text-base text-left text-[#373737]">
                            En caso de que la negociación no llegue a un
                            acuerdo, el proceso avanza hacia el arbitraje, donde
                            un tercero imparcial revisa la información
                            presentada y toma una decisión final para resolver
                            la disputa.
                        </p>
                    </div>
                </div>
                <div className="flex relative flex-col flex-grow-0 flex-shrink-0 gap-4 justify-start items-start self-stretch">
                    <p className="self-stretch flex-grow-0 flex-shrink-0 w-[818px] text-base font-bold text-left text-[#373737]">
                        Estado de la Disputa
                    </p>
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className="flex relative flex-grow-0 flex-shrink-0 gap-5 justify-start items-center self-stretch"
                        >
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#373737]">
                                {item.date}
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#373737]">
                                {item.message}
                            </p>
                        </div>
                    ))}
                </div>

                <div className="flex flex-grow-0 flex-shrink-0 gap-5 justify-end items-center self-stretch">
                    <Button
                        // stretch
                        // color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        text="Resumen de la disputa"
                        addClass={"h-11"}
                        onClick={() => setShowDialog(1)}
                    />
                    <Button
                        // stretch
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        addClass={"h-11"}
                        text="Cerrar"
                        onClick={goToPrev}
                    />
                </div>
            </div>
        </>
    );
}

function TrackStep({ stepNumber = 1, text, active = false, currentStep = 1 }) {
    console.log(text);
    console.log(active);
    return (
        <div className="flex flex-col justify-start items-start self-stretch">
            <div className="flex relative gap-7 justify-start items-center self-stretch">
                {currentStep <= stepNumber ? (
                    <div className="relative w-8 h-8">
                        <div
                            className={twMerge(
                                "w-8 h-8 absolute left-[-1px] top-[-1px] rounded-[32px]  border border-black/25",
                                active && "bg-[#d80027]"
                            )}
                        />
                        <p
                            className={twMerge(
                                `absolute top-1 left-3 text-base text-center text-black/25`,
                                active && "text-[#fff]"
                            )}
                        >
                            {stepNumber}
                        </p>
                    </div>
                ) : (
                    <img src={stepsIcon} alt={"stepsIcon"} />
                )}
                <p
                    className={twMerge(
                        `  text-base text-left text-black/[0.45]`,
                        active && "text-[#d80027]"
                    )}
                >
                    {text}
                </p>
            </div>
            {stepNumber !== 6 && (
                <div className="hidden gap-8 justify-start items-start pl-4 lg:flex">
                    <div
                        className={twMerge(
                            "flex justify-start items-center  relative gap-2.5 py-1.5",
                            active && "bg-[#d80027]"
                        )}
                    >
                        <svg
                            width={1}
                            height={12}
                            viewBox="0 0 1 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M0.5 0V12"
                                stroke={active ? "#D80027" : "#F2F2F2"}
                            />
                        </svg>
                    </div>
                </div>
            )}
        </div>
    );
}

function SidebarTracker({ currentStep }) {
    const steps = [
        "Indique el motivo de su disputa",
        "Adjuntar evidencias",
        "Negociación del hito",
        "Arbitraje"
    ];

    return (
        <div className="flex flex-col justify-start items-center lg:w-[216px] relative gap-[30px]">
            <p className=" text-base font-bold text-left text-[#373737]">
                Tracker Profesional
            </p>
            <div className="flex px-5 lg:px-0 gap-10 lg:gap-2  overflow-x-auto w-full  flex-row lg:flex-col justify-start items-start   lg:w-[169px]">
                {steps.map((step, index) => (
                    <TrackStep
                        stepNumber={index + 1}
                        text={step}
                        active={currentStep >= index + 1}
                        currentStep={currentStep}
                    />
                ))}
            </div>
        </div>
    );
}

function CancelConfirmModal({ closeDialog }) {
    return (
        <div className="flex flex-col justify-center items-center w-full h-screen">
            <div
                className="flex flex-col justify-start items-center w-full  lg:w-[594px] gap-[30px] p-[30px] bg-white"
                style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
            >
                <div className="flex justify-start items-start  relative gap-2.5 p-2.5 rounded-[100px] bg-[#ffd80c]">
                    <svg
                        width={40}
                        height={40}
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-10 h-10"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M20.0026 7.08301C12.8672 7.08301 7.08594 12.9163 7.08594 19.9997C7.08594 27.1351 12.8672 32.9163 20.0026 32.9163C27.0859 32.9163 32.9193 27.1351 32.9193 19.9997C32.9193 12.9163 27.0859 7.08301 20.0026 7.08301ZM20.0026 30.4163C14.2214 30.4163 9.58594 25.7809 9.58594 19.9997C9.58594 14.2705 14.2214 9.58301 20.0026 9.58301C25.7318 9.58301 30.4193 14.2705 30.4193 19.9997C30.4193 25.7809 25.7318 30.4163 20.0026 30.4163ZM22.1901 24.9997C22.1901 23.8018 21.2005 22.8122 20.0026 22.8122C18.7526 22.8122 17.8151 23.8018 17.8151 24.9997C17.8151 26.2497 18.7526 27.1872 20.0026 27.1872C21.2005 27.1872 22.1901 26.2497 22.1901 24.9997ZM17.9193 14.0101L18.2839 21.0934C18.2839 21.4059 18.5964 21.6663 18.9089 21.6663H21.0443C21.3568 21.6663 21.6693 21.4059 21.6693 21.0934L22.0339 14.0101C22.0339 13.6455 21.7734 13.333 21.4089 13.333H18.5443C18.1797 13.333 17.9193 13.6455 17.9193 14.0101Z"
                            fill="#333333"
                        />
                    </svg>
                </div>
                <div className="flex flex-col justify-start items-center self-stretch  relative gap-[5px]">
                    <p className="self-stretch  w-full  lg:w-[534px] text-2xl font-bold text-left text-[#373737]">
                        ¿Estas seguro de desea cancelar el proceso?
                    </p>
                </div>
                <div className="flex justify-start items-center self-stretch  gap-[5px]">
                    <Button
                        addClass={"h-11"}
                        size={SIZE.MEDIUM}
                        color={COLOR.RED}
                        text="Si"
                        link={"/platform/community"}
                        stretch
                    />
                    <Button
                        addClass={"h-11"}
                        stretch
                        size={SIZE.MEDIUM}
                        color={COLOR.WHITE}
                        text="No"
                        onClick={closeDialog}
                    />
                </div>
            </div>
        </div>
    );
}

function DisputeSteps() {
    const [step, setStep] = useState(1);
    const [showDialog, setShowDialog] = useState(false);

    const goToNext = () => setStep((p) => p + 1);
    const goToPrev = () => setStep((p) => p - 1);

    const [formData, setFormData] = useState({
        answer0: true,
        answer1: true,
        answer2: true,
        answer3: true
    });

    const handleChange = useCallback(
        (e = {}) => {
            let { name, value } = e.target;
            setFormData((data) => ({ ...data, ...{ [name]: value } }));
            console.log(formData);
        },
        [formData]
    );
    const getComponent = (step) => {
        switch (step) {
            case 1:
                return (
                    <ReasonForDispute
                        handleChange={handleChange}
                        formData={formData}
                        goToNext={goToNext}
                    />
                );
            case 2:
                return (
                    <AttachEvidence
                        goToNext={goToNext}
                        goToPrev={goToPrev}
                        handleChange={handleChange}
                        formData={formData}
                        setFormData={setFormData}
                    />
                );
            case 3:
                return (
                    <MilestoneNegotiation
                        className={"flex relative flex-col gap-5 w-full"}
                        callback={goToNext}
                        goToNext={goToNext}
                        handleChange={handleChange}
                        goToPrev={goToPrev}
                        setFormData={setFormData}
                        formData={formData}
                    />
                );
            case 4:
                return <Arbitration goToNext={goToNext} goToPrev={goToPrev} />;
            default:
                return <></>;
        }
    };

    const component = getComponent(step);
    return (
        <>
            <Dialog
                main={
                    <CancelConfirmModal
                        closeDialog={() => setShowDialog(false)}
                    />
                }
                setDialog={setShowDialog}
                showDialog={showDialog}
            />
            <div className="relative min-h-screen">
                <div className="flex   lg:left-[200px] flex-col justify-center items-start  gap-2.5 mx-20 py-10 ">
                    <div className="flex justify-start items-center  relative gap-2.5">
                        <svg
                            width={34}
                            height={34}
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=" shrink-0    w-[33.1px] h-[33.87px]"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M32.5872 33.8077L0.51128 33.9349C0.376974 33.9349 0.24817 33.8815 0.153201 33.7866C0.0582324 33.6916 0.00488281 33.5628 0.00488281 33.4285C0.00488281 33.2942 0.0582324 33.1654 0.153201 33.0704C0.24817 32.9755 0.376974 32.9221 0.51128 32.9221L32.5872 32.7949C32.6539 32.7946 32.72 32.8075 32.7817 32.8328C32.8434 32.8581 32.8995 32.8954 32.9467 32.9424C32.994 32.9895 33.0315 33.0454 33.0571 33.107C33.0827 33.1686 33.0959 33.2346 33.0959 33.3013C33.0959 33.368 33.0827 33.4341 33.0571 33.4957C33.0315 33.5572 32.994 33.6132 32.9467 33.6602C32.8995 33.7073 32.8434 33.7445 32.7817 33.7698C32.72 33.7952 32.6539 33.808 32.5872 33.8077Z"
                                fill="#D80027"
                            />
                            <path
                                d="M15.1967 11.9335C15.0624 11.9335 14.9336 11.8802 14.8386 11.7852C14.7436 11.6902 14.6903 11.5614 14.6903 11.4271C14.6903 8.81564 13.9818 7.05345 12.4649 5.87941C12.412 5.8389 12.3677 5.78836 12.3344 5.73069C12.3011 5.67302 12.2795 5.60935 12.2709 5.54333C12.2622 5.47731 12.2667 5.41024 12.284 5.34594C12.3013 5.28164 12.3311 5.22139 12.3717 5.16863C12.4124 5.11564 12.4632 5.07123 12.5212 5.03796C12.5791 5.00468 12.6431 4.98321 12.7094 4.97476C12.7756 4.96632 12.8429 4.97108 12.9074 4.98876C12.9718 5.00645 13.0321 5.03671 13.0848 5.0778C14.847 6.44032 15.7031 8.51588 15.7031 11.4362C15.7007 11.5689 15.6463 11.6954 15.5516 11.7884C15.4569 11.8814 15.3294 11.9336 15.1967 11.9335Z"
                                fill="#D80027"
                            />
                            <path
                                d="M18.1425 11.9346C18.0758 11.9349 18.0097 11.922 17.948 11.8967C17.8863 11.8714 17.8302 11.8342 17.7829 11.7871C17.7356 11.74 17.6981 11.6841 17.6725 11.6225C17.647 11.5609 17.6338 11.4949 17.6338 11.4282C17.6338 8.51698 18.4922 6.43232 20.2544 5.06981C20.3069 5.02878 20.367 4.99856 20.4312 4.9809C20.4955 4.96325 20.5626 4.95851 20.6287 4.96696C20.6948 4.9754 20.7586 4.99687 20.8163 5.03012C20.8741 5.06336 20.9246 5.10772 20.9651 5.16064C21.0062 5.21314 21.0364 5.27326 21.054 5.33751C21.0717 5.40176 21.0764 5.46888 21.068 5.53498C21.0595 5.60107 21.0381 5.66484 21.0048 5.72259C20.9716 5.78034 20.9272 5.83092 20.8743 5.87142C19.3551 7.04546 18.6489 8.80764 18.6489 11.4191C18.6501 11.4864 18.6379 11.5532 18.6129 11.6157C18.588 11.6782 18.5509 11.7351 18.5038 11.7831C18.4566 11.8311 18.4004 11.8692 18.3384 11.8952C18.2763 11.9212 18.2097 11.9346 18.1425 11.9346Z"
                                fill="#D80027"
                            />
                            <path
                                d="M27.3161 4.9707C25.6048 4.97311 23.9643 5.65414 22.7544 6.86443C21.5446 8.07472 20.8641 9.71546 20.8623 11.4268C20.8623 11.5611 20.9157 11.6899 21.0106 11.7848C21.1056 11.8798 21.2344 11.9332 21.3687 11.9332C21.4352 11.9332 21.5011 11.9201 21.5625 11.8946C21.6239 11.8692 21.6798 11.8319 21.7268 11.7848C21.7738 11.7378 21.8111 11.682 21.8366 11.6206C21.862 11.5591 21.8751 11.4933 21.8751 11.4268C21.8751 9.98132 22.4493 8.59507 23.4714 7.57298C24.4935 6.5509 25.8797 5.9767 27.3252 5.9767C27.884 5.97819 28.4371 6.08973 28.9529 6.30497C29.4686 6.52021 29.9369 6.83493 30.331 7.23116C30.7251 7.62738 31.0373 8.09735 31.2498 8.61423C31.4623 9.13111 31.5709 9.68479 31.5694 10.2436C31.5664 11.1168 31.2179 11.9533 30.6001 12.5703C29.9822 13.1873 29.1453 13.5346 28.2721 13.5364C27.599 13.5358 26.9536 13.2681 26.4776 12.7921C26.0016 12.3161 25.7339 11.6707 25.7333 10.9976C25.7339 10.4864 25.9372 9.99639 26.2987 9.63496C26.6601 9.27352 27.1501 9.07021 27.6613 9.06961C28.0169 9.06983 28.3599 9.20169 28.6241 9.43976C28.3902 9.51252 28.1858 9.65825 28.0407 9.85563C27.8956 10.053 27.8176 10.2916 27.818 10.5366C27.818 10.8413 27.939 11.1336 28.1545 11.3491C28.37 11.5646 28.6623 11.6856 28.967 11.6856C29.5983 11.6856 30.1138 11.2315 30.1138 10.3867C30.1138 8.98332 29.017 8.05453 27.6613 8.05453C26.8783 8.05453 26.1274 8.36556 25.5738 8.91919C25.0202 9.47282 24.7091 10.2237 24.7091 11.0067C24.711 11.95 25.0873 12.8541 25.7554 13.5201C26.4235 14.186 27.3288 14.5595 28.2721 14.5583C29.4164 14.5583 30.5139 14.1037 31.323 13.2946C32.1322 12.4854 32.5868 11.388 32.5868 10.2436C32.5871 9.5513 32.451 8.86568 32.1862 8.22595C31.9215 7.58622 31.5333 7.00492 31.0438 6.51525C30.5544 6.02558 29.9732 5.63714 29.3336 5.37213C28.694 5.10711 28.0084 4.9707 27.3161 4.9707Z"
                                fill="#D80027"
                            />
                            <path
                                d="M5.51808 4.9707C7.22978 4.97251 8.87086 5.65328 10.0812 6.86363C11.2916 8.07399 11.9723 9.71506 11.9741 11.4268C11.9741 11.5611 11.9208 11.6899 11.8258 11.7848C11.7309 11.8798 11.602 11.9332 11.4677 11.9332C11.3334 11.9332 11.2046 11.8798 11.1097 11.7848C11.0147 11.6899 10.9613 11.5611 10.9613 11.4268C10.9613 9.98132 10.3871 8.59507 9.36505 7.57298C8.34296 6.5509 6.95672 5.9767 5.51128 5.9767C4.95242 5.97819 4.39933 6.08973 3.88359 6.30497C3.36784 6.52021 2.89955 6.83493 2.50543 7.23116C2.11132 7.62738 1.79911 8.09735 1.58662 8.61423C1.37414 9.13111 1.26554 9.68479 1.26704 10.2436C1.26824 11.1184 1.61624 11.9569 2.23477 12.5755C2.8533 13.194 3.69186 13.542 4.56659 13.5432C5.23974 13.5426 5.88515 13.2749 6.36114 12.7989C6.83714 12.3229 7.10481 11.6775 7.10541 11.0044C7.10481 10.4928 6.90119 10.0025 6.53926 9.64097C6.17734 9.27947 5.68672 9.07642 5.17518 9.07642C4.82019 9.07656 4.4779 9.20846 4.21461 9.44657C4.4485 9.51933 4.65296 9.66507 4.79803 9.86244C4.9431 10.0598 5.02115 10.2984 5.02076 10.5434C5.02016 10.848 4.89892 11.1399 4.68356 11.3552C4.4682 11.5706 4.17627 11.6919 3.87171 11.6925C3.24041 11.6925 2.72265 11.2383 2.72265 10.3935C2.72265 8.99013 3.82175 8.06135 5.17518 8.06135C5.95813 8.06135 6.70901 8.37237 7.26264 8.926C7.81627 9.47963 8.1273 10.2305 8.1273 11.0135C8.12369 11.9553 7.74689 12.8572 7.07945 13.5217C6.41201 14.1861 5.50838 14.5589 4.56659 14.5583C3.42228 14.5583 2.32484 14.1037 1.51569 13.2946C0.706537 12.4854 0.251953 11.388 0.251953 10.2436C0.251952 8.84635 0.806565 7.50619 1.79396 6.51752C2.78136 5.52885 4.12079 4.97251 5.51808 4.9707Z"
                                fill="#D80027"
                            />
                            <path
                                d="M23.1898 3.53624H9.65093C9.51662 3.53624 9.38782 3.48289 9.29285 3.38792C9.19788 3.29295 9.14453 3.16414 9.14453 3.02984C9.14453 2.96334 9.15763 2.89749 9.18308 2.83605C9.20853 2.77461 9.24583 2.71878 9.29285 2.67176C9.33987 2.62474 9.3957 2.58744 9.45714 2.56199C9.51857 2.53654 9.58443 2.52344 9.65093 2.52344H23.1943C23.3287 2.52344 23.4575 2.57679 23.5524 2.67176C23.6474 2.76673 23.7007 2.89553 23.7007 3.02984C23.7008 3.09672 23.6875 3.16295 23.6618 3.22469C23.636 3.28642 23.5983 3.34245 23.5508 3.38954C23.5033 3.43662 23.447 3.47382 23.385 3.499C23.323 3.52418 23.2567 3.53684 23.1898 3.53624Z"
                                fill="#D80027"
                            />
                            <path
                                d="M7.34139 3.53629C6.88144 3.53568 6.44049 3.3527 6.11525 3.02747C5.79002 2.70224 5.60705 2.2613 5.60645 1.80135C5.60705 1.3414 5.79002 0.900456 6.11525 0.575223C6.44049 0.249989 6.88144 0.0670071 7.34139 0.0664062H25.4947C25.9546 0.0670071 26.3955 0.249989 26.7208 0.575223C27.046 0.900456 27.229 1.3414 27.2296 1.80135C27.229 2.2613 27.046 2.70224 26.7208 3.02747C26.3955 3.3527 25.9546 3.53568 25.4947 3.53629C25.428 3.53658 25.3619 3.52371 25.3002 3.49839C25.2385 3.47308 25.1824 3.43582 25.1351 3.38877C25.0878 3.34171 25.0503 3.28579 25.0247 3.2242C24.9991 3.16261 24.986 3.09657 24.986 3.02988C24.986 2.96319 24.9991 2.89715 25.0247 2.83556C25.0503 2.77398 25.0878 2.71805 25.1351 2.671C25.1824 2.62394 25.2385 2.58669 25.3002 2.56137C25.3619 2.53606 25.428 2.52318 25.4947 2.52348C25.6858 2.52288 25.8689 2.44653 26.0038 2.31117C26.1387 2.17581 26.2145 1.99248 26.2145 1.80135C26.2145 1.61022 26.1387 1.42688 26.0038 1.29152C25.8689 1.15616 25.6858 1.07981 25.4947 1.07921H7.34139C7.15026 1.07981 6.96717 1.15616 6.83223 1.29152C6.6973 1.42688 6.62151 1.61022 6.62151 1.80135C6.62151 1.99248 6.6973 2.17581 6.83223 2.31117C6.96717 2.44653 7.15026 2.52288 7.34139 2.52348C7.40808 2.52318 7.47417 2.53606 7.53587 2.56137C7.59758 2.58669 7.65367 2.62394 7.70094 2.671C7.7482 2.71805 7.7857 2.77398 7.81129 2.83556C7.83688 2.89715 7.85006 2.96319 7.85006 3.02988C7.85006 3.09657 7.83688 3.16261 7.81129 3.2242C7.7857 3.28579 7.7482 3.34171 7.70094 3.38877C7.65367 3.43582 7.59758 3.47308 7.53587 3.49839C7.47417 3.52371 7.40808 3.53658 7.34139 3.53629Z"
                                fill="#D80027"
                            />
                            <path
                                d="M24.3735 14.9217C24.3313 14.922 24.2893 14.9166 24.2486 14.9058C19.1085 13.5894 13.7197 13.5894 8.57962 14.9058C8.51521 14.9225 8.44811 14.9264 8.3822 14.9172C8.31628 14.908 8.25283 14.8859 8.19546 14.8521C8.13809 14.8184 8.08794 14.7737 8.04788 14.7205C8.00781 14.6674 7.9786 14.6069 7.96194 14.5424C7.92883 14.412 7.94871 14.2739 8.01723 14.1581C8.08575 14.0423 8.19732 13.9584 8.32755 13.9247C13.63 12.5668 19.1891 12.5668 24.4915 13.9247C24.556 13.9414 24.6165 13.9706 24.6696 14.0107C24.7228 14.0507 24.7675 14.1009 24.8012 14.1583C24.835 14.2156 24.8571 14.2791 24.8663 14.345C24.8755 14.4109 24.8716 14.478 24.8549 14.5424C24.8274 14.6497 24.7654 14.745 24.6784 14.8136C24.5914 14.8821 24.4842 14.9201 24.3735 14.9217Z"
                                fill="#D80027"
                            />
                            <path
                                d="M12.4556 29.6359C12.3889 29.6359 12.3229 29.6227 12.2613 29.5972C12.1997 29.5716 12.1438 29.5341 12.0967 29.4868C12.0497 29.4395 12.0124 29.3834 11.9871 29.3217C11.9618 29.26 11.9489 29.1939 11.9492 29.1272V18.2271C11.9492 18.1606 11.9623 18.0948 11.9878 18.0333C12.0132 17.9719 12.0505 17.9161 12.0975 17.869C12.1446 17.822 12.2004 17.7847 12.2618 17.7593C12.3233 17.7338 12.3891 17.7207 12.4556 17.7207C12.5223 17.7204 12.5884 17.7333 12.6501 17.7586C12.7118 17.7839 12.7679 17.8212 12.8152 17.8682C12.8624 17.9153 12.8999 17.9712 12.9255 18.0328C12.9511 18.0944 12.9643 18.1604 12.9643 18.2271V29.1272C12.9643 29.2622 12.9107 29.3915 12.8153 29.4869C12.7199 29.5823 12.5905 29.6359 12.4556 29.6359Z"
                                fill="#D80027"
                            />
                            <path
                                d="M16.4185 29.636C16.3518 29.636 16.2858 29.6229 16.2242 29.5973C16.1626 29.5717 16.1067 29.5342 16.0596 29.4869C16.0126 29.4397 15.9753 29.3836 15.95 29.3219C15.9247 29.2602 15.9118 29.1941 15.9121 29.1274V17.6959C15.9121 17.5615 15.9655 17.4327 16.0604 17.3378C16.1554 17.2428 16.2842 17.1895 16.4185 17.1895C16.5528 17.1895 16.6816 17.2428 16.7766 17.3378C16.8716 17.4327 16.9249 17.5615 16.9249 17.6959V29.1274C16.9252 29.1941 16.9123 29.2602 16.887 29.3219C16.8617 29.3836 16.8244 29.4397 16.7774 29.4869C16.7303 29.5342 16.6744 29.5717 16.6128 29.5973C16.5512 29.6229 16.4852 29.636 16.4185 29.636Z"
                                fill="#D80027"
                            />
                            <path
                                d="M20.3807 29.6359C20.2458 29.6359 20.1164 29.5823 20.0211 29.4869C19.9257 29.3915 19.8721 29.2622 19.8721 29.1272V18.2271C19.8721 18.1604 19.8852 18.0944 19.9108 18.0328C19.9364 17.9712 19.9739 17.9153 20.0212 17.8682C20.0685 17.8212 20.1246 17.7839 20.1863 17.7586C20.248 17.7333 20.314 17.7204 20.3807 17.7207C20.515 17.7207 20.6439 17.7741 20.7388 17.869C20.8338 17.964 20.8871 18.0928 20.8871 18.2271V29.1272C20.8874 29.1939 20.8746 29.26 20.8492 29.3217C20.8239 29.3834 20.7867 29.4395 20.7396 29.4868C20.6926 29.5341 20.6366 29.5716 20.5751 29.5972C20.5135 29.6227 20.4474 29.6359 20.3807 29.6359Z"
                                fill="#D80027"
                            />
                            <path
                                d="M32.3303 29.6346H23.8668V16.9178C18.9722 15.7302 13.8645 15.7302 8.96991 16.9178V29.6346H0.506403C0.43971 29.6346 0.373684 29.6215 0.312096 29.5959C0.250508 29.5703 0.194573 29.5328 0.14752 29.4855C0.100466 29.4382 0.0632141 29.3821 0.037899 29.3204C0.0125838 29.2587 -0.00029398 29.1926 5.09207e-06 29.126C5.09207e-06 28.9916 0.0533547 28.8628 0.148324 28.7679C0.243292 28.6729 0.372097 28.6196 0.506403 28.6196H7.95484V16.1298L8.33634 16.0322C13.6388 14.6742 19.1979 14.6742 24.5003 16.0322L24.8818 16.1298V28.6196H32.3303C32.4646 28.6196 32.5934 28.6729 32.6883 28.7679C32.7833 28.8628 32.8367 28.9916 32.8367 29.126C32.837 29.1926 32.8241 29.2587 32.7988 29.3204C32.7734 29.3821 32.7362 29.4382 32.6891 29.4855C32.6421 29.5328 32.5862 29.5703 32.5246 29.5959C32.463 29.6215 32.3969 29.6346 32.3303 29.6346Z"
                                fill="#D80027"
                            />
                        </svg>
                        <p className="font-[Enriqueta]    text-[25.104915618896484px] text-left text-[#373737]">
                            Legal EPROF
                        </p>
                    </div>
                </div>

                <div className="flex flex-col gap-10 p-3 lg:p-10 lg:flex-row">
                    <SidebarTracker currentStep={step} />

                    <div className="flex flex-col gap-5 pl-5  w-full lg:pr-[7%]">
                        <div className="flex relative flex-grow-0 flex-shrink-0 gap-5 justify-start items-center self-stretch">
                            <p className="flex-grow w-[715px] text-base font-bold text-left text-[#373737]">
                                Hito: Nombre del hito
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base font-bold text-left text-[#373737]">
                                Valor $200
                            </p>
                        </div>
                        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[818px] text-base font-bold text-left text-[#373737]">
                            Nombre del profesional
                        </p>
                        {component}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DisputeSteps;
