import React from "react";
import Checkbox from "../ui/Checkbox";
import { Button } from "../ui/buttons/Button";

function FormSubmit({ setAgreement, acceptAgreement }) {
    return (
        <>
            <div className="flex justify-start items-center self-stretch   relative gap-[5px] ">
                <Checkbox
                    checked={acceptAgreement}
                    toggleCheck={() => setAgreement((prev) => !prev)}
                    // text=""
                    customElm={
                        <p className="self-stretch flex-grow sm:w-[715px] sm:h-6 text-base text-left">
                            <span className="self-stretch flex-grow sm:w-[715px] sm:h-6 text-base text-left text-[#373737]">
                                Acepto el{" "}
                            </span>
                            <a
                                href="/policy"
                                className="self-stretch flex-grow sm:w-[715px] sm:h-6 text-base text-left text-[#d80027]"
                            >
                                Acuerdo del Usuario
                            </a>
                            <span className="self-stretch flex-grow sm:w-[715px] sm:h-6 text-base text-left text-[#373737]">
                                {" "}
                                y la{" "}
                            </span>
                            <a
                                href="/policy"
                                className="self-stretch flex-grow sm:w-[715px] sm:h-6 text-base text-left text-[#d80027]"
                            >
                                Política de privacidad
                            </a>
                            <span className="self-stretch flex-grow sm:w-[715px] sm:h-6 text-base text-left text-[#373737]">
                                {" "}
                                de eProf{" "}
                            </span>
                        </p>
                    }
                />
            </div>
            <div className="flex flex-col justify-center items-center self-stretch   gap-2.5">
                <Button
                    type={acceptAgreement ? "submit" : "button"}
                    className={` flex justify-center items-center   h-11 relative gap-2.5 px-8 py-2.5 rounded ${
                        acceptAgreement
                            ? "bg-[#d80027] cursor-pointer hover:opacity-90 "
                            : "bg-[#7b7b7b] cursor-not-allowed  "
                    }`}
                >
                    <p className="text-base text-left text-white ">
                        Registrarme
                    </p>
                </Button>
            </div>
        </>
    );
}

export default FormSubmit;
