import React from "react";
/*import Radio from "../ui/Radio";*/
import { Button, SIZE } from "../ui/buttons/Button";
/*import Dialog from "../Dialog";
import CuentaConfirmationModal from "./CuentaConfirmationModal";
import RoleChangeModal from "./RoleChangeModal";*/
import error4044 from "../../assets/images/404/4044.png";
import { Link } from 'react-router-dom';

function Cuenta() {
    /*const [trabajar, setTrabajar] = useState(false);
    const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
    const [showRoleChangeConfirmation, setShowRoleChangeConfirmation] =
        useState(false);*/

    return (
        <div className="flex w-full flex-col items-center mt-[150px]" style={{ position:'relative', top: "-140px"}}  >
        <div style={{ fontSize:"40px", fontWeight:"700"}}>Página en construcción</div>
        <div  style={{ fontSize:"18px", fontWeight:"400", top:"10px", position:'relative'}}>Hola actualmente estamos trabajando en esta página, lo invitamos a regresar pronto.</div>
        <div className="  w-[63vw] h-[30vw] xl:w-[45vw]">
                           <img
                               src={error4044}
                               alt="error4044"
                               // xl:h-[75vh]
                               className={`bottom-[0%] right-[0%] object-contain xl:w-[95%]  `}
                           />
                       </div>
                       <div style={{ top:"35px" , position:'relative'}}>
                       <Link to="/platform/professional-dashboard">
                       <Button size={SIZE.LARGE} stretch  >
                          Regresar al tablero
                       </Button>
                       </Link>
                       </div>
       </div>
       /* <>
            <Dialog
                main={
                    <CuentaConfirmationModal
                        closeDialog={() => setShowDeactivateDialog(false)}
                    />
                }
                showDialog={showDeactivateDialog}
                setDialog={setShowDeactivateDialog}
            />
            <Dialog
                main={
                    <RoleChangeModal
                        closeDialog={() => setShowRoleChangeConfirmation(false)}
                    />
                }
                showDialog={showRoleChangeConfirmation}
                setDialog={setShowRoleChangeConfirmation}
            />
            <div className="flex flex-col justify-start items-start">
                <div className="flex flex-col justify-start items-start w-full self-stretch   w-full relative gap-[22px] p-6 border border-[#cdcdcd]">
                    <p className="flex-grow lg:w-[765px] text-[21px] font-bold text-left text-[#373737]">
                        Cuenta
                    </p>
                </div>
                <div className="flex flex-col justify-start items-start  w-full relative gap-[22px] p-6 border border-[#cdcdcd]">
                    <p className=" lg:w-[765px] h-[25px] text-lg font-bold text-left text-[#373737]">
                        Tipo de cuenta
                    </p>
                    <p className="self-stretch  lg:w-[765px] text-sm font-bold text-left text-[#373737]">
                        Deseo:
                    </p>
                    <div className="flex justify-start items-start  gap-[22px]">
                        <Radio
                            text="Trabajar"
                            checked={trabajar}
                            onClick={() => {
                                setShowRoleChangeConfirmation(true);
                                setTrabajar(true);
                            }}
                        />
                        <Radio
                            text="Contratar"
                            checked={!trabajar}
                            onClick={() => {
                                setShowRoleChangeConfirmation(true);
                                setTrabajar(false);
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-col justify-start items-start  w-full relative gap-[22px] p-6 border border-[#cdcdcd]">
                    <p className=" lg:w-[765px] h-[25px] text-lg font-bold text-left text-[#373737]">
                        Desactivar cuenta
                    </p>
                    <Button
                        text="Desactivar mi cuenta"
                        size={SIZE.MEDIUM}
                        color={COLOR.WHITE}
                    />
                </div>
                <div className="flex flex-col justify-center items-end  w-full gap-[22px] p-6 bg-[#f2f2f2] border border-[#cdcdcd]">
                    <Button
                        text="Guardar configuración"
                        size={SIZE.LARGE}
                        onClick={() => setShowDeactivateDialog(true)}
                    />
                </div>
            </div>
        </>*/
    );
}

export default Cuenta;
