import { useCallback, useState, useEffect } from "react";
import JobSearch from "../../../components/platform/JobSearch";
import JobsFilters from "../../../components/platform/JobsFilters";
import { useScreenDimensions } from "../../../hooks/useDeviceCheck";
function JobOffers() {
    useEffect(() => {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
            window.location.href = "/login";
        }
      }, []); 
    const [formData, setFormData] = useState({});
    const [showFilter, setShowFilter] = useState(false);

    const toggleFilterExpand = useCallback(() => {
        setShowFilter((f) => !f);
    }, []);

    const handleChange = useCallback((target = {}) => {
        let { name, value } = target;
        console.log(target);
        setFormData((data) => ({ ...data, ...{ [name]: value } }));
        // console.log(formData);
    }, []);

    const handleFormSubmission = useCallback((e) => {
        // submit formData
        e.preventDefault();
    }, []);

    const { width } = useScreenDimensions();

    console.log("Rerender ", width);

    const filterSideBarView = width < 1173;

    return (
        <div className="flex flex-col  justify-start items-center relative gap-5 w-full mt-[100px]  ">
            <div className="flex items-start justify-start gap-6">
                <div
                    className={`${
                        !showFilter && filterSideBarView ? "hidden" : ""
                    } ${
                        filterSideBarView
                            ? "absolute left-0 top-0 z-20 bg-white p-10 h-full"
                            : ""
                    }  `}
                >
                    <JobsFilters
                        filterSideBarView={filterSideBarView}
                        toggleFilterExpand={toggleFilterExpand}
                        handleFormSubmission={handleFormSubmission}
                        formData={formData}
                        setFormData={setFormData}
                        handleChange={handleChange}
                    />
                </div>
                <JobSearch
                    filterSideBarView={filterSideBarView}
                    toggleFilterExpand={toggleFilterExpand}
                    handleFormSubmission={handleFormSubmission}
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                />
            </div>
        </div>
    );
}

export default JobOffers;