import React from "react";
import { Link } from "react-router-dom";

const EmptyTableSupport = () => {
    return (
        <div className="flex flex-col  gap-[30px] p-4 border border-[#cdcdcd] ">
            <div className="flex flex-col justify-center items-center self-stretch ">
                <div className="flex  items-center self-stretch   gap-4 px-4 py-3 bg-[#f2f2f2] overflow-x-auto">
                    <p className=" basis-[222.5px] text-sm font-bold text-left text-[#373737]">
                        Nombre
                    </p>
                    <p className=" basis-[222.5px] text-sm font-bold text-left text-[#373737]">
                        Tamaño
                    </p>
                    <p className=" basis-[222.5px] text-sm font-bold text-left text-[#373737]">
                        Propietario
                    </p>
                    <p className=" basis-[222.5px] text-sm font-bold text-left text-[#373737]">
                        Modificado
                    </p>
                    <p className=" basis-[155px] text-sm font-bold text-left text-[#373737]">
                        Acciones
                    </p>
                </div>
                <div className="flex flex-col justify-center  self-stretch  gap-3 px-4 py-[30px]">
                    <p className=" text-sm text-center text-[#373737]">
                        No hay entradas para mostrar en esta pestaña.
                    </p>
                    <Link to={"../../contact-us"}>
                        <p className="text-sm text-center text-[#d80027] hover:underline">
                            Haga clic aquí para abrir un nuevo ticket.
                        </p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default EmptyTableSupport;
