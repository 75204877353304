import {
    eachDayOfInterval,
    endOfMonth,
    endOfWeek,
    format,
    getDay,
    isEqual,
    isSameMonth,
    isToday,
    parse,
    startOfToday,
    startOfWeek
} from "date-fns";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

let colStartClasses = [
    "",
    "col-start-2",
    "col-start-3",
    "col-start-4",
    "col-start-5",
    "col-start-6",
    "col-start-7"
];

const Calender = ({ selectedMonth, selectedYear }) => {
    let today = startOfToday();
    let [selectedDay, setSelectedDay] = useState(today);
    // console.log(currentMonth)
    let propDate = `${selectedMonth}-${selectedYear}`;
    console.log(propDate);

    let firstDaySelectedMonth = parse(
        `${selectedMonth}-${selectedYear}`,
        "MMM-yyyy",
        new Date()
    );
    console.log(firstDaySelectedMonth);

    let days = eachDayOfInterval({
        start: startOfWeek(firstDaySelectedMonth),
        end: endOfWeek(endOfMonth(firstDaySelectedMonth))
    });

    return (
        <div className="">
            <div className="">
                <div className="">
                    <div className="grid grid-cols-7 text-xs leading-6 text-center">
                        <div>S</div>
                        <div>M</div>
                        <div>T</div>
                        <div>W</div>
                        <div>T</div>
                        <div>F</div>
                        <div>S</div>
                    </div>
                    <div className="grid grid-cols-7 text-sm">
                        {days.map((day, dayIdx) => (
                            <div
                                key={day.toString()}
                                className={twMerge(
                                    dayIdx === 0 &&
                                        colStartClasses[getDay(day)],
                                    "py-1.5"
                                )}
                            >
                                <button
                                    type="button"
                                    onClick={() => setSelectedDay(day)}
                                    className={twMerge(
                                        isEqual(day, selectedDay) &&
                                            "text-white",
                                        !isEqual(day, selectedDay) &&
                                            isToday(day) &&
                                            "border border-red-500",
                                        !isEqual(day, selectedDay) &&
                                            !isToday(day) &&
                                            isSameMonth(
                                                day,
                                                firstDaySelectedMonth
                                            ) &&
                                            "text-gray-900",
                                        !isEqual(day, selectedDay) &&
                                            !isToday(day) &&
                                            !isSameMonth(
                                                day,
                                                firstDaySelectedMonth
                                            ) &&
                                            "text-gray-400 pointer-events-none",
                                        isEqual(day, selectedDay) &&
                                            isToday(day) &&
                                            "bg-red-500",
                                        isEqual(day, selectedDay) &&
                                            !isToday(day) &&
                                            "bg-red-500",
                                        !isEqual(day, selectedDay) &&
                                            "hover:bg-gray-200",
                                        "mx-auto flex h-6 w-8 items-center justify-center "
                                    )}
                                >
                                    <time dateTime={format(day, "yyyy-MM-dd")}>
                                        {format(day, "d")}
                                    </time>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calender;
