import registerLeft from "../../assets/images/registerLeft.png";
import { Button } from "../ui/buttons/Button";

function SecondHero() {
    return (
        <div className="flex flex-col lg:h-screen  lg:flex-row justify-between items-center   relative gap-[59px] lg:p-0 w-full my-[100px] lg:my-0   ">
            <div className="flex flex-col justify-start items-start flex-grow relative gap-5 w-[80vw] lg:w-[40%]  ">
                {/* w-[525.19px] */}
                <p className="font-[Satoshi] self-stretch text-[31px]   lg:text-[61px] font-bold text-left text-[#373737]">
                    Si eres un{" "}
                    <span className="relative">
                        profesional
                        <svg
                            width={314}
                            height={37}
                            viewBox="0 0 314 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute flex-grow-0 flex-shrink-0 top-[33px] left-[-10px] lg:left-[2px] lg:top-[62px] w-[200px] lg:w-auto"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M311.578 17.9498C239.549 0.102983 25.9505 -13.4976 1.57812 34.5235"
                                stroke="#D80027"
                                strokeWidth={3}
                                stroke-linecap="round"
                            />
                        </svg>
                    </span>{" "}
                    del derecho estas en el lugar indicado
                </p>
                {/* w-[525.19px] */}
                <p className=" self-stretch   text-[21px] text-left text-[#7b7b7b]">
                    {/* w-[525.19px] */}
                    <span className="self-stretch   text-[21px] text-left text-[#7b7b7b]">
                        Independientemente de tu especialización, en EPROF lo
                        puedes encontrar. Completa tu perfil para que podamos
                        encontrar los trabajos adecuados para ti.
                    </span>
                    <br />
                </p>
                <Button
                // className="transition-all duration-500 cursor-pointer hover:opacity-80 flex justify-center items-center   text-lg text-left text-white  h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]"
                >
                    Busca trabajo
                </Button>
            </div>
            <img
                src={registerLeft}
                alt="registerLeft"
                className="w-[70vw] lg:w-[30vw] object-contain "
            />
        </div>
    );
}

export default SecondHero;
