import Pagination from "../components/Pagination";
import BlogPost from "../components/blog/BlogPost";
import Hero from "../components/blog/Hero";
import Search from "../components/blog/Search";
import Network from "../components/landing/Network";
import React from "react";

function Blog() {
    const blogSearchCards = [];
    for (let index = 0; index < 12; index++) {
        blogSearchCards.push(<BlogPost />);
    }
    return (
        <div className="overflow-x-hidden">
            <Hero />
            <Search />
            <div className="flex flex-col items-center mt-[100px] ">
                <div className="mx-[7%] grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-[50px] sm:gap-[100px]  ">
                    {blogSearchCards}
                </div>
                <div className="my-10">
                    <Pagination />
                </div>
            </div>
            <div className="px-[7%] pb-[100px]">
                <Network />
            </div>
        </div>
    );
}

export default Blog;
