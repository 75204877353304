import React from "react";

function ReturnButton({ onClick = () => {} }) {
    return (
        <div
            onClick={onClick}
            className="flex mt-2 cursor-pointer hover:opacity-80 h-fit "
        >
            <svg
                width={12}
                height={12}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative w-5 h-5 mr-3"
                preserveAspectRatio="none"
            >
                <path
                    d="M9.57 5.92999L3.5 12L9.57 18.07"
                    stroke="#292929"
                    strokeWidth="1.5"
                    stroke-miterlimit={10}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M20.4999 12H3.66992"
                    stroke="#292929"
                    strokeWidth="1.5"
                    stroke-miterlimit={10}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            <p className=" text-sm text-left text-[#d80027]"> Volver</p>
        </div>
    );
}

export default ReturnButton;
