import React from 'react'
import { JobContent } from '../../platform/JobDetailSection'
import { useOutletContext } from "react-router-dom";

const Details = () => {
    const data = useOutletContext();

  return <JobContent data={data}/>
}

export default Details
