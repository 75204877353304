import React from "react";

import "./checkboxStyles.css";
import { noop } from "../../utils/noop";

function Checkbox({
    checked = false,
    toggleCheck = noop,
    text = "Checkbox",
    customElm = undefined
}) {
    return (
        <label className="checkBoxContainer">
            {customElm ?? (
                <span className="  text-base text-left text-[#373737] flex items-center ">
                    {text}
                </span>
            )}
            <input
                onClick={toggleCheck}
                type="checkbox"
                checked={checked && "checked"}
            />
            <span className="checkmark"></span>
        </label>
    );
}

export default Checkbox;
