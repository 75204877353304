import { useCallback, useRef, useState } from "react";

import Webcam from "react-webcam";
import { noop } from "../../utils/noop";
import Dialog from "../Dialog";
import InfoHover from "../ui/InfoHover";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";

const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: "user"
};

function UploadImage({
    cameraFeature = false,
    setFormData = noop,
    label = "Foto de perfil",
    info = "Image should be clear",
    alignLeft = false
}) {
    const [file, setFile] = useState();
    // const [uploadedFile, setUploadedFile] = useState();
    const [error] = useState("");

    const [showCamDialog, setCamDialog] = useState(0);

    const fileUploadRef = useRef(null);

    // take photo via camera
    const [picture, setPicture] = useState("");
    const webcamRef = useRef(null);
    const setFileHandle = useCallback(
        (file) => {
            setFile(file);
            setFormData((data) => ({ ...data, ...{ profileImg: file } }));
        },
        [setFormData]
    );
    const capture = useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot();
        setPicture(pictureSrc);
        setFileHandle(pictureSrc);
        console.log(pictureSrc);
    }, [setFileHandle]);
    const fileHandler = useCallback(
        (event) => {
            const tempFile = event.target.files[0];
            setFileHandle(tempFile);
            console.log(event.target.files);
        },
        [setFileHandle]
    );
    const handleFileUpload = useCallback(() => {
        fileUploadRef.current.click();
    }, []);
    const handleRemoveFile = useCallback(() => {
        setFile(null);
        setFormData((data) => ({ ...data, ...{ profileImg: null } }));
    }, [setFormData]);

    const handleCameraTakeProfileImg = useCallback(() => {
        setCamDialog(1);
    }, []);

    return (
        <>
            {Boolean(cameraFeature && showCamDialog) && (
                <Dialog
                    showDialog={showCamDialog}
                    setDialog={setCamDialog}
                    main={
                        <div className="px-10 py-3 bg-white ">
                            <div className="flex flex-col items-center justify-center">
                                <h2 className="m-10">Take a photo to upload</h2>
                                <Webcam
                                    audio={false}
                                    height={400}
                                    ref={webcamRef}
                                    width={400}
                                    screenshotFormat="image/jpeg"
                                    name="webcamImage"
                                    videoConstraints={videoConstraints}
                                />
                                {picture && (
                                    <img
                                        src={picture}
                                        alt={"webcamImg"}
                                        className="w-[100px] object-contain m-3 "
                                    />
                                )}
                            </div>
                            <div className="flex items-center justify-center gap-5 m-5">
                                <Button
                                    size={SIZE.MEDIUM}
                                    color={COLOR.WHITE}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        // closeDialog();
                                        setCamDialog(0);
                                    }}
                                >
                                    Close
                                </Button>
                                {picture ? (
                                    <Button
                                        size={SIZE.MEDIUM}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setPicture();
                                        }}
                                    >
                                        Retake
                                    </Button>
                                ) : (
                                    <Button
                                        size={SIZE.MEDIUM}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            capture();
                                        }}
                                    >
                                        Capture
                                    </Button>
                                )}
                            </div>
                        </div>
                    }
                />
            )}
            <div className="flex flex-col justify-start items-start self-stretch flex-grow gap-2.5">
                <div className="flex flex-col justify-start items-start self-stretch   gap-[5px]">
                    <div className="flex justify-start items-center   relative gap-[5px]">
                        {Boolean(info) && (
                            <InfoHover text={info} />
                            // <div className="relative w-6 h-6 overflow-hidden ">
                            //     <img src={infoSvg} alt="info" />
                            // </div>
                        )}
                        <p className="  text-sm text-left text-[#373737]">
                            {label}
                        </p>
                    </div>
                    <div className="flex justify-start items-start self-stretch   relative gap-2.5">
                        <div
                            onClick={handleFileUpload}
                            className="cursor-pointer hover:opacity-80 flex-grow h-11 relative overflow-hidden border border-[#cdcdcd] border-dashed"
                        >
                            <input
                                accept="image/png, image/jpg, image/jpeg"
                                type="file"
                                className="hidden"
                                ref={fileUploadRef}
                                onChange={fileHandler}
                            />
                            <div className="w-full  h-11 absolute left-[-0.86px] top-[-0.86px] opacity-50 bg-black/5" />
                            <div
                                className={`flex  justify-center items-center absolute left-[20px]  top-[9.73px] gap-[5px] ${
                                    alignLeft ? " " : "sm:left-[96.88px]"
                                }`}
                            >
                                <svg
                                    width={25}
                                    height={25}
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6 "
                                    preserveAspectRatio="none"
                                >
                                    <g clipPath="url(#clip0_10095_7095)">
                                        <path
                                            d="M20.8789 3.13574C21.9835 3.13574 22.8789 4.03117 22.8789 5.13574V19.1357C22.8789 20.2403 21.9835 21.1357 20.8789 21.1357H4.87891C3.77434 21.1357 2.87891 20.2403 2.87891 19.1357V5.13574C2.87891 4.03117 3.77434 3.13574 4.87891 3.13574H20.8789ZM20.8789 5.13574H4.87891V19.1357H5.80786L15.1163 9.82725C15.6045 9.3391 16.396 9.3391 16.8841 9.82725L20.8789 13.822V5.13574ZM16.0002 11.7718L8.63629 19.1357H20.8789V16.6504L16.0002 11.7718ZM8.37891 7.13574C9.20734 7.13574 9.87891 7.80731 9.87891 8.63574C9.87891 9.46417 9.20734 10.1357 8.37891 10.1357C7.55048 10.1357 6.87891 9.46417 6.87891 8.63574C6.87891 7.80731 7.55048 7.13574 8.37891 7.13574Z"
                                            fill="#A4A4A4"
                                        />
                                    </g>
                                    <defs>
                                        <clippath id="clip0_10095_7095">
                                            <rect
                                                width={24}
                                                height={24}
                                                fill="white"
                                                transform="translate(0.878906 0.135742)"
                                            />
                                        </clippath>
                                    </defs>
                                </svg>
                                <div className="flex flex-col justify-start items-center   relative gap-[7.290872097015381px]">
                                    <p className="  text-[11.665395736694336px] font-medium text-left text-[#373737]">
                                        Añadir imagen o Archivo
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* CAMERA BUTTON */}
                        {cameraFeature && (
                            <div
                                onClick={handleCameraTakeProfileImg}
                                className="cursor-pointer hover:opacity-80 flex justify-start items-start   relative gap-2.5 p-2.5 bg-[#292929]"
                            >
                                <svg
                                    width={24}
                                    height={25}
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6 "
                                    preserveAspectRatio="none"
                                >
                                    <g clipPath="url(#clip0_10095_7105)">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M14.7929 3.40845C15.141 3.40845 15.4766 3.52945 15.7435 3.74815L15.8536 3.84779L17.4142 5.40845H20C21.0544 5.40845 21.9182 6.22432 21.9945 7.25918L22 7.40845V19.4084C22 20.4628 21.1841 21.3266 20.1493 21.403L20 21.4084H4C2.94564 21.4084 2.08183 20.5926 2.00549 19.5577L2 19.4084V7.40845C2 6.35408 2.81588 5.49028 3.85074 5.41393L4 5.40845H6.58579L8.14645 3.84779C8.39259 3.60164 8.71546 3.44988 9.05885 3.41579L9.20711 3.40845H14.7929ZM14.5858 5.40845H9.41421L7.85355 6.96911C7.60741 7.21524 7.28454 7.36701 6.94115 7.40111L6.79289 7.40845H4V19.4084H20V7.40845H17.2071C16.859 7.40845 16.5234 7.28746 16.2565 7.06875L16.1464 6.96911L14.5858 5.40845ZM12 7.90845C14.7614 7.90845 17 10.147 17 12.9084C17 15.6698 14.7614 17.9084 12 17.9084C9.23858 17.9084 7 15.6698 7 12.9084C7 10.147 9.23858 7.90845 12 7.90845ZM12 9.90845C10.3431 9.90845 9 11.2515 9 12.9084C9 14.5653 10.3431 15.9084 12 15.9084C13.6569 15.9084 15 14.5653 15 12.9084C15 11.2515 13.6569 9.90845 12 9.90845Z"
                                            fill="#F2F2F2"
                                        />
                                    </g>
                                    <defs>
                                        <clippath id="clip0_10095_7105">
                                            <rect
                                                width={24}
                                                height={24}
                                                fill="white"
                                                transform="translate(0 0.408447)"
                                            />
                                        </clippath>
                                    </defs>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
                {/* FILES LIST */}
                <div className="flex justify-start items-center self-stretch   relative gap-2.5">
                    {file ? (
                        <>
                            <p className="flex-grow w-[328px] text-sm text-left text-[#c10434]">
                                {file.name ?? "uploadedImage.jpg"}
                            </p>
                            <svg
                                onClick={handleRemoveFile}
                                width={24}
                                height={25}
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 cursor-pointer hover:opacity-80"
                                preserveAspectRatio="none"
                            >
                                <g clipPath="url(#clip0_10095_7108)">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.0002 13.8228L17.657 19.4797C18.0475 19.8702 18.6807 19.8702 19.0712 19.4797C19.4617 19.0891 19.4617 18.456 19.0712 18.0654L13.4144 12.4086L19.0712 6.7518C19.4617 6.36128 19.4617 5.72811 19.0712 5.33759C18.6807 4.94706 18.0475 4.94706 17.657 5.33759L12.0002 10.9944L6.34333 5.33757C5.95281 4.94705 5.31964 4.94705 4.92912 5.33757C4.5386 5.7281 4.5386 6.36126 4.92912 6.75179L10.5859 12.4086L4.92912 18.0655C4.5386 18.456 4.5386 19.0891 4.92912 19.4797C5.31964 19.8702 5.95281 19.8702 6.34333 19.4797L12.0002 13.8228Z"
                                        fill="#A4A4A4"
                                    />
                                </g>
                                <defs>
                                    <clippath id="clip0_10095_7108">
                                        <rect
                                            width={24}
                                            height={24}
                                            fill="white"
                                            transform="translate(0 0.408447)"
                                        />
                                    </clippath>
                                </defs>
                            </svg>
                        </>
                    ) : (
                        <>
                            <p className="flex-grow w-[328px] text-sm text-left text-[#c10434] " />
                            <span className="relative w-6 cursor-pointer hover:opacity-80" />
                        </>
                    )}
                    {error && <p>Error uploading file: {`profileImg.jpg`}</p>}
                </div>
            </div>
        </>
    );
}

export default UploadImage;
