import BlogContent from "../components/blog-preview/BlogContent";
import Hero from "../components/blog-preview/Hero";
import Blog from "../components/landing/Blog";
import React from "react";

function BlogPreview() {
    return (
        <div>
            <Hero />
            <div className="px-[5%] sm:px-[7%]">
                <BlogContent />
                <Blog
                    title="Artículos destacados"
                    desc="Encuentra los artículos relacionados a tu sector y entérate de las ultimas noticias."
                />
            </div>
        </div>
    );
}

export default BlogPreview;
