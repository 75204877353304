import React from "react";
import { Button } from "../ui/buttons/Button";
import { Link } from "react-router-dom";

function Foot({
    title = "Entonces, ¿qué estás esperando?",
    desc = "Publica un proyecto hoy y recibe ofertas de muchos profesionales",
    button = "Contrata un profesional",
    link = "/professionals"
}) {
    return (
        <div className=" flex flex-col justify-start items-center  gap-7 p-[30px] bg-[#ffecef] w-full my-[100px]">
            <div className="flex flex-col justify-start items-center  relative gap-2.5">
                <p className="self-stretch  font-[Satoshi] text-lg lg:text-5xl font-bold text-center text-[#373737]">
                    {title}
                </p>
                <p className="self-stretch text-md  lg:text-[21px] text-center text-[#373737]">
                    {desc}
                </p>
            </div>
            <div className="flex flex-col justify-start items-start  gap-[5px]">
                <Link to={link}>
                    <Button>{button}</Button>
                </Link>
            </div>
        </div>
    );
}

export default Foot;
