import React from "react";
import { Button, COLOR, SIZE } from "../../ui/buttons/Button";
import { noop } from "../../../utils/noop";

const PaypalModal = ({ setShowDialog, setPagoExitosModal = noop }) => {

    const handleConfirm = () => {
        setPagoExitosModal(true)
        setShowDialog(false)
    }
    return (
        <div>
            <div
                className="flex flex-col justify-start items-center w-[594px] relative gap-[30px] p-[30px] bg-white"
                style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
            >
                <div className="flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-[30px]">
                    <p className="self-stretch flex-grow-0 flex-shrink-0 w-[534px] text-base font-bold text-left text-[#373737]">
                        Autorizar pago
                    </p>
                    <p className="self-stretch flex-grow-0 flex-shrink-0 w-[534px] text-base font-bold text-left">
                        <span className="self-stretch flex-grow-0 flex-shrink-0 w-[534px] text-base font-bold text-left text-[#373737]">
                            Importe a pagar:{" "}
                        </span>
                        <span className="self-stretch flex-grow-0 flex-shrink-0 w-[534px] text-base font-bold text-left text-[#1f6bff]">
                            $5.00 USD
                        </span>
                    </p>
                    <div className="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px]">
                        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[534px] text-base text-left text-[#373737]">
                            Esto será debitado de tu método de pago verificado.
                        </p>
                        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[534px] text-base font-bold text-left text-[#373737]">
                            Método de pago verificado:
                        </p>
                    </div>
                    <div className="flex flex-col justify-center items-start self-stretch flex-grow-0 flex-shrink-0 gap-[5px]">
                        <div className="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 relative gap-2.5">
                            <svg
                                width="98"
                                height="27"
                                viewBox="0 0 98 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0 w-[98px] h-[26.13px]"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M16.5885 1.26767C15.3602 0.42324 13.7573 0 11.7799 0H4.12443C3.51823 0 3.18333 0.303169 3.11971 0.908779L0.00976161 20.4299C-0.022559 20.6216 0.0254099 20.797 0.15323 20.9564C0.280319 21.1162 0.440167 21.1956 0.631605 21.1956H4.2679C4.90568 21.1956 5.25624 20.8932 5.32059 20.2865L6.18169 15.0238C6.21313 14.7688 6.32531 14.5614 6.51674 14.4017C6.70803 14.2423 6.94729 14.1379 7.23438 14.0902C7.52146 14.0428 7.79217 14.019 8.04781 14.019C8.30272 14.019 8.60559 14.0351 8.95703 14.0668C9.30758 14.0987 9.53105 14.1142 9.62684 14.1142C12.3699 14.1142 14.5229 13.3413 16.0861 11.7938C17.6487 10.247 18.4308 8.10195 18.4308 5.35835C18.4308 3.47644 17.8162 2.11283 16.5885 1.26708V1.26767ZM12.6413 7.12926C12.4813 8.24586 12.0671 8.97915 11.3973 9.33014C10.7273 9.68157 9.77045 9.85619 8.52662 9.85619L6.94759 9.90416L7.76116 4.78448C7.82463 4.43393 8.03187 4.25843 8.38301 4.25843H9.29237C10.5676 4.25843 11.4932 4.44226 12.0674 4.80846C12.6413 5.17555 12.8327 5.94948 12.6413 7.12926Z"
                                    fill="#003087"
                                ></path>
                                <path
                                    d="M97.3791 0H93.8388C93.4871 0 93.28 0.175499 93.2168 0.526346L90.1066 20.4306L90.0586 20.5262C90.0586 20.6865 90.1225 20.8373 90.2503 20.9809C90.3773 21.1241 90.5374 21.1961 90.7287 21.1961H93.8869C94.4921 21.1961 94.8271 20.8936 94.8918 20.2871L98.0017 0.717782V0.670253C98.0014 0.22376 97.7935 0.000439617 97.3791 0.000439617V0Z"
                                    fill="#009CDE"
                                ></path>
                                <path
                                    d="M54.6034 7.70497C54.6034 7.54585 54.5393 7.39405 54.4124 7.25072C54.2844 7.10726 54.1408 7.03516 53.9817 7.03516H50.2976C49.946 7.03516 49.6591 7.19544 49.4362 7.51353L44.3645 14.978L42.2594 7.80091C42.0991 7.2908 41.7485 7.03516 41.2067 7.03516H37.6178C37.4579 7.03516 37.3143 7.10711 37.1875 7.25072C37.0596 7.39405 36.9961 7.546 36.9961 7.70497C36.9961 7.76932 37.3072 8.70983 37.929 10.528C38.5509 12.3464 39.2208 14.3082 39.9386 16.4136C40.6564 18.5186 41.0308 19.6355 41.0629 19.7623C38.4472 23.3351 37.1397 25.2492 37.1397 25.504C37.1397 25.919 37.3469 26.1261 37.7617 26.1261H41.4458C41.7967 26.1261 42.0836 25.967 42.3072 25.6478L54.5082 8.03958C54.5715 7.97626 54.6034 7.86511 54.6034 7.70482V7.70497Z"
                                    fill="#003087"
                                ></path>
                                <path
                                    d="M88.9093 7.03427H85.2728C84.8259 7.03427 84.5556 7.56061 84.4598 8.6133C83.6296 7.33803 82.1153 6.69922 79.914 6.69922C77.6174 6.69922 75.6634 7.56062 74.0529 9.28312C72.4418 11.0058 71.6367 13.0316 71.6367 15.3598C71.6367 17.2422 72.1868 18.7412 73.2871 19.8572C74.3875 20.9744 75.8627 21.5319 77.7134 21.5319C78.6382 21.5319 79.5791 21.3402 80.536 20.9577C81.4931 20.575 82.242 20.0649 82.7852 19.4267C82.7852 19.4587 82.7527 19.6019 82.6895 19.8569C82.6252 20.1126 82.5937 20.3045 82.5937 20.4311C82.5937 20.9421 82.8005 21.1966 83.2159 21.1966H86.5175C87.1227 21.1966 87.4741 20.8941 87.5695 20.2875L89.5314 7.79958C89.5628 7.60815 89.5152 7.43294 89.3879 7.27324C89.2598 7.11427 89.1007 7.03427 88.9093 7.03427ZM82.6654 16.4602C81.8519 17.2577 80.8711 17.6564 79.7229 17.6564C78.7974 17.6564 78.0485 17.4016 77.474 16.8909C76.8996 16.3815 76.6125 15.6797 76.6125 14.7855C76.6125 13.6062 77.0113 12.6088 77.8089 11.7954C78.6054 10.9819 79.5952 10.5752 80.7754 10.5752C81.6678 10.5752 82.4096 10.8385 83.0001 11.3645C83.5898 11.8909 83.8857 12.6168 83.8857 13.5418C83.8855 14.69 83.4787 15.6632 82.6654 16.4602Z"
                                    fill="#009CDE"
                                ></path>
                                <path
                                    d="M34.5541 7.03427H30.9178C30.4706 7.03427 30.2 7.56061 30.1042 8.6133C29.243 7.33803 27.7277 6.69922 25.5587 6.69922C23.262 6.69922 21.308 7.56062 19.6974 9.28312C18.0863 11.0058 17.2812 13.0316 17.2812 15.3598C17.2812 17.2422 17.8316 18.7412 18.9321 19.8572C20.0326 20.9744 21.5075 21.5319 23.3578 21.5319C24.2505 21.5319 25.1761 21.3402 26.1329 20.9577C27.0898 20.575 27.8554 20.0649 28.4295 19.4267C28.3016 19.8093 28.2381 20.1443 28.2381 20.4311C28.2381 20.9421 28.4453 21.1966 28.8601 21.1966H32.1615C32.7671 21.1966 33.1184 20.8941 33.2142 20.2875L35.1758 7.79958C35.2072 7.60815 35.1594 7.43294 35.0323 7.27324C34.9046 7.11427 34.7454 7.03427 34.5541 7.03427ZM28.3101 16.4839C27.4965 17.2665 26.4992 17.6564 25.3197 17.6564C24.3941 17.6564 23.6525 17.4016 23.0947 16.8909C22.5362 16.3815 22.2573 15.6797 22.2573 14.7855C22.2573 13.6062 22.6558 12.6088 23.4536 11.7954C24.2505 10.9819 25.2394 10.5752 26.4201 10.5752C27.3128 10.5752 28.0544 10.8385 28.6451 11.3647C29.2348 11.891 29.5302 12.617 29.5302 13.542C29.5302 14.7221 29.1235 15.7032 28.3101 16.4839Z"
                                    fill="#003087"
                                ></path>
                                <path
                                    d="M70.9441 1.26767C69.7159 0.42324 68.1134 0 66.1357 0H58.5279C57.8897 0 57.5385 0.303169 57.4753 0.908779L54.3652 20.4299C54.3329 20.6217 54.3807 20.7971 54.5087 20.9565C54.6352 21.1162 54.7956 21.1956 54.9871 21.1956H58.9103C59.293 21.1956 59.5481 20.9885 59.6759 20.5738L60.5373 15.0238C60.5689 14.7688 60.6806 14.5614 60.8722 14.4017C61.0637 14.2423 61.3026 14.138 61.59 14.0902C61.8769 14.0428 62.1475 14.019 62.4034 14.019C62.6583 14.019 62.9612 14.0351 63.3122 14.0668C63.6631 14.0987 63.8871 14.1142 63.982 14.1142C66.7256 14.1142 68.8782 13.3413 70.4415 11.7938C72.0046 10.247 72.786 8.10195 72.786 5.35835C72.7863 3.47659 72.1719 2.11298 70.9441 1.26723V1.26767ZM66.0399 9.1387C65.338 9.61707 64.2853 9.85619 62.8821 9.85619L61.3509 9.90416L62.1643 4.78448C62.2275 4.43393 62.4347 4.25843 62.7862 4.25843H63.6471C64.3488 4.25843 64.9069 4.29031 65.3222 4.35393C65.7363 4.41828 66.1357 4.61703 66.5184 4.95193C66.9013 5.28699 67.0926 5.77355 67.0926 6.41148C67.0926 7.75111 66.7413 8.66003 66.0399 9.1387Z"
                                    fill="#009CDE"
                                ></path>
                            </svg>
                        </div>
                        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-5">
                            <p className="flex-grow w-[444px] text-base text-left text-[#373737]">
                                lui***********@gmail.com
                            </p>
                            <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#00bc4f]">
                                Preferido
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end items-center self-stretch flex-grow-0 flex-shrink-0 gap-5">
                    {/* <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-11 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]">
                        <p className="flex-grow-0 flex-shrink-0 text-base text-left text-white">
                            Confirmar el pago
                        </p>
                    </div>
                    <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-11 relative gap-2.5 px-8 py-2.5 rounded border-2 border-[#292929]">
                        <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#373737]">
                            Cancelar
                        </p>
                    </div> */}

                    <Button
                        color={COLOR.RED}
                        size={SIZE.MEDIUM}
                        type="submit"
                        addClass={"h-11"}
                        onClick={handleConfirm}
                        // stretch
                    >
                        Confirmar el pago
                    </Button>

                    <Button
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        addClass={"h-11"}
                        onClick={()=> setShowDialog(false)}
                        // stretch
                    >
                        Cancelar
                    </Button>
                </div>
                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-grow-0 flex-shrink-0 w-[30px] h-[30px] absolute left-[564px] top-0 cursor-pointer"
                    preserveAspectRatio="xMidYMid meet"
                    onClick={() => setShowDialog(false)}
                >
                    <path
                        d="M15 5.3125C9.64844 5.3125 5.3125 9.64844 5.3125 15C5.3125 20.3516 9.64844 24.6875 15 24.6875C20.3516 24.6875 24.6875 20.3516 24.6875 15C24.6875 9.64844 20.3516 5.3125 15 5.3125ZM15 22.8125C10.6641 22.8125 7.1875 19.3359 7.1875 15C7.1875 10.7031 10.6641 7.1875 15 7.1875C19.2969 7.1875 22.8125 10.7031 22.8125 15C22.8125 19.3359 19.2969 22.8125 15 22.8125ZM18.9453 12.5781C19.1406 12.4219 19.1406 12.1094 18.9453 11.9141L18.0859 11.0547C17.8906 10.8594 17.5781 10.8594 17.4219 11.0547L15 13.4766L12.5391 11.0547C12.3828 10.8594 12.0703 10.8594 11.875 11.0547L11.0156 11.9141C10.8203 12.1094 10.8203 12.4219 11.0156 12.5781L13.4375 15L11.0156 17.4609C10.8203 17.6172 10.8203 17.9297 11.0156 18.125L11.875 18.9844C12.0703 19.1797 12.3828 19.1797 12.5391 18.9844L15 16.5625L17.4219 18.9844C17.5781 19.1797 17.8906 19.1797 18.0859 18.9844L18.9453 18.125C19.1406 17.9297 19.1406 17.6172 18.9453 17.4609L16.5234 15L18.9453 12.5781Z"
                        fill="#333333"
                    ></path>
                </svg>
            </div>
        </div>
    );
};

export default PaypalModal;
