import React from "react";
import { noop } from "../utils/noop";

function Dialog({
    showDialog = true,
    setDialog = noop,
    children,
    main = null // ready-made content with bg
}) {

    const closeDialog = () => setDialog(0);

    if (main)
        return (
            <div
                className={`dialog overflow-y-auto max-w-[100vw] overflow-x-hidden   dialog fixed top-0 left-0   min-h-full items-center justify-center w-full ${
                    !showDialog && "hidden"
                } z-20`}
            >
                <div className="flex max-w-[100vw] overflow-x-hidden overflow-y-auto flex-col justify-center items-center h-screen dialog__inner">
                    <div
                        className="absolute max-w-[100vw] overflow-x-hidden  w-full min-h-full dialog__mask dialog__bg bg-slate-400 opacity-20 "
                        onClick={closeDialog}
                    />
                    <div className="relative z-20 opacity-100 dialog__mainContent">
                        {main}
                    </div>
                </div>
            </div>
        );

    return (
        <div
            className={`overflow-y-auto   dialog fixed top-0 left-0 min-h-full items-center justify-center w-full ${
                !showDialog && "hidden"
            } z-10`}
        >
            <div
                className="absolute w-full min-h-full opacity-20 dialog__bg bg-slate-400"
                onClick={closeDialog}
            />
            <div className="flex relative z-20 flex-col justify-center p-10 m-10 bg-white rounded-3xl opacity-100 dialog__content">
                {children}
            </div>
        </div>
    );
}

export default Dialog;
