import checkedSvg from "../assets/images/checked.svg";
import uncheckedSvg from "../assets/images/unchecked.svg";

import dropdownSvg from "../assets/images/dropdown.svg";

import tickSvg from "../assets/images/tick.svg";
import wrongSvg from "../assets/images/wrong.svg";

import whatsappSvg from "../assets/images/whatsapp.svg";

import infoSvg from "./../assets/icons/info.svg";
import infoRedSvg from "./../assets/images/infoRed.svg";

import attachmentSvg from "./../assets/images/attachment.svg";
import phoneSvg from "./../assets/images/phone.svg";
import phoneBlackSvg from "./../assets/images/phoneBlack.svg";
import thumbsDownSvg from "./../assets/images/thumbsDown.svg";

import arrowRightSvg from "./../assets/images/arrowRight.svg";

import bellSvg from "./../assets/images/bell.svg";
import copySvg from "./../assets/images/copy.svg";
import dollarWhiteSvg from "./../assets/images/dollarWhite.svg";
import filterSvg from "./../assets/images/filter.svg";
import idCardSvg from "./../assets/images/idCard.svg";
import mapSvg from "./../assets/images/map.svg";
import ExtlinkSvg from "./../assets/images/blogs/link.svg";




const VideoCam = () => (
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="relative flex-grow-0 flex-shrink-0 w-6 h-6"
        preserveAspectRatio="xMidYMid meet"
    >
        <path
            d="M20.4688 7C20.2812 7 20.0938 7.0625 19.9062 7.1875L16.5 9.34375V7.5C16.5 6.6875 15.75 6 14.875 6H5.09375C4.21875 6 3.5 6.6875 3.5 7.5V16.5312C3.5 17.3438 4.21875 18 5.09375 18H14.875C15.75 18 16.5 17.3438 16.5 16.5312V14.6875L19.9062 16.8438C20.0938 16.9688 20.2812 17 20.4688 17C21 17 21.5 16.5938 21.5 16.0312V8C21.5 7.40625 21 7 20.4688 7ZM15 10.25V16.4688C14.9688 16.5 14.9375 16.5 14.875 16.5H5.09375C5.03125 16.5 5 16.5 5 16.4688V7.5625C5 7.53125 5.03125 7.5 5.09375 7.5H14.875C14.9375 7.5 14.9688 7.53125 15 7.5625V10.25ZM20 15.125L16.5 12.9375V11.0938L20 8.90625V15.125Z"
            fill="#F2F2F2"
        ></path>
    </svg>
);

const likeFilled = ({ height = "20px", width = "20px" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#b10000"
        height={height}
        width={width}
        viewBox="0 0 512 512"
    >
        <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
    </svg>
);

const disliked = ({ height = "20px", width = "20px" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        height={height}
        width={width}
        viewBox="0 0 512 512"
    >
        <path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.1s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z" />
    </svg>
);

const ArrowIcon = ({ color, height, width }) => (
    <svg
        width={width || 21}
        height={height || 20}
        viewBox="0 0 21 20"
        // fill="none"

        xmlns="http://www.w3.org/2000/svg"
        className="relative w-5 h-5"
        preserveAspectRatio="xMidYMid meet"
    >
        <path
            d="M12.3483 6.35417L11.8275 6.87501C11.7233 7.00521 11.7233 7.18751 11.8535 7.31771L13.9368 9.32292H4.97852C4.79622 9.32292 4.66602 9.47917 4.66602 9.63542V10.3646C4.66602 10.5469 4.79622 10.6771 4.97852 10.6771H13.9368L11.8535 12.7083C11.7233 12.8385 11.7233 13.0208 11.8275 13.151L12.3483 13.6719C12.4785 13.776 12.6608 13.776 12.791 13.6719L16.2285 10.2344C16.3327 10.1042 16.3327 9.92188 16.2285 9.79167L12.791 6.35417C12.6608 6.25001 12.4785 6.25001 12.3483 6.35417Z"
            fill={color}
        />
    </svg>
);

const BookIcon = ({ color, height, width }) => (
    <svg
        width={width || 21}
        height={height || 20}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="relative w-5 h-5"
        preserveAspectRatio="xMidYMid meet"
    >
        <path
            d="M7.99935 7.29166C7.99935 7.42187 8.07747 7.49999 8.20768 7.49999H13.6243C13.7285 7.49999 13.8327 7.42187 13.8327 7.29166V6.45833C13.8327 6.35416 13.7285 6.24999 13.6243 6.24999H8.20768C8.07747 6.24999 7.99935 6.35416 7.99935 6.45833V7.29166ZM8.20768 9.58333H13.6243C13.7285 9.58333 13.8327 9.5052 13.8327 9.37499V8.54166C13.8327 8.43749 13.7285 8.33333 13.6243 8.33333H8.20768C8.07747 8.33333 7.99935 8.43749 7.99935 8.54166V9.37499C7.99935 9.5052 8.07747 9.58333 8.20768 9.58333ZM15.9941 13.75C16.1764 13.724 16.3327 13.5417 16.3327 13.3333V3.74999C16.3327 3.54166 16.1243 3.33333 15.916 3.33333H6.74935C5.57747 3.33333 4.66602 4.27083 4.66602 5.41666V14.5833C4.66602 15.7552 5.57747 16.6667 6.74935 16.6667H15.916C16.1243 16.6667 16.3327 16.4844 16.3327 16.25V15.8333C16.3327 15.651 16.1764 15.4687 15.9941 15.4427C15.8639 15.1042 15.8639 14.0885 15.9941 13.75ZM14.9264 13.75C14.8483 14.2187 14.8483 14.974 14.9264 15.4167H6.74935C6.2806 15.4167 5.91602 15.0521 5.91602 14.5833C5.91602 14.1406 6.2806 13.75 6.74935 13.75H14.9264ZM15.0827 4.58333V12.5H6.74935C6.43685 12.5 6.15039 12.5781 5.91602 12.6823V5.41666C5.91602 4.97395 6.2806 4.58333 6.74935 4.58333H15.0827Z"
            fill={color}
        />
    </svg>
);

const PhoneIcon = ({ color, height, width }) => (
    <svg
        width={width || 20}
        height={height || 20}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="relative w-5 h-5"
        preserveAspectRatio="xMidYMid meet"
    >
        <path
            d="M19.125 14.3125L15.9375 12.9688C15.7812 12.9062 15.5938 12.8438 15.375 12.8438C14.9375 12.8438 14.5312 13.0625 14.2812 13.375L13.25 14.625C11.5938 13.7188 10.2812 12.4062 9.375 10.75L10.625 9.71875C10.9375 9.46875 11.1562 9.0625 11.1562 8.625C11.1562 8.40625 11.0938 8.21875 11.0312 8.0625L9.6875 4.875C9.46875 4.375 8.9375 4 8.34375 4C8.25 4 8.15625 4.03125 8.03125 4.0625L5.09375 4.71875C4.46875 4.875 4 5.4375 4 6.125C4 13.8125 10.2188 20.0312 17.875 20C18.5625 20 19.125 19.5312 19.2812 18.9062L19.9375 15.9688C19.9688 15.8438 20 15.75 20 15.6562C20 15.0625 19.625 14.5312 19.125 14.3125ZM17.8125 18.5312C11.0312 18.5 5.53125 12.9688 5.5 6.1875L8.3125 5.53125L9.625 8.59375L7.5 10.3438C8.96875 13.4375 10.5625 15.0312 13.6562 16.5L15.4062 14.375L18.4688 15.6875L17.8125 18.5312Z"
            fill={color}
        />
    </svg>
);

const ShareIcon = ({ color, height, width }) => (
    <svg
        width={width || "15"}
        height={height || "18"}
        viewBox="0 0 15 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_11496_18405)">
            <path
                d="M11.1665 10.8335C10.354 10.8335 9.63525 11.146 9.07275 11.6772L6.01025 9.73975C6.19775 9.17725 6.19775 8.521 6.01025 7.9585L9.07275 6.021C9.63525 6.521 10.354 6.8335 11.1665 6.8335C12.8228 6.8335 14.1665 5.48975 14.1665 3.8335C14.1665 2.17725 12.8228 0.833496 11.1665 0.833496C9.51025 0.833496 8.1665 2.17725 8.1665 3.8335C8.1665 4.146 8.19775 4.4585 8.2915 4.73975L5.229 6.67725C4.6665 6.146 3.94775 5.8335 3.1665 5.8335C1.51025 5.8335 0.166504 7.17725 0.166504 8.8335C0.166504 10.4897 1.51025 11.8335 3.1665 11.8335C3.94775 11.8335 4.6665 11.521 5.229 11.021L8.2915 12.9585C8.19775 13.2397 8.1665 13.5522 8.1665 13.8335C8.1665 15.4897 9.51025 16.8335 11.1665 16.8335C12.8228 16.8335 14.1665 15.4897 14.1665 13.8335C14.1665 12.1772 12.8228 10.8335 11.1665 10.8335ZM11.1665 2.3335C11.979 2.3335 12.6665 3.021 12.6665 3.8335C12.6665 4.67725 11.979 5.3335 11.1665 5.3335C10.3228 5.3335 9.6665 4.67725 9.6665 3.8335C9.6665 3.021 10.3228 2.3335 11.1665 2.3335ZM3.1665 10.3335C2.32275 10.3335 1.6665 9.67725 1.6665 8.8335C1.6665 8.021 2.32275 7.3335 3.1665 7.3335C3.979 7.3335 4.6665 8.021 4.6665 8.8335C4.6665 9.67725 3.979 10.3335 3.1665 10.3335ZM11.1665 15.3335C10.3228 15.3335 9.6665 14.6772 9.6665 13.8335C9.6665 13.021 10.3228 12.3335 11.1665 12.3335C11.979 12.3335 12.6665 13.021 12.6665 13.8335C12.6665 14.6772 11.979 15.3335 11.1665 15.3335Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_11496_18405">
                <rect
                    width="15"
                    height="17"
                    fill={color}
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);

const BellSilentIcon = ({ color, height, width }) => (
    <svg
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="relative w-6 h-6"
        preserveAspectRatio="none"
    >
        <path
            d="M21.7811 18.75L3.12494 4.125C2.90619 3.9375 2.59369 4 2.40619 4.1875L2.09369 4.59375C1.90619 4.8125 1.96869 5.125 2.18744 5.28125L20.8749 19.9062C21.0624 20.0938 21.3749 20.0312 21.5624 19.8125L21.8749 19.4375C22.0624 19.2188 21.9999 18.9062 21.7811 18.75ZM7.09369 15.5C7.62494 14.8125 8.18744 13.7812 8.37494 12.0625L6.96869 10.9375C6.87494 13.7812 5.81244 14.7188 5.24994 15.3438C5.06244 15.5312 4.96869 15.7812 4.99994 16C4.99994 16.5312 5.37494 17 5.99994 17H14.7187L12.8124 15.5H7.09369ZM11.9999 7C13.9061 7 15.4999 8.59375 15.4999 10.5C15.4999 10.5312 15.4687 10.5312 15.4687 10.5312C15.4687 11.0625 15.5311 11.5312 15.5624 11.9688L17.4374 13.4062C17.1561 12.7188 16.9687 11.8125 16.9687 10.5C16.9687 8.09375 15.2811 6.15625 12.9687 5.65625V5C12.9687 4.46875 12.5311 4 11.9999 4C11.4374 4 10.9999 4.46875 10.9999 5V5.65625C10.1874 5.84375 9.43744 6.1875 8.81244 6.6875L10.0311 7.625C10.5937 7.25 11.2499 7 11.9999 7ZM11.9999 20C13.0937 20 13.9687 19.125 13.9687 18H9.99994C9.99994 19.125 10.8749 20 11.9999 20Z"
            fill={color}
        />
    </svg>
);
const PlusIcon = ({ color, height, width }) => (
    <svg
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="relative w-6 h-6"
        preserveAspectRatio="none"
    >
        <path
            d="M17.5 11H13V6.5C13 6.25 12.75 6 12.5 6H11.5C11.2188 6 11 6.25 11 6.5V11H6.5C6.21875 11 6 11.25 6 11.5V12.5C6 12.7812 6.21875 13 6.5 13H11V17.5C11 17.7812 11.2188 18 11.5 18H12.5C12.75 18 13 17.7812 13 17.5V13H17.5C17.75 13 18 12.7812 18 12.5V11.5C18 11.25 17.75 11 17.5 11Z"
            fill={color}
        />
    </svg>
);

const CopyIcon = ({ color, height, width }) => (
    <svg
        width={width || 24}
        height={height || 24}
        viewBox="0 0 20 21"
        fill="none"
        // className="relative w-6 h-6"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.6973 4.13574C15.9785 4.41699 16.166 4.79199 16.166 5.19824V13.5732C16.166 14.417 15.4785 15.0732 14.666 15.0732H12.166V16.5732C12.166 17.417 11.4785 18.0732 10.666 18.0732H3.66602C2.82227 18.0732 2.16602 17.417 2.16602 16.5732V6.57324C2.16602 5.76074 2.82227 5.07324 3.66602 5.07324H6.16602V3.57324C6.16602 2.76074 6.82227 2.07324 7.66602 2.07324H13.041C13.4473 2.07324 13.8223 2.26074 14.1035 2.54199L15.6973 4.13574ZM10.4785 16.5732C10.5723 16.5732 10.666 16.5107 10.666 16.3857V15.0732H7.66602C6.82227 15.0732 6.16602 14.417 6.16602 13.5732V6.57324H3.85352C3.72852 6.57324 3.66602 6.66699 3.66602 6.76074V16.3857C3.66602 16.5107 3.72852 16.5732 3.85352 16.5732H10.4785ZM14.4785 13.5732C14.5723 13.5732 14.666 13.5107 14.666 13.3857V7.07324H11.916C11.4785 7.07324 11.166 6.76074 11.166 6.32324V3.57324H7.85352C7.72852 3.57324 7.66602 3.66699 7.66602 3.76074V13.3857C7.66602 13.5107 7.72852 13.5732 7.85352 13.5732H14.4785ZM14.666 5.57324V5.29199C14.666 5.22949 14.6348 5.19824 14.6035 5.16699L13.0723 3.63574C13.041 3.60449 13.0098 3.57324 12.9473 3.57324H12.666V5.57324H14.666Z"
            fill={color}
        />
    </svg>
);

export {
    ArrowIcon,
    BookIcon,
    CopyIcon,
    BellSilentIcon,
    PlusIcon,
    ShareIcon,
    PhoneIcon,
    VideoCam,
    likeFilled,
    disliked,
    arrowRightSvg,
    attachmentSvg,
    ExtlinkSvg,
    bellSvg,
    checkedSvg,
    copySvg,
    dollarWhiteSvg,
    dropdownSvg,
    filterSvg,
    idCardSvg,
    infoRedSvg,
    infoSvg,
    mapSvg,
    phoneBlackSvg,
    phoneSvg,
    thumbsDownSvg,
    tickSvg,
    uncheckedSvg,
    whatsappSvg,
    wrongSvg
};
