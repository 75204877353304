import React from "react";
import { noop } from "../../utils/noop";
import { Button } from "../ui/buttons/Button";

function SuccessDialog({
    title = "Su consulta fue enviada con éxito",
    desc = "gracias por contactarnos, pronto estaremos atendiendo su solicitud",
    button = "Aceptar",
    setDialog = noop
}) {
    return (
        <div
            className="flex flex-col justify-start items-center lg:w-[477px] gap-[30px] p-[30px] bg-white"
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" , top:"300px", position:"relative" }}
        >
            <div
                className="flex justify-start items-start  relative gap-2.5 rounded-[100px] bg-[#d80027]"
                style={{ boxShadow: "0px 8px 30px 0 rgba(31,107,255,0.35) " }}
            >
                <svg
                    width={71}
                    height={70}
                    viewBox="0 0 71 70"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" lg:w-[70px] h-[70px] relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M55.5527 26.888L53.0007 24.3359C52.8184 24.1537 52.5449 24.0625 52.1803 24.0625C51.9069 24.0625 51.7246 24.1537 51.5423 24.3359L28.0267 47.8516L19.3678 39.1927C19.1855 39.0104 19.0033 38.9193 18.7298 38.9193C18.3652 38.9193 18.0918 39.0104 17.9095 39.1927L15.3574 41.7448C15.1751 41.9271 15.084 42.2005 15.084 42.5651C15.084 42.8386 15.1751 43.112 15.3574 43.2031L27.2975 55.2344C27.4798 55.4167 27.7533 55.4167 28.0267 55.4167C28.3913 55.4167 28.5736 55.4167 28.7559 55.2344L55.5527 28.4375C55.735 28.1641 55.8262 27.9818 55.8262 27.6172C55.9173 27.3438 55.735 27.0703 55.5527 26.888ZM27.0241 37.7344C27.2975 37.9167 27.571 37.9167 27.8444 37.9167C28.209 37.9167 28.3913 37.9167 28.5736 37.7344L47.2585 19.0495C47.4408 18.7761 47.623 18.5026 47.623 18.2292C47.623 17.9557 47.4408 17.6823 47.2585 17.5L44.7064 14.8568C44.5241 14.6745 44.2507 14.5833 43.8861 14.5833C43.6126 14.5833 43.3392 14.6745 43.1569 14.8568L27.8444 30.3516L22.3757 24.7917C22.1934 24.6094 21.9199 24.4271 21.6465 24.4271C21.373 24.4271 21.0996 24.6094 20.8262 24.7917L18.2741 27.3438C18.0918 27.5261 18.0007 27.7995 18.0007 28.1641C18.0007 28.4375 18.0918 28.7109 18.2741 28.8932L27.0241 37.7344Z"
                        fill="#F2F2F2"
                    />
                </svg>
            </div>
            <div className="flex flex-col justify-start items-center self-stretch  relative gap-[5px]">
                <p className="self-stretch  lg:w-[417px] text-2xl font-bold text-center text-[#373737]">
                    {title}
                </p>
                <p className="self-stretch  lg:w-[417px] text-xl text-center text-[#7b7b7b]">
                    {desc}
                </p>
            </div>
            <Button
                onClick={() => setDialog(false)}
                className="flex justify-center items-center text-lg text-left text-white  lg:w-[207px] h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#292929]"
            >
                {button}
            </Button>
        </div>
    );
}

export default SuccessDialog;
