import React from "react";
import ModelClose from "../../../assets/icons/ModalClose";
import AjantaTareas from "../../../assets/icons/AjantaTareas";
import AjantaVideoConf from "../../../assets/icons/AjantaVideoConf";
import AjantaPersencial from "../../../assets/icons/AjantaPersencial";
import AjantaPersonal from "../../../assets/icons/AjantaPersonal";
import { Button, COLOR, SIZE } from "../../ui/buttons/Button";


const data = [
    {
        component: <AjantaTareas />,
        text: "Tareas",
        title: "Agenda las tareas pendientes y asocialas a proyecto de tu cliente",
        color: "#ffecef",
        buttonText: "Crear",
    },
    {
        component: <AjantaVideoConf />,
        text: "Video conferencia",
        title: "Agenda y envía solicitud de video conferencia a tu cliente",
        color: "#fff",
        buttonText: "Crear",
    },
    {
        component: <AjantaPersencial />,
        text: "Presencial",
        title: "Agenda y envía una solicitud de reunión presencial con tu cliente",
        color: "#99e4b9",
        buttonText: "Crear",
    },
    {
        component: <AjantaPersonal />,
        text: "Personal",
        title: "Agenda tareas o eventos personales sin necesidad de vincular a un cliente.",
        color: "#fff7ce",
        buttonText: "Crear",
    },
];

const CreateEvent = ({ closeDialog }) => {
    return (
        <div
            className="flex flex-col  relative gap-[30px] p-[30px] rounded-[20px] bg-white"
            style={{ boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)" }}
        >
            <div className="flex flex-col    gap-[30px]">
                <p className="  max-w-[1127px] text-[34px] text-center text-[#373737]">
                    Seleccione un tipo de evento
                </p>

                <div className="flex flex-col md:flex-row justify-center items-center w-full  max-w-[1127px]  gap-5">
                    {data.map((item, index) => (
                        <div
                            className="w-[260px] h-[260px] group relative transition-all duration-500 [transform-style:preserve-3d] hover:[transform:rotateY(180deg)]"
                            key={index}
                        >
                            <div
                                className="absolute inset-0  group-hover:opacity-0  "
                                style={{
                                    filter: "drop-shadow(0px 3px 8px rgba(0,0,0,0.15))"
                                }}
                            >
                                <div className="flex flex-col justify-center items-center h-full  gap-4 p-4 rounded-xl bg-white">
                                    {item.component}
                                    <p className="w-[232.2px] text-[21px] font-bold text-center text-[#373737]">
                                        {item.text}
                                    </p>
                                </div>
                            </div>

                            <div className="absolute inset-0 transition-all duration-500 [transform:rotateY(180deg)] opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                                <div
                                    className={`flex flex-col justify-start items-center   gap-[17px] p-[16px] rounded-[10.41px] bg-[${item.color}]`}
                                >
                                    <div className="flex  flex-1 justify-start items-center min-h-[179px]   gap-[2.8860435485839844px]">
                                        <p className="  w-[232.2px]  font-bold text-left text-[#373737] text-sm ">
                                            {item.title}
                                        </p>
                                    </div>
                                    {/* <div className="flex justify-center items-center  h-[34.63px]  gap-[7.215108871459961px] px-[23.088348388671875px] py-[7.215108871459961px] rounded-[2.89px] bg-[#d80027]">
                                        <p className=" text-lg text-left text-white">
                                            Crear
                                        </p>
                                        <svg
                                            width="23"
                                            height="23"
                                            viewBox="0 0 23 23"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className=" w-[21.65px] h-[21.65px] "
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <path
                                                d="M13.8065 7.35237L13.2428 7.91605C13.1301 8.05697 13.1301 8.25426 13.271 8.39518L15.5257 10.5654H5.8304C5.63311 10.5654 5.49219 10.7345 5.49219 10.9036V11.6927C5.49219 11.89 5.63311 12.0309 5.8304 12.0309H15.5257L13.271 14.2293C13.1301 14.3702 13.1301 14.5675 13.2428 14.7084L13.8065 15.2721C13.9474 15.3848 14.1447 15.3848 14.2856 15.2721L18.0059 11.5518C18.1186 11.4109 18.1186 11.2136 18.0059 11.0727L14.2856 7.35237C14.1447 7.23964 13.9474 7.23964 13.8065 7.35237Z"
                                                fill="#F2F2F2"
                                            ></path>
                                        </svg>
                                    </div> */}
                                    <Button
                                        color={COLOR.RED}
                                        size={SIZE.MEDIUM}
                                        type="submit"
                                        iconName={"arrow"}
                                        onClick={""}
                                        text="Crear"
                                    />
                                </div>
                            </div>
                            {/* opacity-0 group-hover:opacity-100 [transform:rotateY(180deg)] */}
                        </div>
                    ))}
                </div>
            </div>
            <ModelClose
                onClick={closeDialog}
                className={"absolute right-0 top-0"}
            />
        </div>
    );
};

export default CreateEvent;
