import { create } from "zustand";

let store = (set) => ({
    profileDescription: "professional",
    setProfileDescription: (value) => {
        set({ profileDescription: value });
        console.log("authStore profile type:",value);
    },
   
});

const useAuthStore = create(store);

export default useAuthStore;