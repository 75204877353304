import { useNavigate } from "react-router-dom";
import blog from "../../assets/images/landing/blog.png";
import profile from "../../assets/images/landing/profile.png";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";
import { useCallback, useState } from "react";
import { ExtlinkSvg } from "../../utils/icons";
import Thumb from "../ui/Thumb";
import gFlag from "../../assets/flags/Guatemala.svg";

import "./styles.css";
import Dialog from "../Dialog";
import Invite from "../dialogs/Invite";
import RatingStars from "../ui/buttons/RatingStars";

function BlogPost({
    data = {
        id: "321"
    }
}) {
    const [hover, setHover] = useState(false);
    const navigate = useNavigate();

    const [showDialog, setDialog] = useState(0);

    const handleOpenBlogPost = useCallback(() => {
        navigate(`/blog/${data.id}`);
    }, [data.id, navigate]);
    const handleSharePost = useCallback(() => {
        setDialog(1);
    }, []);

    return (
        <>
            <Dialog
                setDialog={setDialog}
                showDialog={showDialog}
                main={<Invite closeDialog={() => setDialog(0)} />}
            />
            <div
                onMouseOver={() => setHover(1)}
                onMouseLeave={() => setHover(0)}
                className={`${
                    hover ? "scale-110" : ""
                } flex flex-col justify-start items-start self-stretch  w-auto sm:w-[368px] relative gap-6 bg-white transition-all ease-in-out duration-300`}
                style={{ boxShadow: "0px 3px 8px 0 rgba(0,0,0,0.15)" }}
            >
                <div
                    onClick={handleOpenBlogPost}
                    className="cursor-pointer flex flex-col justify-start items-start self-stretch  relative space-y-[-23px]"
                >
                    <div className="blog__imgWrap">
                        <img
                            src={blog}
                            alt="rectangle-1415.jpeg"
                            className="blog__img w-auto sm:w-full h-[221px] object-cover self-stretch transition-all ease-in-out duration-300 "
                        />
                    </div>
                    <div className="relative flex items-start self-stretch justify-start gap-3 px-4">
                        <div
                            className=" w-[45px] h-[45px] relative overflow-hidden rounded-[50px] border-2 border-[#f2f2f2]"
                            style={{
                                filter: "drop-shadow(0px 8px 15px rgba(0,0,0,0.15))"
                            }}
                        >
                            <img
                                src={profile}
                                alt="rectangle-1413.png"
                                className="w-[45px] h-[45px] absolute left-[-1.21px] top-[-1.21px] object-cover"
                            />
                        </div>
                        <div className="flex flex-col justify-start items-start flex-grow gap-[5px] pr-[43px] pt-[35px]">
                            <div className="flex justify-start items-start self-stretch  relative gap-[5px]">
                                <div className="relative flex flex-col items-start justify-start flex-grow">
                                    <p className=" text-base font-bold text-left text-[#373737]">
                                        Cristian Fernández
                                    </p>
                                    <p className=" text-sm text-left text-[#373737]">
                                        Derecho penal
                                    </p>
                                </div>

                                <img
                                    src={gFlag}
                                    className="  w-[30px] h-[30px] relative"
                                    alt="gFlag"
                                />
                            </div>
                            <RatingStars rating={4.5} className="flex gap-3" />
                        </div>
                    </div>
                </div>
                {Boolean(hover) && (
                    <div
                        onClick={handleOpenBlogPost}
                        className="cursor-pointer absolute right-[-20px] top-[280px]"
                    >
                        <div
                            className="flex flex-col cursor-pointer justify-center items-center flex-grow-0 flex-shrink-0 relative gap-[16.98616600036621px] p-[16.98616600036621px] rounded-[10.19px] bg-[#d80027]"
                            style={{
                                boxShadow:
                                    "0px 8.30434799194336px 15.57065200805664px 0 rgba(0,0,0,0.15)"
                            }}
                        >
                            <img src={ExtlinkSvg} alt="ExtlinkSvg" />
                        </div>
                    </div>
                )}
                <div className="relative flex flex-col items-start self-stretch justify-start px-4 pb-4">
                    <div
                        onClick={handleOpenBlogPost}
                        className="flex flex-col cursor-pointer justify-start items-start self-stretch  relative gap-2.5 pr-4 pb-2"
                    >
                        <p className="self-stretch w-auto  sm:w-[336px] text-lg font-bold text-left text-[#373737]">
                        Conecta con Clientes: Únete a Legaleprof y Amplía tu Práctica Legal
                        </p>
                        <p className="self-stretch w-auto  sm:w-[336px] text-base text-left text-[#7b7b7b]">
                        ¡Bienvenido a Legaleprof! Como abogado, sé lo importante que es conectar con clientes que necesitan asesoramiento legal. En Legaleprof, puedes ampliar tu base de clientes y brindar tus servicios de manera eficiente. Únete a nuestra comunidad y simplifica la forma en que conectas con quienes necesitan tu experiencia legal.
                        </p>
                        <div className="flex justify-start items-center  gap-2.5">
                            <div className="flex justify-center items-center  w-[84px] h-[22px] relative gap-[5px] p-2.5 rounded-[50px] border border-[#a4a4a4]">
                                <p className="flex-grow w-16 text-sm text-center text-[#7b7b7b]">
                                    Pill labels
                                </p>
                            </div>
                            <div className="flex justify-center items-center  w-[84px] h-[22px] relative gap-[5px] p-2.5 rounded-[50px] border border-[#a4a4a4]">
                                <p className="flex-grow w-16 text-sm text-center text-[#7b7b7b]">
                                    Pill labels
                                </p>
                            </div>
                            <div className="flex justify-center items-center  w-[84px] h-[22px] relative gap-[5px] p-2.5 rounded-[50px] border border-[#a4a4a4]">
                                <p className="flex-grow w-16 text-sm text-center text-[#7b7b7b]">
                                    Pill labels
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between  items-center self-stretch  h-[46px] pt-2.5 border-t border-r-0 border-b-0 border-l-0 border-[#d9d9d9]">
                        <div className="flex justify-start items-center  relative gap-[2.3480663299560547px]">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M18 4H6C4.875 4 4 4.90625 4 6V15C4 16.125 4.875 17 6 17H9V19.625C9 19.875 9.15625 20 9.375 20C9.4375 20 9.5 20 9.59375 19.9375L13.5 17H18C19.0938 17 20 16.125 20 15V6C20 4.90625 19.0938 4 18 4ZM18.5 15C18.5 15.2812 18.25 15.5 18 15.5H13L12.5938 15.8125L10.5 17.375V15.5H6C5.71875 15.5 5.5 15.2812 5.5 15V6C5.5 5.75 5.71875 5.5 6 5.5H18C18.25 5.5 18.5 5.75 18.5 6V15ZM15.5 8.25H8.5C8.21875 8.25 8 8.5 8 8.75V9.25C8 9.53125 8.21875 9.75 8.5 9.75H15.5C15.75 9.75 16 9.53125 16 9.25V8.75C16 8.5 15.75 8.25 15.5 8.25ZM12.5 11.25H8.5C8.21875 11.25 8 11.5 8 11.75V12.25C8 12.5312 8.21875 12.75 8.5 12.75H12.5C12.75 12.75 13 12.5312 13 12.25V11.75C13 11.5 12.75 11.25 12.5 11.25Z"
                                    fill="#292929"
                                />
                            </svg>
                            <p className=" text-base text-left text-[#7b7b7b]">
                                123
                            </p>
                        </div>
                        <div className="flex justify-start items-center  relative gap-[2.3480663299560547px]">
                            <Thumb
                                count={340}
                                // onClick={}
                            />
                        </div>
                        <Button
                            size={SIZE.SMALL}
                            color={COLOR.WHITE}
                            onClick={handleSharePost}
                            text="Compartir"
                            iconName={"share"}
                        />
                    </div>
                </div>
                <div
                    className=" hidden sm:flex  justify-start items-start  absolute left-[345px] top-[272px] opacity-0 gap-[16.363636016845703px] p-[16.363636016845703px] rounded-[9.82px] bg-[#d80027]"
                    style={{ boxShadow: "0px 8px 15px 0 rgba(0,0,0,0.15)" }}
                >
                    <div className=" w-[39.27px] h-[39.27px] relative overflow-hidden">
                        <p className="absolute left-[6.55px] top-[6.55px] text-[26.18181800842285px] text-center text-[#f2f2f2]">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="share"
                            >
                                <path d="M18,14a4,4,0,0,0-3.08,1.48l-5.1-2.35a3.64,3.64,0,0,0,0-2.26l5.1-2.35A4,4,0,1,0,14,6a4.17,4.17,0,0,0,.07.71L8.79,9.14a4,4,0,1,0,0,5.72l5.28,2.43A4.17,4.17,0,0,0,14,18a4,4,0,1,0,4-4ZM18,4a2,2,0,1,1-2,2A2,2,0,0,1,18,4ZM6,14a2,2,0,1,1,2-2A2,2,0,0,1,6,14Zm12,6a2,2,0,1,1,2-2A2,2,0,0,1,18,20Z"></path>
                            </svg>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogPost;
