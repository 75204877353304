import React from "react";

const COLOR = {
    filled: "#FF8C00",
    unfilled: "#E6E6E6"
};
const FILL = {
    FULL: "full",
    HALF: "half",
    NONE: "none"
};

const StarIcon = ({
    className = undefined,
    width = 17,
    height = 16,
    fillStatus = FILL.FULL
}) => {
    let color = fillStatus === FILL.NONE ? COLOR.unfilled : COLOR.filled;
    if (fillStatus === FILL.HALF) color = "url(#paint0_linear_11417_6914)";

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 17 16"
            xmlns="http://www.w3.org/2000/svg"
            className={className ?? "relative w-4 h-4 "}
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M4.11273 15.4427C3.72629 15.6413 3.28872 15.2942 3.36652 14.8508L4.19625 10.1213L0.673916 6.76462C0.344736 6.45092 0.515136 5.87737 0.956125 5.81472L5.8546 5.11885L8.03872 0.792305C8.23547 0.402565 8.76844 0.402565 8.96519 0.792305L11.1493 5.11885L16.0478 5.81472C16.4888 5.87737 16.6592 6.45092 16.33 6.76462L12.8077 10.1213L13.6374 14.8508C13.7152 15.2942 13.2776 15.6413 12.8912 15.4427L8.50195 13.1868L4.11273 15.4427Z"
                fill={color}
            />
            {Boolean(fillStatus === FILL.HALF) && (
                <defs>
                    <linearGradient
                        id="paint0_linear_11417_6914"
                        x1="0.500488"
                        y1="16"
                        x2="16.5005"
                        y2="16"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.500167" stop-color={COLOR.filled} />
                        <stop offset="0.500167" stop-color={COLOR.unfilled} />
                    </linearGradient>
                </defs>
            )}
        </svg>
    );
};

function RatingStars({
    editabled = false,
    rating = 4.5,
    stars = 5,
    iconProps = {},
    className = undefined
}) {
    const elms = [];
    let trackRating = rating;
    for (let i = 0; i < stars; i++) {
        if (trackRating >= 1) {
            elms.push(<StarIcon {...iconProps} key={i} />);
            trackRating -= 1;
        } else if (trackRating === 0.5) {
            elms.push(
                <StarIcon {...iconProps} key={i} fillStatus={FILL.HALF} />
            );
            trackRating -= 0.5;
        } else {
            elms.push(
                <StarIcon {...iconProps} key={i} fillStatus={FILL.NONE} />
            );
            trackRating = 0;
        }
    }
    return <div className={className ?? "flex gap-2.5"}>{elms}</div>;
}

export default RatingStars;
