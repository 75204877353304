import { filterSvg } from "../../utils/icons";
import Pagination from "../Pagination";
import Input from "../ui/Input";
import Checkbox from "../ui/Checkbox";
import JobSearchCard from "./JobSearchCard";
import JobSearchCard1 from "./JobSearchCard1";

function JobSearchFooter({ formData, handleChange }) {
    return (
        <div className="flex flex-col lg:flex-row justify-start w-full items-center  gap-10 lg:gap-[309px]">
            <div className="flex justify-start items-center  relative gap-2.5">
                <p className=" text-base text-left text-[#373737]">
                    Trabajos por página:
                </p>
                <div className="flex flex-col justify-center items-start  gap-[5px]">
                    <Input
                        name="order"
                        value={formData.order}
                        onChange={(e) => handleChange(e.target)}
                        type="dropdown"
                        // placeholder="Order"
                        selectList={["10", "50", "100"]}
                        inputClassName={
                            // py-2.5
                            " text-base text-left text-[#373737] flex  items-center  self-stretch  gap-2.5 px-3 py-1.5 rounded h-9 bg-white border-t border-r border-b border-l border-[#292929]"
                        }
                    />
                </div>
            </div>
            <Pagination />
        </div>
    );
}

function JobSearchBar({
    handleFormSubmission,
    formData,
    setFormData,
    handleChange,
    toggleFilterExpand,
    filterSideBarView
}) {
    return (
        <div className="flex flex-col justify-start items-center self-stretch w-full max-w-[1000px]   gap-5 p-[30px] bg-white border border-[#cdcdcd]">
            <div className="flex flex-col-reverse items-center justify-start w-full lg:flex-row gap-7">
                <Checkbox
                    text="Presencial"
                    toggleCheck={() =>
                        handleChange({
                            name: "inPerson",
                            value: !formData.inPerson
                        })
                    }
                    checked={formData.inPerson}
                />
                <div className="flex flex-col justify-start items-start self-stretch  w-full  flex-grow gap-[5px]">
                    <Input
                        // label="Apellido"
                        placeholder="Buscar proyectos"
                        // required
                        onChange={(e) => handleChange(e.target)}
                        value={formData.search}
                        name="search"
                        suggestions
                        suggestionList={[]}
                        icon={
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                    fill="#333333"
                                />
                            </svg>
                        }
                    />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-start items-end lg:items-center self-stretch  gap-[30px]">
                <div className="flex justify-start items-center flex-grow relative gap-2.5">
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" lg:w-[30px] h-[30px] relative"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M23.125 10C24.1406 10 25 10.8594 25 11.875V21.875C25 22.9297 24.1406 23.75 23.125 23.75H6.875C5.82031 23.75 5 22.9297 5 21.875V11.875C5 10.8594 5.82031 10 6.875 10H10V8.125C10 7.10938 10.8203 6.25 11.875 6.25H18.125C19.1406 6.25 20 7.10938 20 8.125V10H23.125ZM11.875 8.125V10H18.125V8.125H11.875ZM7.10938 11.875C6.95313 11.875 6.875 11.9922 6.875 12.1094V15H23.125V12.1094C23.125 11.9922 23.0078 11.875 22.8906 11.875H7.10938ZM22.8906 21.875C23.0078 21.875 23.125 21.7969 23.125 21.6406V16.875H17.5V17.8125C17.5 18.3594 17.0703 18.75 16.5625 18.75H13.4375C12.8906 18.75 12.5 18.3594 12.5 17.8125V16.875H6.875V21.6406C6.875 21.7969 6.95313 21.875 7.10938 21.875H22.8906Z"
                            fill="#D80027"
                        />
                    </svg>
                    <p className="flex-grow xl:w-[303px] text-base font-bold text-left text-[#373737]">
                        empleos encontrados
                    </p>
                </div>
                <div className="flex justify-start items-center  relative gap-2.5">
                    <p className=" text-base  shrink-0 text-left text-[#373737]">
                        Ordenar por
                    </p>
                    <div className="flex flex-col  shrink-0 justify-center items-start  gap-[5px]">
                        <Input
                            name="order"
                            value={formData.order}
                            onChange={(e) => handleChange(e.target)}
                            type="dropdown"
                            // placeholder="Order"
                            selectList={[
                                "Más reciente",
                                "Más popular",
                                "Más trending"
                            ]}
                            inputClassName={
                                // py-2.5
                                " text-base text-left text-[#373737] flex  items-center self-stretch  gap-2.5 px-3 py-1.5 rounded h-9 bg-white border-t border-r border-b border-l border-[#292929]"
                            }
                        />
                    </div>
                </div>
                {Boolean(filterSideBarView) && (
                    <div
                        onClick={toggleFilterExpand}
                        className="flex items-center gap-5 p-5 hover:bg-slate-50"
                    >
                        <img src={filterSvg} alt="filterSvg" />
                        Filters
                    </div>
                )}
            </div>
        </div>
    );
}


function JobSearch(props) {
    let filteredComponent;

    if (props.formData.category === 'CONTRATACION URGENTE') {
        filteredComponent = <JobSearchCard />;
    } else if (props.formData.category === 'ASESORÍA URGENTE') {
        filteredComponent = <JobSearchCard1 />;
    } else {
        filteredComponent = (
            <>
                <JobSearchCard />
                <JobSearchCard1 />
            </>
        );
    }

    return (
        <div className="flex flex-col items-start justify-start gap-5">
            <div className="flex flex-col items-end justify-start ">
                <JobSearchBar {...props} />
                <div className="flex flex-col justify-start items-start  lg:w-[816px]">
                    {filteredComponent}
                </div>
            </div>
            <JobSearchFooter {...props} />
        </div>
    );
}

export default JobSearch;
