import { useState } from "react";
import video from "../../assets/images/blogs/video.png";
import Input from "../ui/Input";
import NavTabs from "../ui/NavTabs";
import Thumb from "../ui/Thumb";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";
import BlogAuthor from "./BlogAuthor";
import BlogComments from "./BlogComments";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormValidation from "../form/FormValidation";

const validationSchema = Yup.object({
    comment: Yup.string()
        .required("Este campo es requerido")
        .max(100, "El comentario no debe tener más de 100 caracteres"),

    email: Yup.string()
        .email("Ingresa un correo válido")
        .required("Este campo es requerido"),
    phone: Yup.string().required("Este campo es requerido")
    // phone: Yup.number()
    //     .typeError("Ingresa un número válido")
    //     .max(999999999999, "Máximo 12 dígitos")
    //     .min(100000000000, "Mínimo 12 dígitos")
    //     .required("Este campo es requerido"),
});


function BlogContent() {
    const [formData, setFormData] = useState({});
    console.log(formData);
    // const handleChange = useCallback(
    //     (target = {}) => {
    //         let { name, value } = target;
    //         console.log(target);
    //         setFormData((data) => ({ ...data, ...{ [name]: value } }));
    //         console.log(formData);
    //     },
    //     [formData]
    // );

    // const handleFormSubmission = useCallback((e) => {
    // submit formData
    // e.preventDefault();
    // const url = "http://localhost:3000/uploadFile";
    // const config = {
    //     headers: {
    //         "content-type": "multipart/form-data"
    //     }
    // };
    // axios
    //     .post(url, formData, config)
    //     .then((response) => {
    //         console.log(response.data);
    //         setUploadedFile(response.data.file);
    //     })
    //     .catch((error) => {
    //         console.error("Error uploading file: ", error);
    //         setError(error);
    //     });
    // }, []);

    const formik = useFormik({
        initialValues: {
            comment: "",
            email: "",
            phone: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setFormData((data) => ({ ...data, ...values }));
        }
    });

    return (
        <div className="flex flex-col md:flex-row justify-start items-start gap-6 mt-[100px] md:mt-0">
            <div className="flex md:flex-[0.6] flex-col justify-start items-start  gap-[100px]">
                <div className="flex flex-col gap-10 justify-start items-start">
                    <div className="flex flex-col justify-start items-start  relative gap-1.5">
                        <div className="flex justify-start items-start  relative gap-2.5">
                            <p className=" text-base text-left text-[#373737]">
                                Autor:
                            </p>
                            <p className=" text-base font-bold text-left text-[#373737]">
                                Cristian Fernández
                            </p>
                        </div>
                        <div className=" w-[209px] h-[3px] bg-[#d80027]" />
                    </div>
                    <div className="flex flex-col justify-start items-center   relative gap-[41px]">
                        <div className="flex flex-col justify-start items-start self-stretch  relative gap-[26px]">
                            <p className="self-stretch   text-2xl font-bold text-left text-[#373737]">
                                Lorem ipsum dolor sit amet consectetur. Sed id
                                at gravida mattis nunc sed. Lectus amet tortor a
                                morbi tellus.{" "}
                            </p>
                            <p className="self-stretch   text-lg text-left text-[#7b7b7b]">
                                <span className="self-stretch   text-lg text-left text-[#7b7b7b]">
                                    Lorem ipsum dolor sit amet consectetur.
                                    Egestas in lectus platea mi vitae bibendum
                                    tempor id pharetra. Vehicula ultrices
                                    commodo facilisi massa. Mauris ut eu nunc
                                    est. In tellus amet arcu nulla. Nibh feugiat
                                    tristique at at at lectus eget aliquam
                                    rutrum. Quam sapien amet eros sed adipiscing
                                    id. Enim viverra suspendisse leo vel cursus
                                    id dignissim. Vestibulum magna quis nunc
                                    tellus arcu donec. Etiam purus pellentesque
                                    volutpat quis in lacus ipsum sodales. Sed
                                    vestibulum porttitor imperdiet nibh quis.
                                    Nam laoreet varius volutpat mauris pretium
                                    risus nunc senectus.
                                </span>
                                <br />
                                <br />
                                <span className="self-stretch   text-lg text-left text-[#7b7b7b]">
                                    Orci faucibus at malesuada proin. Egestas
                                    velit urna volutpat ultrices eget quam
                                    feugiat. A faucibus amet facilisis mi sed
                                    gravida adipiscing. Sed lacinia auctor
                                    habitant augue sagittis hendrerit. Molestie
                                    netus id purus venenatis sit in dui tellus.
                                    Mattis imperdiet aliquet turpis a venenatis.
                                    Ultricies tincidunt odio eu massa erat.
                                    Commodo imperdiet vestibulum mattis risus
                                    eleifend ut sollicitudin egestas tristique.
                                    Sagittis lacus arcu diam ultricies felis
                                    ridiculus leo facilisis. Condimentum varius
                                    ut accumsan leo. Integer nec magnis non
                                    ipsum rutrum felis.
                                </span>
                            </p>
                        </div>

                        <img
                            src={video}
                            alt="rectangle-1413-3.jpeg"
                            className=" h-[286.62px] w-full object-cover"
                        />
                        <p className="self-stretch   text-lg text-left text-[#7b7b7b]">
                            Risus pretium vitae eget feugiat viverra convallis
                            tortor eu. Suspendisse enim mi iaculis in interdum
                            ac bibendum faucibus tempus. Faucibus elementum
                            pharetra est nulla consectetur augue. Posuere eu mi
                            sed cras molestie. Purus id velit eget iaculis
                            placerat semper euismod amet egestas. Arcu netus ut
                            non risus purus amet. Lacus nullam consectetur
                            tempor nunc ullamcorper sit. Vel arcu ut ultricies
                            viverra porttitor. Purus dui elementum pretium
                            lectus scelerisque accumsan.
                        </p>
                        <div className="flex justify-end items-center flex-col sm:flex-row self-stretch  gap-10 pt-5 border-t border-r-0 border-b-0 border-l-0 border-[#d9d9d9]">
                            <div className="flex justify-start items-center  relative gap-[2.3480663299560547px]">
                                <svg
                                    width={25}
                                    height={25}
                                    viewBox="0 0 25 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M18.3037 4.6167H6.30371C5.17871 4.6167 4.30371 5.52295 4.30371 6.6167V15.6167C4.30371 16.7417 5.17871 17.6167 6.30371 17.6167H9.30371V20.2417C9.30371 20.4917 9.45996 20.6167 9.67871 20.6167C9.74121 20.6167 9.80371 20.6167 9.89746 20.5542L13.8037 17.6167H18.3037C19.3975 17.6167 20.3037 16.7417 20.3037 15.6167V6.6167C20.3037 5.52295 19.3975 4.6167 18.3037 4.6167ZM18.8037 15.6167C18.8037 15.8979 18.5537 16.1167 18.3037 16.1167H13.3037L12.8975 16.4292L10.8037 17.9917V16.1167H6.30371C6.02246 16.1167 5.80371 15.8979 5.80371 15.6167V6.6167C5.80371 6.3667 6.02246 6.1167 6.30371 6.1167H18.3037C18.5537 6.1167 18.8037 6.3667 18.8037 6.6167V15.6167ZM15.8037 8.8667H8.80371C8.52246 8.8667 8.30371 9.1167 8.30371 9.3667V9.8667C8.30371 10.1479 8.52246 10.3667 8.80371 10.3667H15.8037C16.0537 10.3667 16.3037 10.1479 16.3037 9.8667V9.3667C16.3037 9.1167 16.0537 8.8667 15.8037 8.8667ZM12.8037 11.8667H8.80371C8.52246 11.8667 8.30371 12.1167 8.30371 12.3667V12.8667C8.30371 13.1479 8.52246 13.3667 8.80371 13.3667H12.8037C13.0537 13.3667 13.3037 13.1479 13.3037 12.8667V12.3667C13.3037 12.1167 13.0537 11.8667 12.8037 11.8667Z"
                                        fill="#292929"
                                    />
                                </svg>
                                <p className=" text-base text-left text-[#7b7b7b]">
                                    123
                                </p>
                            </div>
                            <Thumb
                                count={340}
                                // onClick={}
                            />
                            <Button
                                size={SIZE.SMALL}
                                color={COLOR.WHITE}
                                // onClick={handleSharePost}
                                text="Compartir"
                                iconName={"arrow"}
                            />
                            {/* <div className="flex justify-center items-center  h-9 relative gap-2.5 px-4 py-2.5 rounded border-2 border-[#292929]">
                                <p className=" text-sm text-left text-[#373737]">
                                    Compartir
                                </p>
                                <img src={arrowRightSvg} alt="shareSvg" />
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-start items-start   gap-10 max-w-[98vw] md:max-w-none">
                    <div className="flex relative flex-col gap-5 justify-start items-start">
                        <p className=" text-base font-bold text-left text-[#373737]">
                            Sobre el autor:
                        </p>
                        <BlogAuthor />
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col justify-start items-start w-full  relative gap-5 pb-10 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]"
                    >
                        <p className="self-stretch   text-2xl font-bold text-left text-[#373737]">
                            Déjanos tu comentario
                        </p>
                        <Input
                            label="Tu reseña"
                            onChange={formik.handleChange}
                            value={formik.values.comment}
                            name="comment"
                            type="long"
                        />
                        {<FormValidation formik={formik} name="comment" />}
                        <div className="flex flex-col gap-6 justify-start items-start self-stretch md:flex-row">
                            <div className="flex flex-col flex-grow">
                                <Input
                                    label="E-mail"
                                    placeholder="E-mail"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    name="email"
                                    type="email"
                                />
                                {
                                    <FormValidation
                                        formik={formik}
                                        name="email"
                                    />
                                }
                            </div>
                            <div className="flex flex-col flex-grow">
                                <Input
                                    label="Nombre"
                                    placeholder="Nombre"
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                    name="phone"
                                    type="text"
                                />
                                {
                                    <FormValidation
                                        formik={formik}
                                        name="phone"
                                    />
                                }
                            </div>
                        </div>
                        <div className="flex flex-col justify-start items-end self-stretch  gap-2.5">
                            <Button
                                type="submit"
                                size={SIZE.SMALL}
                                color={COLOR.BLACK}
                                addClass=" text-base   h-11    px-4 py-2.5 sm:w-auto "
                                stretch
                            >
                                Publicar comentario
                            </Button>
                        </div>
                    </form>
                    <div className="flex relative flex-col gap-5 justify-start items-start self-stretch">
                        <p className="  text-base font-bold text-left text-[#373737]">
                            2 Comments
                        </p>
                        <NavTabs
                            tabsClass={"flex items-end justify-end w-full"}
                            tabs={[
                                { text: "Most voted" },
                                { text: "Most recent" },
                                { text: "Older" }
                            ]}
                            views={[
                                <BlogComments sort={"mostedVoted"} />,
                                <BlogComments sort={"mostedRecent"} />,
                                <BlogComments sort={"older"} />
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="flex md:flex-[0.4] flex-col justify-start items-start  gap-8 md:ml-[5%] ">
                <div className="flex justify-start items-center w-full   relative gap-2.5 py-2.5 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                    <p className=" text-lg font-bold text-left text-[#7b7b7b]">
                        Enlaces relacionados
                    </p>
                </div>
                <div className="flex relative flex-col gap-4 justify-start items-start self-stretch">
                    <a
                        href="/blog/post/443"
                        className="self-stretch   text-lg text-left text-[#d80027] cursor-pointer hover:underline"
                    >
                        Lorem ipsum dolor sit amet consectetur.
                    </a>
                    <a
                        href="/blog/post/443"
                        className=" text-lg text-left text-[#d80027] cursor-pointer hover:underline"
                    >
                        Lorem ipsum dolor sit amet consectetur.
                    </a>
                    <a
                        href="/blog/post/443"
                        className="self-stretch   text-lg text-left text-[#d80027] cursor-pointer hover:underline"
                    >
                        Lorem ipsum dolor sit amet consectetur.
                    </a>
                    <a
                        href="/blog/post/443"
                        className="self-stretch   text-lg text-left text-[#d80027] cursor-pointer hover:underline"
                    >
                        Lorem ipsum dolor sit amet consectetur.
                    </a>
                    <a
                        href="/blog/post/443"
                        className="self-stretch   text-lg text-left text-[#d80027] cursor-pointer hover:underline"
                    >
                        Lorem ipsum dolor sit amet consectetur.
                    </a>
                    <a
                        href="/blog/post/443"
                        className="self-stretch   text-lg text-left text-[#d80027] cursor-pointer hover:underline"
                    >
                        Lorem ipsum dolor sit amet consectetur.
                    </a>
                </div>
            </div>
        </div>
    );
}

export default BlogContent;
