import { Link } from "react-router-dom";

import FormBg from "../components/form/FormBg";
import HeaderBack from "../components/form/HeaderBack";
import HeaderLogo from "../components/form/HeaderLogo";
import HeaderText from "../components/form/HeaderText";

function SignUpWrapper({ children }) {
    return (
        <div className="flex  w-full h-full flex-col items-center p-3 sm:p-10">
            <FormBg />
            <div
                className="flex flex-col justify-start items-start w-full sm:w-[804px] gap-8 p-[30px] rounded-[20px] bg-white"
                style={{ boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)" }}
            >
                <div className="flex flex-col sm:flex-row justify-start sm:items-center self-stretch  gap-2.5">
                    <Link to="/signup">
                        <HeaderBack />
                    </Link>
                    <HeaderLogo />
                </div>
                <HeaderText />

                {children}
            </div>
        </div>
    );
}

export default SignUpWrapper;
