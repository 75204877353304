import React from "react";

const CONTENT = {
    LAW: {
        url: "laws",
        type: "DOCS",
        mainPage: "Sección de leyes",
        detail: "Titulo",
        title: "DECRETO NUMERO 1551",
        dummyTitle: "El congreso de la República de Guatemala"
    },
    JURIS: {
        url: "jurisprudence",
        type: "JURIS",
        mainPage: "Jurisprudencias",
        detail: "Titulo",
        title: "DELITO DE HURTO AGRAVADO",
        dummyTitle: "Lorem ipsum dolor sit amet"
    },
    DOCS: {
        url: "documents",
        type: "DOCS",
        mainPage: "Docs Jurídicos",
        detail: "Titulo",
        title: "Lorem ipsum dolor sit amet",
        dummyTitle: "Lorem ipsum dolor sit amet"
    }
};

function DetailTemplate({
    type = "LAW",
    mainPage = "Sección de leyes",
    detail = "Titulo",
    title = "DECRETO NUMERO 1551",
    dummyTitle = "El congreso de la República de Guatemala"
}) {
    return (
        <div className="flex flex-col  justify-start items-center relative w-full mt-[100px] ">
            <div className="flex flex-col items-start gap-10 mx-4 lg:m-0">
                <a
                    href={`/platform/${CONTENT[type].url}/`}
                    className="relative flex items-center justify-start gap-2 overflow-hidden "
                >
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-4 h-4 lg:w-6 lg:h-6"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                            stroke="#292929"
                            strokeWidth="1.5"
                            stroke-miterlimit={10}
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M20.4999 12H3.66992"
                            stroke="#292929"
                            strokeWidth="1.5"
                            stroke-miterlimit={10}
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <p className="  text-sm lg:text-base text-left text-[#d80027]">
                        {CONTENT[type].mainPage}
                    </p>
                    <p className="  text-sm lg:text-base text-left text-[#d80027]">
                        {` / `}
                    </p>
                    <p className="  text-sm lg:text-base text-left text-[#d80027]">
                        {CONTENT[type].detail}
                    </p>
                </a>
                <div className="flex flex-col justify-start items-center gap-[60px]">
                    <div className="flex flex-col justify-start items-start   lg:max-w-[1186px] relative gap-[30px] pb-[60px] border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                        <div className="flex flex-col justify-start items-start self-stretch   relative gap-2.5">
                            <p className="self-stretch   lg:max-w-[1186px] text-xl lg:text-2xl font-bold text-left text-[#373737]">
                                {CONTENT[type].title}
                            </p>
                            <p className="self-stretch   lg:max-w-[1186px] text-xl lg:text-2xl font-bold text-left text-[#373737]">
                                {CONTENT[type].dummyTitle}
                            </p>
                            <p className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                <span className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Lorem ipsum dolor sit amet consectetur. Amet
                                    ac lorem feugiat elit in ultrices semper
                                    massa. Sagittis lectus quisque pellentesque
                                    venenatis magna diam. Magna tincidunt
                                    tristique ut lectus. Congue metus lectus
                                    varius ac sagittis hac. Viverra nunc ornare
                                    sagittis nulla nunc aliquam nullam. Viverra
                                    sit in arcu at facilisi. Vitae vitae pretium
                                    nulla a varius turpis lectus.
                                </span>
                                <br />
                                <span className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Mollis nibh lectus eu imperdiet sed mauris.{" "}
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-col justify-start items-start self-stretch   relative gap-2.5">
                            <p className="self-stretch   lg:max-w-[1186px] text-xl lg:text-2xl font-bold text-left text-[#373737]">
                                Considerando:
                            </p>
                            <p className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                <span className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Lorem ipsum dolor sit amet consectetur. Amet
                                    ac lorem feugiat elit in ultrices semper
                                    massa. Sagittis lectus quisque pellentesque
                                    venenatis magna diam. Magna tincidunt
                                    tristique ut lectus. Congue metus lectus
                                    varius ac sagittis hac. Viverra nunc ornare
                                    sagittis nulla nunc aliquam nullam. Viverra
                                    sit in arcu at facilisi. Vitae vitae pretium
                                    nulla a varius turpis lectus.
                                </span>
                                <br />
                                <span className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Mollis nibh lectus eu imperdiet sed mauris.{" "}
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-col justify-start items-start self-stretch   relative gap-2.5">
                            <p className="self-stretch   lg:max-w-[1186px] text-xl lg:text-2xl font-bold text-left text-[#373737]">
                                Considerando:
                            </p>
                            <p className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                <span className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Lorem ipsum dolor sit amet consectetur. Amet
                                    ac lorem feugiat elit in ultrices semper
                                    massa. Sagittis lectus quisque pellentesque
                                    venenatis magna diam. Magna tincidunt
                                    tristique ut lectus. Congue metus lectus
                                    varius ac sagittis hac. Viverra nunc ornare
                                    sagittis nulla nunc aliquam nullam. Viverra
                                    sit in arcu at facilisi. Vitae vitae pretium
                                    nulla a varius turpis lectus.
                                </span>
                                <br />
                                <span className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Mollis nibh lectus eu imperdiet sed mauris.{" "}
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-col justify-start items-start self-stretch   relative gap-2.5">
                            <p className="self-stretch   lg:max-w-[1186px] text-xl lg:text-2xl font-bold text-left text-[#373737]">
                                Considerando:
                            </p>
                            <p className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                <span className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Lorem ipsum dolor sit amet consectetur. Amet
                                    ac lorem feugiat elit in ultrices semper
                                    massa. Sagittis lectus quisque pellentesque
                                    venenatis magna diam. Magna tincidunt
                                    tristique ut lectus. Congue metus lectus
                                    varius ac sagittis hac. Viverra nunc ornare
                                    sagittis nulla nunc aliquam nullam. Viverra
                                    sit in arcu at facilisi. Vitae vitae pretium
                                    nulla a varius turpis lectus.
                                </span>
                                <br />
                                <span className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Mollis nibh lectus eu imperdiet sed mauris.{" "}
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-col justify-start items-start self-stretch   gap-2.5">
                            <div className="flex justify-start items-start self-stretch   relative gap-2.5 pl-2.5 border-t-0 border-r-0 border-b-0 border-l-2 border-[#292929]">
                                <p className="flex-grow lg:max-w-[1176px] text-xl lg:text-2xl font-bold text-left text-[#d80027]">
                                    Artículo 1:
                                </p>
                            </div>
                            <div className="flex justify-start items-start self-stretch   relative gap-2.5 pl-5">
                                <p className="flex-grow lg:max-w-[1166px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Lorem ipsum dolor sit amet consectetur. Amet
                                    quis accumsan est et. Amet ornare
                                    pellentesque sed felis amet egestas
                                    maecenas. Ut turpis scelerisque cras quam
                                    ipsum auctor vulputate. Purus gravida lacus
                                    diam vehicula.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-start items-start self-stretch   gap-2.5">
                            <div className="flex justify-start items-start self-stretch   relative gap-2.5 pl-2.5 border-t-0 border-r-0 border-b-0 border-l-2 border-[#292929]">
                                <p className="flex-grow lg:max-w-[1176px] text-xl lg:text-2xl font-bold text-left text-[#d80027]">
                                    Artículo 2:
                                </p>
                            </div>
                            <div className="flex justify-start items-start self-stretch   relative gap-2.5 pl-5">
                                <p className="flex-grow lg:max-w-[1166px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Lorem ipsum dolor sit amet consectetur. Amet
                                    quis accumsan est et. Amet ornare
                                    pellentesque sed felis amet egestas
                                    maecenas. Ut turpis scelerisque cras quam
                                    ipsum auctor vulputate. Purus gravida lacus
                                    diam vehicula.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-start items-start self-stretch   gap-2.5">
                            <div className="flex justify-start items-start self-stretch   relative gap-2.5 pl-2.5 border-t-0 border-r-0 border-b-0 border-l-2 border-[#292929]">
                                <p className="flex-grow lg:max-w-[1176px] text-xl lg:text-2xl font-bold text-left text-[#d80027]">
                                    Artículo 3:
                                </p>
                            </div>
                            <div className="flex justify-start items-start self-stretch   relative gap-2.5 pl-5">
                                <p className="flex-grow lg:max-w-[1166px] text-md lg:text-lg text-left text-[#7b7b7b]">
                                    Lorem ipsum dolor sit amet consectetur. Amet
                                    quis accumsan est et. Amet ornare
                                    pellentesque sed felis amet egestas
                                    maecenas. Ut turpis scelerisque cras quam
                                    ipsum auctor vulputate. Purus gravida lacus
                                    diam vehicula.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-start items-center self-stretch   relative gap-2.5">
                            <p className="self-stretch   lg:max-w-[1186px] text-xl lg:text-2xl font-bold text-center text-[#373737]">
                                Miguel Angel Ortega Merida
                            </p>
                            <p className="self-stretch    lg:max-w-[1186px] text-md lg:text-lg font-bold text-center text-[#373737]">
                                Presidente
                            </p>
                        </div>{" "}
                        <div className="flex sm:hidden flex-col justify-start items-center self-stretch   relative gap-2.5">
                            <p className="self-stretch   lg:max-w-[1186px] text-xl lg:text-2xl font-bold text-center text-[#373737]">
                                Miguel Angel Ortega Merida
                            </p>
                            <p className="self-stretch    lg:max-w-[1186px] text-md lg:text-lg font-bold text-center text-[#373737]">
                                Presidente
                            </p>
                        </div>{" "}
                        <div className="flex sm:hidden flex-col justify-start items-center self-stretch   relative gap-2.5">
                            <p className="self-stretch   lg:max-w-[1186px] text-xl lg:text-2xl font-bold text-center text-[#373737]">
                                Miguel Angel Ortega Merida
                            </p>
                            <p className="self-stretch    lg:max-w-[1186px] text-md lg:text-lg font-bold text-center text-[#373737]">
                                Presidente
                            </p>
                        </div>
                        <div className="hidden sm:flex flex-col sm:flex-row justify-start items-start self-stretch   gap-[30px]">
                            <div className="flex flex-col justify-start items-start flex-grow relative gap-2.5">
                                <p className="self-stretch   lg:max-w-[578px] text-xl lg:text-2xl font-bold text-left text-[#373737]">
                                    Miguel Angel Ortega Merida
                                </p>
                                <p className="self-stretch   lg:max-w-[578px] text-md lg:text-lg font-bold text-left text-[#373737]">
                                    Presidente
                                </p>
                            </div>
                            <div className="flex flex-col justify-start items-center flex-grow relative gap-2.5">
                                <p className="self-stretch   lg:max-w-[578px] text-xl lg:text-2xl font-bold text-right text-[#373737]">
                                    Miguel Angel Ortega Merida
                                </p>
                                <p className="self-stretch   lg:max-w-[578px] text-md lg:text-lg font-bold text-right text-[#373737]">
                                    Presidente
                                </p>
                            </div>
                        </div>
                        <p className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-center">
                            <span className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg font-bold text-center text-[#373737]">
                                Palacio Nacional: Guatemala:{" "}
                            </span>
                            <span className="self-stretch   lg:max-w-[1186px] text-md lg:text-lg text-center text-[#7b7b7b]">
                                diecisiete de octubre de mil novecientos sesenta
                                y dos.
                            </span>
                        </p>
                        <div className="flex flex-col justify-start items-center lg:items-start   relative gap-2.5">
                            <p className="  lg:max-w-[1186px] text-md lg:text-lg font-bold text-center text-[#373737]">
                                Publíquese y cúmplase:
                            </p>
                            <p className="  lg:max-w-[1186px] text-md lg:text-lg font-bold text-center text-[#373737]">
                                Miguel y Digoras Fuentes
                            </p>
                            <p className="  lg:max-w-[1186px] text-md lg:text-lg font-bold text-center text-[#373737]">
                                Palacio Nacional: Guatemala, 7 de noviembre de
                                1962.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start justify-start gap-5">
                        <div className="flex justify-start items-center   relative gap-[5px]">
                            <svg
                                width={24}
                                height={25}
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M18 4.5H6C4.875 4.5 4 5.40625 4 6.5V15.5C4 16.625 4.875 17.5 6 17.5H9V20.125C9 20.375 9.15625 20.5 9.375 20.5C9.4375 20.5 9.5 20.5 9.59375 20.4375L13.5 17.5H18C19.0938 17.5 20 16.625 20 15.5V6.5C20 5.40625 19.0938 4.5 18 4.5ZM18.5 15.5C18.5 15.7812 18.25 16 18 16H13L12.5938 16.3125L10.5 17.875V16H6C5.71875 16 5.5 15.7812 5.5 15.5V6.5C5.5 6.25 5.71875 6 6 6H18C18.25 6 18.5 6.25 18.5 6.5V15.5Z"
                                    fill="#333333"
                                />
                            </svg>
                            <a
                                href={`/platform/${CONTENT[type].url}/id/replies`}
                                className="underline  text-lg lg:text-xl text-left text-[#d80027]"
                            >
                                24 replicas
                            </a>
                        </div>
                        <div className="flex justify-start items-center   relative gap-[5px]">
                            <svg
                                width={24}
                                height={25}
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M12 9C11.6562 9.03125 11.3125 9.0625 11 9.15625C11.1562 9.40625 11.2188 9.71875 11.25 10C11.25 10.9688 10.4375 11.75 9.5 11.75C9.1875 11.75 8.875 11.6875 8.65625 11.5312C8.5625 11.8438 8.5 12.1562 8.5 12.5C8.5 14.4375 10.0625 16 12 16C13.9375 16 15.5 14.4375 15.5 12.5C15.5 10.5938 13.9375 9.03125 12 9.03125V9ZM20.875 12.0625C19.1875 8.75 15.8125 6.5 12 6.5C8.15625 6.5 4.78125 8.75 3.09375 12.0625C3.03125 12.1875 3 12.3438 3 12.5C3 12.6875 3.03125 12.8438 3.09375 12.9688C4.78125 16.2812 8.15625 18.5 12 18.5C15.8125 18.5 19.1875 16.2812 20.875 12.9688C20.9375 12.8438 20.9688 12.6875 20.9688 12.5312C20.9688 12.3438 20.9375 12.1875 20.875 12.0625ZM12 17C8.90625 17 6.0625 15.2812 4.5625 12.5C6.0625 9.71875 8.90625 8 12 8C15.0625 8 17.9062 9.71875 19.4062 12.5C17.9062 15.2812 15.0625 17 12 17Z"
                                    fill="#333333"
                                />
                            </svg>
                            <p className=" text-lg lg:text-xl font-bold text-left text-[#373737]">
                                148 Views
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailTemplate;
