import React, { useState } from "react";
//import SignUpClient from "./SignUpClient";
import { Link } from "react-router-dom";
import SignUpProfessional from "./SignUpProfessional";
import AuthWrapper from "../components/AuthTemplate";
import { Button, COLOR } from "../components/ui/buttons/Button";
import { COLORS } from "../constants/colors";
import { FaLock } from 'react-icons/fa';

const ClientIcon = ({ color }) => (
    <svg
        width={50}
        height={50}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className=" w-[50px] h-[50px] relative"
        preserveAspectRatio="xMidYMid meet"
    >
        <path
            d="M30.7941 28.1249C28.9061 28.1249 28.0597 29.1666 24.9998 29.1666C21.8748 29.1666 21.0285 28.1249 19.1405 28.1249C14.3228 28.1249 10.4165 32.0963 10.4165 36.914V38.5416C10.4165 40.2994 11.7837 41.6666 13.5415 41.6666H36.4582C38.1509 41.6666 39.5832 40.2994 39.5832 38.5416V36.914C39.5832 32.0963 35.6118 28.1249 30.7941 28.1249ZM36.4582 38.5416H13.5415V36.914C13.5415 33.789 16.0155 31.2499 19.1405 31.2499C20.117 31.2499 21.6144 32.2916 24.9998 32.2916C28.3201 32.2916 29.8175 31.2499 30.7941 31.2499C33.9191 31.2499 36.4582 33.789 36.4582 36.914V38.5416ZM24.9998 27.0833C30.1431 27.0833 34.3748 22.9166 34.3748 17.7083C34.3748 12.565 30.1431 8.33325 24.9998 8.33325C19.7915 8.33325 15.6248 12.565 15.6248 17.7083C15.6248 22.9166 19.7915 27.0833 24.9998 27.0833ZM24.9998 11.4583C28.3853 11.4583 31.2498 14.3228 31.2498 17.7083C31.2498 21.1588 28.3853 23.9583 24.9998 23.9583C21.5493 23.9583 18.7498 21.1588 18.7498 17.7083C18.7498 14.3228 21.5493 11.4583 24.9998 11.4583Z"
            // fill="#292929"
            fill={color}
        />
    </svg>
);

const ProfesionalIcon = ({ color }) => (
    <svg
        width={50}
        height={50}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className=" w-[50px] h-[50px] relative"
        preserveAspectRatio="xMidYMid meet"
    >
        <path
            d="M24.9998 27.0833C30.1431 27.0833 34.3748 22.9166 34.3748 17.7083C34.3748 12.565 30.1431 8.33325 24.9998 8.33325C19.7915 8.33325 15.6248 12.565 15.6248 17.7083C15.6248 22.9166 19.7915 27.0833 24.9998 27.0833ZM24.9998 11.4583C28.3853 11.4583 31.2498 14.3228 31.2498 17.7083C31.2498 21.1588 28.3853 23.9583 24.9998 23.9583C21.5493 23.9583 18.7498 21.1588 18.7498 17.7083C18.7498 14.3228 21.5493 11.4583 24.9998 11.4583ZM30.9243 28.19L27.2785 38.2161L26.0415 32.8124L28.1248 29.1666H21.8748L23.9582 32.8124L22.6561 38.2161L19.0103 28.19C14.1925 28.2551 10.4165 32.0963 10.4165 36.914V38.5416C10.4165 40.2994 11.7837 41.6666 13.5415 41.6666H36.4582C38.1509 41.6666 39.5832 40.2994 39.5832 38.5416V36.914C39.5832 32.0963 35.742 28.2551 30.9243 28.19ZM16.6665 38.5416H13.5415V36.914C13.5415 34.5702 14.9087 32.6171 16.992 31.7057L19.466 38.5416H16.6665ZM36.4582 38.5416H30.4686L33.0076 31.7057C35.0259 32.6171 36.4582 34.5702 36.4582 36.914V38.5416Z"
            // fill="#333333"
            fill={color}
        />
    </svg>
);

const ActionCard = ({
    title = "Soy un Cliente",
    desc = "Voy a contratar un profesional",
    button = "Registrarme",
    type = "client",
    disabled = false,
    showLockIcon = false
}) => {
    const [hover, setHover] = useState(0);
    return (
        <div
            onMouseOver={() => setHover(1)}
            onMouseLeave={() => setHover(0)}
            className={`actionCard transition-all ease-in-out duration-300 flex flex-col justify-start items-start max-w-none sm:max-w-[500px] w-full relative overflow-hidden gap-1 xl:gap-2.5 p-[20px] xl:p-[30px] ${disabled ? "bg-gray-400" : "bg-white"}`}
            style={hover && !disabled ? { boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)" } : {}}
        >
            {disabled && <div className="absolute inset-0" style={{ backgroundColor: 'rgba(223, 223, 223, 0.5)' }}></div>}
            {type === "client" ? (
                <ClientIcon color={hover && !disabled ? COLORS.PRIMARY : "#333"} />
            ) : (
                <ProfesionalIcon color={hover && !disabled ? COLORS.PRIMARY : "#333"} />
            )}
            <div className="flex flex-col justify-start items-start self-stretch relative gap-1 xl:gap-2.5">
            <div className="flex items-center gap- ">
                <p className="font-[Satoshi] w-[396px] text-[34px] text-left text-[#373737]">
                    {title}
                </p>
                {showLockIcon && <FaLock size={20} color="#d80027" />} {/* Ajusta el tamaño del icono */}
            </div>
                <p className="self-stretch w-[396px] text-lg text-left text-[#7b7b7b]">
                    {desc}
                </p>
                <Link to={disabled ? "#" : `/signup/${type}`} className="w-full">
                    <Button
                        stretch
                        color={hover && !disabled ? COLOR.RED : COLOR.BLACK}
                        disabled={disabled}
                    >
                        {button}
                    </Button>
                </Link>
            </div>
        </div>
    );
};
function SignUp() {
    return (
        <AuthWrapper childClass="flex flex-col gap-5 items-center">
        <ActionCard
            title="Soy un Cliente"
            desc="Próximamente"//Voy a contratar un profesional"
            button="Registrarme"
            type="client"
            disabled={true} // Cambia a `false` para habilitar la tarjeta
            showLockIcon={true} // Mostrar el icono de candado
        />
            <ActionCard
                title="Soy un Profesional"
                desc="Voy a prestar un servicio"
                type="professional"
                redirectTo={SignUpProfessional}
                showLockIcon={false} // Mostrar el icono de candado
            />
        </AuthWrapper>
    );
}

export default SignUp;
