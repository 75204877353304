import { create } from "zustand";
import { EXCLUSIVE_LATIN_COUNTRIES } from "../constants/constants";
// import { persist } from "zustand/middleware";

const initialParams = {
    inPerson: true,
    isOnline: true,
    country: EXCLUSIVE_LATIN_COUNTRIES.COSTA_RICA
};

let store = (set) => ({
    form: initialParams,
    handleEditForm: ({ name, value }) => {
        set((state) => ({
            form: { ...state.form, ...{ [name]: value } }
        }));
        console.log(name + " " + value);
    },
    resetForm: () => {
        set(() => ({
            form: initialParams
        }));
    }
});

// store = persist(store, { name: "professionalsSearch-main" });

const useProfSearchStore = create(store);

export { useProfSearchStore };
