import { useState } from "react";
import { noop } from "../../utils/noop";
import Dialog from "../Dialog";
import BankDepositDialog from "./BankDeposit";
import Input from "../ui/Input";
//import { useMobileCheck } from "../../hooks/useDeviceCheck";

function PaymentMethods({ formData, setFormData = noop, handleChange = noop }) {
    const [showBankDialog, setBankDialog] = useState(0);

    //const mobileView = useMobileCheck();

    return (
        <>
            <Dialog
                showDialog={showBankDialog}
                setDialog={setBankDialog}
                main={
                    <BankDepositDialog
                        showDialog={showBankDialog}
                        setDialog={setBankDialog}
                        handleChange={handleChange}
                        formData={formData}
                        setFormData={setFormData}
                    />
                }
            />
            <div className="flex flex-col justify-start items-start self-stretch   relative gap-[5px]">
                {/* w-[744px] */}
                <p className="self-stretch text-sm text-left">
                    {/* w-[744px] */}
                    <span className="self-stretch    text-sm text-left text-[#373737]">
                        Seleccionar formas de pago
                    </span>
                    {/* w-[744px] */}
                    <span className="self-stretch    text-sm text-left text-[#d80027]">
                        *
                    </span>
                </p>
                <div className="flex flex-col items-start self-stretch justify-start ">
                    <div className="flex flex-col items-start self-stretch justify-start  sm:flex-row">
                        {/* <Input
                            type="checkbox"
                            borderAt={
                                mobileView
                                    ? ["top", "left", "right"]
                                    : ["top", "left"]
                            }
                            label="Tarjeta de débito o credito"
                            desc="Se aceptan todas las principales tarjetas. Pago inmediato, de no ser asi notificarme"
                            // onChange={(e) => handleChange(e.target)}
                            onClick={handleChange}
                            value={formData.creditDebit}
                            checked={formData.creditDebit}
                            name="creditDebit"
                            icon={
                                <svg
                                    width={30}
                                    height={31}
                                    viewBox="0 0 30 31"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="  w-[30px] h-[30px] relative"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M24.3359 6.65845H5.625C4.57031 6.65845 3.75 7.51782 3.75 8.53345V22.2834C3.75 23.3381 4.57031 24.1584 5.625 24.1584H24.3359C25.3906 24.1584 26.25 23.3381 26.25 22.2834V8.53345C26.25 7.51782 25.3906 6.65845 24.3359 6.65845ZM5.85938 8.53345H24.1016C24.2578 8.53345 24.3359 8.65063 24.3359 8.76782V10.4084H5.625V8.76782C5.625 8.65063 5.70312 8.53345 5.85938 8.53345ZM24.1016 22.2834H5.85938C5.70312 22.2834 5.625 22.2053 5.625 22.0491V15.4084H24.3359V22.0491C24.3359 22.2053 24.2578 22.2834 24.1016 22.2834ZM11.25 18.3772C11.25 18.1428 11.0156 17.9084 10.7812 17.9084H7.96875C7.69531 17.9084 7.5 18.1428 7.5 18.3772V19.9397C7.5 20.2131 7.69531 20.4084 7.96875 20.4084H10.7812C11.0156 20.4084 11.25 20.2131 11.25 19.9397V18.3772ZM18.75 18.3772C18.75 18.1428 18.5156 17.9084 18.2812 17.9084H12.9688C12.6953 17.9084 12.5 18.1428 12.5 18.3772V19.9397C12.5 20.2131 12.6953 20.4084 12.9688 20.4084H18.2812C18.5156 20.4084 18.75 20.2131 18.75 19.9397V18.3772Z"
                                        fill="#333333"
                                    />
                                </svg>
                            }
                        />
                        <Input
                            type="checkbox"
                            borderAt={
                                mobileView
                                    ? ["top", "left", "right"]
                                    : ["top", "left", "right"]
                            }
                            label="Depósito bancario"
                            desc="Puede tardar de 1 a 3 días, según el tipo de banco"
                            // onChange={(e) => handleChange(e.target)}
                            onClick={(e) => {
                                handleChange(e);
                                setBankDialog(1);
                            }}
                            value={formData.bankDeposit}
                            checked={formData.bankDeposit}
                            name="bankDeposit"
                            icon={
                                <svg
                                    width={30}
                                    height={31}
                                    viewBox="0 0 30 31"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="  w-[30px] h-[30px] relative"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M23.4375 22.5959H23.125V20.4084C23.125 19.9006 22.6953 19.4709 22.1875 19.4709H21.5625V13.5334H19.6875V19.4709H17.8125V13.5334H15.9375V19.4709H14.0625V13.5334H12.1875V19.4709H10.3125V13.5334H8.4375V19.4709H7.8125C7.26562 19.4709 6.875 19.9006 6.875 20.4084V22.5959H6.5625C6.01562 22.5959 5.625 23.0256 5.625 23.5334V24.1584C5.625 24.3538 5.74219 24.4709 5.9375 24.4709H24.0625C24.2188 24.4709 24.375 24.3538 24.375 24.1584V23.5334C24.375 23.0256 23.9453 22.5959 23.4375 22.5959ZM21.25 22.5959H8.75V21.3459H21.25V22.5959ZM24.0625 10.0178L15.5859 6.46313C15.3906 6.38501 15.1953 6.38501 15 6.38501C14.7656 6.38501 14.5703 6.38501 14.375 6.46313L5.89844 10.0178C5.74219 10.0569 5.625 10.2522 5.625 10.4475V11.5022C5.625 11.7756 5.82031 11.9709 6.09375 11.9709H6.875V12.4397C6.875 12.7131 7.07031 12.9084 7.34375 12.9084H22.6562C22.8906 12.9084 23.125 12.7131 23.125 12.4397V11.9709H23.9062C24.1406 11.9709 24.375 11.7756 24.375 11.5022V10.4475C24.375 10.2522 24.2188 10.0569 24.0625 10.0178ZM7.5 11.0334L15 8.22095L22.5 11.0334H7.5Z"
                                        fill="#333333"
                                    />
                                </svg>
                            }
                        />
                    </div>
                    <div className="flex flex-col items-start self-stretch justify-start  sm:flex-row">
                        <Input
                            type="checkbox"
                            borderAt={
                                mobileView
                                    ? ["top", "left", "right"]
                                    : ["top", "left", "bottom"]
                            }
                            label="Criptomonedas"
                            desc="Pago inmediato, de no ser asi notificarme"
                            // onChange={(e) => handleChange(e.target)}
                            onClick={handleChange}
                            value={formData.crypto}
                            checked={formData.crypto}
                            name="crypto"
                            icon={
                                <svg
                                    width={30}
                                    height={31}
                                    viewBox="0 0 30 31"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="  w-[30px] h-[30px] relative"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M19.6094 14.9006C20.6641 14.3538 21.3672 13.3772 21.2109 11.7366C21.0156 9.47095 19.1797 8.72876 16.7188 8.53345V5.40845H14.8438V8.45532C14.3359 8.45532 13.8281 8.45532 13.3203 8.45532V5.40845H11.4453V8.53345C10.7422 8.53345 9.92188 8.53345 7.61719 8.53345V10.5647C9.14062 10.5256 9.92188 10.4084 10.0781 11.385V19.8616C9.96094 20.6428 9.375 20.5256 8.00781 20.5256L7.61719 22.7522C11.0938 22.7522 11.4453 22.7522 11.4453 22.7522V25.4084H13.3203V22.7913C13.8281 22.8303 14.3359 22.8303 14.8438 22.8303V25.4084H16.7188V22.7522C19.8828 22.5959 22.0312 21.8147 22.3047 18.8069C22.5391 16.4241 21.4062 15.3303 19.6094 14.9006ZM13.3594 10.6819C14.4531 10.6819 17.7734 10.3694 17.7734 12.5959C17.7734 14.7053 14.4531 14.4709 13.3594 14.4709V10.6819ZM13.3594 20.5256V16.3459C14.6484 16.3459 18.5547 15.9944 18.5547 18.4553C18.5547 20.7991 14.6484 20.5256 13.3594 20.5256Z"
                                        fill="#333333"
                                    />
                                </svg>
                            }
                        />*/}
                        <Input
                            type="checkbox"
                            borderAt={["top", "left", "bottom", "right"]}
                            label="Efectivo"
                            desc="Se llevara a cabo cuando los Clientes decidan pagar de esta manera"
                            // onChange={(e) => handleChange(e.target)}
                            onClick={handleChange}
                            value={formData.cash}
                            checked={formData.cash}
                            name="cash"
                            icon={
                                <svg
                                    width={30}
                                    height={31}
                                    viewBox="0 0 30 31"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="  w-[30px] h-[30px] relative"
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M26.25 7.90845H3.75C3.04688 7.90845 2.5 8.49438 2.5 9.15845V21.6584C2.5 22.3616 3.04688 22.9084 3.75 22.9084H26.25C26.9141 22.9084 27.5 22.3616 27.5 21.6584V9.15845C27.5 8.49438 26.9141 7.90845 26.25 7.90845ZM25.625 18.5334C24.2188 18.5334 23.125 19.6663 23.125 21.0334H6.875C6.875 19.6663 5.74219 18.5334 4.375 18.5334V12.2834C5.74219 12.2834 6.875 11.1897 6.875 9.78345H23.125C23.125 11.1897 24.2188 12.2834 25.625 12.2834V18.5334ZM15 11.6584C13.2422 11.6584 11.875 13.3381 11.875 15.4084C11.875 17.5178 13.2422 19.1584 15 19.1584C16.7188 19.1584 18.125 17.5178 18.125 15.4084C18.125 13.3381 16.7188 11.6584 15 11.6584Z"
                                        fill="#333333"
                                    />
                                </svg>
                            }
                        />
                    </div>
                    <div className="flex justify-start items-center self-stretch   relative gap-2.5 p-2.5 bg-[#f5f8ff] border border-[#cdcdcd]">
                        <p className="flex-grow sm:w-[724px] text-xs text-center text-[#373737]">
                            Puedes omitir agregar las cuentas y hacerlo
                            posteriormente desde configuración
                        </p>
                    </div>
                    <div className="cursor-pointer hover:opacity-80 flex justify-center items-center self-stretch   h-[60px] relative px-4 border border-[#cdcdcd]">
                        <div className="flex justify-center items-center   relative gap-2.5">
                            <p className="  text-sm font-bold text-center text-[#373737]">
                                Mostrar todas las opciones de pago
                            </p>
                        </div>
                        <svg
                            width={25}
                            height={25}
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6 "
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M19.2812 9.65845L18.6875 9.03345C18.5312 8.8772 18.2812 8.8772 18.1562 9.03345L12.5 14.6897L6.8125 9.03345C6.6875 8.8772 6.4375 8.8772 6.28125 9.03345L5.6875 9.65845C5.53125 9.78345 5.53125 10.0334 5.6875 10.1897L12.2188 16.7209C12.375 16.8772 12.5938 16.8772 12.75 16.7209L19.2812 10.1897C19.4375 10.0334 19.4375 9.78345 19.2812 9.65845Z"
                                fill="#333333"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentMethods;
