import EmptyTableSupport from "./EmptyTableSupport";
import TableSupport from "./TableSupport";

const ResultTable = () => {

    const tableData = [
        {
            id: "345656567",
            message: "El profesional no responde mis mensajes",
            category: "General",
            timestamp: "03/06/2023 - 10:57am",
            support: {
                name: "Antonio G. (Soporte)",
                timestamp: "03/06/2023 - 10:57am",
            },
        },
        {
            id: "345656567",
            message: "El profesional no responde mis mensajes",
            category: "General",
            timestamp: "03/06/2023 - 10:57am",
            support: {
                name: "Antonio G. (Soporte)",
                timestamp: "03/06/2023 - 10:57am",
            },
        },
        {
            id: "345656567",
            message: "El profesional no responde mis mensajes",
            category: "General",
            timestamp: "03/06/2023 - 10:57am",
            support: {
                name: "Antonio G. (Soporte)",
                timestamp: "03/06/2023 - 10:57am",
            },
        },
        {
            id: "345656567",
            message: "El profesional no responde mis mensajes",
            category: "General",
            timestamp: "03/06/2023 - 10:57am",
            support: {
                name: "Antonio G. (Soporte)",
                timestamp: "03/06/2023 - 10:57am",
            },
        },
    ];

    const EMPTY = tableData.length === 0;

    if (EMPTY)
        return <EmptyTableSupport />

    return  <TableSupport tableData={tableData} />

};

export default ResultTable;
