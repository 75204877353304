import React from "react";
import { twMerge } from "tailwind-merge";
import "./styles.css";

function WhatsappInvite({
    className = "",
    hover = true,

    width = 67,
    height = 67
}) {
    return (
        <div
            className={twMerge(
                `flex waShadow cursor-pointer justify-center items-center w-[76.74px] h-[76.74px] relative gap-[27.407394409179688px] p-[5.481479167938232px] rounded-[21.93px] ${
                    hover ? "bg-white" : "bg-[#daffe2]"
                } hover:bg-[#daffe2]`,
                className
            )}
            // style={{ boxShadow: "0px 16.44443702697754px 27.407394409179688px 0 rgba(21,87,255,0.25)" }}
        >
            <svg
                width={width}
                height={height}
                viewBox="0 0 67 67"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative flex-grow-0 flex-shrink-0"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M46.9907 19.8378C43.4792 16.2406 38.6829 14.1851 33.544 14.1851C23.0949 14.1851 14.5301 22.7499 14.5301 33.1989C14.5301 36.6249 15.4722 39.8795 17.0995 42.7059L14.4445 52.5554L24.4653 49.986C27.2917 51.442 30.375 52.2985 33.544 52.2985C44.0787 52.2985 52.8148 43.7337 52.8148 33.2846C52.8148 28.1457 50.588 23.4351 46.9907 19.8378ZM33.544 49.0438C30.7176 49.0438 27.9769 48.273 25.4931 46.817L24.9792 46.4744L18.9838 48.1017L20.6111 42.2776L20.1829 41.6781C18.6412 39.1087 17.7847 36.1966 17.7847 33.1989C17.7847 24.5485 24.8935 17.4397 33.6296 17.4397C37.8264 17.4397 41.7662 19.067 44.7639 22.0647C47.7616 25.0624 49.5602 29.0022 49.5602 33.2846C49.5602 41.935 42.2801 49.0438 33.544 49.0438ZM42.2801 37.2244C41.7662 36.9675 39.4537 35.854 39.0255 35.6827C38.5972 35.5114 38.2546 35.4258 37.912 35.9397C37.6551 36.3679 36.713 37.4813 36.456 37.8239C36.1134 38.0809 35.8565 38.1665 35.4282 37.9096C32.6019 36.5392 30.8032 35.4258 28.919 32.2568C28.4051 31.4003 29.4329 31.486 30.2894 29.6874C30.4607 29.3448 30.375 29.0878 30.2894 28.8309C30.2037 28.5739 29.1759 26.2614 28.8333 25.3193C28.4051 24.3772 28.0625 24.4628 27.7199 24.4628C27.463 24.4628 27.1204 24.4628 26.8634 24.4628C26.5208 24.4628 26.007 24.5485 25.5787 25.0624C25.1505 25.5763 23.9514 26.6897 23.9514 29.0022C23.9514 31.4003 25.5787 33.6272 25.8357 33.9698C26.0926 34.2267 29.1759 39.023 33.9722 41.0786C36.9699 42.4489 38.169 42.5346 39.7107 42.2776C40.5671 42.192 42.4514 41.1642 42.8796 40.0508C43.3079 38.9374 43.3079 37.9952 43.1366 37.8239C43.0509 37.567 42.7083 37.4813 42.2801 37.2244Z"
                    fill="#228B22"
                />
            </svg>
        </div>
    );
}

export default WhatsappInvite;
