import React, { useState } from "react";
import HIWFirstContent from "../components/howitworks/HIWFirstContent";
import HIWSecondContent from "../components/howitworks/HIWSecondContent";
import Hero from "../components/howitworks/Hero";
import Navigate from "../components/howitworks/Navigate";
import SecondHero from "../components/howitworks/SecondHero";

function HowItWorks() {
    const [firstPage, setFirstPage] = useState(true);
    return (
        <>
            {!firstPage ? (
                <div className="mx-[20px] sm:mx-[7%]">
                    <SecondHero />
                </div>
            ) : (
                <Hero />
            )}
            <Navigate firstPage={firstPage} setFirstPage={setFirstPage} />
            {firstPage ? (
                <HIWFirstContent
                    className={firstPage ? "opacity-0 h-0 w-0" : ""}
                />
            ) : (
                <HIWSecondContent
                    className={!firstPage ? "opacity-0 h-0 w-0" : ""}
                />
            )}
        </>
    );
}

export default HowItWorks;
