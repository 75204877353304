import Dialog from "./Dialog";
import SelectHireConsult from "./modal/SelectHireConsult";
import SelectHireConsultUrgent from "./modal/SelectHireConsultUrgent";
//import Discount from "./Discount";
//import ApplyHireConsult from "./modal/ApplyHireConsult";



function DiscountTemplate({ showDialog, setDialog, urgent }) {
    return (
        <Dialog
            showDialog={showDialog === 1} // Mostrar el diálogo si showDialog es 1
            setDialog={setDialog}
            main={
                urgent ? (
                    <SelectHireConsultUrgent
                        closeDialog={() => setDialog(0)}
                        contrato={false}
                    />
                ) : (
                    <SelectHireConsult
                        closeDialog={() => setDialog(0)}
                        contrato={false}
                    />
                )
            }
        />
    );
}

export default DiscountTemplate;





// Codigo Ricardo
// function DiscountTemplate({ showDialog, setDialog }) {
//     return (
//         <Dialog
//             showDialog={showDialog}
//             setDialog={setDialog}
//             main={
//                 <Discount
//                     header1="Contratación urgente"
//                     header2="Contratación recomendada"
//                     set1={[
//                         `Se enviará una alerta a todos los profesionales de tu área, y atenderán a prontitud tu solicitud.`,
//                         `Se notificará al Admin cada contratación urgente y este puede seleccionar un profesional para que realice el trabajo.`,
//                         `El Admin siempre estará supervisando y dándole seguimiento al proyecto.`
//                     ]}
//                     set2={[
//                         `Se enviará una alerta a todos los profesionales de tu área, y atenderán a prontitud tu solicitud.`,
//                         `Puede elegir un profesional según su conveniencia y necesidad.`,
//                         `Puede revisar las reseñas y reputación y tomar una mejor decisión.`,
//                         `Puede solicitar uno o varios abogados para su caso.`
//                     ]}
//                     dHeader=""          // Vacío para ocultar el encabezado del cupón
//                     dSubHeader=""       // Vacío para ocultar el subtítulo del cupón
//                     dText=""            // Vacío para ocultar el texto del cupón
//                     closeDialog={() => setDialog(0)}
//                     discountPercent=""  // Vacío para ocultar el porcentaje del cupón
//                 />
//                 // <ApplyHireConsult closeDialog={() => setDialog(0)} contrato={true} />
//             }
//         />
//     );
// }


// export default DiscountTemplate;






// Codigo original
// function DiscountTemplate({ showDialog, setDialog }) {
//     return (
//         <Dialog
//             showDialog={showDialog}
//             setDialog={setDialog}
//             main={
//                 // <Discount
//                 //     header1="Contratación urgente"
//                 //     header2="Contratación recomendada"
//                 //     set1={[
//                 //         `Se enviará una alerta a todos los profesionales de tu área, y atenderán a prontitud tu solicitud.`,
//                 //         `Se notificará al Admin cada contratación urgente y este puede seleccionar un profesional para que realice el trabajo.`,
//                 //         `El Admin siempre estará  supervisando y dandole seguimiento al proyecto.`
//                 //     ]}
//                 //     set2={[
//                 //         `Se enviará una alerta a todos los profesionales de tu área, y atenderán a prontitud tu solicitud.`,
//                 //         `Puede elegir un profesional según su conveniencia y necesidad.`,
//                 //         `Puede revisar las reseñas y reputación y tomar una mejor decisión.`,
//                 //         `Puede solicitar uno o varios abogados para su caso.`
//                 //     ]}
//                 //     dHeader="¡Obtén tu cupón de bienvenida!"
//                 //     dSubHeader="Regístrese correctamente e inicie sesión y reclame su cupón"
//                 //     dText="Aplica solo para su primer contrato"
//                 //     closeDialog={() => setDialog(0)}
//                 //     discountPercent="+5"
//                 // />
//                 <SelectHireConsult
//                     closeDialog={() => setDialog(0)}
//                     contrato={false}
//                 />
//                 // <ApplyHireConsult closeDialog={() => setDialog(0)} contrato={true}/>
//             }
//         />
//     );
// }

// export default DiscountTemplate;
