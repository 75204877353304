import { SECTORS } from "../../constants/constants";
import { PAGE_TYPES } from "../../constants/platform";
import Input from "../ui/Input";
import AddTelephoneButton from "../ui/buttons/AddTelephoneButton";

function SearchPageInput({
    handleChange,
    handleFormSubmission,
    formData,
    type,
    CONTENT
}) {
    return (
        <div className="flex flex-col justify-start items-center w-full  lg:w-[816px] gap-5 p-4 lg:p-[30px] bg-white border border-[#cdcdcd]">
            <form
                onSubmit={handleFormSubmission}
                className="flex flex-col items-center self-stretch justify-start gap-3 lg:flex-row"
            >
                <Input
                    addClass="flex-shrink shrink"
                    name="search"
                    type="text"
                    value={formData.search}
                    onChange={(e) => handleChange(e.target)}
                    placeholder={CONTENT[type].search.placeholder}
                    icon={
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            // className="relative w-6 h-6 "
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                fill="#333333"
                            />
                        </svg>
                    }
                />
                {type === PAGE_TYPES.TELE ? <AddTelephoneButton /> : null}
            </form>
            <div className="flex flex-col lg:flex-row justify-start items-center self-stretch   gap-[30px]">
                <div className="flex justify-start items-center flex-grow relative gap-2.5">
                    {CONTENT[type].search.underIcon}
                    <p className="flex-grow lg:w-96 text-base font-bold text-left text-[#373737]">
                        {CONTENT[type].search.resultsCount} {` resultados`}
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row justify-start items-center   relative gap-2.5">
                    <p className=" flex-grow-0 shrink-0 text-sm lg:text-md    text-left text-[#373737]">
                        Ordenar por
                    </p>

                    <Input
                        className={" text-sm lg:text-md w-[200px] "}
                        addInputClass="h-9 py-2"
                        name="sector"
                        value={formData.sector}
                        onChange={(e) => handleChange(e.target)}
                        type="dropdown"
                        selectList={SECTORS}
                        icon={
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M11.9995 16.3006C11.4276 16.3006 10.8561 16.0801 10.4231 15.6471L3.90308 9.12707C3.80835 9.03233 3.80835 8.86892 3.90308 8.77418C3.99782 8.67944 4.16124 8.67944 4.25598 8.77418L10.776 15.2942C11.4512 15.9694 12.5478 15.9694 13.2231 15.2942L19.7431 8.77418C19.8378 8.67944 20.0012 8.67944 20.096 8.77418C20.1907 8.86892 20.1907 9.03233 20.096 9.12707L20.4495 9.48062L20.096 9.12707L13.576 15.6471C13.1429 16.0801 12.5715 16.3006 11.9995 16.3006Z"
                                    fill="#292929"
                                    stroke="#292929"
                                />
                            </svg>
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default SearchPageInput;
