import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useToggleHeaderFooter from "../hooks/useToggleHeaderFooter";
import PlatformHeader from "./platform/PlatformHeader";
import { Button, COLOR, SIZE } from "./ui/buttons/Button";

function Header({ platformSubHeader = false }) {
    const { toggleValue } = useToggleHeaderFooter();
    const location = useLocation();
    const [menu, setMenu] = useState(0);

    const hideHeaderArr = ["urgent-contract/processing"];
    const hideHeader = hideHeaderArr.some((el) =>
        location.pathname.includes(el)
    );

    if (hideHeader) {
        return null;
    } else if (window.location.pathname.includes("platform")) {
        return <PlatformHeader subHeader={platformSubHeader} />;
    }

    return toggleValue ? (
        <div className="flex fixed top-0 justify-between items-center w-[100vw] sm:w-full bg-white px-[7%] py-[20px] z-10">
            <Link to="/">
                <div className="cursor-pointer flex justify-start items-center  relative gap-2.5">
                    <svg
                        width={34}
                        height={34}
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" w-[33.1px] h-[33.87px] flex-grow-0 flex-shrink-0"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M32.5867 33.8067L0.510792 33.9339C0.376486 33.9339 0.247682 33.8805 0.152713 33.7856C0.0577441 33.6906 0.00439453 33.5618 0.00439453 33.4275C0.00439453 33.2932 0.0577441 33.1644 0.152713 33.0694C0.247682 32.9744 0.376486 32.9211 0.510792 32.9211L32.5867 32.7939C32.6534 32.7936 32.7195 32.8065 32.7812 32.8318C32.8429 32.8571 32.899 32.8944 32.9463 32.9414C32.9935 32.9885 33.031 33.0444 33.0566 33.106C33.0822 33.1676 33.0954 33.2336 33.0954 33.3003C33.0954 33.367 33.0822 33.4331 33.0566 33.4946C33.031 33.5562 32.9935 33.6122 32.9463 33.6592C32.899 33.7063 32.8429 33.7435 32.7812 33.7688C32.7195 33.7942 32.6534 33.807 32.5867 33.8067Z"
                            fill="#D80027"
                        />
                        <path
                            d="M15.1967 11.934C15.0624 11.934 14.9336 11.8806 14.8386 11.7856C14.7436 11.6907 14.6903 11.5619 14.6903 11.4276C14.6903 8.81607 13.9818 7.05388 12.4649 5.87984C12.412 5.83932 12.3677 5.78879 12.3344 5.73112C12.3011 5.67345 12.2795 5.60978 12.2709 5.54376C12.2622 5.47774 12.2667 5.41066 12.284 5.34637C12.3013 5.28207 12.3311 5.22182 12.3717 5.16906C12.4124 5.11607 12.4632 5.07166 12.5212 5.03839C12.5791 5.00511 12.6431 4.98363 12.7094 4.97519C12.7756 4.96675 12.8429 4.9715 12.9074 4.98919C12.9718 5.00687 13.0321 5.03713 13.0848 5.07823C14.847 6.44074 15.7031 8.51631 15.7031 11.4366C15.7007 11.5694 15.6463 11.6958 15.5516 11.7889C15.4569 11.8819 15.3294 11.934 15.1967 11.934Z"
                            fill="#D80027"
                        />
                        <path
                            d="M18.142 11.9338C18.0753 11.9341 18.0092 11.9212 17.9475 11.8959C17.8858 11.8706 17.8297 11.8333 17.7824 11.7862C17.7352 11.7392 17.6976 11.6833 17.6721 11.6217C17.6465 11.5601 17.6333 11.4941 17.6333 11.4274C17.6333 8.51612 18.4917 6.43147 20.2539 5.06895C20.3064 5.02792 20.3665 4.9977 20.4307 4.98005C20.495 4.9624 20.5621 4.95766 20.6282 4.9661C20.6943 4.97455 20.7581 4.99602 20.8158 5.02926C20.8736 5.06251 20.9242 5.10687 20.9647 5.15979C21.0057 5.21229 21.0359 5.2724 21.0536 5.33666C21.0712 5.40091 21.0759 5.46803 21.0675 5.53412C21.059 5.60022 21.0376 5.66399 21.0043 5.72174C20.9711 5.77948 20.9267 5.83007 20.8738 5.87057C19.3546 7.0446 18.6484 8.80679 18.6484 11.4183C18.6496 11.4855 18.6374 11.5524 18.6125 11.6149C18.5875 11.6774 18.5504 11.7343 18.5033 11.7822C18.4561 11.8302 18.3999 11.8684 18.3379 11.8944C18.2758 11.9204 18.2092 11.9338 18.142 11.9338Z"
                            fill="#D80027"
                        />
                        <path
                            d="M27.3161 4.97141C25.6048 4.97381 23.9643 5.65484 22.7544 6.86513C21.5446 8.07542 20.8641 9.71616 20.8623 11.4275C20.8623 11.5618 20.9157 11.6906 21.0106 11.7855C21.1056 11.8805 21.2344 11.9339 21.3687 11.9339C21.4352 11.9339 21.5011 11.9208 21.5625 11.8953C21.6239 11.8699 21.6798 11.8326 21.7268 11.7855C21.7738 11.7385 21.8111 11.6827 21.8366 11.6213C21.862 11.5598 21.8751 11.494 21.8751 11.4275C21.8751 9.98202 22.4493 8.59577 23.4714 7.57369C24.4935 6.5516 25.8797 5.9774 27.3252 5.9774C27.884 5.97889 28.4371 6.09043 28.9529 6.30567C29.4686 6.52092 29.9369 6.83563 30.331 7.23186C30.7251 7.62808 31.0373 8.09805 31.2498 8.61493C31.4623 9.13182 31.5709 9.68549 31.5694 10.2443C31.5664 11.1175 31.2179 11.954 30.6001 12.571C29.9822 13.188 29.1453 13.5353 28.2721 13.5371C27.599 13.5365 26.9536 13.2688 26.4776 12.7928C26.0016 12.3168 25.7339 11.6714 25.7333 10.9983C25.7339 10.4871 25.9372 9.99709 26.2987 9.63566C26.6601 9.27423 27.1501 9.07091 27.6613 9.07031C28.0169 9.07053 28.3599 9.20239 28.6241 9.44046C28.3902 9.51322 28.1858 9.65896 28.0407 9.85633C27.8956 10.0537 27.8176 10.2923 27.818 10.5373C27.818 10.842 27.939 11.1343 28.1545 11.3498C28.37 11.5653 28.6623 11.6863 28.967 11.6863C29.5983 11.6863 30.1138 11.2322 30.1138 10.3874C30.1138 8.98402 29.017 8.05524 27.6613 8.05524C26.8783 8.05524 26.1274 8.36626 25.5738 8.91989C25.0202 9.47352 24.7091 10.2244 24.7091 11.0074C24.711 11.9507 25.0873 12.8548 25.7554 13.5208C26.4235 14.1867 27.3288 14.5602 28.2721 14.559C29.4164 14.559 30.5139 14.1044 31.323 13.2953C32.1322 12.4861 32.5868 11.3887 32.5868 10.2443C32.5871 9.552 32.451 8.86638 32.1862 8.22665C31.9215 7.58692 31.5333 7.00562 31.0438 6.51595C30.5544 6.02628 29.9732 5.63785 29.3336 5.37283C28.694 5.10781 28.0084 4.9714 27.3161 4.97141Z"
                            fill="#D80027"
                        />
                        <path
                            d="M5.51808 4.97141C7.22978 4.97321 8.87086 5.65398 10.0812 6.86433C11.2916 8.07469 11.9723 9.71577 11.9741 11.4275C11.9741 11.5618 11.9208 11.6906 11.8258 11.7855C11.7309 11.8805 11.602 11.9339 11.4677 11.9339C11.3334 11.9339 11.2046 11.8805 11.1097 11.7855C11.0147 11.6906 10.9613 11.5618 10.9613 11.4275C10.9613 9.98202 10.3871 8.59577 9.36505 7.57369C8.34296 6.5516 6.95672 5.9774 5.51128 5.9774C4.95242 5.97889 4.39933 6.09043 3.88359 6.30567C3.36784 6.52092 2.89955 6.83563 2.50543 7.23186C2.11132 7.62808 1.79911 8.09805 1.58662 8.61493C1.37414 9.13182 1.26554 9.68549 1.26704 10.2443C1.26824 11.1191 1.61624 11.9576 2.23477 12.5762C2.8533 13.1947 3.69186 13.5427 4.56659 13.5439C5.23974 13.5433 5.88515 13.2756 6.36114 12.7996C6.83714 12.3236 7.10481 11.6782 7.10541 11.0051C7.10481 10.4935 6.90119 10.0032 6.53926 9.64167C6.17734 9.28017 5.68672 9.07712 5.17518 9.07712C4.82019 9.07726 4.4779 9.20917 4.21461 9.44727C4.4485 9.52003 4.65296 9.66577 4.79803 9.86314C4.9431 10.0605 5.02115 10.2992 5.02076 10.5441C5.02016 10.8487 4.89892 11.1406 4.68356 11.3559C4.4682 11.5713 4.17627 11.6926 3.87171 11.6932C3.24041 11.6932 2.72265 11.239 2.72265 10.3942C2.72265 8.99083 3.82175 8.06205 5.17518 8.06205C5.95813 8.06205 6.70901 8.37308 7.26264 8.9267C7.81627 9.48033 8.1273 10.2312 8.1273 11.0142C8.12369 11.956 7.74689 12.8579 7.07945 13.5224C6.41201 14.1868 5.50838 14.5596 4.56659 14.559C3.42228 14.559 2.32484 14.1044 1.51569 13.2953C0.706537 12.4861 0.251953 11.3887 0.251953 10.2443C0.251952 8.84705 0.806565 7.50689 1.79396 6.51822C2.78136 5.52955 4.12079 4.97321 5.51808 4.97141Z"
                            fill="#D80027"
                        />
                        <path
                            d="M23.1903 3.53637H9.65142C9.51711 3.53637 9.38831 3.48301 9.29334 3.38804C9.19837 3.29307 9.14502 3.16427 9.14502 3.02996C9.14502 2.96346 9.15812 2.89761 9.18356 2.83617C9.20901 2.77473 9.24631 2.71891 9.29334 2.67188C9.34036 2.62486 9.39618 2.58756 9.45762 2.56211C9.51906 2.53666 9.58492 2.52356 9.65142 2.52356H23.1948C23.3291 2.52356 23.4579 2.57691 23.5529 2.67188C23.6479 2.76685 23.7012 2.89565 23.7012 3.02996C23.7012 3.09685 23.688 3.16307 23.6623 3.22481C23.6365 3.28655 23.5988 3.34257 23.5513 3.38966C23.5038 3.43674 23.4475 3.47395 23.3855 3.49913C23.3235 3.52431 23.2572 3.53697 23.1903 3.53637Z"
                            fill="#D80027"
                        />
                        <path
                            d="M7.34188 3.53622C6.88193 3.53562 6.44098 3.35264 6.11574 3.02741C5.79051 2.70217 5.60753 2.26124 5.60693 1.80129C5.60753 1.34134 5.79051 0.900395 6.11574 0.575162C6.44098 0.249928 6.88193 0.0669461 7.34188 0.0663452H25.4951C25.9551 0.0669461 26.396 0.249928 26.7213 0.575162C27.0465 0.900395 27.2295 1.34134 27.2301 1.80129C27.2295 2.26124 27.0465 2.70217 26.7213 3.02741C26.396 3.35264 25.9551 3.53562 25.4951 3.53622C25.4284 3.53652 25.3624 3.52365 25.3007 3.49833C25.239 3.47302 25.1829 3.43576 25.1356 3.38871C25.0883 3.34165 25.0508 3.28573 25.0252 3.22414C24.9996 3.16255 24.9865 3.09651 24.9865 3.02982C24.9865 2.96313 24.9996 2.89709 25.0252 2.8355C25.0508 2.77392 25.0883 2.71799 25.1356 2.67094C25.1829 2.62388 25.239 2.58663 25.3007 2.56131C25.3624 2.536 25.4284 2.52312 25.4951 2.52342C25.6863 2.52282 25.8694 2.44647 26.0043 2.31111C26.1392 2.17575 26.215 1.99242 26.215 1.80129C26.215 1.61016 26.1392 1.42682 26.0043 1.29146C25.8694 1.1561 25.6863 1.07975 25.4951 1.07915H7.34188C7.15075 1.07975 6.96765 1.1561 6.83272 1.29146C6.69778 1.42682 6.622 1.61016 6.622 1.80129C6.622 1.99242 6.69778 2.17575 6.83272 2.31111C6.96765 2.44647 7.15075 2.52282 7.34188 2.52342C7.40857 2.52312 7.47466 2.536 7.53636 2.56131C7.59806 2.58663 7.65416 2.62388 7.70142 2.67094C7.74869 2.71799 7.78619 2.77392 7.81178 2.8355C7.83737 2.89709 7.85055 2.96313 7.85055 3.02982C7.85055 3.09651 7.83737 3.16255 7.81178 3.22414C7.78619 3.28573 7.74869 3.34165 7.70142 3.38871C7.65416 3.43576 7.59806 3.47302 7.53636 3.49833C7.47466 3.52365 7.40857 3.53652 7.34188 3.53622Z"
                            fill="#D80027"
                        />
                        <path
                            d="M24.373 14.9224C24.3308 14.9227 24.2888 14.9174 24.2481 14.9065C19.108 13.5901 13.7192 13.5901 8.57914 14.9065C8.51472 14.9233 8.44763 14.9271 8.38171 14.9179C8.31579 14.9087 8.25234 14.8866 8.19497 14.8529C8.1376 14.8191 8.08746 14.7744 8.04739 14.7213C8.00732 14.6681 7.97812 14.6076 7.96145 14.5432C7.92834 14.4128 7.94822 14.2746 8.01674 14.1589C8.08526 14.0431 8.19683 13.9592 8.32706 13.9255C13.6295 12.5675 19.1886 12.5675 24.491 13.9255C24.5555 13.9422 24.616 13.9714 24.6692 14.0114C24.7223 14.0515 24.767 14.1017 24.8008 14.159C24.8345 14.2164 24.8566 14.2798 24.8658 14.3458C24.875 14.4117 24.8711 14.4788 24.8544 14.5432C24.827 14.6505 24.7649 14.7458 24.6779 14.8144C24.5909 14.8829 24.4837 14.9209 24.373 14.9224Z"
                            fill="#D80027"
                        />
                        <path
                            d="M12.4556 29.6351C12.3889 29.6351 12.3229 29.622 12.2613 29.5964C12.1997 29.5708 12.1438 29.5333 12.0967 29.486C12.0497 29.4387 12.0124 29.3826 11.9871 29.3209C11.9618 29.2592 11.9489 29.1931 11.9492 29.1265V18.2263C11.9492 18.1598 11.9623 18.094 11.9878 18.0325C12.0132 17.9711 12.0505 17.9153 12.0975 17.8682C12.1446 17.8212 12.2004 17.7839 12.2618 17.7585C12.3233 17.733 12.3891 17.7199 12.4556 17.7199C12.5223 17.7196 12.5884 17.7325 12.6501 17.7578C12.7118 17.7831 12.7679 17.8204 12.8152 17.8674C12.8624 17.9145 12.8999 17.9704 12.9255 18.032C12.9511 18.0936 12.9643 18.1596 12.9643 18.2263V29.1265C12.9643 29.2614 12.9107 29.3907 12.8153 29.4861C12.7199 29.5815 12.5905 29.6351 12.4556 29.6351Z"
                            fill="#D80027"
                        />
                        <path
                            d="M16.4185 29.6352C16.3518 29.6352 16.2858 29.622 16.2242 29.5964C16.1626 29.5708 16.1067 29.5333 16.0596 29.4861C16.0126 29.4388 15.9753 29.3827 15.95 29.321C15.9247 29.2593 15.9118 29.1932 15.9121 29.1265V17.695C15.9121 17.5607 15.9655 17.4319 16.0604 17.3369C16.1554 17.242 16.2842 17.1886 16.4185 17.1886C16.5528 17.1886 16.6816 17.242 16.7766 17.3369C16.8716 17.4319 16.9249 17.5607 16.9249 17.695V29.1265C16.9252 29.1932 16.9123 29.2593 16.887 29.321C16.8617 29.3827 16.8244 29.4388 16.7774 29.4861C16.7303 29.5333 16.6744 29.5708 16.6128 29.5964C16.5512 29.622 16.4852 29.6352 16.4185 29.6352Z"
                            fill="#D80027"
                        />
                        <path
                            d="M20.3812 29.6351C20.2463 29.6351 20.1169 29.5815 20.0215 29.4861C19.9261 29.3907 19.8726 29.2614 19.8726 29.1265V18.2263C19.8726 18.1596 19.8857 18.0936 19.9113 18.032C19.9369 17.9704 19.9744 17.9145 20.0217 17.8674C20.0689 17.8204 20.125 17.7831 20.1867 17.7578C20.2484 17.7325 20.3145 17.7196 20.3812 17.7199C20.5155 17.7199 20.6443 17.7733 20.7393 17.8682C20.8343 17.9632 20.8876 18.092 20.8876 18.2263V29.1265C20.8879 29.1931 20.875 29.2592 20.8497 29.3209C20.8244 29.3826 20.7872 29.4387 20.7401 29.486C20.6931 29.5333 20.6371 29.5708 20.5755 29.5964C20.514 29.622 20.4479 29.6351 20.3812 29.6351Z"
                            fill="#D80027"
                        />
                        <path
                            d="M32.3303 29.6354H23.8668V16.9185C18.9722 15.7309 13.8645 15.7309 8.96991 16.9185V29.6354H0.506403C0.43971 29.6354 0.373684 29.6222 0.312096 29.5966C0.250508 29.571 0.194573 29.5335 0.14752 29.4862C0.100466 29.439 0.0632141 29.3829 0.037899 29.3212C0.0125838 29.2595 -0.00029398 29.1934 5.09207e-06 29.1267C5.09207e-06 28.9924 0.0533547 28.8636 0.148324 28.7686C0.243292 28.6736 0.372097 28.6203 0.506403 28.6203H7.95484V16.1305L8.33634 16.0329C13.6388 14.6749 19.1979 14.6749 24.5003 16.0329L24.8818 16.1305V28.6203H32.3303C32.4646 28.6203 32.5934 28.6736 32.6883 28.7686C32.7833 28.8636 32.8367 28.9924 32.8367 29.1267C32.837 29.1934 32.8241 29.2595 32.7988 29.3212C32.7734 29.3829 32.7362 29.439 32.6891 29.4862C32.6421 29.5335 32.5862 29.571 32.5246 29.5966C32.463 29.6222 32.3969 29.6354 32.3303 29.6354Z"
                            fill="#D80027"
                        />
                    </svg>
                    <p className="font-[Enriqueta] text-[25.104915618896484px] flex-grow-0 flex-shrink-0  text-left text-[#373737]">
                        Legal EPROF
                    </p>
                </div>
            </Link>

            <div className="hidden justify-center items-start flex-grow gap-[29px]  xl:flex">
                <a
                    href="/"
                    className=" transition-all duration-500 flex flex-col justify-start items-center  w-[45px] relative"
                >
                    <p
                        className={`text-base ${
                            location.pathname === "/" ? "font-bold" : ""
                        } text-left text-[#373737]`}
                    >
                        Inicio
                    </p>
                    <div
                        className={`self-stretch  h-px ${
                            location.pathname === "/"
                                ? "opacity-100"
                                : "opacity-0"
                        } bg-[#d80027]`}
                    />
                </a>
                <a
                    href="/howitworks"
                    className=" transition-all duration-500 flex flex-col justify-start items-center  w-[131px] relative"
                >
                    <p
                        className={`text-base ${
                            location.pathname === "/howitworks"
                                ? "font-bold"
                                : ""
                        } text-left text-[#373737]`}
                    >
                        ¿Cómo Funciona?
                    </p>
                    <div
                        className={`self-stretch    h-px ${
                            location.pathname === "/howitworks"
                                ? "opacity-100"
                                : "opacity-0"
                        } bg-[#d80027]`}
                    />
                </a>
                <a
                    href="/professionals"
                    className=" transition-all duration-500 flex flex-col justify-start items-center  w-[102px] relative"
                >
                    <p
                        className={`text-base ${
                            location.pathname === "/professionals"
                                ? "font-bold"
                                : ""
                        } text-left text-[#373737]`}
                    >
                        Profesionales
                    </p>
                    <div
                        className={`self-stretch    h-px ${
                            location.pathname === "/professionals"
                                ? "opacity-100"
                                : "opacity-0"
                        } bg-[#d80027]`}
                    />
                </a>
                <a
                    href="/blog"
                    className=" transition-all duration-500 flex flex-col justify-start items-center  w-[35px] relative"
                >
                    <p
                        className={`text-base ${
                            location.pathname === "/blog" ? "font-bold" : ""
                        } text-left text-[#373737]`}
                    >
                        Blog
                    </p>
                    <div
                        className={`self-stretch    h-px ${
                            location.pathname === "/blog"
                                ? "opacity-100"
                                : "opacity-0"
                        } bg-[#d80027]`}
                    />
                </a>
                <a
                    href="/contact-us"
                    className=" transition-all duration-500 flex flex-col justify-start items-center  w-[68px] relative"
                >
                    <p
                        className={`text-base ${
                            location.pathname === "/contact-us"
                                ? "font-bold"
                                : ""
                        } text-left text-[#373737]`}
                    >
                        Contacto
                    </p>
                    <div
                        className={`self-stretch    h-px ${
                            location.pathname === "/contact-us"
                                ? "opacity-100"
                                : "opacity-0"
                        } bg-[#d80027]`}
                    />
                </a>
            </div>
            <div className="flex gap-5 items-center">
                <div className="hidden gap-5 justify-start items-start sm:flex">
                    <Link to="/signup">
                        {/* className="hover:opacity-80 flex justify-center items-center  text-sm text-left text-white  w-[103px] h-9 relative gap-2.5 px-4 py-2.5 rounded bg-[#d80027] transition-all duration-300 " */}
                        <Button size={SIZE.SMALL}>Registrate</Button>
                    </Link>
                    <Link to="/login">
                        {/* className="flex justify-center items-center  text-sm text-left text-[#373737] hover:text-white  h-9 relative gap-2.5 px-4 py-2.5 rounded border-2 border-[#292929] hover:bg-[#292929] transition-all duration-300  " */}

                        <Button
                            stretch
                            color={COLOR.WHITE}
                            size={SIZE.SMALL}
                            text="Iniciar sesión"
                            iconName={"arrow"}
                        />
                    </Link>
                </div>

                <div className="relative xl:hidden">
                    <div
                        onClick={() => setMenu((m) => !m)}
                        className="transition-all duration-500 header__mobileMenuToggle"
                    >
                        {menu ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width={"28"}
                                height={"28"}
                                id="close"
                            >
                                <path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={"28"}
                                height={"28"}
                                viewBox="0 0 24 24"
                                id="close"
                            >
                                <path
                                    fill="#000"
                                    d="M20 5H4c-.6 0-1-.4-1-1s.4-1 1-1h16c.6 0 1 .4 1 1s-.4 1-1 1zm0 16H4c-.6 0-1-.4-1-1s.4-1 1-1h16c.6 0 1 .4 1 1s-.4 1-1 1zm0-8H4c-.6 0-1-.4-1-1s.4-1 1-1h16c.6 0 1 .4 1 1s-.4 1-1 1z"
                                ></path>
                            </svg>
                        )}
                    </div>

                    {menu ? (
                        <div className="header__mobileMenu flex flex-col justify-evenly items-center bg-white p-10 absolute top-[100%] right-0 translate-x-3  translate-y-3   h-[70vh] w-[100vw]">
                            <a
                                href="/"
                                className=" transition-all duration-500 flex flex-col justify-start items-center  w-[45px] relative"
                            >
                                <p
                                    className={`text-base ${
                                        location.pathname === "/"
                                            ? "font-bold"
                                            : ""
                                    } text-left text-[#373737]`}
                                >
                                    Inicio
                                </p>
                                <div
                                    className={`self-stretch  h-px ${
                                        location.pathname === "/"
                                            ? "opacity-100"
                                            : "opacity-0"
                                    } bg-[#d80027]`}
                                />
                            </a>
                            <a
                                href="/howitworks"
                                className=" transition-all duration-500 flex flex-col justify-start items-center  w-[131px] relative"
                            >
                                <p
                                    className={`text-base ${
                                        location.pathname === "/howitworks"
                                            ? "font-bold"
                                            : ""
                                    } text-left text-[#373737]`}
                                >
                                    ¿Cómo Funciona?
                                </p>
                                <div
                                    className={`self-stretch    h-px ${
                                        location.pathname === "/howitworks"
                                            ? "opacity-100"
                                            : "opacity-0"
                                    } bg-[#d80027]`}
                                />
                            </a>
                            <a
                                href="/professionals"
                                className=" transition-all duration-500 flex flex-col justify-start items-center  w-[102px] relative"
                            >
                                <p
                                    className={`text-base ${
                                        location.pathname === "/professionals"
                                            ? "font-bold"
                                            : ""
                                    } text-left text-[#373737]`}
                                >
                                    Profesionales
                                </p>
                                <div
                                    className={`self-stretch    h-px ${
                                        location.pathname === "/professionals"
                                            ? "opacity-100"
                                            : "opacity-0"
                                    } bg-[#d80027]`}
                                />
                            </a>
                            <a
                                href="/blog"
                                className=" transition-all duration-500 flex flex-col justify-start items-center  w-[35px] relative"
                            >
                                <p
                                    className={`text-base ${
                                        location.pathname === "/blog"
                                            ? "font-bold"
                                            : ""
                                    } text-left text-[#373737]`}
                                >
                                    Blog
                                </p>
                                <div
                                    className={`self-stretch    h-px ${
                                        location.pathname === "/blog"
                                            ? "opacity-100"
                                            : "opacity-0"
                                    } bg-[#d80027]`}
                                />
                            </a>
                            <a
                                href="/contact-us"
                                className=" transition-all duration-500 flex flex-col justify-start items-center  w-[68px] relative"
                            >
                                <p
                                    className={`text-base ${
                                        location.pathname === "/contact-us"
                                            ? "font-bold"
                                            : ""
                                    } text-left text-[#373737]`}
                                >
                                    Contacto
                                </p>
                                <div
                                    className={`self-stretch    h-px ${
                                        location.pathname === "/contact-us"
                                            ? "opacity-100"
                                            : "opacity-0"
                                    } bg-[#d80027]`}
                                />
                            </a>
                            <div className="flex flex-col gap-5 justify-evenly">
                                <Button
                                    link={"/signup"}
                                    size={SIZE.SMALL}
                                    stretch
                                >
                                    Registrate
                                </Button>
                                <Button
                                    stretch
                                    link={"/login"}
                                    color={COLOR.WHITE}
                                    size={SIZE.SMALL}
                                    text="Iniciar sesión"
                                    iconName={"arrow"}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    ) : null;
}

export default Header;
