import { useState } from "react";
import Chat from "../../components/platform/chat/Chat";
import Profile from "../../components/platform/chat/Profile";
import Sidebar from "../../components/platform/chat/Sidebar";

function ChatApp({ className }) {
    const [show, setShow] = useState(1);
    const handleNavigateNext = () => setShow((s) => s + 1);
    const handleNavigatePrevious = () => setShow((s) => s - 1);

    return (
        <div
            className={
                className ??
                "flex flex-col justify-start items-start w-full xl:flex-row pt-[70px]"
            }
        >
            <Sidebar
                handleNavigateNext={handleNavigateNext}
                addClass={`  ${show === 1 ? "block" : "hidden"}  xl:block`}
            />

            <Chat
                handleNavigateNext={handleNavigateNext}
                handleNavigatePrevious={handleNavigatePrevious}
                addClass={`  ${show === 2 ? "block" : "hidden"}  xl:block`}
            />

            <Profile
                handleNavigatePrevious={handleNavigatePrevious}
                addClass={`  ${show === 3 ? "block" : "hidden"}  xl:block`}
            />
        </div>
    );
}

export default ChatApp;
