import Input from "../../components/ui/Input";

import { useFormik } from "formik";
import { useCallback, useState } from "react";
import * as Yup from "yup";
import FormValidation from "../../components/form/FormValidation";
import UploadImage from "../../components/form/UploadImage";
import Radio from "../../components/ui/Radio";
import { Button } from "../../components/ui/buttons/Button";
import Checkbox from "../../components/ui/Checkbox";
import Discount from "../../components/Discount";
import Dialog from "../../components/Dialog";

const validationSchema = Yup.object({
    quoteTitle: Yup.string().required("El título es requerido"),
    startDate: Yup.string()
        .required("La fecha de inicio es requerida")
        .test(
            "start-date",
            "La fecha de inicio debe ser igual o anterior a la fecha de finalización",
            function (value) {
                const { endDate } = this.parent;
                const startDateDate = Date.parse(value);
                const endDateDate = Date.parse(endDate);
                return startDateDate <= endDateDate;
            }
        ),
    endDate: Yup.string().required("La fecha de finalización es requerida"),
    proposal: Yup.string()
        .required("La propuesta es requerida")
        .min(10, "El comentario debe tener al menos 10 caracteres")
        .max(100, "La propuesta no debe tener más de 100 caracteres")
    // documents: Yup.string().required("Se requieren documentos")
});

const OPTIONS = {
    HIRING: "hiring",
    CONSULTATION: "consultation"
};

function QuoteForm({
    quoteId = "XNINMRPQMY",
    from = "loremipsum",
    to = "loremipsum",
    date = "19 julio 2023"
}) {
    const [showDiscountDialog, setDiscountDialog] = useState(0);
    const [option, setOption] = useState(OPTIONS.HIRING);
    const [isUrgent, setUrgent] = useState(false);

    const toggleOption = useCallback(() => {
        setOption(
            option === OPTIONS.HIRING ? OPTIONS.CONSULTATION : OPTIONS.HIRING
        );
    }, [option]);

    const formik = useFormik({
        initialValues: {
            quoteTitle: "",
            startDate: "",
            endDate: "",
            proposal: "",
            documents: ""
        },
        validationSchema: validationSchema,
        onSubmit: (e) => {
            // e.preventDefault();
            console.log("done");
            setDiscountDialog(1);
            // formik.resetForm();
        }
    });

    const isHiring = option === OPTIONS.HIRING;

    return (
        <>
            <Dialog
                showDialog={showDiscountDialog}
                setDialog={setDiscountDialog}
                main={
                    <Discount
                        setDiscountDialog={setDiscountDialog}
                        cost1={150}
                        cost2={110}
                        closeDialog={() => setDiscountDialog(0)}
                    />
                }
            />
            <div className="flex flex-col w-full items-center mt-[100px]">
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col justify-start items-start w-full sm:w-[577px] gap-[19px] p-5 border border-[#cdcdcd]"
                >
                    <div className="flex flex-col sm:flex-row justify-start items-start sm:items-center self-stretch  gap-[30px]">
                        <div className="flex-shrink-0 flex justify-center sm:justify-start items-center  relative gap-2.5 w-full sm:w-fit ">
                            <svg
                                width={34}
                                height={34}
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-shrink-0 sm:w-[33.1px] h-[33.87px]"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M32.5862 33.8068L0.510304 33.9339C0.375998 33.9339 0.247193 33.8806 0.152225 33.7856C0.0572558 33.6906 0.00390625 33.5618 0.00390625 33.4275C0.00390625 33.2932 0.0572558 33.1644 0.152225 33.0694C0.247193 32.9745 0.375998 32.9211 0.510304 32.9211L32.5862 32.794C32.6529 32.7937 32.719 32.8065 32.7807 32.8318C32.8424 32.8572 32.8985 32.8944 32.9458 32.9415C32.993 32.9885 33.0305 33.0444 33.0561 33.106C33.0817 33.1676 33.0949 33.2337 33.0949 33.3004C33.0949 33.367 33.0817 33.4331 33.0561 33.4947C33.0305 33.5563 32.993 33.6122 32.9458 33.6592C32.8985 33.7063 32.8424 33.7436 32.7807 33.7689C32.719 33.7942 32.6529 33.8071 32.5862 33.8068Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M15.1957 11.9345C15.0614 11.9345 14.9326 11.8812 14.8376 11.7862C14.7427 11.6912 14.6893 11.5624 14.6893 11.4281C14.6893 8.81662 13.9808 7.05443 12.4639 5.88039C12.411 5.83987 12.3667 5.78933 12.3334 5.73167C12.3001 5.674 12.2786 5.61033 12.2699 5.54431C12.2613 5.47829 12.2657 5.41121 12.283 5.34692C12.3003 5.28262 12.3301 5.22237 12.3708 5.16961C12.4115 5.11662 12.4623 5.07221 12.5202 5.03893C12.5781 5.00566 12.6421 4.98418 12.7084 4.97574C12.7747 4.9673 12.842 4.97205 12.9064 4.98974C12.9708 5.00742 13.0311 5.03768 13.0838 5.07878C14.846 6.44129 15.7021 8.51686 15.7021 11.4372C15.6997 11.5699 15.6453 11.6964 15.5506 11.7894C15.4559 11.8824 15.3285 11.9345 15.1957 11.9345Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M18.1415 11.9336C18.0748 11.9339 18.0087 11.9211 17.947 11.8958C17.8853 11.8704 17.8292 11.8332 17.7819 11.7861C17.7347 11.7391 17.6972 11.6831 17.6716 11.6216C17.646 11.56 17.6328 11.4939 17.6328 11.4272C17.6328 8.516 18.4912 6.43135 20.2534 5.06883C20.3059 5.0278 20.366 4.99758 20.4303 4.97993C20.4945 4.96227 20.5616 4.95753 20.6277 4.96598C20.6938 4.97443 20.7576 4.99589 20.8153 5.02914C20.8731 5.06238 20.9237 5.10675 20.9642 5.15966C21.0052 5.21217 21.0354 5.27228 21.0531 5.33653C21.0707 5.40079 21.0755 5.4679 21.067 5.534C21.0586 5.6001 21.0371 5.66386 21.0038 5.72161C20.9706 5.77936 20.9262 5.82995 20.8733 5.87044C19.3541 7.04448 18.6479 8.80667 18.6479 11.4182C18.6491 11.4854 18.6369 11.5522 18.612 11.6147C18.5871 11.6772 18.55 11.7341 18.5028 11.7821C18.4557 11.8301 18.3994 11.8682 18.3374 11.8942C18.2754 11.9203 18.2088 11.9337 18.1415 11.9336Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M27.3171 4.97168C25.6058 4.97408 23.9653 5.65512 22.7554 6.86541C21.5455 8.0757 20.8651 9.71643 20.8633 11.4277C20.8633 11.562 20.9166 11.6909 21.0116 11.7858C21.1066 11.8808 21.2354 11.9341 21.3697 11.9341C21.4362 11.9341 21.502 11.921 21.5635 11.8956C21.6249 11.8701 21.6807 11.8328 21.7278 11.7858C21.7748 11.7388 21.8121 11.683 21.8375 11.6215C21.863 11.5601 21.8761 11.4942 21.8761 11.4277C21.8761 9.98229 22.4503 8.59605 23.4724 7.57396C24.4945 6.55187 25.8807 5.97767 27.3262 5.97767C27.885 5.97916 28.4381 6.09071 28.9538 6.30595C29.4696 6.52119 29.9379 6.83591 30.332 7.23213C30.7261 7.62836 31.0383 8.09832 31.2508 8.61521C31.4633 9.13209 31.5719 9.68577 31.5704 10.2446C31.5674 11.1178 31.2189 11.9542 30.6011 12.5712C29.9832 13.1882 29.1463 13.5356 28.2731 13.5374C27.6 13.5368 26.9545 13.2691 26.4785 12.7931C26.0026 12.3171 25.7349 11.6717 25.7343 10.9985C25.7349 10.4874 25.9382 9.99737 26.2996 9.63593C26.6611 9.2745 27.1511 9.07119 27.6622 9.07059C28.0179 9.07081 28.3609 9.20266 28.6251 9.44074C28.3912 9.51349 28.1867 9.65923 28.0417 9.8566C27.8966 10.054 27.8185 10.2926 27.8189 10.5376C27.8189 10.8423 27.94 11.1346 28.1555 11.3501C28.371 11.5656 28.6632 11.6866 28.968 11.6866C29.5993 11.6866 30.1148 11.2324 30.1148 10.3877C30.1148 8.98429 29.0179 8.05551 27.6622 8.05551C26.8793 8.05551 26.1284 8.36654 25.5748 8.92017C25.0211 9.4738 24.7101 10.2247 24.7101 11.0076C24.7119 11.951 25.0883 12.855 25.7564 13.521C26.4245 14.187 27.3297 14.5605 28.2731 14.5593C29.4174 14.5593 30.5149 14.1047 31.324 13.2955C32.1332 12.4864 32.5877 11.3889 32.5877 10.2446C32.588 9.55228 32.4519 8.86666 32.1872 8.22693C31.9225 7.5872 31.5343 7.00589 31.0448 6.51623C30.5553 6.02656 29.9742 5.63812 29.3346 5.3731C28.695 5.10809 28.0094 4.97168 27.3171 4.97168Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M5.51808 4.97168C7.22978 4.97348 8.87086 5.65425 10.0812 6.86461C11.2916 8.07496 11.9723 9.71604 11.9741 11.4277C11.9741 11.562 11.9208 11.6909 11.8258 11.7858C11.7309 11.8808 11.602 11.9341 11.4677 11.9341C11.3334 11.9341 11.2046 11.8808 11.1097 11.7858C11.0147 11.6909 10.9613 11.562 10.9613 11.4277C10.9613 9.98229 10.3871 8.59605 9.36505 7.57396C8.34296 6.55187 6.95672 5.97767 5.51128 5.97767C4.95242 5.97916 4.39933 6.09071 3.88359 6.30595C3.36784 6.52119 2.89955 6.83591 2.50543 7.23213C2.11132 7.62836 1.79911 8.09832 1.58662 8.61521C1.37414 9.13209 1.26554 9.68577 1.26704 10.2446C1.26824 11.1193 1.61624 11.9579 2.23477 12.5764C2.8533 13.195 3.69186 13.543 4.56659 13.5442C5.23974 13.5436 5.88515 13.2759 6.36114 12.7999C6.83714 12.3239 7.10481 11.6785 7.10541 11.0054C7.10481 10.4938 6.90119 10.0034 6.53926 9.64195C6.17734 9.28045 5.68672 9.0774 5.17518 9.0774C4.82019 9.07754 4.4779 9.20944 4.21461 9.44755C4.4485 9.52031 4.65296 9.66604 4.79803 9.86342C4.9431 10.0608 5.02115 10.2994 5.02076 10.5444C5.02016 10.8489 4.89892 11.1409 4.68356 11.3562C4.4682 11.5716 4.17627 11.6928 3.87171 11.6934C3.24041 11.6934 2.72265 11.2393 2.72265 10.3945C2.72265 8.99111 3.82175 8.06232 5.17518 8.06232C5.95813 8.06232 6.70901 8.37335 7.26264 8.92698C7.81627 9.48061 8.1273 10.2315 8.1273 11.0144C8.12369 11.9562 7.74689 12.8582 7.07945 13.5226C6.41201 14.1871 5.50838 14.5599 4.56659 14.5593C3.42228 14.5593 2.32484 14.1047 1.51569 13.2955C0.706537 12.4864 0.251953 11.3889 0.251953 10.2446C0.251952 8.84733 0.806565 7.50717 1.79396 6.5185C2.78136 5.52982 4.12079 4.97348 5.51808 4.97168Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M23.1898 3.53624H9.65093C9.51662 3.53624 9.38782 3.48289 9.29285 3.38792C9.19788 3.29295 9.14453 3.16414 9.14453 3.02984C9.14453 2.96334 9.15763 2.89749 9.18308 2.83605C9.20853 2.77461 9.24583 2.71878 9.29285 2.67176C9.33987 2.62474 9.3957 2.58744 9.45714 2.56199C9.51857 2.53654 9.58443 2.52344 9.65093 2.52344H23.1943C23.3287 2.52344 23.4575 2.57679 23.5524 2.67176C23.6474 2.76673 23.7007 2.89553 23.7007 3.02984C23.7008 3.09672 23.6875 3.16295 23.6618 3.22469C23.636 3.28642 23.5983 3.34245 23.5508 3.38954C23.5033 3.43662 23.447 3.47382 23.385 3.499C23.323 3.52418 23.2567 3.53684 23.1898 3.53624Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M7.34236 3.53629C6.88241 3.53568 6.44147 3.3527 6.11623 3.02747C5.791 2.70224 5.60802 2.2613 5.60742 1.80135C5.60802 1.3414 5.791 0.900456 6.11623 0.575223C6.44147 0.249989 6.88241 0.0670071 7.34236 0.0664062H25.4956C25.9556 0.0670071 26.3965 0.249989 26.7218 0.575223C27.047 0.900456 27.23 1.3414 27.2306 1.80135C27.23 2.2613 27.047 2.70224 26.7218 3.02747C26.3965 3.3527 25.9556 3.53568 25.4956 3.53629C25.4289 3.53658 25.3628 3.52371 25.3011 3.49839C25.2394 3.47308 25.1833 3.43582 25.1361 3.38877C25.0888 3.34171 25.0513 3.28579 25.0257 3.2242C25.0001 3.16261 24.987 3.09657 24.987 3.02988C24.987 2.96319 25.0001 2.89715 25.0257 2.83556C25.0513 2.77398 25.0888 2.71805 25.1361 2.671C25.1833 2.62394 25.2394 2.58669 25.3011 2.56137C25.3628 2.53606 25.4289 2.52318 25.4956 2.52348C25.6868 2.52288 25.8699 2.44653 26.0048 2.31117C26.1397 2.17581 26.2155 1.99248 26.2155 1.80135C26.2155 1.61022 26.1397 1.42688 26.0048 1.29152C25.8699 1.15616 25.6868 1.07981 25.4956 1.07921H7.34236C7.15124 1.07981 6.96814 1.15616 6.83321 1.29152C6.69827 1.42688 6.62249 1.61022 6.62249 1.80135C6.62249 1.99248 6.69827 2.17581 6.83321 2.31117C6.96814 2.44653 7.15124 2.52288 7.34236 2.52348C7.40906 2.52318 7.47515 2.53606 7.53685 2.56137C7.59855 2.58669 7.65465 2.62394 7.70191 2.671C7.74918 2.71805 7.78668 2.77398 7.81227 2.83556C7.83786 2.89715 7.85103 2.96319 7.85103 3.02988C7.85103 3.09657 7.83786 3.16261 7.81227 3.2242C7.78668 3.28579 7.74918 3.34171 7.70191 3.38877C7.65465 3.43582 7.59855 3.47308 7.53685 3.49839C7.47515 3.52371 7.40906 3.53658 7.34236 3.53629Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M24.3725 14.9226C24.3303 14.9229 24.2883 14.9176 24.2476 14.9067C19.1076 13.5904 13.7187 13.5904 8.57865 14.9067C8.51423 14.9235 8.44714 14.9274 8.38122 14.9182C8.31531 14.909 8.25185 14.8868 8.19448 14.8531C8.13712 14.8194 8.08697 14.7746 8.0469 14.7215C8.00683 14.6684 7.97763 14.6078 7.96097 14.5434C7.92785 14.413 7.94774 14.2748 8.01625 14.1591C8.08477 14.0433 8.19634 13.9594 8.32657 13.9257C13.629 12.5677 19.1881 12.5677 24.4906 13.9257C24.555 13.9424 24.6155 13.9716 24.6687 14.0117C24.7218 14.0517 24.7665 14.1019 24.8003 14.1592C24.834 14.2166 24.8561 14.2801 24.8653 14.346C24.8745 14.4119 24.8707 14.479 24.8539 14.5434C24.8265 14.6507 24.7645 14.746 24.6774 14.8146C24.5904 14.8831 24.4832 14.9211 24.3725 14.9226Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M12.4556 29.6349C12.3889 29.6349 12.3229 29.6218 12.2613 29.5962C12.1997 29.5706 12.1438 29.5331 12.0967 29.4858C12.0497 29.4386 12.0124 29.3825 11.9871 29.3208C11.9618 29.2591 11.9489 29.193 11.9492 29.1263V18.2261C11.9492 18.1596 11.9623 18.0938 11.9878 18.0323C12.0132 17.9709 12.0505 17.9151 12.0975 17.8681C12.1446 17.821 12.2004 17.7837 12.2618 17.7583C12.3233 17.7328 12.3891 17.7197 12.4556 17.7197C12.5223 17.7194 12.5884 17.7323 12.6501 17.7576C12.7118 17.7829 12.7679 17.8202 12.8152 17.8672C12.8624 17.9143 12.8999 17.9702 12.9255 18.0318C12.9511 18.0934 12.9643 18.1594 12.9643 18.2261V29.1263C12.9643 29.2612 12.9107 29.3906 12.8153 29.486C12.7199 29.5814 12.5905 29.6349 12.4556 29.6349Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M16.4185 29.6351C16.3518 29.6351 16.2858 29.6219 16.2242 29.5963C16.1626 29.5707 16.1067 29.5332 16.0596 29.4859C16.0126 29.4387 15.9753 29.3826 15.95 29.3209C15.9247 29.2592 15.9118 29.1931 15.9121 29.1264V17.6949C15.9121 17.5606 15.9655 17.4318 16.0604 17.3368C16.1554 17.2418 16.2842 17.1885 16.4185 17.1885C16.5528 17.1885 16.6816 17.2418 16.7766 17.3368C16.8716 17.4318 16.9249 17.5606 16.9249 17.6949V29.1264C16.9252 29.1931 16.9123 29.2592 16.887 29.3209C16.8617 29.3826 16.8244 29.4387 16.7774 29.4859C16.7303 29.5332 16.6744 29.5707 16.6128 29.5963C16.5512 29.6219 16.4852 29.6351 16.4185 29.6351Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M20.3817 29.6349C20.2468 29.6349 20.1174 29.5814 20.022 29.486C19.9266 29.3906 19.873 29.2612 19.873 29.1263V18.2261C19.873 18.1594 19.8862 18.0934 19.9118 18.0318C19.9374 17.9702 19.9749 17.9143 20.0222 17.8672C20.0694 17.8202 20.1255 17.7829 20.1872 17.7576C20.2489 17.7323 20.315 17.7194 20.3817 17.7197C20.516 17.7197 20.6448 17.7731 20.7398 17.8681C20.8348 17.963 20.8881 18.0918 20.8881 18.2261V29.1263C20.8884 29.193 20.8755 29.2591 20.8502 29.3208C20.8249 29.3825 20.7877 29.4386 20.7406 29.4858C20.6935 29.5331 20.6376 29.5706 20.576 29.5962C20.5145 29.6218 20.4484 29.6349 20.3817 29.6349Z"
                                    fill="#D80027"
                                />
                                <path
                                    d="M32.3303 29.6356H23.8668V16.9188C18.9722 15.7311 13.8645 15.7311 8.96991 16.9188V29.6356H0.506403C0.43971 29.6356 0.373684 29.6224 0.312096 29.5968C0.250508 29.5712 0.194573 29.5337 0.14752 29.4865C0.100466 29.4392 0.0632141 29.3831 0.037899 29.3214C0.0125838 29.2597 -0.00029398 29.1936 5.09207e-06 29.1269C5.09207e-06 28.9926 0.0533547 28.8638 0.148324 28.7688C0.243292 28.6739 0.372097 28.6205 0.506403 28.6205H7.95484V16.1308L8.33634 16.0331C13.6388 14.6752 19.1979 14.6752 24.5003 16.0331L24.8818 16.1308V28.6205H32.3303C32.4646 28.6205 32.5934 28.6739 32.6883 28.7688C32.7833 28.8638 32.8367 28.9926 32.8367 29.1269C32.837 29.1936 32.8241 29.2597 32.7988 29.3214C32.7734 29.3831 32.7362 29.4392 32.6891 29.4865C32.6421 29.5337 32.5862 29.5712 32.5246 29.5968C32.463 29.6224 32.3969 29.6356 32.3303 29.6356Z"
                                    fill="#D80027"
                                />
                            </svg>
                            <p className="font-[Enriqueta] flex-shrink-0 text-[25.104915618896484px] text-left text-[#373737]">
                                Legal EPROF
                            </p>
                        </div>
                        <div className="flex flex-col justify-start items-start  relative gap-[5px] w-full ">
                            <Input
                                // label="Nombre"
                                placeholder="Introduce un título para tu cotización"
                                // required
                                // onChange={(e) => handleChange(e.target)}
                                // value={formData.quoteTitle}
                                value={formik.values.quoteTitle}
                                onChange={formik.handleChange}
                                name="quoteTitle"
                                type="underline"
                            />
                            {
                                <FormValidation
                                    formik={formik}
                                    name="quoteTitle"
                                />
                            }
                            <p className=" text-xs text-center text-[#373737]">
                                ID de cotización: {quoteId}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row justify-start items-start self-stretch  gap-[29px]">
                        <div className="flex flex-col justify-start items-start flex-grow relative gap-[5px]">
                            <p className=" text-xs font-bold text-left text-[#373737]">
                                De
                            </p>
                            <p className=" text-xs text-left text-[#7b7b7b]">
                                @{from}
                            </p>
                        </div>
                        <div className="flex flex-col justify-start items-start flex-grow relative gap-[5px]">
                            <p className=" text-xs font-bold text-left text-[#373737]">
                                Para
                            </p>
                            <p className=" text-xs text-left text-[#7b7b7b]">
                                @{to}
                            </p>
                        </div>
                        <div className="flex flex-col justify-start items-start flex-grow relative gap-[5px]">
                            <p className=" text-xs font-bold text-right text-[#373737]">
                                Fecha de emisión
                            </p>
                            <p className=" text-xs text-right text-[#7b7b7b]">
                                {date}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col items-start self-stretch justify-start gap-5 sm:flex-row">
                        <div className="flex flex-col flex-grow">
                            <Input
                                label="Inicio propuesto"
                                placeholder="07/19/2023"
                                labelClass={
                                    "text-xs font-bold text-left text-[#373737]"
                                }
                                // required
                                // onChange={(e) => handleChange(e.target)}
                                // value={formData.startDate}
                                value={formik.values.startDate}
                                onChange={formik.handleChange}
                                name="startDate"
                                type="date"
                            />
                            {
                                <FormValidation
                                    formik={formik}
                                    name="startDate"
                                />
                            }
                        </div>
                        <div className="flex flex-col flex-grow">
                            <Input
                                label="Finalización Propuesta (no oficial)"
                                placeholder="07/19/2023"
                                labelClass={
                                    "text-xs font-bold text-left text-[#373737]"
                                }
                                // required
                                // onChange={(e) => handleChange(e.target)}
                                // value={formData.endDate}
                                value={formik.values.endDate}
                                onChange={formik.handleChange}
                                name="endDate"
                                type="date"
                            />
                            {<FormValidation formik={formik} name="endDate" />}
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start self-stretch  gap-2.5">
                        <div className="flex justify-start items-start  gap-[19px]">
                            <Radio
                                text="Contratación"
                                checked={isHiring}
                                onClick={toggleOption}
                            />
                            <Radio
                                text="Asesoría"
                                checked={!isHiring}
                                onClick={toggleOption}
                            />
                        </div>
                        <div className="flex flex-col justify-center items-start self-stretch  relative gap-[5px] p-2.5 rounded bg-[#f5f8ff]">
                            <Checkbox
                                text={isHiring ? "Urgente" : "Inmediata"}
                                checked={isUrgent}
                                toggleCheck={() => setUrgent((prev) => !prev)}
                            />
                            <p className="self-stretch  sm:w-[517px] text-xs text-left text-[#373737]">
                                Son aquellas diligencias que precisen un
                                acompañamiento legal de forma presencial
                                inmediata.
                            </p>
                        </div>
                    </div>

                    <Input
                        placeholder="Ingresa una cotización detallada de tu cotizacion en relación con los requisitos del cliente"
                        labelClass={
                            "text-xs font-bold text-left text-[#373737]"
                        }
                        label={isHiring ? "Propuesta" : "Consulta"}
                        // onChange={(e) => handleChange(e.target)}
                        // value={formData.proposal}
                        value={formik.values.proposal}
                        onChange={formik.handleChange}
                        name="proposal"
                        type="long"
                        addClass=" text-sm font-semibold text-left"
                    />
                    {<FormValidation formik={formik} name="proposal" />}
                    {isHiring ? (
                        <div className="flex flex-col justify-start items-start w-full  sm:w-[190px] gap-2.5">
                            <UploadImage
                                alignLeft
                                // setFormData={setFormData}
                                setFormData={formik.setFieldValue}
                                label="Adjuntar documentos"
                                name="documents"
                            />
                        </div>
                    ) : null}
                    {/* {<FormValidation formik={formik} name="documents" />} */}
                    <Button
                        type="submit"
                        stretch
                        // className="flex justify-center items-center self-stretch  text-lg text-left text-white  h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]"
                    >
                        Enviar cotización
                    </Button>
                </form>
            </div>
        </>
    );
}

export default QuoteForm;
