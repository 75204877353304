import React from "react";

function FormValidation({ formik, name }) {
    return (
        <>
            {formik.errors[name] && formik.touched[name] ? (
                <div className="text-red-500 text-sm">{formik.errors[name]}</div>
            ) : null}
        </>
    );
}

export default FormValidation;
