import { noop } from "../../utils/noop";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";

function ConfirmationModal({
    text = `¿Esta de acuerdo en recibir la cantidad que indicada el
    cliente por el servicio realizado?`,
    cancelCallback = noop,
    acceptCallback = noop,
    hideInfoIcon = false,
    customButtons = null
}) {
    return (
        <div
            className="flex flex-col justify-start items-center w-[594px] gap-[30px] p-[30px] bg-white"
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
        >
            {hideInfoIcon ? null : (
                <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-2.5 p-2.5 rounded-[100px] bg-[#ffd80c]">
                    <svg
                        width={40}
                        height={40}
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative flex-grow-0 flex-shrink-0 w-10 h-10"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M19.9987 7.08203C12.8633 7.08203 7.08203 12.9154 7.08203 19.9987C7.08203 27.1341 12.8633 32.9154 19.9987 32.9154C27.082 32.9154 32.9154 27.1341 32.9154 19.9987C32.9154 12.9154 27.082 7.08203 19.9987 7.08203ZM19.9987 30.4154C14.2174 30.4154 9.58203 25.7799 9.58203 19.9987C9.58203 14.2695 14.2174 9.58203 19.9987 9.58203C25.7279 9.58203 30.4154 14.2695 30.4154 19.9987C30.4154 25.7799 25.7279 30.4154 19.9987 30.4154ZM19.9987 12.8112C18.7487 12.8112 17.8112 13.8008 17.8112 14.9987C17.8112 16.2487 18.7487 17.1862 19.9987 17.1862C21.1966 17.1862 22.1862 16.2487 22.1862 14.9987C22.1862 13.8008 21.1966 12.8112 19.9987 12.8112ZM22.9154 26.0404V24.7904C22.9154 24.4779 22.6029 24.1654 22.2904 24.1654H21.6654V18.957C21.6654 18.6445 21.3529 18.332 21.0404 18.332H17.707C17.3424 18.332 17.082 18.6445 17.082 18.957V20.207C17.082 20.5716 17.3424 20.832 17.707 20.832H18.332V24.1654H17.707C17.3424 24.1654 17.082 24.4779 17.082 24.7904V26.0404C17.082 26.4049 17.3424 26.6654 17.707 26.6654H22.2904C22.6029 26.6654 22.9154 26.4049 22.9154 26.0404Z"
                            fill="#292929"
                        />
                    </svg>
                </div>
            )}
            <div className="flex relative flex-col flex-grow-0 flex-shrink-0 gap-3 justify-start items-center self-stretch">
                <p className="self-stretch flex-grow-0 flex-shrink-0 w-[534px] text-[21px] font-bold text-center text-[#373737]">
                    {text}
                </p>
            </div>
            {customButtons ? (
                customButtons
            ) : (
                <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 gap-[5px]">
                    <Button
                        size={SIZE.MEDIUM}
                        color={COLOR.WHITE}
                        addClass={"h-11"}
                        text="Cancelar"
                        link="/platform/lawyer-dispute"
                        onClick={cancelCallback}
                    />
                    <Button
                        size={SIZE.MEDIUM}
                        // color={COLOR.WHITE}
                        addClass={"h-11"}
                        text="Aceptar"
                        link="/platform/lawyer-dispute"
                        onClick={acceptCallback}
                    />
                </div>
            )}
        </div>
    );
}

export default ConfirmationModal;
