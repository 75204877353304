import avatar from "../../assets/images/blogs/avatar.png";
import Thumb from "../ui/Thumb";

function BlogComment({ blogComment }) {
    return (
        <div className="relative flex flex-col items-start justify-start gap-4 ">
            <div className="relative flex items-center justify-start gap-4">
                <div className=" w-[70px] h-[70px] relative overflow-hidden rounded-[46.5px]">
                    <img
                        src={avatar}
                        alt="rectangle-1413.png"
                        className="w-[70px] h-[70px] absolute left-[-0.66px] top-[-0.66px] object-cover"
                    />
                    <img
                        src={avatar}
                        className="w-[70px] h-[70px] absolute left-[-0.66px] top-[-0.66px]"
                        alt="rectangle-1415.png"
                    />
                </div>
                <div className="flex flex-col justify-start items-start  relative gap-[5px]">
                    <div className="flex justify-start items-center  relative gap-[5px]">
                        <p className=" text-base font-bold text-left text-[#373737]">
                            Abidemi K.
                        </p>
                        <svg
                            width={30}
                            height={31}
                            viewBox="0 0 30 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=" w-[30px] h-[30px] relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M27.5 6.8667H2.5V23.5335H27.5V6.8667Z"
                                fill="white"
                            />
                            <path
                                d="M27.5 8.94995H2.5V11.0331H27.5V8.94995Z"
                                fill="#D80027"
                            />
                            <path
                                d="M27.5 13.1169H2.5V15.2001H27.5V13.1169Z"
                                fill="#D80027"
                            />
                            <path
                                d="M27.5 17.2832H2.5V19.3664H27.5V17.2832Z"
                                fill="#D80027"
                            />
                            <path
                                d="M27.5 21.45H2.5V23.5331H27.5V21.45Z"
                                fill="#D80027"
                            />
                            <path
                                d="M15 6.8667H2.5V15.8412H15V6.8667Z"
                                fill="#2E52B2"
                            />
                            <path
                                d="M7.37397 10.5432L7.17266 11.1625H6.52148L7.04839 11.5451L6.84707 12.1644L7.37397 11.7818L7.90054 12.1644L7.69927 11.5451L8.22617 11.1625H7.57495L7.37397 10.5432Z"
                                fill="white"
                            />
                            <path
                                d="M7.57495 13.3976L7.37397 12.7783L7.17266 13.3976H6.52148L7.04839 13.7802L6.84707 14.3995L7.37397 14.0169L7.90054 14.3995L7.69927 13.7802L8.22617 13.3976H7.57495Z"
                                fill="white"
                            />
                            <path
                                d="M4.82305 13.3976L4.62202 12.7783L4.4207 13.3976H3.76953L4.29644 13.7802L4.09512 14.3995L4.62202 14.0169L5.14863 14.3995L4.94736 13.7802L5.47417 13.3976H4.82305Z"
                                fill="white"
                            />
                            <path
                                d="M4.62202 10.5432L4.4207 11.1625H3.76953L4.29644 11.5451L4.09512 12.1644L4.62202 11.7818L5.14863 12.1644L4.94736 11.5451L5.47417 11.1625H4.82305L4.62202 10.5432Z"
                                fill="white"
                            />
                            <path
                                d="M7.37397 8.30811L7.17266 8.92749H6.52148L7.04839 9.31011L6.84707 9.92939L7.37397 9.54678L7.90054 9.92939L7.69927 9.31011L8.22617 8.92749H7.57495L7.37397 8.30811Z"
                                fill="white"
                            />
                            <path
                                d="M4.62202 8.30811L4.4207 8.92749H3.76953L4.29644 9.31011L4.09512 9.92939L4.62202 9.54678L5.14863 9.92939L4.94736 9.31011L5.47417 8.92749H4.82305L4.62202 8.30811Z"
                                fill="white"
                            />
                            <path
                                d="M10.1259 10.5432L9.92461 11.1625H9.27344L9.80029 11.5451L9.59907 12.1644L10.1259 11.7818L10.6525 12.1644L10.4512 11.5451L10.9781 11.1625H10.327L10.1259 10.5432Z"
                                fill="white"
                            />
                            <path
                                d="M10.327 13.3976L10.1259 12.7783L9.92461 13.3976H9.27344L9.80029 13.7802L9.59907 14.3995L10.1259 14.0169L10.6525 14.3995L10.4512 13.7802L10.9781 13.3976H10.327Z"
                                fill="white"
                            />
                            <path
                                d="M13.0789 13.3976L12.8778 12.7783L12.6765 13.3976H12.0254L12.5522 13.7802L12.3509 14.3995L12.8778 14.0169L13.4044 14.3995L13.2031 13.7802L13.73 13.3976H13.0789Z"
                                fill="white"
                            />
                            <path
                                d="M12.8778 10.5432L12.6765 11.1625H12.0254L12.5522 11.5451L12.3509 12.1644L12.8778 11.7818L13.4044 12.1644L13.2031 11.5451L13.73 11.1625H13.0789L12.8778 10.5432Z"
                                fill="white"
                            />
                            <path
                                d="M10.1259 8.30811L9.92461 8.92749H9.27344L9.80029 9.31011L9.59907 9.92939L10.1259 9.54678L10.6525 9.92939L10.4512 9.31011L10.9781 8.92749H10.327L10.1259 8.30811Z"
                                fill="white"
                            />
                            <path
                                d="M12.8778 8.30811L12.6765 8.92749H12.0254L12.5522 9.31011L12.3509 9.92939L12.8778 9.54678L13.4044 9.92939L13.2031 9.31011L13.73 8.92749H13.0789L12.8778 8.30811Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <p className=" text-sm text-left text-[#7b7b7b]">
                        12 March 2022
                    </p>
                </div>
            </div>
            <p className="self-stretch   text-base text-left text-[#7b7b7b]">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
            </p>
            <div className="flex items-center self-stretch justify-end gap-4 ">
                <div className="flex justify-start items-center  relative gap-[2.3480663299560547px]">
                    {/* <svg
                        width={25}
                        height={25}
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M18.8662 13.5854C19.1475 13.1167 19.3037 12.6167 19.3037 12.0229C19.3037 10.6479 18.1162 9.3667 16.6162 9.3667H15.46C15.6162 8.96045 15.7412 8.4917 15.7412 7.89795C15.7412 5.6167 14.5537 4.6167 12.7725 4.6167C10.835 4.6167 10.96 7.58545 10.5225 8.02295C9.80371 8.7417 8.95996 10.1167 8.36621 10.6167H5.30371C4.74121 10.6167 4.30371 11.0854 4.30371 11.6167V19.1167C4.30371 19.6792 4.74121 20.1167 5.30371 20.1167H7.30371C7.74121 20.1167 8.14746 19.8042 8.24121 19.3979C9.64746 19.4292 10.6162 20.6167 13.8037 20.6167C14.0537 20.6167 14.3037 20.6167 14.5225 20.6167C16.9287 20.6167 17.9912 19.3979 18.0225 17.6479C18.46 17.0854 18.6787 16.3042 18.585 15.5542C18.8975 14.9917 18.9912 14.3042 18.8662 13.5854ZM16.9287 15.2729C17.335 15.9292 16.96 16.8042 16.4912 17.0854C16.7412 18.5854 15.9287 19.1167 14.835 19.1167H13.6475C11.4287 19.1167 9.95996 17.9604 8.30371 17.9604V12.1167H8.61621C9.52246 12.1167 10.7412 9.9292 11.585 9.08545C12.46 8.21045 12.1787 6.71045 12.7725 6.1167C14.2412 6.1167 14.2412 7.14795 14.2412 7.89795C14.2412 9.1167 13.3662 9.6792 13.3662 10.8667H16.6162C17.2725 10.8667 17.7725 11.4604 17.8037 12.0542C17.8037 12.6167 17.3975 13.2104 17.085 13.2104C17.5225 13.6792 17.6162 14.6479 16.9287 15.2729ZM7.05371 18.1167C7.05371 18.5542 6.70996 18.8667 6.30371 18.8667C5.86621 18.8667 5.55371 18.5542 5.55371 18.1167C5.55371 17.7104 5.86621 17.3667 6.30371 17.3667C6.70996 17.3667 7.05371 17.7104 7.05371 18.1167Z"
                            fill="#333333"
                        />
                    </svg> */}
                    <Thumb selected={false} count={340} />
                    {/* <p className=" text-base text-left text-[#7b7b7b]">340</p> */}
                </div>
                <div className="flex justify-start items-center  relative gap-[2.3480663299560547px]">
                    {/* <div className="relative w-6 h-6 overflow-hidden ">
                         <p className="absolute left-1 top-1 text-base text-center text-[#333]">
                            thumbs-down
                        </p>
                    </div> */}
                    {/* <img
                        src={thumbsDownSvg}
                        alt="thumbsDownSvg"
                        className="mr-1"
                    /> */}
                    <Thumb dislike selected={false} />
                    <p className=" text-base text-left text-[#7b7b7b]">0</p>
                </div>
            </div>
        </div>
    );
}

export default BlogComment;
