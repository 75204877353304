import { useEffect, useState } from "react";
import Dialog from "../../components/Dialog";
import AbandonQuestionModal from "../../components/modal/CertificationsPage/AbandonQuestionModal";
import TimerModal from "../../components/modal/CertificationsPage/TimerModal";
import Radio from "../../components/ui/Radio";
import { Button, COLOR, SIZE } from "../../components/ui/buttons/Button";

const answers = ["answer 1", "answer 2", "answer 3", "answer 4", "answer 5"];

const CertificatePregunta = () => {
    const [showModal, setShowModal] = useState(0);
    const [timerModal, setTimerModal] = useState(0);
    const [seconds, setSeconds] = useState(900);
    const [ans, setAns] = useState("");

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(intervalId);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [seconds]);

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const progress = ((900 - seconds) / 900) * 100;
    const lineWidth = `${progress}%`;
    const circumference = 2 * Math.PI * 40;
    const dashOffset = circumference - (circumference * (900 - seconds)) / 900;

    return (
        <div
            className="absolute w-full h-full bg-white"
            style={{ zIndex: 1000 }}
        >
            <Dialog
                showDialog={showModal}
                setDialog={setShowModal}
                main={<AbandonQuestionModal setShowDialog={setShowModal} />}
            />
            <Dialog
                showDialog={timerModal}
                setDialog={setTimerModal}
                main={<TimerModal setShowDialog={setTimerModal} />}
            />
            <div className="py-10">
                <div className="flex flex-col w-full items-center gap-[60px] px-2 ">
                    {/* sect1 */}
                    <div className="flex  items-center w-full max-w-[936px] relative">
                        <div className="flex  items-center   gap-2.5 mx-auto">
                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=" w-[33.1px] h-[33.87px]"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M33.4925 33.7823L1.41655 33.9095C1.28225 33.9095 1.15344 33.8562 1.05847 33.7612C0.963506 33.6662 0.910156 33.5374 0.910156 33.4031C0.910156 33.2688 0.963506 33.14 1.05847 33.045C1.15344 32.9501 1.28225 32.8967 1.41655 32.8967L33.4925 32.7695C33.5592 32.7692 33.6253 32.7821 33.687 32.8074C33.7487 32.8327 33.8048 32.87 33.852 32.9171C33.8993 32.9641 33.9368 33.02 33.9624 33.0816C33.988 33.1432 34.0011 33.2092 34.0011 33.2759C34.0011 33.3426 33.988 33.4087 33.9624 33.4703C33.9368 33.5318 33.8993 33.5878 33.852 33.6348C33.8048 33.6819 33.7487 33.7191 33.687 33.7445C33.6253 33.7698 33.5592 33.7826 33.4925 33.7823Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M16.102 11.912C15.9677 11.912 15.8389 11.8587 15.7439 11.7637C15.6489 11.6688 15.5956 11.54 15.5956 11.4056C15.5956 8.79415 14.8871 7.03196 13.3701 5.85793C13.3173 5.81741 13.273 5.76687 13.2397 5.7092C13.2064 5.65154 13.1848 5.58787 13.1762 5.52185C13.1675 5.45583 13.172 5.38875 13.1893 5.32446C13.2066 5.26016 13.2364 5.19991 13.277 5.14715C13.3177 5.09416 13.3685 5.04975 13.4265 5.01647C13.4844 4.9832 13.5484 4.96172 13.6146 4.95328C13.6809 4.94483 13.7482 4.94959 13.8126 4.96728C13.8771 4.98496 13.9374 5.01522 13.9901 5.05631C15.7523 6.41883 16.6084 8.4944 16.6084 11.4147C16.606 11.5475 16.5516 11.6739 16.4569 11.767C16.3622 11.86 16.2347 11.9121 16.102 11.912Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M19.0477 11.9092C18.981 11.9095 18.9149 11.8967 18.8532 11.8713C18.7915 11.846 18.7354 11.8088 18.6882 11.7617C18.6409 11.7147 18.6034 11.6587 18.5778 11.5971C18.5522 11.5356 18.5391 11.4695 18.5391 11.4028C18.5391 8.49158 19.3974 6.40693 21.1596 5.04442C21.2121 5.00339 21.2722 4.97317 21.3365 4.95551C21.4008 4.93786 21.4679 4.93312 21.534 4.94157C21.6001 4.95001 21.6638 4.97148 21.7216 5.00472C21.7793 5.03797 21.8299 5.08233 21.8704 5.13525C21.9114 5.18775 21.9417 5.24787 21.9593 5.31212C21.977 5.37637 21.9817 5.44349 21.9733 5.50959C21.9648 5.57568 21.9433 5.63945 21.9101 5.6972C21.8768 5.75495 21.8325 5.80553 21.7796 5.84603C20.2604 7.02007 19.5541 8.78225 19.5541 11.3937C19.5553 11.461 19.5431 11.5278 19.5182 11.5903C19.4933 11.6528 19.4562 11.7097 19.4091 11.7577C19.3619 11.8057 19.3057 11.8438 19.2436 11.8698C19.1816 11.8958 19.115 11.9092 19.0477 11.9092Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M28.2233 4.94922C26.512 4.95162 24.8715 5.63266 23.6617 6.84295C22.4518 8.05324 21.7713 9.69397 21.7695 11.4053C21.7695 11.5396 21.8229 11.6684 21.9178 11.7634C22.0128 11.8583 22.1416 11.9117 22.2759 11.9117C22.3424 11.9117 22.4083 11.8986 22.4697 11.8731C22.5312 11.8477 22.587 11.8104 22.634 11.7634C22.681 11.7163 22.7183 11.6605 22.7438 11.5991C22.7692 11.5376 22.7823 11.4718 22.7823 11.4053C22.7823 9.95983 23.3565 8.57359 24.3786 7.5515C25.4007 6.52941 26.787 5.95521 28.2324 5.95521C28.7913 5.9567 29.3443 6.06825 29.8601 6.28349C30.3758 6.49873 30.8441 6.81345 31.2382 7.20967C31.6324 7.60589 31.9446 8.07586 32.1571 8.59275C32.3695 9.10963 32.4781 9.66331 32.4766 10.2222C32.4736 11.0953 32.1251 11.9318 31.5073 12.5488C30.8895 13.1658 30.0525 13.5131 29.1794 13.5149C28.5062 13.5143 27.8608 13.2466 27.3848 12.7706C26.9088 12.2947 26.6411 11.6492 26.6405 10.9761C26.6411 10.4649 26.8444 9.97491 27.2059 9.61347C27.5673 9.25204 28.0573 9.04873 28.5685 9.04812C28.9241 9.04835 29.2671 9.1802 29.5313 9.41828C29.2974 9.49103 29.093 9.63677 28.9479 9.83414C28.8028 10.0315 28.7248 10.2701 28.7252 10.5151C28.7252 10.8198 28.8462 11.1121 29.0617 11.3276C29.2772 11.5431 29.5695 11.6642 29.8742 11.6642C30.5055 11.6642 31.021 11.21 31.021 10.3652C31.021 8.96183 29.9242 8.03305 28.5685 8.03305C27.7855 8.03305 27.0347 8.34408 26.481 8.89771C25.9274 9.45134 25.6164 10.2022 25.6164 10.9852C25.6182 11.9285 25.9945 12.8326 26.6626 13.4986C27.3307 14.1646 28.236 14.538 29.1794 14.5368C30.3237 14.5368 31.4211 14.0822 32.2303 13.2731C33.0394 12.4639 33.494 11.3665 33.494 10.2222C33.4943 9.52981 33.3582 8.84419 33.0934 8.20447C32.8287 7.56474 32.4405 6.98343 31.9511 6.49377C31.4616 6.0041 30.8805 5.61566 30.2408 5.35064C29.6012 5.08562 28.9157 4.94922 28.2233 4.94922Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M6.42628 4.94922C8.13798 4.95102 9.77907 5.63179 10.9894 6.84215C12.1998 8.0525 12.8805 9.69358 12.8823 11.4053C12.8823 11.5396 12.829 11.6684 12.734 11.7634C12.6391 11.8583 12.5103 11.9117 12.3759 11.9117C12.2416 11.9117 12.1128 11.8583 12.0179 11.7634C11.9229 11.6684 11.8695 11.5396 11.8695 11.4053C11.8695 9.95983 11.2953 8.57359 10.2732 7.5515C9.25116 6.52941 7.86493 5.95521 6.41948 5.95521C5.86063 5.9567 5.30753 6.06825 4.79179 6.28349C4.27605 6.49873 3.80775 6.81345 3.41364 7.20967C3.01952 7.60589 2.70731 8.07586 2.49483 8.59275C2.28234 9.10963 2.17375 9.66331 2.17524 10.2222C2.17644 11.0969 2.52445 11.9354 3.14297 12.554C3.7615 13.1725 4.60006 13.5205 5.47479 13.5217C6.14795 13.5211 6.79336 13.2534 7.26935 12.7775C7.74534 12.3015 8.01301 11.6561 8.01361 10.9829C8.01301 10.4714 7.80939 9.98098 7.44747 9.61949C7.08554 9.25799 6.59492 9.05494 6.08338 9.05494C5.72839 9.05508 5.3861 9.18698 5.12281 9.42509C5.35671 9.49785 5.56116 9.64358 5.70623 9.84095C5.8513 10.0383 5.92935 10.277 5.92897 10.5219C5.92837 10.8265 5.80712 11.1184 5.59176 11.3338C5.3764 11.5491 5.08447 11.6704 4.77991 11.671C4.14861 11.671 3.63085 11.2168 3.63085 10.372C3.63085 8.96865 4.72995 8.03986 6.08338 8.03986C6.86633 8.03986 7.61722 8.35089 8.17085 8.90452C8.72448 9.45815 9.0355 10.209 9.0355 10.992C9.0319 11.9338 8.65509 12.8357 7.98765 13.5002C7.32022 14.1646 6.41659 14.5374 5.47479 14.5368C4.33048 14.5368 3.23304 14.0822 2.42389 13.2731C1.61474 12.4639 1.16016 11.3665 1.16016 10.2222C1.16016 8.82487 1.71477 7.48471 2.70216 6.49604C3.68956 5.50736 5.02899 4.95102 6.42628 4.94922Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M24.0961 3.51281H10.5572C10.4229 3.51281 10.2941 3.45945 10.1991 3.36448C10.1041 3.26952 10.0508 3.14071 10.0508 3.0064C10.0508 2.9399 10.0639 2.87405 10.0893 2.81261C10.1148 2.75117 10.1521 2.69535 10.1991 2.64832C10.2461 2.6013 10.3019 2.564 10.3634 2.53855C10.4248 2.5131 10.4907 2.5 10.5572 2.5H24.1006C24.2349 2.5 24.3637 2.55335 24.4587 2.64832C24.5536 2.74329 24.607 2.87209 24.607 3.0064C24.607 3.07329 24.5938 3.13951 24.568 3.20125C24.5423 3.26299 24.5046 3.31901 24.4571 3.3661C24.4096 3.41318 24.3532 3.45039 24.2912 3.47557C24.2293 3.50075 24.1629 3.51341 24.0961 3.51281Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M8.24666 3.51285C7.78671 3.51225 7.34576 3.32927 7.02053 3.00403C6.69529 2.6788 6.51232 2.23786 6.51172 1.77791C6.51232 1.31796 6.69529 0.877019 7.02053 0.551785C7.34576 0.226551 7.78671 0.0435696 8.24666 0.0429688H26.3999C26.8599 0.0435696 27.3008 0.226551 27.626 0.551785C27.9513 0.877019 28.1343 1.31796 28.1349 1.77791C28.1343 2.23786 27.9513 2.6788 27.626 3.00403C27.3008 3.32927 26.8599 3.51225 26.3999 3.51285C26.3332 3.51315 26.2671 3.50027 26.2054 3.47495C26.1437 3.44964 26.0876 3.41238 26.0404 3.36533C25.9931 3.31828 25.9556 3.26235 25.93 3.20076C25.9044 3.13917 25.8913 3.07314 25.8913 3.00644C25.8913 2.93975 25.9044 2.87371 25.93 2.81213C25.9556 2.75054 25.9931 2.69461 26.0404 2.64756C26.0876 2.60051 26.1437 2.56325 26.2054 2.53794C26.2671 2.51262 26.3332 2.49974 26.3999 2.50004C26.5911 2.49944 26.7741 2.4231 26.9091 2.28773C27.044 2.15237 27.1198 1.96904 27.1198 1.77791C27.1198 1.58678 27.044 1.40345 26.9091 1.26809C26.7741 1.13272 26.5911 1.05638 26.3999 1.05577H8.24666C8.05553 1.05638 7.87244 1.13272 7.7375 1.26809C7.60257 1.40345 7.52679 1.58678 7.52679 1.77791C7.52679 1.96904 7.60257 2.15237 7.7375 2.28773C7.87244 2.4231 8.05553 2.49944 8.24666 2.50004C8.31335 2.49974 8.37945 2.51262 8.44115 2.53794C8.50285 2.56325 8.55894 2.60051 8.60621 2.64756C8.65347 2.69461 8.69097 2.75054 8.71656 2.81213C8.74216 2.87371 8.75533 2.93975 8.75533 3.00644C8.75533 3.07314 8.74216 3.13917 8.71656 3.20076C8.69097 3.26235 8.65347 3.31828 8.60621 3.36533C8.55894 3.41238 8.50285 3.44964 8.44115 3.47495C8.37945 3.50027 8.31335 3.51315 8.24666 3.51285Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M25.2787 14.8982C25.2366 14.8985 25.1946 14.8932 25.1538 14.8823C20.0138 13.5659 14.6249 13.5659 9.4849 14.8823C9.42048 14.8991 9.35339 14.9029 9.28747 14.8937C9.22156 14.8845 9.1581 14.8624 9.10073 14.8287C9.04337 14.7949 8.99322 14.7502 8.95315 14.6971C8.91308 14.6439 8.88388 14.5834 8.86722 14.519C8.8341 14.3886 8.85399 14.2504 8.9225 14.1347C8.99102 14.0189 9.10259 13.935 9.23282 13.9013C14.5353 12.5433 20.0944 12.5433 25.3968 13.9013C25.4612 13.918 25.5218 13.9472 25.5749 13.9872C25.6281 14.0273 25.6728 14.0775 25.7065 14.1348C25.7403 14.1922 25.7624 14.2556 25.7716 14.3216C25.7808 14.3875 25.7769 14.4546 25.7602 14.519C25.7327 14.6263 25.6707 14.7216 25.5837 14.7902C25.4967 14.8587 25.3895 14.8967 25.2787 14.8982Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M13.3619 29.6105C13.2952 29.6105 13.2292 29.5974 13.1676 29.5718C13.106 29.5462 13.05 29.5087 13.003 29.4614C12.9559 29.4141 12.9187 29.3581 12.8934 29.2963C12.8681 29.2346 12.8552 29.1685 12.8555 29.1019V18.2017C12.8555 18.1352 12.8686 18.0694 12.894 18.0079C12.9195 17.9465 12.9568 17.8907 13.0038 17.8436C13.0508 17.7966 13.1067 17.7593 13.1681 17.7339C13.2295 17.7084 13.2954 17.6953 13.3619 17.6953C13.4286 17.695 13.4947 17.7079 13.5564 17.7332C13.6181 17.7585 13.6742 17.7958 13.7214 17.8428C13.7687 17.8899 13.8062 17.9458 13.8318 18.0074C13.8574 18.069 13.8706 18.135 13.8706 18.2017V29.1019C13.8706 29.2368 13.817 29.3661 13.7216 29.4615C13.6262 29.5569 13.4968 29.6105 13.3619 29.6105Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M17.3228 29.6107C17.2561 29.6107 17.1901 29.5975 17.1285 29.5719C17.0669 29.5463 17.011 29.5088 16.9639 29.4615C16.9169 29.4143 16.8796 29.3582 16.8543 29.2965C16.829 29.2348 16.8161 29.1687 16.8164 29.102V17.6705C16.8164 17.5362 16.8698 17.4074 16.9647 17.3124C17.0597 17.2174 17.1885 17.1641 17.3228 17.1641C17.4571 17.1641 17.5859 17.2174 17.6809 17.3124C17.7759 17.4074 17.8292 17.5362 17.8292 17.6705V29.102C17.8295 29.1687 17.8166 29.2348 17.7913 29.2965C17.766 29.3582 17.7287 29.4143 17.6817 29.4615C17.6346 29.5088 17.5787 29.5463 17.5171 29.5719C17.4555 29.5975 17.3895 29.6107 17.3228 29.6107Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M21.286 29.6105C21.1511 29.6105 21.0217 29.5569 20.9263 29.4615C20.8309 29.3661 20.7773 29.2368 20.7773 29.1019V18.2017C20.7773 18.135 20.7905 18.069 20.8161 18.0074C20.8417 17.9458 20.8792 17.8899 20.9265 17.8428C20.9737 17.7958 21.0298 17.7585 21.0915 17.7332C21.1532 17.7079 21.2193 17.695 21.286 17.6953C21.4203 17.6953 21.5491 17.7487 21.6441 17.8436C21.7391 17.9386 21.7924 18.0674 21.7924 18.2017V29.1019C21.7927 29.1685 21.7798 29.2346 21.7545 29.2963C21.7292 29.3581 21.692 29.4141 21.6449 29.4614C21.5978 29.5087 21.5419 29.5462 21.4803 29.5718C21.4187 29.5974 21.3527 29.6105 21.286 29.6105Z"
                                    fill="#D80027"
                                ></path>
                                <path
                                    d="M33.2365 29.6131H24.773V16.8963C19.8784 15.7087 14.7708 15.7087 9.87616 16.8963V29.6131H1.41265C1.34596 29.6131 1.27993 29.6 1.21835 29.5744C1.15676 29.5488 1.10082 29.5113 1.05377 29.464C1.00672 29.4168 0.969464 29.3607 0.944149 29.299C0.918834 29.2373 0.905956 29.1712 0.906255 29.1045C0.906255 28.9702 0.959605 28.8414 1.05457 28.7464C1.14954 28.6514 1.27835 28.5981 1.41265 28.5981H8.86109V16.1083L9.24259 16.0107C14.545 14.6527 20.1042 14.6527 25.4066 16.0107L25.7881 16.1083V28.5981H33.2365C33.3708 28.5981 33.4996 28.6514 33.5946 28.7464C33.6896 28.8414 33.7429 28.9702 33.7429 29.1045C33.7432 29.1712 33.7303 29.2373 33.705 29.299C33.6797 29.3607 33.6424 29.4168 33.5954 29.464C33.5483 29.5113 33.4924 29.5488 33.4308 29.5744C33.3692 29.6 33.3032 29.6131 33.2365 29.6131Z"
                                    fill="#D80027"
                                ></path>
                            </svg>
                            <p className=" text-[25px] text-left text-[#373737]">
                                Legal EPROF
                            </p>
                        </div>
                        <Button
                            color={COLOR.WHITE}
                            size={SIZE.SMALL}
                            text="Abandonar"
                            addClass={"h-12 "}
                            onClick={() => setShowModal(true)}
                        />
                    </div>

                    {/* sect2 */}
                    <div className="flex  items-center w-full max-w-[936px] gap-10  ">
                        <div className=" w-full max-w-[816px] h-[22px]  flex">
                            <div className="flex flex-col self-center w-full rounded-full bg-neutral-100">
                                <div
                                    className="h-2 rounded-full bg-[#d80027]"
                                    style={{ width: lineWidth }}
                                ></div>
                            </div>
                        </div>
                        <div className="relative flex-shrink-0 w-20 h-20 -translate-y-2">
                            <svg className="w-24 h-24 transform -rotate-90 -translate-x-3">
                                <circle
                                    cx="48"
                                    cy="52"
                                    r="40"
                                    stroke="currentColor"
                                    strokeWidth="5"
                                    fill="transparent"
                                    className="text-[#F5F5F5]"
                                />

                                <circle
                                    cx="48"
                                    cy="52"
                                    r="40"
                                    stroke="currentColor"
                                    strokeWidth="5"
                                    fill="transparent"
                                    strokeDasharray={circumference}
                                    strokeDashoffset={dashOffset}
                                    className="text-[#D80027] "
                                />
                            </svg>
                            <p className="w-20 h-[18px] absolute left-0 top-[31px] text-lg text-center text-black/[0.85]">
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {remainingSeconds < 10
                                    ? `0${remainingSeconds}`
                                    : remainingSeconds}
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col   w-full max-w-[936px] relative gap-[30px] p-5 bg-white border border-[#cdcdcd]">
                        <p className="self-stretch  w-full max-w-[896px] text-2xl font-bold text-left text-[#373737]">
                            Pregunta en cuestion
                        </p>
                        <div className="flex flex-col  self-stretch  gap-[22px]">
                            {answers.map((answer, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="flex  items-center   h-14 relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#cdcdcd]"
                                    >
                                        <Radio
                                            text={answer}
                                            labelClass="text-base text-left text-[#373737] font-normal ml-2"
                                            checked={ans === i}
                                            onClick={() => setAns(i)}
                                            checkMarkClass="mt-1"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex flex-col gap-4 justify-end items-center self-stretch sm:flex-row">
                            <Button
                                color={COLOR.WHITE}
                                size={SIZE.SMALL}
                                addClass={"h-[52px] text-sm"}
                                // onClick={() => setShowDialog(false)}
                                // stretch
                            >
                                Saltar pregunta
                            </Button>
                            <Button
                                color={COLOR.RED}
                                size={SIZE.SMALL}
                                type="submit"
                                iconName={"arrow"}
                                // onClick={""}
                                text="Próxima pregunta"
                                addClass={"h-[52px] text-sm"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CertificatePregunta;
