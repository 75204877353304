import React, { useCallback, useEffect, useState } from "react";
import { noop } from "../../utils/noop";
import { twMerge } from "tailwind-merge";

function Toggle({
    label = "",
    activeProp = false,
    labelClass = undefined,
    callback = noop,
    labelSecond = false
    // formData = {}
}) {
    const [active, setActive] = useState(activeProp);
    const toggleActive = useCallback(() => {
        setActive((a) => {
            callback(!a);
            return !a;
        });
        // handleChange({
        //     name: "closest",
        //     value: formData.closest
        // });
    }, [callback]);

    useEffect(() => {
        setActive(activeProp);
    }, [activeProp]);

    return (
        <div
            onClick={toggleActive}
            className={
                twMerge("cursor-pointer hover:opacity-90 flex justify-start items-center transition-all duration-1000  relative gap-[5px]", labelSecond && "flex-row-reverse")
            }
        >
            <p className={labelClass ?? " text-base text-left text-[#373737]"}>
                {label}
            </p>
            {active ? (
                <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M15 6H9C5.6875 6 3 8.6875 3 12C3 15.3125 5.6875 18 9 18H15C18.3125 18 21 15.3125 21 12C21 8.6875 18.3125 6 15 6ZM15 16.5C12.5 16.5 10.5 14.5 10.5 12C10.5 9.53125 12.5 7.5 15 7.5C17.4688 7.5 19.5 9.53125 19.5 12C19.5 14.5 17.4688 16.5 15 16.5Z"
                        fill="#00BC4F"
                    />
                </svg>
            ) : (
                <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M15 6H9C5.65625 6 3 8.6875 3 12C3 15.3438 5.65625 18 9 18H15C18.3125 18 21 15.3438 21 12C21 8.6875 18.3125 6 15 6ZM4.5 12C4.5 9.53125 6.5 7.5 9 7.5C11.4688 7.5 13.5 9.53125 13.5 12C13.5 14.5 11.4688 16.5 9 16.5C6.5 16.5 4.5 14.5 4.5 12ZM15 16.5H12.9375C15.6562 14.125 15.6562 9.90625 12.9375 7.5H15C17.4688 7.5 19.5 9.53125 19.5 12C19.5 14.5 17.4688 16.5 15 16.5Z"
                        fill="#D80027"
                    />
                </svg>
            )}
        </div>
    );
}

export default Toggle;
