import React from "react";
import "./radioStyles.css";

import { noop } from "../../utils/noop";
import { twMerge } from "tailwind-merge";

function Radio({ checked = false, text = "", onClick = noop, name, labelClass = "", inputClass = "", checkMarkClass = "" }) {
  return (
    <label className="radio-container">
      <p className={twMerge("flex-grow-0 flex-shrink-0 text-xs font-bold text-left text-[#373737]", labelClass)}>
        {text}
      </p>

      <input
        type="radio"
        checked={checked ? "checked" : false}
        name={name}
        onClick={onClick}
        className={twMerge("", inputClass)}
      />
      <span className={twMerge("radio-checkmark", checkMarkClass)} />
    </label>
  );
}

export default Radio;
