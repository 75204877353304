import { useCallback, useState,useEffect } from "react";
import avatar from "../../assets/images/landing/profile.png";
import { Tag } from "../ui/Tag";
import Thumb from "../ui/Thumb";
import { Link } from "react-router-dom";
import RatingStars from "../ui/buttons/RatingStars";
import apiurl from "../../components/ApiConfig";

function JobSearchCard({
    budgetRange = "1500 - 2000",
    //offerCount = 34,
    averageOffer = 1750,
    suggestedOffer = 1950,
    likes = 340,
    isLikedProp = false,
    commentCount = 4,
    jobPerson = {
        avatar,
        name: "Abidemi K.",
        flag: "FLAG"
    },
    postedDateTime = "3 hours"
}) {
    const [isLiked, setLiked] = useState(isLikedProp);

    const toggleLike = useCallback(() => {
        setLiked((l) => !l);
    }, []);

    const [projects, setProjects] = useState([]);
    useEffect(() => {
        fetch(`${apiurl}/api/v1/UrgentHiring/`)
            .then(response => response.json())
            .then(data => {
                setProjects(data);
            })
            .catch(error => console.error('Error fetching job data:', error));
    }, []);
    return (
        <div>
        {projects.map((project) => (
            <div key={project.id} className="flex flex-col justify-start items-start self-stretch relative gap-4 p-[30px] bg-white border border-[#cdcdcd]">
                <div className="flex justify-start items-start relative gap-2.5 pb-1 border-t-0 border-r-0 border-b-2 border-l-0 border-[#d80027]">
                    <p className="text-base text-left text-[#373737]">
                        Presupuesto GTQ {budgetRange}
                    </p>
                </div>
                <Link to={`/platform/jobs/${project.id}`}>
                    <div className="relative flex flex-col items-start self-stretch justify-start gap-4 lg:flex-row">
                        <p className="flex-grow lg:w-[526px] text-[21px] font-bold text-left text-[#373737]">
                            {project.project_name}
                        </p>
                        <div className="flex flex-col justify-start items-start self-stretch relative gap-2.5 pl-5 border-t-0 border-r-0 border-b-0 border-l border-[#cdcdcd]">
                            <p className="text-[21px] text-left text-[#373737]">
                                0 ofertas
                            </p>
                            <div className="flex justify-start items-start gap-2.5">
                                <div className="relative flex flex-col items-start justify-start">
                                    <p className="text-lg font-bold text-left text-[#373737]">
                                        GTQ {averageOffer}
                                    </p>
                                    <p className="text-xs text-center text-[#7b7b7b]">
                                        Oferta promedio
                                    </p>
                                </div>
                                <div className="relative flex flex-col items-start justify-start">
                                    <p className="text-lg font-bold text-left text-[#373737]">
                                        GTQ {suggestedOffer}
                                    </p>
                                    <p className="text-xs text-center text-[#7b7b7b]">
                                        Oferta sugerida
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                <p style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"normal" }} className="self-stretch lg:w-[756px] text-base text-left text-[#7b7b7b]">
                    {project.description}
                </p>
            {/* <div className="flex flex-col items-start justify-start gap-4 sm:flex-row"> */}

            <div className="flex justify-start items-start  gap-5 overflow-x-auto w-[90vw] sm:w-full sm:overflow-x-hidden  min-h-[50px]">
                <Tag />
                <Tag />
                <Tag />
                <Tag />
            </div>
            {/* </div> */}
            <div className="flex items-center self-stretch justify-start gap-4">
                <div className="flex justify-start items-center flex-grow h-[22px] relative gap-4">
                    <RatingStars rating={3.5} />
                    <p className=" text-base text-left text-[#373737]">3,5</p>
                </div>
                <div className="flex justify-start items-center  relative gap-[2.3480663299560547px]">
                    <Thumb
                        count={likes}
                        onClick={toggleLike}
                        selected={isLiked}
                    />
                </div>
                <div className="flex justify-start items-center  relative gap-[2.3480663299560547px] ">
                    <svg
                        width={25}
                        height={24}
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M18.6523 4H6.65234C5.52734 4 4.65234 4.90625 4.65234 6V15C4.65234 16.125 5.52734 17 6.65234 17H9.65234V19.625C9.65234 19.875 9.80859 20 10.0273 20C10.0898 20 10.1523 20 10.2461 19.9375L14.1523 17H18.6523C19.7461 17 20.6523 16.125 20.6523 15V6C20.6523 4.90625 19.7461 4 18.6523 4ZM19.1523 15C19.1523 15.2812 18.9023 15.5 18.6523 15.5H13.6523L13.2461 15.8125L11.1523 17.375V15.5H6.65234C6.37109 15.5 6.15234 15.2812 6.15234 15V6C6.15234 5.75 6.37109 5.5 6.65234 5.5H18.6523C18.9023 5.5 19.1523 5.75 19.1523 6V15Z"
                            fill="#333333"
                        />
                    </svg>
                    <p className=" text-base text-left text-[#7b7b7b]">
                        {commentCount}
                    </p>
                </div>
            </div>
            <div className="flex justify-start items-center  relative gap-2.5">
                <div className=" w-10 h-10 relative overflow-hidden rounded-[30.56px]">
                    <img
                        src={avatar}
                        alt="rectangle-1413.png"
                        className="w-10 h-10 absolute left-[-0.44px] top-[-0.44px] object-cover"
                    />
                </div>
                <div className="flex justify-start items-center  relative gap-2.5">
                    <div className="flex justify-start items-center  relative gap-[5px]">
                        <p className=" text-sm text-left text-[#7b7b7b]">por</p>
                        <p className=" text-base font-bold text-left text-[#373737]">
                            {project.name}
                        </p>
                        {/* {jobPerson.flag} */}
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6 "
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M21.9997 5H2V18.3333H21.9997V5Z"
                                fill="white"
                            />
                            <path
                                d="M8.66643 5H2V18.3333H8.66643V5Z"
                                fill="#4997D0"
                            />
                            <path
                                d="M22.0004 5H15.334V18.3333H22.0004V5Z"
                                fill="#4997D0"
                            />
                        </svg>
                    </div>
                    <p className=" text-sm text-left text-[#7b7b7b]">
                        hace {postedDateTime}
                    </p>
                </div>
            </div>
        </div>
        ))}
        </div>
    );
}

export default JobSearchCard;
