import React, { useState } from "react";
import Dialog from "../../Dialog";
import AddTelephoneDialog from "../../dialogs/AddTelephoneDialog";
import { Button, COLOR } from "./Button";

function AddTelephoneButton() {
    // todo: Abstract this even more with DIALOG HANDLER? (clickable, dialog)
    const [showDialog, setDialog] = useState(0);

    return (
        <>
            <Dialog
                showDialog={showDialog}
                setDialog={setDialog}
                main={<AddTelephoneDialog closeDialog={() => setDialog(0)} />}
            />
            <Button
                onClick={() => setDialog(1)}
                // className="flex justify-center items-center min-w-fit  h-12 relative gap-2.5 px-4 py-2.5 rounded bg-[#292929]"
                text="Agregar número"
                iconName={"plus"}
                color={COLOR.BLACK}
                // stretch
                // addClass={"sm:flex-row-reverse"}
            />
            {/* <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-[30px] h-[30px] shrink-0 relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M21.875 13.75H16.25V8.125C16.25 7.8125 15.9375 7.5 15.625 7.5H14.375C14.0234 7.5 13.75 7.8125 13.75 8.125V13.75H8.125C7.77344 13.75 7.5 14.0625 7.5 14.375V15.625C7.5 15.9766 7.77344 16.25 8.125 16.25H13.75V21.875C13.75 22.2266 14.0234 22.5 14.375 22.5H15.625C15.9375 22.5 16.25 22.2266 16.25 21.875V16.25H21.875C22.1875 16.25 22.5 15.9766 22.5 15.625V14.375C22.5 14.0625 22.1875 13.75 21.875 13.75Z"
                        fill="#F2F2F2"
                    />
                </svg>
                <p className="text-left text-white shrink-0 text-md lg:text-lg">
                    Agregar número
                </p>
            </Butt> */}
        </>
    );
}

export default AddTelephoneButton;
