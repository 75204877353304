import React from "react";
import CommunityBlogPost from "../../../components/platform/CommunityBlogPost";
import PlatformWrapper from "../../../components/platform/PlatformWrapper";
import { attachmentSvg } from "../../../utils/icons";
import { PAGE_TYPES } from "../../../constants/platform";
import Input from "../../../components/ui/Input";

const CONTENT = {
    LAW: {
        url: "laws",
        type: "LAW",
        mainPage: "Sección de leyes"
    },
    JURIS: {
        url: "jurisprudence",
        type: "JURIS",
        mainPage: "Jurisprudencias"
    },
    DOCS: {
        url: "documents",
        type: "DOCS",
        mainPage: "Docs Jurídicos"
    }
};

function RepliesTemplate({ type = PAGE_TYPES.LAW }) {
    return (
        <PlatformWrapper>
            <div className="flex flex-col items-start gap-5 ">
                <a
                    href={`/platform/${CONTENT[type].url}/`}
                    className="px-5 xl:px-0 flex justify-start items-center absolute top-0 left-0  translate-y-[-70px] overflow-hidden gap-2"
                >
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6 "
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                            stroke="#292929"
                            strokeWidth="1.5"
                            stroke-miterlimit={10}
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M20.4999 12H3.66992"
                            stroke="#292929"
                            strokeWidth="1.5"
                            stroke-miterlimit={10}
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <p className="hover:opacity-80 cursor-pointer  text-base text-left text-[#d80027]">
                        {`${CONTENT[type].mainPage} / Titulo / Replicas`}
                    </p>
                </a>
                <div className="relative flex flex-col items-start justify-between w-full gap-4 sm:items-center sm:flex-row sm:justify-start sm:gap-0">
                    <p className="flex-grow  text-lg font-bold text-left text-[#373737]">
                        3 Replicas
                    </p>
                    <Input
                        name="De más antiguo a más reciente"
                        value="De más antiguo a más reciente"
                        // value={formData.sector}
                        // onChange={(e) => handleChange(e.target)}
                        type="dropdown"
                        // placeholder="Sector"
                        selectList={[
                            "De más antiguo a más reciente",
                            "De más antiguo a más trending",
                            "De más antiguo a más first",
                            "De más antiguo a más popular"
                        ]}
                    />
                </div>
                <CommunityBlogPost />
                <CommunityBlogPost />
                <CommunityBlogPost />
                <CommunityBlogPost />
                <div className="flex justify-start items-center  xl:w-[815px] h-[59px] relative gap-2.5 px-4 py-2.5 border border-[#cdcdcd]">
                    <svg
                        width={30}
                        height={31}
                        viewBox="0 0 30 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" xl:w-[30px] h-[30px] relative"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M15 5.8125C9.64844 5.8125 5.3125 10.1484 5.3125 15.5C5.3125 20.8516 9.64844 25.1875 15 25.1875C20.3516 25.1875 24.6875 20.8516 24.6875 15.5C24.6875 10.1484 20.3516 5.8125 15 5.8125ZM15 23.3125C10.6641 23.3125 7.1875 19.8359 7.1875 15.5C7.1875 11.2031 10.6641 7.6875 15 7.6875C19.2969 7.6875 22.8125 11.2031 22.8125 15.5C22.8125 19.8359 19.2969 23.3125 15 23.3125ZM11.875 14.875C12.5391 14.875 13.125 14.3281 13.125 13.625C13.125 12.9609 12.5391 12.375 11.875 12.375C11.1719 12.375 10.625 12.9609 10.625 13.625C10.625 14.3281 11.1719 14.875 11.875 14.875ZM18.125 14.875C18.7891 14.875 19.375 14.3281 19.375 13.625C19.375 12.9609 18.7891 12.375 18.125 12.375C17.4219 12.375 16.875 12.9609 16.875 13.625C16.875 14.3281 17.4219 14.875 18.125 14.875ZM18.2812 17.7266C17.4609 18.7031 16.25 19.25 15 19.25C13.7109 19.25 12.5 18.7031 11.7188 17.7266C11.3672 17.3359 10.7812 17.2969 10.3906 17.6094C10 17.9219 9.92188 18.5469 10.2734 18.9375C11.4453 20.3438 13.1641 21.125 15 21.125C16.7969 21.125 18.5156 20.3438 19.6875 18.9375C20.0391 18.5469 20 17.9219 19.5703 17.6094C19.1797 17.2969 18.5938 17.3359 18.2812 17.7266Z"
                            fill="#333333"
                        />
                    </svg>
                    <p className="flex-grow xl:w-[558px] text-sm text-left text-[#7b7b7b]">
                        Escribe un mensaje
                    </p>
                    <img
                        src={attachmentSvg}
                        alt="attachmentSvg"
                        className="px-3 cursor-pointer"
                    />
                    {/*<div className=" xl:w-[30px] h-[30px] relative overflow-hidden">
                       <p className="absolute left-[7px] top-[7px] text-base text-center text-[#333]">
                            paperclip
                        </p>
                    </div>*/}
                    <div className="flex justify-center items-center  xl:w-[135px] h-9 relative gap-2.5 px-8 py-2.5 rounded bg-[#292929]">
                        <p className="text-sm text-left text-white ">Enviar</p>
                    </div>
                </div>
            </div>
        </PlatformWrapper>
    );
}

export default RepliesTemplate;
