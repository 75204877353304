import { useState } from "react";

import { twMerge } from "tailwind-merge";

function NavTabs({
    selectedProp = 0,
    tabs = [],
    views = [],
    borderBottom = false,
    addClass,
    className,
    tabsClass = "flex items-center",
    viewsClass = "flex flex-col justify-start items-start self-stretch  gap-[30px]"
}) {
    const [selected, setSelected] = useState(selectedProp);
    return (
        // mb-[10vh]
        <div
            className={
                className ??
                twMerge(
                    `flex flex-col  `,
                    addClass,
                    Boolean(borderBottom) &&
                        "border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]"
                )
            }
        >
            <div className={tabsClass}>
                {tabs.map((tab, id) => (
                    <div
                        key={`tabButton_${id}`}
                        className=" tabButton hover:opacity-90"
                    >
                        <button
                            disabled={selected === id}
                            onClick={() => setSelected(id)}
                            className="flex flex-col justify-between sm:justify-center items-start min-h-[42px] h-full  rounded-tl-[5px] rounded-tr-[5px] "
                        >
                            <div className="flex flex-col sm:flex-row justify-center items-center self-stretch flex-grow relative gap-[5px] px-4 py-[5px] min-h-[42px] ">
                                {tab.icon}
                                <p
                                    className={` text-base text-center ${
                                        selected === id
                                            ? "text-[#373737] font-bold"
                                            : "text-[#7b7b7b]"
                                    } `}
                                >
                                    {tab.text}
                                </p>
                            </div>
                            <div className="flex flex-col justify-start items-start self-stretch  relative gap-2.5 min-h-fit">
                                <div
                                    className={`self-stretch  h-0.5  ${
                                        selected === id
                                            ? "bg-[#d80027]"
                                            : " bg-[#a4a4a4] opacity-0  "
                                    }`}
                                />
                            </div>
                        </button>
                    </div>
                ))}
            </div>

            <div className={viewsClass}>
                {views.map((tab, id) => (
                    <div
                        key={`viewsTab_${id}`}
                        className={`viewsTab  ${
                            selected === id ? "block   " : "hidden h-0 w-0"
                        }`}
                    >
                        {tab}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NavTabs;
