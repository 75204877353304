import { filterSvg } from "../../utils/icons";
import Pagination from "../Pagination";
import Input from "../ui/Input";
import Checkbox from "../ui/Checkbox";
import ProfSearchCard from "./ProfSearchCard";

function ProfSearchFooter({ formData, handleChange }) {
    return (
        <div className="flex flex-col lg:flex-row justify-start w-full items-center  gap-10 lg:gap-[309px]">
            <div className="flex justify-start items-center  relative gap-2.5">
                <p className=" text-base text-left text-[#373737]">
                    Trabajos por página:
                </p>
                <div className="flex flex-col justify-center items-start  gap-[5px]">
                    <Input
                        name="order"
                        value={formData.order}
                        onChange={(e) => handleChange(e.target)}
                        type="dropdown"
                        // placeholder="Order"
                        selectList={["10", "50", "100"]}
                        inputClassName={
                            // py-2.5
                            " text-base text-left text-[#373737] flex  items-center  self-stretch  gap-2.5 px-3 py-1.5 rounded h-9 bg-white border-t border-r border-b border-l border-[#292929]"
                        }
                    />
                </div>
            </div>
            <Pagination />
        </div>
    );
}

function ProfSearchBar({
    handleFormSubmission,
    formData,
    setFormData,
    handleChange,
    toggleFilterExpand,
    filterSideBarView
}) {
    return (
        <div className="flex flex-col justify-start items-center self-stretch w-full max-w-[1000px]   gap-5 p-[30px] bg-white border border-[#cdcdcd]">
            <div className="flex flex-col-reverse items-center justify-start w-full lg:flex-row gap-7">
                <Checkbox
                    text="Presencial"
                    toggleCheck={() =>
                        handleChange({
                            name: "inPerson",
                            value: !formData.inPerson
                        })
                    }
                    checked={formData.inPerson}
                />
                <div className="flex flex-col justify-start items-start self-stretch  w-full  flex-grow gap-[5px]">
                    <Input
                        placeholder="Buscar profesionales"
                        // required
                        onChange={(e) => handleChange(e.target)}
                        value={formData.search}
                        name="search"
                        suggestions
                        suggestionList={[]}
                        icon={
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                    fill="#333333"
                                />
                            </svg>
                        }
                    />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-start items-end lg:items-center self-stretch  gap-[30px]">
                <div className="flex justify-start items-center flex-grow relative gap-2.5">
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="  w-[30px] h-[30px] relative"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12.5005 18.125H12.4614C12.4614 17.5 12.5395 17.8125 9.1411 11.0547C8.8286 10.3516 8.16454 10 7.46142 10C6.79735 10 6.13329 10.3516 5.82079 11.0547C2.38329 17.8516 2.50048 17.5391 2.50048 18.125C2.50048 19.8828 4.72704 21.25 7.50048 21.25C10.2349 21.25 12.5005 19.8828 12.5005 18.125ZM5.74267 15.3906C6.17235 14.4922 6.75829 13.3594 7.46142 11.9141V11.875H7.50048C8.24267 13.4375 8.86767 14.6094 9.29735 15.5078C9.72704 16.4063 10.0786 17.0703 10.313 17.5H4.64892C4.92235 17.0313 5.27392 16.3281 5.74267 15.3906ZM23.1255 23.125H15.938V11.0938C16.9927 10.7813 17.813 9.88281 18.0474 8.75H23.1255C23.438 8.75 23.7505 8.47656 23.7505 8.125V7.5C23.7505 7.1875 23.438 6.875 23.1255 6.875H17.852C17.3442 5.78125 16.2505 5 15.0005 5C13.7114 5 12.6177 5.78125 12.1099 6.875H6.87548C6.52392 6.875 6.25048 7.1875 6.25048 7.5V8.125C6.25048 8.47656 6.52392 8.75 6.87548 8.75H11.9145C12.1489 9.88281 12.9692 10.7813 14.063 11.0938V23.125H6.87548C6.52392 23.125 6.25048 23.4375 6.25048 23.75V24.375C6.25048 24.7266 6.52392 25 6.87548 25H23.1255C23.438 25 23.7505 24.7266 23.7505 24.375V23.75C23.7505 23.4375 23.438 23.125 23.1255 23.125ZM15.0005 9.375C14.2974 9.375 13.7505 8.82813 13.7505 8.125C13.7505 7.46094 14.2974 6.875 15.0005 6.875C15.6645 6.875 16.2505 7.46094 16.2505 8.125C16.2505 8.82813 15.6645 9.375 15.0005 9.375ZM27.4614 18.125C27.4614 17.5 27.5395 17.8125 24.1411 11.0547C23.8286 10.3516 23.1645 10 22.4614 10C21.7974 10 21.1333 10.3516 20.8208 11.0547C17.3833 17.8516 17.5005 17.5391 17.5005 18.125C17.5005 19.8828 19.727 21.25 22.5005 21.25C25.2349 21.25 27.5005 19.8828 27.5005 18.125H27.4614ZM19.6489 17.5C19.9224 17.0313 20.2739 16.3281 20.7427 15.3906C21.1724 14.4922 21.7583 13.3594 22.4614 11.9141V11.875H22.5005C23.2427 13.4375 23.8677 14.6094 24.2974 15.5078C24.727 16.4063 25.0786 17.0703 25.313 17.5H19.6489Z"
                            fill="#D80027"
                        />
                    </svg>
                    <p className="flex-grow xl:w-[303px] text-base font-bold text-left text-[#373737]">
                        17K resultados
                    </p>
                </div>
                <div className="flex justify-start items-center  relative gap-2.5">
                    <p className=" text-base  shrink-0 text-left text-[#373737]">
                        Ordenar por
                    </p>
                    <div className="flex flex-col  shrink-0 justify-center items-start  gap-[5px]">
                        <Input
                            name="order"
                            value={formData.order}
                            onChange={(e) => handleChange(e.target)}
                            type="dropdown"
                            // placeholder="Order"
                            selectList={[
                                "Más reciente",
                                "Más popular",
                                "Más trending"
                            ]}
                            inputClassName={
                                // py-2.5
                                " text-base text-left text-[#373737] flex  items-center self-stretch  gap-2.5 px-3 py-1.5 rounded h-9 bg-white border-t border-r border-b border-l border-[#292929]"
                            }
                        />
                    </div>
                </div>
                {Boolean(filterSideBarView) && (
                    <div
                        onClick={toggleFilterExpand}
                        className="flex items-center gap-5 px-5 py-1 hover:bg-slate-50"
                    >
                        <img src={filterSvg} alt="filterSvg" />
                        Filters
                    </div>
                )}
            </div>
        </div>
    );
}

function ProfSearch(props) {
    return (
        <div className="flex flex-col items-start justify-start gap-5">
            <div className="flex flex-col items-end justify-start ">
                <ProfSearchBar {...props} />
                <div className="flex flex-col justify-start items-start  lg:w-[816px]">
                    <ProfSearchCard />
                    <ProfSearchCard />
                    <ProfSearchCard />
                </div>
            </div>
            <ProfSearchFooter {...props} />
        </div>
    );
}

export default ProfSearch;
