import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../ui/Input";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";

const validationSchema = Yup.object({
    banco: Yup.string().required("Banco requerido"),
    cuenta: Yup.number()
        .required("Cuenta requerida")
        .typeError("Debe ser un número"),
    tipo: Yup.string().required("Tipo requerido"),
    documento: Yup.string().required("Documento requerido"),
    correoBanco: Yup.string()
        .email("Formato de correo electrónico inválido")
        .required("Correo electrónico requerido")
});


function BankDepositDialog({
    showDialog = true,
    setDialog = () => {},
    // handleBankFormData = () => {},
    formData,
    setFormData
}) {
    const formik = useFormik({
        initialValues: {
            banco: "",
            cuenta: "",
            tipo: "",
            documento: "",
            correoBanco: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            setFormData({ ...formData, ...values });
            // setDialog(0);
        }
    });

    return (
        // TODO: Nested form - change this to normal div or find a way to separate this form from the main
        <form
            onSubmit={formik.handleSubmit}
            className={`flex flex-col justify-start items-end  sm:w-[50vw] relative gap-5 px-[34px] py-[30px] bg-white ${
                !showDialog && "hidden"
            }`}
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
        >
            <div className="flex justify-end w-full h-10 bg-white">
                <svg
                    width={40}
                    height={40}
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                    onClick={() => setDialog(0)}
                    // className="  w-10 h-10 absolute left-[506px] top-[13px]"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M19.9997 7.08325C12.8643 7.08325 7.08301 12.8645 7.08301 19.9999C7.08301 27.1353 12.8643 32.9166 19.9997 32.9166C27.1351 32.9166 32.9163 27.1353 32.9163 19.9999C32.9163 12.8645 27.1351 7.08325 19.9997 7.08325ZM19.9997 30.4166C14.2184 30.4166 9.58301 25.7812 9.58301 19.9999C9.58301 14.2708 14.2184 9.58325 19.9997 9.58325C25.7288 9.58325 30.4163 14.2708 30.4163 19.9999C30.4163 25.7812 25.7288 30.4166 19.9997 30.4166ZM25.2601 16.7708C25.5205 16.5624 25.5205 16.1458 25.2601 15.8853L24.1143 14.7395C23.8538 14.4791 23.4372 14.4791 23.2288 14.7395L19.9997 17.9687L16.7184 14.7395C16.5101 14.4791 16.0934 14.4791 15.833 14.7395L14.6872 15.8853C14.4268 16.1458 14.4268 16.5624 14.6872 16.7708L17.9163 19.9999L14.6872 23.2812C14.4268 23.4895 14.4268 23.9062 14.6872 24.1666L15.833 25.3124C16.0934 25.5728 16.5101 25.5728 16.7184 25.3124L19.9997 22.0833L23.2288 25.3124C23.4372 25.5728 23.8538 25.5728 24.1143 25.3124L25.2601 24.1666C25.5205 23.9062 25.5205 23.4895 25.2601 23.2812L22.0309 19.9999L25.2601 16.7708Z"
                        fill="#333333"
                    />
                </svg>
            </div>
            <div className="flex justify-start items-center self-stretch   relative gap-2.5 px-4 py-2.5 border border-[#cdcdcd]">
                <div className="flex justify-start items-center flex-grow gap-2.5">
                    <div className="flex flex-col justify-center items-start flex-grow relative gap-[3px]">
                        <p className="self-stretch   sm:w-[405px] text-lg font-bold text-left text-[#373737]">
                            Depósito bancario
                        </p>
                        <p className="self-stretch   sm:w-[405px] text-sm sm:text-base text-left text-[#373737]">
                            Puede tardar de 1 a 3 días, según el tipo de banco
                        </p>
                    </div>
                </div>
                <svg
                    width={60}
                    height={60}
                    viewBox="0 0 60 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="    sm:w-[60px] h-[60px] relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M46.875 44.375H46.25V40C46.25 38.9844 45.3906 38.125 44.375 38.125H43.125V26.25H39.375V38.125H35.625V26.25H31.875V38.125H28.125V26.25H24.375V38.125H20.625V26.25H16.875V38.125H15.625C14.5312 38.125 13.75 38.9844 13.75 40V44.375H13.125C12.0312 44.375 11.25 45.2344 11.25 46.25V47.5C11.25 47.8906 11.4844 48.125 11.875 48.125H48.125C48.4375 48.125 48.75 47.8906 48.75 47.5V46.25C48.75 45.2344 47.8906 44.375 46.875 44.375ZM42.5 44.375H17.5V41.875H42.5V44.375ZM48.125 19.2188L31.1719 12.1094C30.7812 11.9531 30.3906 11.9531 30 11.9531C29.5312 11.9531 29.1406 11.9531 28.75 12.1094L11.7969 19.2188C11.4844 19.2969 11.25 19.6875 11.25 20.0781V22.1875C11.25 22.7344 11.6406 23.125 12.1875 23.125H13.75V24.0625C13.75 24.6094 14.1406 25 14.6875 25H45.3125C45.7812 25 46.25 24.6094 46.25 24.0625V23.125H47.8125C48.2812 23.125 48.75 22.7344 48.75 22.1875V20.0781C48.75 19.6875 48.4375 19.2969 48.125 19.2188ZM15 21.25L30 15.625L45 21.25H15Z"
                        fill="#333333"
                    />
                </svg>
            </div>
            <div className="flex flex-col justify-start items-start self-stretch   gap-[5px]">
                <div className="flex justify-start items-center   relative gap-[5px]">
                    <p className="  text-sm text-left text-[#373737]">Banco</p>
                </div>
                <div className="flex items-start self-stretch justify-start h-12">
                    <div className="flex flex-col flex-grow">
                        <Input
                            name="banco"
                            value={formik.values.banco}
                            onChange={formik.handleChange}
                            // onChange={(e) => handleChange(e.target)}
                            type="dropdown"
                        />
                        {formik.errors.banco && (
                            <p className="text-sm text-red-500">
                                {formik.errors.banco}
                            </p>
                        )}
                    </div>
                    {/* <div className="flex flex-col justify-center items-start self-stretch flex-grow gap-2.5 px-4 py-2.5 rounded-tl rounded-bl bg-white border-t border-r-0 border-b border-l border-[#292929]">
                        <div className="flex justify-start items-center self-stretch   relative gap-2.5">
                            <p className="flex-grow sm:w-[415px] text-sm sm:text-base text-left text-[#7b7b7b]">
                                Seleccionar
                            </p>
                        </div>
                    </div> */}
                    {/* <div className="flex flex-col justify-center items-center self-stretch     sm:w-[60px] relative gap-2.5 px-4 py-2.5 rounded-tr rounded-br bg-white border-t border-r border-b border-l-0 border-[#292929]">
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6 "
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M11.9995 16.2999C11.4276 16.2999 10.8561 16.0794 10.4231 15.6463L3.90308 9.12634C3.80835 9.0316 3.80835 8.86818 3.90308 8.77345C3.99782 8.67871 4.16124 8.67871 4.25598 8.77345L10.776 15.2934C11.4512 15.9687 12.5478 15.9687 13.2231 15.2934L19.7431 8.77345C19.8378 8.67871 20.0012 8.67871 20.096 8.77345C20.1907 8.86818 20.1907 9.0316 20.096 9.12634L20.4495 9.47989L20.096 9.12634L13.576 15.6463C13.1429 16.0794 12.5715 16.2999 11.9995 16.2999Z"
                                fill="#A4A4A4"
                                stroke="#A4A4A4"
                            />
                        </svg>
                    </div> */}
                </div>
            </div>
            <div className="flex flex-col justify-start items-start self-stretch   gap-[5px]">
                <Input
                    label="Número de cuenta"
                    required
                    type="number"
                    addClass="w-full"
                    name="cuenta"
                    value={formik.values.cuenta}
                    onChange={formik.handleChange}
                />
                {formik.errors.cuenta && (
                    <p className="text-sm text-red-500">
                        {formik.errors.cuenta}
                    </p>
                )}
                {/* <div className="flex justify-start items-center self-stretch   relative gap-[5px]">
                    <p className="text-sm text-left ">
                        <span className="  text-sm text-left text-[#373737]">
                            Número de cuenta
                        </span>
                        <span className="  text-sm text-left text-[#d80027]">
                            *
                        </span>
                    </p>
                </div>
                <div className="flex justify-end items-center self-stretch   h-12 relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929]">
                    <p className="flex-grow   sm:w-[475px] text-sm sm:text-base text-left text-[#7b7b7b]">
                        Número de cuenta
                    </p>
                </div> */}
            </div>
            <div className="flex flex-col items-start self-stretch justify-center gap-5 sm:flex-row">
                <div className="flex flex-col justify-start items-start     sm:w-[133px] gap-[5px]">
                    <div className="flex justify-start items-center   relative gap-[5px]">
                        <p className="  text-sm text-left text-[#373737]">
                            Tipo
                        </p>
                    </div>
                    <Input
                        name="tipo"
                        className="w-[355px] sm:w-full "
                        value={formik.values.tipo}
                        onChange={formik.handleChange}
                        type="dropdown"
                        selectList={["Tipo:", "pdf", "txt", "image"]}
                    />
                    {formik.errors.tipo && (
                        <p className="text-sm text-red-500">
                            {formik.errors.tipo}
                        </p>
                    )}
                    {/* <div className="flex items-start self-stretch justify-start h-12">
                        <div className="flex flex-col justify-center items-start self-stretch flex-grow gap-2.5 px-4 py-2.5 rounded-tl rounded-bl bg-white border-t border-r-0 border-b border-l border-[#292929]">
                            <div className="flex justify-start items-center self-stretch   relative gap-2.5">
                                <p className="flex-grow w-full sm:w-[41px] text-sm sm:text-base text-left text-[#7b7b7b]">
                                    Tipo:
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center self-stretch     sm:w-[60px] relative gap-2.5 px-4 py-2.5 rounded-tr rounded-br bg-white border-t border-r border-b border-l-0 border-[#292929]">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M11.9995 16.3C11.4276 16.3 10.8561 16.0795 10.4231 15.6465L3.90308 9.12646C3.80835 9.03172 3.80835 8.86831 3.90308 8.77357C3.99782 8.67883 4.16124 8.67883 4.25598 8.77357L10.776 15.2936C11.4512 15.9688 12.5478 15.9688 13.2231 15.2936L19.7431 8.77357C19.8378 8.67883 20.0012 8.67883 20.096 8.77357C20.1907 8.86831 20.1907 9.03172 20.096 9.12646L20.4495 9.48001L20.096 9.12646L13.576 15.6465C13.1429 16.0795 12.5715 16.3 11.9995 16.3Z"
                                    fill="#A4A4A4"
                                    stroke="#A4A4A4"
                                />
                            </svg>
                        </div>
                    </div> */}
                </div>
                <div className="flex flex-col justify-start items-start flex-grow gap-[5px]">
                    <div className="flex justify-start items-center self-stretch   relative gap-[5px]">
                        <p className="text-sm text-left ">
                            <span className="  text-sm text-left text-[#373737]">
                                Documento
                            </span>
                            <span className="  text-sm text-left text-[#d80027]">
                                *
                            </span>
                        </p>
                    </div>
                    <Input
                        // label="Número de cuenta"
                        placeholder="Documento"
                        required
                        type="text"
                        addClass="w-[355px] sm:w-full "
                        name="documento"
                        value={formik.values.documento}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.documento && (
                        <p className="text-sm text-red-500">
                            {formik.errors.documento}
                        </p>
                    )}
                    {/* <div className="flex justify-end items-center self-stretch   h-12 relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929]">
                        <p className="flex-grow w-full sm:w-[322px] text-sm sm:text-base text-left text-[#7b7b7b]">
                            Documento
                        </p>
                    </div> */}
                </div>
            </div>
            <div className="flex flex-col justify-start items-start self-stretch   gap-[5px]">
                <div className="flex justify-start items-center self-stretch   relative gap-[5px]">
                    <p className="text-sm text-left ">
                        <span className="  text-sm text-left text-[#373737]">
                            Correo electrónico
                        </span>
                        <span className="  text-sm text-left text-[#d80027]">
                            *
                        </span>
                    </p>
                </div>
                <Input
                    // label="Número de cuenta"
                    required
                    type="text"
                    addClass="w-full"
                    name="correoBanco"
                    value={formik.values.correoBanco}
                    onChange={formik.handleChange}
                />
                {formik.errors.correoBanco && (
                    <p className="text-sm text-red-500">
                        {formik.errors.correoBanco}
                    </p>
                )}
                {/* <div className="flex justify-end items-center self-stretch   h-12 relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929]">
                    <p className="flex-grow   sm:w-[475px] text-sm sm:text-base text-left text-[#7b7b7b]">
                        Correo electrónico
                    </p>
                </div> */}
            </div>
            <div className="flex items-start justify-end gap-5">
                <Button
                    addClass={"h-11"}
                    size={SIZE.MEDIUM}
                    color={COLOR.WHITE}
                    onClick={(e) => {
                        e.preventDefault();
                        setDialog(0);
                    }}
                    // className="flex justify-center items-center   h-11 relative gap-2.5 px-8 py-2.5 rounded border-2  text-[#373737] border-[#292929]"
                >
                    Omitir
                </Button>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                    addClass={"h-11"}
                    size={SIZE.MEDIUM}
                    type="submit"
                    // className="flex justify-center items-center   h-11 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027] text-white"
                >
                    Guardar
                </Button>
            </div>
        </form>
    );
}

export default BankDepositDialog;
