import { useState } from "react";
import ConfirmationModal from "./DisputeConfirmationModal";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";

const DisputeTimerModal = ({
    title = "Detalles de la Apelación",
    topInfo = "El abogado ha apelado su disputa, si usted decide rechazar la contraoferta del abogado este caso pasara a etapa de arbitraje.",
    milestone = "",
    value = 200,
    name = "",
    desc = "Tras haber prestado el servicio en cuestión, me encuentro en la posición de tener que iniciar una apelación debido a que se ha cumplido completamente con lo acordado",
    counterOffer = 100,
    customTimerButtons = null,
    toggleDialog,
    closeDialog
}) => {
    return (
        <div
            className="flex flex-col justify-start items-center w-[832px] relative gap-5 p-[30px] bg-white"
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
        >
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 pb-4 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                <p className="flex-grow w-[772px] text-[21px] font-bold text-center text-[#373737]">
                    {title}
                </p>
            </div>
            <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-[5px] p-2 rounded bg-[#f5f8ff]">
                <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                        fill="#333333"
                    />
                </svg>
                <p className="flex-grow w-[727px] text-base text-left text-[#373737]">
                    {topInfo}
                </p>
            </div>
            <div className="flex relative flex-col flex-grow-0 flex-shrink-0 gap-2 justify-start items-start self-stretch">
                <div className="flex relative flex-grow-0 flex-shrink-0 gap-5 justify-start items-center self-stretch">
                    <p className="flex-grow w-[669px] text-base font-bold text-left text-[#373737]">
                        Hito: {milestone}
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 text-base font-bold text-left text-[#373737]">
                        Valor ${value}
                    </p>
                </div>
                <p className="self-stretch flex-grow-0 flex-shrink-0 w-[772px] text-base font-bold text-left text-[#373737]">
                    Nombre del profesional
                </p>
                <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5">
                    <p className="flex-grow-0 flex-shrink-0 text-base font-bold text-left text-[#373737]">
                        El abogado a indicado
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#373737]">
                        {name}
                    </p>
                </div>
                <p className="self-stretch flex-grow-0 flex-shrink-0 w-[772px] text-base text-left">
                    <span className="self-stretch flex-grow-0 flex-shrink-0 w-[772px] text-base font-bold text-left text-[#373737]">
                        Descripción de apelación:{" "}
                    </span>
                    <span className="self-stretch flex-grow-0 flex-shrink-0 w-[772px] text-base text-left text-[#373737]">
                        {desc}
                    </span>
                    <span className="self-stretch flex-grow-0 flex-shrink-0 w-[772px] text-base text-left text-[#d80027]">
                        más
                    </span>
                </p>
                <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5">
                    <p className="flex-grow-0 flex-shrink-0 text-base font-bold text-left text-[#373737]">
                        Monto de la contra oferta:
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 text-base text-left text-[#373737]">
                        ${counterOffer}
                    </p>
                </div>
            </div>
            <div className="flex flex-col flex-grow-0 flex-shrink-0 gap-4 justify-start items-center self-stretch">
                <div className="flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5">
                    <p className="flex-grow-0 flex-shrink-0 text-base font-bold text-left text-[#373737]">
                        Su plazo para aceptar o rechazar esta replica es
                    </p>
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM12 7.6875C11.25 7.6875 10.6875 8.28125 10.6875 9C10.6875 9.75 11.25 10.3125 12 10.3125C12.7188 10.3125 13.3125 9.75 13.3125 9C13.3125 8.28125 12.7188 7.6875 12 7.6875ZM13.75 15.625V14.875C13.75 14.6875 13.5625 14.5 13.375 14.5H13V11.375C13 11.1875 12.8125 11 12.625 11H10.625C10.4062 11 10.25 11.1875 10.25 11.375V12.125C10.25 12.3438 10.4062 12.5 10.625 12.5H11V14.5H10.625C10.4062 14.5 10.25 14.6875 10.25 14.875V15.625C10.25 15.8438 10.4062 16 10.625 16H13.375C13.5625 16 13.75 15.8438 13.75 15.625Z"
                            fill="#292929"
                        />
                    </svg>
                </div>
                <div className="flex justify-start items-start flex-grow-0 flex-shrink-0 relative gap-[15px]">
                    <div className="flex relative flex-col flex-grow-0 flex-shrink-0 justify-center items-center">
                        <p className="flex-grow-0 flex-shrink-0 text-[38px] font-bold text-left text-[#d80027]">
                            01
                        </p>
                        <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-[#373737]">
                            Días
                        </p>
                    </div>
                    <p className="flex-grow-0 flex-shrink-0 text-[38px] font-bold text-left text-[#373737]">
                        :
                    </p>
                    <div className="flex relative flex-col flex-grow-0 flex-shrink-0 justify-center items-center">
                        <p className="flex-grow-0 flex-shrink-0 text-[38px] font-bold text-left text-[#d80027]">
                            01
                        </p>
                        <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-[#373737]">
                            Horas
                        </p>
                    </div>
                    <p className="flex-grow-0 flex-shrink-0 text-[38px] font-bold text-left text-[#373737]">
                        :
                    </p>
                    <div className="flex relative flex-col flex-grow-0 flex-shrink-0 justify-center items-center">
                        <p className="flex-grow-0 flex-shrink-0 text-[38px] font-bold text-left text-[#d80027]">
                            01
                        </p>
                        <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-[#373737]">
                            Minutos
                        </p>
                    </div>
                </div>
            </div>
            {customTimerButtons ? (
                customTimerButtons
            ) : (
                <div className="flex flex-grow-0 flex-shrink-0 gap-4 justify-center items-center self-stretch">
                    <Button
                        // stretch
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        addClass={"h-11"}
                        text="Cerrar"
                        onClick={toggleDialog}
                        // onClick={goToPrev}
                    />
                    <Button
                        // stretch
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        addClass={"h-11"}
                        text="Rechazar"
                        // onClick={goToPrev}
                    />
                    <Button
                        // stretch
                        // color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        addClass={"h-11"}
                        text="Aceptar"
                        // onClick={goToPrev}
                    />
                </div>
            )}
            <svg
                width={30}
                height={30}
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="flex-grow-0 flex-shrink-0 cursor-pointer  w-[30px] h-[30px] absolute left-[802px] top-0"
                preserveAspectRatio="xMidYMid meet"
                onClick={closeDialog}
            >
                <path
                    d="M15 5.3125C9.64844 5.3125 5.3125 9.64844 5.3125 15C5.3125 20.3516 9.64844 24.6875 15 24.6875C20.3516 24.6875 24.6875 20.3516 24.6875 15C24.6875 9.64844 20.3516 5.3125 15 5.3125ZM15 22.8125C10.6641 22.8125 7.1875 19.3359 7.1875 15C7.1875 10.7031 10.6641 7.1875 15 7.1875C19.2969 7.1875 22.8125 10.7031 22.8125 15C22.8125 19.3359 19.2969 22.8125 15 22.8125ZM18.9453 12.5781C19.1406 12.4219 19.1406 12.1094 18.9453 11.9141L18.0859 11.0547C17.8906 10.8594 17.5781 10.8594 17.4219 11.0547L15 13.4766L12.5391 11.0547C12.3828 10.8594 12.0703 10.8594 11.875 11.0547L11.0156 11.9141C10.8203 12.1094 10.8203 12.4219 11.0156 12.5781L13.4375 15L11.0156 17.4609C10.8203 17.6172 10.8203 17.9297 11.0156 18.125L11.875 18.9844C12.0703 19.1797 12.3828 19.1797 12.5391 18.9844L15 16.5625L17.4219 18.9844C17.5781 19.1797 17.8906 19.1797 18.0859 18.9844L18.9453 18.125C19.1406 17.9297 19.1406 17.6172 18.9453 17.4609L16.5234 15L18.9453 12.5781Z"
                    fill="#333333"
                />
            </svg>
        </div>
    );
};

function DetailsModal({ customTimerButtons, closeDialog }) {
    const [showConfirmDialog, setShowConfirmDialog] = useState(0);

    const toggleDialog = () => setShowConfirmDialog((p) => !p);

    return showConfirmDialog ? (
        <ConfirmationModal cancelCallback={toggleDialog} />
    ) : (
        <DisputeTimerModal
            customTimerButtons={customTimerButtons}
            toggleDialog={toggleDialog}
            closeDialog={closeDialog}
        />
    );
}

export default DetailsModal;
