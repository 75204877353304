import React, { useState, useEffect } from "react";
import Input from "../../components/ui/Input";
import { Button, COLOR, SIZE } from "../../components/ui/buttons/Button";
import apiurl from "../../components/ApiConfig";
import { useFormik } from "formik";
import Swal from "sweetalert2";

function SettingsProfile() {
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    localStorage.setItem("userEdited", edited);
  }, [edited]);

  const formik = useFormik({
    initialValues: {
      house_number: "",
      address_two: "",
      house_number_two: "",
      city: "",
      postal_code: "",
      company: "",
      location: "",
      selectValue: "Español",
    },
    onSubmit: async (values) => {
      try {
        const authToken = localStorage.getItem("authToken");

        const response = await fetch(
          "https://eprof-production-6g7zxvibga-uc.a.run.app/Profile/Personal",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(values),
          }
        );

        if (!response.ok) {
          throw new Error("Error al actualizar el perfil");
        }

        await Swal.fire({
          icon: "success",
          title: "¡Perfil actualizado correctamente!",
          showConfirmButton: false,
          timer: 1500,
        });

        setEdited(true);

        // Refrescar la página
        window.location.reload();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "¡Hubo un error al actualizar el perfil!",
          text: "Por favor, inténtalo de nuevo más tarde.",
        });
        console.error("Error:", error);
      }
    },
  });

  const [formData, setFormData] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.location.href = "/login";
    }
    if (authToken) {
      fetchUser(authToken);
    }
  }, []);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const fetchUser = async (authToken) => {
    try {
      const response = await fetch(`${apiurl}/Profile/Personal`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (!response.ok) {
        throw new Error(`Error fetching user data: ${response.status}`);
      }

      const data = await response.json();
      setUserData(data);
    } catch (error) {
      setError(error);
    }
  };

  if (error) {
    return <div>Error loading settings profile: {error.message}</div>;
  }

  if (!userData) {
    return <div>Loading settings profile...</div>;
  }

  return (
    <div className="flex flex-col justify-start items-start">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col justify-start items-start  w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
          <div className="flex flex-col gap-3 justify-start items-start self-stretch">
            <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
              <p className="flex-grow w-full max-w-[765px] text-[21px] font-bold text-left text-[#373737]">
                Detalles del perfil
              </p>
            </div>
          </div>
          <div className="flex justify-start items-center self-stretch  relative gap-[5px] p-2 rounded bg-[#f5f8ff]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative w-6 h-6"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M9.09375 11.2188C8.9375 11.0625 8.6875 11.0625 8.5625 11.2188L7.84375 11.9062C7.6875 12.0625 7.6875 12.3125 7.84375 12.4375L10.6875 15.3125C10.8125 15.4688 11.0625 15.4688 11.2188 15.3125L16.5938 9.96875C16.75 9.8125 16.75 9.5625 16.5938 9.4375L15.9062 8.71875C15.75 8.5625 15.5312 8.5625 15.375 8.71875L10.9688 13.0938L9.09375 11.2188ZM18.5625 6.625L12.5625 4.125C12.375 4.0625 12.1875 4.03125 12 4.03125C11.7812 4.03125 11.5938 4.0625 11.4062 4.125L5.40625 6.625C4.84375 6.875 4.5 7.40625 4.5 8C4.5 14.2188 8.0625 18.5 11.4062 19.9062C11.7812 20.0625 12.1875 20.0625 12.5625 19.9062C15.25 18.7812 19.5 14.9375 19.5 8C19.5 7.40625 19.125 6.875 18.5625 6.625ZM12 18.5C8.9375 17.25 6 13.3125 6 8L12 5.5L18 8C18 13.4375 14.9062 17.2812 12 18.5Z"
                fill="#333333"
              ></path>
            </svg>
            <p className="flex-grow w-full max-w-[720px] text-sm text-left">
              <span className="flex-grow w-full max-w-[720px] text-sm text-left text-[#373737]">
                Tus detalles han sido guardados mediante nuestro proceso de
                verificación de ID. Para cambiarlo, debes{" "}
              </span>
              <span className="flex-grow w-full max-w-[720px] text-sm text-left text-[#d80027]">
                solicitar un permiso.
              </span>
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start  w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
          <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
            <p className="flex-grow w-full max-w-[765px] text-lg font-bold text-left text-[#373737]">
              Nombre completo
            </p>
          </div>
          <div className="flex justify-start items-start self-stretch  gap-[22px] flex-col md:flex-row">
            <Input
              label="Nombre"
              placeholder={userData.first_name}
              name="firstName"
              onChange={handleInputChange}
              // value={formik.lastName}
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" w-6 h-6 relative bg-[#f2f2f2]"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                    fill="#333333"
                  ></path>
                </svg>
              }
              required
              type="text"
              disabled
            />
            <Input
              label="Apellido"
              placeholder={userData.last_name}
              name="lastName"
              onChange={handleInputChange}
              // value={formik.lastName}
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" w-6 h-6 relative bg-[#f2f2f2]"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                    fill="#333333"
                  ></path>
                </svg>
              }
              required
              type="text"
              disabled
            />
          </div>
        </div>

        <div className="flex flex-col justify-start items-start  w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
          <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
            <p className="flex-grow w-full max-w-[765px] text-lg font-bold text-left text-[#373737]">
              Dirección
            </p>
          </div>
          <Input
            label="Dirección"
            placeholder={userData.legal_address}
            name="address1line1"
            onChange={handleInputChange}
            // value={formik.address1line1}
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className=" w-6 h-6 relative bg-[#f2f2f2]"
                preserveAspectRatio="xMidYMid meet"
              >
                <path
                  d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                  fill="#333333"
                ></path>
              </svg>
            }
            required
            type="text"
            disabled
          />
          <Input
            placeholder={
              userData.house_number ? userData.house_number : "Casa número 23"
            }
            name="house_number"
            onChange={formik.handleChange}
            value={formik.values.house_number}
            icon={
                userData.house_number && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-6 h-6 relative bg-[#f2f2f2]"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                      fill="#333333"
                    ></path>
                  </svg>
                  )
              }
            type="text"
            disabled={userData.house_number ? true : false}
          />

          <Input
            label="Dirección 2"
            placeholder={
              userData.address_two
                ? userData.address_two
                : "Vda 24 Urb San Juan"
            }
            name="address_two"
            onChange={formik.handleChange}
            value={formik.values.address_two}
            icon={
                userData.address_two && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-6 h-6 relative bg-[#f2f2f2]"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                      fill="#333333"
                    ></path>
                  </svg>
                  )
              }
            required
            type="text"
            disabled={userData.address_two ? true : false}
          />
          <Input
            placeholder={
              userData.house_number_two
                ? userData.house_number_two
                : "Casa número 23"
            }
            name="house_number_two"
            onChange={formik.handleChange}
            value={formik.values.house_number_two}
            icon={
                userData.house_number_two && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-6 h-6 relative bg-[#f2f2f2]"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                      fill="#333333"
                    ></path>
                  </svg>
                  )
              }
            type="text"
            disabled={userData.house_number_two ? true : false}
          />

          <Input
            label="Ciudad/Cantón"
            placeholder={
              userData.canton
                ? userData.canton
                : userData.municipality
                ? userData.municipality
                : "Guanajuato"
            }
            name="city"
            onChange={formik.handleChange}
            value={
              userData.canton || userData.municipality
                ? userData.canton
                  ? userData.canton
                  : userData.municipality
                : formik.values.city
            }
            icon={
                (userData.canton || userData.municipality) && (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 relative bg-[#f2f2f2]"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                            fill="#333333"
                        ></path>
                    </svg>
                )
            }
            required
            type="text"
            disabled={
              userData.city || userData.municipality || userData.canton
                ? true
                : false
            }
          />
          <div className="flex justify-start items-start self-stretch  gap-[22px] flex-col md:flex-row">
            <Input
              label="Código postal"
              placeholder={userData.postal_code ? userData.postal_code : "6201"}
              name="postal_code"
              onChange={formik.handleChange}
              value={formik.values.postal_code}
              icon={
                userData.postal_code && (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 relative bg-[#f2f2f2]"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                            fill="#333333"
                        ></path>
                    </svg>
                )
            }
              required
              type="text"
              disabled={userData.postal_code ? true : false}
            />
            <Input
              label="Estad/Provincia"
              placeholder={
                userData.province
                  ? userData.province
                  : userData.department
                  ? userData.department
                  : "Maturin"
              }
              name="city"
              onChange={formik.handleChange}
              value={
                userData.province || userData.department
                  ? userData.province
                    ? userData.province
                    : userData.department
                  : formik.values.city
              }
              icon={
                (userData.province || userData.department) && (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 relative bg-[#f2f2f2]"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                            fill="#333333"
                        ></path>
                    </svg>
                )
            }
              required
              type="text"
              disabled={
                userData.city || userData.province || userData.department
                  ? true
                  : false
              }
            />
          </div>
          <div className="flex flex-col justify-start items-start self-stretch gap-[5px]">
            {userData.district && (
              <Input
                label="Distrito"
                placeholder={userData.district}
                name="address1line1"
                onChange={handleInputChange}
                value={formik.district}
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 relative bg-[#f2f2f2]"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <path
                      d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                      fill="#333333"
                    ></path>
                  </svg>
                }
                required
                type="text"
                disabled
              />
            )}
          </div>
          <div className="flex flex-col justify-start items-start self-stretch  gap-[5px]">
            <Input
              label="País"
              placeholder={userData.nationality}
              name="address1line1"
              onChange={handleInputChange}
              value={formik.address1line1}
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" w-6 h-6 relative bg-[#f2f2f2]"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                    fill="#333333"
                  ></path>
                </svg>
              }
              required
              type="text"
              disabled
            />
          </div>

          <Input
            label="Compañía"
            placeholder={userData.company ? userData.company : "Google"}
            name="company"
            onChange={formik.handleChange}
            value={formik.values.company}
            required
            type="text"
            icon={
                userData.company && (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 relative bg-[#f2f2f2]"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
                            fill="#333333"
                        ></path>
                    </svg>
                )
            }
            disabled={userData.company ? true : false}
          />
          <div className="flex justify-start items-start self-stretch  gap-[22px] flex-col md:flex-row">
            <Input
              name="selectZone"
              type="dropdown"
              label="Zona horaria"
              labelClass={"mb-1"}
              onChange={handleInputChange}
              value={formik.selectZone}
              selectList={[
                "Lorem Ipsum",
                "Instantáneamente",
                "Cada hora",
                "Cada 3 horas",
                "Cada 6 horas",
                "Cada 12 horas",
                "Diario",
              ]}
              // required
              className=""
            />

            <Input
              label="Ubicación"
              placeholder="Lorem ipsum"
              name="location"
              onChange={handleInputChange}
              value={formik.location}
              required
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative w-6 h-6"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M12 4C8.65625 4 6 6.71875 6 10C6 12.25 6.71875 12.9375 10.7188 19.3125C11.3125 20.25 12.6562 20.25 13.25 19.3125C17.25 12.9375 18 12.25 18 10C18 6.6875 15.2812 4 12 4ZM12 18.5C8 12.125 7.5 11.7188 7.5 10C7.5 7.53125 9.5 5.5 12 5.5C14.4688 5.5 16.5 7.53125 16.5 10C16.5 11.7188 16 12.0625 12 18.5ZM9.5 10C9.5 11.4062 10.5938 12.5 12 12.5C13.375 12.5 14.5 11.4062 14.5 10C14.5 8.625 13.375 7.5 12 7.5C10.5938 7.5 9.5 8.625 9.5 10Z"
                    fill="#333333"
                  ></path>
                </svg>
              }
              type="text"
            />
          </div>
        </div>

        <div className="flex flex-col justify-start items-start  w-full max-w-[813px] gap-[22px] p-6 border border-[#cdcdcd]">
          <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
            <p className="flex-grow w-full max-w-[765px] text-lg font-bold text-left text-[#373737]">
              Configuración de idioma
            </p>
          </div>
          <div className="flex flex-col justify-start items-start w-full max-w-[371.5px] gap-[5px]">
            <Input
              name="selectValue"
              type="dropdown"
              onChange={handleInputChange}
              value={formik.values.selectValue}
              selectList={[
                "Español",
                "Instantáneamente",
                "Cada hora",
                "Cada 3 horas",
                "Cada 6 horas",
                "Cada 12 horas",
                "Diario",
              ]}
              // required
              className="lg:min-w-[279.5px]"
            />
          </div>
        </div>
        <div className="flex flex-col justify-start items-start  w-full max-w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
          <div className="flex justify-start items-center self-stretch  relative gap-[22px]">
            <p className="flex-grow w-full max-w-[765px] text-lg font-bold text-left text-[#373737]">
              Número de teléfono de seguridad
            </p>
          </div>
          <p className="self-stretch  w-full max-w-[765px] text-base text-left text-[#373737]">
            <span className="self-stretch  w-full max-w-[765px] text-base font-bold text-left text-[#373737]">
              Teléfono:
            </span>
            <span className="self-stretch  w-full max-w-[765px] text-base text-left text-[#373737]">
              {userData.phone}
            </span>
          </p>
          <p className="self-stretch  w-full max-w-[765px] text-base text-left text-[#373737]">
            <span className="self-stretch  w-full max-w-[765px] text-base font-bold text-left text-[#373737]">
              País:{" "}
            </span>
            <span className="self-stretch  w-full max-w-[765px] text-base text-left text-[#373737]">
              {userData.nationality}
            </span>
          </p>

          <Button size={SIZE.LARGE} color={COLOR.WHITE} iconName={"plus"}>
            Solicitar modificación
          </Button>
        </div>

        <div className="flex flex-col justify-center items-end  w-full max-w-[813px] gap-[22px] p-6 bg-[#f2f2f2] border border-[#cdcdcd]">
          <Button
            size={SIZE.LARGE}
            color={COLOR.RED}
            iconName={"plus"}
            type="submit"
          >
            Guardar configuración
          </Button>
        </div>
      </form>
    </div>
  );
}

export default SettingsProfile;
