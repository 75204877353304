import React from "react";
import Input from "../ui/Input";
import Checkbox from "../ui/Checkbox";
import Expand from "../ui/Expand";
import Toggle from "../ui/Toggle";
import { CATEGORIESS } from "../../constants/constants";

const apiUrl = 'https://eprof-production-6g7zxvibga-uc.a.run.app';
function JobsFilters({
    handleFormSubmission,
    formData,
    setFormData,
    toggleFilterExpand,
    handleChange,
    filterSideBarView
}) {
    const handleCategoryChange = async (e) => {
        const selectedCategory = e.target.value;
        setFormData({ ...formData, category: selectedCategory });
    
        let apiEndpoint = `${apiUrl}/api/v1/`;
    
        if (selectedCategory === 'DISPONIBLES') {
            apiEndpoint += 'UrgentHiring/';
            try {
                const responseHiring = await fetch(apiEndpoint);
                const dataHiring = await responseHiring.json();
                console.log('Trabajos de UrgentHiring:', dataHiring);
    
                apiEndpoint = `${apiUrl}/api/v1/UrgentAdvice/`;
                const responseAdvice = await fetch(apiEndpoint);
                const dataAdvice = await responseAdvice.json();
                console.log('Trabajos de UrgentAdvice:', dataAdvice);
    
            } catch (error) {
                console.error('Error al obtener datos de la API:', error);
            }
        } else if (selectedCategory === 'CONTRATACION URGENTE') {
            apiEndpoint += 'UrgentHiring/';
        } else if (selectedCategory === 'ASESORÍA URGENTE') {
            apiEndpoint += 'UrgentAdvice/';
        }
    
        if (selectedCategory !== 'DISPONIBLES') {
            try {
                const response = await fetch(apiEndpoint);
                const data = await response.json();
                // Manejar la respuesta de la API como se ha hecho anteriormente
                console.log(data);
                // Actualizar el estado o realizar otras acciones necesarias con los datos obtenidos
            } catch (error) {
                console.error('Error al obtener datos de la API:', error);
            }
        }
    };
    return (
        <div className="flex flex-col justify-start items-start  relative gap-[30px]">
            <div
                onClick={toggleFilterExpand}
                className={`relative ${
                    filterSideBarView ? "flex" : "hidden"
                } flex-col items-end w-full `}
            >
                <svg
                    width={40}
                    height={40}
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute top-0 right-0 flex-grow-0 flex-shrink-0 w-10 h-10 cursor-pointer"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M20.0007 7.08301C12.8652 7.08301 7.08398 12.8643 7.08398 19.9997C7.08398 27.1351 12.8652 32.9163 20.0007 32.9163C27.1361 32.9163 32.9173 27.1351 32.9173 19.9997C32.9173 12.8643 27.1361 7.08301 20.0007 7.08301ZM20.0007 30.4163C14.2194 30.4163 9.58398 25.7809 9.58398 19.9997C9.58398 14.2705 14.2194 9.58301 20.0007 9.58301C25.7298 9.58301 30.4173 14.2705 30.4173 19.9997C30.4173 25.7809 25.7298 30.4163 20.0007 30.4163ZM25.2611 16.7705C25.5215 16.5622 25.5215 16.1455 25.2611 15.8851L24.1152 14.7393C23.8548 14.4788 23.4382 14.4788 23.2298 14.7393L20.0007 17.9684L16.7194 14.7393C16.5111 14.4788 16.0944 14.4788 15.834 14.7393L14.6882 15.8851C14.4277 16.1455 14.4277 16.5622 14.6882 16.7705L17.9173 19.9997L14.6882 23.2809C14.4277 23.4893 14.4277 23.9059 14.6882 24.1663L15.834 25.3122C16.0944 25.5726 16.5111 25.5726 16.7194 25.3122L20.0007 22.083L23.2298 25.3122C23.4382 25.5726 23.8548 25.5726 24.1152 25.3122L25.2611 24.1663C25.5215 23.9059 25.5215 23.4893 25.2611 23.2809L22.0319 19.9997L25.2611 16.7705Z"
                        fill="#333333"
                    />
                </svg>
            </div>
            <p className=" w-full lg:w-[216px] text-2xl font-bold text-left text-[#373737]">
                Filtrado por
            </p>
            <div className="flex flex-col justify-start items-start  gap-[30px]">
                <div className="flex flex-col justify-start items-start  gap-2.5 w-full pb-[30px] border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                    <Expand
                        label="Ubicación del cliente"
                        content={
                            <>
                                <Toggle
                                    label="Más cercanos"
                                    callback={() => {
                                        handleChange({
                                            name: "closest",
                                            value: formData.closest
                                        });
                                    }}
                                />
                                <div className="flex flex-col justify-start items-start  w-full lg:w-[336px] gap-[5px]">
                                    <Input
                                        // label="Apellido"
                                        placeholder="Selecciona una ubicación"
                                        // required
                                        onChange={(e) => handleChange(e.target)}
                                        value={formData.location}
                                        suggestions
                                        suggestionList={[]}
                                        icon={
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="relative w-6 h-6 "
                                                preserveAspectRatio="xMidYMid meet"
                                            >
                                                <path
                                                    d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                                    fill="#333333"
                                                />
                                            </svg>
                                        }
                                    />
                                </div>
                            </>
                        }
                    />
                </div>
                <div className="flex flex-col justify-start items-start  gap-2.5 w-full pb-[30px] border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                    <Expand
                        label="Categoría"
                        content={
                            <div className="flex flex-col justify-start items-start  w-full lg:w-[336px] gap-[5px]">
                                <Input
                                    placeholder="Selecciona una categoría"
                                    onChange={handleCategoryChange}
                                    value={formData.category}
                                    name="category"
                                    selectList={CATEGORIESS['Público']}
                                    type="dropdown"
                                />
                            </div>
                        }
                    />
                </div>
                <div className="flex flex-col justify-start items-start  gap-2.5 w-full pb-[30px] border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                    <Expand
                        label="Precio fijo"
                        content={
                            <div className="flex flex-col justify-start items-start  w-full lg:w-[336px] gap-2.5">
                                <Input
                                    label="Mínimo"
                                    placeholder="ej: 5"
                                    // required
                                    onChange={(e) => handleChange(e.target)}
                                    value={formData.minPrice}
                                    name="minPrice"
                                    //  lg:w-[304px]
                                    inputClassName="flex justify-end items-center self-stretch  h-9 relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929] w-full text-sm font-semibold text-left"
                                />
                                <Input
                                    label="Máximo"
                                    placeholder="ej: 15"
                                    // required
                                    onChange={(e) => handleChange(e.target)}
                                    value={formData.maxPrice}
                                    name="maxPrice"
                                    //  lg:w-[304px]
                                    inputClassName="flex justify-end items-center self-stretch  h-9 relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929] w-full text-sm font-semibold text-left"
                                />
                            </div>
                        }
                    />
                </div>
                <div className="flex flex-col justify-start items-start  gap-2.5 w-full pb-[30px] border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                    <Expand
                        label="Información del cliente"
                        content={
                            <Checkbox
                                text="Pago verificado"
                                toggleCheck={() =>
                                    handleChange({
                                        name: "verifiedOnly",
                                        value: !formData.verifiedOnly
                                    })
                                }
                                checked={formData.verifiedOnly}
                            />
                        }
                    />
                </div>
                <div className="flex flex-col justify-start items-start  gap-2.5 w-full pb-[30px] border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                    <Expand
                        label="Historial del cliente"
                        content={
                            <>
                                <Checkbox
                                    text={`Sin contrataciones (1)`}
                                    toggleCheck={() =>
                                        handleChange({
                                            name: "noHires",
                                            value: !formData.noHires
                                        })
                                    }
                                    checked={formData.noHires}
                                />
                                <Checkbox
                                    text={`1 a 9 contrataciones (3)`}
                                    toggleCheck={() =>
                                        handleChange({
                                            name: "lowHires",
                                            value: !formData.lowHires
                                        })
                                    }
                                    checked={formData.lowHires}
                                />
                                <Checkbox
                                    text={`Más de 10 contrataciones (5)`}
                                    toggleCheck={() =>
                                        handleChange({
                                            name: "highHires",
                                            value: !formData.highHires
                                        })
                                    }
                                    checked={formData.highHires}
                                />
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default JobsFilters;
