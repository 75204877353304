import FAQItem from "./FAQItem";

function InviteFriendFAQ() {
    return (
        <div
            className="relative flex flex-col items-start justify-start w-full gap-5 px-5 lg:w-auto lg:p-0"
            id="faq"
        >
            <p className="  lg:w-[936px] font-[Satoshi] text-2xl lg:text-5xl font-bold text-center text-[#373737]">
                Preguntas frecuentes
            </p>
            <div className="flex flex-col justify-start items-start   lg:w-[936px] overflow-hidden bg-white border-2 border-[#a4a4a4]">
                <FAQItem expandedProp />
                <FAQItem />
                <FAQItem />
                <FAQItem />
                <FAQItem />
            </div>
        </div>
    );
}

export default InviteFriendFAQ;
