
import React, { useState, useEffect } from 'react';
import apiurl from "../../../components/ApiConfig";
import ProfileBoard from "../../../components/platform/ProfileBoardt";
import VerificationsBoard from '../../../components/platform/VerificationsProfile';

function ClientProfile() {
  const [userData, setUserData] = useState(null);
   // eslint-disable-next-line
  const [error, setError] = useState(null);

  useEffect(() => {
        
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
        window.location.href = "/login";
    }
    if (authToken) {
        fetchUser(authToken);
      }
  }, []);

  const fetchUser = async (token) => {
    try {
      const response = await fetch(`${apiurl}/User/Profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (!response.ok) {
        throw new Error(`Error fetching user data: ${response.status}`);
      }

      const data = await response.json();
      setUserData(data);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="flex w-full flex-col items-center mt-[150px]">
      <div className="flex flex-col items-start justify-start w-full gap-6 xl:w-auto xl:flex-row">
        <div className="flex flex-col justify-start items-start  gap-[27px]">
          <div className=" xl:w-[375px] flex flex-col gap-5">
            <ProfileBoard />
            <VerificationsBoard />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full  xl:w-auto">
          <div
            className="relative flex flex-col w-full xl:w-[1150px] p-2.5 bg-white"
            style={{ boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)", padding: "50px", border: "1px solid #c5c5c5" }}
          >
            <span style={{ fontWeight: 'bold', fontSize: "20px",}}>Descripcion</span>
            <br />
            {userData && userData.description && (
              <span>{userData.description}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientProfile;