 // eslint-disable-next-line
function VerificationsBoard({
}) {
    return (
        <div
            className="relative flex flex-col items-start justify-start w-full gap-4 p-5 bg-white"
            style={{
                boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)",
                width:"375px",
                justifyContent:"center",
                display:"flex",
                alignItems:"start",
                border: "1px solid #c5c5c5"
            }}
        >
              <span  style={{fontWeight: "bold",fontSize:"20px",}}>Verificaciones</span>
              <div style={{display:"flex", fontSize:"16px", fontWeight:"400"}}>
              <img alt="img" src={require(`./img/User.png`)} style={{width:"25px"}}/>
              <span style={{position:"relative", left:"5px"}}>Identidad verificada</span>
              <img alt="img" src={require(`./img/Check-gray.png`)} style={{width:"25px",position:"relative", left:"130px"}}/>
              </div>
              <div style={{display:"flex", fontSize:"16px", fontWeight:"400"}}>
              <img alt="img" src={require(`./img/Dolar.png`)} style={{width:"25px"}}/>
              <span style={{position:"relative", left:"5px"}}>Pago verificado</span>
              <img alt="img" src={require(`./img/Check.png`)} style={{width:"25px",position:"relative", left:"164px"}}/>
              </div>
              <div style={{display:"flex", fontSize:"16px", fontWeight:"400"}}>
              <img alt="img" src={require(`./img/Phone.png`)} style={{width:"25px"}}/>
              <span style={{position:"relative", left:"5px"}}>Teléfono verificado</span>
              <img alt="img" src={require(`./img/Check.png`)} style={{width:"25px",position:"relative", left:"139px"}}/>
              </div>
              <div style={{display:"flex", fontSize:"16px", fontWeight:"400"}}>
              <img alt="img" src={require(`./img/mail.png`)} style={{width:"25px"}}/>
              <span style={{position:"relative", left:"5px"}}>Email verificado</span>
              <img alt="img" src={require(`./img/Check.png`)} style={{width:"25px",position:"relative", left:"164px"}}/>
              </div>
              
        </div>
    );
    
}

export default VerificationsBoard;
