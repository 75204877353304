import { useEffect } from "react";

function LoginStep3({ navigateNext }) {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigateNext();
        }, 5000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [navigateNext]);
    return (
        <div
            onClick={navigateNext}
            className="cursor-pointer flex flex-col justify-start items-start mt-20 mx-2 xl:m-0 max-w-[500px]    xl:w-[28vw]   gap-8 p-[30px] rounded-[20px] bg-white"
            // className="cursor-pointer flex flex-col justify-start items-center w-[456px] gap-5 p-[30px] rounded-[20px] bg-white"
            style={{ boxShadow: "0px 8px 16px 0 rgba(0,0,0,0.1)" }}
        >
            <div className="flex justify-center items-center xl:items-start self-stretch  gap-5 xl:gap-2.5">
                <div className="flex justify-center items-center  relative gap-2.5 p-2 rounded-[100px] bg-[#ccf2dc]">
                    <svg
                        width={40}
                        height={40}
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-10 h-10 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M19.9997 7.0835C12.8643 7.0835 7.08301 12.9168 7.08301 20.0002C7.08301 27.1356 12.8643 32.9168 19.9997 32.9168C27.083 32.9168 32.9163 27.1356 32.9163 20.0002C32.9163 12.9168 27.083 7.0835 19.9997 7.0835ZM19.9997 9.5835C25.7288 9.5835 30.4163 14.271 30.4163 20.0002C30.4163 25.7814 25.7288 30.4168 19.9997 30.4168C14.2184 30.4168 9.58301 25.7814 9.58301 20.0002C9.58301 14.271 14.2184 9.5835 19.9997 9.5835ZM27.2913 16.4064L26.0934 15.2085C25.8851 14.9481 25.4684 14.9481 25.208 15.2085L17.8643 22.5002L14.7393 19.3752C14.4788 19.1147 14.1143 19.1147 13.8538 19.3752L12.6559 20.521C12.4476 20.7814 12.4476 21.1981 12.6559 21.4064L17.3955 26.1981C17.6559 26.4585 18.0205 26.4585 18.2809 26.1981L27.2913 17.2918C27.4997 17.0314 27.4997 16.6147 27.2913 16.4064Z"
                            fill="#00BC4F"
                        />
                    </svg>
                </div>
                <div className="flex flex-col justify-start items-start flex-grow relative gap-2.5">
                    <p className="self-stretch  xl:w-[330px] text-[34px] text-left text-[#373737]">
                        Genial!
                    </p>
                    <p className="self-stretch  xl:w-[330px] text-base text-left text-[#7b7b7b]">
                        Si hay una cuenta registrada, le enviaremos un mensaje
                        con instrucciones.
                    </p>
                </div>
            </div>
            <div className="flex justify-start items-start self-stretch  relative gap-2.5 p-2.5 rounded-[10px] bg-[#f5f8ff]">
                <p className="flex-grow xl:w-[376px] text-base text-left text-[#373737]">
                    El mensaje llegará a su bandeja de entrada en los próximos 5
                    minutos. Si no puede encontrarlo, verifique también la
                    carpeta de correo no deseado.
                </p>
            </div>
        </div>
    );
}

export default LoginStep3;
