import React from "react";
import hiring from "../../assets/images/landing/hiring.png";
import { Button } from "../ui/buttons/Button";

function Hiring({ email = "lic.lopezalvarado@gmail.com" }) {
    return (
        <div className="flex flex-col-reverse sm:flex-row justify-between items-center   relative gap-[39px] sm:p-0 w-full mb-[100px] ">
            <div className="flex flex-col justify-start items-start flex-grow relative gap-5 w-[80vw] sm:w-[40%]  ">
                {/* w-[525.19px] */}
                <p className="font-[Inter] self-stretch   text-lg font-bold text-left text-[#7b7b7b]">
                    Estamos contratando
                </p>
                {/* w-[525.19px] */}
                <p className="font-[Inter] self-stretch   text-[31px] sm:text-[61px] font-bold text-left text-[#373737]">
                    <span className="relative">
                        Súmate
                        <svg
                            width={220}
                            height={19}
                            viewBox="0 0 166 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute right-0 top-[85%] w-[120px] sm:w-auto"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M164.5 11.441C148.5 1.27004 28.5 -5.55896 2 17.4411"
                                stroke="#D80027"
                                strokeWidth={3}
                                stroke-linecap="round"
                            />
                        </svg>
                    </span>{" "}
                    a nuestro equipo legal en expansión como mensajero y
                    procurador del derecho!
                </p>
                {/* w-[525.19px] */}
                <p className=" self-stretch   text-[21px] text-left text-[#7b7b7b]">
                    {/* w-[525.19px] */}
                    <span className="self-stretch   text-[21px] text-left text-[#7b7b7b]">
                        ¿Eres una persona dinámica, comprometida y apasionada
                        por el mundo jurídico? ¡Esta es tu oportunidad para
                        formar parte de nuestro prestigioso Equipo Profesional
                        de Abogados! Estamos en busca de Mensajeros y
                        Procuradores para unirse a nuestra selecto equipo en
                        constante crecimiento.
                    </span>
                    <br />
                </p>
                {/* <a href={`mailto:${email}`}> */}
                <Button onClick={() => window.open(`mailto:${email}`)}>
                    Solicitud de empleo
                </Button>
                {/* </a> */}
            </div>
            <img
                src={hiring}
                alt="hiring"
                className="w-[70vw] sm:w-[30vw] object-contain "
            />
        </div>
    );
}

export default Hiring;
