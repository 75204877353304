import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import Dialog from "../../../components/Dialog";
import ContactFormModal from "./ContactFormModal";
import TickDialog from "../../../components/ui/dialogs/TickDialog";
import Input from "../../../components/ui/Input";

const links = [
    { to: ".", text: "General" },
    { to: "proyecto", text: "Proyecto" },
    { to: "pagos", text: "Pagos" },
    { to: "afiliacion", text: "Afiliación" },
    { to: "perfil", text: "Perfil" },
];

const CustomerSupportLayout = () => {
    const [showModal, setShowModal] = useState(0);
    const [showTickDialog, setShowTickDialog] = useState(false);
    const [search, setSearch] = useState("");

    const navigate = useNavigate();

    if (showTickDialog)
        return (
            <TickDialog
                title="Solicitud enviada!"
                message="Nuestro grupo de expertos estará atendiendo a la brevedad posible."
                buttonText="Aceptar"
                onClick={() => navigate("/")}
            />
        );

    return (
        <div className="flex flex-col w-full gap-10 max-w-[1160px] mx-auto px-5 xl:px-0">
            <Dialog
                showDialog={showModal}
                setDialog={setShowModal}
                main={
                    <ContactFormModal
                        setShowDialog={setShowModal}
                        setShowTickDialog={setShowTickDialog}
                    />
                }
            />
            <div className="flex justify-start items-center   overflow-hidden gap-2">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-6 h-6 "
                    preserveAspectRatio="none"
                >
                    <path
                        d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                        stroke="#292929"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M20.5019 12H3.67188"
                        stroke="#292929"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <p className=" text-base text-left text-[#7b7b7b]">Soporte</p>
                <p className=" text-base text-left text-[#d80027]">/</p>
                <p className=" text-base text-left text-[#d80027]">
                    Centro de ayuda para clientes
                </p>
            </div>
            <div className="flex flex-col    gap-2.5">
                <p className="text-[40px] font-bold text-left text-[#373737]">
                    Centro de ayuda para clientes
                </p>
                <p className="text-[34px] text-left text-[#373737]">
                    ¿Cómo podemos ayudarte?
                </p>
            </div>
            <div className="flex flex-col   max-w-[607px] gap-[5px]">
                <Input
                    placeholder="Busca un referido por correo electrónico"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    name="search"
                    icon={
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6 "
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                fill="#333333"
                            />
                        </svg>
                    }
                />
            </div>

            <div className="flex flex-col sm:flex-row w-full  gap-6 ">
                <div className="flex flex-col   gap-[21px]">
                    <div className="flex flex-col   w-full sm:max-w-[336px] p-2 sm:p-[30px] bg-white border border-[#cdcdcd]">
                        <div className="flex sm:flex-col  overflow-x-auto ">
                            {links.map((link, index) => (
                                <NavLink
                                    key={index}
                                    to={link.to}
                                    end={index === 0}
                                    className={({ isActive }) =>
                                        twMerge(
                                            " rounded sm:rounded-none text-center px-2 sm:px-4 py-2 w-full max-w-[260px] text-sm sm:text-left text-[#373737]",
                                            isActive && "bg-[#ffecef]"
                                        )
                                    }
                                >
                                    {link.text}
                                </NavLink>
                            ))}
                        </div>
                    </div>
                    <div
                        onClick={() => setShowModal(true)}
                        className="flex flex-col   w-full sm:max-w-[336px]  gap-4 p-5 bg-white cursor-pointer"
                        style={{ boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)" }}
                    >
                        <div className="flex flex-col     gap-2.5">
                            <svg
                                width="60"
                                height="60"
                                viewBox="0 0 60 60"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className=" w-[60px] h-[60px] "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M30 10.625C19.2969 10.625 10.625 19.2969 10.625 30C10.625 40.7031 19.2969 49.375 30 49.375C40.7031 49.375 49.375 40.7031 49.375 30C49.375 19.2969 40.7031 10.625 30 10.625ZM30 45.625C21.3281 45.625 14.375 38.6719 14.375 30C14.375 21.4063 21.3281 14.375 30 14.375C38.5938 14.375 45.625 21.4063 45.625 30C45.625 38.6719 38.5938 45.625 30 45.625ZM34.7656 37.5C35.2344 37.8125 35.7813 37.7344 36.0938 37.2656L37.5781 35.3125C37.8906 34.8438 37.8125 34.2969 37.3438 33.9844L32.1875 30.1563V19.0625C32.1875 18.5938 31.7188 18.125 31.25 18.125H28.75C28.2031 18.125 27.8125 18.5938 27.8125 19.0625V31.9531C27.8125 32.1875 27.8906 32.5 28.125 32.6563L34.7656 37.5Z"
                                    fill="#D80027"
                                ></path>
                            </svg>
                            <p className=" text-[21px] font-bold text-left text-[#373737]">
                                Contáctanos
                            </p>
                        </div>
                        <p className=" text-sm text-left text-[#373737]">
                            ¿Aún tienes preguntas? Nuestro increíble equipo de
                            servicio al cliente está listo para ayudarte
                        </p>
                    </div>
                    <Link to={"../ticket-history"}>
                        <div
                            className="flex flex-col    w-full sm:max-w-[336px]  gap-4 p-5 bg-white"
                            style={{
                                boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)",
                            }}
                        >
                            <div className="flex flex-col     gap-2.5">
                                <svg
                                    width="60"
                                    height="60"
                                    viewBox="0 0 60 60"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className=" w-[60px] h-[60px] "
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M51.875 26.875H52.5V18.75C52.5 16.7187 50.7812 15 48.75 15H11.25C9.14062 15 7.5 16.7187 7.5 18.75V26.875H8.125C9.84375 26.875 11.25 28.2812 11.25 30C11.25 31.7969 9.84375 33.125 8.125 33.125H7.5V41.25C7.5 43.3594 9.14062 45 11.25 45H48.75C50.7812 45 52.5 43.3594 52.5 41.25V33.125H51.875C50.0781 33.125 48.75 31.7969 48.75 30C48.75 28.2812 50.0781 26.875 51.875 26.875ZM48.75 23.9062C46.4844 25.0781 45 27.3437 45 30C45 32.7344 46.4844 35 48.75 36.1719V41.25H11.25V36.1719C13.4375 35 15 32.7344 15 30C15 27.3437 13.4375 25.0781 11.25 23.9062V18.75H48.75V23.9062Z"
                                        fill="#D80027"
                                    ></path>
                                </svg>
                                <p className=" text-[21px] font-bold text-left text-[#373737]">
                                    Mi historial de tickets
                                </p>
                            </div>
                            <p className="w-full  max-w-[296px] text-sm text-left text-[#373737]">
                                ¿Aún tienes preguntas? Nuestro increíble equipo
                                de servicio al cliente está listo para ayudarte
                            </p>
                        </div>
                    </Link>
                </div>

                <div className="flex w-full max-w-[816px] flex-grow  gap-[5px] border border-[#cdcdcd]">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default CustomerSupportLayout;
