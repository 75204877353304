import React, { useState } from "react";
import Input from "../ui/Input";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";

const CreateNewListModal = ({ cancel }) => {
    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        console.log(e.target.value);
        console.log(e.target.name);
        const { name, value } = e?.target;
        setFormData((pre) => ({
            ...pre,
            [name]: value,
        }));
    };

    const saveNewList = () => {
        localStorage.setItem("list", JSON.stringify(formData))
        cancel()
        // navigate("")
    }

    return (
        <div
            className="flex flex-col w-full md:min-w-[674px] gap-[30px] p-[30px] rounded-[20px] bg-white"
            style={{ boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)" }}
        >
            <div className="flex flex-col  gap-[30px] w-full">
                <p className="text-[21px] text-left text-[#373737]">
                    Crear nueva lista de tareas
                </p>
                <div className="flex flex-col  gap-3 w-full">
                    <Input
                        label="Nombre de la lista"
                        placeholder="Escribir aquí el nombre de la lista de tareas"
                        required
                        onChange={handleChange}
                        // value={formik.values.firstName}
                        // onChange={(e) => handleChange(e.target)}
                        value={formData.listName}
                        name="listName"
                    />

                    <Input
                        label="Descripción"
                        // required
                        onChange={handleChange}
                        value={formData.description}
                        name="description"
                        textAreaLimit={1200}
                        type="long"
                        placeholder="Describe aquí tu lista de tareas"
                    />
                </div>

                <Input
                    label="Asignar a"
                    placeholder="Buscar asignado"
                    required
                    onChange={handleChange}
                    // value={formik.values.firstName}
                    // onChange={(e) => handleChange(e.target)}
                    value={formData.assignTo}
                    name="assignTo"
                />

                <div className="flex justify-between items-center py-5 w-full">
                    <Button
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        addClass={"h-11"}
                        onClick={() => cancel()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color={COLOR.RED}
                        size={SIZE.MEDIUM}
                        type="submit"
                        addClass={"h-11"}
                        onClick={saveNewList}
                    >
                        Crear
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreateNewListModal;
