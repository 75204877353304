import React from "react";
import { twMerge } from "tailwind-merge";

function AngleDown({ active, size, onClick }) {
    const handleClick = (e) => {
        if (!active) return;
        onClick(e);
    };
    return (
        <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
            className={twMerge(active && "cursor-pointer")}
        >
            <g id="Angle Down">
                <path
                    id="angle-down"
                    d="M11.7188 15.375C11.875 15.5312 12.0938 15.5312 12.25 15.375L16.875 10.7812C17.0312 10.6562 17.0312 10.4062 16.875 10.25L16.25 9.65625C16.125 9.5 15.875 9.5 15.7188 9.65625L12 13.3438L8.25 9.65625C8.09375 9.5 7.875 9.5 7.71875 9.65625L7.09375 10.25C6.9375 10.4062 6.9375 10.6562 7.09375 10.7812L11.7188 15.375Z"
                    fill={active ? "black" : "#A4A4A4"}
                />
            </g>
        </svg>
    );
}

export default AngleDown;
