import { useCallback, useRef, useState } from "react";

import formBg from "../../../assets/images/form/background.png";
import { EXCLUSIVE_LATIN_COUNTRIES } from "../../../constants/constants";
import { trimString } from "../../../utils/trimString";
import Dialog from "../../Dialog";
import HeaderBack from "../../form/HeaderBack";
import Checkbox from "../../ui/Checkbox";
import InfoHover from "../../ui/InfoHover";
import Input from "../../ui/Input";
import { Tag } from "../../ui/Tag";
import { Button, COLOR, SIZE } from "../../ui/buttons/Button";
import TickDialog from "../../ui/dialogs/TickDialog";

const areasOfLaw = [
    "Civil",
    "Commercial",
    "Labor",
    "Private International",
    "Notarial And Registry",
    "Constitutional",
    "Administrative",
    "Criminal",
    "Criminal Procedure",
    "Labor And Social Security",
    "Tax",
    "Agricultural",
    "Environmental",
    "Municipal",
    "Computer",
    "International Public"
];

export function UploadDocuments(props) {
    const {
        files = [],
        handleChange,
        removeFile,
        text = "Arrastra y suelta aquí cualquier imagen o documento que puede ser útil para explicar tu proyecto (Tamaño máximo de archivo: 25 MB)"
    } = props;
    const inputRef = useRef();

    return (
        <div className="flex flex-col gap-5 w-full">
            <div className="flex justify-start items-start self-stretch relative gap-2.5 ">
                <div
                    className="w-full  overflow-hidden border border-[#cdcdcd] border-dashed cursor-pointer flex flex-col justify-center items-center p-10"
                    onClick={() => inputRef.current.click()}
                >
                    <input
                        // accept="image/png, image/jpg, image/jpeg"
                        type="file"
                        name="documents"
                        id="documents"
                        onChange={handleChange}
                        ref={inputRef}
                        className="absolute top-0 left-0 w-full h-full opacity-0 -z-10"
                        multiple
                    />

                    <div className="flex flex-col justify-center items-center gap-[5px] ">
                        <svg
                            width={41}
                            height={41}
                            viewBox="0 0 41 41"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-10 h-10"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <g clip-path="url(#clip0_11850_27942)">
                                <path
                                    d="M34.2109 5.04395C36.0519 5.04395 37.5443 6.53633 37.5443 8.37728V31.7106C37.5443 33.5516 36.0519 35.0439 34.2109 35.0439H7.54427C5.70332 35.0439 4.21094 33.5516 4.21094 31.7106V8.37728C4.21094 6.53633 5.70332 5.04395 7.54427 5.04395H34.2109ZM34.2109 8.37728H7.54427V31.7106H9.09252L24.6066 16.1965C25.4203 15.3829 26.7394 15.3829 27.5529 16.1965L34.2109 22.8544V8.37728ZM26.0798 19.4374L13.8066 31.7106H34.2109V27.5684L26.0798 19.4374ZM13.3776 11.7106C14.7583 11.7106 15.8776 12.8299 15.8776 14.2106C15.8776 15.5913 14.7583 16.7106 13.3776 16.7106C11.9969 16.7106 10.8776 15.5913 10.8776 14.2106C10.8776 12.8299 11.9969 11.7106 13.3776 11.7106Z"
                                    fill="#A4A4A4"
                                />
                            </g>
                            <defs>
                                <clippath id="clip0_11850_27942">
                                    <rect
                                        width={40}
                                        height={40}
                                        fill="white"
                                        transform="translate(0.875 0.0439453)"
                                    />
                                </clippath>
                            </defs>
                        </svg>
                        <p className=" text-[11.66px] font-medium text-left text-[#373737]">
                            {text}
                        </p>
                    </div>
                </div>
            </div>
            {Object.keys(files).map((key) => {
                const file = files[key];
                return (
                    <div
                        key={key}
                        className="flex justify-start items-center self-stretch  gap-2.5"
                    >
                        <p className="flex-grow max-w-[80vw] text-ellipsis lg:w-[842px] text-sm text-left text-[#c10434]">
                            {trimString(file.name, 30) ?? "file name.jpg"}
                        </p>
                        <svg
                            width={24}
                            height={25}
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 cursor-pointer grow-0 shrink-0"
                            preserveAspectRatio="none"
                            onClick={() => removeFile(key)}
                        >
                            <g clipPath="url(#clip0_11850_27955)">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.9968 14.2303L17.6536 19.8872C18.0441 20.2777 18.6773 20.2777 19.0678 19.8872C19.4583 19.4966 19.4583 18.8635 19.0678 18.4729L13.411 12.8161L19.0678 7.15927C19.4583 6.76875 19.4583 6.13558 19.0678 5.74506C18.6773 5.35453 18.0441 5.35453 17.6536 5.74506L11.9968 11.4019L6.33991 5.74504C5.94939 5.35452 5.31622 5.35452 4.9257 5.74504C4.53518 6.13557 4.53518 6.76873 4.9257 7.15926L10.5825 12.8161L4.9257 18.473C4.53518 18.8635 4.53518 19.4966 4.9257 19.8872C5.31622 20.2777 5.94939 20.2777 6.33991 19.8872L11.9968 14.2303Z"
                                    fill="#292929"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_11850_27955">
                                    <rect
                                        width={24}
                                        height={24}
                                        fill="white"
                                        transform="translate(0 0.816406)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                );
            })}
        </div>
    );
}

function RecommendedAdvisoryForm() {
    const [formData, setFormData] = useState({
        documents: [],
        areas: [],
        accuracyRange: 15,
        country: EXCLUSIVE_LATIN_COUNTRIES.COSTA_RICA
    });
    const [sendAnonymously, setSendAnonymously] = useState(false);

    const [showDialog, setShowDialog] = useState(false);

    const fileHandler = useCallback((event) => {
        const documents = event.target.files;
        setFormData({
            documents
        });
    }, []);
    const handleChange = useCallback(
        (e) => {
            console.log("e : ");
            console.log(e);
            const { name, value } = e.target;
            if (name === "documents") {
                fileHandler(e);
                return;
            }
            if (name === "areas") {
                if (
                    Array.isArray(formData.areas) &&
                    formData.areas.includes(value)
                ) {
                    return;
                }
                setFormData((prev) => ({
                    ...prev,
                    areas: [...prev.areas, value]
                }));
                return;
            }
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        },
        [fileHandler, formData.areas]
    );

    const showSuccessDialog = useCallback(() => {
        console.log("formData : ");
        console.log(formData);
        setShowDialog(true);
    }, [formData]);

    const removeFileByIndex = (index) => {
        setFormData((prevFormData) => {
            // Create a copy of the documents array
            const newDocuments = [...prevFormData.documents];

            // Check if the index is valid
            if (index >= 0 && index < newDocuments.length) {
                // Remove the file at the specified index
                newDocuments.splice(index, 1);
                console.log(`File at index ${index} removed successfully.`);
            } else {
                console.log(`Invalid index: ${index}`);
            }

            // Return the updated formData object
            return { ...prevFormData, documents: newDocuments };
        });
    };

    const removeTag = (tag) => {
        setFormData((prevData) => ({
            ...prevData,
            areas: prevData.areas.filter((area) => area !== tag)
        }));
    };

    const handleCheckboxChange = () => {};

    console.log("formData.country : ");
    console.log(formData.country);

    return (
        <div className="flex relative flex-col items-center py-32 w-full bg-white">
            <Dialog
                main={<TickDialog closeDialog={() => setShowDialog(false)} />}
                showDialog={showDialog}
                setDialog={setShowDialog}
            />
            <img
                src={formBg}
                alt="pexels-spencer-davis-4388164-1.jpeg"
                className="object-cover absolute top-0 left-0 z-0 w-full h-full min-h-screen"
            />
            <div
                className="box-border flex flex-col justify-start items-start w-full max-w-[936px] h-fit gap-[30px] p-[30px] z-[2] bg-white"
                style={{
                    boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)"
                }}
            >
                <div className="box-border flex flex-col justify-center items-start h-[70px] w-full max-w-[216px] gap-2.5 p-2.5">
                    <div className="flex justify-start items-center  gap-[13.823529243469238px]">
                        <svg
                            width={46}
                            height={47}
                            viewBox="0 0 46 47"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-8 h-9"
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M45.259 46.6413L0.91877 46.8171C0.733112 46.8171 0.555059 46.7433 0.423778 46.612C0.292498 46.4807 0.21875 46.3027 0.21875 46.117C0.21875 45.9314 0.292498 45.7533 0.423778 45.622C0.555059 45.4908 0.733112 45.417 0.91877 45.417L45.259 45.2412C45.3512 45.2408 45.4426 45.2586 45.5279 45.2936C45.6132 45.3286 45.6907 45.3801 45.756 45.4451C45.8214 45.5102 45.8732 45.5875 45.9086 45.6726C45.944 45.7578 45.9622 45.8491 45.9622 45.9412C45.9622 46.0334 45.944 46.1247 45.9086 46.2099C45.8732 46.295 45.8214 46.3723 45.756 46.4374C45.6907 46.5024 45.6132 46.5539 45.5279 46.5889C45.4426 46.6239 45.3512 46.6417 45.259 46.6413Z"
                                fill="#D80027"
                            />
                            <path
                                d="M21.2145 16.4063C21.0288 16.4063 20.8508 16.3326 20.7195 16.2013C20.5882 16.07 20.5145 15.892 20.5145 15.7063C20.5145 12.0963 19.5351 9.66034 17.4381 8.03741C17.3651 7.9814 17.3038 7.91154 17.2578 7.83182C17.2118 7.7521 17.1819 7.66409 17.17 7.57283C17.158 7.48156 17.1642 7.38884 17.1881 7.29996C17.212 7.21108 17.2532 7.12779 17.3094 7.05486C17.3657 6.98161 17.4359 6.92022 17.516 6.87422C17.5961 6.82822 17.6845 6.79853 17.7761 6.78686C17.8677 6.77519 17.9608 6.78177 18.0498 6.80621C18.1389 6.83066 18.2223 6.87249 18.2951 6.92929C20.7311 8.81277 21.9145 11.6819 21.9145 15.7189C21.9112 15.9023 21.836 16.0772 21.7051 16.2058C21.5742 16.3343 21.398 16.4064 21.2145 16.4063Z"
                                fill="#D80027"
                            />
                            <path
                                d="M25.2891 16.405C25.1969 16.4054 25.1055 16.3876 25.0203 16.3526C24.935 16.3176 24.8574 16.2661 24.7921 16.201C24.7267 16.136 24.6749 16.0587 24.6395 15.9736C24.6041 15.8884 24.5859 15.7971 24.5859 15.7049C24.5859 11.6806 25.7725 8.79885 28.2085 6.91537C28.2811 6.85865 28.3642 6.81688 28.453 6.79248C28.5418 6.76808 28.6346 6.76152 28.726 6.7732C28.8173 6.78488 28.9055 6.81455 28.9853 6.86051C29.0651 6.90646 29.1351 6.96779 29.191 7.04094C29.2478 7.11352 29.2895 7.19662 29.3139 7.28544C29.3383 7.37426 29.3449 7.46704 29.3332 7.5584C29.3215 7.64977 29.2918 7.73792 29.2459 7.81775C29.1999 7.89758 29.1386 7.96751 29.0655 8.02349C26.9654 9.64642 25.9891 12.0824 25.9891 15.6924C25.9908 15.7854 25.9739 15.8777 25.9395 15.9641C25.905 16.0505 25.8537 16.1292 25.7886 16.1955C25.7234 16.2619 25.6457 16.3145 25.5599 16.3505C25.4742 16.3865 25.3821 16.405 25.2891 16.405Z"
                                fill="#D80027"
                            />
                            <path
                                d="M37.9683 6.78125C35.6027 6.78457 33.3349 7.726 31.6625 9.39905C29.99 11.0721 29.0494 13.3402 29.0469 15.7058C29.0469 15.8915 29.1206 16.0695 29.2519 16.2008C29.3832 16.3321 29.5612 16.4058 29.7469 16.4058C29.8388 16.4058 29.9299 16.3877 30.0148 16.3525C30.0997 16.3174 30.1769 16.2658 30.2419 16.2008C30.3069 16.1358 30.3585 16.0586 30.3937 15.9737C30.4288 15.8888 30.4469 15.7977 30.4469 15.7058C30.4469 13.7077 31.2407 11.7914 32.6536 10.3785C34.0664 8.96564 35.9827 8.17189 37.9809 8.17189C38.7534 8.17395 39.5179 8.32814 40.2309 8.62568C40.9438 8.92322 41.5912 9.35827 42.136 9.90599C42.6808 10.4537 43.1124 11.1034 43.4061 11.8179C43.6998 12.5324 43.85 13.2978 43.8479 14.0703C43.8437 15.2773 43.362 16.4336 42.5079 17.2865C41.6538 18.1394 40.4969 18.6196 39.2899 18.6221C38.3593 18.6212 37.4671 18.2512 36.8092 17.5932C36.1512 16.9352 35.7811 16.043 35.7803 15.1125C35.7811 14.4059 36.0622 13.7285 36.5618 13.2289C37.0615 12.7293 37.7389 12.4482 38.4454 12.4474C38.9371 12.4477 39.4112 12.63 39.7764 12.9591C39.4531 13.0596 39.1705 13.2611 38.9699 13.5339C38.7694 13.8068 38.6615 14.1367 38.662 14.4753C38.662 14.8965 38.8294 15.3006 39.1273 15.5984C39.4252 15.8963 39.8292 16.0637 40.2504 16.0637C41.1231 16.0637 41.8357 15.4358 41.8357 14.2681C41.8357 12.3281 40.3195 11.0442 38.4454 11.0442C37.3631 11.0442 36.3251 11.4741 35.5598 12.2395C34.7945 13.0048 34.3646 14.0427 34.3646 15.1251C34.3671 16.4291 34.8873 17.6788 35.8109 18.5995C36.7344 19.5201 37.9858 20.0363 39.2899 20.0347C40.8717 20.0347 42.3888 19.4063 43.5073 18.2877C44.6258 17.1692 45.2542 15.6522 45.2542 14.0703C45.2546 13.1132 45.0665 12.1655 44.7005 11.2811C44.3346 10.3968 43.7979 9.59325 43.1213 8.91636C42.4447 8.23947 41.6414 7.70251 40.7572 7.33616C39.873 6.96981 38.9253 6.78125 37.9683 6.78125Z"
                                fill="#D80027"
                            />
                            <path
                                d="M7.83434 6.78125C10.2005 6.78374 12.4691 7.72481 14.1422 9.39794C15.8153 11.0711 16.7564 13.3396 16.7589 15.7058C16.7589 15.8915 16.6851 16.0695 16.5539 16.2008C16.4226 16.3321 16.2445 16.4058 16.0589 16.4058C15.8732 16.4058 15.6952 16.3321 15.5639 16.2008C15.4326 16.0695 15.3588 15.8915 15.3588 15.7058C15.3588 13.7077 14.5651 11.7914 13.1522 10.3785C11.7393 8.96564 9.82305 8.17189 7.82493 8.17189C7.0524 8.17395 6.28782 8.32814 5.57488 8.62568C4.86195 8.92322 4.2146 9.35827 3.66979 9.90599C3.12499 10.4537 2.6934 11.1034 2.39967 11.8179C2.10595 12.5324 1.95583 13.2978 1.95789 14.0703C1.95955 15.2795 2.44062 16.4387 3.29564 17.2937C4.15067 18.1487 5.30985 18.6298 6.51904 18.6315C7.44957 18.6306 8.34176 18.2606 8.99975 17.6026C9.65773 16.9446 10.0278 16.0525 10.0286 15.1219C10.0278 14.4148 9.74627 13.7369 9.24597 13.2372C8.74566 12.7375 8.06745 12.4568 7.36032 12.4568C6.86961 12.457 6.39643 12.6393 6.03248 12.9685C6.3558 13.0691 6.63843 13.2705 6.83897 13.5434C7.0395 13.8162 7.1474 14.1461 7.14687 14.4847C7.14604 14.9057 6.97843 15.3092 6.68073 15.6069C6.38302 15.9046 5.97948 16.0723 5.55847 16.0731C4.68579 16.0731 3.97006 15.4453 3.97006 14.2775C3.97006 12.3375 5.4894 11.0536 7.36032 11.0536C8.44264 11.0536 9.48062 11.4836 10.2459 12.2489C11.0112 13.0142 11.4412 14.0522 11.4412 15.1345C11.4362 16.4364 10.9153 17.6832 9.9927 18.6017C9.07007 19.5202 7.82093 20.0355 6.51904 20.0347C4.93719 20.0347 3.42015 19.4063 2.30162 18.2877C1.18308 17.1692 0.554688 15.6522 0.554688 14.0703C0.554686 12.1388 1.32136 10.2862 2.68629 8.9195C4.05121 7.5528 5.90279 6.78375 7.83434 6.78125Z"
                                fill="#D80027"
                            />
                            <path
                                d="M32.2749 4.79654H13.5594C13.3737 4.79654 13.1957 4.72279 13.0644 4.59151C12.9331 4.46023 12.8594 4.28217 12.8594 4.09651C12.8594 4.00458 12.8775 3.91355 12.9127 3.82862C12.9478 3.74369 12.9994 3.66652 13.0644 3.60152C13.1294 3.53651 13.2066 3.48495 13.2915 3.44977C13.3764 3.41459 13.4675 3.39648 13.5594 3.39648H32.2812C32.4668 3.39648 32.6449 3.47024 32.7762 3.60152C32.9075 3.7328 32.9812 3.91085 32.9812 4.09651C32.9812 4.18897 32.9629 4.28051 32.9273 4.36586C32.8918 4.4512 32.8396 4.52865 32.774 4.59374C32.7083 4.65882 32.6304 4.71025 32.5447 4.74506C32.4591 4.77987 32.3674 4.79737 32.2749 4.79654Z"
                                fill="#D80027"
                            />
                            <path
                                d="M10.3671 4.7966C9.73124 4.79577 9.12169 4.54282 8.6721 4.09323C8.22252 3.64365 7.96958 3.03411 7.96875 2.3983C7.96958 1.76249 8.22252 1.15295 8.6721 0.703364C9.12169 0.253776 9.73124 0.000830586 10.3671 0H35.4613C36.0971 0.000830586 36.7066 0.253776 37.1562 0.703364C37.6058 1.15295 37.8587 1.76249 37.8596 2.3983C37.8587 3.03411 37.6058 3.64365 37.1562 4.09323C36.7066 4.54282 36.0971 4.79577 35.4613 4.7966C35.3691 4.79701 35.2777 4.77921 35.1924 4.74422C35.1071 4.70922 35.0296 4.65772 34.9643 4.59268C34.8989 4.52763 34.8471 4.45032 34.8117 4.36518C34.7763 4.28005 34.7581 4.18876 34.7581 4.09657C34.7581 4.00437 34.7763 3.91309 34.8117 3.82795C34.8471 3.74282 34.8989 3.66551 34.9643 3.60047C35.0296 3.53542 35.1071 3.48392 35.1924 3.44893C35.2777 3.41393 35.3691 3.39613 35.4613 3.39654C35.7255 3.39571 35.9786 3.29018 36.1651 3.10306C36.3516 2.91594 36.4564 2.66251 36.4564 2.3983C36.4564 2.13409 36.3516 1.88066 36.1651 1.69354C35.9786 1.50643 35.7255 1.40089 35.4613 1.40005H10.3671C10.1028 1.40089 9.84975 1.50643 9.66322 1.69354C9.47669 1.88066 9.37193 2.13409 9.37193 2.3983C9.37193 2.66251 9.47669 2.91594 9.66322 3.10306C9.84975 3.29018 10.1028 3.39571 10.3671 3.39654C10.4592 3.39613 10.5506 3.41393 10.6359 3.44893C10.7212 3.48392 10.7987 3.53542 10.8641 3.60047C10.9294 3.66551 10.9812 3.74282 11.0166 3.82795C11.052 3.91309 11.0702 4.00437 11.0702 4.09657C11.0702 4.18876 11.052 4.28005 11.0166 4.36518C10.9812 4.45032 10.9294 4.52763 10.8641 4.59268C10.7987 4.65772 10.7212 4.70922 10.6359 4.74422C10.5506 4.77921 10.4592 4.79701 10.3671 4.7966Z"
                                fill="#D80027"
                            />
                            <path
                                d="M33.9035 20.537C33.8452 20.5374 33.7871 20.53 33.7308 20.515C26.6255 18.6953 19.1762 18.6953 12.0708 20.515C11.9818 20.5381 11.889 20.5435 11.7979 20.5308C11.7068 20.5181 11.6191 20.4875 11.5398 20.4409C11.4605 20.3942 11.3911 20.3324 11.3357 20.2589C11.2804 20.1855 11.24 20.1018 11.217 20.0127C11.1712 19.8325 11.1987 19.6415 11.2934 19.4815C11.3881 19.3215 11.5423 19.2055 11.7223 19.1589C19.0522 17.2817 26.7369 17.2817 34.0667 19.1589C34.1558 19.1819 34.2394 19.2223 34.3129 19.2777C34.3864 19.3331 34.4482 19.4024 34.4948 19.4817C34.5415 19.561 34.572 19.6487 34.5847 19.7398C34.5975 19.831 34.5921 19.9237 34.569 20.0127C34.531 20.1611 34.4453 20.2928 34.325 20.3876C34.2047 20.4823 34.0566 20.5348 33.9035 20.537Z"
                                fill="#D80027"
                            />
                            <path
                                d="M17.4344 40.8744C17.3422 40.8744 17.2509 40.8561 17.1658 40.8208C17.0807 40.7854 17.0033 40.7335 16.9383 40.6682C16.8733 40.6029 16.8218 40.5253 16.7868 40.44C16.7518 40.3548 16.734 40.2634 16.7344 40.1712V25.1034C16.7344 25.0114 16.7525 24.9204 16.7877 24.8355C16.8228 24.7505 16.8744 24.6734 16.9394 24.6084C17.0044 24.5434 17.0816 24.4918 17.1665 24.4566C17.2515 24.4214 17.3425 24.4033 17.4344 24.4033C17.5266 24.4029 17.618 24.4207 17.7033 24.4557C17.7886 24.4907 17.8661 24.5422 17.9314 24.6072C17.9968 24.6723 18.0486 24.7496 18.084 24.8347C18.1194 24.9199 18.1376 25.0112 18.1376 25.1034V40.1712C18.1376 40.3577 18.0635 40.5365 17.9316 40.6684C17.7997 40.8003 17.6209 40.8744 17.4344 40.8744Z"
                                fill="#D80027"
                            />
                            <path
                                d="M22.911 40.8745C22.8188 40.8745 22.7275 40.8563 22.6423 40.8209C22.5572 40.7856 22.4799 40.7337 22.4149 40.6684C22.3498 40.603 22.2983 40.5255 22.2633 40.4402C22.2283 40.3549 22.2105 40.2636 22.2109 40.1714V24.369C22.2109 24.1833 22.2847 24.0053 22.416 23.874C22.5473 23.7427 22.7253 23.6689 22.911 23.6689C23.0966 23.6689 23.2747 23.7427 23.406 23.874C23.5372 24.0053 23.611 24.1833 23.611 24.369V40.1714C23.6114 40.2636 23.5936 40.3549 23.5586 40.4402C23.5236 40.5255 23.4721 40.603 23.4071 40.6684C23.342 40.7337 23.2647 40.7856 23.1796 40.8209C23.0944 40.8563 23.0032 40.8745 22.911 40.8745Z"
                                fill="#D80027"
                            />
                            <path
                                d="M28.3907 40.8744C28.2042 40.8744 28.0253 40.8003 27.8934 40.6684C27.7616 40.5365 27.6875 40.3577 27.6875 40.1712V25.1034C27.6875 25.0112 27.7057 24.9199 27.7411 24.8347C27.7765 24.7496 27.8283 24.6723 27.8936 24.6072C27.959 24.5422 28.0365 24.4907 28.1218 24.4557C28.2071 24.4207 28.2985 24.4029 28.3907 24.4033C28.5763 24.4033 28.7544 24.4771 28.8857 24.6084C29.0169 24.7396 29.0907 24.9177 29.0907 25.1034V40.1712C29.0911 40.2634 29.0733 40.3548 29.0383 40.44C29.0033 40.5253 28.9518 40.6029 28.8868 40.6682C28.8217 40.7335 28.7444 40.7854 28.6593 40.8208C28.5741 40.8561 28.4829 40.8744 28.3907 40.8744Z"
                                fill="#D80027"
                            />
                            <path
                                d="M44.9028 40.8754H33.2032V23.2962C26.4371 21.6545 19.3766 21.6545 12.6105 23.2962V40.8754H0.910965C0.818771 40.8754 0.727501 40.8572 0.642364 40.8218C0.557228 40.7864 0.479906 40.7346 0.414862 40.6692C0.349817 40.6039 0.298322 40.5264 0.263327 40.4411C0.228333 40.3558 0.210531 40.2644 0.210945 40.1722C0.210945 39.9866 0.284693 39.8085 0.415973 39.6772C0.547253 39.5459 0.725306 39.4722 0.910965 39.4722H11.2073V22.207L11.7347 22.072C19.0645 20.1948 26.7492 20.1948 34.079 22.072L34.6064 22.207V39.4722H44.9028C45.0884 39.4722 45.2665 39.5459 45.3978 39.6772C45.529 39.8085 45.6028 39.9866 45.6028 40.1722C45.6032 40.2644 45.5854 40.3558 45.5504 40.4411C45.5154 40.5264 45.4639 40.6039 45.3989 40.6692C45.3338 40.7346 45.2565 40.7864 45.1714 40.8218C45.0863 40.8572 44.995 40.8754 44.9028 40.8754Z"
                                fill="#D80027"
                            />
                        </svg>
                        <p className="font-[Enriqueta] whitespace-nowrap text-2xl text-left text-[#373737]">
                            Legal EPROF
                        </p>
                    </div>
                </div>
                <HeaderBack
                    text={
                        <span className="font-['Nunito'] text-base leading-[normal] text-left text-[#d80027]">
                            Tablero / Nueva solicitud de asesoría
                        </span>
                    }
                />

                <div className="box-border flex flex-col gap-4 justify-start items-start self-stretch">
                    <p className="whitespace-pre-wrap self-stretch font-['Satoshi'] text-[38px] leading-[normal] font-bold text-left text-[#373737]">
                        Asesoría recomendada
                    </p>
                    <p className="whitespace-pre-wrap self-stretch font-['Noto_Sans'] text-[21px] leading-[normal] text-left text-[#373737]">
                        Nuestro grupo de expertos estará atendiendo a la
                        brevedad posible.
                    </p>
                    <div className="box-border flex justify-start items-center self-stretch  gap-2.5 p-2.5 rounded bg-[#f5f8ff]">
                        <p className="whitespace-pre-wrap flex-grow font-['Noto_Sans'] text-xs leading-[normal] text-left text-[#373737]">
                            No compartas, ni divulgues información que
                            consideres confidencial. Hasta que el profesional se
                            contacte contigo...
                        </p>
                    </div>
                </div>

                <div className="flex relative flex-col gap-3 justify-start items-start self-stretch">
                    <p className="self-stretch  lg:w-full max-w-[876px] text-base font-bold text-left text-[#373737]">
                        Datos del proyecto
                    </p>

                    <Input
                        label="Descripción"
                        required
                        onChange={handleChange}
                        value={formData.description}
                        name="description"
                        textAreaLimit={1200}
                        type="long"
                        placeholder="Describe el trabajo a realizar"
                    />
                    <div className="flex flex-col justify-start items-start self-stretch  gap-2.5 ">
                        <div className="flex flex-col justify-start items-start self-stretch  gap-[5px] ">
                            <div className="flex justify-start items-center  gap-[5px]">
                                <InfoHover
                                    red={false}
                                    text={"Adjuntar archivos"}
                                />

                                <p className=" text-sm text-left text-[#373737]">
                                    Adjuntar archivos
                                </p>
                            </div>

                            <UploadDocuments
                                files={formData.documents}
                                handleChange={handleChange}
                                removeFile={removeFileByIndex}
                            />
                        </div>
                    </div>
                </div>

                <div className="box-border flex flex-col justify-start items-start w-full max-w-[876px]  gap-3">
                    <div className="box-border flex flex-col gap-2.5 w-full max-w-[578px] sm:flex-row">
                        <Input
                            label="Programa tu asesoría"
                            placeholder="07/19/2023 - 1:15 pm"
                            required
                            onChange={handleChange}
                            // onBlur={formik.handleBlur}
                            value={formData.date}
                            name="date"
                            type="datetime-local"
                        />
                        <Input
                            label="DPI (CUI)"
                            placeholder="DPI (CUI)"
                            required
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            value={formData.dpi}
                            name="dpi"
                        />
                    </div>

                    <p className="self-stretch  lg:w-[876px] text-base font-bold text-left text-[#373737]">
                        Datos de contacto
                    </p>
                    <div className="flex flex-col gap-5 justify-start items-start self-stretch lg:flex-row">
                        <Input
                            label="Teléfono celular"
                            placeholder="Teléfono"
                            type="number"
                            required
                            onChange={handleChange}
                            value={formData.phone1}
                            name="phone1"
                        />
                        <Input
                            label="Teléfono celular 2"
                            placeholder="Teléfono"
                            type="number"
                            required
                            onChange={handleChange}
                            value={formData.phone2}
                            name="phone2"
                        />
                    </div>

                    <div className="flex justify-start items-start   relative gap-5 p-2 rounded bg-[#fff5f7]">
                        <div className="flex flex-col sm:flex-row justify-start items-center  relative gap-[10px] p-2">
                            <input
                                type="checkbox"
                                id="anonimo"
                                // onChange={(e) => handleChange(e.target)}
                                onChange={handleCheckboxChange}
                                checked={formData.anonimo}
                                name="anonimo"
                                className="border"
                            />
                            <label
                                htmlFor="anonimo"
                                className="text-sm font-bold text-left text-[#373737]"
                            >
                                Anónimo
                            </label>
                        </div>

                        <p className="text-xs text-left text-[#373737]">
                            Oculta los datos de tu perfil, te proporcionaremos
                            un “alias” y tus datos permanecerán ocultos, una vez
                            que contrates un profesional y agregues tus fondos
                            tus datos volverán a ser visibles al profesional
                            contratado.
                        </p>
                    </div>
                </div>

                <div className="flex relative flex-col gap-3 justify-start items-start self-stretch">
                    <p className="self-stretch  lg:w-full max-w-[876px] text-base font-bold text-left text-[#373737]">
                        Áreas del derecho
                    </p>
                    <div className="flex flex-row-reverse gap-5 items-end">
                        <div className="flex  items-center gap-[5px] max-w-[494px] p-2 rounded bg-[#f5f8ff] shrink-0 h-min">
                            <svg
                                width={24}
                                height={25}
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M12 5.06641C7.71875 5.06641 4.25 8.56641 4.25 12.8164C4.25 17.0977 7.71875 20.5664 12 20.5664C16.25 20.5664 19.75 17.0977 19.75 12.8164C19.75 8.56641 16.25 5.06641 12 5.06641ZM12 19.0664C8.53125 19.0664 5.75 16.2852 5.75 12.8164C5.75 9.37891 8.53125 6.56641 12 6.56641C15.4375 6.56641 18.25 9.37891 18.25 12.8164C18.25 16.2852 15.4375 19.0664 12 19.0664ZM12 8.50391C11.25 8.50391 10.6875 9.09766 10.6875 9.81641C10.6875 10.5664 11.25 11.1289 12 11.1289C12.7188 11.1289 13.3125 10.5664 13.3125 9.81641C13.3125 9.09766 12.7188 8.50391 12 8.50391ZM13.75 16.4414V15.6914C13.75 15.5039 13.5625 15.3164 13.375 15.3164H13V12.1914C13 12.0039 12.8125 11.8164 12.625 11.8164H10.625C10.4062 11.8164 10.25 12.0039 10.25 12.1914V12.9414C10.25 13.1602 10.4062 13.3164 10.625 13.3164H11V15.3164H10.625C10.4062 15.3164 10.25 15.5039 10.25 15.6914V16.4414C10.25 16.6602 10.4062 16.8164 10.625 16.8164H13.375C13.5625 16.8164 13.75 16.6602 13.75 16.4414Z"
                                    fill="#333333"
                                />
                            </svg>
                            <p className=" w-full  text-xs text-left text-[#373737]">
                                Puedes seleccionar máximo de 5 áreas de derecho
                                que luego podrás editar desde tu perfil{" "}
                            </p>
                        </div>
                        <Input
                            label="Seleccionar áreas del derecho"
                            placeholder="Seleccione áreas del derecho"
                            onChange={(e) => handleChange(e)}
                            value={formData.areas}
                            name="areas"
                            type="dropdown"
                            selectList={areasOfLaw}
                            // addInputClass="lg:w-[362px]"
                        />
                    </div>

                    {formData.areas?.length ? (
                        <div className="flex justify-start items-start  gap-5 overflow-x-auto    w-full    min-h-[50px]">
                            {formData.areas.map((text, index) => (
                                <Tag
                                    key={index}
                                    text={text}
                                    hasClose
                                    removeTag={(text) => removeTag(text)}
                                />
                            ))}
                        </div>
                    ) : null}
                </div>

                <div className="box-border flex justify-start items-center self-stretch  gap-[5px]">
                    {/* <ButtonCheckBox /> */}

                    <p className="whitespace-pre-wrap font-['Noto_Sans'] text-sm leading-[normal] text-left text-[#373737]">
                        Protégete y envíalo anónimo
                    </p>
                </div>
                <Checkbox
                    checked={sendAnonymously}
                    toggleCheck={() => setSendAnonymously((prev) => !prev)}
                    customElm={
                        <p className="whitespace-pre-wrap font-['Noto_Sans'] text-sm leading-[normal] text-left text-[#373737]">
                            Protégete y envíalo anónimo
                        </p>
                    }
                />

                <div className="flex flex-row-reverse justify-between items-center py-3 w-full">
                    <Button
                        color={COLOR.RED}
                        size={SIZE.MEDIUM}
                        type="submit"
                        addClass={"h-11"}
                        onClick={showSuccessDialog}
                        // stretch
                    >
                        Siguiente
                    </Button>
                    <Button
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        addClass={"h-11"}
                        // stretch
                    >
                        Cancelar
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default RecommendedAdvisoryForm;
