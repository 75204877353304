import { useEffect, useState } from "react";
import frame1 from "../../assets/images/frame1.png";
import DiscountTemplate from "../DiscountTemplate";
import DiscountTemplateUrgent from "../DiscountTemplateUrgent";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";
import apiurl from "../ApiConfig";

const FirstContent = () => (
  <div className="flex justify-end  items-start self-stretch relative gap-2.5 font-[Satoshi] font-bold   ">
    {/* w-full */}
    <p className="  text-4xl xl:text-[81px] font-bold text-right text-[#373737]">
      <div className="flex relative justify-end text-right">
        <div className="flex  items-center text-4xl xl:text-[81px] font-bold text-right text-[#373737]">
          <svg
            width={80}
            height={80}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-2 w-10 h-10 xl:w-20 xl:h-20 xl:mx-5"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M59.9998 43.3333C56.8665 43.3333 53.9665 44.4334 51.6665 46.2667C48.5998 48.7 46.6665 52.4667 46.6665 56.6667C46.6665 59.1667 47.3665 61.5333 48.5998 63.5333C50.8998 67.4 55.1332 70 59.9998 70C63.3665 70 66.4332 68.7667 68.7665 66.6667C69.7999 65.8 70.6999 64.7333 71.3999 63.5333C72.6332 61.5333 73.3332 59.1667 73.3332 56.6667C73.3332 49.3 67.3665 43.3333 59.9998 43.3333ZM66.8999 55.2334L59.7998 61.7999C59.3332 62.2333 58.6998 62.4666 58.0998 62.4666C57.4665 62.4666 56.8331 62.2334 56.3331 61.7334L53.0332 58.4334C52.0665 57.4668 52.0665 55.8666 53.0332 54.8999C53.9998 53.9332 55.5998 53.9332 56.5665 54.8999L58.1665 56.5001L63.4998 51.5666C64.4998 50.6332 66.0998 50.7 67.0331 51.7C67.9665 52.7 67.8999 54.2667 66.8999 55.2334Z"
              fill="#E8667D"
            />
            <path
              d="M70.3007 71.6667C70.3007 72.6 69.5673 73.3333 68.634 73.3333H11.3674C10.434 73.3333 9.70068 72.6 9.70068 71.6667C9.70068 57.8667 23.3007 46.6667 40.0007 46.6667C43.434 46.6667 46.7674 47.1332 49.834 48.0332C47.8674 50.3666 46.6673 53.4 46.6673 56.6667C46.6673 59.1667 47.3673 61.5333 48.6006 63.5333C49.2673 64.6666 50.134 65.6999 51.134 66.5666C53.4673 68.6999 56.5673 70 60.0007 70C63.734 70 67.1007 68.4666 69.5007 65.9999C70.034 67.7999 70.3007 69.7 70.3007 71.6667Z"
              fill="#373737"
            />
            <path
              d="M40.0002 40C49.2049 40 56.6668 32.5381 56.6668 23.3333C56.6668 14.1286 49.2049 6.66667 40.0002 6.66667C30.7954 6.66667 23.3335 14.1286 23.3335 23.3333C23.3335 32.5381 30.7954 40 40.0002 40Z"
              fill="#373737"
            />
          </svg>
          <span className="flex-grow-0 flex-shrink-0">Los profesionales</span>
        </div>
        <svg
          width={400}
          height={49}
          viewBox="0 0 488 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          // xl:translate-y-5 xl:translate-y-2
          className="w-[220px] h-[30px] xl:w-[470px] xl:h-[49px] absolute bottom-0 right-0   translate-y-5 xl:translate-y-9  translate-x-1    "
          preserveAspectRatio="none"
        >
          <path
            d="M486 23.0043C377 2.00431 39 -19.9958 2 47.0042"
            stroke="#D80027"
            strokeWidth={3}
            stroke-linecap="round"
          />
        </svg>
      </div>

      <br className="hidden xl:block" />
      {/* w-full */}
      <span className="  text-4xl xl:text-[81px] font-bold text-right text-[#373737]">
        más destacados a un
        <br />
        <br className="hidden xl:block" />
        solo click de distancia
      </span>
    </p>
  </div>
);
const SecondContent = () => (
  <div
    data-show
    className="flex justify-end  items-start self-stretch relative gap-1 font-[Satoshi] text-black font-bold   "
  >
    <p className="  text-4xl xl:text-[81px] font-bold text-right text-[#373737]">
      <div className="flex relative justify-end text-right">
        <div className="flex  items-center text-4xl xl:text-[81px] font-bold text-right text-[#373737]">
          <svg
            width={80}
            height={80}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-2 w-10 h-10 xl:w-20 xl:h-20 xl:mx-5"
            preserveAspectRatio="none"
          >
            <path
              d="M69.7001 37.0663C69.7001 53.3663 57.8668 68.633 41.7001 73.0997C40.6001 73.3997 39.4 73.3997 38.3 73.0997C22.1334 68.633 10.3 53.3663 10.3 37.0663V22.4331C10.3 19.6997 12.3668 16.5997 14.9334 15.5664L33.5 7.96641C37.6667 6.26641 42.3667 6.26641 46.5334 7.96641L65.1001 15.5664C67.6334 16.5997 69.7334 19.6997 69.7334 22.4331L69.7001 37.0663Z"
              fill="#E8667D"
            />
            <path
              d="M48.3334 35.0003C48.3334 30.4003 44.6001 26.667 40.0001 26.667C35.4001 26.667 31.6667 30.4003 31.6667 35.0003C31.6667 38.7337 34.1334 41.8336 37.5001 42.9003V51.667C37.5001 53.0337 38.6334 54.167 40.0001 54.167C41.3667 54.167 42.5001 53.0337 42.5001 51.667V42.9003C45.8667 41.8336 48.3334 38.7337 48.3334 35.0003Z"
              fill="#292D32"
            />
          </svg>
          <span className="flex-grow-0 flex-shrink-0">Seguridad y</span>
        </div>
        <svg
          width={400}
          height={49}
          viewBox="0 0 488 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-[180px] h-[30px] xl:w-[370px] xl:h-[49px] absolute bottom-0 right-0 translate-y-7 xl:translate-y-10 -translate-x-[20px] xl:-translate-x-[70px]  "
          preserveAspectRatio="none"
        >
          <path
            d="M486 23.0043C377 2.00431 39 -19.9958 2 47.0042"
            stroke="#D80027"
            strokeWidth={3}
            stroke-linecap="round"
          />
        </svg>
      </div>
      <br className="hidden xl:block" />

      <span className="  text-4xl xl:text-[81px] font-bold text-right text-[#373737]">
        confidencialidad
        <br />
        <br className="hidden xl:block" />
        cliente-abogado
      </span>
    </p>
  </div>
);
const ThirdContent = () => (
  <div
    data-show
    className="flex justify-end  items-start self-stretch relative gap-2.5 font-[Satoshi] text-black font-bold   "
  >
    <p className="  text-4xl xl:text-[81px] font-bold text-right text-[#373737]">
      <div className="flex relative justify-end text-right">
        <div className="flex  items-center text-4xl xl:text-[81px] font-bold text-right text-[#373737]">
          <svg
            width={70}
            height={70}
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-2 w-10 h-10 xl:w-20 xl:h-20 xl:mx-5"
            preserveAspectRatio="xMidYMid meet"
          >
            <rect width={70} height={70} rx={35} fill="#E8667D" />
            <path
              d="M35 12.3955C22.5131 12.3955 12.3959 22.5127 12.3959 34.9997C12.3959 47.4867 22.5131 57.6038 35 57.6038C47.487 57.6038 57.6042 47.4867 57.6042 34.9997C57.6042 22.5127 47.487 12.3955 35 12.3955ZM35 53.2288C24.8829 53.2288 16.7709 45.1169 16.7709 34.9997C16.7709 24.9736 24.8829 16.7705 35 16.7705C45.0261 16.7705 53.2292 24.9736 53.2292 34.9997C53.2292 45.1169 45.0261 53.2288 35 53.2288ZM40.5599 43.7497C41.1068 44.1143 41.7448 44.0231 42.1094 43.4762L43.8412 41.1976C44.2058 40.6507 44.1146 40.0127 43.5677 39.6481L37.5521 35.182V22.2393C37.5521 21.6924 37.0052 21.1455 36.4584 21.1455H33.5417C32.9037 21.1455 32.448 21.6924 32.448 22.2393V37.2783C32.448 37.5518 32.5391 37.9163 32.8125 38.0986L40.5599 43.7497Z"
              fill="#333333"
            />
          </svg>
          <span className="flex-grow-0 flex-shrink-0">Servicio 24 horas</span>
        </div>
        <svg
          width={270}
          height={49}
          viewBox="0 0 488 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-[150px] h-[30px] xl:w-[270px] xl:h-[49px] absolute bottom-0 right-0 translate-y-6 xl:translate-y-9 translate-x-1 xl:-translate-x-5  "
          preserveAspectRatio="none"
        >
          <path
            d="M486 23.0043C377 2.00431 39 -19.9958 2 47.0042"
            stroke="#D80027"
            strokeWidth={3}
            stroke-linecap="round"
          />
        </svg>
      </div>
      <br className="hidden xl:block" />

      <div className="flex relative justify-end text-right">
        <div className="flex  items-center text-4xl xl:text-[81px] font-bold text-right text-[#373737]">
          <svg
            width={80}
            height={80}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-2 w-10 h-10 xl:w-20 xl:h-20 xl:mx-5"
            preserveAspectRatio="xMidYMid meet"
          >
            <path
              d="M55.8333 11.867V6.66699C55.8333 5.30033 54.6999 4.16699 53.3333 4.16699C51.9666 4.16699 50.8333 5.30033 50.8333 6.66699V11.667H29.1666V6.66699C29.1666 5.30033 28.0333 4.16699 26.6666 4.16699C25.2999 4.16699 24.1666 5.30033 24.1666 6.66699V11.867C15.1666 12.7003 10.8 18.067 10.1333 26.0337C10.0666 27.0003 10.8666 27.8003 11.8 27.8003H68.1999C69.1666 27.8003 69.9666 26.967 69.8666 26.0337C69.1999 18.067 64.8333 12.7003 55.8333 11.867Z"
              fill="#373737"
            />
            <path
              d="M70 36.1333V41.9333C70 43.9666 68.2 45.5333 66.2 45.2C65.2667 45.0666 64.3 44.9666 63.3333 44.9666C53.2333 44.9666 45 53.1999 45 63.2999C45 64.8333 45.6 66.9667 46.2333 68.9C46.9667 71.0667 45.3667 73.2999 43.0667 73.2999H26.6667C15 73.2999 10 66.6333 10 56.6333V36.0999C10 34.2666 11.5 32.7666 13.3333 32.7666H66.6667C68.5 32.7999 70 34.3 70 36.1333Z"
              fill="#E8667D"
            />
            <path
              d="M63.3333 50C55.9667 50 50 55.9667 50 63.3333C50 65.8333 50.7 68.2 51.9333 70.2C54.2333 74.0667 58.4667 76.6667 63.3333 76.6667C68.2 76.6667 72.4333 74.0667 74.7333 70.2C75.9667 68.2 76.6667 65.8333 76.6667 63.3333C76.6667 55.9667 70.7 50 63.3333 50ZM70.2333 61.9L63.1333 68.4667C62.6667 68.9 62.0333 69.1333 61.4333 69.1333C60.8 69.1333 60.1667 68.9 59.6667 68.4L56.3667 65.1C55.4 64.1333 55.4 62.5333 56.3667 61.5667C57.3333 60.6 58.9333 60.6 59.9 61.5667L61.5 63.1667L66.8333 58.2333C67.8333 57.3 69.4333 57.3667 70.3667 58.3667C71.3 59.3667 71.2333 60.9334 70.2333 61.9Z"
              fill="#373737"
            />
            <path
              d="M28.3333 49.9997C27.4667 49.9997 26.6 49.633 25.9667 49.033C25.3667 48.3997 25 47.533 25 46.6664C25 45.7997 25.3667 44.9331 25.9667 44.2997C26.7333 43.5331 27.9 43.1663 29 43.3997C29.2 43.433 29.4 43.4997 29.6 43.5997C29.8 43.6663 30 43.7664 30.2 43.8998C30.3667 44.0331 30.5333 44.1664 30.7 44.2997C31.3 44.9331 31.6667 45.7997 31.6667 46.6664C31.6667 47.533 31.3 48.3997 30.7 49.033C30.5333 49.1663 30.3667 49.2997 30.2 49.433C30 49.5663 29.8 49.6664 29.6 49.7331C29.4 49.8331 29.2 49.8997 29 49.9331C28.7667 49.9664 28.5333 49.9997 28.3333 49.9997Z"
              fill="#373737"
            />
            <path
              d="M40 50C39.1333 50 38.2666 49.6333 37.6333 49.0333C37.0333 48.3999 36.6666 47.5333 36.6666 46.6666C36.6666 45.8 37.0333 44.9333 37.6333 44.3C38.9 43.0667 41.1333 43.0667 42.3666 44.3C42.9666 44.9333 43.3333 45.8 43.3333 46.6666C43.3333 47.5333 42.9666 48.3999 42.3666 49.0333C41.7333 49.6333 40.8666 50 40 50Z"
              fill="#373737"
            />
            <path
              d="M28.3333 61.6669C27.4667 61.6669 26.6 61.3002 25.9667 60.7002C25.3667 60.0669 25 59.2002 25 58.3336C25 57.4669 25.3667 56.6003 25.9667 55.9669C26.3 55.6669 26.6333 55.4335 27.0667 55.2669C28.3 54.7335 29.7667 55.0336 30.7 55.9669C31.3 56.6003 31.6667 57.4669 31.6667 58.3336C31.6667 59.2002 31.3 60.0669 30.7 60.7002C30.0667 61.3002 29.2 61.6669 28.3333 61.6669Z"
              fill="#373737"
            />
          </svg>
          <span className="flex-grow-0 flex-shrink-0">365 días del año</span>
        </div>
      </div>
    </p>
  </div>
);

const texts = [<FirstContent />, <SecondContent />, <ThirdContent />];

function Hero() {
  useEffect(() => {
    const checkTokenAndRedirect = async () => {
      const authToken = localStorage.getItem("authToken");
      if (authToken) {
        const profileResponse = await fetch(`${apiurl}/User/Profile`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!profileResponse.ok) {
          throw new Error("Error al obtener el perfil del usuario");
        }

        const profileData = await profileResponse.json();
        if (profileData.description === null) {
          window.location.href = "/platform/board";
        } else {
          window.location.href = "/platform/professional-dashboard";
        }
      }
    };

    checkTokenAndRedirect();
  }, []);
  const [showDialog, setDialog] = useState(0);
  const [showDialog1, setDialog1] = useState(0);
  const [heroText, setHeroText] = useState(<FirstContent />);

  useEffect(() => {
    let i = 0;
    // set animation for firstcontent, second and third with 3 second interval
    const intr = setInterval(() => {
      setHeroText(texts[i++ % 3]);
    }, 4000);

    return () => {
      clearInterval(intr);
    };
  }, []);

  return (
    <>
      <DiscountTemplate showDialog={showDialog} setDialog={setDialog} />
      <DiscountTemplateUrgent showDialog={showDialog1} setDialog={setDialog1} />
      <div className=" h-[100vh] relative mb-[100px]">
        <div className="absolute bottom-0 left-0">
          {/* h-[85vh] */}
          <div className="  w-[63vw] h-screen xl:w-[45vw]  relative">
            <img
              src={frame1}
              alt="frame1"
              // xl:h-[75vh]
              className={`absolute bottom-[0%] right-[0%] object-contain xl:w-[95%]  `}
            />
          </div>
        </div>
        {/* <div className="block overflow-y-auto max-h-screen">

                </div> */}
        {/* xl:bottom-[20%] */}
        <div className="hideScrollBar flex flex-col justify-start items-end absolute max-h-[65vh] lg:max-h-none overflow-y-scroll xl:overflow-y-hidden overflow-x-hidden    xl:h-auto bottom-[35%] xl:bottom-[7%] right-[5%] xl:right-[7%]  gap-[15px] ">
          {heroText}

          <p className=" w-[80%] xl:w-[604px] text-[16px] xl:text-[21px] text-right text-[#7b7b7b] ">
            Contratación y Asesoría personalizada con los mejores abogados y
            notarios del país, a través de una plataforma segura
            {/* Solicita asesoría personalizada con los mejores abogados
                        y notario del país, a través de una plataforma segura */}
          </p>

          <div className="flex flex-row gap-4 justify-end items-start">
            <div className="flex flex-col justify-start items-start  gap-[5px]">
              <Button
                onClick={() => setDialog1(1)}
                size={SIZE.LARGE_SMALL}
                // className="hover:opacity-80 flex justify-center items-center text-sm xl:text-lg text-left text-white  h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027] transition-all duration-500 "
              >
                Contrata un profesional
              </Button>

              <div className="cursor-pointer flex justify-start items-center  relative gap-[5px]">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative w-6 h-6"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75ZM12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 9.5C10.5938 9.5 9.5 10.625 9.5 12C9.5 13.4062 10.5938 14.5 12 14.5C13.375 14.5 14.5 13.4062 14.5 12C14.5 10.625 13.375 9.5 12 9.5Z"
                    fill="#D80027"
                  />
                </svg>

                <p className=" text-sm text-left text-[#373737]">
                  Presencial y Online
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-4 justify-end xl:justify-end xl:items-start xl:flex-row">
              <div className="flex flex-col justify-start items-start  gap-[5px]">
                <Button
                  stretch
                  onClick={() => setDialog(1)}
                  color={COLOR.WHITE}
                  size={SIZE.LARGE_SMALL}
                  //  className="flex justify-center items-center text-sm xl:text-lg text-left text-[#373737]  h-12 relative gap-2.5 px-8 py-2.5 rounded border-2 border-[#292929] hover:bg-[#292929] hover:text-white  transition-all duration-500 "
                >
                  ¡Asesórate ya!
                </Button>
                <div className="cursor-pointer flex justify-start items-center  relative gap-[5px]">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative w-6 h-6"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75ZM12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 9.5C10.5938 9.5 9.5 10.625 9.5 12C9.5 13.4062 10.5938 14.5 12 14.5C13.375 14.5 14.5 13.4062 14.5 12C14.5 10.625 13.375 9.5 12 9.5Z"
                      fill="#D80027"
                    />
                  </svg>

                  <p className=" text-sm text-left text-[#373737]">
                    Presencial y Online
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
