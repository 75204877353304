import React from "react";

import registerFormBg from "../../assets/images/registerFormBg.png";

function FormBg() {
    return (
        <img
            src={registerFormBg}
            alt="registerFormBg"
            className="  h-full w-full  object-cover bg-no-repeat  fixed left-0 top-0  -z-[1]"
        />
    );
}

export default FormBg;
