import React from "react";
import { Button, COLOR } from "../ui/buttons/Button";

function ChatConfirmDialog({ closeDialog, deleteChat }) {
    return (
        <div
            className="flex flex-col justify-start items-center lg:w-[572px] gap-[30px] p-[30px] bg-white"
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
        >
            <div className="flex justify-start items-start   relative gap-2.5 p-2.5 rounded-[100px] bg-[#d80027]">
                <svg
                    width={40}
                    height={40}
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative flex-grow-0 flex-shrink-0 w-10 h-10 "
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M17.916 17.3438L18.2805 23.1771C18.2805 23.5417 18.541 23.75 18.9055 23.75H21.041C21.4055 23.75 21.666 23.4896 21.666 23.1771L22.0305 17.3438C22.0305 16.9792 21.7701 16.6667 21.4055 16.6667H18.541C18.1764 16.6667 17.916 16.9792 17.916 17.3438ZM22.1868 26.6667C22.1868 25.4688 21.1972 24.4792 19.9993 24.4792C18.7493 24.4792 17.8118 25.4688 17.8118 26.6667C17.8118 27.9167 18.7493 28.8542 19.9993 28.8542C21.1972 28.8542 22.1868 27.9167 22.1868 26.6667ZM22.1347 7.9167C21.1972 6.30212 18.7493 6.25004 17.8118 7.9167L5.31179 29.6355C4.37429 31.25 5.5722 33.3334 7.49929 33.3334H32.4472C34.3743 33.3334 35.5722 31.3021 34.6347 29.6355L22.1347 7.9167ZM7.7597 30.4167L19.6868 9.68754C19.843 9.4792 20.1035 9.4792 20.2597 9.68754L32.1868 30.4167C32.343 30.625 32.1868 30.8334 31.9264 30.8334H8.02012C7.7597 30.8334 7.60345 30.625 7.7597 30.4167Z"
                        fill="#F2F2F2"
                    />
                </svg>
            </div>
            <div className="flex flex-col justify-start items-center self-stretch   relative gap-[5px]">
                <p className="self-stretch   lg:w-[512px] text-2xl font-bold text-left text-[#373737]">
                    ¿Estas seguro de querer borrar el chat?{" "}
                </p>
                <p className="self-stretch   lg:w-[512px] text-xl text-left text-[#7b7b7b]">
                    Tu seguridad y la confidencialidad de tus documentos es
                    importante para nosotros, puedes solicitar conservar lo
                    datos en nuestro sistema o bien eliminar el chat con el
                    profesional.
                </p>
            </div>
            <div className="flex justify-start items-center self-stretch   gap-[5px]">
                <Button
                    stretch
                    onClick={deleteChat}
                    // className="flex justify-center text-base text-left text-white items-center flex-grow h-11 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]"
                >
                    Borrar chat
                    <svg
                        width={25}
                        height={24}
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M18.75 6.5H16.1562L15.0938 4.75C14.8438 4.3125 14.3438 4 13.8125 4H10.6562C10.125 4 9.625 4.3125 9.375 4.75L8.3125 6.5H5.75C5.46875 6.5 5.25 6.75 5.25 7V7.5C5.25 7.78125 5.46875 8 5.75 8H6.25L6.90625 18.5938C6.9375 19.4062 7.59375 20 8.40625 20H16.0625C16.875 20 17.5312 19.4062 17.5625 18.5938L18.25 8H18.75C19 8 19.25 7.78125 19.25 7.5V7C19.25 6.75 19 6.5 18.75 6.5ZM10.6562 5.5H13.8125L14.4062 6.5H10.0625L10.6562 5.5ZM16.0625 18.5H8.40625L7.75 8H16.7188L16.0625 18.5Z"
                            fill="#F2F2F2"
                        />
                    </svg>
                </Button>
                <Button
                    stretch
                    onClick={closeDialog}
                    color={COLOR.WHITE}
                    // className="flex  text-base text-left text-[#373737] justify-center items-center flex-grow h-11 relative gap-2.5 px-8 py-2.5 rounded border-2 border-[#292929]"
                >
                    Conservar
                </Button>
            </div>
        </div>
    );
}

export default ChatConfirmDialog;
