import { useState } from "react";
import hiwhero from "../../assets/images/howitworks/hiwhero.png";
import { Button, COLOR } from "../ui/buttons/Button";
import DiscountTemplate from "../DiscountTemplate";
import Dialog from "../Dialog";
import Contact from "../Contact";

function Hero() {
    const [showDialog, setDialog] = useState(0);
    const [showContactDialog, setContactDialog] = useState(0);
    const handleUrgentClick = () => {setDialog(1);};
    const handleContactClick = () => {setContactDialog(1);};

    return (
        <>
            <DiscountTemplate showDialog={showDialog} setDialog={setDialog} urgent={showDialog === 1} />
            <Dialog
                showDialog={showContactDialog === 1}
                setDialog={setContactDialog}
                main={
                    <Contact
                        isDialog
                        addClass="mt-10"
                        closeDialog={() => setContactDialog(0)}
                    />
                }
            />
            <div className="hiw__hero flex items-center justify-start lg:pl-[7%] py-[100px] gap-5 lg:gap-0 lg:pt-0  h-full lg:h-screen flex-col-reverse lg:flex-row-reverse ">
                <div className="absolute h-[80vh]  w-[35vw]  bg-[#ffecef] -z-10 hidden lg:block" />
                <img
                    src={hiwhero}
                    alt="joven-mujer-feliz-estrechandole-mano-agente-bienes-raices-mientras-su-esposo-usa-panel-tactil-oficina-1.jpeg"
                    className="w-full lg:w-[45vw] object-contain"
                />

                <div className="flex flex-col justify-start items-start flex-grow relative gap-5 w-[80vw] lg:w-[30vw] mr-5  ">
                    <p className="font-[Satoshi] self-stretch   text-[31px] lg:text-[61px] font-bold text-left text-[#373737]">
                        ¿Como los{" "}
                        <span className="relative">
                            profesionales
                            <svg
                                width={378}
                                height={37}
                                viewBox="0 0 378 37"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute flex-grow-0 flex-shrink-0 top-[33px] left-[-10px] lg:left-[0] lg:top-[62px] w-[200px] lg:w-auto"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M376 18.9028C289.101 1.05593 31.4041 -12.5447 2 35.4765"
                                    stroke="#D80027"
                                    strokeWidth={3}
                                    stroke-linecap="round"
                                />
                            </svg>
                        </span>{" "}
                        pueden ayudarte?
                    </p>
                    <p className=" self-stretch   lg:w-[85%] text-md lg:text-xl text-left text-[#7b7b7b]">
                        Las posibilidades son ilimitadas. Tenemos expertos que
                        trabajan en cada campo técnico, profesional que te
                        puedas imaginar.
                    </p>
                    <div className="flex flex-col w-full gap-5 lg:flex-row ">
                        <Button onClick={handleUrgentClick}>
                            Contrata un profesional
                        </Button>
                        <Button
                            color={COLOR.WHITE}
                            onClick={handleContactClick}
                            //onClick={() => setContactDialog(0)}original ambos botones
                        >
                            ¡Asesórate ya!
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hero;
