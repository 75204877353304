import React from "react";
import { Outlet } from "react-router-dom";
import MyProjectsNav from "./MyProjectsNav";

const MyProjectsLayout = () => {
    return (
        <div className="mt-28 py-10  ">
            <div className=" w-full max-w-[1176px] mx-auto">
                <p className="w-full text-[21px] font-bold text-left text-[#373737] pb-[27px]">
                    Proyectos y cotizaciones
                </p>
                <MyProjectsNav />

                <Outlet />
            </div>
        </div>
    );
};

export default MyProjectsLayout;
