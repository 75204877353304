import React from "react";
import { Button, COLOR, SIZE } from "../../../components/ui/buttons/Button";
import InfoHover from "../../../components/ui/InfoHover";
import Input from "../../../components/ui/Input";

const WithdrawOption = ({
    icon = (
        <svg
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="relative w-10 h-10 "
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M31.25 29.5833H30.8333V26.6667C30.8333 25.9896 30.2604 25.4167 29.5833 25.4167H28.75V17.5H26.25V25.4167H23.75V17.5H21.25V25.4167H18.75V17.5H16.25V25.4167H13.75V17.5H11.25V25.4167H10.4167C9.6875 25.4167 9.16667 25.9896 9.16667 26.6667V29.5833H8.75C8.02083 29.5833 7.5 30.1562 7.5 30.8333V31.6667C7.5 31.9271 7.65625 32.0833 7.91667 32.0833H32.0833C32.2917 32.0833 32.5 31.9271 32.5 31.6667V30.8333C32.5 30.1562 31.9271 29.5833 31.25 29.5833ZM28.3333 29.5833H11.6667V27.9167H28.3333V29.5833ZM32.0833 12.8125L20.7812 8.07292C20.5208 7.96875 20.2604 7.96875 20 7.96875C19.6875 7.96875 19.4271 7.96875 19.1667 8.07292L7.86458 12.8125C7.65625 12.8646 7.5 13.125 7.5 13.3854V14.7917C7.5 15.1562 7.76042 15.4167 8.125 15.4167H9.16667V16.0417C9.16667 16.4062 9.42708 16.6667 9.79167 16.6667H30.2083C30.5208 16.6667 30.8333 16.4062 30.8333 16.0417V15.4167H31.875C32.1875 15.4167 32.5 15.1562 32.5 14.7917V13.3854C32.5 13.125 32.2917 12.8646 32.0833 12.8125ZM10 14.1667L20 10.4167L30 14.1667H10Z"
                fill="#333333"
            />
        </svg>
    ),
    title = "Retiro express",
    desc = `¡El método más rapido de retirar fondos
    directamente a tu cuenta bancaria local!
    Disponible solo en algunos países.`,
    fees = "Sin cargos",
    arrow = true
}) => (
    <div className="flex flex-col sm:flex-row justify-start items-center self-stretch   relative gap-[42px] p-4 bg-white border border-[#cdcdcd]">
        <div className="flex flex-col md:flex-row justify-start items-center  relative gap-2.5">
            {icon}
            <Button
                addClass={"  w-[156px]"}
                text={title}
                color={COLOR.WHITE}
                size={SIZE.SMALL}
                iconName={"arrow"}
                // stretch
            />
        </div>
        <div className="flex flex-col md:flex-row justify-start items-center w-full md:w-auto   relative gap-2.5">
            <p className="flex-grow md:w-[254px] text-sm text-center md:text-left text-[#373737] pb-3 md:pb-0 border-b     border-[#cdcdcd] md:border-0  ">
                {desc}
            </p>
            <p className="  text-sm text-right text-[#373737]">{fees}</p>
        </div>
    </div>
);

function Withdraw() {
    return (
        <div className="flex flex-col w-full items-center mt-[100px]">
            <div className="relative flex flex-col items-center justify-start gap-5 md:items-start">
                <p className="  text-2xl font-bold text-left text-[#373737]">
                    Solicitud de retiro
                </p>
                <div className="flex flex-col md:flex-row justify-start items-start w-full gap-[25px]">
                    <div className="flex flex-col justify-start items-start   md:w-[644px] gap-[46px]">
                        <div className="flex flex-col items-start self-stretch justify-start ">
                            <div className="flex justify-start items-center self-stretch   h-[60px] relative gap-[133px] px-10 md:px-6 border border-[#cdcdcd]">
                                <p className=" w-full sm:w-fit md:w-[156px]  text-base font-bold text-center sm:text-left text-[#373737]">
                                    Moneda
                                    <span className="visible sm:hidden">
                                        ,&nbsp;Descripción & Cargos
                                    </span>
                                </p>
                                <p className=" hidden sm:block sm:w-[400px]  md:w-[156px] text-base font-bold text-center   text-[#373737]">
                                    Descripción{" "}
                                    <span className="visible md:hidden">
                                        {" "}
                                        & Cargos
                                    </span>
                                </p>
                                <p className="hidden md:block md:w-[116px]  text-base font-bold text-left text-[#373737]">
                                    Cargos
                                </p>
                            </div>
                            <WithdrawOption />
                            <WithdrawOption
                                title="Retirar"
                                icon={
                                    <svg
                                        width={40}
                                        height={40}
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="relative w-10 h-10 "
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                            d="M26.1458 19.3228C27.5521 18.5936 28.4896 17.2915 28.2812 15.104C28.0208 12.0832 25.5729 11.0936 22.2917 10.8332V6.6665H19.7917V10.729C19.1146 10.729 18.4375 10.729 17.7604 10.729V6.6665H15.2604V10.8332C14.3229 10.8332 13.2292 10.8332 10.1562 10.8332V13.5415C12.1875 13.4894 13.2292 13.3332 13.4375 14.6353V25.9373C13.2813 26.979 12.5 26.8228 10.6771 26.8228L10.1562 29.7915C14.7917 29.7915 15.2604 29.7915 15.2604 29.7915V33.3332H17.7604V29.8436C18.4375 29.8957 19.1146 29.8957 19.7917 29.8957V33.3332H22.2917V29.7915C26.5104 29.5832 29.375 28.5415 29.7396 24.5311C30.0521 21.354 28.5417 19.8957 26.1458 19.3228ZM17.8125 13.6978C19.2708 13.6978 23.6979 13.2811 23.6979 16.2498C23.6979 19.0623 19.2708 18.7498 17.8125 18.7498V13.6978ZM17.8125 26.8228V21.2498C19.5312 21.2498 24.7396 20.7811 24.7396 24.0623C24.7396 27.1873 19.5312 26.8228 17.8125 26.8228Z"
                                            fill="#333333"
                                        />
                                    </svg>
                                }
                                desc="Retira fondos en Criptomonedas"
                            />
                            <WithdrawOption
                                title="Retirar"
                                icon={
                                    <svg
                                        width={40}
                                        height={40}
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="relative w-10 h-10 "
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                            d="M35.0007 10H5.00065C4.06315 10 3.33398 10.7812 3.33398 11.6667V28.3333C3.33398 29.2708 4.06315 30 5.00065 30H35.0007C35.8861 30 36.6673 29.2708 36.6673 28.3333V11.6667C36.6673 10.7812 35.8861 10 35.0007 10ZM34.1673 24.1667C32.2923 24.1667 30.834 25.6771 30.834 27.5H9.16732C9.16732 25.6771 7.6569 24.1667 5.83398 24.1667V15.8333C7.6569 15.8333 9.16732 14.375 9.16732 12.5H30.834C30.834 14.375 32.2923 15.8333 34.1673 15.8333V24.1667ZM20.0007 15C17.6569 15 15.834 17.2396 15.834 20C15.834 22.8125 17.6569 25 20.0007 25C22.2923 25 24.1673 22.8125 24.1673 20C24.1673 17.2396 22.2923 15 20.0007 15Z"
                                            fill="#333333"
                                        />
                                    </svg>
                                }
                                desc="Retira fondos en Efectivo"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-start flex-grow w-full lg:w-auto ">
                        <div className="flex w-full justify-between items-start self-stretch  h-[60px] relative lg:gap-[133px] pl-4 border border-[#cdcdcd]">
                            <p className="flex flex-row h-full items-center flex-grow lg:w-[247px] text-base font-bold text-left text-[#373737]">
                                Seleccionar forma de pago
                            </p>
                            <div className="flex justify-center items-center self-stretch  lg:w-[60px] relative gap-2.5 p-2.5 bg-[#292929]">
                                <p className="text-base font-bold text-left text-white ">
                                    GTQ
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-start self-stretch  gap-5 px-4 py-5 border border-[#cdcdcd]">
                            <div className="relative flex flex-col self-stretch justify-start gap-5 lg:items-center lg:flex-row">
                                <p className="flex-grow lg:w-[196px] text-sm font-bold text-left text-[#373737]">
                                    Moneda
                                </p>
                                <Input
                                    name="currency"
                                    // value={formData.currency}
                                    // onChange={(e) => handleChange(e.target)}
                                    type="dropdown"
                                    placeholder="🇨🇷 Quetzales"
                                    selectList={[
                                        "🇨🇷 Quetzales",
                                        "🇺🇸 Dollars",
                                        "🇨🇷 Quetzales",
                                        "🇺🇸 Dollars",
                                        "🇨🇷 Quetzales",
                                        "🇺🇸 Dollars"
                                    ]}
                                    icon={
                                        <svg
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="relative w-6 h-6 "
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <path
                                                d="M18.7812 9.25L18.1875 8.625C18.0312 8.46875 17.7812 8.46875 17.6562 8.625L12 14.2812L6.3125 8.625C6.1875 8.46875 5.9375 8.46875 5.78125 8.625L5.1875 9.25C5.03125 9.375 5.03125 9.625 5.1875 9.78125L11.7188 16.3125C11.875 16.4688 12.0938 16.4688 12.25 16.3125L18.7812 9.78125C18.9375 9.625 18.9375 9.375 18.7812 9.25Z"
                                                fill="#333333"
                                            />
                                        </svg>
                                    }
                                />
                            </div>
                            <div className="relative flex flex-col self-stretch justify-start gap-5 lg:items-center lg:flex-row">
                                <p className="flex-grow lg:w-[196px] text-sm font-bold text-left text-[#373737]">
                                    Cantidad
                                </p>

                                <Input
                                    // label="Tarifa por hora"
                                    placeholder="GTQ"
                                    // onChange={(e) => handleChange(e.target)}
                                    // value={formData.tariff}
                                    name="tariff"
                                    type="text"
                                    // icon={
                                    //     <p className=" text-base text-right text-[#373737] -translate-x-5  ">
                                    //         30
                                    //     </p>
                                    // }
                                />
                            </div>
                            <div className="relative flex items-center self-stretch justify-start">
                                <p className="flex-grow lg:w-[212px] text-sm font-bold text-left text-[#373737]">
                                    Total adeudado
                                </p>
                                <p className="flex-grow lg:w-[212px] text-sm text-right text-[#373737]">
                                    {" "}
                                    GTQ 30,00
                                </p>
                            </div>
                            <div className="flex justify-start items-center self-stretch  relative pb-5 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                                <div className="flex justify-start items-center flex-grow relative lg:gap-[5px]">
                                    <p className=" text-sm font-bold text-left text-[#373737]">
                                        Cuota de procesamiento
                                    </p>
                                    <InfoHover
                                        text={"Cuota de procesamiento "}
                                    />
                                </div>
                                <p className="flex-grow lg:w-[212px] text-sm text-right text-[#373737]">
                                    GTQ 0,99
                                </p>
                            </div>
                            <div className="relative flex items-center self-stretch justify-start">
                                <p className="flex-grow lg:w-[212px] text-sm font-bold text-left text-[#373737]">
                                    Pago adeudado
                                </p>
                                <p className="flex-grow lg:w-[212px] text-sm text-right text-[#373737]">
                                    GTQ 30,99
                                </p>
                            </div>
                            <Button
                                // onClick={handleFormSubmission}
                                stretch
                                // className="flex justify-center items-center self-stretch text-sm lg:text-lg text-left text-white h-12 relative gap-2.5 px-4 py-1 lg:px-8 lg:py-2.5 rounded bg-[#d80027]"
                            >
                                Confirmar y pagar GTQ
                                {/* {formData.tariff || null} */}
                            </Button>
                        </div>
                    </div>
                    {/* <div className="flex flex-col items-start justify-start flex-grow w-full md:w-auto">
                        <div className="flex justify-start items-center self-stretch   h-[60px] relative pl-4 border border-[#cdcdcd]">
                            <p className="flex-grow md:w-[191px] text-base font-bold text-left text-[#373737]">
                                Moneda
                            </p>
                            <div className="flex justify-center items-center self-stretch   md:w-[60px] relative gap-2.5 p-2.5 bg-[#292929]">
                                <p className="text-base font-bold text-left text-white ">
                                    Total
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-start self-stretch   gap-5 px-4 py-5 border border-[#cdcdcd]">
                            <div className="relative flex items-center self-stretch justify-start">
                                <p className="flex-grow md:w-[117.5px] text-sm font-bold text-left text-[#373737]">
                                    GTQ principal
                                </p>
                                <p className="flex-grow md:w-[117.5px] text-sm text-right text-[#373737]">
                                    00,00 $
                                </p>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="flex flex-col justify-start items-start   md:w-[644px] relative gap-[13px] p-4 bg-[#ffecef]">
                    <p className="  text-base font-bold text-left text-[#373737]">
                        Cosas que se deben tener en cuenta:
                    </p>
                    <div className="flex flex-col justify-start items-start self-stretch   relative gap-[13px]">
                        <p className="self-stretch   md:w-[612px] text-sm text-left text-[#373737]">
                            Lorem ipsum dolor sit amet consectetur. Praesent
                            nibh quis nulla facilisis. Nunc arcu orci dolor
                            neque ut rhoncus diam adipiscing nulla. Dictumst
                            enim mauris ipsum aliquam orci hendrerit
                            consectetur. Erat nulla tempus tellus ultricies
                            condimentum.
                        </p>
                        <p className="self-stretch   md:w-[612px] text-sm text-left text-[#373737]">
                            Lorem ipsum dolor sit amet consectetur. Praesent
                            nibh quis nulla facilisis. Nunc arcu orci dolor
                            neque ut rhoncus diam adipiscing nulla. Dictumst
                            enim mauris ipsum aliquam orci hendrerit
                            consectetur. Erat nulla tempus tellus ultricies
                            condimentum.
                        </p>
                        <p className="self-stretch   md:w-[612px] text-sm text-left text-[#373737]">
                            Lorem ipsum dolor sit amet consectetur. Praesent
                            nibh quis nulla facilisis. Nunc arcu orci dolor
                            neque ut rhoncus diam adipiscing nulla. Dictumst
                            enim mauris ipsum aliquam orci hendrerit
                            consectetur. Erat nulla tempus tellus ultricies
                            condimentum.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Withdraw;
