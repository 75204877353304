import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import HeaderProfessional from "./components/HeaderProfessionals";
import Home from "./components/Home";
import FindingProfesssionalWrapper from "./components/client-only/FindingProfesssionalWrapper";
import UrgentContractForm from "./components/client-only/UrgentContractForm";
import InviteFriendFAQ from "./components/platform/InviteFriendFAQ";
import Cuenta from "./components/settings/Cuenta";
import { PAGE_TYPES } from "./constants/platform";
import NotFound from "./pages/404";
import Blog from "./pages/Blog";
import BlogPreview from "./pages/BlogPreview";
import Contact from "./pages/Contact";
import HowItWorks from "./pages/HowItWorks";
import Login from "./pages/Login";
import Policy from "./pages/Policy";
import Professionals from "./pages/Professionals";
import SignUp from "./pages/SignUp";
import SignUpClient from "./pages/SignUpClient";
import SignUpProfessional from "./pages/SignUpProfessional";
import Analytics from "./pages/platform/Analytics";
import ChatApp from "./pages/platform/ChatApp";
import InviteFriend from "./pages/platform/InviteFriend";
import ProfessionalDirectory from "./pages/platform/ProfessionalDirectory";
import QuoteForm from "./pages/platform/QuoteForm";
import Community from "./pages/platform/community/Community";
import CommunityBlog from "./pages/platform/community/CommunityBlog";
import Board from "./pages/platform/dashboard/Client";
import ProfessionalDashboard from "./pages/platform/dashboard/Professional-dashboard";
// import Board from "./pages/platform/dashboard/Professional";
import RecommendedHiringForm from "./components/client-only/RecommendedHiringForm";
import UrgentHiringForm from "./components/client-only/UrgentHiringForm";
import RecommendedAdvisoryForm from "./components/client-only/recommendedAdvisory/RecommendedAdvisoryForm";
import Details from "./components/client-only/recommendedHiring/Details";
import Files from "./components/client-only/recommendedHiring/Files";
import Payments from "./components/client-only/recommendedHiring/Payments";
import Proposals from "./components/client-only/recommendedHiring/Proposals";
import Rate from "./components/client-only/recommendedHiring/Rate";
import RecommendedHiringDetailsLayout from "./components/client-only/recommendedHiring/RecommendedHiringDetailsLayout";
import TaskPage from "./components/client-only/recommendedHiring/TaskPage";
import Ajanta from "./pages/Ajanta";
import CertificateDetails from "./pages/platform/CertificateDetails";
import CertificateNegativeResult from "./pages/platform/CertificateNegativeResult";
import CertificatePositiveResult from "./pages/platform/CertificatePositiveResult";
import CertificatePregunta from "./pages/platform/CertificatePregunta";
import CertificateSurvey from "./pages/platform/CertificateSurvey";
import Certifications from "./pages/platform/Certifications";
import CurrentWork from "./pages/platform/MyProjects/CurrentWork";
import MyProjects from "./pages/platform/MyProjects/MyProjects";
import MyProjectsLayout from "./pages/platform/MyProjects/MyProjectsLayout";
import PreviousWork from "./pages/platform/MyProjects/PreviousWork";
import Quotations from "./pages/platform/MyProjects/Quotations";
import Error404 from "./pages/platform/dashboard/404-construction";
import Error4044 from "./pages/platform/dashboard/404-construction1";
import Error404Client from "./pages/platform/dashboard/404-constructionClient";
import ProfessionalProfile from "./pages/platform/dashboard/Professional";
import ClientProfile from "./pages/platform/dashboard/ProfileClient";
import JobDetails from "./pages/platform/jobs/JobDetails";
import JobOffers from "./pages/platform/jobs/JobOffers";
import Funds from "./pages/platform/payment/Funds";
import Withdraw from "./pages/platform/payment/Withdraw";
import DetailTemplate from "./pages/platform/templates/DetailTemplate";
import RepliesTemplate from "./pages/platform/templates/RepliesTemplate";
import SearchTemplates from "./pages/platform/templates/SearchTemplate";
import EmailNotifications from "./pages/settings/EmailNotifications";
import Membership from "./pages/settings/Membership";
import Password from "./pages/settings/Password";
import PaymentsFinances from "./pages/settings/PaymentsFinances";
import SettingsLayout from "./pages/settings/SettingsLayout";
import SettingsProfile from "./pages/settings/SettingsProfile";
import SettingsProfileClient from "./pages/settings/SettingsProfileClient";
import TrustVerification from "./pages/settings/TrustVerification";
import ScrollToTop from "./utils/ScrollTop";

import CustomerSupport from "./pages/platform/support/CustomerSupport";
import CustomerSupportLayout from "./pages/platform/support/CustomerSupportLayout";
import OpenTicketTable from "./pages/platform/support/OpenTicketTable";
import ResultTable from "./pages/platform/support/ResultTable";
import Support from "./pages/platform/support/Support";
import SupportContact from "./pages/platform/support/SupportContact";
import SupportLayout from "./pages/platform/support/SupportLayout";
import TicketDetails from "./pages/platform/support/TicketDetails";
import TicketHistory from "./pages/platform/support/TicketHistory";
import DisputeSteps from "./components/lawyer-dispute/DisputeSteps";

function App() {
  const headerRoutes = [
    "/",
    "/howitworks",
    "/login",
    "/platform/board",
    "/platform/client/profile",
    "/platform/settings/profile-client",
  ];
  const isHeaderRoute = headerRoutes.includes(window.location.pathname);
  return (
    <Router>
      <ScrollToTop />
      <div>
        {!isHeaderRoute && <HeaderProfessional />}
        {isHeaderRoute && <Header />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/howitworks" element={<HowItWorks />} />
          {/* AUTH */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup/client" element={<SignUpClient />} />
          <Route path="/signup/professional" element={<SignUpProfessional />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/platform/settings" element={<SettingsLayout />}>
            <Route
              path="/platform/settings/profile"
              element={<SettingsProfile />}
            />
            <Route
              path="/platform/settings/profile-client"
              element={<SettingsProfileClient />}
            />
            <Route
              path="email-notifications"
              element={<EmailNotifications />}
            />
            <Route path="membership" element={<Membership />} />
            <Route path="password" element={<Password />} />
            <Route path="payments" element={<PaymentsFinances />} />
            <Route path="verification" element={<TrustVerification />} />
            <Route path="account" element={<Cuenta />} />
          </Route>
          {/* CLIENT ONLY PAGES */}
          <Route
            path="/client/urgent-hiring"
            element={<UrgentContractForm />}
          />
          <Route path="/platform/client/profile" element={<ClientProfile />} />
          <Route
            path="/client/urgent-hiring/processing/:key_jobs"
            element={<FindingProfesssionalWrapper />}
          />
          <Route path="/client/urgent-advice" element={<UrgentHiringForm />} />
          <Route
            path="/platform/client/recommended-hiring"
            element={<RecommendedHiringForm />}
          />
          <Route path="/platform/lawyer-dispute" element={<DisputeSteps />} />
          <Route
            path="/platform/client/recommended-hiring/:lawyerId"
            element={<RecommendedHiringDetailsLayout />}
          >
            <Route index element={<Details />} />
            <Route path="proposals" element={<Proposals />} />
            <Route path="payments" element={<Payments />} />
            <Route path="files" element={<Files />} />
            <Route path="tasks" element={<TaskPage />} />
            <Route path="rate" element={<Rate />} />
          </Route>
          <Route
            path="/platform/client/recommended-advisory"
            element={<RecommendedAdvisoryForm />}
          />{" "}
          <Route path="/platform/certifications" element={<Certifications />} />
          <Route
            path="/platform/certificate-details"
            element={<CertificateDetails />}
          />
          <Route
            path="/platform/certificate-survey"
            element={<CertificateSurvey />}
          />
          <Route
            path="/platform/certificate-pregunta"
            element={<CertificatePregunta />}
          />
          <Route
            path="/platform/certificate-positiveresult"
            element={<CertificatePositiveResult />}
          />
          <Route
            path="/platform/certificate-negativeresult"
            element={<CertificateNegativeResult />}
          />
          <Route
            path="/client/urgent-advice/processing/:key_jobs"
            element={<FindingProfesssionalWrapper />}
          />
          <Route path="/professionals" element={<Professionals />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPreview />} />
          <Route path="/blog/post/:id" element={<BlogPreview />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/privacy" element={<Policy />} />
          <Route path="/platform/board" element={<Board />} />
          <Route path="/ajanta" element={<Ajanta />} />
          <Route
            path="/platform/professional-dashboard"
            element={<ProfessionalDashboard />}
          />
          <Route path="/platform/community" element={<Community />} />
          <Route path="/platform/projects" element={<MyProjectsLayout />}>
            <Route index element={<MyProjects />} />
            <Route path="currentWork" element={<CurrentWork />} />
            <Route path="previousWork" element={<PreviousWork />} />
            <Route path="quotations" element={<Quotations />} />
          </Route>
          <Route path="/platform/support" element={<SupportLayout />}>
            <Route index element={<Support />} />
            <Route path="customer" element={<CustomerSupportLayout />}>
              <Route index element={<CustomerSupport />} />
              <Route path="proyecto" element={<h2>Proyecto</h2>} />
              <Route path="pagos" element={<h2>Pagos</h2>} />
              <Route path="afiliacion" element={<h2>Afiliacion</h2>} />
              <Route path="perfil" element={<h2>Perfil</h2>} />
            </Route>
            <Route path="ticket-history" element={<TicketHistory />}>
              <Route index element={<OpenTicketTable />} />
              <Route path="result" element={<ResultTable />} />
            </Route>
            <Route path="ticket-history/:id" element={<TicketDetails />} />
            <Route path="contact-us" element={<SupportContact />} />
          </Route>
          <Route path="/platform/community/blog" element={<CommunityBlog />} />
          <Route path="/platform/support" element={<Support />} />
          <Route path="/platform/chat" element={<ChatApp />} />
          <Route path="/platform/analytics" element={<Analytics />} />
          <Route path="/platform/funds" element={<Funds />} />
          <Route path="/platform/withdraw" element={<Withdraw />} />
          <Route path="/platform/quote" element={<QuoteForm />} />
          <Route path="/platform/jobs/:id/" element={<JobDetails />} />
          <Route path="/platform/jobs" element={<JobOffers />} />
          {/* SIMILAR PAGES: Laws, Jurispudence, Documents, Telephone  */}
          {/* SEARCH PAGES  */}
          <Route
            path="/platform/jurisprudence"
            element={<SearchTemplates type={PAGE_TYPES.JURIS} />}
          />
          <Route
            path="/platform/documents"
            element={<SearchTemplates type={PAGE_TYPES.DOCS} />}
          />
          <Route
            path="/platform/telephone"
            element={<SearchTemplates type={PAGE_TYPES.TELE} />}
          />
          <Route
            path="/platform/laws"
            element={<SearchTemplates type={PAGE_TYPES.LAW} />}
          />
          {/* POST DETAILS */}
          <Route
            path="/platform/jurisprudence/:id"
            element={<DetailTemplate type={PAGE_TYPES.JURIS} />}
          />
          <Route
            path="/platform/documents/:id"
            element={<DetailTemplate type={PAGE_TYPES.DOCS} />}
          />
          <Route
            path="/platform/laws/:id"
            element={<DetailTemplate type={PAGE_TYPES.LAW} />}
          />
          {/* POST REPLIES */}
          <Route
            path="/platform/jurisprudence/:id/replies"
            element={<RepliesTemplate type={PAGE_TYPES.JURIS} />}
          />
          <Route
            path="/platform/documents/:id/replies"
            element={<RepliesTemplate type={PAGE_TYPES.DOCS} />}
          />
          <Route
            path="/platform/laws/:id/replies"
            element={<RepliesTemplate type={PAGE_TYPES.LAW} />}
          />
          <Route
            path="/platform/professionals-directory"
            element={<ProfessionalDirectory />}
          />
          <Route
            path="/platform/professionals/profile"
            element={<ProfessionalProfile />}
          />
          <Route path="/platform/404" element={<Error404 />} />
          <Route path="/4044" element={<Error4044 />} />
          <Route path="/platform/404-client" element={<Error404Client />} />
          <Route path="/platform/invite-friend" element={<InviteFriend />} />
          <Route path="/platform/faq" element={<InviteFriendFAQ />} />
          <Route path="/:other" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
