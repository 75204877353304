import React, { useState } from "react";
import Checkbox from "../../ui/Checkbox";
import { StarSvg } from "../find-professional/ReviewPage";

const Rate = () => {
    const data = [
        {
            text: "Calidad del trabajo",
            rating: 3
        },
        {
            text: "Comunicación",
            rating: 3
        },
        {
            text: "Experticia",
            rating: 3
        },
        {
            text: "Profesionalismo",
            rating: 3
        },
        {
            text: "¿Qué probabilidades hay de que vuelvas a contratar?",
            rating: 3
        }
    ];

    // const [showDialog, setDialog] = useState(false);
    const [onTime, setOnTime] = useState(false);
    const [inBudget, setInBudget] = useState(false);
    const [ratings, setRatings] = useState(data.map(() => 3));

    const handleRatingChange = (index, rating) => {
        console.log(index, rating);
        const newRatings = [...ratings];
        newRatings[index] = rating;
        setRatings(newRatings);
    };

    return (
        <div className="w-full p-4 bg-white border border-[#cdcdcd] flex flex-col gap-7">
            <div className="space-y-[30px] w-full">
                <div className="space-y-[5px]">
                    <p className="text-[21px] font-bold text-left text-[#373737]">
                        Calificar
                    </p>
                    <p className="text-base text-left text-[#373737]">
                        Por favor indicanos que te parecio el servicio, califica
                        al profesional
                    </p>
                </div>
                <div className="space-y-3">
                    <Checkbox
                        checked={onTime}
                        toggleCheck={() => setOnTime((x) => !x)}
                        text="¿Entregado a tiempo?"
                    />
                    <Checkbox
                        checked={inBudget}
                        toggleCheck={() => setInBudget((x) => !x)}
                        text="¿Entregado dentro del presupuesto?"
                    />
                </div>
                <div className="space-y-3">
                    <p className="text-[21px] font-bold text-left text-[#373737]">
                        Tasa de efectividad
                    </p>
                    <div className="space-y-4">
                        {data.map((item, index) => (
                            <div
                                key={index}
                                className="flex items-center pb-4 border-t-0 border-r-0 border-b border-l-0 border-[#e6e6e6]"
                            >
                                <p className="flex-grow text-base text-left text-[#373737] ">
                                    {item.text}
                                </p>
                                <div className="flex gap-6">
                                    <div className="flex relative flex-grow-0 flex-shrink-0 gap-2 justify-start items-center">
                                        {[...Array(5)].map((_, i) => (
                                            <StarSvg
                                                key={i}
                                                colored={i < ratings[index]}
                                                onClick={() =>
                                                    handleRatingChange(
                                                        index,
                                                        i + 1
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                    <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[73px] relative gap-2.5 px-2.5 py-1 rounded-[100px] bg-[#292929]">
                                        <p className="flex-grow-0 flex-shrink-0 text-base text-left text-white">
                                            {ratings[index]}.0
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rate;
