import React from "react";
import { twMerge } from "tailwind-merge";

const ProgressRing = ({ size = 120, strokeWidth = 6, percent = 75, time, text = "correcto", color = "#1890FF" }) => {

    const circumference = Math.PI * size;
    const dashOffset = circumference - (circumference * percent) / 100; 
    return (
        <div
            className={twMerge(
                "relative  flex-shrink-0 ",
                `h-[${size}px] w-[${size}px]`
            )}
        >
            <svg className="transform  -rotate-90  w-full h-full ">
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={size / 2 - strokeWidth / 2}
                    stroke="currentColor"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    className="text-[#F5F5F5]"
                />

                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={size / 2 - strokeWidth / 2}
                    stroke="currentColor"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    strokeDasharray={circumference}
                    strokeDashoffset={dashOffset}
                    className={`text-[${color}]`}
                />
            </svg>
            <div className="absolute  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <p className="text-black/[0.85]">
                    <span className="text-2xl">{time ? time : percent+'%'}</span>
                    <br />
                    <span className="text-sm">{text}</span>
                </p>
            </div>
        </div>
    );
};

export default ProgressRing;
