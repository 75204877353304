import React, { useCallback, useState } from "react";

import avatar from "../../assets/images/landing/profile.png";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";
import Thumb from "../ui/Thumb";

function CommunityBlogPost({ self = { liked: false } }) {
    const [hover, setHover] = useState(0);
    const [liked, setLiked] = useState(self.liked);

    const handleClick = useCallback(() => {
        setLiked((l) => !l);
    }, []);

    return (
        <div
            onMouseLeave={() => setHover(0)}
            onMouseOver={() => setHover(1)}
            className={`flex flex-col justify-start items-start  w-full gap-5 p-5 ${
                !hover ? "bg-white" : "bg-[#fff5f7]"
            }  border border-[#cdcdcd] transition-all ease-in-out duration-200  `}
        >
            <div className="flex justify-start items-center self-stretch   relative gap-2.5 pb-5 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                <div className="flex flex-col items-center justify-start w-full xl:flex-row">
                    <div className="flex justify-start items-center flex-grow relative gap-2.5">
                        <div className="  w-10 h-10 relative overflow-hidden rounded-[30.56px]">
                            <img
                                src={avatar}
                                alt="rectangle-1413.png"
                                className="w-10 h-10 absolute left-[-0.44px] top-[-0.44px] object-cover"
                            />
                        </div>
                        <div className="flex flex-col justify-center items-start   relative gap-[5px]">
                            <div className="flex justify-start items-center   relative gap-2.5">
                                <div className="flex justify-start items-center   relative gap-[5px]">
                                    <p className="  text-sm text-left text-[#7b7b7b]">
                                        por
                                    </p>
                                    <p className="  text-sm xl:text-base font-bold text-left text-[#373737]">
                                        Abidemi K.
                                    </p>
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="relative w-6 h-6 "
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                            d="M21.9997 5H2V18.3333H21.9997V5Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M8.66643 5H2V18.3333H8.66643V5Z"
                                            fill="#4997D0"
                                        />
                                        <path
                                            d="M22.0004 5H15.334V18.3333H22.0004V5Z"
                                            fill="#4997D0"
                                        />
                                    </svg>
                                </div>
                                <p className="  text-sm text-left text-[#7b7b7b]">
                                    hace 3 horas
                                </p>
                            </div>
                            <p className="  text-xs text-left text-[#7b7b7b]">
                                Miembro de la comunidad
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start w-full xl:w-auto mt-3 xl:mt-0   gap-[5px]">
                        <div className="flex justify-start items-start   relative gap-[5px]">
                            <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-4 h-4 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M11.6673 4.00001H10.6673V2.91667C10.6673 2.79167 10.5423 2.66667 10.4173 2.66667H9.58398C9.43815 2.66667 9.33398 2.79167 9.33398 2.91667V4.00001H6.66732V2.91667C6.66732 2.79167 6.54232 2.66667 6.41732 2.66667H5.58398C5.43815 2.66667 5.33398 2.79167 5.33398 2.91667V4.00001H4.33398C3.77148 4.00001 3.33398 4.45834 3.33398 5.00001V12.3333C3.33398 12.8958 3.77148 13.3333 4.33398 13.3333H11.6673C12.209 13.3333 12.6673 12.8958 12.6673 12.3333V5.00001C12.6673 4.45834 12.209 4.00001 11.6673 4.00001ZM11.5423 12.3333H4.45898C4.37565 12.3333 4.33398 12.2917 4.33398 12.2083V6H11.6673V12.2083C11.6673 12.2917 11.6048 12.3333 11.5423 12.3333Z"
                                    fill="#A4A4A4"
                                />
                            </svg>
                            <p className="  text-xs text-left text-[#7b7b7b]">
                                14 de agosto de 2023
                            </p>
                        </div>
                        <div className="flex justify-start items-start   relative gap-[5px]">
                            <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-4 h-4 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M8.00065 2.83333C5.14648 2.83333 2.83398 5.14583 2.83398 8C2.83398 10.8542 5.14648 13.1667 8.00065 13.1667C10.8548 13.1667 13.1673 10.8542 13.1673 8C13.1673 5.14583 10.8548 2.83333 8.00065 2.83333ZM8.00065 12.1667C5.68815 12.1667 3.83398 10.3125 3.83398 8C3.83398 5.70833 5.68815 3.83333 8.00065 3.83333C10.2923 3.83333 12.1673 5.70833 12.1673 8C12.1673 10.3125 10.2923 12.1667 8.00065 12.1667ZM9.27148 10C9.39648 10.0833 9.54232 10.0625 9.62565 9.9375L10.0215 9.41666C10.1048 9.29166 10.084 9.14583 9.95898 9.0625L8.58398 8.04166V5.08333C8.58398 4.95833 8.45898 4.83333 8.33398 4.83333H7.66732C7.52148 4.83333 7.41732 4.95833 7.41732 5.08333V8.52083C7.41732 8.58333 7.43815 8.66666 7.50065 8.70833L9.27148 10Z"
                                    fill="#A4A4A4"
                                />
                            </svg>
                            <p className="  text-xs text-left text-[#7b7b7b]">
                                19:09:21
                            </p>
                        </div>
                    </div>
                </div>
                <svg
                    width={24}
                    height={25}
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="relative w-6 h-6 cursor-pointer "
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M12 11C11.1562 11 10.5 11.6875 10.5 12.5C10.5 13.3438 11.1562 14 12 14C12.8125 14 13.5 13.3438 13.5 12.5C13.5 11.6875 12.8125 11 12 11ZM10.5 7.75C10.5 8.59375 11.1562 9.25 12 9.25C12.8125 9.25 13.5 8.59375 13.5 7.75C13.5 6.9375 12.8125 6.25 12 6.25C11.1562 6.25 10.5 6.9375 10.5 7.75ZM10.5 17.25C10.5 18.0938 11.1562 18.75 12 18.75C12.8125 18.75 13.5 18.0938 13.5 17.25C13.5 16.4375 12.8125 15.75 12 15.75C11.1562 15.75 10.5 16.4375 10.5 17.25Z"
                        fill="#333333"
                    />
                </svg>
            </div>
            <div className="flex flex-col items-start self-stretch justify-start gap-5">
                <div className="relative flex flex-col items-start self-stretch justify-start gap-1">
                    <p className="self-stretch   xl:w-[776px] text-sm font-bold text-left text-[#373737]">
                        Artículo:
                    </p>
                    <div
                        className={`flex justify-start items-start self-stretch   relative gap-2.5 ${
                            hover ? "bg-white" : "bg-[#fff5f7]"
                        } `}
                    >
                        <p className="flex-grow xl:w-[776px] text-sm xl:text-base font-bold text-left text-[#373737]">
                            Lorem ipsum dolor sit amet consectetur. Pretium
                            cursus mattis rutrum magna morbi vitae lacinia odio
                            in. Diam auctor integer leo ligula in bibendum vitae
                            egestas. Quis neque elit facilisis et aliquam orci
                            volutpat. Amet mi arcu at cras.
                        </p>
                    </div>
                </div>
                <div className="relative flex flex-col items-start self-stretch justify-start gap-1">
                    <p className="self-stretch   xl:w-[776px] text-sm font-bold text-left text-[#373737]">
                        Comentario:
                    </p>
                    <p className="self-stretch   xl:w-[776px] text-sm xl:text-base text-left text-[#7b7b7b]">
                        Lorem ipsum dolor sit amet consectetur. Pretium cursus
                        mattis rutrum magna morbi vitae lacinia odio in. Diam
                        auctor integer leo ligula in bibendum vitae egestas.
                        Quis neque elit facilisis et aliquam orci volutpat. Amet
                        mi arcu at cras.
                    </p>
                </div>
            </div>
            <div className="flex justify-end items-center self-stretch   gap-2.5">
                <div className="flex justify-start items-center flex-grow relative gap-2.5">
                    <Thumb
                        textClass={
                            "text-sm xl:text-base font-bold text-left text-[#373737]"
                        }
                        selected={liked}
                        count={liked ? 3 : 2}
                        onClick={handleClick}
                    />
                    <span className="  text-sm xl:text-base font-bold text-left text-[#373737]">
                        votos a favor
                    </span>
                </div>
                <Button
                    color={COLOR.WHITE}
                    size={SIZE.SMALL}
                    text="Responder"
                    iconName="arrow"
                    // getIcon={<ArrowIcon />}
                    // className="flex justify-center items-center text-sm text-left text-[#373737]   w-[141px] h-9 relative gap-2.5 px-4 py-2.5 rounded border-2 border-[#292929]"
                />
            </div>
        </div>
    );
}

export default CommunityBlogPost;
