import { useFormik } from "formik";
import { useState, useEffect } from "react";
import FormValidation from "../form/FormValidation";
import Input from "../ui/Input";
import { Button } from "../ui/buttons/Button";
import Swal from "sweetalert2";

// /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!])(?=\S+$).{8,}$/;

function LoginStep4({ navigateNext }) {
  // showDialog

  // const handleChange = useCallback(
  //     (target = {}) => {
  //         let { name, value } = target;
  //         console.log(target);
  //         setFormData((data) => ({ ...data, ...{ [name]: value } }));
  //         console.log(formData);
  //     },
  //     [formData]
  // );

  // const handleFormSubmission = useCallback((e) => {
  //     // submit formData
  //     e.preventDefault();
  //     setDialog(1);
  // }, []);
  const [storedCorreo, setStoredCorreo] = useState(
    localStorage.getItem("storedCorreo") || ""
  );
  const [inputType, setInputType] = useState("password");
  useEffect(() => {
    const storedValue = localStorage.getItem("storedCorreo") || "";
    setStoredCorreo(storedValue);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: storedCorreo || "",
      temporary_key: "",
      new_password: "",
      confirm_password: ""
    },
    validate: (values) => {
        const errors = {};
        if (values.new_password !== values.confirm_password) {
            errors.confirm_password = "Las contraseñas no coinciden";
        }
        const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
        if (!passwordRegex.test(values.new_password)) {
            errors.new_password = "La contraseña debe tener al menos 8 caracteres, una mayúscula y un número";
        }

        return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await fetch(
          "https://eprof-production-6g7zxvibga-uc.a.run.app/reset_password/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: values.email,
              temporary_key: values.temporary_key,
              new_password: values.new_password,
            }),
          }
        );

        if (response.ok) {
          await Swal.fire({
            title: "Cambio de clave exitoso",
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          navigateNext();
        }
        else{ 
            const responseBody = await response.json();
            const errorMessage = responseBody.error; 
            Swal.fire({
                title: "Error al cambiar la clave",
                icon: "error",
                text: `Ha ocurrido un error: ${errorMessage}`,
                timer: 2000,
                showConfirmButton: false,
              });
        }

        
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col justify-start items-start mt-20 mx-2 xl:m-0 xl:w-[28vw] gap-8 p-[30px] rounded-[20px] bg-white"
        // className="flex flex-col justify-start items-start w-[456px] relative gap-8 p-[30px] rounded-[20px] bg-white"
        style={{ boxShadow: "0px 8px 16px 0 rgba(0,0,0,0.1)" }}
      >
        <p className=" w-[402px] text-[34px] text-left text-[#373737]">
          Crea tu nueva contraseña
        </p>
        <div style={{ display:"none" }}>
            <Input
          label="Correo electrónico"
          placeholder="Correo electrónico"
          // onChange={formik.handleChange}
          // value={formik.values.email}
          // required
          value={formik.values.email}
          onChange={formik.handleChange}
          disabled
          name="email"
          type="text"
        />
        {<FormValidation formik={formik} name="email" />}
        </div>
        
        <Input
          label="Contraseña Temporal"
          placeholder="Contraseña Temporal"
          required
          onChange={formik.handleChange}
          value={formik.values.temporary_key}
          name="temporary_key"
          type={inputType}
          onIconClick={() =>
              setInputType((type) =>
                  type === "password" ? "text" : "password"
              )
          }
          icon={
              <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative w-6 h-6"
                  preserveAspectRatio="xMidYMid meet"
              >
                  <path
                      d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                      fill="#A4A4A4"
                  />
              </svg>
          }
      />
        {<FormValidation formik={formik} name="temporary_key" />}
        <Input
          label="Nueva Contraseña"
          placeholder="Contraseña"
          required
          onChange={formik.handleChange}
          value={formik.values.new_password}
          name="new_password"
          type={inputType}
          onIconClick={() =>
              setInputType((type) =>
                  type === "password" ? "text" : "password"
              )
          }
          icon={
              <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative w-6 h-6"
                  preserveAspectRatio="xMidYMid meet"
              >
                  <path
                      d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                      fill="#A4A4A4"
                  />
              </svg>
          }
      />
        {formik.errors.new_password && formik.touched.new_password && (
                <p className="text-red-500">{formik.errors.new_password}</p>
            )}
        <Input
          label="Confirmar Contraseña"
          placeholder="Contraseña"
          required
          onChange={formik.handleChange}
          value={formik.values.confirm_password}
          name="confirm_password"
          type={inputType}
          onIconClick={() =>
              setInputType((type) =>
                  type === "password" ? "text" : "password"
              )
          }
          icon={
              <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative w-6 h-6"
                  preserveAspectRatio="xMidYMid meet"
              >
                  <path
                      d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                      fill="#A4A4A4"
                  />
              </svg>
          }
      />
      {formik.errors.confirm_password && formik.touched.confirm_password && (
                <p className="text-red-500">{formik.errors.confirm_password}</p>
            )}
        <Button
          // onClick={() =>
          //     formik.isValid
          //         ? () => setDialog(1)
          //         : () => console.log("invalid")
          // }
          stretch
          type="submit"
          // className="flex justify-center items-center self-stretch  h-11 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]"
        >
          Continuar
        </Button>
      </form>
    </>
  );
}

export default LoginStep4;
