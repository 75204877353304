import React, { useState } from "react";
import Input from "../../../components/ui/Input";
import Pagination from "../../../components/Pagination";
import { Link } from "react-router-dom";

const TableSupport = ({ tableData }) => {
    const [count, setCount] = useState(10);

    return (
        <div className="flex flex-col w-full  gap-[30px] p-4 border border-[#cdcdcd] ">
            <div className="flex flex-col justify-center items-center overflow-x-auto">
                <div className="flex  items-center w-full relative  md:gap-6 px-4  bg-[#f2f2f2] ">
                    <p className=" self-stretch basis-[152px] text-sm font-bold text-left text-[#373737] py-3 px-3 md:px-0 bg-[#f2f2f2]">
                        Referencia
                    </p>
                    <p className="flex-grow self-stretch basis-[363px] text-sm font-bold text-left text-[#373737] py-3 px-3 md:px-0 bg-[#f2f2f2]">
                        Asunto
                    </p>
                    <p className=" self-stretch basis-[131px] text-sm font-bold text-left text-[#373737] py-3 px-3 md:px-0 bg-[#f2f2f2]">
                        Departamento
                    </p>
                    <p className=" self-stretch basis-[162px] text-sm font-bold text-left text-[#373737] py-3 px-3 md:px-0 bg-[#f2f2f2]">
                        Fecha de creación
                    </p>
                    <p className=" self-stretch basis-52 text-sm font-bold text-right text-[#373737] py-3 px-3 md:px-0 bg-[#f2f2f2]">
                        Última acción
                    </p>
                </div>

                {tableData.map((item, index) => (
                    <Link to={"/platform/support/ticket-history/" + item.id}  className="w-full">
                        <div
                            className="flex justify-start self-stretch  relative md:gap-6 px-4  hover:bg-[#ffecef] cursor-pointer  border-b  border-[#cdcdcd] "
                            key={index}
                        >
                            <p className=" basis-[152px] text-sm text-left text-[#373737] py-3 px-3 md:px-0">
                                {item.id}
                            </p>
                            <p className="flex-grow basis-[363px] text-sm text-left text-[#373737] py-3 px-3 md:px-0">
                                {item.message}
                            </p>
                            <p className=" basis-[131px] text-sm text-left text-[#373737] py-3 px-3 md:px-0">
                                {item.category}
                            </p>
                            <p className=" basis-[162px] text-sm text-left text-[#373737] py-3 px-3 md:px-0">
                                {item.timestamp}
                            </p>
                            <div className="flex flex-col justify-center items-end  basis-52 relative gap-1 py-3 px-3 md:px-0">
                                <p className="self-stretch  w-full text-sm font-bold text-right text-[#373737] ">
                                    {item.support.name}
                                </p>
                                <p className="self-stretch  w-full text-sm text-right text-[#373737]">
                                    {item.support.timestamp}
                                </p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>

            <div className="flex flex-col sm:flex-row w-full gap-y-5">
                <div className="flex items-center gap-2.5  w-full">
                    <p className=" text-base text-left text-[#373737]">
                        Postulaciones por página:
                    </p>

                    <div className="flex flex-col justify-center items-start  gap-[5px]">
                        <Input
                            name="order"
                            value={count}
                            onChange={(e) => setCount(e.target.value)}
                            type="dropdown"
                            // placeholder="Order"
                            selectList={["10", "50", "100"]}
                            inputClassName={
                                // py-2.5
                                " text-base w-[100px] text-left text-[#373737] flex  items-center  self-stretch  gap-2.5 px-3 py-1.5 rounded h-9 bg-white border-t border-r border-b border-l border-[#292929]"
                            }
                        />
                    </div>
                </div>

                <Pagination />
            </div>
        </div>
    );
};

export default TableSupport;
