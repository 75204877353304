import React from "react";
import { Link } from "react-router-dom";

function HeaderLogo() {
    return (
        <div className="flex flex-col justify-start items-center flex-grow gap-2.5 pr-[95px] mt-10 sm:m-0">
            <Link to="/">
                <div className="flex justify-start items-center flex-grow-0  relative gap-[13.823529243469238px]">
                    <svg
                        width={46}
                        height={48}
                        viewBox="0 0 46 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-grow-0 shrink-0  w-[45.75px] h-[46.82px]"
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M45.26 47.2326L0.919747 47.4084C0.734088 47.4084 0.556035 47.3346 0.424755 47.2033C0.293475 47.0721 0.219727 46.894 0.219727 46.7083C0.219727 46.5227 0.293475 46.3446 0.424755 46.2134C0.556035 46.0821 0.734088 46.0083 0.919747 46.0083L45.26 45.8325C45.3522 45.8321 45.4435 45.8499 45.5288 45.8849C45.6141 45.9199 45.6917 45.9714 45.757 46.0365C45.8223 46.1015 45.8742 46.1788 45.9096 46.2639C45.9449 46.3491 45.9632 46.4404 45.9632 46.5326C45.9632 46.6247 45.9449 46.716 45.9096 46.8012C45.8742 46.8863 45.8223 46.9636 45.757 47.0287C45.6917 47.0937 45.6141 47.1452 45.5288 47.1802C45.4435 47.2152 45.3522 47.233 45.26 47.2326Z"
                            fill="#D80027"
                        />
                        <path
                            d="M21.2203 16.9964C21.0347 16.9964 20.8566 16.9227 20.7253 16.7914C20.5941 16.6601 20.5203 16.4821 20.5203 16.2964C20.5203 12.6864 19.5409 10.2504 17.444 8.6275C17.3709 8.57149 17.3097 8.50163 17.2636 8.42191C17.2176 8.34219 17.1878 8.25418 17.1759 8.16292C17.1639 8.07165 17.1701 7.97893 17.194 7.89005C17.2179 7.80117 17.2591 7.71788 17.3153 7.64495C17.3715 7.57169 17.4417 7.5103 17.5218 7.46431C17.6019 7.41831 17.6903 7.38862 17.782 7.37695C17.8736 7.36528 17.9666 7.37186 18.0557 7.3963C18.1448 7.42075 18.2281 7.46258 18.301 7.51938C20.7369 9.40286 21.9204 12.272 21.9204 16.309C21.9171 16.4924 21.8419 16.6673 21.7109 16.7958C21.58 16.9244 21.4038 16.9965 21.2203 16.9964Z"
                            fill="#D80027"
                        />
                        <path
                            d="M25.292 16.9963C25.1998 16.9967 25.1085 16.9789 25.0232 16.9439C24.9379 16.9089 24.8603 16.8574 24.795 16.7924C24.7297 16.7273 24.6778 16.65 24.6424 16.5649C24.6071 16.4797 24.5889 16.3884 24.5889 16.2963C24.5889 12.2719 25.7755 9.39016 28.2114 7.50668C28.284 7.44996 28.3671 7.40819 28.4559 7.38379C28.5447 7.35938 28.6375 7.35283 28.7289 7.36451C28.8203 7.37619 28.9084 7.40586 28.9882 7.45181C29.0681 7.49777 29.138 7.5591 29.194 7.63225C29.2507 7.70482 29.2925 7.78792 29.3169 7.87674C29.3413 7.96557 29.3478 8.05834 29.3361 8.14971C29.3245 8.24108 29.2948 8.32923 29.2488 8.40906C29.2029 8.48889 29.1415 8.55882 29.0684 8.6148C26.9683 10.2377 25.992 12.6737 25.992 16.2837C25.9937 16.3767 25.9768 16.4691 25.9424 16.5554C25.908 16.6418 25.8567 16.7205 25.7915 16.7868C25.7263 16.8532 25.6486 16.9059 25.5629 16.9418C25.4771 16.9778 25.385 16.9963 25.292 16.9963Z"
                            fill="#D80027"
                        />
                        <path
                            d="M37.9741 7.37207C35.6085 7.37539 33.3408 8.31682 31.6683 9.98987C29.9959 11.6629 29.0552 13.931 29.0527 16.2966C29.0527 16.4823 29.1265 16.6603 29.2578 16.7916C29.389 16.9229 29.5671 16.9966 29.7528 16.9966C29.8447 16.9966 29.9357 16.9785 30.0206 16.9434C30.1056 16.9082 30.1827 16.8566 30.2477 16.7916C30.3128 16.7266 30.3643 16.6494 30.3995 16.5645C30.4347 16.4796 30.4528 16.3886 30.4528 16.2966C30.4528 14.2985 31.2465 12.3822 32.6594 10.9693C34.0723 9.55646 35.9886 8.76271 37.9867 8.76271C38.7592 8.76477 39.5238 8.91896 40.2367 9.2165C40.9497 9.51404 41.597 9.94909 42.1418 10.4968C42.6866 11.0445 43.1182 11.6942 43.412 12.4087C43.7057 13.1232 43.8558 13.8886 43.8538 14.6611C43.8496 15.8681 43.3679 17.0244 42.5138 17.8773C41.6597 18.7302 40.5027 19.2104 39.2957 19.2129C38.3652 19.212 37.473 18.842 36.815 18.184C36.157 17.526 35.787 16.6339 35.7862 15.7033C35.787 14.9967 36.0681 14.3193 36.5677 13.8197C37.0673 13.3201 37.7447 13.039 38.4513 13.0382C38.9429 13.0385 39.4171 13.2208 39.7823 13.5499C39.459 13.6505 39.1763 13.8519 38.9758 14.1248C38.7753 14.3976 38.6674 14.7275 38.6679 15.0661C38.6679 15.4874 38.8353 15.8914 39.1331 16.1893C39.431 16.4871 39.835 16.6545 40.2563 16.6545C41.129 16.6545 41.8416 16.0267 41.8416 14.8589C41.8416 12.9189 40.3254 11.635 38.4513 11.635C37.369 11.635 36.331 12.065 35.5657 12.8303C34.8004 13.5956 34.3704 14.6336 34.3704 15.7159C34.3729 17.0199 34.8931 18.2696 35.8167 19.1903C36.7403 20.1109 37.9917 20.6272 39.2957 20.6255C40.8776 20.6255 42.3946 19.9971 43.5132 18.8786C44.6317 17.76 45.2601 16.243 45.2601 14.6611C45.2605 13.7041 45.0723 12.7563 44.7064 11.872C44.3404 10.9876 43.8038 10.1841 43.1272 9.50718C42.4506 8.83029 41.6472 8.29333 40.7631 7.92698C39.8789 7.56063 38.9312 7.37207 37.9741 7.37207Z"
                            fill="#D80027"
                        />
                        <path
                            d="M7.84166 7.37207C10.2078 7.37456 12.4764 8.31563 14.1495 9.98876C15.8227 11.6619 16.7637 13.9305 16.7662 16.2966C16.7662 16.4823 16.6925 16.6603 16.5612 16.7916C16.4299 16.9229 16.2518 16.9967 16.0662 16.9967C15.8805 16.9967 15.7025 16.9229 15.5712 16.7916C15.4399 16.6603 15.3662 16.4823 15.3662 16.2966C15.3662 14.2985 14.5724 12.3822 13.1595 10.9693C11.7466 9.55646 9.83037 8.76271 7.83225 8.76271C7.05972 8.76477 6.29515 8.91896 5.58221 9.2165C4.86927 9.51404 4.22192 9.94909 3.67712 10.4968C3.13231 11.0445 2.70073 11.6942 2.407 12.4087C2.11327 13.1232 1.96315 13.8886 1.96521 14.6611C1.96687 15.8703 2.44794 17.0295 3.30297 17.8845C4.15799 18.7396 5.31718 19.2206 6.52636 19.2223C7.4569 19.2215 8.34908 18.8514 9.00707 18.1935C9.66506 17.5355 10.0351 16.6433 10.0359 15.7127C10.0351 15.0056 9.7536 14.3277 9.25329 13.828C8.75299 13.3283 8.07477 13.0476 7.36765 13.0476C6.87693 13.0478 6.40376 13.2302 6.0398 13.5593C6.36313 13.6599 6.64576 13.8613 6.84629 14.1342C7.04683 14.407 7.15472 14.7369 7.15419 15.0755C7.15336 15.4965 6.98576 15.9 6.68805 16.1978C6.39035 16.4955 5.9868 16.6631 5.56579 16.6639C4.69311 16.6639 3.97739 16.0361 3.97739 14.8683C3.97739 12.9283 5.49673 11.6444 7.36765 11.6444C8.44996 11.6444 9.48795 12.0744 10.2533 12.8397C11.0186 13.605 11.4485 14.643 11.4485 15.7253C11.4435 17.0272 10.9227 18.274 10 19.1925C9.07739 20.111 7.82826 20.6263 6.52636 20.6255C4.94452 20.6255 3.42747 19.9971 2.30894 18.8786C1.19041 17.76 0.562012 16.243 0.562012 14.6611C0.56201 12.7296 1.32868 10.877 2.69361 9.51032C4.05854 8.14362 5.91011 7.37457 7.84166 7.37207Z"
                            fill="#D80027"
                        />
                        <path
                            d="M32.2705 5.38809H13.555C13.3693 5.38809 13.1913 5.31434 13.06 5.18306C12.9287 5.05178 12.855 4.87372 12.855 4.68806C12.855 4.59613 12.8731 4.50511 12.9083 4.42018C12.9434 4.33524 12.995 4.25807 13.06 4.19307C13.125 4.12807 13.2022 4.0765 13.2871 4.04133C13.372 4.00615 13.4631 3.98804 13.555 3.98804H32.2768C32.4624 3.98804 32.6405 4.06179 32.7718 4.19307C32.9031 4.32435 32.9768 4.5024 32.9768 4.68806C32.9768 4.78052 32.9585 4.87207 32.9229 4.95741C32.8874 5.04275 32.8352 5.1202 32.7696 5.18529C32.7039 5.25038 32.626 5.30181 32.5403 5.33661C32.4547 5.37142 32.363 5.38892 32.2705 5.38809Z"
                            fill="#D80027"
                        />
                        <path
                            d="M10.3627 5.38815C9.72684 5.38732 9.1173 5.13438 8.66771 4.68479C8.21812 4.2352 7.96519 3.62567 7.96436 2.98985C7.96519 2.35404 8.21812 1.7445 8.66771 1.29492C9.1173 0.845328 9.72684 0.592383 10.3627 0.591553H35.4569C36.0927 0.592383 36.7022 0.845328 37.1518 1.29492C37.6014 1.7445 37.8543 2.35404 37.8552 2.98985C37.8543 3.62567 37.6014 4.2352 37.1518 4.68479C36.7022 5.13438 36.0927 5.38732 35.4569 5.38815C35.3647 5.38856 35.2733 5.37076 35.188 5.33577C35.1027 5.30077 35.0252 5.24927 34.9599 5.18423C34.8945 5.11918 34.8427 5.04187 34.8073 4.95674C34.7719 4.8716 34.7537 4.78031 34.7537 4.68812C34.7537 4.59593 34.7719 4.50464 34.8073 4.41951C34.8427 4.33437 34.8945 4.25706 34.9599 4.19202C35.0252 4.12697 35.1027 4.07547 35.188 4.04048C35.2733 4.00548 35.3647 3.98768 35.4569 3.9881C35.7211 3.98726 35.9742 3.88173 36.1607 3.69461C36.3472 3.50749 36.452 3.25406 36.452 2.98985C36.452 2.72565 36.3472 2.47221 36.1607 2.2851C35.9742 2.09798 35.7211 1.99244 35.4569 1.99161H10.3627C10.0985 1.99244 9.84535 2.09798 9.65882 2.2851C9.4723 2.47221 9.36754 2.72565 9.36754 2.98985C9.36754 3.25406 9.4723 3.50749 9.65882 3.69461C9.84535 3.88173 10.0985 3.98726 10.3627 3.9881C10.4548 3.98768 10.5462 4.00548 10.6315 4.04048C10.7168 4.07547 10.7943 4.12697 10.8597 4.19202C10.925 4.25706 10.9769 4.33437 11.0122 4.41951C11.0476 4.50464 11.0658 4.59593 11.0658 4.68812C11.0658 4.78031 11.0476 4.8716 11.0122 4.95674C10.9769 5.04187 10.925 5.11918 10.8597 5.18423C10.7943 5.24927 10.7168 5.30077 10.6315 5.33577C10.5462 5.37076 10.4548 5.38856 10.3627 5.38815Z"
                            fill="#D80027"
                        />
                        <path
                            d="M33.9059 21.1278C33.8476 21.1282 33.7896 21.1208 33.7333 21.1058C26.6279 19.2861 19.1786 19.2861 12.0732 21.1058C11.9842 21.129 11.8915 21.1343 11.8003 21.1216C11.7092 21.1089 11.6215 21.0783 11.5422 21.0317C11.4629 20.985 11.3936 20.9232 11.3382 20.8498C11.2828 20.7763 11.2424 20.6926 11.2194 20.6036C11.1736 20.4233 11.2011 20.2323 11.2958 20.0723C11.3905 19.9123 11.5448 19.7963 11.7248 19.7497C19.0546 17.8725 26.7393 17.8725 34.0691 19.7497C34.1582 19.7727 34.2419 19.8131 34.3153 19.8685C34.3888 19.9239 34.4506 19.9932 34.4973 20.0725C34.5439 20.1518 34.5745 20.2395 34.5872 20.3307C34.5999 20.4218 34.5945 20.5145 34.5714 20.6036C34.5335 20.7519 34.4477 20.8837 34.3275 20.9784C34.2072 21.0732 34.059 21.1257 33.9059 21.1278Z"
                            fill="#D80027"
                        />
                        <path
                            d="M17.4315 41.4662C17.3393 41.4662 17.248 41.4479 17.1629 41.4126C17.0777 41.3772 17.0004 41.3253 16.9354 41.26C16.8703 41.1947 16.8188 41.1171 16.7838 41.0318C16.7488 40.9465 16.731 40.8552 16.7315 40.763V25.6951C16.7315 25.6032 16.7496 25.5122 16.7847 25.4273C16.8199 25.3423 16.8715 25.2652 16.9365 25.2002C17.0015 25.1352 17.0787 25.0836 17.1636 25.0484C17.2485 25.0132 17.3395 24.9951 17.4315 24.9951C17.5237 24.9947 17.615 25.0125 17.7003 25.0475C17.7856 25.0825 17.8632 25.134 17.9285 25.199C17.9938 25.2641 18.0457 25.3414 18.0811 25.4265C18.1164 25.5117 18.1347 25.603 18.1347 25.6951V40.763C18.1347 40.9495 18.0606 41.1283 17.9287 41.2602C17.7968 41.3921 17.618 41.4662 17.4315 41.4662Z"
                            fill="#D80027"
                        />
                        <path
                            d="M22.9095 41.4658C22.8173 41.4658 22.726 41.4476 22.6409 41.4123C22.5557 41.3769 22.4784 41.325 22.4134 41.2597C22.3484 41.1944 22.2969 41.1168 22.2619 41.0315C22.2269 40.9462 22.2091 40.8549 22.2095 40.7627V24.9603C22.2095 24.7746 22.2832 24.5966 22.4145 24.4653C22.5458 24.334 22.7238 24.2603 22.9095 24.2603C23.0952 24.2603 23.2732 24.334 23.4045 24.4653C23.5358 24.5966 23.6095 24.7746 23.6095 24.9603V40.7627C23.61 40.8549 23.5922 40.9462 23.5572 41.0315C23.5222 41.1168 23.4706 41.1944 23.4056 41.2597C23.3406 41.325 23.2633 41.3769 23.1781 41.4123C23.093 41.4476 23.0017 41.4658 22.9095 41.4658Z"
                            fill="#D80027"
                        />
                        <path
                            d="M28.3872 41.4662C28.2008 41.4662 28.0219 41.3921 27.89 41.2602C27.7582 41.1283 27.6841 40.9495 27.6841 40.763V25.6951C27.6841 25.603 27.7023 25.5117 27.7377 25.4265C27.773 25.3414 27.8249 25.2641 27.8902 25.199C27.9556 25.134 28.0331 25.0825 28.1184 25.0475C28.2037 25.0125 28.2951 24.9947 28.3872 24.9951C28.5729 24.9951 28.751 25.0689 28.8822 25.2002C29.0135 25.3314 29.0873 25.5095 29.0873 25.6951V40.763C29.0877 40.8552 29.0699 40.9465 29.0349 41.0318C28.9999 41.1171 28.9484 41.1947 28.8833 41.26C28.8183 41.3253 28.741 41.3772 28.6559 41.4126C28.5707 41.4479 28.4794 41.4662 28.3872 41.4662Z"
                            fill="#D80027"
                        />
                        <path
                            d="M44.9052 41.4662H33.2057V23.8871C26.4396 22.2453 19.379 22.2453 12.613 23.8871V41.4662H0.913406C0.821213 41.4662 0.729942 41.448 0.644806 41.4126C0.559669 41.3772 0.482348 41.3254 0.417303 41.2601C0.352258 41.1947 0.300763 41.1172 0.265769 41.0319C0.230774 40.9466 0.212973 40.8552 0.213386 40.763C0.213386 40.5774 0.287134 40.3993 0.418414 40.268C0.549695 40.1368 0.727748 40.063 0.913406 40.063H11.2098V22.7978L11.7371 22.6628C19.067 20.7856 26.7517 20.7856 34.0815 22.6628L34.6089 22.7978V40.063H44.9052C45.0909 40.063 45.2689 40.1368 45.4002 40.268C45.5315 40.3993 45.6052 40.5774 45.6052 40.763C45.6056 40.8552 45.5878 40.9466 45.5528 41.0319C45.5179 41.1172 45.4664 41.1947 45.4013 41.2601C45.3363 41.3254 45.259 41.3772 45.1738 41.4126C45.0887 41.448 44.9974 41.4662 44.9052 41.4662Z"
                            fill="#D80027"
                        />
                    </svg>
                    <p className="font-[Enriqueta] shrink-0  flex-grow-0  text-[34.703853607177734px] text-left text-[#373737] ">
                        Legal EPROF
                    </p>
                </div>
            </Link>
        </div>
    );
}

export default HeaderLogo;
