import {
    add,
    eachDayOfInterval,
    eachMonthOfInterval,
    eachYearOfInterval,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    isSameMonth,
    isToday,
    parse,
    startOfToday,
    startOfWeek
} from "date-fns";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import Dialog from "../components/Dialog";
import CreateEvent from "../components/modal/AjantaModals/CreateEvent";
import Calender from "../components/ui/Calender";
import Input from "../components/ui/Input";

let years = eachYearOfInterval({
    start: endOfYear(new Date()),
    end: add(endOfYear(new Date()), { years: 5 })
}).map((year) => format(year, "yyyy"));

const months = eachMonthOfInterval({
    start: new Date(2014, 0, 6),
    end: new Date(2014, 11, 10)
}).map((month) => format(month, "MMM"));

const Ajanta = () => {
    const [showDialog, setShowDialog] = useState(false);

    console.log(months);

    // console.log(years)

    return (
        <div className="flex flex-col md:flex-row w-full max-w-[1415px] mt-20 mx-auto">
            <Dialog
                main={<CreateEvent closeDialog={() => setShowDialog(false)} />}
                // main={<CreateEventColor closeDialog={() => setShowDialog(false)} />}
                // main={<Presencial closeDialog={()=>setShowDialog(false)} eventType={"Presencial"}/>}
                showDialog={showDialog}
                setDialog={setShowDialog}
            />

            {/* sidebar */}
            <div className="flex flex-col  flex-grow-0 flex-shrink-0 gap-3 px-4 pt-[30px] border border-[#cdcdcd] ">
                <div className="flex flex-col    gap-3 pb-3  border-b  border-[#cdcdcd]">
                    <div
                        className="flex relative gap-1 items-center"
                        onClick={() => setShowDialog(true)}
                    >
                        <div className=" p-1.5 bg-[#F7CCD4]">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <rect
                                    width="24"
                                    height="24"
                                    fill="#F7CCD4"
                                ></rect>
                                <path
                                    d="M17.5 5H6.5C5.65625 5 5 5.6875 5 6.5V17.5C5 18.3438 5.65625 19 6.5 19H17.5C18.3125 19 19 18.3438 19 17.5V6.5C19 5.6875 18.3125 5 17.5 5ZM17.3125 17.5H6.6875C6.5625 17.5 6.5 17.4375 6.5 17.3125V6.6875C6.5 6.59375 6.5625 6.5 6.6875 6.5H17.3125C17.4062 6.5 17.5 6.59375 17.5 6.6875V17.3125C17.5 17.4375 17.4062 17.5 17.3125 17.5Z"
                                    fill="#292929"
                                ></path>
                            </svg>
                        </div>
                        <p className="flex-grow-0 flex-shrink-0 text-base text-left text-black">
                            Tareas
                        </p>
                    </div>
                    <div className="flex relative gap-1 items-center">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <rect width="24" height="24" fill="#B8CEFF"></rect>
                            <path
                                d="M17.5 5H6.5C5.65625 5 5 5.6875 5 6.5V17.5C5 18.3438 5.65625 19 6.5 19H17.5C18.3125 19 19 18.3438 19 17.5V6.5C19 5.6875 18.3125 5 17.5 5ZM17.3125 17.5H6.6875C6.5625 17.5 6.5 17.4375 6.5 17.3125V6.6875C6.5 6.59375 6.5625 6.5 6.6875 6.5H17.3125C17.4062 6.5 17.5 6.59375 17.5 6.6875V17.3125C17.5 17.4375 17.4062 17.5 17.3125 17.5Z"
                                fill="#292929"
                            ></path>
                        </svg>
                        <p className="flex-grow-0 flex-shrink-0 text-base text-left text-black">
                            Video conferencia
                        </p>
                    </div>
                    <div className="flex relative gap-1 items-center">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <rect width="24" height="24" fill="#FFEF9E"></rect>
                            <path
                                d="M17.5 5H6.5C5.65625 5 5 5.6875 5 6.5V17.5C5 18.3438 5.65625 19 6.5 19H17.5C18.3125 19 19 18.3438 19 17.5V6.5C19 5.6875 18.3125 5 17.5 5ZM17.3125 17.5H6.6875C6.5625 17.5 6.5 17.4375 6.5 17.3125V6.6875C6.5 6.59375 6.5625 6.5 6.6875 6.5H17.3125C17.4062 6.5 17.5 6.59375 17.5 6.6875V17.3125C17.5 17.4375 17.4062 17.5 17.3125 17.5Z"
                                fill="#292929"
                            ></path>
                        </svg>
                        <p className="flex-grow-0 flex-shrink-0 text-base text-left text-black">
                            Presencial
                        </p>
                    </div>
                    <div className="flex relative gap-1 items-center">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <rect width="24" height="24" fill="#CCF2DC"></rect>
                            <path
                                d="M17.5 5H6.5C5.65625 5 5 5.6875 5 6.5V17.5C5 18.3438 5.65625 19 6.5 19H17.5C18.3125 19 19 18.3438 19 17.5V6.5C19 5.6875 18.3125 5 17.5 5ZM17.3125 17.5H6.6875C6.5625 17.5 6.5 17.4375 6.5 17.3125V6.6875C6.5 6.59375 6.5625 6.5 6.6875 6.5H17.3125C17.4062 6.5 17.5 6.59375 17.5 6.6875V17.3125C17.5 17.4375 17.4062 17.5 17.3125 17.5Z"
                                fill="#292929"
                            ></path>
                        </svg>
                        <p className="flex-grow-0 flex-shrink-0 text-base text-left text-black">
                            Personal
                        </p>
                    </div>
                </div>

                <div className="flex relative gap-3">
                    <div className="flex flex-col  flex-grow gap-3 pb-3 border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                        <div className="flex relative flex-grow-0 flex-shrink-0 gap-3 justify-start items-center self-stretch">
                            <p className="flex-grow w-[248px] text-base font-bold text-left text-[#373737]">
                                Datos del evento / reunión
                            </p>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M15.5625 14.7812C15.5 14.8438 15.5 14.9062 15.5 14.9688V18.5H4.5V7.5H12.0312C12.0938 7.5 12.1562 7.5 12.2188 7.4375L13.2188 6.4375C13.375 6.28125 13.25 6 13.0312 6H4.5C3.65625 6 3 6.6875 3 7.5V18.5C3 19.3438 3.65625 20 4.5 20H15.5C16.3125 20 17 19.3438 17 18.5V13.9688C17 13.75 16.7188 13.625 16.5625 13.7812L15.5625 14.7812ZM20.4375 8.5C21.1562 7.78125 21.1562 6.625 20.4375 5.90625L19.0938 4.5625C18.375 3.84375 17.2188 3.84375 16.5 4.5625L8.3125 12.75L8 15.5938C7.90625 16.4062 8.59375 17.0938 9.40625 17L12.25 16.6875L20.4375 8.5ZM17.375 9.4375L11.5625 15.25L9.5 15.5L9.75 13.4375L15.5625 7.625L17.375 9.4375ZM19.375 6.96875C19.5312 7.09375 19.5312 7.28125 19.4062 7.4375L18.4375 8.40625L16.625 6.5625L17.5625 5.625C17.6875 5.46875 17.9062 5.46875 18.0312 5.625L19.375 6.96875Z"
                                    fill="#D80027"
                                ></path>
                            </svg>
                        </div>
                        <div className="flex flex-col  self-stretch flex-grow-0 flex-shrink-0 relative gap-0.5">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm font-bold text-left text-[#373737]">
                                Titulo
                            </p>
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm text-left text-[#373737]">
                                Lorem ipsum
                            </p>
                        </div>
                        <div className="flex flex-col  self-stretch flex-grow-0 flex-shrink-0 relative gap-0.5">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm font-bold text-left text-[#373737]">
                                Comienza
                            </p>
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm text-left text-[#373737]">
                                07/19/2023 - 1:15 pm
                            </p>
                        </div>
                        <div className="flex flex-col  self-stretch flex-grow-0 flex-shrink-0 relative gap-0.5">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm font-bold text-left text-[#373737]">
                                Termina
                            </p>
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm text-left text-[#373737]">
                                07/19/2023 - 1:15 pm
                            </p>
                        </div>
                        <div className="flex flex-col  self-stretch flex-grow-0 flex-shrink-0 relative gap-0.5">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm font-bold text-left text-[#373737]">
                                Descripción
                            </p>
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm text-left text-[#373737]">
                                Lorem ipsum dolor sit amet consectetur. Tortor
                                lobortis praesent a id congue nulla leo. Nec
                                fermentum porttitor libero vitae. Augue laoreet
                                volutpat eget habitant et blandit lacus enim.
                                Ligula at euismod laoreet mattis non.
                            </p>
                        </div>
                        <div className="flex flex-col  self-stretch flex-grow-0 flex-shrink-0 relative gap-0.5">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm font-bold text-left text-[#373737]">
                                Teléfonos
                            </p>
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-base text-left text-[#7b7b7b]">
                                +34 45656 4566
                            </p>
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-base text-left text-[#7b7b7b]">
                                +34 45656 4566
                            </p>
                        </div>
                        <div className="flex flex-col  self-stretch flex-grow-0 flex-shrink-0 relative gap-0.5">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm font-bold text-left text-[#373737]">
                                Dirección
                            </p>
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[284px] text-sm text-left text-[#373737]">
                                Lorem ipsum dolor sit amet consectetur. Tortor
                                lobortis praesent a id congue nulla leo.
                            </p>
                        </div>
                    </div>
                    <div className="flex-grow-0 flex-shrink-0 w-1 h-[33px] rounded-[100px] bg-[#d9d9d9]"></div>
                </div>

                <SidebarCalander />
            </div>

            {/* main calender */}
            <MainCalender />
        </div>
    );
};

export default Ajanta;

const SidebarCalander = () => {
    const [selectedMonth, setSelectedMonth] = useState(
        format(startOfToday(), "MMM")
    );
    const [selectedYear, setSelectedYear] = useState(years[0]);

    return (
        <div className="flex flex-col w-[300px] rounded-sm">
            <div className="flex gap-2 justify-end items-center self-stretch px-2 py-3 bg-white">
                <Input
                    label=""
                    placeholder=""
                    required
                    onChange={(e) => setSelectedYear(e.target.value)}
                    value={selectedYear}
                    name="year"
                    selectList={years}
                    type="dropdown"
                    addInputClass="w-20 py-0 h-8 text-sm smallIcon_select"
                    addOptionClass="text-sm"
                />

                <Input
                    label=""
                    placeholder=""
                    required
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    value={selectedMonth}
                    name="month"
                    selectList={months}
                    type="dropdown"
                    addInputClass="w-20 py-0 h-8 text-sm smallIcon_select"
                    addOptionClass="text-sm"
                />

                <div className="flex flex-row-reverse   space-x-[-1px] space-x-reverse">
                    <div className="flex   space-x-[-1px]">
                        <div className="flex justify-start items-center  relative overflow-hidden gap-1 px-2 py-px rounded-tr-sm rounded-br-sm bg-white border border-[#d9d9d9]">
                            <p className=" text-sm text-left text-black/[0.85]">
                                Year
                            </p>
                        </div>
                        <div className="flex justify-start items-center  relative overflow-hidden gap-1 px-2 py-px rounded-tl-sm rounded-bl-sm bg-white border border-[#d80027]">
                            <p className=" text-sm text-left text-[#d80027]">
                                Month
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* small calender */}
            <Calender
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
            />
        </div>
    );
};

const MainCalender = () => {
    const [selectedMonth, setSelectedMonth] = useState(
        format(startOfToday(), "MMM")
    );
    const [selectedYear, setSelectedYear] = useState(years[0]);

    let today = startOfToday();
    // eslint-disable-next-line no-unused-vars
    let [selectedDay, setSelectedDay] = useState(today);

    let propDate = `${selectedMonth}-${selectedYear}`;
    console.log(propDate);

    let firstDaySelectedMonth = parse(
        `${selectedMonth}-${selectedYear}`,
        "MMM-yyyy",
        new Date()
    );
    console.log(firstDaySelectedMonth);

    let days = eachDayOfInterval({
        start: startOfWeek(firstDaySelectedMonth),
        end: endOfWeek(endOfMonth(firstDaySelectedMonth))
    });

    return (
        <div className="flex flex-col gap-2.5 p-5 border border-[#cdcdcd] w-full">
            <div className="flex flex-col w-full h-[813px]">
                <div className="flex flex-wrap gap-2 items-center py-3 ml-0 max-w-min md:flex-nowrap md:ml-auto">
                    <div className="flex gap-2">
                        <Input
                            label=""
                            placeholder=""
                            required
                            onChange={(e) => setSelectedYear(e.target.value)}
                            value={selectedYear}
                            name="year"
                            selectList={years}
                            type="dropdown"
                            addInputClass="w-20 py-0 h-8 text-sm smallIcon_select"
                            addOptionClass="text-sm"
                        />
                        <Input
                            label=""
                            placeholder=""
                            required
                            onChange={(e) => setSelectedMonth(e.target.value)}
                            value={selectedMonth}
                            name="month"
                            selectList={months}
                            type="dropdown"
                            addInputClass="w-20 py-0 h-8 text-sm smallIcon_select"
                            addOptionClass="text-sm"
                        />
                    </div>
                    <div className="flex flex-row-reverse   space-x-[-1px] space-x-reverse">
                        <div className="flex   space-x-[-1px]">
                            <div className="flex justify-start items-center  gap-1 rounded-tr-sm rounded-br-sm bg-white border border-[#d9d9d9]">
                                <div className="flex   relative gap-1 px-4 py-[5px]">
                                    <div className=" w-[60px] h-8 opacity-20 rounded-tr-sm rounded-br-sm bg-[#d80027]"></div>
                                    <div className=" w-[60px] h-8 rounded-tr-sm rounded-br-sm bg-white border border-[#d9d9d9]"></div>
                                    <p className=" text-sm text-left text-black/[0.85]">
                                        Year
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-start items-center  relative gap-1 px-4 py-[5px] rounded-tl-sm rounded-bl-sm bg-white border border-[#d80027]">
                                <p className=" text-sm text-left text-[#d80027]">
                                    Month
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-7 text-xs leading-6 text-right">
                    <div>dom</div>
                    <div>lun</div>
                    <div>mar</div>
                    <div>mié</div>
                    <div>jue</div>
                    <div>vie</div>
                    <div>Sáb</div>
                </div>
                <div className="grid grid-cols-7 gap-1 text-sm">
                    {days.map((day) => (
                        <div
                            key={day.toString()}
                            className={twMerge(
                                isToday(day) && "border-t border-red-500",
                                !isSameMonth(day, firstDaySelectedMonth) &&
                                    "pointer-events-none text-gray-400",
                                "border-t hover:bg-gray-200"
                            )}
                        >
                            <button
                                type="button"
                                onClick={() => setSelectedDay(day)}
                                className={twMerge(
                                    "flex justify-end p-2 mx-auto w-full h-24"
                                )}
                            >
                                <time dateTime={format(day, "yyyy-MM-dd")}>
                                    {format(day, "d")}
                                </time>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
