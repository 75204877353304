import React, { useState } from "react";
import TaskListIcon from "../../../assets/icons/TaskListIcon";
import { Button, COLOR, SIZE } from "../../ui/buttons/Button";
import Dialog from "../../Dialog";
import CreateNewListModal from "../../modal/CreateNewListModal";
import CreateNewTaskModal from "../../modal/CreateNewTaskModal";
import { twMerge } from "tailwind-merge";

const EmptyComponent = (props) => {
    const { para1, para2, btn, ModalComp } = props;
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="flex w-full flex-col  gap-[30px] p-4 border border-[#cdcdcd]">
            <Dialog
                setDialog={setShowModal}
                showDialog={showModal}
                main={<ModalComp cancel={() => setShowModal(false)} />}
            />
            <div className="flex w-full flex-col justify-center items-center  gap-[22px] p-[60px]">
                <TaskListIcon />
                <div className="flex flex-col  gap-[5px]">
                    <p className="w-full  text-base font-bold text-center text-[#373737]">
                        {para1}
                    </p>
                    <p className="w-full  text-base text-center text-[#373737]">
                        {para2}
                    </p>
                </div>
                <Button
                    color={COLOR.RED}
                    addClass={" "}
                    addLabelClass={"text-sm"}
                    text={<p className="text-sm  text-white">{btn}</p>}
                    size={SIZE.SMALL}
                    onClick={() => setShowModal(true)}
                />
            </div>
        </div>
    );
};

const ListItem = ({ text = "Nombre de la lista" }) => (
    <div className="flex justify-between items-center w-full  px-4 py-2 rounded bg-[#ffecef]">
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className=" w-[30px] h-[30px]"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M24.375 14.0625H13.125C12.7734 14.0625 12.5 14.375 12.5 14.6875V15.3125C12.5 15.6641 12.7734 15.9375 13.125 15.9375H24.375C24.6875 15.9375 25 15.6641 25 15.3125V14.6875C25 14.375 24.6875 14.0625 24.375 14.0625ZM24.375 20.3125H13.125C12.7734 20.3125 12.5 20.625 12.5 20.9375V21.5625C12.5 21.9141 12.7734 22.1875 13.125 22.1875H24.375C24.6875 22.1875 25 21.9141 25 21.5625V20.9375C25 20.625 24.6875 20.3125 24.375 20.3125ZM24.375 7.8125H13.125C12.7734 7.8125 12.5 8.125 12.5 8.4375V9.0625C12.5 9.41406 12.7734 9.6875 13.125 9.6875H24.375C24.6875 9.6875 25 9.41406 25 9.0625V8.4375C25 8.125 24.6875 7.8125 24.375 7.8125ZM7.5 19.375C6.48438 19.375 5.625 20.2344 5.625 21.25C5.625 22.3047 6.48438 23.125 7.5 23.125C8.51562 23.125 9.375 22.3047 9.375 21.25C9.375 20.2344 8.51562 19.375 7.5 19.375ZM10.4297 12.6562C10.3516 12.5781 10.2344 12.5 10.1172 12.5C9.96094 12.5 9.84375 12.5781 9.76562 12.6562L7.26562 15.1172L6.40625 14.2578C6.32812 14.1797 6.21094 14.1406 6.05469 14.1406C5.9375 14.1406 5.82031 14.1797 5.74219 14.2578L5.11719 14.8828C5.03906 14.9609 4.96094 15.0781 4.96094 15.1953C4.96094 15.3516 5.03906 15.4688 5.11719 15.5469L6.99219 17.3828C7.07031 17.4609 7.1875 17.5 7.30469 17.5C7.46094 17.5 7.57812 17.4609 7.65625 17.3828L8.28125 16.7578L11.0938 13.9844C11.1719 13.9062 11.2109 13.7891 11.2109 13.6328C11.2109 13.5156 11.1719 13.3984 11.0938 13.3203L10.4297 12.6562ZM10.4297 6.40625C10.3516 6.32812 10.2344 6.25 10.1172 6.25C9.96094 6.25 9.84375 6.32812 9.76562 6.40625L7.26562 8.86719L6.40625 8.00781C6.32812 7.92969 6.21094 7.89062 6.05469 7.89062C5.9375 7.89062 5.82031 7.92969 5.74219 8.00781L5.11719 8.63281C5.03906 8.71094 4.96094 8.82812 4.96094 8.94531C4.96094 9.10156 5.03906 9.21875 5.11719 9.29688L6.99219 11.1328C7.07031 11.2109 7.1875 11.25 7.30469 11.25C7.46094 11.25 7.57812 11.2109 7.65625 11.1328L8.28125 10.5078L11.0938 7.69531C11.1719 7.61719 11.25 7.5 11.25 7.38281C11.25 7.26562 11.1719 7.14844 11.0938 7.03125L10.4297 6.40625Z"
                fill="#333333"
            ></path>
        </svg>
        <p className="  w-full max-w-[158px] text-sm text-left text-[#373737]">
            Nombre de la lista
        </p>
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className=" w-10 h-10 justify-self-end"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M22.5 20C22.5 18.6458 21.3542 17.5 20 17.5C18.5938 17.5 17.5 18.6458 17.5 20C17.5 21.4062 18.5938 22.5 20 22.5C21.3542 22.5 22.5 21.4062 22.5 20ZM28.75 17.5C27.3438 17.5 26.25 18.6458 26.25 20C26.25 21.4062 27.3438 22.5 28.75 22.5C30.1042 22.5 31.25 21.4062 31.25 20C31.25 18.6458 30.1042 17.5 28.75 17.5ZM11.25 17.5C9.84375 17.5 8.75 18.6458 8.75 20C8.75 21.4062 9.84375 22.5 11.25 22.5C12.6042 22.5 13.75 21.4062 13.75 20C13.75 18.6458 12.6042 17.5 11.25 17.5Z"
                fill="#A4A4A4"
            ></path>
        </svg>
    </div>
);

const TaskItem = ({ status, task_name, date, assigned_to }) => {
    return (
        <div className="flex w-full     p-4  border-b border-[#cdcdcd] last-of-type:border-b-0">
            <div className="w-full flex  items-center   ">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-6 h-6 "
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M18.5 13H5.5C5.21875 13 5 13.25 5 13.5V14C5 14.2812 5.21875 14.5 5.5 14.5H18.5C18.75 14.5 19 14.2812 19 14V13.5C19 13.25 18.75 13 18.5 13ZM18.5 9.5H5.5C5.21875 9.5 5 9.75 5 10V10.5C5 10.7812 5.21875 11 5.5 11H18.5C18.75 11 19 10.7812 19 10.5V10C19 9.75 18.75 9.5 18.5 9.5Z"
                        fill="#333333"
                    ></path>
                </svg>
                <p className="  text-sm text-left text-[#373737]">
                    {task_name}
                </p>
            </div>
            <p className="w-full   text-sm text-left text-[#373737]">{date}</p>
            <p className="w-full   text-sm text-left text-[#373737]">
                {assigned_to}
            </p>
            <div className="w-full flex justify-between items-start  gap-2.5">
                <div
                    className={twMerge(
                        "flex justify-center items-center   h-[22px]  gap-[5px] p-2.5 rounded-[50px] border border-[#1f6bff] text-[#1f6bff]",
                        status === "No iniciada" &&
                            "border-[#d80027] text-[#d80027]",
                        status === "Completada" &&
                            "border-[#00bc4f] text-[#00bc4f]"
                    )}
                >
                    <p className=" text-sm text-center ">{status}</p>
                </div>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" min-w-6 h-6 "
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M13.5 12C13.5 11.1875 12.8125 10.5 12 10.5C11.1562 10.5 10.5 11.1875 10.5 12C10.5 12.8438 11.1562 13.5 12 13.5C12.8125 13.5 13.5 12.8438 13.5 12ZM17.25 10.5C16.4062 10.5 15.75 11.1875 15.75 12C15.75 12.8438 16.4062 13.5 17.25 13.5C18.0625 13.5 18.75 12.8438 18.75 12C18.75 11.1875 18.0625 10.5 17.25 10.5ZM6.75 10.5C5.90625 10.5 5.25 11.1875 5.25 12C5.25 12.8438 5.90625 13.5 6.75 13.5C7.5625 13.5 8.25 12.8438 8.25 12C8.25 11.1875 7.5625 10.5 6.75 10.5Z"
                        fill="#A4A4A4"
                    ></path>
                </svg>
            </div>
        </div>
    );
};

const taskData = [
    {
        task_name: "Nombre de la tarea",
        date: "03/06/2023",
        assigned_to: "@joelleal",
        status: "En curso",
    },
    {
        task_name: "Nombre de la tarea",
        date: "03/06/2023",
        assigned_to: "@joelleal",
        status: "No iniciada",
    },
    {
        task_name: "Nombre de la tarea",
        date: "03/06/2023",
        assigned_to: "@joelleal",
        status: "Completada",
    },
];

const TaskSection = () => {
    return (
        <div className="flex flex-col w-full   gap-4 p-4 border border-[#cdcdcd]">
            {/* title */}
            <div className="flex flex-col w-full   gap-[5px] pb-4   border-b  border-[#cdcdcd]">
                <div className="flex  items-center    gap-[22px]">
                    <p className=" flex-grow text-base font-bold text-left text-[#373737]">
                        Nombre de la tarea
                    </p>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" w-6 h-6 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M15.5625 14.7812C15.5 14.8438 15.5 14.9062 15.5 14.9688V18.5H4.5V7.5H12.0312C12.0938 7.5 12.1562 7.5 12.2188 7.4375L13.2188 6.4375C13.375 6.28125 13.25 6 13.0312 6H4.5C3.65625 6 3 6.6875 3 7.5V18.5C3 19.3438 3.65625 20 4.5 20H15.5C16.3125 20 17 19.3438 17 18.5V13.9688C17 13.75 16.7188 13.625 16.5625 13.7812L15.5625 14.7812ZM20.4375 8.5C21.1562 7.78125 21.1562 6.625 20.4375 5.90625L19.0938 4.5625C18.375 3.84375 17.2188 3.84375 16.5 4.5625L8.3125 12.75L8 15.5938C7.90625 16.4062 8.59375 17.0938 9.40625 17L12.25 16.6875L20.4375 8.5ZM17.375 9.4375L11.5625 15.25L9.5 15.5L9.75 13.4375L15.5625 7.625L17.375 9.4375ZM19.375 6.96875C19.5312 7.09375 19.5312 7.28125 19.4062 7.4375L18.4375 8.40625L16.625 6.5625L17.5625 5.625C17.6875 5.46875 17.9062 5.46875 18.0312 5.625L19.375 6.96875Z"
                            fill="#333333"
                        ></path>
                    </svg>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" w-6 h-6 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M9 13C11.4688 13 13.5 11 13.5 8.5C13.5 6.03125 11.4688 4 9 4C6.5 4 4.5 6.03125 4.5 8.5C4.5 11 6.5 13 9 13ZM9 5.5C10.625 5.5 12 6.875 12 8.5C12 10.1562 10.625 11.5 9 11.5C7.34375 11.5 6 10.1562 6 8.5C6 6.875 7.34375 5.5 9 5.5ZM11.7812 13.5C10.875 13.5 10.4688 14 9 14C7.5 14 7.09375 13.5 6.1875 13.5C3.875 13.5 2 15.4062 2 17.7188V18.5C2 19.3438 2.65625 20 3.5 20H14.5C15.3125 20 16 19.3438 16 18.5V17.7188C16 15.4062 14.0938 13.5 11.7812 13.5ZM14.5 18.5H3.5V17.7188C3.5 16.2188 4.6875 15 6.1875 15C6.65625 15 7.375 15.5 9 15.5C10.5938 15.5 11.3125 15 11.7812 15C13.2812 15 14.5 16.2188 14.5 17.7188V18.5ZM21.5 10.75H19.25V8.5C19.25 8.25 19 8 18.75 8H18.25C17.9688 8 17.75 8.25 17.75 8.5V10.75H15.5C15.2188 10.75 15 11 15 11.25V11.75C15 12.0312 15.2188 12.25 15.5 12.25H17.75V14.5C17.75 14.7812 17.9688 15 18.25 15H18.75C19 15 19.25 14.7812 19.25 14.5V12.25H21.5C21.75 12.25 22 12.0312 22 11.75V11.25C22 11 21.75 10.75 21.5 10.75Z"
                            fill="#333333"
                        ></path>
                    </svg>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" w-6 h-6 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M16 11.5C16 11.3125 15.8125 11.125 15.625 11.125H12.875V8.375C12.875 8.1875 12.6875 8 12.5 8H11.5C11.2812 8 11.125 8.1875 11.125 8.375V11.125H8.375C8.15625 11.125 8 11.3125 8 11.5V12.5C8 12.7188 8.15625 12.875 8.375 12.875H11.125V15.625C11.125 15.8438 11.2812 16 11.5 16H12.5C12.6875 16 12.875 15.8438 12.875 15.625V12.875H15.625C15.8125 12.875 16 12.7188 16 12.5V11.5ZM19.75 12C19.75 7.71875 16.2812 4.25 12 4.25C7.71875 4.25 4.25 7.71875 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.2812 19.75 19.75 16.2812 19.75 12ZM18.25 12C18.25 15.4688 15.4375 18.25 12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12Z"
                            fill="#333333"
                        ></path>
                    </svg>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" w-6 h-6 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M18.5 6.5H15.9062L14.8438 4.75C14.5938 4.3125 14.0938 4 13.5625 4H10.4062C9.875 4 9.375 4.3125 9.125 4.75L8.0625 6.5H5.5C5.21875 6.5 5 6.75 5 7V7.5C5 7.78125 5.21875 8 5.5 8H6L6.65625 18.5938C6.6875 19.4062 7.34375 20 8.15625 20H15.8125C16.625 20 17.2812 19.4062 17.3125 18.5938L18 8H18.5C18.75 8 19 7.78125 19 7.5V7C19 6.75 18.75 6.5 18.5 6.5ZM10.4062 5.5H13.5625L14.1562 6.5H9.8125L10.4062 5.5ZM15.8125 18.5H8.15625L7.5 8H16.4688L15.8125 18.5Z"
                            fill="#333333"
                        ></path>
                    </svg>
                </div>
                <p className="   text-sm text-left text-[#7b7b7b]">
                    Descripción de la tarea
                </p>
            </div>

            <div className="flex flex-col w-full">
                {/* table header */}
                <div className="hidden md:flex  px-4 py-3 bg-[#f2f2f2]">
                    <p className="w-full text-sm font-bold text-left text-[#373737]">
                        Tarea
                    </p>
                    <p className="w-full text-sm font-bold text-left text-[#373737]">
                        Fecha de vencimiento
                    </p>
                    <p className="w-full text-sm font-bold text-left text-[#373737]">
                        Asignado
                    </p>
                    <div className="w-full flex  items-center   gap-[5px]">
                        <p className=" text-sm font-bold text-left text-[#373737]">
                            Estado
                        </p>
                        <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=" w-4 h-4 "
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M12.3131 3.16669H3.66728C2.77144 3.16669 2.31311 4.25002 2.95894 4.87502L6.33394 8.25002V11.8334C6.33394 12.1667 6.47977 12.4584 6.72977 12.6667L8.06311 13.6667C8.70894 14.1042 9.66727 13.6875 9.66727 12.8542V8.25002L13.0214 4.87502C13.6673 4.25002 13.2089 3.16669 12.3131 3.16669ZM8.66727 7.83335V12.8334L7.33394 11.8334V7.83335L3.66728 4.16669H12.3339L8.66727 7.83335Z"
                                fill="#333333"
                            ></path>
                        </svg>
                    </div>
                </div>
                {/* table data */}

                {taskData.map((task, index) => (
                    <TaskItem {...task} key={index}/>
                ))}
            </div>
        </div>
    );
};

const TaskPage = () => {

    const list = JSON.parse(localStorage.getItem("list"));
    const task = JSON.parse(localStorage.getItem("task"));

    if (!list)
        return (
            <EmptyComponent
                para1="¡Haz más cosas con mayor rapidez!"
                para2="Organiza tu trabajo, fija plazos, da prioridad a las tareas y asígnalas."
                btn="Agregar lista de tareas"
                ModalComp={CreateNewListModal}
            />
        );

    return (
        <div className="flex flex-col md:flex-row w-full ">
            <aside className="flex flex-col  w-full max-w-[336px] gap-[30px] p-[30px] bg-white border border-[#cdcdcd] border-r-0 self-start">
                <div className="flex flex-col   gap-4">
                    <div className="flex   gap-4 px-4 py-2">
                        <p className=" w-full max-w-[204px] h-[22px] text-base font-bold text-left text-[#373737]">
                            Lista de tareas
                        </p>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=" w-6 h-6"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M16 11.5C16 11.3125 15.8125 11.125 15.625 11.125H12.875V8.375C12.875 8.1875 12.6875 8 12.5 8H11.5C11.2812 8 11.125 8.1875 11.125 8.375V11.125H8.375C8.15625 11.125 8 11.3125 8 11.5V12.5C8 12.7188 8.15625 12.875 8.375 12.875H11.125V15.625C11.125 15.8438 11.2812 16 11.5 16H12.5C12.6875 16 12.875 15.8438 12.875 15.625V12.875H15.625C15.8125 12.875 16 12.7188 16 12.5V11.5ZM19.75 12C19.75 7.71875 16.2812 4.25 12 4.25C7.71875 4.25 4.25 7.71875 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.2812 19.75 19.75 16.2812 19.75 12ZM18.25 12C18.25 15.4688 15.4375 18.25 12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12Z"
                                fill="#333333"
                            ></path>
                        </svg>
                    </div>
                    <div className="flex flex-col  ">
                        <ListItem text="Nombre de la lista" />
                    </div>
                </div>
            </aside>
            {!task ? (
                <EmptyComponent
                    para1="Aún no hay tareas"
                    para2="Crea tareas y asígnalas a personas que ayudarán a que tu equipo siga por el buen camino."
                    btn="Crea tu primera tarea"
                    ModalComp={CreateNewTaskModal}
                />
            ) : (
                <TaskSection />
            )}
        </div>
    );
};

export default TaskPage;
