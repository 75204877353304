import { noop } from "../../utils/noop";

export const Tag = ({
    hasClose = true,
    text = "Etiqueta",
    removeTag = noop
}) => (
    <div className="flex justify-center cursor-pointer items-center   w-fit h-[30px] relative gap-[5px] px-3.5 py-2.5 rounded-[50px] border border-[#292929]">
        {Boolean(hasClose) && (
            <svg
                width={21}
                height={20}
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative w-5 h-5"
                preserveAspectRatio="xMidYMid meet"
                onClick={() => removeTag(text)}
            >
                <path
                    d="M11.724 9.99999L14.5365 7.21353C14.6927 7.05728 14.6927 6.77082 14.5365 6.61457L13.8854 5.96353C13.7292 5.80728 13.4427 5.80728 13.2865 5.96353L10.5 8.77603L7.6875 5.96353C7.53125 5.80728 7.24479 5.80728 7.08854 5.96353L6.4375 6.61457C6.28125 6.77082 6.28125 7.05728 6.4375 7.21353L9.25 9.99999L6.4375 12.8125C6.28125 12.9687 6.28125 13.2552 6.4375 13.4114L7.08854 14.0625C7.24479 14.2187 7.53125 14.2187 7.6875 14.0625L10.5 11.25L13.2865 14.0625C13.4427 14.2187 13.7292 14.2187 13.8854 14.0625L14.5365 13.4114C14.6927 13.2552 14.6927 12.9687 14.5365 12.8125L11.724 9.99999Z"
                    fill="#333333"
                />
            </svg>
        )}
        <p className="flex-grow shrink-0  text-sm text-left text-[#373737]">
            {text}
        </p>
    </div>
);
