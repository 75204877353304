import React from "react";

const Underline = ({
    oportunidades = false,
    className = "",
    ganarmás = false,
}) => {
    if (oportunidades) {
        return (
            <svg
                width="387"
                height="35"
                viewBox="0 0 387 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                preserveAspectRatio="none"
            >
                <path
                    d="M384.5 17.4308C295.742 0.665548 32.5331 -12.1108 2.5 33"
                    stroke="#D80027"
                    stroke-width="3.42451"
                    stroke-linecap="round"
                ></path>
            </svg>
        );
    }
    if (ganarmás) {
        return (
            <svg
                width="284"
                height="30"
                viewBox="0 0 284 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                preserveAspectRatio="none"
            >
                <path
                    d="M282 14.9419C216.942 0.880782 24.0138 -9.83482 2 28"
                    stroke="#D80027"
                    stroke-width="3.42451"
                    stroke-linecap="round"
                ></path>
            </svg>
        );
    }

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 166 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="-mt-5"
        >
            <path
                d="M164.5 11.441C148.5 1.27004 28.5 -5.55896 2 17.4411"
                stroke="#D80027"
                strokeWidth={3}
                strokeLinecap="round"
            />
        </svg>
    );
};

export default Underline;
