import React from "react";
import { Button, COLOR } from "../../ui/buttons/Button";
import CustomDropdown from "../../ui/CustomDropdown";
import { UploadDocuments } from "../UrgentHiringForm";
import { Link, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const FilesTableRow = ({ file, index, onChange }) => {
    const handleChange = (e) => {
        onChange(e.target.value, index);
    };

    return (
        <div className="flex flex-col md:flex-row gap-2 md:gap-0 justify-start items-center p-4 border-b  border-[#cdcdcd] last-of-type:border-b-0 last-of-type:pb-2">
            <div className="flex items-center w-full  gap-[5px]">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M15 11.75C15 11.5625 14.8125 11.375 14.625 11.375H9.375C9.15625 11.375 9 11.5625 9 11.75V12.625C9 12.8438 9.15625 13 9.375 13H14.625C14.8125 13 15 12.8438 15 12.625V11.75ZM14.625 14H9.375C9.15625 14 9 14.1875 9 14.375V15.25C9 15.4688 9.15625 15.625 9.375 15.625H14.625C14.8125 15.625 15 15.4688 15 15.25V14.375C15 14.1875 14.8125 14 14.625 14ZM18 8.125C18 7.75 17.8125 7.34375 17.5312 7.0625L14.9375 4.46875C14.6562 4.1875 14.25 4 13.875 4H7.5C6.65625 4 6 4.6875 6 5.5V18.5C6 19.3438 6.65625 20 7.5 20H16.5C17.3125 20 18 19.3438 18 18.5V8.125ZM14 5.625L16.375 8H14V5.625ZM16.5 18.5H7.5V5.5H12.5V8.75C12.5 9.1875 12.8125 9.5 13.25 9.5H16.5V18.5Z"
                        fill="#333333"
                    ></path>
                </svg>
                <p className="w-full whitespace-nowrap text-sm text-left text-[#373737]">
                    {file.name}
                </p>
            </div>
            <p className="w-full whitespace-nowrap text-sm text-left text-[#373737]">
                {file.size}
            </p>
            <p className="w-full whitespace-nowrap text-sm text-left text-[#373737]">
                {file.owner}
            </p>
            <p className="w-full whitespace-nowrap text-sm text-left text-[#373737]">
                {file.modified}
            </p>
            <CustomDropdown
                options={["Descargar", "Vista previa", "Compartir", "Eliminar"]}
                name={""}
                onChange={handleChange}
                className={"min-w-[165px] w-full"}
            />
        </div>
    );
};

const filesData = [
    {
        name: "Lorem Doc",
        size: "40 MB",
        owner: "Tú",
        modified: "03/06/2023",
        type: "doc",
    },
    {
        name: "Lorem Video",
        size: "40 MB",
        owner: "Tú",
        modified: "03/06/2023",
        type: "video",
    },
    {
        name: "Lorem Audio",
        size: "40 MB",
        owner: "Tú",
        modified: "03/06/2023",
        type: "audio",
    },
    {
        name: "Lorem Image",
        size: "40 MB",
        owner: "Tú",
        modified: "03/06/2023",
        type: "image",
    },
];

const Files = () => {
    const [searchParams, ] = useSearchParams();

    const typeFilter = searchParams.get("type");
    const displayFiles = typeFilter ? filesData.filter(file => file.type === typeFilter): filesData;

    const handleChange = (value, index) => {
        console.log(`File ${index} action:`, value);
    };

    return (
        <div className="flex flex-col md:flex-row gap-6 ">
            <div className="w-full max-w-[816px] p-4 bg-white border border-[#cdcdcd] flex flex-col gap-7">
                {/* title */}
                <div className="flex flex-col sm:flex-row justify-start items-start self-stretch gap-[22px]">
                    <p className="w-full text-center sm:text-left text-[21px] leading-7 font-bold text-[#373737]">
                        Archivos
                    </p>

                    <Button
                        color={COLOR.RED}
                        addClass={" h-9"}
                        text="Cargar archivos"
                    />
                </div>

                {filesData?.length !== 0 ? (
                    // table
                    <div>
                        {/* table Heading */}
                        <div className="hidden md:flex h-11 px-4 py-3 bg-zinc-100 justify-between  items-center text-neutral-700 text-sm font-bold font-['Noto Sans']">
                            <div className="w-full max-w-[123px]">Nombre</div>
                            <div className="w-full max-w-[123px]">Tamaño</div>
                            <div className="w-full max-w-[123px]">Propietario</div>
                            <div className="w-full max-w-[123px]">Modificado</div>
                            <div className="w-full max-w-[165px]">Acciones</div>
                        </div>
                        {/* table rows */}

                        {displayFiles.map((file, index) => (
                            <FilesTableRow
                                key={index}
                                file={file}
                                index={index}
                                onChange={handleChange}
                            />
                        ))}
                    </div>
                ) : (
                    <UploadDocuments
                        text={"Arrastra y suelta aquí tus archivos o explora"}
                    />
                )}
            </div>

            {/* sidebar */}
            <aside className="p-[30px] border border-stone-300 self-start w-full  max-w-[276px] space-y-4">
                {/* <div className="flex flex-col justify-start items-start self-stretch  "> */}
                <p className="w-full  max-w-[276px] h-[22px] text-base font-bold text-left text-[#373737]">
                    Filtrar
                </p>

                <div className="flex flex-col justify-start items-start ">
                    <Link to={""} className={twMerge("border-l border-white", !typeFilter && "border-red-500")}>
                      <div className="flex justify-start items-center  px-4 py-2">
                          <div className="flex justify-start items-start   gap-2.5 pr-4">
                              <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className=" w-[30px] h-[30px] "
                                  preserveAspectRatio="xMidYMid meet"
                              >
                                  <path
                                      d="M21.9141 8.82812L18.6719 5.54688C18.3203 5.19531 17.8125 5 17.3438 5H9.375C8.32031 5 7.5 5.85938 7.5 6.875V23.125C7.5 24.1797 8.32031 25 9.375 25H20.625C21.6406 25 22.5 24.1797 22.5 23.125V10.1562C22.5 9.6875 22.2656 9.17969 21.9141 8.82812ZM20.4688 10H17.5V7.03125L20.4688 10ZM9.375 23.125V6.875H15.625V10.9375C15.625 11.4844 16.0156 11.875 16.5625 11.875H20.625V23.125H9.375Z"
                                      fill="#333333"
                                  ></path>
                              </svg>
                          </div>
                          <div className="flex flex-col justify-start items-start flex-grow ">
                              <p className="  text-sm text-left text-[#373737]">
                                  Todos los archivos
                              </p>
                          </div>
                      </div>
                    </Link>
                    <Link to={"?type=doc"} className={twMerge("border-l border-white", typeFilter === "doc" && "border-red-500")}>
                      <div className="flex justify-start items-center  px-4 py-2">
                          <div className="flex justify-start items-start   gap-2.5 pr-4">
                              <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className=" w-[30px] h-[30px] "
                                  preserveAspectRatio="xMidYMid meet"
                              >
                                  <path
                                      d="M18.75 14.6875C18.75 14.4531 18.5156 14.2188 18.2812 14.2188H11.7188C11.4453 14.2188 11.25 14.4531 11.25 14.6875V15.7812C11.25 16.0547 11.4453 16.25 11.7188 16.25H18.2812C18.5156 16.25 18.75 16.0547 18.75 15.7812V14.6875ZM18.2812 17.5H11.7188C11.4453 17.5 11.25 17.7344 11.25 17.9688V19.0625C11.25 19.3359 11.4453 19.5312 11.7188 19.5312H18.2812C18.5156 19.5312 18.75 19.3359 18.75 19.0625V17.9688C18.75 17.7344 18.5156 17.5 18.2812 17.5ZM22.5 10.1562C22.5 9.6875 22.2656 9.17969 21.9141 8.82812L18.6719 5.58594C18.3203 5.23438 17.8125 5 17.3438 5H9.375C8.32031 5 7.5 5.85938 7.5 6.875V23.125C7.5 24.1797 8.32031 25 9.375 25H20.625C21.6406 25 22.5 24.1797 22.5 23.125V10.1562ZM17.5 7.03125L20.4688 10H17.5V7.03125ZM20.625 23.125H9.375V6.875H15.625V10.9375C15.625 11.4844 16.0156 11.875 16.5625 11.875H20.625V23.125Z"
                                      fill="#333333"
                                  ></path>
                              </svg>
                          </div>
                          <div className="flex flex-col justify-start items-start flex-grow relative">
                              <p className="  text-sm text-left text-[#373737]">
                                  Documentos
                              </p>
                          </div>
                      </div>
                    </Link>
                    <Link to={"?type=image"} className={twMerge("border-l border-white", typeFilter === "image" && "border-red-500")}>
                      <div className="flex justify-start items-center  px-4 py-2">
                          <div className="flex justify-start items-start  relative gap-2.5 pr-4">
                              <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className=" w-[30px] h-[30px] relative"
                                  preserveAspectRatio="xMidYMid meet"
                              >
                                  <path
                                      d="M23.125 7.5H6.875C5.82031 7.5 5 8.35938 5 9.375V20.625C5 21.6797 5.82031 22.5 6.875 22.5H23.125C24.1406 22.5 25 21.6797 25 20.625V9.375C25 8.35938 24.1406 7.5 23.125 7.5ZM22.8906 20.625H7.10938C6.95312 20.625 6.875 20.5469 6.875 20.3906V9.60938C6.875 9.49219 6.95312 9.375 7.10938 9.375H22.8906C23.0078 9.375 23.125 9.49219 23.125 9.60938V20.3906C23.125 20.5469 23.0078 20.625 22.8906 20.625ZM10 10.9375C9.10156 10.9375 8.4375 11.6406 8.4375 12.5C8.4375 13.3984 9.10156 14.0625 10 14.0625C10.8594 14.0625 11.5625 13.3984 11.5625 12.5C11.5625 11.6406 10.8594 10.9375 10 10.9375ZM8.75 18.75H21.25V15.625L17.8125 12.2266C17.6172 12.0312 17.3438 12.0312 17.1484 12.2266L12.5 16.875L10.9375 15.3516C10.7422 15.1562 10.4688 15.1562 10.2734 15.3516L8.75 16.875V18.75Z"
                                      fill="#333333"
                                  ></path>
                              </svg>
                          </div>
                          <div className="flex flex-col justify-start items-start flex-grow relative">
                              <p className="  text-sm text-left text-[#373737]">
                                  Imágenes
                              </p>
                          </div>
                      </div>
                    </Link>
                    <Link to={"?type=video"} className={twMerge("border-l border-white", typeFilter === "video" && "border-red-500")}>
                      <div className="flex justify-start items-center  px-4 py-2">
                          <div className="flex justify-start items-start  relative gap-2.5 pr-4">
                              <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className=" w-[30px] h-[30px] relative"
                                  preserveAspectRatio="xMidYMid meet"
                              >
                                  <path
                                      d="M24.9609 8.75C24.7266 8.75 24.4922 8.82812 24.2578 8.98438L20 11.6797V9.375C20 8.35938 19.0625 7.5 17.9688 7.5H5.74219C4.64844 7.5 3.75 8.35938 3.75 9.375V20.6641C3.75 21.6797 4.64844 22.5 5.74219 22.5H17.9688C19.0625 22.5 20 21.6797 20 20.6641V18.3594L24.2578 21.0547C24.4922 21.2109 24.7266 21.25 24.9609 21.25C25.625 21.25 26.25 20.7422 26.25 20.0391V10C26.25 9.25781 25.625 8.75 24.9609 8.75ZM18.125 12.8125V20.5859C18.0859 20.625 18.0469 20.625 17.9688 20.625H5.74219C5.66406 20.625 5.625 20.625 5.625 20.5859V9.45312C5.625 9.41406 5.66406 9.375 5.74219 9.375H17.9688C18.0469 9.375 18.0859 9.41406 18.125 9.45312V12.8125ZM24.375 18.9062L20 16.1719V13.8672L24.375 11.1328V18.9062Z"
                                      fill="#333333"
                                  ></path>
                              </svg>
                          </div>
                          <div className="flex flex-col justify-start items-start flex-grow relative">
                              <p className="  text-sm text-left text-[#373737]">
                                  Videos
                              </p>
                          </div>
                      </div>
                    </Link>
                </div>
                {/* </div> */}
            </aside>
        </div>
    );
};

export default Files;
