import { useState} from "react";
import { Button, SIZE } from "../../components/ui/buttons/Button";
import Input from "../../components/ui/Input";
import { useFormik } from "formik";
import Swal from "sweetalert2";


function Password() {
    const [inputType, setInputType] = useState("password");
    const token = localStorage.getItem('authToken');

    const formik = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        },
        validate: (values) => {
            const errors = {};
            if (values.newPassword !== values.confirmPassword) {
                errors.confirmPassword = "Las contraseñas no coinciden";
            }
            const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
            if (!passwordRegex.test(values.newPassword)) {
                errors.newPassword = "La contraseña debe tener al menos 8 caracteres, una mayúscula y un número";
            }

            return errors;
        },
        onSubmit: async (values) => {
            try {
                const response = await fetch("https://eprof-production-6g7zxvibga-uc.a.run.app/change-password/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        old_password: values.currentPassword,
                        new_password: values.newPassword
                    })
                });
                if (!response.ok) {
                    throw new Error("Failed to change password");
                }
                await Swal.fire({
                    icon: 'success',
                    title: 'Contraseña cambiada exitosamente',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    const previousUrl = document.referrer;
                    if (previousUrl && previousUrl !== window.location.href) {
                      window.location.href = previousUrl;
                    }
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al cambiar la contraseña',
                    text: error.message,
                    timer: 2000,
                    showConfirmButton: false
                });
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit} className="flex flex-col justify-start items-start">
        <div className="flex flex-col justify-start items-start w-full lg:w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
            <p className="flex w-full lg:w-[765px] text-[21px] font-bold text-left text-[#373737]">
                Contraseña
            </p>
        </div>
        <div className="flex flex-col justify-start items-start w-full lg:w-[813px] relative gap-[22px] p-6 border border-[#cdcdcd]">
            <p className=" lg:w-[765px] h-[25px] text-lg font-bold text-left text-[#373737]">
                Cambiar contraseña
            </p>
            <div>
            <Input
                label="Contraseña actual"
                name="currentPassword"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                addInputClass="w-full lg:w-[337px] "
                onBlur={formik.handleBlur}
                type={inputType}
                onIconClick={() =>
                    setInputType((type) =>
                        type === "password" ? "text" : "password"
                    )
                }
                icon={
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                            fill="#A4A4A4"
                        />
                    </svg>
                }
            />
            </div>
            <div>
            <Input
                label="Nueva contraseña"
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                addInputClass="w-full lg:w-[337px] "
                onBlur={formik.handleBlur}
                type={inputType}
                onIconClick={() =>
                    setInputType((type) =>
                        type === "password" ? "text" : "password"
                    )
                }
                icon={
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                            fill="#A4A4A4"
                        />
                    </svg>
                }
            />
            </div>
            {formik.errors.newPassword && formik.touched.newPassword && (
                <p className="text-red-500">{formik.errors.newPassword}</p>
            )}
            <div>
            <Input
                label="Confirmar nueva contraseña"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                addInputClass="w-full lg:w-[337px] "
                onBlur={formik.handleBlur}
                type={inputType}
                onIconClick={() =>
                    setInputType((type) =>
                        type === "password" ? "text" : "password"
                    )
                }
                icon={
                    <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative w-6 h-6"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M12 8.5C11.6562 8.53125 11.3125 8.5625 11 8.65625C11.1562 8.90625 11.2188 9.21875 11.25 9.5C11.25 10.4688 10.4375 11.25 9.5 11.25C9.1875 11.25 8.875 11.1875 8.65625 11.0312C8.5625 11.3438 8.5 11.6562 8.5 12C8.5 13.9375 10.0625 15.5 12 15.5C13.9375 15.5 15.5 13.9375 15.5 12C15.5 10.0938 13.9375 8.53125 12 8.53125V8.5ZM20.875 11.5625C19.1875 8.25 15.8125 6 12 6C8.15625 6 4.78125 8.25 3.09375 11.5625C3.03125 11.6875 3 11.8438 3 12C3 12.1875 3.03125 12.3438 3.09375 12.4688C4.78125 15.7812 8.15625 18 12 18C15.8125 18 19.1875 15.7812 20.875 12.4688C20.9375 12.3438 20.9688 12.1875 20.9688 12.0312C20.9688 11.8438 20.9375 11.6875 20.875 11.5625ZM12 16.5C8.90625 16.5 6.0625 14.7812 4.5625 12C6.0625 9.21875 8.90625 7.5 12 7.5C15.0625 7.5 17.9062 9.21875 19.4062 12C17.9062 14.7812 15.0625 16.5 12 16.5Z"
                            fill="#A4A4A4"
                        />
                    </svg>
                }
            />
            </div>
            {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                <p className="text-red-500">{formik.errors.confirmPassword}</p>
            )}
        </div>
        <div className="flex flex-col justify-center items-end w-full lg:w-[813px] gap-[22px] p-6 bg-[#f2f2f2] border border-[#cdcdcd]">
            <Button text="Guardar cambios" size={SIZE.LARGE} type="submit" />
        </div>
    </form>
    );
}

export default Password;
