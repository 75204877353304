import React from "react";

function ListFeatures() {
    return (
        <div className="flex flex-col justify-start items-center gap-10 ">
            {/* w-[1172px] */}
            <div className="flex flex-col justify-start items-center  relative gap-2.5 sm:gap-7">
                {/* w-[1172px] */}
                <p className="font-[Satoshi] self-stretch  text-xl sm:text-5xl font-bold text-center text-[#373737]">
                    Más beneficios para tí
                </p>
                {/* w-[1172px] */}
                <p className="self-stretch  text-sm sm:text-[21px] text-center text-[#7b7b7b]">
                    Al ser parte de la familia EPROF tendrás acceso a múltiples
                    beneficios, estos son algunos de ellos:
                </p>
            </div>
            <div className="flex flex-col md:flex-row justify-start items-start gap-6">
                <div className="flex flex-col justify-start items-start d gap-5">
                    <div className="flex justify-start items-center sm:items-start self-stretch relative gap-6">
                        <div
                            className="flex justify-start items-start relative gap-2.5 bg-[#d80027]"
                            style={{
                                boxShadow: "0px 8px 15px 0 rgba(0,0,0,0.15)"
                            }}
                        >
                            <svg
                                width={100}
                                height={101}
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="0 w-[40px] h-[40px] sm:w-[100px] sm:h-[100px] relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M49.9997 18.4844C32.1611 18.4844 17.708 32.9375 17.708 50.776C17.708 68.6146 32.1611 83.0677 49.9997 83.0677C67.8382 83.0677 82.2913 68.6146 82.2913 50.776C82.2913 32.9375 67.8382 18.4844 49.9997 18.4844ZM49.9997 76.8177C35.5465 76.8177 23.958 65.2292 23.958 50.776C23.958 36.4531 35.5465 24.7344 49.9997 24.7344C64.3226 24.7344 76.0413 36.4531 76.0413 50.776C76.0413 65.2292 64.3226 76.8177 49.9997 76.8177ZM57.9424 63.276C58.7236 63.7969 59.6351 63.6667 60.1559 62.8854L62.6299 59.6302C63.1507 58.849 63.0205 57.9375 62.2393 57.4167L53.6455 51.0365V32.5469C53.6455 31.7656 52.8643 30.9844 52.083 30.9844H47.9163C47.0049 30.9844 46.3538 31.7656 46.3538 32.5469V54.0312C46.3538 54.4219 46.484 54.9427 46.8747 55.2031L57.9424 63.276Z"
                                    fill="#F2F2F2"
                                />
                            </svg>
                        </div>
                        <p className="flex-grow  text-md   sm:text-4xl text-left text-[#373737]">
                            Podrás contratar mensajeros y procuradores dentro de
                            la plataforma
                        </p>
                    </div>
                    <div className="flex justify-start items-center sm:items-start self-stretch relative gap-6">
                        <div
                            className="flex justify-start items-start relative gap-2.5 bg-[#d80027]"
                            style={{
                                boxShadow: "0px 8px 15px 0 rgba(0,0,0,0.15)"
                            }}
                        >
                            <svg
                                width={100}
                                height={101}
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="0 w-[40px] h-[40px] sm:w-[100px] sm:h-[100px] relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M49.9997 54.9424C60.2861 54.9424 68.7497 46.6091 68.7497 36.1924C68.7497 25.9059 60.2861 17.4424 49.9997 17.4424C39.583 17.4424 31.2497 25.9059 31.2497 36.1924C31.2497 46.6091 39.583 54.9424 49.9997 54.9424ZM49.9997 23.6924C56.7705 23.6924 62.4997 29.4216 62.4997 36.1924C62.4997 43.0934 56.7705 48.6924 49.9997 48.6924C43.0986 48.6924 37.4997 43.0934 37.4997 36.1924C37.4997 29.4216 43.0986 23.6924 49.9997 23.6924ZM61.8486 57.1559L54.557 77.208L52.083 66.4007L56.2497 59.1091H43.7497L47.9163 66.4007L45.3122 77.208L38.0205 57.1559C28.3851 57.2861 20.833 64.9684 20.833 74.6038V77.8591C20.833 81.3747 23.5674 84.1091 27.083 84.1091H72.9163C76.3018 84.1091 79.1663 81.3747 79.1663 77.8591V74.6038C79.1663 64.9684 71.484 57.2861 61.8486 57.1559ZM33.333 77.8591H27.083V74.6038C27.083 69.9163 29.8174 66.0101 33.984 64.1872L38.932 77.8591H33.333ZM72.9163 77.8591H60.9372L66.0153 64.1872C70.0518 66.0101 72.9163 69.9163 72.9163 74.6038V77.8591Z"
                                    fill="#F2F2F2"
                                />
                            </svg>
                        </div>
                        {/*  */}
                        <p className=" text-md   sm:text-4xl text-left text-[#373737]">
                            Tendrás acceso a un contenedor de leyes,
                            jurisprudencias, minutas y documentos legales
                        </p>
                    </div>
                    <div className="flex justify-start items-center sm:items-start self-stretch relative gap-6">
                        <div
                            className="flex justify-start items-start relative gap-2.5 bg-[#d80027]"
                            style={{
                                boxShadow: "0px 8px 15px 0 rgba(0,0,0,0.15)"
                            }}
                        >
                            <svg
                                width={100}
                                height={101}
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="0 w-[40px] h-[40px] sm:w-[100px] sm:h-[100px] relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M77.0837 34.1094H66.667V27.8594C66.667 24.474 63.8024 21.6094 60.417 21.6094H39.5837C36.068 21.6094 33.3337 24.474 33.3337 27.8594V34.1094H22.917C19.4014 34.1094 16.667 36.974 16.667 40.3594V73.6927C16.667 77.2083 19.4014 79.9427 22.917 79.9427H77.0837C80.4691 79.9427 83.3337 77.2083 83.3337 73.6927V40.3594C83.3337 36.974 80.4691 34.1094 77.0837 34.1094ZM39.5837 27.8594H60.417V34.1094H39.5837V27.8594ZM22.917 73.6927V40.3594H33.3337V73.6927H22.917ZM39.5837 73.6927V40.3594H60.417V73.6927H39.5837ZM77.0837 73.6927H66.667V40.3594H77.0837V73.6927Z"
                                    fill="#F2F2F2"
                                />
                            </svg>
                        </div>
                        {/*  */}
                        <p className=" text-md   sm:text-4xl text-left text-[#373737]">
                            Directorio telefónico personalizado donde podrás
                            agregar o editar.
                        </p>
                    </div>
                </div>

                <div className="flex flex-col justify-start items-start d gap-5">
                    <div className="flex justify-start items-center sm:items-start self-stretch relative gap-6">
                        <div
                            className="flex justify-start items-start relative gap-2.5 bg-[#d80027]"
                            style={{
                                boxShadow: "0px 8px 15px 0 rgba(0,0,0,0.15)"
                            }}
                        >
                            <svg
                                width={100}
                                height={101}
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="0 w-[40px] h-[40px] sm:w-[100px] sm:h-[100px] relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M77.0837 34.1094H66.667V27.8594C66.667 24.474 63.8024 21.6094 60.417 21.6094H39.5837C36.068 21.6094 33.3337 24.474 33.3337 27.8594V34.1094H22.917C19.4014 34.1094 16.667 36.974 16.667 40.3594V73.6927C16.667 77.2083 19.4014 79.9427 22.917 79.9427H77.0837C80.4691 79.9427 83.3337 77.2083 83.3337 73.6927V40.3594C83.3337 36.974 80.4691 34.1094 77.0837 34.1094ZM39.5837 27.8594H60.417V34.1094H39.5837V27.8594ZM22.917 73.6927V40.3594H33.3337V73.6927H22.917ZM39.5837 73.6927V40.3594H60.417V73.6927H39.5837ZM77.0837 73.6927H66.667V40.3594H77.0837V73.6927Z"
                                    fill="#F2F2F2"
                                />
                            </svg>
                        </div>
                        {/*  */}
                        <p className=" text-md   sm:text-4xl text-left text-[#373737]">
                            Subcontrata a uno o varios colegas para que te
                            apoyen en el trabajo.
                        </p>
                    </div>
                    <div className="flex justify-start items-center sm:items-start self-stretch relative gap-6">
                        <div
                            className="flex justify-start items-start relative gap-2.5 bg-[#d80027]"
                            style={{
                                boxShadow: "0px 8px 15px 0 rgba(0,0,0,0.15)"
                            }}
                        >
                            <svg
                                width={100}
                                height={101}
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="0 w-[40px] h-[40px] sm:w-[100px] sm:h-[100px] relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M77.0837 34.1094H66.667V27.8594C66.667 24.474 63.8024 21.6094 60.417 21.6094H39.5837C36.068 21.6094 33.3337 24.474 33.3337 27.8594V34.1094H22.917C19.4014 34.1094 16.667 36.974 16.667 40.3594V73.6927C16.667 77.2083 19.4014 79.9427 22.917 79.9427H77.0837C80.4691 79.9427 83.3337 77.2083 83.3337 73.6927V40.3594C83.3337 36.974 80.4691 34.1094 77.0837 34.1094ZM39.5837 27.8594H60.417V34.1094H39.5837V27.8594ZM22.917 73.6927V40.3594H33.3337V73.6927H22.917ZM39.5837 73.6927V40.3594H60.417V73.6927H39.5837ZM77.0837 73.6927H66.667V40.3594H77.0837V73.6927Z"
                                    fill="#F2F2F2"
                                />
                            </svg>
                        </div>
                        {/*  */}
                        <p className=" text-md   sm:text-4xl text-left text-[#373737]">
                            Acceso a la comunidad y un foro especial entre
                            abogados para resolver cualquier duda en materia del
                            Derecho.
                        </p>
                    </div>
                    <div className="flex justify-start items-center sm:items-start self-stretch relative gap-6">
                        <div
                            className="flex justify-start items-start relative gap-2.5 bg-[#d80027]"
                            style={{
                                boxShadow: "0px 8px 15px 0 rgba(0,0,0,0.15)"
                            }}
                        >
                            <svg
                                width={100}
                                height={101}
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="0 w-[40px] h-[40px] sm:w-[100px] sm:h-[100px] relative"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M77.0837 34.1094H66.667V27.8594C66.667 24.474 63.8024 21.6094 60.417 21.6094H39.5837C36.068 21.6094 33.3337 24.474 33.3337 27.8594V34.1094H22.917C19.4014 34.1094 16.667 36.974 16.667 40.3594V73.6927C16.667 77.2083 19.4014 79.9427 22.917 79.9427H77.0837C80.4691 79.9427 83.3337 77.2083 83.3337 73.6927V40.3594C83.3337 36.974 80.4691 34.1094 77.0837 34.1094ZM39.5837 27.8594H60.417V34.1094H39.5837V27.8594ZM22.917 73.6927V40.3594H33.3337V73.6927H22.917ZM39.5837 73.6927V40.3594H60.417V73.6927H39.5837ZM77.0837 73.6927H66.667V40.3594H77.0837V73.6927Z"
                                    fill="#F2F2F2"
                                />
                            </svg>
                        </div>
                        {/*  */}
                        <p className=" text-md   sm:text-4xl text-left text-[#373737]">
                            Chat integrado para conversar con tus clientes y
                            enviar presupuestos
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListFeatures;
