import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { attachmentSvg } from "../../../utils/icons";
import Input from "../../ui/Input";
import ReturnButton from "../../ui/buttons/ReturnButton";
import Message from "./Message";
import { Button } from "../../ui/buttons/Button";

function Chat({
    name = "Cristian Ferrari",
    username = "cristian",
    projectName = "Project name",
    handleNavigateNext,
    handleNavigatePrevious,
    addClass = ""
}) {
    const [formData, setFormData] = useState({});

    const locationPath = useLocation()?.pathname;
    const processingChatScreen = locationPath.includes(
        "urgent-contract/processing"
    );

    const handleChange = useCallback(
        (target = {}) => {
            let { name, value } = target;
            console.log(target);
            setFormData((data) => ({ ...data, ...{ [name]: value } }));
            console.log(formData);
        },
        [formData]
    );

    const handleFormSubmission = useCallback((e) => {
        // submit formData
        e.preventDefault();
    }, []);

    return (
        <div
            className={twMerge(
                "flex flex-col justify-between relative    items-start flex-grow overflow-hidden gap-[19px] border-0 border-[#cdcdcd]",
                addClass,
                processingChatScreen &&
                    "basis-[553px] flex xl:flex  gap-0 h-full"
            )}
        >
            <div className="block w-full xl:hidden">
                <ReturnButton onClick={handleNavigatePrevious} />
            </div>

            <div className="flex justify-start items-center w-full  gap-[5px] px-5 py-2.5 bg-white  border-b  border-[#cdcdcd] ">
                <div
                    onClick={handleNavigateNext}
                    className="flex justify-start items-center flex-grow gap-[5px]"
                >
                    <div className="flex flex-col justify-center items-start self-stretch flex-grow relative gap-[5px]">
                        <p className="self-stretch   text-sm text-left text-[#373737]">
                            <span className="self-stretch   text-sm font-bold text-left text-[#373737]">
                                {name}
                            </span>
                            <span className="self-stretch   text-sm text-left text-[#373737]">
                                {` @${username}`}
                            </span>
                        </p>
                        <p className=" text-xs text-left text-[#373737]">
                            {projectName}
                        </p>
                    </div>
                </div>
            </div>

            <div
                className={twMerge(
                    "flex flex-col justify-start items-start self-stretch flex-grow gap-[45px] px-1 xl:px-10  overflow-y-auto overflow-x-hidden  ",
                    processingChatScreen && "py-2"
                )}
            >
                <Message
                    handleNavigateNext={handleNavigateNext}
                    date={"enero 6, 2023 12:09 pm"}
                />

                <div className="flex justify-end items-center self-stretch  relative gap-2.5">
                    <div className="flex justify-start items-center">
                        <div className="flex justify-start items-center  relative gap-2.5 p-4 rounded-[15px] bg-[#f2f2f2]">
                            <p className=" text-base text-left text-[#292929]">
                                Hola, igualmente
                            </p>
                        </div>
                        <svg
                            width={16}
                            height={20}
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M15.1109 8.46154C16.2952 9.1453 16.2952 10.8547 15.1109 11.5385L0.455078 20L0.455079 0L15.1109 8.46154Z"
                                fill="#F2F2F2"
                            />
                        </svg>

                        <div className="w-[41px] h-[41px] relative   overflow-hidden rounded-[100px] border-2 border-[#d9d9d9]">
                            <img
                                src={"https://source.unsplash.com/random/41×41"}
                                alt="rectangle-1413.png"
                                className="w-[41px] h-[41px]  object-cover"
                            />
                            <svg
                                width={9}
                                height={10}
                                viewBox="0 0 9 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute left-[28px] top-[3px]  "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <circle
                                    cx="4.5"
                                    cy={5}
                                    r={4}
                                    fill="#00BC4F"
                                    stroke="#F2F2F2"
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                <Message handleNavigateNext={handleNavigateNext} />
                <Message handleNavigateNext={handleNavigateNext} />
                <Message handleNavigateNext={handleNavigateNext} />
                <Message handleNavigateNext={handleNavigateNext} />
                <Message handleNavigateNext={handleNavigateNext} />
                <Message handleNavigateNext={handleNavigateNext} />
                <Message handleNavigateNext={handleNavigateNext} />

                <div className="flex  items-center self-end relative gap-2.5 ">
                    <svg
                        width={41}
                        height={41}
                        viewBox="0 0 41 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" w-[41px] h-[41px] relative"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M13.6673 28.6146H17.084V26.0521H13.6673V28.6146ZM34.1673 17.0834H32.459C32.459 14.3073 30.11 11.9584 27.334 11.9584H21.7819V8.11462C21.7819 7.42061 21.1947 6.83337 20.5007 6.83337C19.7533 6.83337 19.2194 7.42061 19.2194 8.11462V11.9584H13.6673C10.8379 11.9584 8.54232 14.3073 8.54232 17.0834H6.83398C5.39258 17.0834 4.27148 18.2579 4.27148 19.6459V26.4792C4.27148 27.9206 5.39258 29.0417 6.83398 29.0417H8.54232C8.54232 31.8711 10.8379 34.1667 13.6673 34.1667H27.334C30.11 34.1667 32.459 31.8711 32.459 29.0417H34.1673C35.5553 29.0417 36.7298 27.9206 36.7298 26.4792V19.6459C36.7298 18.2579 35.5553 17.0834 34.1673 17.0834ZM8.54232 26.4792H6.83398V19.6459H8.54232V26.4792ZM29.8965 29.0417C29.8431 30.4831 28.722 31.6042 27.334 31.6042H13.6673C12.2259 31.6042 11.1048 30.4831 11.1048 29.0417V17.0834C11.1048 15.6954 12.2259 14.5209 13.6673 14.5209H27.334C28.722 14.5209 29.8965 15.6954 29.8965 17.0834V29.0417ZM34.1673 26.4792H32.459V19.6459H34.1673V26.4792ZM16.2298 17.9375C14.7884 17.9375 13.6673 19.112 13.6673 20.5C13.6673 21.9414 14.7884 23.0625 16.2298 23.0625C17.6178 23.0625 18.7923 21.9414 18.7923 20.5C18.7923 19.112 17.6178 17.9375 16.2298 17.9375ZM24.7715 17.9375C23.3301 17.9375 22.209 19.112 22.209 20.5C22.209 21.9414 23.3301 23.0625 24.7715 23.0625C26.1595 23.0625 27.334 21.9414 27.334 20.5C27.334 19.112 26.1595 17.9375 24.7715 17.9375ZM23.9173 28.6146H27.334V26.0521H23.9173V28.6146ZM18.7923 28.6146H22.209V26.0521H18.7923V28.6146Z"
                            fill="#D80027"
                        />
                    </svg>

                    <div className="flex justify-start items-center">
                        <svg
                            width={16}
                            height={21}
                            viewBox="0 0 16 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M0.889107 8.96154C-0.295201 9.6453 -0.295201 11.3547 0.889107 12.0385L15.5449 20.5L15.5449 0.5L0.889107 8.96154Z"
                                fill="#F2F2F2"
                            />
                        </svg>
                        <div className="flex flex-col justify-center items-start  relative gap-[5px] p-4 rounded-[10px] bg-[#f2f2f2]">
                            <p className="self-stretch  text-base font-bold text-left text-[#373737]">
                                Chatbot:
                            </p>
                            <div className="flex justify-center items-center  relative gap-[5px]">
                                <p className=" text-base text-left text-[#373737]">
                                    esta solicitando borrar este chat
                                </p>
                                <p className=" text-base text-left text-[#d80027]">
                                    @cristian
                                </p>
                            </div>
                            <p className="self-stretch  text-base text-left text-[#373737]">
                                Esperando confirmación de Alicia...
                            </p>
                            <div className="flex justify-start items-center self-stretch  gap-[5px]">
                                <div className="flex justify-center items-center flex-grow h-9 relative gap-2.5 px-8 py-2.5 rounded bg-[#292929]">
                                    <p className="text-sm text-left text-white">
                                        Cancelar petición
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-start items-center  w-full h-[59px] sticky  bottom-0 gap-2.5 px-4 py-2.5 border-t  border-[#cdcdcd] bg-white">
                <svg
                    width={50}
                    height={51}
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-[50px] h-[50px] xl:w-[30px] xl:h-[30px] relative"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <path
                        d="M15 5.8125C9.64844 5.8125 5.3125 10.1484 5.3125 15.5C5.3125 20.8516 9.64844 25.1875 15 25.1875C20.3516 25.1875 24.6875 20.8516 24.6875 15.5C24.6875 10.1484 20.3516 5.8125 15 5.8125ZM15 23.3125C10.6641 23.3125 7.1875 19.8359 7.1875 15.5C7.1875 11.2031 10.6641 7.6875 15 7.6875C19.2969 7.6875 22.8125 11.2031 22.8125 15.5C22.8125 19.8359 19.2969 23.3125 15 23.3125ZM11.875 14.875C12.5391 14.875 13.125 14.3281 13.125 13.625C13.125 12.9609 12.5391 12.375 11.875 12.375C11.1719 12.375 10.625 12.9609 10.625 13.625C10.625 14.3281 11.1719 14.875 11.875 14.875ZM18.125 14.875C18.7891 14.875 19.375 14.3281 19.375 13.625C19.375 12.9609 18.7891 12.375 18.125 12.375C17.4219 12.375 16.875 12.9609 16.875 13.625C16.875 14.3281 17.4219 14.875 18.125 14.875ZM18.2812 17.7266C17.4609 18.7031 16.25 19.25 15 19.25C13.7109 19.25 12.5 18.7031 11.7188 17.7266C11.3672 17.3359 10.7812 17.2969 10.3906 17.6094C10 17.9219 9.92188 18.5469 10.2734 18.9375C11.4453 20.3438 13.1641 21.125 15 21.125C16.7969 21.125 18.5156 20.3438 19.6875 18.9375C20.0391 18.5469 20 17.9219 19.5703 17.6094C19.1797 17.2969 18.5938 17.3359 18.2812 17.7266Z"
                        fill="#333333"
                    />
                </svg>

                <Input
                    // label="Escribe un mensaje"
                    placeholder="Escribe un mensaje"
                    // required
                    onChange={(e) => handleChange(e.target)}
                    value={formData.message}
                    name="message"
                    inputClassName={
                        "flex-grow w-full border-none outline-none "
                    }
                />
                <img src={attachmentSvg} alt="attachmentSvg" className="mr-3" />
                <Button
                    onClick={handleFormSubmission}
                    className="flex justify-center text-sm text-left text-white items-center  w-[135px] h-9 relative gap-2.5 px-8 py-2.5 rounded bg-[#292929]"
                >
                    Enviar
                </Button>
            </div>
        </div>
    );
}

export default Chat;
