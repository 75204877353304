import { useEffect } from "react";
import CreditMeter from "../../components/platform/CreditMeter";
import InviteFriendFAQ from "../../components/platform/InviteFriendFAQ";
import InviteFriendHero from "../../components/platform/InviteFriendHero";
import ReferEmailList from "../../components/platform/ReferEmailList";
import HowInviteWorks from "../../components/platform/HowInviteWorks";

function InviteFriend({ inviteLink = "https://www.eprof.com/post/5d6b9ea" }) {
    useEffect(() => {
        setTimeout(() => {
            if (window.location.href.includes("faq")) {
                document.getElementById("faq").scrollIntoView({
                    behavior: "smooth"
                });
            }
        }, 500);
        return () => {};
    }, []);

    return (
        <>
            <InviteFriendHero inviteLink={inviteLink} />
            <div className="flex flex-col  justify-start items-center relative gap-5 w-full lg:mt-[50px]  ">
                <div className="flex flex-col justify-start items-center gap-[100px]">
                    <CreditMeter />
                    <HowInviteWorks />
                    <ReferEmailList />
                    <InviteFriendFAQ />
                </div>
            </div>
        </>
    );
}

export default InviteFriend;
