import React from "react";
import { Button, COLOR, SIZE } from "../buttons/Button";

function TickDialog({
    buttonText = "Continuar",
    closeDialog,
    onClick,
    title = "Solicitud enviada!",
    message = ` Su solicitud ha sido enviada, será dirigido a la
sección de seguimiento para continuar el proceso.`
}) {
    return (
        <div className="flex flex-col justify-center items-center w-full h-screen">
            <div
                className="flex flex-col justify-start items-center w-full  lg:w-[456px] gap-5 p-[30px]   bg-white"
                style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
            >
                <div className="flex justify-center items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5">
                    <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 p-2 rounded-[100px] bg-[#ccf2dc]">
                        <svg
                            width={40}
                            height={40}
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative flex-grow-0 flex-shrink-0 w-10 h-10"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M20.0026 7.0835C12.8672 7.0835 7.08594 12.9168 7.08594 20.0002C7.08594 27.1356 12.8672 32.9168 20.0026 32.9168C27.0859 32.9168 32.9193 27.1356 32.9193 20.0002C32.9193 12.9168 27.0859 7.0835 20.0026 7.0835ZM20.0026 9.5835C25.7318 9.5835 30.4193 14.271 30.4193 20.0002C30.4193 25.7814 25.7318 30.4168 20.0026 30.4168C14.2214 30.4168 9.58594 25.7814 9.58594 20.0002C9.58594 14.271 14.2214 9.5835 20.0026 9.5835ZM27.2943 16.4064L26.0964 15.2085C25.888 14.9481 25.4714 14.9481 25.2109 15.2085L17.8672 22.5002L14.7422 19.3752C14.4818 19.1147 14.1172 19.1147 13.8568 19.3752L12.6589 20.521C12.4505 20.7814 12.4505 21.1981 12.6589 21.4064L17.3984 26.1981C17.6589 26.4585 18.0234 26.4585 18.2839 26.1981L27.2943 17.2918C27.5026 17.0314 27.5026 16.6147 27.2943 16.4064Z"
                                fill="#00BC4F"
                            />
                        </svg>
                    </div>
                    <div className="flex flex-col justify-start items-start flex-grow relative gap-2.5">
                        <p className="self-stretch flex-grow-0 flex-shrink-0 w-full  lg:w-[330px] text-[34px] text-left text-[#373737]">
                            {title}
                        </p>
                        <p className="self-stretch flex-grow-0 flex-shrink-0 w-full  lg:w-[330px] text-base text-left text-[#7b7b7b]">
                            {message}
                        </p>
                    </div>
                </div>
                <Button
                    color={COLOR.BLACK}
                    stretch
                    size={SIZE.LARGE}
                    onClick={closeDialog ?? onClick}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
}

export default TickDialog;
