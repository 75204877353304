import React, { useState } from "react";
import AuthWrapper from "../components/AuthTemplate";
import LoginStep1 from "../components/login/LoginStep1";
import LoginStep2 from "../components/login/LoginStep2";
import LoginStep3 from "../components/login/LoginStep3";
import LoginStep4 from "../components/login/LoginStep4";

function Login() {
    const [step, setStep] = useState(0);
    const navigateNext = () => setStep((s) => (s + 1) % 4);

    return (
        <AuthWrapper>
            {step === 0 && <LoginStep1 navigateNext={navigateNext} />}
            {step === 1 && <LoginStep2 navigateNext={navigateNext} />}
            {step === 2 && <LoginStep3 navigateNext={navigateNext} />}
            {step === 3 && <LoginStep4 navigateNext={navigateNext} />}
        </AuthWrapper>
    );
}

export default Login;