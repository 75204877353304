import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import {
    ArrowIcon,
    BellSilentIcon,
    BookIcon,
    CopyIcon,
    PhoneIcon,
    PlusIcon,
    VideoCam,
    ShareIcon
} from "../../../utils/icons";
import { noop } from "../../../utils/noop";

const COLOR = {
    RED: "RED",
    BLACK: "BLACK",
    WHITE: "WHITE",
    GRAY: "GRAY"
};
const SIZE = {
    SMALL: "sm",
    MEDIUM: "md",
    LARGE: "lg",
    LARGE_SMALL: "lgsm"
};

const STRETCH_CLASS = "w-full";

const SIZE_CLASSES = {
    sm: "px-3 py-1 sm:px-4 sm:py-1.5  h-9 text-sm  ",
    md: "px-4 py-1.5 sm:px-6 sm:py-2  h-9 text-sm sm:text-lg",
    lg: "px-6 py-2 sm:px-8 sm:py-2.5 h-12 text-md  sm:text-lg",
    lgsm: "px-3 py-1 xl:px-8 xl:py-2.5  h-9 xl:h-12 text-sm  xl:text-lg"
};

const BUTTON_CLASSES = {
    RED: "  min-w-fit    flex justify-center items-center   text-center text-white    relative gap-2.5 rounded bg-[#d80027]  ",
    BLACK: "  min-w-fit cursor-pointer flex justify-center items-center  text-center text-white     relative gap-2.5  rounded bg-[#292929]   ",
    WHITE: "  min-w-fit flex justify-center items-center  text-center text-[#373737]   relative gap-2.5  rounded border-2 border-[#292929] ",
    GRAY: "  min-w-fit cursor-pointer flex justify-center items-center  text-center text-white     relative gap-2.5  rounded bg-[#7b7b7b]   "
};

const HOVER_EFFECTS = {
    RED: "  ease-in-out transition-all duration-500   hover:opacity-80 ",
    BLACK: "    ease-in-out transition-all duration-500  hover:opacity-80 ",
    WHITE: "   hover:bg-[#292929] hover:text-white  ease-in-out transition-all duration-500 ",
    GRAY: "    ease-in-out transition-all duration-500  hover:opacity-80 ",
};

const Button = ({
    text = "Add Text Here",
    icon = null,
    color = COLOR.RED,GRAY,
    link = null,
    size = SIZE.LARGE,
    type = "button",

    stretch = false,
    iconName = null,

    onClick = noop,

    className = undefined,
    addClass = null,
    children = undefined
}) => {
    const navigate = useNavigate();

    const isCustomIcon = Boolean(iconName);
    // const [btnHover, setBtnHover] = useState(0);
    const [iconColor, setIconColor] = useState("#fff");

    const icons = {};
    icons[iconName] = 1;
    // console.log("iconName");
    // console.log(iconName);
    // console.log(icons);

    const handleClick = (e) => {
        e.stopPropagation();
        onClick(e);
        if (link) {
            navigate(link);
        }
    };

    const handleHover = useCallback(
        (isHovered) => {
            // setBtnHover(isHovered);
            if (color === COLOR.WHITE) {
                setIconColor(isHovered ? "#fff" : "#000");
            }
        },
        [color]
    );

    useEffect(() => {
        if (isCustomIcon) handleHover(0);
        return () => {};
    }, [handleHover, isCustomIcon]);

    return (
        <button
            type={type}
            onMouseLeave={isCustomIcon ? () => handleHover(0) : noop}
            onMouseOver={isCustomIcon ? () => handleHover(1) : noop}
            onClick={handleClick}
            className={
                className ??
                twMerge(
                    `${BUTTON_CLASSES[color]} ${HOVER_EFFECTS[color]} ${SIZE_CLASSES[size]}`,
                    addClass,
                    stretch && STRETCH_CLASS
                )
            }
        >
            {children ?? (
                <>
                    {text} {icon}{" "}
                    {icons.arrow ? (
                        <ArrowIcon color={iconColor || "white"} />
                    ) : null}
                    {icons.book ? <BookIcon color={iconColor} /> : null}
                    {icons.phone ? <PhoneIcon color={iconColor} /> : null}
                    {icons.videoCam ? <VideoCam color={iconColor} /> : null}
                    {icons.whitePhone ? <PhoneIcon color={"white"} /> : null}
                    {icons.plus ? <PlusIcon color={iconColor} /> : null}
                    {icons.copy ? <CopyIcon color={iconColor} /> : null}
                    {icons.share ? <ShareIcon color={iconColor} /> : null}
                    {icons.bellSilent ? (
                        <BellSilentIcon color={iconColor} />
                    ) : null}
                </>
            )}
        </button>
    );
};


export { Button, COLOR, SIZE };
