import React from "react";
import { Button, COLOR, SIZE } from "../../ui/buttons/Button";
import { Link } from "react-router-dom";

const PagoExitosModal = () => {
    return (
        <div>
            <div
                className="flex flex-col justify-start items-center w-[456px] gap-5 p-[30px] bg-white"
                style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
            >
                <div className="flex justify-center items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5">
                    <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2.5 p-2 rounded-[100px] bg-[#ccf2dc]">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-grow-0 flex-shrink-0 w-10 h-10 relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M19.9987 7.08203C12.8633 7.08203 7.08203 12.9154 7.08203 19.9987C7.08203 27.1341 12.8633 32.9154 19.9987 32.9154C27.082 32.9154 32.9154 27.1341 32.9154 19.9987C32.9154 12.9154 27.082 7.08203 19.9987 7.08203ZM19.9987 9.58203C25.7279 9.58203 30.4154 14.2695 30.4154 19.9987C30.4154 25.7799 25.7279 30.4154 19.9987 30.4154C14.2174 30.4154 9.58203 25.7799 9.58203 19.9987C9.58203 14.2695 14.2174 9.58203 19.9987 9.58203ZM27.2904 16.4049L26.0924 15.207C25.8841 14.9466 25.4674 14.9466 25.207 15.207L17.8633 22.4987L14.7383 19.3737C14.4779 19.1133 14.1133 19.1133 13.8529 19.3737L12.6549 20.5195C12.4466 20.7799 12.4466 21.1966 12.6549 21.4049L17.3945 26.1966C17.6549 26.457 18.0195 26.457 18.2799 26.1966L27.2904 17.2904C27.4987 17.0299 27.4987 16.6133 27.2904 16.4049Z"
                                fill="#00BC4F"
                            ></path>
                        </svg>
                    </div>
                    <div className="flex flex-col justify-start items-start flex-grow relative gap-2.5">
                        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[330px] text-[34px] text-left text-[#373737]">
                            Pago exitoso!
                        </p>
                        <p className="self-stretch flex-grow-0 flex-shrink-0 w-[330px] text-base text-left text-[#7b7b7b]">
                            Tus fondos se han agregado exitosamente, acepta para
                            continuar con el examen.
                        </p>
                    </div>
                </div>
               <Link to={"/platform/certificate-survey"}>

                    <Button
                        color={COLOR.BLACK}
                        size={SIZE.LARGE}
                        type="submit"
                        stretch
                        addClass={"h-11"}
                    >
                        Aceptar
                    </Button>
               </Link>
            </div>
        </div>
    );
};

export default PagoExitosModal;
