import React from "react";
import { Button, COLOR } from "../ui/buttons/Button";

function StarterWrapper({
    title = "Elige entre un sinfín de posibilidades",
    desc = `Publica tu solicitud legal o solicita asesoría personalizada con los mejores profesionales del derecho a través de una plataforma segura, elige una opción:`,
    tags = [
        "Contratación recomendada",
        "Contratación urgente",
        "Asesoría legal"
    ],
    ids = ["recommended", "urgent", "legal"]
}) {
    const scrollToSection = (id) => {
        document.getElementById(ids[id]).scrollIntoView({
            behavior: "smooth",
            inline: "nearest"
        });
    };
    return (
        <div className="flex flex-col items-center justify-start gap-10">
            <div className="flex flex-col items-center self-stretch justify-start gap-5">
                <div className="flex flex-col justify-start items-center self-stretch  relative gap-4 lg:gap-2.5">
                    <p className="self-stretch  font-[Satoshi] text-4xl lg:text-5xl font-bold text-center text-[#373737]">
                        {title}
                    </p>
                    <p className=" text-sm lg:text-xl text-center text-[#7b7b7b] w-[80vw] lg:w-[50vw]">
                        {desc}
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row justify-start items-start  gap-2.5">
                    {tags.map((tag, id) => (
                        <Button
                            onClick={() => scrollToSection(id)}
                            key={`tag_${id}`}
                            color={COLOR.WHITE}
                            addClass={"w-full"}
                            // className="flex justify-center w-full lg:w-auto items-center  h-12 relative gap-2.5 px-8 py-2.5 rounded border-2 border-[#292929]"
                        >
                            {/* <p className=" text-md lg:text-lg text-center lg:text-left text-[#373737]"> */}
                            {tag}
                            {/* </p> */}
                        </Button>
                    ))}
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-start items-start  gap-[37px]  ">
                <div className="relative flex items-center justify-start flex-grow gap-6">
                    <div
                        className="flex justify-start items-start  relative gap-[6.009933948516846px] bg-white"
                        style={{
                            boxShadow:
                                "0px 0px 12.019867897033691px 0 rgba(0,0,0,0.1)"
                        }}
                    >
                        <svg
                            width={61}
                            height={61}
                            viewBox="0 0 61 61"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            //  w-[60.
                            className="1px] h-[60.1px] relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M30.0497 10.6426C19.3288 10.6426 10.6426 19.3288 10.6426 30.0497C10.6426 40.7705 19.3288 49.4567 30.0497 49.4567C40.7705 49.4567 49.4567 40.7705 49.4567 30.0497C49.4567 19.3288 40.7705 10.6426 30.0497 10.6426ZM30.0497 45.7005C21.3634 45.7005 14.3988 38.7359 14.3988 30.0497C14.3988 21.4417 21.3634 14.3988 30.0497 14.3988C38.6576 14.3988 45.7005 21.4417 45.7005 30.0497C45.7005 38.7359 38.6576 45.7005 30.0497 45.7005ZM34.8232 37.5621C35.2927 37.8751 35.8405 37.7968 36.1535 37.3273L37.6403 35.371C37.9533 34.9014 37.8751 34.3536 37.4056 34.0406L32.2408 30.2062V19.094C32.2408 18.6245 31.7713 18.155 31.3017 18.155H28.7976C28.2498 18.155 27.8585 18.6245 27.8585 19.094V32.006C27.8585 32.2408 27.9368 32.5538 28.1716 32.7103L34.8232 37.5621Z"
                                fill="#292929"
                            />
                        </svg>
                    </div>
                    <p className="flex-grow9px text-sm] lg:text-xl font-bold text-left text-[#373737]">
                        Servicio 24 horas del día y 365 días del año
                    </p>
                </div>
                <div className="relative flex items-center justify-start flex-grow gap-6">
                    <div
                        className="flex justify-start items-start  relative gap-[6.009933948516846px] bg-white"
                        style={{
                            boxShadow:
                                "0px 0px 12.019867897033691px 0 rgba(0,0,0,0.1)"
                        }}
                    >
                        <svg
                            width={61}
                            height={61}
                            viewBox="0 0 61 61"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            //  w-[60.
                            className="1px] h-[60.1px] relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M30.0495 32.5538C36.2316 32.5538 41.3181 27.5455 41.3181 21.2852C41.3181 15.1031 36.2316 10.0165 30.0495 10.0165C23.7891 10.0165 18.7809 15.1031 18.7809 21.2852C18.7809 27.5455 23.7891 32.5538 30.0495 32.5538ZM30.0495 13.7727C34.1187 13.7727 37.5619 17.2159 37.5619 21.2852C37.5619 25.4326 34.1187 28.7976 30.0495 28.7976C25.902 28.7976 22.5371 25.4326 22.5371 21.2852C22.5371 17.2159 25.902 13.7727 30.0495 13.7727ZM37.1706 33.8841L32.7884 45.9353L31.3015 39.4402L33.8057 35.0579H26.2933L28.7974 39.4402L27.2323 45.9353L22.8501 33.8841C17.0593 33.9624 12.5205 38.5794 12.5205 44.3702V46.3266C12.5205 48.4394 14.1638 50.0828 16.2767 50.0828H43.8222C45.8569 50.0828 47.5785 48.4394 47.5785 46.3266V44.3702C47.5785 38.5794 42.9614 33.9624 37.1706 33.8841ZM20.0329 46.3266H16.2767V44.3702C16.2767 41.553 17.9201 39.2054 20.4242 38.1099L23.3979 46.3266H20.0329ZM43.8222 46.3266H36.6228L39.6748 38.1099C42.1006 39.2054 43.8222 41.553 43.8222 44.3702V46.3266Z"
                                fill="#292929"
                            />
                        </svg>
                    </div>
                    <p className="flex-grow9px text-sm] lg:text-xl font-bold text-left text-[#373737]">
                        Más de 100 profesionales registrados
                    </p>
                </div>
                <div className="relative flex items-center justify-start flex-grow gap-6">
                    <div
                        className="flex justify-start items-start  relative gap-[6.009933948516846px] bg-white"
                        style={{
                            boxShadow:
                                "0px 0px 12.019867897033691px 0 rgba(0,0,0,0.1)"
                        }}
                    >
                        <svg
                            width={61}
                            height={61}
                            viewBox="0 0 61 61"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            //  w-[60.
                            className="1px] h-[60.1px] relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M46.3266 20.0331H40.0663V16.2769C40.0663 14.2423 38.3447 12.5207 36.3101 12.5207H23.7894C21.6765 12.5207 20.0332 14.2423 20.0332 16.2769V20.0331H13.7728C11.6599 20.0331 10.0166 21.7547 10.0166 23.7893V43.8224C10.0166 45.9353 11.6599 47.5786 13.7728 47.5786H46.3266C48.3612 47.5786 50.0828 45.9353 50.0828 43.8224V23.7893C50.0828 21.7547 48.3612 20.0331 46.3266 20.0331ZM23.7894 16.2769H36.3101V20.0331H23.7894V16.2769ZM13.7728 43.8224V23.7893H20.0332V43.8224H13.7728ZM23.7894 43.8224V23.7893H36.3101V43.8224H23.7894ZM46.3266 43.8224H40.0663V23.7893H46.3266V43.8224Z"
                                fill="#292929"
                            />
                        </svg>
                    </div>
                    <p className="flex-grow9px text-sm] lg:text-xl font-bold text-left text-[#373737]">
                        Mas de 100 clientes atendidos
                    </p>
                </div>
            </div>
        </div>
    );
}

export default StarterWrapper;
