import React from 'react'
import { Link, Outlet } from "react-router-dom";
import { SubHeaderItem } from '../../../components/platform/PlatformHeader';


const SupportLayout = () => {
  return (
    <article className="mt-16 py-1.5">
        <BlackHeader />
        <div className='py-20 '>
            <Outlet />
        </div>
    </article>
  )
}

export default SupportLayout


const BlackHeader = () => {
    return (
        <div className="flex justify-start  items-center sm:w-full pl-0  sm:pl-[132px] bg-[#292929]">
            <div className="flex justify-center items-center sm:items-start w-full sm:w-auto  gap-[29px] overflow-x-auto">
                <Link to="/platform/support">
                    <SubHeaderItem active={true} text="Support" />
                </Link>
                <Link to="">
                    <SubHeaderItem active={""} text="Video tutorial" />
                </Link>
                <Link to="">
                    <SubHeaderItem active={""} text="Como funciona EPROF" />
                </Link>
                <Link to="">
                    <SubHeaderItem active={""} text="Preguntas frecuentes" />
                </Link>
                <Link to="">
                    <SubHeaderItem active={""} text="Cargos y tarifas" />
                </Link>
                <Link to="">
                    <SubHeaderItem active={""} text="Disputas" />
                </Link>
            </div>
        </div>
    );
};