import { useState } from "react";
import contact from "../assets/images/landing/contact.jpeg";
import Dialog from "./Dialog";
import Discount from "./Discount";
import Checkbox from "./ui/Checkbox";
import Input from "./ui/Input";
import { Button, COLOR, SIZE } from "./ui/buttons/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { noop } from "../utils/noop";
import { twMerge } from "tailwind-merge";
import SuccessDialog1 from "../components/dialogs/SuccessDialog1";

const validationSchema = Yup.object({
    firstName: Yup.string()
    .required("El nombre es obligatorio")
    .matches(/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/, "Solo se permiten letras en el nombre"),

lastName: Yup.string()
    .required("El apellido es obligatorio")
    .matches(/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]*$/, "Solo se permiten letras en el apellido"),
    phone: Yup.number()
        .typeError("Por favor ingresa un número válido")
        .max(999999999999, "Máximo 12 dígitos")
        .min(10000000, "Mínimo 8 dígitos")
        .required("Requerido"),
    email: Yup.string()
        .email("Correo electrónico inválido")
        .required("Campo requerido"),
    date: Yup.date().required("La fecha es obligatoria"),
    dpi: Yup.number()
        .typeError("El DPI debe ser un número")
        .required("El DPI es obligatorio"),
    consulta: Yup.string().required("La consulta es obligatoria")
});

function Contact({
    // contact is used normally in landing page and as a DIALOG in howitworks page
    closeDialog = noop,
    addClass = "",
    isDialog = undefined
}) {
    const [showDialog, setDialog] = useState(0);
    const [showDiscountDialog, setDiscountDialog] = useState(0);
    const [formData, setFormData] = useState({});
    const [acceptAgreement, setAgreement] = useState(false);
    console.log(formData);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            date: "",
            dpi: "",
            consulta: ""
        },
        validationSchema,
        onSubmit: async (values) => {
            setDialog(1);
            await new Promise(resolve => setTimeout(resolve, 3000));
            setDialog(null);
            setDiscountDialog(1);
            setFormData((data) => ({ ...data, ...values }));
        }
    });
    return (
        <>
         <Dialog
                showDialog={showDialog}
                setDialog={setDialog}
                main={<SuccessDialog1 setDialog={setDialog} />}
            />
            <Dialog
                showDialog={showDiscountDialog}
                setDialog={setDiscountDialog}
                main={
                    <Discount
                        setDiscountDialog={setDiscountDialog}
                        cost1={150}
                        cost2={110}
                        closeDialog={() => setDiscountDialog(0)}
                    />
                }
            />
            <div
                id="contactForm"
                className={twMerge(
                    "flex relative justify-center lg:justify-start items-center w-auto lg:w-full gap-6   bg-white h-full ",
                    addClass
                )}
            >
                {Boolean(isDialog) && (
                    <svg
                        // width={40}
                        // height={40}
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer w-10 h-10 absolute right-[5%] xl:right-[5px] top-[5px] z-10 "
                        preserveAspectRatio="xMidYMid meet"
                        onClick={closeDialog}
                    >
                        <path
                            d="M20.0002 7.0835C12.8647 7.0835 7.0835 12.8647 7.0835 20.0002C7.0835 27.1356 12.8647 32.9168 20.0002 32.9168C27.1356 32.9168 32.9168 27.1356 32.9168 20.0002C32.9168 12.8647 27.1356 7.0835 20.0002 7.0835ZM20.0002 30.4168C14.2189 30.4168 9.5835 25.7814 9.5835 20.0002C9.5835 14.271 14.2189 9.5835 20.0002 9.5835C25.7293 9.5835 30.4168 14.271 30.4168 20.0002C30.4168 25.7814 25.7293 30.4168 20.0002 30.4168ZM25.2606 16.771C25.521 16.5627 25.521 16.146 25.2606 15.8856L24.1147 14.7397C23.8543 14.4793 23.4377 14.4793 23.2293 14.7397L20.0002 17.9689L16.7189 14.7397C16.5106 14.4793 16.0939 14.4793 15.8335 14.7397L14.6877 15.8856C14.4272 16.146 14.4272 16.5627 14.6877 16.771L17.9168 20.0002L14.6877 23.2814C14.4272 23.4897 14.4272 23.9064 14.6877 24.1668L15.8335 25.3127C16.0939 25.5731 16.5106 25.5731 16.7189 25.3127L20.0002 22.0835L23.2293 25.3127C23.4377 25.5731 23.8543 25.5731 24.1147 25.3127L25.2606 24.1668C25.521 23.9064 25.521 23.4897 25.2606 23.2814L22.0314 20.0002L25.2606 16.771Z"
                            fill="#333333"
                        />
                    </svg>
                )}
                <div className="justify-start items-center flex-shrink self-stretch  relative gap-2.5 bg-[#f2f2f2] hidden lg:flex">
                    <img
                        src={contact}
                        alt="bodegon-balanza-justicia-1.jpeg"
                        className="self-stretch  w-[575px] opacity-80 object-cover mix-blend-luminosity"
                    />
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col items-start justify-start flex-grow gap-5 p-5"
                >
                    <div className="flex flex-col justify-start items-start self-stretch  relative gap-2.5">
                        <div className="relative flex flex-col items-start self-stretch justify-start">
                            {/* w-[577px] */}
                            <p className="font-[Satoshi] self-stretch   text-[34px] text-left text-[#373737]">
                                ¿Tienes alguna duda legal?
                            </p>
                            {/* w-[577px] */}
                            <p className=" self-stretch   text-[21px] font-bold text-left text-[#373737]">
                                Puedes consultar sin necesidad de registro
                            </p>
                        </div>
                        {/* w-[577px] */}
                        <p className=" self-stretch  text-[21px] text-left text-[#7b7b7b]">
                            Envia tu Consulta y nuestro grupo de expertos estará
                            atendiendo a la brevedad posible
                        </p>
                    </div>
                    <div className="flex flex-col justify-start items-start self-stretch  gap-2.5">
                        <div className="flex flex-col lg:flex-row justify-start items-start self-stretch gap-[21px]">
                            <div className="flex flex-col flex-grow w-full">
                                <Input
                                    id="firstName"
                                    label="Nombre"
                                    placeholder="Nombre"
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                    onBlur={formik.handleBlur}
                                    name="firstName"
                                />
                                {formik.touched.firstName &&
                                formik.errors.firstName ? (
                                    <div className="mt-1 text-red-600">
                                        {formik.errors.firstName}
                                    </div>
                                ) : null}
                            </div>
                            <div className="flex flex-col flex-grow w-full">
                                <Input
                                    id="lastName"
                                    placeholder="Apellido"
                                    label="Apellido"
                                    onBlur={formik.handleBlur}
                                    required
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                    name="lastName"
                                />
                                {formik.touched.lastName &&
                                formik.errors.lastName ? (
                                    <div className="mt-1 text-red-600">
                                        {formik.errors.lastName}
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        {/* <div className="flex flex-col lg:flex-row justify-start items-start self-stretch  h-fit gap-[21px]">
                            <Input
                                label="Nombre"
                                placeholder="Nombre"
                                required
                                onChange={formik.handleChange}
                                value={formik.values.firstName}
                                // onChange={(e) => handleChange(e.target)}
                                // value={formData.firstName}
                                name="firstName"
                            />
                            {formik.touched.firstName && formik.errors.firstName ? (
                                <div className="text-red-600 ">{formik.errors.firstName}</div>
                            ) : null}
                            <Input
                                label="Apellido"
                                placeholder="Apellido"
                                required
                                onChange={(e) => handleChange(e.target)}
                                value={formData.lastName}
                                name="lastName"
                            />
                        </div> */}
                        <div className="flex flex-col lg:flex-row justify-start items-start self-stretch  gap-2.5">
                            <div className="flex flex-col flex-grow w-full">
                                <Input
                                    label="Teléfono"
                                    placeholder="Teléfono"
                                    required
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                    name="phone"
                                    type="number"
                                />
                                {formik.touched.phone && formik.errors.phone ? (
                                    <div className="mt-1 text-red-600">
                                        {formik.errors.phone}
                                    </div>
                                ) : null}
                            </div>

                            <div className="flex flex-col flex-grow w-full">
                                <Input
                                    label="Correo electrónico"
                                    placeholder="Correo electrónico"
                                    required
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    // onChange={(e) => handleChange(e.target)}
                                    // value={formData.email}
                                    name="email"
                                    type="email"
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="mt-1 text-red-600">
                                        {formik.errors.email}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className=" flex justify-start items-center  relative gap-[5px]">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75ZM12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25ZM12 9.5C10.5938 9.5 9.5 10.625 9.5 12C9.5 13.4062 10.5938 14.5 12 14.5C13.375 14.5 14.5 13.4062 14.5 12C14.5 10.625 13.375 9.5 12 9.5Z"
                                    fill="#D80027"
                                />
                            </svg>
                            <p className="cursor-pointer text-sm text-left text-[#373737]">
                                Asesoría inmediata
                            </p>
                        </div>
                        <div className="flex flex-col lg:flex-row justify-start items-start self-stretch  gap-2.5">
                            <div className="relative flex flex-col flex-grow w-full">
                                <Input
                                    label="Programa tu asesoría"
                                    placeholder="07/19/1985"
                                    required
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.date}
                                    name="date"
                                    type="datetime-local"
                                />
                                {/* <span
                                    className="absolute cursor-pointer right-3 top-10"
                                    onClick={handleToggleCalendar}
                                >
                                    📅
                                </span>
                                {calendarVisible && <div style={calendarStyle} id="myCal"></div>} */}

                                {formik.touched.date && formik.errors.date ? (
                                    <div className="mt-1 text-red-600">
                                        {formik.errors.date}
                                    </div>
                                ) : null}
                            </div>
                            <div className="flex flex-col flex-grow w-full">
                                <Input
                                    label="DPI (CUI)"
                                    placeholder="DPI (CUI)"
                                    required
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.dpi}
                                    name="dpi"
                                />
                                {formik.touched.dpi && formik.errors.dpi ? (
                                    <div className="mt-1 text-red-600">
                                        {formik.errors.dpi}
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <Input
                            label="Consulta"
                            required
                            onChange={formik.handleChange}
                            value={formik.values.consulta}
                            name="consulta"
                            onBlur={formik.handleBlur}
                            type="long"
                        />
                        {formik.touched.consulta && formik.errors.consulta ? (
                            <div className="mt-1 text-red-600">
                                {formik.errors.consulta}
                            </div>
                        ) : null}

                        <div className="flex flex-col justify-start items-start self-stretch  relative gap-2.5">
                            {/* w-[577px] */}
                            <p className="self-stretch   text-sm font-bold text-left text-[#373737]">
                                Tus datos estan seguros con nosotros
                            </p>{" "}
                        </div>
                        <div className="flex justify-start items-center   relative gap-[5px]">
                            <Checkbox
                                checked={acceptAgreement}
                                toggleCheck={() =>
                                    setAgreement((prev) => !prev)
                                }
                                customElm={
                                    <p className="self-stretch flex-grow text-base text-left">
                                        {/* w-[715px] */}
                                        <span className="self-stretch flex-grow text-sm text-left text-[#373737]">
                                            Acepto que mis datos sean
                                            recopilados de acuerdo con la{" "}
                                        </span>
                                        <a
                                            href="/policy"
                                            className=" text-sm text-left text-[#d80027]"
                                        >
                                            política de privacidad *
                                        </a>
                                    </p>
                                }
                                // text=""
                            />
                            {/* w-[715px] */}
                        </div>
                        <Button
                            color={COLOR.BLACK}
                            size={SIZE.LARGE}
                            type="submit"
                            stretch
                            // className="cursor-pointer hover:opacity-80 flex justify-center items-center text-lg text-left text-white self-stretch  h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#292929]"
                        >
                            Enviar consulta
                        </Button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Contact;
