import React from "react";
import ReleasePayment from "../find-professional/ReleasePayment";
import avatar from "../../../assets/images/avatar2.png";
import { Button, COLOR } from "../../ui/buttons/Button";
// import avatar from "../../assets/images/avatar2.png";



const Payments = () => {
    return (
        <div className="flex flex-col md:flex-row gap-6 ">
            <div className="w-full max-w-[816px] p-4 bg-white border border-[#cdcdcd]">
                <ReleasePayment buttonRed={true}/>
            </div>
            <div className="p-[30px] space-y-[30px] border border-stone-300 self-start w-full  max-w-[276px]">
                <div className="flex flex-col justify-start items-start self-stretch relative gap-4">
                    <p className="w-full  max-w-[276px] h-[22px] text-base font-bold text-left text-[#373737]">
                        Profesional
                    </p>
                    <div className="flex justify-start items-center relative gap-2.5">
                        <div className="w-10 h-10 relative overflow-hidden rounded-full">
                          <img
                              src={avatar}
                              alt="rectangle-1413.png"
                              className="w-10 h-10 object-contain"
                          />
                        </div>
                        <div className="flex flex-col justify-center items-start gap-[5px]">
                            <div className="flex justify-start items-center relative gap-[5px]">
                                <p className="text-base font-bold text-left text-[#373737]">
                                    Abidemi K.
                                </p>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6 relative"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                        d="M21.9997 5H2V18.3333H21.9997V5Z"
                                        fill="white"
                                    ></path>
                                    <path
                                        d="M8.66643 5H2V18.3333H8.66643V5Z"
                                        fill="#4997D0"
                                    ></path>
                                    <path
                                        d="M22.0024 5H15.3359V18.3333H22.0024V5Z"
                                        fill="#4997D0"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                  color={COLOR.WHITE}
                  addClass={"w-36 h-9"}
                  text="chat"
                />
            </div>
        </div>
    );
};

export default Payments;
