import { useState } from "react";
import ReportTelephoneButton from "../ui/buttons/ReportTelephoneButton";
import { Tag } from "../ui/Tag";
import Thumb from "../ui/Thumb";

const CONTENT = {
    LAW: {
        url: "laws",
        type: "LAW",
        searchItemLogo: (
            <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="  lg:w-[50px] h-[50px] relative"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M20.8335 30.209H20.7684C20.7684 29.1673 20.8986 29.6882 15.2345 18.4251C14.7137 17.2533 13.6069 16.6673 12.435 16.6673C11.3283 16.6673 10.2215 17.2533 9.70067 18.4251C3.9715 29.7533 4.16681 29.2324 4.16681 30.209C4.16681 33.1387 7.87775 35.4173 12.5001 35.4173C17.0574 35.4173 20.8335 33.1387 20.8335 30.209ZM9.57046 25.6517C10.2866 24.1543 11.2632 22.2663 12.435 19.8574V19.7923H12.5001C13.7371 22.3965 14.7788 24.3496 15.4949 25.847C16.2111 27.3444 16.797 28.4512 17.1876 29.1673H7.74754C8.20327 28.3861 8.78921 27.2142 9.57046 25.6517ZM38.5418 38.5423H26.5626V18.4902C28.3205 17.9694 29.6876 16.472 30.0783 14.584H38.5418C39.0626 14.584 39.5835 14.1283 39.5835 13.5423V12.5007C39.5835 11.9798 39.0626 11.459 38.5418 11.459H29.7527C28.9064 9.63607 27.0835 8.33398 25.0001 8.33398C22.8517 8.33398 21.0288 9.63607 20.1824 11.459H11.4585C10.8725 11.459 10.4168 11.9798 10.4168 12.5007V13.5423C10.4168 14.1283 10.8725 14.584 11.4585 14.584H19.8569C20.2475 16.472 21.6147 17.9694 23.4376 18.4902V38.5423H11.4585C10.8725 38.5423 10.4168 39.0632 10.4168 39.584V40.6257C10.4168 41.2116 10.8725 41.6673 11.4585 41.6673H38.5418C39.0626 41.6673 39.5835 41.2116 39.5835 40.6257V39.584C39.5835 39.0632 39.0626 38.5423 38.5418 38.5423ZM25.0001 15.6257C23.8283 15.6257 22.9168 14.7142 22.9168 13.5423C22.9168 12.4355 23.8283 11.459 25.0001 11.459C26.1069 11.459 27.0835 12.4355 27.0835 13.5423C27.0835 14.7142 26.1069 15.6257 25.0001 15.6257ZM45.7684 30.209C45.7684 29.1673 45.8986 29.6882 40.2345 18.4251C39.7137 17.2533 38.6069 16.6673 37.435 16.6673C36.3283 16.6673 35.2215 17.2533 34.7007 18.4251C28.9715 29.7533 29.1668 29.2324 29.1668 30.209C29.1668 33.1387 32.8777 35.4173 37.5001 35.4173C42.0574 35.4173 45.8335 33.1387 45.8335 30.209H45.7684ZM32.7475 29.1673C33.2033 28.3861 33.7892 27.2142 34.5705 25.6517C35.2866 24.1543 36.2632 22.2663 37.435 19.8574V19.7923H37.5001C38.7371 22.3965 39.7788 24.3496 40.4949 25.847C41.2111 27.3444 41.797 28.4512 42.1876 29.1673H32.7475Z"
                    fill="#333333"
                />
            </svg>
        ),
        topText: null,
        title: "Amet minim mollit non deserunt ullamco est sit aliqua...",
        desc: "Decreto número 1551",
        closeTag: true,
        tags: ["Etiquette", "Etiquette", "Etiquette"],
        views: 500,
        likes: 128,
        dislikes: 8,
        commentCount: 0,
        uploadedBefore: "2 weeks"
    },
    JURIS: {
        url: "jurisprudence",
        type: "JURIS",
        searchItemLogo: (
            <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="  lg:w-[50px] h-[50px] relative"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M9.375 18.7507H40.625C41.1458 18.7507 41.6667 18.2949 41.6667 17.709V15.3652C41.6667 14.9095 41.3411 14.5189 40.9505 14.3887L25.7161 8.5293C25.4557 8.39909 25.1953 8.33398 25 8.33398C24.7396 8.33398 24.4792 8.39909 24.2188 8.5293L8.98438 14.3887C8.59375 14.5189 8.33333 14.9095 8.33333 15.3652V17.709C8.33333 18.2949 8.78906 18.7507 9.375 18.7507ZM25 11.5892L35.612 15.6257H14.3229L25 11.5892ZM42.7083 38.5423H41.6667V34.3757C41.6667 33.2689 40.5599 32.2923 39.2578 32.2923H37.5V20.834H34.375V32.2923H30.2083V20.834H27.0833V32.2923H22.9167V20.834H19.7917V32.2923H15.625V20.834H12.5V32.2923H10.6771C9.375 32.2923 8.33333 33.2689 8.33333 34.3757V38.5423H7.29167C6.70573 38.5423 6.25 39.0632 6.25 39.584V40.6257C6.25 41.2116 6.70573 41.6673 7.29167 41.6673H42.7083C43.2292 41.6673 43.75 41.2116 43.75 40.6257V39.584C43.75 39.0632 43.2292 38.5423 42.7083 38.5423ZM38.5417 38.5423H11.4583V35.4173H38.5417V38.5423Z"
                    fill="#333333"
                />
            </svg>
        ),
        topText: "Delito de hurto agravado",
        title: "Amet minim mollit non deserunt ullamco est sit aliqua...",
        desc: "Sentencia de casación No. 443-2021 de fecha 10-06-2022",
        closeTag: false,
        tags: ["Penal"],
        views: 148,
        likes: 39,
        dislikes: 8,
        commentCount: 0,
        uploadedBefore: "1 week"
    },
    DOCS: {
        url: "documents",
        type: "docs",
        searchItemLogo: (
            <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="  lg:w-[50px] h-[50px] relative"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M18.7493 18.2298C18.7493 18.5553 18.9447 18.7507 19.2702 18.7507H32.8118C33.0723 18.7507 33.3327 18.5553 33.3327 18.2298V16.1465C33.3327 15.8861 33.0723 15.6257 32.8118 15.6257H19.2702C18.9447 15.6257 18.7493 15.8861 18.7493 16.1465V18.2298ZM19.2702 23.959H32.8118C33.0723 23.959 33.3327 23.7637 33.3327 23.4382V21.3548C33.3327 21.0944 33.0723 20.834 32.8118 20.834H19.2702C18.9447 20.834 18.7493 21.0944 18.7493 21.3548V23.4382C18.7493 23.7637 18.9447 23.959 19.2702 23.959ZM38.7363 34.3757C39.1921 34.3105 39.5827 33.8548 39.5827 33.334V9.37565C39.5827 8.85482 39.0618 8.33398 38.541 8.33398H15.6243C12.6947 8.33398 10.416 10.6777 10.416 13.5423V36.459C10.416 39.3887 12.6947 41.6673 15.6243 41.6673H38.541C39.0618 41.6673 39.5827 41.2116 39.5827 40.6257V39.584C39.5827 39.1283 39.1921 38.6725 38.7363 38.6074C38.4108 37.7611 38.4108 35.222 38.7363 34.3757ZM36.0671 34.3757C35.8717 35.5475 35.8717 37.4355 36.0671 38.5423H15.6243C14.4525 38.5423 13.541 37.6309 13.541 36.459C13.541 35.3522 14.4525 34.3757 15.6243 34.3757H36.0671ZM36.4577 11.459V31.2507H15.6243C14.8431 31.2507 14.127 31.446 13.541 31.7064V13.5423C13.541 12.4355 14.4525 11.459 15.6243 11.459H36.4577Z"
                    fill="#333333"
                />
            </svg>
        ),
        topText: null,
        title: "Amet minim mollit non deserunt ullamco est sit aliqua...",
        desc: "Sentencia de casación No. 443-2021 de fecha 10-06-2022",
        closeTag: false,
        tags: ["Notariado", "Civil", "Familia"],
        views: 500,
        likes: 128,
        dislikes: 8,
        commentCount: 0,
        uploadedBefore: "2 days"
    },
    TELE: {
        url: "telephone",
        type: "TELE",
        searchItemLogo: null,
        topText: null,
        title: "Amet minim mollit non deserunt ullamco est sit aliqua...",
        desc: "(208) 555-0112 / (208) 555-0112 / (208) 555-0112"
        // closeTag: true,
        // tags: ["Etiquette", "Etiquette", "Etiquette"],
        // views: 500,
        // likes: 128,
        // dislikes: 8,
        // commentCount: 0,
        // uploadedBefore: "2 weeks"
    }
};

function SearchItemTemplate({
    type = "LAW",
    // searchItemLogo = (
    //     <svg
    //         width={50}
    //         height={50}
    //         viewBox="0 0 50 50"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="  lg:w-[50px] h-[50px] relative"
    //         preserveAspectRatio="xMidYMid meet"
    //     >
    //         <path
    //             d="M20.8335 30.209H20.7684C20.7684 29.1673 20.8986 29.6882 15.2345 18.4251C14.7137 17.2533 13.6069 16.6673 12.435 16.6673C11.3283 16.6673 10.2215 17.2533 9.70067 18.4251C3.9715 29.7533 4.16681 29.2324 4.16681 30.209C4.16681 33.1387 7.87775 35.4173 12.5001 35.4173C17.0574 35.4173 20.8335 33.1387 20.8335 30.209ZM9.57046 25.6517C10.2866 24.1543 11.2632 22.2663 12.435 19.8574V19.7923H12.5001C13.7371 22.3965 14.7788 24.3496 15.4949 25.847C16.2111 27.3444 16.797 28.4512 17.1876 29.1673H7.74754C8.20327 28.3861 8.78921 27.2142 9.57046 25.6517ZM38.5418 38.5423H26.5626V18.4902C28.3205 17.9694 29.6876 16.472 30.0783 14.584H38.5418C39.0626 14.584 39.5835 14.1283 39.5835 13.5423V12.5007C39.5835 11.9798 39.0626 11.459 38.5418 11.459H29.7527C28.9064 9.63607 27.0835 8.33398 25.0001 8.33398C22.8517 8.33398 21.0288 9.63607 20.1824 11.459H11.4585C10.8725 11.459 10.4168 11.9798 10.4168 12.5007V13.5423C10.4168 14.1283 10.8725 14.584 11.4585 14.584H19.8569C20.2475 16.472 21.6147 17.9694 23.4376 18.4902V38.5423H11.4585C10.8725 38.5423 10.4168 39.0632 10.4168 39.584V40.6257C10.4168 41.2116 10.8725 41.6673 11.4585 41.6673H38.5418C39.0626 41.6673 39.5835 41.2116 39.5835 40.6257V39.584C39.5835 39.0632 39.0626 38.5423 38.5418 38.5423ZM25.0001 15.6257C23.8283 15.6257 22.9168 14.7142 22.9168 13.5423C22.9168 12.4355 23.8283 11.459 25.0001 11.459C26.1069 11.459 27.0835 12.4355 27.0835 13.5423C27.0835 14.7142 26.1069 15.6257 25.0001 15.6257ZM45.7684 30.209C45.7684 29.1673 45.8986 29.6882 40.2345 18.4251C39.7137 17.2533 38.6069 16.6673 37.435 16.6673C36.3283 16.6673 35.2215 17.2533 34.7007 18.4251C28.9715 29.7533 29.1668 29.2324 29.1668 30.209C29.1668 33.1387 32.8777 35.4173 37.5001 35.4173C42.0574 35.4173 45.8335 33.1387 45.8335 30.209H45.7684ZM32.7475 29.1673C33.2033 28.3861 33.7892 27.2142 34.5705 25.6517C35.2866 24.1543 36.2632 22.2663 37.435 19.8574V19.7923H37.5001C38.7371 22.3965 39.7788 24.3496 40.4949 25.847C41.2111 27.3444 41.797 28.4512 42.1876 29.1673H32.7475Z"
    //             fill="#333333"
    //         />
    //     </svg>
    // ),
    // topText = null,
    // title = "Amet minim mollit non deserunt ullamco est sit aliqua...",
    // desc = "Decreto número 1551",
    // closeTag = true,
    // tags = ["Etiquette", "Etiquette", "Etiquette"],
    // views = 500,
    // likes = 128,
    // dislikes = 8,
    // commentCount = 0,
    // uploadedBefore = "2 weeks",
    liked = true,
    disliked = false,

    // important
    telephoneId = null
}) {
    const [likeStatus, setLikeStatus] = useState(
        liked ? "liked" : disliked ? "disliked" : false
    );
    if (type === "TELE") {
        return (
            <div className="flex justify-start items-start   lg:w-[816px] relative gap-5 p-5 bg-white border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd] hover:bg-[#FFF5F7]">
                <div className="flex flex-col justify-start items-start flex-grow relative gap-2.5">
                    <p className="self-stretch   lg:w-[716px] text-lg font-bold text-left text-[#373737]">
                        {CONTENT.TELE.title}
                    </p>
                    <div className="flex justify-start items-center   relative gap-2.5">
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative h-6 lg:w-6 "
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M19.125 14.3125L15.9375 12.9688C15.7812 12.9062 15.5938 12.8438 15.375 12.8438C14.9375 12.8438 14.5312 13.0625 14.2812 13.375L13.25 14.625C11.5938 13.7188 10.2812 12.4062 9.375 10.75L10.625 9.71875C10.9375 9.46875 11.1562 9.0625 11.1562 8.625C11.1562 8.40625 11.0938 8.21875 11.0312 8.0625L9.6875 4.875C9.46875 4.375 8.9375 4 8.34375 4C8.25 4 8.15625 4.03125 8.03125 4.0625L5.09375 4.71875C4.46875 4.875 4 5.4375 4 6.125C4 13.8125 10.2188 20.0312 17.875 20C18.5625 20 19.125 19.5312 19.2812 18.9062L19.9375 15.9688C19.9688 15.8438 20 15.75 20 15.6562C20 15.0625 19.625 14.5312 19.125 14.3125ZM17.8125 18.5312C11.0312 18.5 5.53125 12.9688 5.5 6.1875L8.3125 5.53125L9.625 8.59375L7.5 10.3438C8.96875 13.4375 10.5625 15.0312 13.6562 16.5L15.4062 14.375L18.4688 15.6875L17.8125 18.5312Z"
                                fill="#333333"
                            />
                        </svg>
                        <p className="  text-sm text-left text-[#7b7b7b]">
                            {CONTENT.TELE.desc}
                        </p>
                    </div>
                </div>
                <ReportTelephoneButton telephoneId={telephoneId} />
            </div>
        );
    }

    return (
        <div className="flex flex-col justify-start items-start   lg:w-[816px] gap-5 p-5 bg-white border-t-0 border-r-0 border-b border-l-0  hover:bg-[#FFF5F7] border-[#cdcdcd]">
            <div className="relative flex flex-col items-start self-stretch justify-start gap-5 lg:flex-row">
                {CONTENT[type].searchItemLogo}
                <a
                    href={`/platform/${CONTENT[type].url}/:passIdParam`}
                    className="flex flex-col justify-start items-start flex-grow relative gap-2.5"
                >
                    {CONTENT[type].topText ? (
                        <p className="  text-sm text-left text-[#7b7b7b]">
                            {CONTENT[type].topText}
                        </p>
                    ) : null}
                    <p className="underline self-stretch   lg:w-[581px] text-lg text-left text-[#373737]">
                        {CONTENT[type].title}
                    </p>
                    <p className="  text-sm text-left text-[#7b7b7b]">
                        {CONTENT[type].desc}
                    </p>
                    <div className="flex items-center justify-start gap-4 max-w-[80vw] h-14 overflow-x-auto">
                        {CONTENT[type].tags.map((tag, id) => (
                            <Tag
                                text={tag}
                                key={`tagseartemp${id}`}
                                hasClose={false}
                            />
                        ))}
                    </div>
                </a>
                <div className="flex flex-col justify-start items-end   relative gap-[5px]  ">
                    {CONTENT[type].views ? (
                        // lg:w-[105px]
                        <p className="self-stretch    text-base font-bold lg:text-right text-[#7b7b7b]">
                            {CONTENT[type].views} Views
                        </p>
                    ) : null}
                    <p className="  text-xs text-right text-[#7b7b7b]">
                        Uploaded {CONTENT[type].uploadedBefore} ago
                    </p>
                </div>
            </div>
            <div className="flex lg:justify-end items-center self-stretch   gap-[27px]">
                <div className="flex justify-start items-center   relative gap-[2.3480663299560547px]">
                    <Thumb
                        onClick={() =>
                            setLikeStatus(
                                likeStatus === "liked" ? false : "liked"
                            )
                        }
                        selected={likeStatus === "liked"}
                        count={CONTENT[type].likes}
                    />
                </div>
                <div className="flex justify-start items-center   relative gap-[2.3480663299560547px]">
                    <Thumb
                        onClick={() =>
                            setLikeStatus(
                                likeStatus === "disliked" ? false : "disliked"
                            )
                        }
                        dislike
                        selected={likeStatus === "disliked"}
                        count={CONTENT[type].dislikes}
                    />
                </div>
                <div className="flex justify-start items-center   relative gap-[2.3480663299560547px]">
                    <svg
                        width={25}
                        height={24}
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="relative h-6 lg:w-6 "
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M18.6523 4H6.65234C5.52734 4 4.65234 4.90625 4.65234 6V15C4.65234 16.125 5.52734 17 6.65234 17H9.65234V19.625C9.65234 19.875 9.80859 20 10.0273 20C10.0898 20 10.1523 20 10.2461 19.9375L14.1523 17H18.6523C19.7461 17 20.6523 16.125 20.6523 15V6C20.6523 4.90625 19.7461 4 18.6523 4ZM19.1523 15C19.1523 15.2812 18.9023 15.5 18.6523 15.5H13.6523L13.2461 15.8125L11.1523 17.375V15.5H6.65234C6.37109 15.5 6.15234 15.2812 6.15234 15V6C6.15234 5.75 6.37109 5.5 6.65234 5.5H18.6523C18.9023 5.5 19.1523 5.75 19.1523 6V15Z"
                            fill="#333333"
                        />
                    </svg>
                    <p className="  text-base text-left text-[#7b7b7b]">
                        {CONTENT[type].commentCount}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SearchItemTemplate;
