export const trimString = (str, maxLength) => {
  // Remove leading and trailing whitespace
  let trimmedStr = str.trim();

  // Trim the string to the specified maxLength
  if (trimmedStr.length > maxLength) {
    trimmedStr = trimmedStr.substring(0, maxLength) + "...";
  }

  return trimmedStr;
};
