import { useCallback, useState } from "react";
import Input from "../../components/ui/Input";
import { useScreenDimensions } from "../../hooks/useDeviceCheck";

function Analytics() {
    const [formData, setFormData] = useState({});

    const handleChange = useCallback(
        (target = {}) => {
            let { name, value } = target;
            console.log(target);
            setFormData((data) => ({ ...data, ...{ [name]: value } }));
            console.log(formData);
        },
        [formData]
    );
    const { width } = useScreenDimensions();

    return (
        <div className="flex flex-col justify-start items-center gap-5 w-full mt-[100px] px-[7%]">
            <div className="flex flex-col items-center justify-start gap-6">
                <div className="flex items-center justify-start w-full ">
                    <div className=" flex flex-col justify-start items-start flex-grow relative gap-[5px]">
                        <p className=" text-2xl font-bold text-left text-[#373737]">
                            Estadísticas del usuario
                        </p>
                        <p className=" text-sm text-left text-[#7b7b7b]">
                            Monitorea tu actividad y rendimiento mientras
                            utilizas Freelancer.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row  justify-start items-start w-full sm:max-w-[1176px] gap-6">
                    <div
                        className="flex flex-col sm:justify-between items-start self-stretch flex-grow sm:max-w-xl overflow-hidden gap-4 bg-white border border-[#cdcdcd]"
                        style={{ boxShadow: "0px 0px 10px 0 rgba(0,0,0,0.1)" }}
                    >
                        <div className="flex justify-start items-center self-stretch  h-11 relative gap-2.5 pl-4 bg-white border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                            <p className="flex-grow   text-sm font-bold text-left text-[#373737]">
                                Ganancias totales
                            </p>
                            <div className="flex justify-center items-center self-stretch  w-11 relative gap-2.5 bg-[#292929]">
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6 "
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M20.2188 13.75C19.25 12.9375 18.0625 13.25 17.4688 13.7188L15.5938 15.125H15.4688C15.4688 13.9375 14.5312 13 13.3125 13H8.8125C7.9375 13 7.0625 13.3125 6.375 13.8438L5.46875 14.5H3.5C3.21875 14.5 3 14.75 3 15V15.5C3 15.7812 3.21875 16 3.5 16H6L7.28125 15.0312C7.71875 14.6875 8.25 14.5 8.8125 14.5H13.3125C14.1875 14.5 14.2188 15.7812 13.2812 15.7812H11.4062C11.1875 15.7812 11 15.9688 11 16.1875V16.2188C11 16.4375 11.1875 16.625 11.4062 16.625H15.625C15.9375 16.625 16.2188 16.5312 16.4688 16.3438L18.375 14.9062C18.625 14.7188 19.0312 14.7188 19.25 14.9062C19.5938 15.1875 19.5625 15.625 19.25 15.875L16.0938 18.2188C15.8438 18.4062 15.5625 18.5 15.25 18.5H3.5C3.21875 18.5 3 18.75 3 19V19.5C3 19.7812 3.21875 20 3.5 20H15.25C15.875 20 16.5 19.8125 17 19.4375L20.125 17.0625C20.6562 16.6875 20.9688 16.0938 20.9688 15.4375C21 14.8125 20.7188 14.1875 20.2188 13.75ZM11.0312 8.53125L12.5938 8.96875C12.7188 9 12.7812 9.09375 12.7812 9.21875C12.7812 9.375 12.6875 9.5 12.5312 9.5H11.5C11.4062 9.5 11.2812 9.46875 11.1875 9.40625C11.0312 9.34375 10.8438 9.375 10.75 9.46875L10.1875 10.0312C10.0312 10.1875 10.0625 10.4688 10.25 10.5938C10.5312 10.8125 10.875 10.9062 11.25 10.9688V11.5C11.25 11.7812 11.4688 12 11.75 12H12.25C12.5 12 12.75 11.7812 12.75 11.5V10.9688C13.6875 10.8438 14.4062 10 14.2812 9C14.1875 8.28125 13.625 7.71875 12.9375 7.5L11.375 7.0625C11.25 7.03125 11.1875 6.90625 11.1875 6.8125C11.1875 6.65625 11.3125 6.53125 11.4375 6.53125H12.4688C12.5938 6.53125 12.6875 6.5625 12.7812 6.625C12.9375 6.6875 13.125 6.65625 13.2188 6.5625L13.7812 6C13.9375 5.84375 13.9375 5.5625 13.7188 5.4375C13.4375 5.21875 13.0938 5.125 12.75 5.0625V4.5C12.75 4.25 12.5 4 12.25 4H11.75C11.4688 4 11.25 4.25 11.25 4.5V5.0625C10.2812 5.1875 9.5625 6.03125 9.6875 7.03125C9.78125 7.75 10.3438 8.3125 11.0312 8.53125Z"
                                        fill="#F2F2F2"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row sm:justify-evenly sm:w-full xl:justify-start items-start   gap-[17px] px-4">
                            <div className="flex flex-col justify-start items-start  relative gap-[5px] border-t-0 xl:border-r border-b-0 border-l-0 border-[#cdcdcd]">
                                <div className="flex justify-start items-start  relative gap-2.5 pb-0.5 border-t-0 border-r-0 border-b-2 border-l-0 border-[#d80027]">
                                    <p className=" text-2xl font-bold text-left text-[#373737]">
                                        $10,493.35
                                    </p>
                                </div>
                                <p className=" sm:w-[177px] text-xs text-left text-[#7b7b7b]">
                                    Tus ganancias totales desde que te uniste a
                                    Eprof
                                </p>
                            </div>
                            <div className="flex flex-col justify-start items-start  relative gap-[5px]">
                                <div className="flex justify-start items-start  relative gap-2.5 pb-0.5 border-t-0 border-r-0 border-b-2 border-l-0 border-[#d80027]">
                                    <p className=" text-2xl font-bold text-left text-[#373737]">
                                        $478.04
                                    </p>
                                </div>
                                <p className=" sm:w-[177px] text-xs text-left text-[#7b7b7b]">
                                    Tus ganancias totales durante los ultimos 30
                                    días
                                </p>
                            </div>
                        </div>
                        <div className="cursor-pointer flex justify-start items-center self-stretch  h-11 relative gap-2.5 px-4 bg-[#f2f2f2] border-t border-r-0 border-b-0 border-l-0 border-[#cdcdcd]">
                            <p className="flex-grow   text-sm font-bold text-left text-[#373737]">
                                Ver detalles
                            </p>
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M15.625 15.4374L18.7188 12.3436C18.875 12.2186 18.875 11.9686 18.7188 11.8124L15.625 8.71863C15.375 8.49988 15 8.65613 15 8.99988V11.2186H5.375C5.15625 11.2186 5 11.3749 5 11.5936V12.5936C5 12.8124 5.15625 12.9686 5.375 12.9686H15V15.1874C15 15.5311 15.375 15.6874 15.625 15.4374Z"
                                    fill="#333333"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="flex flex-col items-start self-stretch justify-start flex-grow gap-2 sm:flex-row">
                        <div
                            className="w-full flex flex-col justify-start items-start self-stretch  flex-grow xl:min-w-[286px] overflow-hidden gap-4 bg-white border border-[#cdcdcd]"
                            style={{
                                boxShadow: "0px 0px 10px 0 rgba(0,0,0,0.1)"
                            }}
                        >
                            <div className="flex justify-start items-center self-stretch  h-11 relative gap-2.5 pl-4 bg-white border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                                <p className="flex-grow  text-sm font-bold text-left text-[#373737]">
                                    Proyectos
                                </p>
                                <div className="flex justify-center items-center self-stretch  w-11 relative gap-2.5 bg-[#292929]">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                            d="M18.5 8C19.3125 8 20 8.6875 20 9.5V17.5C20 18.3438 19.3125 19 18.5 19H5.5C4.65625 19 4 18.3438 4 17.5V9.5C4 8.6875 4.65625 8 5.5 8H8V6.5C8 5.6875 8.65625 5 9.5 5H14.5C15.3125 5 16 5.6875 16 6.5V8H18.5ZM9.5 6.5V8H14.5V6.5H9.5ZM5.6875 9.5C5.5625 9.5 5.5 9.59375 5.5 9.6875V12H18.5V9.6875C18.5 9.59375 18.4062 9.5 18.3125 9.5H5.6875ZM18.3125 17.5C18.4062 17.5 18.5 17.4375 18.5 17.3125V13.5H14V14.25C14 14.6875 13.6562 15 13.25 15H10.75C10.3125 15 10 14.6875 10 14.25V13.5H5.5V17.3125C5.5 17.4375 5.5625 17.5 5.6875 17.5H18.3125Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex justify-start items-start  gap-[17px] px-4">
                                <div className="flex flex-col justify-start items-start  relative gap-[5px]">
                                    <div className="flex justify-start items-start  relative gap-2.5 pb-0.5 border-t-0 border-r-0 border-b-2 border-l-0 border-[#d80027]">
                                        <p className=" text-left text-[#373737]">
                                            <span className=" text-2xl font-bold text-left text-[#373737]">
                                                23{" "}
                                            </span>
                                            <span className=" text-xl text-left text-[#373737]">
                                                proyectos activos
                                            </span>
                                        </p>
                                    </div>
                                    <p className=" sm:w-[177px] text-xs text-left text-[#7b7b7b]">
                                        <span className=" sm:w-[177px] text-xs text-left text-[#7b7b7b]">
                                            3 propuestas pendientes
                                        </span>
                                        <br />
                                        <span className=" sm:w-[177px] text-xs text-left text-[#7b7b7b]">
                                            6 proyectos pausados
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="cursor-pointer flex justify-start items-center self-stretch  h-11 relative gap-2.5 px-4 bg-[#f2f2f2] border-t border-r-0 border-b-0 border-l-0 border-[#cdcdcd]">
                                <p className="flex-grow  text-sm font-bold text-left text-[#373737]">
                                    Ver detalles
                                </p>
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6 "
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M15.625 15.4374L18.7188 12.3436C18.875 12.2186 18.875 11.9686 18.7188 11.8124L15.625 8.71863C15.375 8.49988 15 8.65613 15 8.99988V11.2186H5.375C5.15625 11.2186 5 11.3749 5 11.5936V12.5936C5 12.8124 5.15625 12.9686 5.375 12.9686H15V15.1874C15 15.5311 15.375 15.6874 15.625 15.4374Z"
                                        fill="#333333"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div
                            className="w-full flex flex-col justify-start items-start self-stretch  xl:min-w-[286px] flex-grow overflow-hidden gap-4 bg-white border border-[#cdcdcd]"
                            style={{
                                boxShadow: "0px 0px 10px 0 rgba(0,0,0,0.1)"
                            }}
                        >
                            <div className="flex justify-start items-center self-stretch  h-11 relative gap-2.5 pl-4 bg-white border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]">
                                <p className="flex-grow  text-sm font-bold text-left text-[#373737]">
                                    Horas trabajadas esta semana
                                </p>
                                <div className="flex justify-center items-center self-stretch  w-11 relative gap-2.5 bg-[#292929]">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="relative w-6 h-6 "
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                            d="M12 4.25C7.71875 4.25 4.25 7.71875 4.25 12C4.25 16.2812 7.71875 19.75 12 19.75C16.2812 19.75 19.75 16.2812 19.75 12C19.75 7.71875 16.2812 4.25 12 4.25ZM12 18.25C8.53125 18.25 5.75 15.4688 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25ZM13.9062 15C14.0938 15.125 14.3125 15.0938 14.4375 14.9062L15.0312 14.125C15.1562 13.9375 15.125 13.7188 14.9375 13.5938L12.875 12.0625V7.625C12.875 7.4375 12.6875 7.25 12.5 7.25H11.5C11.2812 7.25 11.125 7.4375 11.125 7.625V12.7812C11.125 12.875 11.1562 13 11.25 13.0625L13.9062 15Z"
                                            fill="#F2F2F2"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex justify-start items-start self-stretch flex-grow gap-[17px] px-4">
                                <div className="flex flex-col justify-start items-start  relative gap-[5px]">
                                    <div className="flex justify-start items-start  relative gap-2.5 pb-0.5 border-t-0 border-r-0 border-b-2 border-l-0 border-[#d80027]">
                                        <p className=" text-2xl font-bold text-left text-[#373737]">
                                            20.9h
                                        </p>
                                    </div>
                                    <p className=" sm:w-[177px] text-xs text-left text-[#828282]">
                                        Este mes: 42.8h
                                    </p>
                                </div>
                            </div>
                            <div className="cursor-pointer flex justify-start items-center self-stretch  h-11 relative gap-2.5 px-4 bg-[#f2f2f2] border-t border-r-0 border-b-0 border-l-0 border-[#cdcdcd]">
                                <p className="flex-grow  text-sm font-bold text-left text-[#373737]">
                                    Ver detalles
                                </p>
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="relative w-6 h-6 "
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M15.625 15.4374L18.7188 12.3436C18.875 12.2186 18.875 11.9686 18.7188 11.8124L15.625 8.71863C15.375 8.49988 15 8.65613 15 8.99988V11.2186H5.375C5.15625 11.2186 5 11.3749 5 11.5936V12.5936C5 12.8124 5.15625 12.9686 5.375 12.9686H15V15.1874C15 15.5311 15.375 15.6874 15.625 15.4374Z"
                                        fill="#333333"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-start gap-6 xl:flex-row xl:items-start">
                    {width < 800 ? (
                        <div className="flex flex-col justify-start items-start w-[333px] h-[413px] relative gap-2.5">
                            <p className="flex-grow-0 flex-shrink-0 text-lg font-bold text-left text-[#292929]">
                                Ganancias a lo largo del tiempo
                            </p>
                            <div
                                className="flex flex-col justify-center items-start flex-grow w-[339px] relative gap-[23.429601669311523px] p-5 bg-white"
                                style={{
                                    boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)"
                                }}
                            >
                                <div className="flex  justify-start items-center self-stretch   h-[34.89px]  ">
                                    <div className="flex justify-start items-start flex-grow gap-[20.34978485107422px]">
                                        <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[6.783261775970459px]">
                                            <svg
                                                width={8}
                                                height={9}
                                                viewBox="0 0 8 9"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="flex-grow-0 flex-shrink-0"
                                                preserveAspectRatio="xMidYMid meet"
                                            >
                                                <circle
                                                    cx="3.87615"
                                                    cy="4.68462"
                                                    r="3.87615"
                                                    fill="#D80027"
                                                />
                                            </svg>
                                            <p className="flex-grow-0 flex-shrink-0 text-sm font-bold text-center text-[#373737]">
                                                Importe ganado
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <Input
                                            // label="Programa tu asesoría"
                                            placeholder="Enero, 19/2023"
                                            // required
                                            onChange={(e) =>
                                                handleChange(e.target)
                                            }
                                            value={formData.date}
                                            name="date"
                                            type="date"
                                            addClass="text-sm p-0 p-2"
                                        />
                                    </div>
                                    {/* <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-9 relative gap-2.5 px-4 py-2.5 rounded border-2 border-[#292929]">
                                        <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-[#373737]">
                                            Enero, 19/2023
                                        </p>
                                        <svg
                                            width={20}
                                            height={21}
                                            viewBox="0 0 20 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="relative flex-grow-0 flex-shrink-0 w-5 h-5"
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <path
                                                d="M14.5827 5.68461H13.3327V4.33044C13.3327 4.17419 13.1764 4.01794 13.0202 4.01794H11.9785C11.7962 4.01794 11.666 4.17419 11.666 4.33044V5.68461H8.33268V4.33044C8.33268 4.17419 8.17643 4.01794 8.02018 4.01794H6.97852C6.79622 4.01794 6.66602 4.17419 6.66602 4.33044V5.68461H5.41602C4.71289 5.68461 4.16602 6.25753 4.16602 6.93461V16.1013C4.16602 16.8044 4.71289 17.3513 5.41602 17.3513H14.5827C15.2598 17.3513 15.8327 16.8044 15.8327 16.1013V6.93461C15.8327 6.25753 15.2598 5.68461 14.5827 5.68461ZM14.4264 16.1013H5.57227C5.4681 16.1013 5.41602 16.0492 5.41602 15.945V8.18461H14.5827V15.945C14.5827 16.0492 14.5046 16.1013 14.4264 16.1013Z"
                                                fill="#333333"
                                            />
                                        </svg>
                                    </div> */}
                                </div>
                                <div className="flex-grow-0 flex-shrink-0 w-[299px] h-[263.2px]">
                                    <svg
                                        width={196}
                                        height={225}
                                        viewBox="0 0 196 225"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-[194.23px] h-[223.85px]"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                            d="M0.892578 135.157C2.53716 133.31 6.41838 130.723 8.78659 135.157C11.7468 140.701 17.0095 155.113 21.4499 151.233C25.8903 147.353 33.1264 148.461 35.5933 155.113C38.0602 161.765 44.803 170.634 47.9277 157.885C51.0524 145.135 54.5061 121.853 59.4398 117.973C64.3736 114.093 65.6892 125.371 71.1164 120.798C74.4685 117.973 81.8062 99.1257 86.0821 102.452C90.358 105.778 97.2653 130.722 103.35 129.059C109.435 127.396 112.889 121.299 116.178 102.452C119.467 83.6042 119.312 74.3711 123.579 75.8436C130.157 78.1141 131.802 69.2448 135.913 50.9518C140.128 32.2004 143.643 54.8321 148.247 48.7345C152.852 42.6368 160.911 -4.53456 165.844 1.00876C170.778 6.55208 178.179 61.9853 185.086 64.2026C190.612 65.9765 194.077 61.6157 195.118 59.2136"
                                            stroke="#D80027"
                                            strokeWidth="0.969037"
                                        />
                                        <path
                                            opacity="0.4"
                                            d="M8.78659 135.157C6.41838 130.723 2.53716 133.31 0.892578 135.157V224.405H195.118V59.2136C194.077 61.6157 190.612 65.9765 185.086 64.2026C178.179 61.9853 170.778 6.55208 165.844 1.00876C160.911 -4.53456 152.852 42.6368 148.247 48.7345C143.643 54.8322 140.128 32.2004 135.913 50.9518C131.802 69.2448 130.157 78.1141 123.579 75.8436C119.312 74.3711 119.467 83.6042 116.178 102.452C112.889 121.299 109.435 127.396 103.35 129.059C97.2653 130.722 90.358 105.778 86.0821 102.452C81.8062 99.1257 74.4685 117.973 71.1164 120.798C65.6892 125.371 64.3736 114.093 59.4398 117.973C54.5061 121.853 51.0524 145.135 47.9277 157.885C44.803 170.634 38.0602 161.765 35.5933 155.113C33.1264 148.461 25.8903 147.353 21.4499 151.233C17.0095 155.113 11.7468 140.701 8.78659 135.157Z"
                                            fill="url(#paint0_linear_9245_14068)"
                                        />
                                        <defs>
                                            <lineargradient
                                                id="paint0_linear_9245_14068"
                                                x1="98.0053"
                                                y1="-162.241"
                                                x2="98.0053"
                                                y2="172.561"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stop-color="#A4A4A4" />
                                                <stop
                                                    offset={1}
                                                    stop-color="#A4A4A4"
                                                    stop-opacity={0}
                                                />
                                            </lineargradient>
                                        </defs>
                                    </svg>
                                    <div className="flex flex-col justify-start items-start w-[20.82px] h-[206.4px] absolute left-5 top-[96.46px] gap-[45.54475784301758px]">
                                        <p className="flex-grow-0 flex-shrink-0 w-[23.26px] h-[17.44px] text-xs font-bold text-center text-[#7b7b7b]">
                                            400
                                        </p>
                                        <p className="flex-grow-0 flex-shrink-0 w-[23.26px] h-[17.44px] text-xs font-bold text-center text-[#7b7b7b]">
                                            300
                                        </p>
                                        <p className="flex-grow-0 flex-shrink-0 w-[23.26px] h-[17.44px] text-xs font-bold text-center text-[#7b7b7b]">
                                            200
                                        </p>
                                        <p className="flex-grow-0 flex-shrink-0 w-[23.26px] h-[17.44px] text-xs font-bold text-center text-[#7b7b7b]">
                                            100
                                        </p>
                                    </div>
                                    <div className="flex justify-between items-center w-[189.78px] h-[17px] absolute left-[51.33px] top-[332.76px]">
                                        <p className="flex-grow w-[28.89px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Jan
                                        </p>
                                        <p className="flex-grow w-[28.89px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Feb
                                        </p>
                                        <p className="flex-grow w-[28.89px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Mar
                                        </p>
                                        <p className="flex-grow w-[28.89px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Apr
                                        </p>
                                        <p className="flex-grow w-[28.89px] text-xs font-bold text-left text-[#7b7b7b]">
                                            May
                                        </p>
                                        <p className="flex-grow w-[28.89px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Jun
                                        </p>
                                        <p className="flex-grow-0 flex-shrink-0 w-[16.47px] h-[17.44px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Jul
                                        </p>
                                    </div>
                                    <svg
                                        width={3}
                                        height={146}
                                        viewBox="0 0 3 146"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-[169.5px] top-[93.1px]"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <line
                                            x1="1.2917"
                                            y1="4.38072e-8"
                                            x2="1.2917"
                                            y2={146}
                                            stroke="url(#paint0_linear_9245_14084)"
                                            strokeWidth="1.79435"
                                            stroke-dasharray="3.59 3.59"
                                        />
                                        <defs>
                                            <lineargradient
                                                id="paint0_linear_9245_14084"
                                                x1="-0.6056"
                                                y1="-5.89913"
                                                x2="-0.605616"
                                                y2="148.317"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop
                                                    stop-color="#A4A4A4"
                                                    stop-opacity={0}
                                                />
                                                <stop
                                                    offset="0.251366"
                                                    stop-color="#A4A4A4"
                                                />
                                                <stop
                                                    offset="0.697917"
                                                    stop-color="#A4A4A4"
                                                />
                                                <stop
                                                    offset={1}
                                                    stop-color="#A4A4A4"
                                                    stop-opacity={0}
                                                />
                                            </lineargradient>
                                        </defs>
                                    </svg>
                                    {/* <svg
                                        width={188}
                                        height={2}
                                        viewBox="0 0 188 2"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-[240.22px] top-[162.1px]"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <line
                                            x1="187.117"
                                            y1="0.897173"
                                            x2="0.0183868"
                                            y2="0.897173"
                                            stroke="url(#paint0_linear_9245_14085)"
                                            strokeWidth="1.79435"
                                            stroke-dasharray="3.59 3.59"
                                        />
                                        <defs>
                                            <lineargradient
                                                id="paint0_linear_9245_14085"
                                                x1="194.677"
                                                y1="-1.00013"
                                                x2="-2.95145"
                                                y2="-1.00015"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stop-color="#A4A4A4" />
                                                <stop
                                                    offset="0.251366"
                                                    stop-color="#A4A4A4"
                                                />
                                                <stop
                                                    offset="0.697917"
                                                    stop-color="#A4A4A4"
                                                />
                                                <stop
                                                    offset={1}
                                                    stop-color="#A4A4A4"
                                                    stop-opacity={0}
                                                />
                                            </lineargradient>
                                        </defs>
                                    </svg> */}
                                    <div className="w-[24.26px] h-[27.1px]">
                                        <div className="w-[24.13px] h-[26.96px] absolute left-[157.39px] top-[147.86px] opacity-30 rounded-[100px] bg-[#7b7b7b]" />
                                        <div className="w-[11.53px] h-[12.88px] absolute left-[163.65px] top-[154.86px] rounded-[7.4px] bg-[#d80027] border-[3px] border-white" />
                                    </div>
                                    <div className="flex justify-start items-center w-[102px] h-14 absolute left-[217px] top-[242px] gap-[13.613006591796875px] px-[6.8065032958984375px] py-[2.7226009368896484px] rounded bg-[#d80027]">
                                        <p className="flex-grow w-[88.39px] text-[10px] text-left text-white">
                                            <span className="flex-grow w-[88.39px] text-[10px] text-left text-white">
                                                Mayo 23
                                            </span>
                                            <br />
                                            <span className="flex-grow w-[88.39px] text-[10px] font-bold text-left text-white">
                                                Ganados 400$ USD
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col justify-start items-start  h-[413px] w-[815px] relative gap-2.5">
                            <p className=" text-lg font-bold text-left text-[#292929]">
                                Ganancias a lo largo del tiempo
                            </p>
                            <div
                                className="flex flex-col justify-center items-start self-stretch flex-grow relative gap-[23.429601669311523px] p-5 bg-white"
                                style={{
                                    boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)"
                                }}
                            >
                                <div className="flex justify-between items-center self-stretch w-full  h-[34.89px]">
                                    <div className="flex justify-start items-start flex-grow gap-[20.34978485107422px]">
                                        <div className="flex justify-start items-center  relative gap-[6.783261775970459px]">
                                            <svg
                                                width={8}
                                                height={8}
                                                viewBox="0 0 8 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className=""
                                                preserveAspectRatio="xMidYMid meet"
                                            >
                                                <circle
                                                    cx="3.87615"
                                                    cy="4.07781"
                                                    r="3.87615"
                                                    fill="#D80027"
                                                />
                                            </svg>
                                            <p className=" text-sm font-bold text-center text-[#373737]">
                                                Importe ganado
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex justify-end items-center self-stretch  relative gap-2.5 p-2.5">
                                        <Input
                                            // label="Programa tu asesoría"
                                            placeholder="Enero, 19/2023"
                                            // required
                                            onChange={(e) =>
                                                handleChange(e.target)
                                            }
                                            value={formData.date}
                                            name="date"
                                            type="date"
                                            addClass="text-sm p-0 p-2 h-9 max-w-[200px]"
                                            // inputClassName={"h-9"}
                                        />
                                    </div>
                                    {/* <div className="flex justify-center items-center  h-9 relative gap-2.5 px-4 py-2.5 rounded border-2 border-[#292929]">
                                        <p className=" text-sm text-left text-[#373737]">
                                            Enero, 19/2023
                                        </p>
                                        <svg
                                            width={20}
                                            height={21}
                                            viewBox="0 0 20 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="relative w-5 h-5 "
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <path
                                                d="M14.5827 5.0778H13.3327V3.72363C13.3327 3.56738 13.1764 3.41113 13.0202 3.41113H11.9785C11.7962 3.41113 11.666 3.56738 11.666 3.72363V5.0778H8.33268V3.72363C8.33268 3.56738 8.17643 3.41113 8.02018 3.41113H6.97852C6.79622 3.41113 6.66602 3.56738 6.66602 3.72363V5.0778H5.41602C4.71289 5.0778 4.16602 5.65072 4.16602 6.3278V15.4945C4.16602 16.1976 4.71289 16.7445 5.41602 16.7445H14.5827C15.2598 16.7445 15.8327 16.1976 15.8327 15.4945V6.3278C15.8327 5.65072 15.2598 5.0778 14.5827 5.0778ZM14.4264 15.4945H5.57227C5.4681 15.4945 5.41602 15.4424 5.41602 15.3382V7.5778H14.5827V15.3382C14.5827 15.4424 14.5046 15.4945 14.4264 15.4945Z"
                                                fill="#333333"
                                            />
                                        </svg>
                                    </div> */}
                                </div>
                                <div className=" w-[783px] h-[260.42px]">
                                    <svg
                                        width={213}
                                        height={225}
                                        viewBox="0 0 613 225"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-[610.96px] h-[223.85px]"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                            d="M1.03906 135.551C6.21231 133.703 18.4212 131.116 25.8706 135.551C35.1825 141.094 51.7368 155.506 65.7046 151.626C79.6724 147.746 102.435 148.855 110.194 155.506C117.954 162.158 139.165 171.028 148.994 158.278C158.823 145.529 169.687 122.247 185.206 118.366C200.726 114.486 204.865 125.764 221.937 121.191C232.481 118.366 255.563 99.519 269.013 102.845C282.463 106.171 304.191 131.116 323.332 129.453C342.473 127.79 353.337 121.692 363.683 102.845C374.03 83.9975 373.543 74.7644 386.963 76.2369C407.656 78.5074 412.829 69.6381 425.762 51.3451C439.02 32.5937 450.077 55.2255 464.562 49.1278C479.047 43.0302 504.396 -4.14125 519.915 1.40207C535.435 6.94539 558.715 62.3786 580.442 64.5959C597.824 66.3698 608.723 62.009 611.999 59.6069"
                                            stroke="#D80027"
                                            strokeWidth="0.969037"
                                        />
                                        <path
                                            opacity="0.4"
                                            d="M25.8706 135.551C18.4212 131.116 6.21231 133.703 1.03906 135.551V224.798H611.999V59.6069C608.723 62.009 597.824 66.3698 580.442 64.5959C558.715 62.3786 535.435 6.94539 519.915 1.40207C504.396 -4.14125 479.047 43.0302 464.562 49.1278C450.077 55.2255 439.02 32.5937 425.762 51.3451C412.829 69.6381 407.656 78.5074 386.963 76.2369C373.543 74.7644 374.03 83.9975 363.683 102.845C353.337 121.692 342.473 127.79 323.332 129.453C304.191 131.116 282.463 106.171 269.013 102.845C255.563 99.519 232.481 118.366 221.937 121.191C204.865 125.764 200.726 114.486 185.207 118.366C169.687 122.247 158.823 145.529 148.994 158.278C139.165 171.028 117.954 162.158 110.194 155.507C102.435 148.855 79.6723 147.746 65.7046 151.626C51.7368 155.507 35.1825 141.094 25.8706 135.551Z"
                                            fill="url(#paint0_linear_9245_14068)"
                                        />
                                        <defs>
                                            <lineargradient
                                                id="paint0_linear_9245_14068"
                                                x1="306.519"
                                                y1="-161.848"
                                                x2="306.519"
                                                y2="172.954"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stop-color="#A4A4A4" />
                                                <stop
                                                    offset={1}
                                                    stop-color="#A4A4A4"
                                                    stop-opacity={0}
                                                />
                                            </lineargradient>
                                        </defs>
                                    </svg>
                                    <div className="flex flex-col justify-start items-start w-[23.26px] h-[206.4px] absolute left-5 top-[95.55px] gap-[45.54475784301758px]">
                                        <p className=" w-[23.26px] h-[17.44px] text-xs font-bold text-center text-[#7b7b7b]">
                                            400
                                        </p>
                                        <p className=" w-[23.26px] h-[17.44px] text-xs font-bold text-center text-[#7b7b7b]">
                                            300
                                        </p>
                                        <p className=" w-[23.26px] h-[17.44px] text-xs font-bold text-center text-[#7b7b7b]">
                                            200
                                        </p>
                                        <p className=" w-[23.26px] h-[17.44px] text-xs font-bold text-center text-[#7b7b7b]">
                                            100
                                        </p>
                                    </div>
                                    <div className="flex justify-start items-start w-[587px] h-[17px] absolute left-[55px] top-[331.36px]">
                                        <p className="flex-grow w-[95.09px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Jan
                                        </p>
                                        <p className="flex-grow w-[95.09px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Feb
                                        </p>
                                        <p className="flex-grow w-[95.09px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Mar
                                        </p>
                                        <p className="flex-grow w-[95.09px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Apr
                                        </p>
                                        <p className="flex-grow w-[95.09px] text-xs font-bold text-left text-[#7b7b7b]">
                                            May
                                        </p>
                                        <p className="flex-grow w-[95.09px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Jun
                                        </p>
                                        <p className=" w-[16.47px] h-[17.44px] text-xs font-bold text-left text-[#7b7b7b]">
                                            Jul
                                        </p>
                                    </div>
                                    <svg
                                        width={2}
                                        height={147}
                                        viewBox="0 0 2 147"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute left-[448.1px] top-[92.88px]"
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <line
                                            x1="0.897173"
                                            y1="0.775391"
                                            x2="0.897166"
                                            y2="146.775"
                                            stroke="url(#paint0_linear_9245_14084)"
                                            strokeWidth="1.79435"
                                            stroke-dasharray="3.59 3.59"
                                        />
                                        <defs>
                                            <lineargradient
                                                id="paint0_linear_9245_14084"
                                                x1="-1.00013"
                                                y1="-5.12374"
                                                x2="-1.00015"
                                                y2="149.093"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop
                                                    stop-color="#A4A4A4"
                                                    stop-opacity={0}
                                                />
                                                <stop
                                                    offset="0.251366"
                                                    stop-color="#A4A4A4"
                                                />
                                                <stop
                                                    offset="0.697917"
                                                    stop-color="#A4A4A4"
                                                />
                                                <stop
                                                    offset={1}
                                                    stop-color="#A4A4A4"
                                                    stop-opacity={0}
                                                />
                                            </lineargradient>
                                        </defs>
                                    </svg>
                                    <div className="w-[27.1px] h-[27.1px]">
                                        <div className="w-[26.96px] h-[26.96px] absolute left-[434.65px] top-[147.64px] opacity-30 rounded-[100px] bg-[#7b7b7b]" />
                                        <div className="w-[12.88px] h-[12.88px] absolute left-[441.65px] top-[154.64px] rounded-[7.4px] bg-[#d80027] border-[3px] border-white" />
                                    </div>
                                    <div className="flex justify-start items-center w-[114px] absolute left-[689px] top-[145px] gap-[13.613006591796875px] px-[6.8065032958984375px] py-[2.7226009368896484px] rounded bg-[#d80027]">
                                        <p className="flex-grow w-[100.39px] text-[10px] text-left text-white">
                                            <span className="flex-grow w-[100.39px] text-[10px] text-left text-white">
                                                Mayo 23
                                            </span>
                                            <br />
                                            <span className="flex-grow w-[100.39px] text-[10px] font-bold text-left text-white">
                                                Ganados 400$ USD
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col justify-start items-start  relative gap-2.5">
                        <p className=" text-lg font-bold text-left text-[#292929]">
                            Calendario de actividades
                        </p>
                        <div
                            className="flex flex-col justify-start items-start  sm:w-[336px] gap-[5px] p-3 bg-white"
                            style={{
                                boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)"
                            }}
                        >
                            <div className="flex justify-start items-center self-stretch  relative gap-[19px]">
                                <p className="flex-grow sm:w-[253px] text-lg font-bold text-left text-[#373737]">
                                    December 2023
                                </p>
                                <svg
                                    width={40}
                                    height={17}
                                    viewBox="0 0 40 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-10 h-4 "
                                    preserveAspectRatio="none"
                                >
                                    <g opacity="0.2">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M29.4572 15.457L28.043 14.0428L33.8359 8.24992L28.043 2.45703L29.4572 1.04282L36.6643 8.24992L29.4572 15.457Z"
                                            fill="#A4A4A4"
                                        />
                                    </g>
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10.5428 1.04297L11.957 2.45718L6.16414 8.25008L11.957 14.043L10.5428 15.4572L3.33571 8.25008L10.5428 1.04297Z"
                                        fill="#7B7B7B"
                                    />
                                </svg>
                            </div>
                            <div className="flex flex-col items-center self-stretch justify-start ">
                                <div className="flex items-center self-stretch justify-start ">
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5">
                                        <p className=" w-5 h-5 text-xs font-bold text-center text-[#292929]">
                                            Mo
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5">
                                        <p className=" w-5 h-5 text-xs font-bold text-center text-[#292929]">
                                            Tu
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5">
                                        <p className=" w-5 h-5 text-xs font-bold text-center text-[#292929]">
                                            We
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5">
                                        <p className=" w-5 h-5 text-xs font-bold text-center text-[#292929]">
                                            Th
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5">
                                        <p className=" w-5 h-5 text-xs font-bold text-center text-[#292929]">
                                            Fr
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5">
                                        <p className=" w-5 h-5 text-xs font-bold text-center text-[#292929]">
                                            Sa
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5">
                                        <p className=" w-5 h-5 text-xs font-bold text-center text-[#292929]">
                                            Su
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center self-stretch justify-start ">
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            1
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            2
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            3
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            4
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            5
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            6
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            7
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center self-stretch justify-start ">
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            8
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            9
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            10
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            11
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            12
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            13
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            14
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center self-stretch justify-start ">
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            15
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            16
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            17
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5 bg-[#d80027] border border-[#cdcdcd]">
                                        <p className="w-5 h-5 text-xs font-bold text-center text-white ">
                                            18
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            19
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            20
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            21
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center self-stretch justify-start ">
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            22
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            23
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            24
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            25
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            26
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            27
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            28
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center self-stretch justify-start ">
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            29
                                        </p>
                                    </div>
                                    <div className="cursor-pointer hover:bg-red-300 transition-all duration-100 flex flex-col justify-center items-center flex-grow relative p-2.5 border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#292929]">
                                            30
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5 bg-white border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#7b7b7b]">
                                            1
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5 bg-white border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#7b7b7b]">
                                            2
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5 bg-white border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#7b7b7b]">
                                            3
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5 bg-white border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#7b7b7b]">
                                            4
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center items-center flex-grow relative p-2.5 bg-white border border-[#cdcdcd]">
                                        <p className=" w-5 h-5 text-xs text-center text-[#7b7b7b]">
                                            5
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-start items-start self-stretch  gap-2.5">
                                <div className="relative flex flex-col items-start self-stretch justify-start flex-grow overflow-hidden">
                                    <p className=" text-xs font-bold text-left text-[#d80027]">
                                        8 AM
                                    </p>
                                    <p className=" text-sm font-bold text-left text-[#292929]">
                                        Desayuno
                                    </p>
                                </div>
                                <div className="relative flex flex-col items-start self-stretch justify-start flex-grow overflow-hidden">
                                    <p className=" text-xs font-bold text-left text-[#d80027]">
                                        10 AM
                                    </p>
                                    <p className="self-stretch  sm:w-[151px] text-sm font-bold text-left text-[#292929]">
                                        Reunion con cliente
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-end items-center self-stretch  relative gap-2.5 p-2.5">
                                <p className=" text-sm text-right text-[#d80027]">
                                    Ver agenda
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Analytics;
