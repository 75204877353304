import React from "react";
import frame2 from "../../assets/images/frame2.png";
import Underline from "../../assets/icons/Underline";

function About() {
    return (
        <div className="flex flex-col-reverse md:flex-row w-full max-w-[1274px] mx-auto gap-5 items-center">
            <div className="flex flex-col flex-1 gap-5 justify-start items-start px-5 py-5">
                {/* w-[576px] */}
                <p className="font-[inter] self-stretch  w-full  text-lg font-bold text-left text-[#7b7b7b]">
                    ¿Como lo ayudamos?
                </p>
                {/* w-[576px] */}
                <p className="font-[Satoshi] self-stretch w-full text-[41px] sm:text-[61px] font-bold text-left text-[#373737] relative">
                    Legal EPROF, la{" "}
                    <span className="inline-block max-w-min">
                        ayuda
                        <Underline />
                    </span>{" "}
                    legal que necesitas.
                </p>
                {/* w-[576px] */}
                <p className=" self-stretch  w-full text-base text-left text-[#7b7b7b]">
                    Cada día, un número creciente de usuarios confían en nuestra
                    plataforma en busca de profesionales del derecho. Si
                    necesitas asesoría legal de calidad, estás en el lugar
                    adecuado. En nuestra plataforma, encontrarás una comunidad
                    de abogados y notarios altamente cualificados listos para
                    ayudarte. Nuestro compromiso es proporcionar a nuestros
                    usuarios el mejor asesoramiento y trabajo legal, ya sea en
                    línea o en persona, al mismo tiempo que apoyamos a abogados
                    de diversas especialidades y notarios que ofrecen servicios
                    tanto a nivel nacional como internacional, ayudándoles a
                    fortalecer su presencia en línea y su reputación
                    profesional.
                </p>
                <div className="flex justify-start items-start w-full  gap-[30px] flex-col sm:flex-row  ">
                    <div className="flex relative flex-col flex-grow justify-start items-start">
                        <p className="font-[Satoshi] self-stretch  text-5xl font-bold text-left text-[#d80027]">
                            30+
                        </p>
                        <p className=" self-stretch  text-lg font-bold text-left text-[#7b7b7b]">
                            beneficios para los Clientes y Profesionales
                        </p>
                    </div>
                    <div className="flex relative flex-col flex-grow justify-start items-start">
                        <p className="font-[Satoshi] self-stretch  text-5xl font-bold text-left text-[#d80027]">
                            10x
                        </p>
                        <p className="self-stretch  text-lg font-bold text-left text-[#7b7b7b]">
                            Optimización en respuesta de sus solicitudes
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex-1 p-5 max-h-max md:p-0">
                <img src={frame2} alt="frame2" className="object-contain" />
            </div>
        </div>
    );
}

export default About;
