// import React, { useEffect } from "react";
import JobDetailsUnregistred from "../../../pages/platform/jobs/JobDetailsUnregistred";
// import Dialog from "../../Dialog";
//import { Button } from "../../ui/buttons/Button";
// import FinalChooseProf30Sec from "./ChoosenProfessionalDialog";

// const ChooseTimeHeader = () => {
//     const [showDialog, setShowDialog] = React.useState(false);
//     const [time, setTime] = React.useState(15 * 60);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             setTime((prev) => {
//                 if (prev === 0) {
//                     setShowDialog(true);
//                     clearInterval(interval);
//                     return 0;
//                 }
//                 return prev - 1;
//             });
//         }, 1000);
//         return () => clearInterval(interval);
//     }, []);

//     return (
//         <>
//             <Dialog
//                 main={
//                     showDialog ? (
//                         <FinalChooseProf30Sec
//                             closeDialog={() => setShowDialog(false)}
//                         />
//                     ) : null
//                 }
//                 setDialog={setShowDialog}
//                 showDialog={showDialog}
//             />
//             <div className="flex justify-between items-center self-stretch gap-[30px] p-2.5 bg-[#fff7ce]">
//                 <p className="w-full max-w-[905px] text-[21px] text-left text-black">
//                     Usted tiene 15 minutos para elegir su candidato de los
//                     contrario se le asignara uno de forma automática
//                 </p>
//                 <p className="text-[38px] font-bold text-left text-[#d80027]">
//                     {Math.floor(time / 60) < 10 ? "0" : ""}
//                     {Math.floor(time / 60)}:{time % 60 < 10 ? "0" : ""}
//                     {time % 60}
//                 </p>
//             </div>
//         </>
//     );
// };

function ChooseProfessional({ goToNext }) {
   

    return (
        <div className="flex flex-col justify-start items-center gap-[68px]">
            {/* <ChooseTimeHeader /> Timer on and of */}
            <JobDetailsUnregistred
                showContactButton={false}
                CustomChooseProf
                className="flex relative flex-col w-full"
            />
        </div>
    );
}

export default ChooseProfessional;
