import { noop } from "../../utils/noop";

function Navigate({ firstPage = true, setFirstPage = noop }) {
    return (
        // items-end sm:items-start
        <div className="flex justify-center  items-end w-auto border-t-0 border-r-0 border-b border-l-0 border-[#cdcdcd]   mb-[10vh]   ">
            <button
                disabled={firstPage}
                onClick={() => setFirstPage(true)}
                className="flex flex-col justify-between sm:justify-center items-start min-h-[42px] h-full rounded-tl-[5px] rounded-tr-[5px] "
            >
                <div className="flex flex-col sm:flex-row justify-center items-center self-stretch flex-grow relative gap-[5px] px-4 py-[5px] min-h-[42px] ">
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" w-[30px] h-[30px] relative"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M15 16.25C18.0859 16.25 20.625 13.75 20.625 10.625C20.625 7.53906 18.0859 5 15 5C11.875 5 9.375 7.53906 9.375 10.625C9.375 13.75 11.875 16.25 15 16.25ZM15 6.875C17.0312 6.875 18.75 8.59375 18.75 10.625C18.75 12.6953 17.0312 14.375 15 14.375C12.9297 14.375 11.25 12.6953 11.25 10.625C11.25 8.59375 12.9297 6.875 15 6.875ZM18.5547 16.9141L16.3672 22.9297L15.625 19.6875L16.875 17.5H13.125L14.375 19.6875L13.5938 22.9297L11.4062 16.9141C8.51562 16.9531 6.25 19.2578 6.25 22.1484V23.125C6.25 24.1797 7.07031 25 8.125 25H21.875C22.8906 25 23.75 24.1797 23.75 23.125V22.1484C23.75 19.2578 21.4453 16.9531 18.5547 16.9141ZM10 23.125H8.125V22.1484C8.125 20.7422 8.94531 19.5703 10.1953 19.0234L11.6797 23.125H10ZM21.875 23.125H18.2812L19.8047 19.0234C21.0156 19.5703 21.875 20.7422 21.875 22.1484V23.125Z"
                            fill={firstPage ? "#373737  " : "#7b7b7b"}
                        />
                    </svg>
                    <p
                        className={` text-base text-center ${
                            firstPage
                                ? "text-[#373737] font-bold"
                                : "text-[#7b7b7b]"
                        } `}
                    >
                        ¿Cómo contratar a un profesional?
                    </p>
                </div>
                <div className="flex flex-col justify-start items-start self-stretch  relative gap-2.5 min-h-fit">
                    <div
                        className={`self-stretch  h-0.5  ${
                            firstPage
                                ? "bg-[#d80027]"
                                : " bg-[#a4a4a4] opacity-0  "
                        }`}
                    />
                </div>
            </button>
            <button
                disabled={!firstPage}
                onClick={() => setFirstPage(false)}
                className="flex flex-col justify-between sm:justify-center items-start min-h-[45px] rounded-tl-[5px] rounded-tr-[5px] "
            >
                <div className="flex  flex-col sm:flex-row justify-center items-center self-stretch flex-grow relative gap-[5px] px-4 py-[5px] min-h-fit">
                    <svg
                        width={30}
                        height={30}
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" w-[30px] h-[30px] relative"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <path
                            d="M18.4766 16.875C17.3438 16.875 16.8359 17.5 15 17.5C13.125 17.5 12.6172 16.875 11.4844 16.875C8.59375 16.875 6.25 19.2578 6.25 22.1484V23.125C6.25 24.1797 7.07031 25 8.125 25H21.875C22.8906 25 23.75 24.1797 23.75 23.125V22.1484C23.75 19.2578 21.3672 16.875 18.4766 16.875ZM21.875 23.125H8.125V22.1484C8.125 20.2734 9.60938 18.75 11.4844 18.75C12.0703 18.75 12.9688 19.375 15 19.375C16.9922 19.375 17.8906 18.75 18.4766 18.75C20.3516 18.75 21.875 20.2734 21.875 22.1484V23.125ZM15 16.25C18.0859 16.25 20.625 13.75 20.625 10.625C20.625 7.53906 18.0859 5 15 5C11.875 5 9.375 7.53906 9.375 10.625C9.375 13.75 11.875 16.25 15 16.25ZM15 6.875C17.0312 6.875 18.75 8.59375 18.75 10.625C18.75 12.6953 17.0312 14.375 15 14.375C12.9297 14.375 11.25 12.6953 11.25 10.625C11.25 8.59375 12.9297 6.875 15 6.875Z"
                            fill={!firstPage ? "#373737  " : "#7b7b7b"}
                        />
                    </svg>

                    <p
                        className={` text-base text-center ${
                            !firstPage
                                ? "text-[#373737] font-bold"
                                : "text-[#7b7b7b]"
                        } `}
                    >
                        ¿Cómo ganar dinero como profesional?
                    </p>
                </div>
                <div className="flex flex-col justify-start items-start self-stretch    relative gap-2.5">
                    {/* <div className=" h-0.5  bg-[#a4a4a4] opacity-0  " /> */}

                    <div
                        className={`self-stretch  h-0.5   ${
                            !firstPage
                                ? "bg-[#d80027] "
                                : " bg-[#a4a4a4] opacity-0   "
                        }`}
                    />
                </div>
            </button>
        </div>
    );
}

export default Navigate;
