import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import MyProjectsNav from "../MyProjects/MyProjectsNav";
import Input from "../../../components/ui/Input";
import { Button, COLOR, SIZE } from "../../../components/ui/buttons/Button";

const links = [
    { to: ".", text: "Tickets abiertos" },
    { to: "result", text: "Resuelto actual" },
];

const TicketHistory = () => {
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    return (
        <div className="flex flex-col   gap-10 w-full max-w-[1173px]  mx-auto px-4 xl:px-0">
            <div className="flex  items-center   overflow-hidden gap-2">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className=" w-6 h-6 "
                    preserveAspectRatio="none"
                >
                    <path
                        d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                        stroke="#292929"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                    <path
                        d="M20.5019 12H3.67188"
                        stroke="#292929"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
                <p className=" text-base text-left text-[#7b7b7b]">Soporte</p>
                <p className=" text-base text-left text-[#7b7b7b]">/</p>
                <p className=" text-base text-left text-[#7b7b7b]">
                    Centro de ayuda para clientes
                </p>
                <p className=" text-base text-left text-[#d80027]">/</p>
                <p className=" text-base text-left text-[#d80027]">
                    Historial de tickets
                </p>
            </div>
            <div className="flex flex-col sm:flex-row items-center w-full   gap-6 sm:gap-10">
                <p className=" text-3xl sm:text-[40px] flex-grow font-bold text-left text-[#373737]">
                    Historial de tickets
                </p>
                <div className="flex flex-row-reverse justify-start items-center   gap-6">
                    <Button
                        color={COLOR.RED}
                        size={SIZE.MEDIUM}
                        type="submit"
                        addClass={"h-11 text-sm"}
                        onClick={() => navigate("../contact-us")}
                    >
                        Crear un nuevo ticket
                    </Button>
                    <p className=" text-base font-bold text-left text-[#d80027]">
                        Tickets (2)
                    </p>
                </div>
            </div>
            <div className="flex flex-col w-full max-w-[607px] gap-[5px]">
                <Input
                    placeholder="Busca un referido por correo electrónico"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    name="search"
                    icon={
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6 "
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                fill="#333333"
                            />
                        </svg>
                    }
                />
            </div>
            <div className="flex flex-col     gap-3">
                <p className=" text-2xl font-bold text-left text-[#373737]">
                    Tus tickets
                </p>
                <p className=" text-base text-left text-[#373737]">
                    Esta es la descripción de la lista de tus tickets
                </p>
            </div>

            <div className="flex flex-col w-full max-w-[1173px]">
                {/* <div className="flex   ">
                    <div className="flex flex-col justify-center   h-[42px] rounded-tl-[5px] rounded-tr-[5px]">
                        <div className="flex justify-center items-center self-stretch   gap-[5px] px-4 py-[5px]">
                            <p className=" text-base font-bold text-center text-[#373737]">
                                Tickets abiertos
                            </p>
                        </div>
                        <div className="flex flex-col   self-stretch   gap-2.5">
                            <div className="self-stretch  h-0.5 bg-[#d80027]"></div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center   h-[42px] rounded-tl-[5px] rounded-tr-[5px]">
                        <div className="flex justify-center items-center self-stretch   gap-[5px] px-4 py-[5px]">
                            <p className=" text-base text-center text-[#7b7b7b]">
                                Resuelto
                            </p>
                        </div>
                        <div className="flex flex-col    w-[121px]  gap-2.5">
                            <div className=" w-0.5 h-0.5 opacity-0 bg-[#a4a4a4]"></div>
                        </div>
                    </div>
                </div> */}
                <MyProjectsNav links={links} />
                <Outlet />
                {/* <div className="flex flex-col  gap-[30px] p-4 border border-[#cdcdcd]">
                    <div className="flex flex-col justify-center items-center self-stretch ">
                        <div className="flex  items-center self-stretch   gap-4 px-4 py-3 bg-[#f2f2f2]">
                            <p className=" w-[222.5px] text-sm font-bold text-left text-[#373737]">
                                Nombre
                            </p>
                            <p className=" w-[222.5px] text-sm font-bold text-left text-[#373737]">
                                Tamaño
                            </p>
                            <p className=" w-[222.5px] text-sm font-bold text-left text-[#373737]">
                                Propietario
                            </p>
                            <p className=" w-[222.5px] text-sm font-bold text-left text-[#373737]">
                                Modificado
                            </p>
                            <p className=" w-[155px] text-sm font-bold text-left text-[#373737]">
                                Acciones
                            </p>
                        </div>
                        <div className="flex flex-col justify-center  self-stretch  gap-6 px-4 py-[30px]">
                            <div className="flex flex-col justify-center  self-stretch   gap-3">
                                <p className="self-stretch  w-[1109px] text-sm text-center text-[#373737]">
                                    No hay entradas para mostrar en esta
                                    pestaña.
                                </p>
                                <p className="self-stretch  w-[1109px] text-sm text-center text-[#d80027]">
                                    Haga clic aquí para abrir un nuevo ticket.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default TicketHistory;
