import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import TickDialog from "../../../components/ui/dialogs/TickDialog";
import Input from "../../../components/ui/Input";
import InfoHover from "../../../components/ui/InfoHover";
import { UploadDocuments } from "../../../components/client-only/UrgentHiringForm";
import { Button, COLOR, SIZE } from "../../../components/ui/buttons/Button";

const SupportContact = () => {
    const [showTickDialog, setShowTickDialog] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});

    const fileHandler = useCallback((event) => {
        const documents = event.target.files;
        setFormData({
            documents
        });
    }, []);
    const handleChange = useCallback(
        (e) => {
            // console.log("e : ");
            // console.log(e);
            const { name, value } = e.target;
            if (name === "documents") {
                fileHandler(e);
                return;
            }
            if (name === "areas") {
                if (
                    Array.isArray(formData.areas) &&
                    formData.areas.includes(value)
                ) {
                    return;
                }
                setFormData((prev) => ({
                    ...prev,
                    areas: [...prev.areas, value]
                }));
                return;
            }
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        },
        [fileHandler, formData.areas]
    );

    const removeFileByIndex = (index) => {
        setFormData((prevFormData) => {
            // Create a copy of the documents array
            const newDocuments = [...prevFormData.documents];

            // Check if the index is valid
            if (index >= 0 && index < newDocuments.length) {
                // Remove the file at the specified index
                newDocuments.splice(index, 1);
                console.log(`File at index ${index} removed successfully.`);
            } else {
                console.log(`Invalid index: ${index}`);
            }

            // Return the updated formData object
            return { ...prevFormData, documents: newDocuments };
        });
    };

    if (showTickDialog)
        return (
            <TickDialog
                title="Solicitud enviada!"
                message="Nuestro grupo de expertos estará atendiendo a la brevedad posible."
                buttonText="Aceptar"
                onClick={
                    // redirect to /
                    () => navigate("/")
                }
            />
        );

    return (
        <div className="flex flex-col justify-center items-center w-full max-w-[1180px] mx-auto gap-10 px-5">
            <div className="flex flex-col  w-full  gap-10">
                <div className="flex justify-start items-center   overflow-hidden gap-2">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className=" w-6 h-6 "
                        preserveAspectRatio="none"
                    >
                        <path
                            d="M9.57 5.92969L3.5 11.9997L9.57 18.0697"
                            stroke="#292929"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                        <path
                            d="M20.5019 12H3.67188"
                            stroke="#292929"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                    <p className=" text-base text-left text-[#7b7b7b]">
                        Soporte
                    </p>
                    <p className=" text-base text-left text-[#7b7b7b]">
                        /
                    </p>
                    <p className=" text-base text-left text-[#7b7b7b]">
                        Centro de ayuda para clientes
                    </p>
                    <p className=" text-base text-left text-[#d80027]">
                        /
                    </p>
                    <p className=" text-base text-left text-[#d80027]">
                        Contáctenos
                    </p>
                </div>
                <div className="flex flex-col  w-full   gap-3">
                    <div className="flex flex-col sm:flex-row justify-start items-center    gap-10">
                        <p className="flex-grow  text-[40px] font-bold text-left text-[#373737]">
                            Contáctenos
                        </p>
                        <div className="flex flex-row-reverse justify-start items-center   gap-6">
                            
                            <Button
                                color={COLOR.RED}
                                size={SIZE.MEDIUM}
                                type="submit"
                                addClass={"h-11 text-sm"}
                                onClick={""}
                            >
                                Crear un nuevo ticket
                            </Button>
                            <p className=" text-base font-bold text-left text-[#d80027]">
                                Tickets (2)
                            </p>
                        </div>
                    </div>
                    <p className="self-stretch  text-base text-left text-[#373737]">
                        Por favor complete este formulario y uno de nuestros
                        agentes le responderá por correo electrónico lo antes
                        posible.
                    </p>
                </div>
            </div>
            <div className="flex flex-col w-full  max-w-[696px] gap-5">
                <div className="flex flex-col sm:flex-row gap-5">
                    
                    <Input
                        label="Nombre"
                        placeholder="Nombre"
                        required
                        onChange={handleChange}
                        value={formData.name}
                        name="name"
                    />
                    <Input
                        label="Correo"
                        placeholder="Correo"
                        required
                        onChange={handleChange}
                        value={formData.correo}
                        name="correo"
                    />
                   
                </div>

                <Input
                    label="Departamento"
                    placeholder="General"
                    disabled
                    onChange={handleChange}
                    value={formData.email}
                    name="department"
                />

                <Input
                    label="Asunto"
                    placeholder="Nombre"
                    required
                    onChange={handleChange}
                    value={formData.asunto}
                    name="asunto"
                />

                <Input
                    type="long"
                    value={formData.description}
                    label="Descripción"
                    name="description"
                    onChange={handleChange}
                    // required
                    placeholder="Describe aquí tu inquietud"
                    textAreaLimit={1200}
                    addInputClass="h-[129px] "
                />

                <div className="flex flex-col gap-[5px] w-full">
                    <div className="flex justify-start items-center  gap-[5px]">
                        <InfoHover red={false} text={"Adjuntar archivos"} />

                        <p className=" text-sm text-left text-[#373737]">
                            Adjuntar archivos
                        </p>
                    </div>

                    <UploadDocuments
                        files={formData.documents}
                        handleChange={handleChange}
                        text="+Añadir imagen"
                        removeFile={removeFileByIndex}
                        supportPage={true}
                    />
                </div>

                <div className="flex justify-between items-center w-full">
                    <Button
                        color={COLOR.RED}
                        size={SIZE.MEDIUM}
                        type="submit"
                        addClass={"h-11"}
                        onClick={() => setShowTickDialog(true)}
                    >
                        Enviar
                    </Button>
                    <Button
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        addClass={"h-11"}
                        onClick={""}
                    >
                        Reiniciar
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SupportContact;
