import About from "./landing/About";
import Blog from "./landing/Blog";
import Features from "./landing/Features";
import Hero from "./landing/Hero";
import Hiring from "./landing/Hiring";
import LandingHowItWorks from "./landing/LandingHowItWorks";
import Network from "./landing/Network";
import SearchBar from "./landing/SearchBar";
import Whatsapp from "./landing/Whatsapp";

const Home = () => {
    return (
        <>
            <Whatsapp />
            <Hero />
            <SearchBar className="pb-[50px]" />
            <About />
            <LandingHowItWorks />
            <Features />
            <div className="px-[20px] pt-10 sm:px-[7%]">
                <Network />
                <Hiring />
                <Blog />
            </div>
        </>
    );
};

export default Home;
