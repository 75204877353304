import { Link, Outlet, useParams } from "react-router-dom";
import jobDetailsDummyData from "../../../pages/data/platform/jobDetails.json";
import MyProjectsNav from "../../../pages/platform/MyProjects/MyProjectsNav";
import HeaderBack from "../../form/HeaderBack";
// import jobDetailsDummyData from "../../data/platform/jobDetails.json";

const RecommendedHiringDetailsLayout = () => {
    const { lawyerId } = useParams();
    const links = [
        { to: ".", text: "Detalles" },
        { to: "proposals", text: "Propuestas" },
        { to: "payments", text: "Pagos" },
        { to: "files", text: "Archivos" },
        { to: "tasks", text: "List de tareas" },
        { to: "rate", text: "Calificar" }
    ];

    console.log("lawyerId = ", lawyerId);
    console.log("hello");
    const { title, offerCount, averageOffer, suggestedOffer } =
        jobDetailsDummyData;

    return (
        <div className="px-1 py-10 mt-28">
            <div className=" w-full max-w-[1176px] mx-auto">
                <Link to=".." relative="path">
                    <HeaderBack
                        text={
                            <span className="font-['Nunito'] text-base leading-[normal] text-left text-[#d80027]">
                                Volver atrás
                            </span>
                        }
                    />
                </Link>

                <div
                    className={`flex relative flex-col gap-4 justify-start items-start py-6 w-full sm:flex-row`}
                >
                    <p className="flex-grow w-full max-w-[946px] lg:text-lg text-xl font-bold text-left text-[#373737] ">
                        {title}
                    </p>
                    <div className="flex flex-col justify-start items-start shrink-0   relative gap-2.5 pl-5 border-t-0 border-r-0 border-b-0 border-l border-[#cdcdcd]">
                        <p className="  lg:text-lg text-xl text-left text-[#373737]">
                            {offerCount} ofertas
                        </p>
                        <div className="flex justify-start items-start   gap-2.5">
                            <div className="flex relative flex-col justify-start items-start">
                                <p className="  text-lg font-bold text-left text-[#373737]">
                                    GTQ {averageOffer}
                                </p>
                                <p className="  text-xs text-center text-[#7b7b7b]">
                                    Oferta promedio
                                </p>
                            </div>
                            <div className="flex relative flex-col justify-start items-start">
                                <p className="  text-lg font-bold text-left text-[#373737]">
                                    GTQ {suggestedOffer}
                                </p>
                                <p className="  text-xs text-center text-[#7b7b7b]">
                                    Oferta sugerida
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <MyProjectsNav links={links} />

                <Outlet context={jobDetailsDummyData} />
            </div>
        </div>
    );
};

export default RecommendedHiringDetailsLayout;
