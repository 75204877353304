import React from "react";
import { twMerge } from "tailwind-merge";
import avatar from "../../../assets/images/landing/profile.png";

function ChatItem({
    active = false,
    handleNavigateNext = () => {},
    processingChatScreen = false
}) {
    return (
        <div
            onClick={handleNavigateNext}
            className={`flex cursor-pointer hover:bg-[#f1f1f1] justify-start items-center self-stretch  relative gap-2.5 px-[13px] py-2 ${
                active ? "bg-[#f2f2f2] border-l-[3px] border-[#d80027]" : ""
            } `}
        >
            {/* <div className="w-[41px] h-[41px]   overflow-hidden rounded-[100px] border-2 border-[#d9d9d9]">
                <img
                    src={avatar}
                    alt="rectangle-1413.png"
                    className="w-[41px] h-[41px] object-cover rounded-[999px]   border-2 border-[#d9d9d9]"
                />
            </div> */}
            <img
                src={avatar}
                alt="rectangle-1413.png"
                className="w-[41px] h-[41px] object-cover rounded-[999px]   border-2 border-[#d9d9d9]"
            />
            {/* <div className=" w-[41px] h-[41px] relative overflow-hidden rounded-[100px] border-2 border-[#d9d9d9]">
                <img
                    src={avatar}
                    alt="rectangle-1413.png"
                    className="w-[41px] h-[41px] absolute left-[-1.37px] top-[-1.37px] object-cover"
                />
            </div> */}
            <div className="flex flex-col w-full">
                <div className="flex relative justify-start items-center self-stretch">
                    <p className="flex flex-grow text-sm text-left">
                        <span className="  text-sm font-bold text-left text-[#373737]">
                            Cristian Fer...{" "}
                        </span>
                        <span className="  text-sm text-left text-[#7b7b7b]">
                            @cristian
                        </span>
                    </p>
                    <p className=" text-xs text-left text-[#7b7b7b]">1 hora</p>
                </div>
                <p className="self-stretch   text-xs text-left text-[#7b7b7b]">
                    Tú: me parece excelente 👍
                </p>
            </div>
            <svg
                width={9}
                height={9}
                viewBox="0 0 9 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={twMerge(
                    processingChatScreen && "absolute left-[44px] top-[24px]"
                )}
                preserveAspectRatio="xMidYMid meet"
            >
                <circle
                    cx="4.5"
                    cy="4.5"
                    r={4}
                    fill="#00BC4F"
                    stroke="#F2F2F2"
                />
            </svg>
        </div>
    );
}

export default ChatItem;
