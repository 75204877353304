import React, { useState } from "react";
import Input from "../ui/Input";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";

const CreateNewTaskModal = ({ cancel }) => {
    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        console.log(e.target.value);
        console.log(e.target.name);
        const { name, value } = e?.target;
        setFormData((pre) => ({
            ...pre,
            [name]: value,
        }));
    };

    const saveNewTask = () => {
        localStorage.setItem("task", JSON.stringify(formData))
        cancel()
        // navigate("")
    }

    return (
        <div
            className="flex flex-col w-full md:min-w-[674px] gap-[30px] p-[30px] rounded-[20px] bg-white"
            style={{ boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)" }}
        >
            <div className="flex flex-col  gap-[30px] w-full">
                
                <div className="flex flex-col  gap-3 w-full">
                    <Input
                        label="Nombre de la tarea*"
                        placeholder="Escribir aquí el nombre de la lista de tareas"
                        required
                        onChange={handleChange}
                        // value={formik.values.firstName}
                        // onChange={(e) => handleChange(e.target)}
                        value={formData.taskName}
                        name="taskName"
                    />

                    <div className="flex gap-5">
                        <Input
                            label="Asignar a"
                            placeholder="Buscar asignado"
                            required
                            onChange={handleChange}
                            // value={formik.values.firstName}
                            // onChange={(e) => handleChange(e.target)}
                            value={formData.assignTo}
                            name="assignTo"
                        />
                         <Input
                            label="Fecha de vencimiento"
                            placeholder="DD/MM/YYYY"
                            required    
                            onChange={handleChange}
                            // onBlur={formik.handleBlur}
                            value={formData.date}
                            name="date"
                            type="datetime-local"
                        />
                    </div>
                    <Input
                        label="Descripción"
                        // required
                        onChange={handleChange}
                        value={formData.description}
                        name="description"
                        textAreaLimit={1200}
                        type="long"
                        placeholder="Describe aquí tu lista de tareas"
                    />
                </div>


                <div className="flex justify-between items-center py-5 w-full">
                    <Button
                        color={COLOR.WHITE}
                        size={SIZE.MEDIUM}
                        addClass={"h-11"}
                        onClick={() => cancel()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color={COLOR.RED}
                        size={SIZE.MEDIUM}
                        type="submit"
                        addClass={"h-11"}
                        onClick={saveNewTask}
                    >
                        Agregar tarea
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreateNewTaskModal;
