import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import {
    ALL_DEPARTMENTS,
    ALL_MUNICIPALITIES,
    CANTONS,
    CATEGORIES,
    DISTRICTS,
    EXCLUSIVE_LATIN_COUNTRIES,
    PROVINCES,
    SECTORS
} from "../../constants/constants";
import { useProfSearchStore } from "../../store/professionals";
import { objectToQueryString } from "../../utils/objToQuery";
import Checkbox from "../ui/Checkbox";
import Input from "../ui/Input";
import Toggle from "../ui/Toggle";
import { Button, COLOR, SIZE } from "../ui/buttons/Button";

function SearchBar({ className = "", filters = false }) {
    const [inPerson, setInPerson] = useState(1);
    const formData = useProfSearchStore((state) => state.form);
    const handleChange = useProfSearchStore((state) => state.handleEditForm);
    // const reset = useProfSearchStore((state) => state.resetForm);

    const location = useLocation();
    const navigate = useNavigate();
    const toggleInPerson = () => {
        handleChange({
            name: "inPerson",
            value: !inPerson
        });
        setInPerson((prev) => !prev);
    };

    const handleSearch = useCallback(
        (e) => {
            e.preventDefault();
            console.log(location.pathname);
            if (location.pathname === "/") {
                navigate(`/professionals?${objectToQueryString(formData)}`);
            }
            // else {
            //     reset();
            // }
        },
        [formData, location.pathname, navigate]
    );

    return (
        <div
            className={twMerge(
                `flex flex-col items-center w-full mt-[70px]`,
                className
            )}
        >
            <div className=" flex max-w-[1400px] sm:w-[80vw] items-center flex-col   mb-[30px] ">
                <form
                    onSubmit={handleSearch}
                    className="w-full flex flex-col lg:flex-row justify-center items-center gap-7 p-[30px] backdrop-blur-[10px]"
                    style={{
                        background:
                            "linear-gradient(134.72deg, rgba(255,255,255,0.85) -17.57%, rgba(255,255,255,0) 103.21%)",
                        boxShadow: "0px 4px 20px 0 rgba(0,0,0,0.1)"
                    }}
                >
                    <Checkbox
                        checked={formData.inPerson}
                        toggleCheck={toggleInPerson}
                        text="Presencial"
                    />
                    <Input
                        placeholder="Consigue tu asesoría"
                        onChange={(e) => handleChange(e.target)}
                        value={formData.search}
                        name="search"
                        icon={
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 -translate-y-1 cursor-pointer"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M19.875 18.6562L16.0938 14.875C16 14.8125 15.9062 14.75 15.8125 14.75H15.4062C16.375 13.625 17 12.125 17 10.5C17 6.9375 14.0625 4 10.5 4C6.90625 4 4 6.9375 4 10.5C4 14.0938 6.90625 17 10.5 17C12.125 17 13.5938 16.4062 14.75 15.4375V15.8438C14.75 15.9375 14.7812 16.0312 14.8438 16.125L18.625 19.9062C18.7812 20.0625 19.0312 20.0625 19.1562 19.9062L19.875 19.1875C20.0312 19.0625 20.0312 18.8125 19.875 18.6562ZM10.5 15.5C7.71875 15.5 5.5 13.2812 5.5 10.5C5.5 7.75 7.71875 5.5 10.5 5.5C13.25 5.5 15.5 7.75 15.5 10.5C15.5 13.2812 13.25 15.5 10.5 15.5Z"
                                    fill="#333333"
                                />
                            </svg>
                        }
                        addClass="lg:max-w-[20vw]  gap-0"
                    />
                    <div className="flex flex-col justify-start items-start  w-full lg:w-[175px] gap-[5px]">
                        <Input
                            name="sector"
                            value={formData.sector}
                            onChange={(e) => handleChange(e.target)}
                            type="dropdown"
                            selectList={SECTORS}
                        />
                    </div>
                    <div className="flex flex-col justify-start items-start  w-full lg:w-[211px] gap-[5px]">
                        <Input
                            name="category"
                            value={formData.category}
                            onChange={(e) => handleChange(e.target)}
                            type="dropdown"
                            // addInputClass="w-[300px] self-stretch "
                            selectList={
                                formData.sector &&
                                [SECTORS[1], SECTORS[2]].includes(
                                    formData.sector
                                )
                                    ? CATEGORIES[formData.sector]
                                    : ["Categorías"]
                            }
                        />
                    </div>
                    <Button
                        type="submit"
                        size={SIZE.LARGE}
                        color={COLOR.BLACK}
                        addClass=" w-full lg:w-[156px] "
                        // className="hover:opacity-80 flex justify-center items-center  text-lg text-left text-white  w-full lg:w-[156px] h-12 relative gap-2.5 px-8 py-2.5 rounded bg-[#292929]   transition-all duration-500 "
                    >
                        Buscar
                    </Button>
                </form>
            </div>
            {filters && (
                <>
                    <div className="flex  flex-wrap justify-center items-center  gap-[29px] px-[7%]">
                        <div className="flex flex-col justify-start items-start  gap-[5px]">
                            <div className="flex justify-end items-center  h-9 relative gap-2.5 px-4 py-2.5 rounded bg-white border border-[#292929]">
                                <Toggle
                                    activeProp={formData.isOnline}
                                    label="En linea"
                                    callback={() =>
                                        handleChange({
                                            name: "isOnline",
                                            value: !formData.isOnline
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex flex-col justify-start items-start  min-w-[181px] gap-[5px]">
                            <Input
                                name="experience"
                                value={formData.experience}
                                onChange={(e) => handleChange(e.target)}
                                type="dropdown"
                                // placeholder="Sector"
                                selectList={[
                                    "Experiencia",
                                    "0-2",
                                    "3-5",
                                    "6-10",
                                    "10+"
                                ]}
                                inputClassName={
                                    "flex flex-col text-base   text-left text-[#373737] justify-center items-start self-stretch flex-grow gap-2.5 px-3 py-1.5 rounded  bg-white border-t border-r  border-b border-l border-[#292929]"
                                }
                            />
                        </div>
                        <div className="flex flex-col justify-start items-start  min-w-[181px] gap-[5px]">
                            <Input
                                name="country"
                                value={formData.country}
                                onChange={(e) => handleChange(e.target)}
                                type="dropdown"
                                selectList={Object.keys(
                                    EXCLUSIVE_LATIN_COUNTRIES
                                ).map((key) => EXCLUSIVE_LATIN_COUNTRIES[key])}
                                inputClassName={
                                    "flex flex-col text-base   text-left text-[#373737] justify-center items-start self-stretch flex-grow gap-2.5 px-3 py-1.5 rounded  bg-white border-t border-r  border-b border-l border-[#292929]"
                                }
                            />
                        </div>
                    </div>
                    <div className="flex flex-grow gap-10 justify-evenly pt-10">
                        {formData.country ===
                        EXCLUSIVE_LATIN_COUNTRIES.COSTA_RICA ? (
                            <>
                                <Input
                                    label="Provincia"
                                    placeholder="Provincia"
                                    onChange={(e) => handleChange(e.target)}
                                    value={formData.province}
                                    name="province"
                                    selectList={PROVINCES}
                                    type="dropdown"
                                    addInputClass="h-[38px] px-3 py-1.5   max-w-[300px]"
                                />
                                <Input
                                    label="Cantón"
                                    placeholder="Cantón"
                                    onChange={(e) => handleChange(e.target)}
                                    value={formData.canton}
                                    name="canton"
                                    selectList={CANTONS}
                                    type="dropdown"
                                    addInputClass="h-[38px] px-3 py-1.5   max-w-[300px]"
                                />
                                <Input
                                    label="Distrito"
                                    placeholder="Distrito"
                                    onChange={(e) => handleChange(e.target)}
                                    value={formData.district}
                                    name="district"
                                    selectList={DISTRICTS}
                                    type="dropdown"
                                    addInputClass="h-[38px] px-3 py-1.5   max-w-[300px]"
                                />
                            </>
                        ) : (
                            <>
                                <div className="flex  justify-start items-start  min-w-[181px]  gap-[5px] ">
                                    <Input
                                        name="department"
                                        value={formData.department}
                                        onChange={(e) => handleChange(e.target)}
                                        type="dropdown"
                                        label="Departamento"
                                        selectList={
                                            ALL_DEPARTMENTS[formData.country]
                                        }
                                        addInputClass="h-[38px] px-3 py-1.5   max-w-[300px]"
                                    />
                                </div>
                                <div className="flex flex-col justify-start items-start  min-w-[181px]  gap-[5px]">
                                    <Input
                                        name="municipal"
                                        value={formData.municipal}
                                        onChange={(e) => handleChange(e.target)}
                                        type="dropdown"
                                        label="Municipio"
                                        selectList={
                                            ALL_MUNICIPALITIES[
                                                formData.country
                                            ]?.[
                                                formData?.department ||
                                                    "Ahuachápan"
                                            ] || ["Municipio"]
                                        }
                                        inputClassName={
                                            "flex flex-col text-base   text-left text-[#373737] justify-center items-start self-stretch flex-grow gap-2.5 px-3 py-1.5 rounded  bg-white border-t border-r  border-b border-l border-[#292929]"
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export default SearchBar;
