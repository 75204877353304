import React, { useCallback, useState } from "react";
import Input from "../ui/Input";
import { Button } from "../ui/buttons/Button";
import { useNavigate } from "react-router-dom";

function Quote({ closeDialog }) {
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();

    const handleChange = useCallback(
        (target = {}) => {
            let { name, value } = target;
            console.log(target);
            setFormData((data) => ({ ...data, ...{ [name]: value } }));
            console.log(formData);
        },
        [formData]
    );

    const handleFormSubmission = useCallback(
        (e) => {
            // submit formData
            e.preventDefault();

            setFormData({});
            navigate("/platform/quote");
            closeDialog();
        },
        [closeDialog, navigate]
    );

    return (
        <div
            className="flex flex-col justify-start items-start lg:w-[649px] relative gap-5 p-5 bg-white"
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)", position:"relative", top:"260px" }}
        >
            <div className="flex justify-start items-center self-stretch  relative gap-2.5">
                <p className="flex-grow lg:w-[609px] text-2xl font-bold text-left text-[#373737]">
                    Aumenta tus ganancias con cotizaciones
                </p>
            </div>
            <p className="self-stretch  lg:w-[609px] text-base text-left">
                <span className="self-stretch  lg:w-[609px] text-base text-left text-[#7b7b7b]">
                    {`Las Cotizaciones te permiten presentar proyectos a clientes
                    nuevos y existentes, incluso si no tienen una cuenta de
                    EProf `}
                </span>
                <span className="self-stretch underline  lg:w-[609px] text-base text-left text-[#d80027]">
                    {`Conoce más`}
                </span>
                <br />
                <span className="self-stretch  lg:w-[609px] text-base text-left text-[#7b7b7b]">
                    Para comenzar, ingresa el nombre, nombre de usuario o
                    dirección de correo electrónico de tu cliente.
                </span>
                <br />
                <span className="self-stretch  lg:w-[609px] text-base text-left text-[#7b7b7b]">
                    Se aplicará una{" "}
                </span>
                <span className="self-stretch underline lg:w-[609px] text-base text-left text-[#d80027]">
                    tarifa de proyecto rebajada del 3 %
                </span>
                <span className="self-stretch  lg:w-[609px] text-base text-left text-[#7b7b7b]">
                    {" "}
                    a cualquier proyecto iniciado mediante una Cotización con un
                    cliente que no ha usado EProf.
                </span>
            </p>

            <Input
                label="Nombre de usuario o correo electrónico"
                placeholder="Nombre o correo eletrónico"
                // required
                onChange={(e) => handleChange(e.target)}
                value={formData.name}
                name="name"
            />
            <div className="flex items-center self-stretch justify-end gap-5">
                <Button
                    link="/platform/quote"
                    text="Siguiente"
                    iconName={"arrow"}
                    addClass={"h-11"}
                    onClick={handleFormSubmission}
                />
            </div>
            <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer w-10 h-10 absolute left-[609px] top-0"
                preserveAspectRatio="xMidYMid meet"
                onClick={closeDialog}
            >
                <path
                    d="M20.0007 7.08301C12.8652 7.08301 7.08398 12.8643 7.08398 19.9997C7.08398 27.1351 12.8652 32.9163 20.0007 32.9163C27.1361 32.9163 32.9173 27.1351 32.9173 19.9997C32.9173 12.8643 27.1361 7.08301 20.0007 7.08301ZM20.0007 30.4163C14.2194 30.4163 9.58398 25.7809 9.58398 19.9997C9.58398 14.2705 14.2194 9.58301 20.0007 9.58301C25.7298 9.58301 30.4173 14.2705 30.4173 19.9997C30.4173 25.7809 25.7298 30.4163 20.0007 30.4163ZM25.2611 16.7705C25.5215 16.5622 25.5215 16.1455 25.2611 15.8851L24.1152 14.7393C23.8548 14.4788 23.4382 14.4788 23.2298 14.7393L20.0007 17.9684L16.7194 14.7393C16.5111 14.4788 16.0944 14.4788 15.834 14.7393L14.6882 15.8851C14.4277 16.1455 14.4277 16.5622 14.6882 16.7705L17.9173 19.9997L14.6882 23.2809C14.4277 23.4893 14.4277 23.9059 14.6882 24.1663L15.834 25.3122C16.0944 25.5726 16.5111 25.5726 16.7194 25.3122L20.0007 22.083L23.2298 25.3122C23.4382 25.5726 23.8548 25.5726 24.1152 25.3122L25.2611 24.1663C25.5215 23.9059 25.5215 23.4893 25.2611 23.2809L22.0319 19.9997L25.2611 16.7705Z"
                    fill="#333333"
                />
            </svg>
        </div>
    );
}

export default Quote;
