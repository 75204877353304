import React from "react";

function HeaderBack({ text = "Regresar" }) {
    return (
        <div className="hover:opacity-90 cursor-pointer flex justify-start items-center  relative">
            <svg
                width={30}
                height={30}
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="flex-grow-0  w-[30px] h-[30px] relative"
                preserveAspectRatio="xMidYMid meet"
            >
                <path
                    d="M12.1875 20.5078L12.9688 19.7266C13.125 19.5312 13.125 19.2578 12.9297 19.0625L9.80469 16.0156H23.2812C23.5156 16.0156 23.75 15.8203 23.75 15.5469V14.4531C23.75 14.2188 23.5156 13.9844 23.2812 13.9844H9.80469L12.9297 10.9766C13.125 10.7812 13.125 10.5078 12.9688 10.3125L12.1875 9.53125C11.9922 9.375 11.7188 9.375 11.5234 9.53125L6.36719 14.6875C6.21094 14.8828 6.21094 15.1562 6.36719 15.3516L11.5234 20.5078C11.7188 20.6641 11.9922 20.6641 12.1875 20.5078Z"
                    fill="#333333"
                />
            </svg>
            <p className="flex-grow-0  text-sm text-left text-[#373737]">
                {text}
            </p>
        </div>
    );
}

export default HeaderBack;
