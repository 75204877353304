import React from "react";
import PlatformWrapper from "../../../components/platform/PlatformWrapper";
import CommunityBlogPost from "../../../components/platform/CommunityBlogPost";
import { attachmentSvg } from "../../../utils/icons";
import { Link } from "react-router-dom";
import Input from "../../../components/ui/Input";
import { Button, COLOR, SIZE } from "../../../components/ui/buttons/Button";

function CommunityBlog() {
    return (
        <PlatformWrapper>
            <div className="absolute left-5 sm:left-0 top-0 -translate-y-[50px] mt-10">
                <Link to={"/platform/community"}>
                    <div className="flex items-center justify-start gap-2 overflow-hidden">
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="relative w-6 h-6 "
                            preserveAspectRatio="none"
                        >
                            <path
                                d="M9.57 5.92999L3.5 12L9.57 18.07"
                                stroke="#292929"
                                strokeWidth="1.5"
                                stroke-miterlimit={10}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M20.4999 12H3.66992"
                                stroke="#292929"
                                strokeWidth="1.5"
                                stroke-miterlimit={10}
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <p className="font-[Nunito]  flex-grow-0 flex-shrink-0 text-base font-semibold text-left text-[#d80027]">
                            Comunidad &nbsp;/&nbsp; Articulo del blog
                        </p>
                    </div>
                </Link>
            </div>
            <div>
                <div className="flex flex-col justify-start sm:items-end  max-w-[95vw] sm:max-w-none mt-10   gap-5">
                    <div className="relative flex flex-col justify-start gap-3 sm:flex-row sm:items-center">
                        <button className="hover:opacity-80   text-base text-left text-[#373737]">
                            Todos los temas del foro
                        </button>
                        <button className="hover:opacity-80 flex sm:justify-center items-center   relative gap-[15px] px-4 py-2 rounded">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 rotate-180"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M15.625 15.4375L18.7188 12.3438C18.875 12.2188 18.875 11.9688 18.7188 11.8125L15.625 8.71875C15.375 8.5 15 8.65625 15 9V11.2188H5.375C5.15625 11.2188 5 11.375 5 11.5938V12.5938C5 12.8125 5.15625 12.9688 5.375 12.9688H15V15.1875C15 15.5313 15.375 15.6875 15.625 15.4375Z"
                                    fill="#333333"
                                />
                            </svg>{" "}
                            <p className="  text-base text-left text-[#373737]">
                                Tema anterior
                            </p>
                        </button>
                        <button className="hover:opacity-80 flex sm:justify-center items-center   relative gap-[15px] px-4 py-2 rounded">
                            <p className="  text-base text-left text-[#373737]">
                                Siguiente tema
                            </p>
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative w-6 h-6 "
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <path
                                    d="M15.625 15.4375L18.7188 12.3438C18.875 12.2188 18.875 11.9688 18.7188 11.8125L15.625 8.71875C15.375 8.5 15 8.65625 15 9V11.2188H5.375C5.15625 11.2188 5 11.375 5 11.5938V12.5938C5 12.8125 5.15625 12.9688 5.375 12.9688H15V15.1875C15 15.5313 15.375 15.6875 15.625 15.4375Z"
                                    fill="#333333"
                                />
                            </svg>
                        </button>
                    </div>
                    <CommunityBlogPost />
                    <div className="relative flex flex-col justify-start w-full sm:flex-row sm:items-center">
                        <p className="flex-grow  text-lg font-bold text-left text-[#373737]">
                            1 RESPUESTA
                        </p>

                        <div className="flex justify-start items-center mt-5 sm:m-0  gap-[5px]">
                            <div className="flex flex-col justify-start items-start   gap-[5px]">
                                <div className="flex items-start self-stretch justify-start h-9">
                                    <Input
                                        name="De más antiguo a más reciente"
                                        value="De más antiguo a más reciente"
                                        // onChange={(e) => handleChange(e.target)}
                                        type="dropdown"
                                        selectList={[
                                            "De más antiguo a más reciente",
                                            "De más antiguo a más trending",
                                            "De más antiguo a más first",
                                            "De más antiguo a más popular"
                                        ]}
                                        inputClassName={
                                            "flex flex-col justify-center items-start self-stretch flex-grow outline-none h-10  sm:w-[333px] text-base text-left text-[#373737] gap-2.5 px-4 py-1.5 rounded  bg-white border-t border-r border-b border-l border-[#cdcdcd]"
                                        }
                                    />

                                    {/* <div className="flex flex-col justify-center items-start self-stretch flex-grow gap-2.5 px-4 py-2.5 rounded-tl rounded-bl bg-white border-t border-r-0 border-b border-l border-[#cdcdcd]">
                                        <div className="flex justify-start items-center self-stretch   relative gap-2.5">
                                            <p className="flex-grow sm:w-[233px] text-base text-left text-[#373737]">
                                                De más antiguo a más reciente
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center items-center self-stretch   sm:w-[60px] relative gap-2.5 px-4 py-2.5 rounded-tr rounded-br bg-white border-t border-r border-b border-l-0 border-[#cdcdcd]">
                                        <svg
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="relative w-6 h-6 "
                                            preserveAspectRatio="xMidYMid meet"
                                        >
                                            <path
                                                d="M11.9995 16.3C11.4276 16.3 10.8561 16.0795 10.4231 15.6464L3.90308 9.12645C3.80835 9.03171 3.80835 8.86829 3.90308 8.77355C3.99782 8.67881 4.16124 8.67881 4.25598 8.77355L10.776 15.2936C11.4512 15.9688 12.5478 15.9688 13.2231 15.2936L19.7431 8.77355C19.8378 8.67881 20.0012 8.67881 20.096 8.77355C20.1907 8.86829 20.1907 9.03171 20.096 9.12644L20.4495 9.48L20.096 9.12645L13.576 15.6464C13.1429 16.0795 12.5715 16.3 11.9995 16.3Z"
                                                fill="#292929"
                                                stroke="#292929"
                                            />
                                        </svg>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <CommunityBlogPost />
                    <div className="flex justify-start items-center flex-grow self-stretch   w-full h-[59px] relative gap-2.5 px-4 py-2.5 border border-[#cdcdcd]">
                        <svg
                            width={30}
                            height={31}
                            viewBox="0 0 30 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=" hidden sm:block sm:w-[30px] h-[30px] relative"
                            preserveAspectRatio="xMidYMid meet"
                        >
                            <path
                                d="M15 5.8125C9.64844 5.8125 5.3125 10.1484 5.3125 15.5C5.3125 20.8516 9.64844 25.1875 15 25.1875C20.3516 25.1875 24.6875 20.8516 24.6875 15.5C24.6875 10.1484 20.3516 5.8125 15 5.8125ZM15 23.3125C10.6641 23.3125 7.1875 19.8359 7.1875 15.5C7.1875 11.2031 10.6641 7.6875 15 7.6875C19.2969 7.6875 22.8125 11.2031 22.8125 15.5C22.8125 19.8359 19.2969 23.3125 15 23.3125ZM11.875 14.875C12.5391 14.875 13.125 14.3281 13.125 13.625C13.125 12.9609 12.5391 12.375 11.875 12.375C11.1719 12.375 10.625 12.9609 10.625 13.625C10.625 14.3281 11.1719 14.875 11.875 14.875ZM18.125 14.875C18.7891 14.875 19.375 14.3281 19.375 13.625C19.375 12.9609 18.7891 12.375 18.125 12.375C17.4219 12.375 16.875 12.9609 16.875 13.625C16.875 14.3281 17.4219 14.875 18.125 14.875ZM18.2812 17.7266C17.4609 18.7031 16.25 19.25 15 19.25C13.7109 19.25 12.5 18.7031 11.7188 17.7266C11.3672 17.3359 10.7812 17.2969 10.3906 17.6094C10 17.9219 9.92188 18.5469 10.2734 18.9375C11.4453 20.3438 13.1641 21.125 15 21.125C16.7969 21.125 18.5156 20.3438 19.6875 18.9375C20.0391 18.5469 20 17.9219 19.5703 17.6094C19.1797 17.2969 18.5938 17.3359 18.2812 17.7266Z"
                                fill="#333333"
                            />
                        </svg>
                        <input
                            placeholder="Escribe un mensaje"
                            type="text"
                            className="border-none flex-grow sm:w-[558px] text-sm text-left outline-none "
                        />
                        {/* <p className="flex-grow sm:w-[558px] text-sm text-left text-[#7b7b7b]">
                            Escribe un mensaje
                        </p> */}
                        <div className=" hidden sm:block sm:w-[30px] h-[30px] relative overflow-hidden">
                            <img src={attachmentSvg} alt="" />
                            {/* <p className="absolute left-[7px] top-[7px] text-base text-center text-[#333]">
                                paperclip
                            </p> */}
                        </div>

                        <Button
                            text="Enviar"
                            addClass={"sm:w-[135px] h-9 flex"}
                            color={COLOR.BLACK}
                            size={SIZE.SMALL}
                        />
                    </div>
                </div>
            </div>
        </PlatformWrapper>
    );
}

export default CommunityBlog;
