import React from "react";
import Pagination from "../components/Pagination";
import Network from "../components/landing/Network";
import SearchBar from "../components/landing/SearchBar";
import Hero from "../components/professionals/Hero";
import ProfessionalsCard from "../components/professionals/ProfessionalsCard";

function Professionals() {
    const profCards = [];
    for (let index = 0; index < 12; index++) {
        profCards.push(<ProfessionalsCard />);
    }
    return (
        <>
            <Hero />
            <div className="xl:mt-[-15vh]">
                <SearchBar filters />
            </div>
            {/* SEARCH RESULTS */}
            <div className="flex flex-col items-center mt-[100px]">
                <div className="px-5  lg:px-[7%] w-full lg:w-auto flex flex-wrap justify-center   gap-[50px] lg:gap-[100px]">
                    {profCards}
                </div>
                <div className="my-14">
                    <Pagination />
                </div>
            </div>
            <div className="px-[7%] pb-[100px]">
                <Network />
            </div>
        </>
    );
}

export default Professionals;
