import { useState, useEffect } from "react";

const useScreenDimensions = () => {
    const [screenDimensions, setScreenDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setScreenDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return screenDimensions;
};

const useMobileCheck = () => useScreenDimensions().width < 640;

export { useScreenDimensions, useMobileCheck };
