import BlogPost from "../blog/BlogPost";

function Blog({
    title = "Blog",
    desc = "Encuentra los artículos relacionados a tu sector y entérate de las ultimas noticias."
}) {
    return (
        <div className="flex flex-col justify-start items-start gap-10 py-[100px] ">
            <div className="relative flex flex-col items-center self-stretch justify-start gap-5">
                <span className="font-[Satoshi] relative self-stretch text-4xl sm:text-6xl font-bold text-center text-[#373737]">
                    {title}
                    {title === "Blog" ? (
                        <span className="flex flex-col items-center mt-[15px]">
                            <svg
                                width={"160"}
                                height={"19"}
                                viewBox="0 0 166 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="absolute bottom-0 w-[110px] sm:w-auto"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M164.5 11.441C148.5 1.27004 28.5 -5.55896 2 17.4411"
                                    stroke="#D80027"
                                    strokeWidth={3}
                                    stroke-linecap="round"
                                />
                            </svg>
                        </span>
                    ) : null}
                </span>
                <p className=" self-stretch text-md sm:text-[21px] text-center text-[#7b7b7b]">
                    {desc}
                </p>
            </div>
            <div className="flex flex-col xl:flex-row justify-center items-center  gap-[42px] w-full ">
                <BlogPost />
                <BlogPost />
                <BlogPost />
            </div>
        </div>
    );
}

export default Blog;
