import React from "react";

function HeaderText({
    title = "Registro",
    desc = "Ingresa tus credenciales para acceder a tu cuenta y comienza a disfrutar de nuestros beneficios "
}) {
    return (
        <div className="flex flex-col justify-start items-start self-stretch    relative gap-2.5">
            <p className="font-[Satoshi] flex-grow sm:w-[396px] text-[34px] text-left text-[#373737]">
                {title}
            </p>
            <p className="self-stretch    sm:w-[744px] text-base text-left text-[#7b7b7b]">
                {desc}
            </p>
        </div>
    );
}

export default HeaderText;
