import Pagination from "../../../components/Pagination";
import CommunityPost from "../../../components/platform/CommunityPost";
import PlatformWrapper from "../../../components/platform/PlatformWrapper";
import { Button, COLOR, SIZE } from "../../../components/ui/buttons/Button";
import avatar from "../../../assets/images/avatar2.png";

const communityPostDummy = {
    verified: false,
    title: `Amet minim mollit non deserunt ullamco est sit aliqua...`,
    createAgo: "3 horas",
    views: 432,
    replies: 23,
    user: {
        title: "por",
        name: "Abidemi K.",
        image: avatar,

        country: (
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="relative w-6 h-6 "
                preserveAspectRatio="none"
            >
                <path d="M21.9997 5H2V18.3333H21.9997V5Z" fill="white" />
                <path d="M8.66643 5H2V18.3333H8.66643V5Z" fill="#4997D0" />
                <path d="M22.0004 5H15.334V18.3333H22.0004V5Z" fill="#4997D0" />
            </svg>
        )
    }
};

function Community() {
    return (
        <PlatformWrapper>
            <div className="flex flex-col justify-start items-start   gap-[30px] ">
                <div className="flex flex-col items-start justify-start gap-5">
                    <div className="flex flex-col justify-start items-start   w-full relative gap-[5px]">
                        <p className="self-stretch   sm:w-[796px] text-left text-[#373737]">
                            <span className="self-stretch   sm:w-[796px] text-2xl font-bold text-left text-[#373737]">
                                Bienvenido
                            </span>
                            <span className="self-stretch   sm:w-[796px] text-lg font-bold text-left text-[#373737]">
                                {" "}
                            </span>
                            <span className="self-stretch   sm:w-[796px] text-lg text-left text-[#373737]">
                                a la comunidad Legal EPROF
                            </span>
                        </p>
                        <p className="self-stretch max-w-[90%]  sm:w-[796px] text-base text-left text-[#7b7b7b]">
                            Mantengase informado y actualizado con las ultimas
                            noticias y tendencias, participe y opine sobre las
                            leyes, formule preguntas y obtenga respuesta de la
                            comunidad.{" "}
                        </p>
                    </div>
                    <div className="flex flex-col sm:flex-row w-full sm:w-auto justify-start  items-stretch sm:items-start   gap-[18px]">
                        <Button
                            color={COLOR.WHITE}
                            addClass={"h-9 px-4 py-2.5 "}
                            size={SIZE.SMALL}
                            iconName={"bellSilent"}
                            text="Silenciar notificaciones"
                        />
                        <Button
                            addClass={"h-9 px-4 py-2.5 "}
                            size={SIZE.SMALL}
                            iconName={"plus"}
                            text="Nuevo topico"
                        />
                    </div>
                </div>
                <div className="flex flex-col items-end justify-start w-full gap-6">
                    <div className="flex items-start justify-start select-none">
                        <Pagination />
                    </div>
                    <div className="w-full communityPosts">
                        <CommunityPost
                            post={{ ...communityPostDummy, verified: true }}
                        />
                        <CommunityPost />
                        <CommunityPost />
                        <CommunityPost />
                        <CommunityPost />
                        <CommunityPost />
                    </div>
                    <div className="flex items-start justify-start ">
                        <Pagination />
                    </div>
                </div>
            </div>
        </PlatformWrapper>
    );
}

export default Community;
