import React from "react";
import HoverCard from "./HoverCard";
import { infoSvg, infoRedSvg } from "../../utils/icons";

function InfoHover({ text, red = false }) {
    return (
        <HoverCard text={text}>
            <img
                className="mt-1 hover:opacity-80"
                src={!red ? infoSvg : infoRedSvg}
                alt="infoSvg"
            />
        </HoverCard>
    );
}

export default InfoHover;
