import { useFormik } from "formik";
import { useState } from "react";
import FormValidation from "../form/FormValidation";
import Input from "../ui/Input";
import { Button } from "../ui/buttons/Button";
import Swal from "sweetalert2";



function LoginStep2({ navigateNext }) {


    const [storedCorreo, setStoredCorreo] = useState(localStorage.getItem('storedCorreo') || '');

const formik = useFormik({
    initialValues: {
        email: storedCorreo || ''
    },
    onSubmit: async (values, { setSubmitting }) => {
        try {
            const response = await fetch('https://eprof-production-6g7zxvibga-uc.a.run.app/reset_password_request/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: values.email }),
            });
            if (!response.ok) {
                throw new Error('Error al enviar el formulario');
            }

            localStorage.setItem('storedCorreo', values.email);

            setStoredCorreo(values.email);
            navigateNext();
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
            await Swal.fire({
                title: 'Error',
                text: 'Correo no asociado a algun usuario',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
            });
        } finally {
            setSubmitting(false);
        }
    }
});

    return (
        <div
            className="flex flex-col justify-start items-start mt-20 mx-2 xl:m-0 xl:w-[28vw] gap-8 p-[30px] rounded-[20px] bg-white"
            // className="flex flex-col justify-start items-start w-[456px] relative gap-8 p-[30px] rounded-[20px] bg-white"
            style={{ boxShadow: "0px 8px 16px 0 rgba(0,0,0,0.1)" }}
        >
            <p className="self-stretch  xl:w-[396px] text-[21px] text-left text-[#373737]">
                Introduzca la dirección de correo electrónico asociada
            </p>
            <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col items-end self-stretch justify-start gap-5"
        >
            <Input
                label="Correo electrónico"
                placeholder="Correo electrónico"
                required
                onChange={formik.handleChange}
                value={formik.values.email}
                name="email"
                type="text"
            />
            <div className="w-full">
                {<FormValidation formik={formik} name="email" />}
            </div>

            <Button
                type="submit"
                addClass="  self-stretch  text-base   h-11  px-8 py-2.5  "
            >
                Continuar
            </Button>
            <div className="flex flex-col justify-start items-center self-stretch  relative gap-2.5">
                <p className=" text-base text-left text-[#373737]">
                    ¿Aún no tienes una cuenta?
                </p>
                <a
                    href="/signup"
                    className=" text-base text-left text-[#d80027]"
                >
                    Crea una aquí
                </a>
            </div>
        </form>
        </div>
    );
}

export default LoginStep2;
