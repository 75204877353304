import React from 'react';
import { Outlet } from 'react-router-dom';
import SettingsSidebar from './SettingsSidebar';

function SettingsLayout() {
  return (
      <div className="flex flex-col items-center py-32 w-full min-h-screen">
          <div className="flex flex-col gap-5 lg:flex-row">
              <SettingsSidebar />
              <Outlet />
          </div>
      </div>
  );
}

export default SettingsLayout
