import { useFormik } from "formik";
import * as Yup from "yup";
import { noop } from "../../utils/noop";
import FormValidation from "../form/FormValidation";
import Input from "../ui/Input";
import { Button, COLOR } from "../ui/buttons/Button";
import { CATEGORIES } from "../../constants/constants";

const validationSchema = Yup.object({
    type: Yup.string().required("Tipo es requerido"),
    desc: Yup.string()
        .required("Descripción es requerida")
        .min(10, "El comentario debe tener al menos 10 caracteres")
        .max(100, "El comentario no debe tener más de 100 caracteres")
});

// , postId = null, type = "proposal"
function ReportDialog({ setDialog = noop }) {
    // const [formData, setFormData] = useState({});

    // const handleChange = useCallback(
    //     (target = {}) => {
    //         let { name, value } = target;
    //         console.log(target);
    //         setFormData((data) => ({ ...data, ...{ [name]: value } }));
    //         console.log(formData);
    //     },
    //     [formData]
    // );

    // const handleSubmit = useCallback((e) => {
    //     e.preventDefault();
    // }, []);

    const formik = useFormik({
        initialValues: {
            type: "",
            desc: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
        }
    });

    return (
        <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col justify-start items-start lg:w-[516px] relative gap-5 p-5 bg-white"
            style={{ boxShadow: "0px 20px 40px 0 rgba(0,0,0,0.15)" }}
        >
            <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer  w-10 h-10 flex-grow-0 flex-shrink-0 absolute left-[476px] top-0"
                preserveAspectRatio="xMidYMid meet"
                onClick={() => setDialog(0)}
            >
                <path
                    d="M20.0007 7.08301C12.8652 7.08301 7.08398 12.8643 7.08398 19.9997C7.08398 27.1351 12.8652 32.9163 20.0007 32.9163C27.1361 32.9163 32.9173 27.1351 32.9173 19.9997C32.9173 12.8643 27.1361 7.08301 20.0007 7.08301ZM20.0007 30.4163C14.2194 30.4163 9.58398 25.7809 9.58398 19.9997C9.58398 14.2705 14.2194 9.58301 20.0007 9.58301C25.7298 9.58301 30.4173 14.2705 30.4173 19.9997C30.4173 25.7809 25.7298 30.4163 20.0007 30.4163ZM25.2611 16.7705C25.5215 16.5622 25.5215 16.1455 25.2611 15.8851L24.1152 14.7393C23.8548 14.4788 23.4382 14.4788 23.2298 14.7393L20.0007 17.9684L16.7194 14.7393C16.5111 14.4788 16.0944 14.4788 15.834 14.7393L14.6882 15.8851C14.4277 16.1455 14.4277 16.5622 14.6882 16.7705L17.9173 19.9997L14.6882 23.2809C14.4277 23.4893 14.4277 23.9059 14.6882 24.1663L15.834 25.3122C16.0944 25.5726 16.5111 25.5726 16.7194 25.3122L20.0007 22.083L23.2298 25.3122C23.4382 25.5726 23.8548 25.5726 24.1152 25.3122L25.2611 24.1663C25.5215 23.9059 25.5215 23.4893 25.2611 23.2809L22.0319 19.9997L25.2611 16.7705Z"
                    fill="#333333"
                />
            </svg>
            <div className="flex justify-start items-center self-stretch  relative gap-2.5">
                <p className="flex-grow lg:w-[476px] text-2xl font-bold text-left text-[#373737]">
                    Indique el problema
                </p>
            </div>
            <Input
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                // value={formData.type}
                // onChange={(e) => handleChange(e.target)}
                type="dropdown"
                required
                label="Seleccione"
                selectList={CATEGORIES["Público"]}
            />
            {<FormValidation formik={formik} name="type" />}
            <Input
                type="long"
                placeholder="Descripción"
                label="Descripción"
                required
                name="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                // value={formData.desc}
                // onChange={(e) => handleChange(e.target)}
            />
            {<FormValidation formik={formik} name="desc" />}
            <div className="flex items-start self-stretch justify-start gap-5">
                <Button
                    stretch
                    color={COLOR.WHITE}
                    onClick={() => setDialog(0)}

                    // className="flex   text-base text-left text-[#373737] justify-center items-center flex-grow h-11 relative gap-2.5 px-8 py-2.5 rounded border-2 border-[#292929]"
                >
                    Cancelar
                </Button>
                <Button
                    type="submit"
                    stretch
                    // className="flex justify-center  text-base text-left text-white items-center flex-grow h-11 relative gap-2.5 px-8 py-2.5 rounded bg-[#d80027]"
                >
                    Enviar
                </Button>
            </div>
        </form>
    );
}

export default ReportDialog;
