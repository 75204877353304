import React, { useState } from "react";
import { Button } from "../ui/buttons/Button";
import Invite from "../dialogs/Invite";
import Dialog from "../Dialog";

function CreditMeter() {
    const [showDialog, setDialog] = useState(0);

    return (
        <>
            <Dialog
                setDialog={setDialog}
                showDialog={showDialog}
                main={<Invite closeDialog={() => setDialog(0)} />}
            />
            <div className="flex flex-col justify-center items-center   gap-[30px]">
                <div className="flex flex-col items-start justify-start gap-5">
                    <div className="flex flex-col justify-start items-center  w-full lg:w-auto relative gap-2.5">
                        <p className="  w-full lg:w-[696px] font-[Satoshi] text-2xl lg:text-5xl font-bold text-center text-[#373737]">
                            Tu crédito desbloqueado:
                        </p>
                        <div className="flex flex-col justify-start items-center    w-[184px] relative gap-2.5 bg-[#292929]">
                            <p className="text-2xl font-bold text-center text-white lg:text-4xl">
                                $0
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col items-start justify-start gap-4">
                        <div className="flex justify-between items-center   w-full lg:w-[696px] gap-[100px] sm:gap-[138px] rounded-[100px] border border-[#cdcdcd]">
                            <div className="flex justify-start items-start flex-[0.3]   lg:w-[179px] gap-2.5 p-1 rounded-[100px] bg-[#ffecef]">
                                <div className="flex justify-start items-start   relative gap-2.5 p-2.5 rounded-[100px] bg-white">
                                    <svg
                                        width={16}
                                        height={18}
                                        viewBox="0 0 16 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className=""
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <path
                                            d="M15.5 9L0.499999 17.6603L0.5 0.339745L15.5 9Z"
                                            fill="#E03352"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex justify-center items-center  flex-[0.3]  relative gap-2.5 p-2.5 rounded-[100px] bg-white">
                                <svg
                                    width={16}
                                    height={18}
                                    viewBox="0 0 16 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className=""
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M15.5 9L0.499999 17.6603L0.5 0.339745L15.5 9Z"
                                        fill="#E03352"
                                    />
                                </svg>
                            </div>
                            <div className="flex justify-center items-center  flex-[0.3]  relative gap-2.5 p-2.5 rounded-[100px] bg-white">
                                <svg
                                    width={16}
                                    height={18}
                                    viewBox="0 0 16 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className=""
                                    preserveAspectRatio="xMidYMid meet"
                                >
                                    <path
                                        d="M15.5 9L0.499999 17.6603L0.5 0.339745L15.5 9Z"
                                        fill="#E03352"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="flex justify-between lg:justify-start items-start   w-full lg:w-[696px] relative px-5">
                            <p className="    w-fit lg:w-[232px]  text-sm lg:text-base font-bold text-left text-[#373737]">
                                GTQ 0
                            </p>
                            <p className="    w-fit lg:w-[232px]  text-sm lg:text-base font-bold text-center text-[#373737]">
                                GTQ 1000
                            </p>
                            <p className="    w-fit lg:w-[232px]  text-sm lg:text-base font-bold text-right text-[#373737]">
                                GTQ 2000
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row justify-start items-start   gap-[23px]">
                    <div
                        className="flex flex-col justify-start items-center   w-[216px] relative gap-[5px] p-2.5 bg-white"
                        style={{
                            boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)"
                        }}
                    >
                        <p className="  text-lg lg:text-xl font-bold text-center text-[#373737]">
                            Crédito total
                        </p>
                        <p className="font-[Satoshi]  text-2xl lg:text-4xl font-bold text-center text-[#373737]">
                            GTQ 0
                        </p>
                    </div>
                    <div
                        className="flex flex-col justify-start items-center   w-[216px] relative gap-[5px] p-2.5 bg-white"
                        style={{
                            boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)"
                        }}
                    >
                        <p className="  text-lg lg:text-xl font-bold text-center text-[#373737]">
                            Crédito potencial
                        </p>
                        <p className=" font-[Satoshi] text-2xl lg:text-4xl font-bold text-center text-[#373737]">
                            GTQ 180
                        </p>
                    </div>
                    <div
                        className="flex flex-col justify-start items-center   w-[216px] relative gap-[5px] p-2.5 bg-white"
                        style={{
                            boxShadow: "0px 0px 20px 0 rgba(0,0,0,0.1)"
                        }}
                    >
                        <p className="  text-lg lg:text-xl font-bold text-center text-[#373737]">
                            Amigos invitados
                        </p>
                        <p className=" font-[Satoshi] text-2xl lg:text-4xl font-bold text-center text-[#373737]">
                            9
                        </p>
                    </div>
                </div>
                <Button onClick={() => setDialog(1)}>
                    Comparte con un amigo
                </Button>
            </div>
        </>
    );
}

export default CreditMeter;
